import { Route, Switch } from 'react-router-dom';
import { PrivateContentPage } from '../../ui/features/content/pages/PrivateContentPage';
import { PublicContentPage } from '../../ui/features/content/pages/PublicContentPage';
import { contentPaths } from './content.paths';

export const PublicContentRouteList = () => (
    <Switch>
        <Route path={contentPaths.public} component={PublicContentPage} />
    </Switch>
);

export const PrivateContentRouteList = () => (
    <Switch>
        <Route path={contentPaths.protected} component={PrivateContentPage} />
    </Switch>
);
