import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useClient } from 'src/ui/shared/hooks/client.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { useLayout } from 'src/routes/RouteList';
import { Markdown } from '../../details/Markdown';
import { Icon } from '../../icon/Icon';

const disabledNavKey = 'disabledNavKey';

interface Props {
    children: React.ReactNode;
}

export const UserNavConfigurationGuard = ({
    children,
}: Props): React.ReactElement<any, any> | null => {
    const { navButtonVariant } = useLayout();
    const {
        nav: { login, loginPopover },
    } = useStrings();
    const { disableApplication } = useClient();

    return disableApplication ? (
        <OverlayTrigger
            placement="left-start"
            key={disabledNavKey}
            rootClose
            overlay={
                <Popover id={disabledNavKey}>
                    {loginPopover.label && (
                        <Popover.Title as="h3">{loginPopover.label}</Popover.Title>
                    )}
                    <Popover.Content>
                        <Markdown source={loginPopover.popover} />
                    </Popover.Content>
                </Popover>
            }
        >
            <Button variant={navButtonVariant} className="disabled btn-no-focus">
                <Icon icon={faUser} size="lg">
                    {login}
                </Icon>
            </Button>
        </OverlayTrigger>
    ) : (
        <>{children}</>
    );
};
