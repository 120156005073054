import uniq from 'lodash/uniq';
import { createReducer } from 'typesafe-actions';
import { LoaderState } from '../../../models/errors/error.model';
import { OrganisationSimilar } from '../../../models/organisation/organisation-similar.model';
import { Organisation } from '../../../models/organisation/organisation.model';
import { RegisterName } from '../../../models/register/register-name.model';
import { EntityListState } from '../../../models/store-models/entity-list-state.model';
import { entityListInitialState } from '../../helpers/initial-state.helper';
import { listReducerHandler } from '../../reducer-handlers/list.reducer-handler';
import * as actions from './register.actions';
import { SimilarOrganisationsRequest } from './register.service';

export type RegisterState = Readonly<{
    name: RegisterName | null;
    details: Organisation | null;
    similar: EntityListState<OrganisationSimilar, SimilarOrganisationsRequest>;
    legacyLogin: {
        show: boolean;
        fetch: LoaderState;
    };
    requestAccess: {
        show: boolean;
        fetch: LoaderState;
        ids: number[];
    };
    registerOrganisation: {
        fetch: LoaderState;
    };
    createIndividual: {
        fetch: LoaderState;
    };
}>;

const initialState: RegisterState = {
    name: null,
    details: null,
    similar: { ...entityListInitialState },
    legacyLogin: {
        show: false,
        fetch: { loading: false },
    },
    requestAccess: {
        show: false,
        fetch: { loading: false },
        ids: [],
    },
    registerOrganisation: {
        fetch: { loading: false },
    },
    createIndividual: {
        fetch: { loading: false },
    },
};

const registerReducer = createReducer(initialState)
    // register has a special reset reducer, because I always want to retain
    // the requested access organisation Ids
    .handleAction(actions.registerResetState, state => ({
        ...initialState,
        requestAccess: {
            ...initialState.requestAccess,
            ids: [...state.requestAccess.ids],
        },
    }))
    .handleAction(actions.registerNameStore, (state, action) => ({
        ...state,
        details: state.details
            ? {
                  ...state.details,
                  ...action.payload,
              }
            : state.details,
        name: action.payload,
    }))
    .handleAction(actions.registerDetailsStore, (state, action) => ({
        ...state,
        details: action.payload.organisation,
        logoObjectUrl: action.payload.logo ? URL.createObjectURL(action.payload.logo) : null,
    }))

    .handleAction(actions.registerSimilarAsync.request, (state, action) => ({
        ...state,
        similar: listReducerHandler.request(state.similar, action),
    }))
    .handleAction(actions.registerSimilarAsync.success, (state, action) => ({
        ...state,
        similar: listReducerHandler.success(state.similar, action),
    }))
    .handleAction(actions.registerSimilarAsync.cancel, (state, action) => ({
        ...state,
        similar: listReducerHandler.cancel(state.similar, action),
    }))
    .handleAction(actions.registerSimilarAsync.failure, (state, action) => ({
        ...state,
        similar: listReducerHandler.failure(state.similar, action),
    }))

    .handleAction(actions.registerSimilarListSetActive, (state, action) => ({
        ...state,
        similar: listReducerHandler.setActive(state.similar, action),
    }))

    .handleAction(actions.registerLegacyLoginShow, (state, action) => ({
        ...state,
        legacyLogin: {
            ...state.legacyLogin,
            show: action.payload.show,
            fetch: { ...initialState.legacyLogin.fetch },
        },
    }))
    .handleAction(actions.registerRequestAccessShow, (state, action) => ({
        ...state,
        requestAccess: {
            ...state.requestAccess,
            show: action.payload.show,
        },
    }))
    .handleAction(actions.registerRequestAccessAsync.request, state => ({
        ...state,
        requestAccess: {
            ...state.requestAccess,
            fetch: { loading: true },
        },
    }))
    .handleAction(actions.registerRequestAccessAsync.success, (state, { payload }) => ({
        ...state,
        requestAccess: {
            ...state.requestAccess,
            fetch: { loading: false },
            ids: uniq([...state.requestAccess.ids, payload.data.organisationId]),
            show: false,
        },
    }))
    .handleAction(actions.registerRequestAccessAsync.failure, (state, { payload }) => ({
        ...state,
        requestAccess: {
            ...state.requestAccess,
            fetch: { loading: false, error: payload },
        },
    }))
    .handleAction(actions.registerOrganisationAsync.request, state => ({
        ...state,
        registerOrganisation: {
            fetch: { loading: true },
        },
    }))
    .handleAction(actions.registerOrganisationAsync.success, state => ({
        ...state,
        registerOrganisation: {
            fetch: { loading: false },
        },
    }))
    .handleAction(actions.registerOrganisationAsync.failure, (state, action) => ({
        ...state,
        registerOrganisation: {
            fetch: { loading: false, error: action.payload },
        },
    }))
    .handleAction(actions.registerIndividualAsync.request, state => ({
        ...state,
        createIndividual: {
            fetch: { loading: true },
        },
    }))
    .handleAction(actions.registerIndividualAsync.success, state => ({
        ...state,
        createIndividual: {
            fetch: { loading: false },
        },
    }))
    .handleAction(actions.registerIndividualAsync.failure, (state, action) => ({
        ...state,
        createIndividual: {
            fetch: { loading: false, error: action.payload },
        },
    }));

export default registerReducer;
