import { faPlus, faRedo } from '@fortawesome/free-solid-svg-icons';
import { Button, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
    emailClaimsShowModal,
    userInfoAsync,
} from 'src/logic/features/email-claims/email-claims.actions';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { LoaderSpinner } from 'src/ui/shared/components/loader/LoaderSpinner';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

export const ContactEmailAppend = (): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    const onRefreshEmailsClick = () => {
        dispatch(userInfoAsync.request());
    };

    const onAddEmailClick = () => {
        dispatch(emailClaimsShowModal({ show: true }));
    };

    const { loading } = useRootSelector(state => state.emailClaims.userInfoFetch);

    return (
        <InputGroup.Append>
            {loading && (
                <InputGroup.Text>
                    <LoaderSpinner size="sm" />
                </InputGroup.Text>
            )}
            {!loading && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="contact-email-refresh">Refresh email claims</Tooltip>}
                >
                    <Button variant="outline-input" onClick={onRefreshEmailsClick}>
                        <Icon icon={faRedo} iconClassName="text-secondary" />
                    </Button>
                </OverlayTrigger>
            )}
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="contact-email-add">add email address</Tooltip>}
            >
                <Button variant="outline-input" onClick={onAddEmailClick}>
                    <Icon icon={faPlus} iconClassName="text-secondary" />
                </Button>
            </OverlayTrigger>
        </InputGroup.Append>
    );
};
