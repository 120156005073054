import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { EventOffCampusLocation } from 'src/models/events/event-location.model';
import { Icon } from 'src/ui/shared/components/icon/Icon';

interface Props {
    location: EventOffCampusLocation;
}

export const OffCampusLocationDisplay = ({
    location,
}: Props): React.ReactElement<any, any> | null => {
    const display = useMemo(() => {
        return [location.country, location.region, location.city, location.address]
            .filter(i => !!i)
            .join(' / ');
    }, [location.address, location.city, location.country, location.region]);

    return <Icon icon={faMapMarkerAlt}>{display}</Icon>;
};
