import { push } from 'connected-react-router';
import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { RootEpic } from 'src/logic/features/epic.root-index';
import { ActionCreator, isActionOf } from 'typesafe-actions';
import { isRouteMatch } from './location.epic-helper';

export const redirectEpic = (options: {
    when: ActionCreator | ActionCreator[];
    from: string | string[];
    to: string;
}): RootEpic => {
    return (action$, state$) =>
        action$.pipe(
            filter(isActionOf(options.when)),
            filter(() => isRouteMatch(state$.value.router.location, options.from)),
            mergeMap(() => of(push(options.to)))
        );
};
