import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { jobUpdateAsync } from '../../../../logic/features/jobs/job.actions';
import { JobCreateModel } from '../../../../models/jobs/job-create.model';
import { CardWrapper } from '../../../shared/components/bootstrap/cards/CardWrapper';
import { Loader } from '../../../shared/components/loader/Loader';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';
import { useFormButton } from '../../../shared/hooks/form-button.hook';
import { JobDetailsForm } from '../components/forms/JobDetailsForm';
import { useActiveJob } from '../hooks/active-job.hook';

export const JobEditPage = (): React.ReactElement<any, any> | null => {
    const { job, fetch, update } = useActiveJob();

    const dispatch = useDispatch();
    const onSubmit = useCallback(
        (values: JobCreateModel) => {
            dispatch(
                jobUpdateAsync.request(values, { redirectToPublish: true, id: values.job.id })
            );
        },
        [dispatch]
    );

    const onSaveDraft = useCallback(
        (values: JobCreateModel) => {
            dispatch(
                jobUpdateAsync.request(values, { redirectToPublish: false, id: values.job.id })
            );
        },
        [dispatch]
    );

    const { onCancel } = useFormButton();

    return (
        <ApplicationPageContent>
            <Loader state={fetch} useCard={true}>
                <CardWrapper>
                    <JobDetailsForm
                        job={job}
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                        onSaveDraft={onSaveDraft}
                        state={update}
                    />
                </CardWrapper>
            </Loader>
        </ApplicationPageContent>
    );
};
