import { useMemo } from 'react';
import { EventRequest } from 'src/models/events/event-request.model';
import { CareerHubEvent } from 'src/models/events/event.model';
import { useEventsEnabled } from '../../events/hooks/events-enabled.hook';
import { SkillBadgeList } from './SkillBadgeList';

interface Props {
    entity: EventRequest | CareerHubEvent;
}

export const SkillDetailsDisplay = ({ entity }: Props): React.ReactElement<any, any> | null => {
    const { isSkillsEnabled } = useEventsEnabled();

    const showPrimary = useMemo(
        () => entity.primarySkillIds?.length || 0 > 0,
        [entity.primarySkillIds?.length]
    );
    const showSecondary = useMemo(
        () => entity.secondarySkillIds?.length || 0 > 0,
        [entity.secondarySkillIds?.length]
    );

    if (!isSkillsEnabled) {
        return null;
    }

    return (
        <>
            {showPrimary && (
                <>
                    <h4 className="font-weight-normal">Primary skills</h4>
                    <div className="mb-3">
                        <SkillBadgeList ids={entity.primarySkillIds} />{' '}
                    </div>
                </>
            )}
            {showSecondary && (
                <>
                    <h4 className="font-weight-normal">Secondary skills</h4>
                    <div className="mb-3">
                        <SkillBadgeList ids={entity.secondarySkillIds} />
                    </div>
                </>
            )}
        </>
    );
};
