import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { HookForm } from 'src/ui/shared/components/forms/HookForm';
import { LoaderSpinner } from 'src/ui/shared/components/loader/LoaderSpinner';
import { emailPatternRule } from 'src/ui/shared/helpers/validation.helper';
import { useFormDefault } from 'src/ui/shared/hooks/form-default.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import {
    emailClaimsAddAsync,
    emailClaimsShowModal,
} from 'src/logic/features/email-claims/email-claims.actions';

export const ContactEmailAddModal = (): React.ReactElement<any, any> | null => {
    const { modal, emailAdd, userInfoFetch, emailAddSuccess } = useRootSelector(
        state => state.emailClaims
    );
    const dispatch = useDispatch();

    const formMethods = useFormDefault<{ email: string }>({
        error: emailAdd.error || userInfoFetch.error,
        defaultValues: {},
    });

    const onSubmit = (data: { email: string }) => {
        dispatch(emailClaimsAddAsync.request(data));
    };

    const onHide = () => {
        dispatch(emailClaimsShowModal({ show: false }));
    };

    return (
        <Modal show={modal.show} onHide={onHide} animation={false}>
            <HookForm {...formMethods} onSubmit={onSubmit}>
                <HookForm.ErrorAlert error={emailAdd.error || userInfoFetch.error} />
                <Modal.Header>
                    <Modal.Title>Add email address</Modal.Title>
                </Modal.Header>
                {emailAddSuccess && (
                    <Modal.Body className="pb-0">
                        <div className="mb-3">
                            <span className="pr-2">
                                <LoaderSpinner size="sm" />
                            </span>
                            <span>Checking email confimation.</span>
                        </div>
                        <p>
                            We have just sent you a confirmation link. Please click the link to
                            confirm your email address then continue.
                        </p>
                        <div className="text-right">
                            <Button onClick={onHide} variant="link" className="text-muted">
                                Close
                            </Button>
                        </div>
                    </Modal.Body>
                )}

                {!emailAddSuccess && (
                    <Modal.Body className="pb-0">
                        <HookForm.Input
                            disabled={emailAdd.loading}
                            name="email"
                            label="Email"
                            rules={{ required: true, pattern: emailPatternRule }}
                        />

                        <HookForm.Buttons
                            submitText="Submit"
                            cancelText="Cancel"
                            onCancel={onHide}
                            showSpinner={emailAdd.loading}
                        />
                    </Modal.Body>
                )}
            </HookForm>
        </Modal>
    );
};
