import { Job } from 'src/models/jobs/job.model';
import { useCategoryLabel } from 'src/ui/shared/hooks/category-label.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { useCentralAlreadyPublished } from '../../hooks/central-already-published.hook';
import { useJobCentralMappings } from '../../hooks/job-central-mappings.hook';
import { CentralPublishInfo } from '../publish/CentralPublishInfo';
import { CentralPublishSectionTitle } from '../publish/CentralPublishSectionTitle';
import { CategoryMappings } from './CategoryMappings';

interface Props {
    job: Job;
}

export const CentralPublishJobInfo = ({ job }: Props): React.ReactElement<any, any> | null => {
    const { jobCreateState } = useRootSelector(state => state.central);
    const alreadyPublished = useCentralAlreadyPublished(job.id);
    const typesOfWorkLabel = useCategoryLabel('typesOfWork');
    const occupationsLabel = useCategoryLabel('occupations');
    const { hasJobOccupationMappings, hasTypesOfWorkMappings } = useJobCentralMappings(job);
    const {
        central: {
            publish: { job: jobStrings },
        },
    } = useStrings();

    return (
        <>
            <CentralPublishSectionTitle state={jobCreateState} complete={alreadyPublished}>
                <span>
                    {jobStrings.label} {job.title}
                </span>
            </CentralPublishSectionTitle>
            {!jobCreateState.loading && !alreadyPublished && (
                <>
                    <ul>
                        <li>{jobStrings.bullets.job}</li>
                        <li>{jobStrings.bullets.contact}</li>
                        {hasTypesOfWorkMappings && (
                            <li>
                                {typesOfWorkLabel}
                                <CategoryMappings
                                    options={job.typesOfWork}
                                    categoryKey="typesOfWork"
                                />
                            </li>
                        )}
                        {hasJobOccupationMappings && (
                            <li>
                                {occupationsLabel}
                                <CategoryMappings
                                    options={job.occupations}
                                    categoryKey="occupations"
                                />
                            </li>
                        )}
                    </ul>

                    <p className="mb-0">
                        <CentralPublishInfo />
                    </p>
                </>
            )}
            <hr />
        </>
    );
};
