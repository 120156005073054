import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';

export const useFormSubmissionsEnabled = () => {
    const { value } = useApiConfiguration();

    // setting added in 5.13
    if (value?.settings?.disableForms === undefined) {
        return true;
    }

    return value?.settings?.disableForms === false;
};
