import { Upper } from 'src/ui/shared/components/text/Upper';
import { jobPathCreator } from '../../../../../routes/employer/paths/job.paths';
import { BreadcrumbLink } from '../../../../shared/components/breadcrumbs/BreadcrumbLink';
import { useStrings } from '../../../../shared/hooks/strings.hook';

export const JobListBreadcrumb = (): React.ReactElement<any, any> | null => {
    const {
        nav: {
            application: { jobs },
        },
    } = useStrings();
    return (
        <BreadcrumbLink to={jobPathCreator.listDefault()}>
            <Upper>{jobs}</Upper>
        </BreadcrumbLink>
    );
};
