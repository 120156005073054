import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

interface ClaimFacade {
    email: string[];
    firstName: string;
    lastName: string;
    phone: string;
    countryCode: string;
    sub: string;
}

export const useClaimValues = (): ClaimFacade | undefined => {
    return useRootSelector(state => {
        const claims = state.authentication.oidcUser?.profile;

        let claimEmail: string[] = [];
        if (claims?.email) {
            claimEmail = typeof claims.email === 'string' ? [claims.email] : claims.email;
        }

        return claims
            ? {
                  email: claimEmail,
                  firstName: claims.given_name!,
                  lastName: claims.family_name!,
                  phone: claims.phone_number!,
                  countryCode: state.configuration.value.countryCode,
                  sub: claims.sub,
              }
            : undefined;
    });
};
