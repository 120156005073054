import { useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useFormError } from 'src/ui/shared/hooks/form-error.hook';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Markdown } from '../../details/Markdown';
import { HookControlProps } from '../HookForm';
import ControlHelpText from '../shared/ControlHelpText';
import ControlValidationDisplay from '../shared/ControlValidationDisplay';
import { HookFormPopover } from '../shared/HookFormPopover';

interface HookCheckboxProps extends HookControlProps {
    defaultTrue?: boolean;
    includeEmptyLabel?: boolean;
    formGroupClassName?: string;
}

export const CheckboxControl = (props: HookCheckboxProps): React.ReactElement<any, any> | null => {
    const {
        rules,
        name,
        errorName,
        disabled,
        label,
        helpText,
        defaultTrue,
        tabIndex,
        popover,
        includeEmptyLabel,
        formGroupClassName,
        errorIndex,
    } = props;
    const { register, watch, setValue, control } = useFormContext();

    const controlError = useFormError(errorName || name, errorIndex);
    const innerRef = control._fields[name];
    const checked = !!watch(name);
    const uniqueIdForControl = `form-checkbox-${name}`;

    useDeepCompareEffect(() => {
        register(name, rules);
    }, [register, name, rules]);

    useEffect(() => {
        if (defaultTrue) {
            setValue(name, true);
        }
    }, [setValue, defaultTrue, name]);

    const isStringOrStringArray = useMemo(() => {
        if (typeof label === 'string') {
            return true;
        }

        return Array.isArray(label) && label.length > 0 && label.every(i => typeof i === 'string');
    }, [label]);

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        setValue(name, event.target.checked, {
            shouldDirty: true,
            shouldValidate: true,
        });
    }

    return (
        <Form.Group className={formGroupClassName}>
            {includeEmptyLabel && <label>&nbsp;</label>}
            <div className="form-check">
                <input
                    id={uniqueIdForControl}
                    type="checkbox"
                    disabled={disabled || innerRef?._f.ref.disabled}
                    tabIndex={tabIndex}
                    checked={checked}
                    onChange={onChange}
                    className={`form-check-input ${controlError ? 'is-invalid' : ''}`}
                />

                {/* Checkboxes are currently the only label that accepts markdown as a valid type */}
                <label
                    className="form-check-label d-flex align-items-start"
                    htmlFor={uniqueIdForControl}
                >
                    <div>
                        {isStringOrStringArray ? (
                            <Markdown source={label as string | string[]} />
                        ) : (
                            label
                        )}

                        <ControlHelpText
                            helpText={helpText}
                            error={controlError}
                            className="d-block"
                        />
                    </div>
                    {popover && <HookFormPopover name={name} popover={popover} />}
                </label>
                {/* Annoyingly, it's easier to keep this here because of the bootstrap styles - may fix later (it targets direct children) */}
                <ControlValidationDisplay error={controlError} />
            </div>
        </Form.Group>
    );
};
