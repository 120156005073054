import { Job } from '../../../../models/jobs/job.model';
import { ListGroupWrapper } from '../../../shared/components/lists/ListGroupWrapper';
import { Loader } from '../../../shared/components/loader/Loader';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';
import { ListPaginationWrapper } from '../../../shared/components/page-components/ListPaginationWrapper';
import { useActiveEntityList } from '../../../shared/hooks/active-entity-list.hook';
import { JobListGroupItem } from '../components/list/JobListGroupItem';
import { JobListStatusFilter } from '../components/list/JobListStatusFilter';

export const JobListPage = (): React.ReactElement<any, any> | null => {
    const { entities: jobs, fetch, pagination } = useActiveEntityList<Job>(state => state.jobs);

    return (
        <ApplicationPageContent className="job-list-page">
            <ListPaginationWrapper pagination={pagination}>
                <JobListStatusFilter />
            </ListPaginationWrapper>
            <Loader state={fetch} useCard={true}>
                <ListGroupWrapper items={jobs}>
                    {jobs.map(job => (
                        <JobListGroupItem key={job.id} job={job} />
                    ))}
                </ListGroupWrapper>
            </Loader>
        </ApplicationPageContent>
    );
};
