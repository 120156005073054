import { BreadcrumbLink } from '../../../../shared/components/breadcrumbs/BreadcrumbLink';
import { registerPaths } from '../../../../../routes/register/register.paths';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';

export const RegisterStartBreadcrumb = (): React.ReactElement<any, any> | null => {
    const {
        register: { title },
    } = useStrings();

    return <BreadcrumbLink to={registerPaths.start}>{title}</BreadcrumbLink>;
};
