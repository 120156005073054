import { zodResolver } from '@hookform/resolvers/zod';
import { Component, ReactNode, createContext, useContext } from 'react';
import { Col, Form } from 'react-bootstrap';
import {
    FieldPath,
    FieldValues,
    FormProvider,
    FormProviderProps,
    RegisterOptions,
} from 'react-hook-form';
import { HookFormPrompt } from './HookFormPrompt';
import { CheckboxControl } from './controls/CheckboxControl';
import { HiddenIdControl } from './controls/HiddenIdControl';
import { HtmlControl } from './controls/HtmlControl';
import { InputControl } from './controls/InputControl';
import { DisabledInputControl } from './controls/ReadonlyControl';
import { RegionControl } from './controls/RegionControl';
import { SocialControl } from './controls/SocialControl';
import { TextareaControl } from './controls/TextareaControl';
import { CropperControl } from './controls/cropper/CropperControl';
import { DatePickerControl } from './controls/datepicker/DatePickerControl';
import { DatePickerLocalControl } from './controls/datepicker/DatePickerLocalControl';
import { EventBookingControl } from './controls/event-booking/EventBookingControl';
import { EventLocationControl } from './controls/event-location/EventLocationControl';
import { RadioControl } from './controls/radio/RadioControl';
import { CategoryMultiSelectControl } from './controls/react-selects/CategoryMultiSelectControl';
import { CategorySelectControl } from './controls/react-selects/CategorySelectControl';
import { ContactSelectControl } from './controls/react-selects/ContactSelectControl';
import { CountrySelectControl } from './controls/react-selects/CountrySelectControl';
import { EndorsementMultiSelectControl } from './controls/react-selects/EndorsementMultiSelectControl';
import { EventTypeSelectControl } from './controls/react-selects/EventTypeSelectControl';
import { MultiSelectControl } from './controls/react-selects/MultiSelectControl';
import { SingleSelectControl } from './controls/react-selects/SingleSelectControl';
import { SkillMultiSelectControl } from './controls/react-selects/SkillMultiSelectControl';
import { WorkGroupSelectControl } from './controls/react-selects/WorkGroupSelectControl';
import { SelectControl } from './controls/selects/SelectControl';
import { HookFormButtons } from './shared/HookFormButtons';
import { HookFormErrorAlert } from './shared/HookFormErrorAlert';
import { HookFormErrorSpan } from './shared/HookFormErrorSpan';
import { TimeZoneSelectControl } from './controls/react-selects/TimeZoneSelectControl';

export type HookFormRules<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<
    RegisterOptions<TFieldValues, TName>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
>;

export interface HookControlProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
    label?: React.ReactNode;
    removeLabel?: boolean;
    name: string;
    errorName?: string;
    errorIndex?: number;
    helpText?: string | ReactNode;
    placeholder?: string;
    rules?: HookFormRules<TFieldValues, TName>;
    disabled?: boolean;
    tabIndex?: number;
    autoFocus?: boolean;
    popover?: string | string[];
}

interface HookFormContextProps<TFormData extends FieldValues> {
    onSubmit: (data: TFormData) => any;
    onChange?: ((event: React.FormEvent<HTMLFormElement>) => void) | undefined;
    // this is a quick fix solution. You can't have more than one prompt in the view
    // at the same time, which happens if you have more than one form on the page
    // this is actually really rare in this app, so I've added an option to disable
    // the prompt. The proper solution would probably be to use a Context at a root
    // level to track all properties across all forms, or something like that
    disablePrompt?: boolean;
    zod?: Parameters<typeof zodResolver>[0];
    disabled?: boolean;
}

type Props<TFormData extends FieldValues> = FormProviderProps<TFormData> &
    HookFormContextProps<TFormData>;

const CustomFormContext = createContext<{
    zod?: Parameters<typeof zodResolver>[0];
    disabled?: boolean;
}>({ zod: undefined, disabled: undefined });
export const useCustomFormContext = () => useContext(CustomFormContext);

export class HookForm<TFormData extends FieldValues> extends Component<Props<TFormData>> {
    // Controls
    static Input = InputControl;
    static Select = SelectControl;
    static Textarea = TextareaControl;

    static DatePicker = DatePickerControl;
    static DatePickerLocal = DatePickerLocalControl;
    static Checkbox = CheckboxControl;
    static Radio = RadioControl;
    static MultiSelect = MultiSelectControl;
    static SingleSelect = SingleSelectControl;

    // custom select controls
    static CountrySelect = CountrySelectControl;
    static CategoryMultiSelect = CategoryMultiSelectControl;
    static ContactSelect = ContactSelectControl;
    static CategorySelect = CategorySelectControl;
    static WorkGroupSelect = WorkGroupSelectControl;
    static EventTypeSelect = EventTypeSelectControl;
    static SkillMultiSelect = SkillMultiSelectControl;
    static EndorsementMultiSelect = EndorsementMultiSelectControl;
    static TimeZoneSelect = TimeZoneSelectControl;

    // Somewhat Different Controls
    static HiddenId = HiddenIdControl;
    static DisabledInput = DisabledInputControl;
    static Social = SocialControl;
    static Region = RegionControl;
    static EventLocation = EventLocationControl;
    static EventBooking = EventBookingControl;
    static Cropper = CropperControl;
    static Html = HtmlControl;

    // other
    static Buttons = HookFormButtons;
    static ErrorAlert = HookFormErrorAlert;
    static ErrorSpan = HookFormErrorSpan;
    static Row = Form.Row;
    static Col = Col;

    public render() {
        return (
            <CustomFormContext.Provider
                value={{ zod: this.props.zod, disabled: this.props.disabled }}
            >
                <FormProvider {...this.props}>
                    {!this.props.disablePrompt && <HookFormPrompt />}
                    <form
                        onSubmit={this.props.handleSubmit(this.props.onSubmit)}
                        onChange={this.props.onChange}
                    >
                        {this.props.children}
                    </form>
                </FormProvider>
            </CustomFormContext.Provider>
        );
    }
}
