import { useCallback, useEffect, useState } from 'react';

export const useTimer = (delay = 0) => {
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [isDelaying, setIsDelaying] = useState(false);

    const triggerDelay = useCallback(
        (action?: () => void) => {
            setIsDelaying(true);

            const timeout = setTimeout(() => {
                setTimer(t => {
                    if (t) clearTimeout(t);
                    return null;
                });
                if (action) action();
                setIsDelaying(false);
            }, delay);
            setTimer(t => {
                if (t) clearTimeout(t);
                return timeout;
            });
        },
        [delay]
    );

    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);

    return { triggerDelay, isDelaying };
};
