import { LoaderSpinner } from 'src/ui/shared/components/loader/LoaderSpinner';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { LOGO_DISPLAY_SIZE } from '../../constants/logo.constants';

interface Props {
    url: string;
}

export const OrganisationLogoDisplay = ({ url }: Props): React.ReactElement<any, any> => {
    const state = useRootSelector(state => state.organisation.logoState);

    return (
        <div style={{ width: `${LOGO_DISPLAY_SIZE}px`, height: `${LOGO_DISPLAY_SIZE}px` }}>
            {state?.loading ? (
                <div className="d-flex h-100 align-items-center justify-content-center">
                    <LoaderSpinner />
                </div>
            ) : (
                <img
                    src={url}
                    style={{
                        maxWidth: `${LOGO_DISPLAY_SIZE}px`,
                        maxHeight: `${LOGO_DISPLAY_SIZE}px`,
                    }}
                />
            )}
        </div>
    );
};
