import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import {
    ENTITY_NOTE_EVENT_TYPE_OPTIONS,
    EntityNoteEventType,
    entityNoteEventTypeMeta,
} from 'src/models/entity-notes/entity-note-type.model';
import { EntityNote } from 'src/models/entity-notes/entity-note.model';
import { LoaderState } from 'src/models/errors/error.model';
import { useEndorsementsIsEnabled } from 'src/ui/features/endorsements/hooks/endorsements-is-enabled.hook';
import { AlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/AlertWrapper';
import { HookForm } from 'src/ui/shared/components/forms/HookForm';
import { SelectOption } from 'src/ui/shared/components/forms/controls/selects/SelectControl';
import { dateHelper } from 'src/ui/shared/helpers/date.helper';
import { createMaxLengthRule } from 'src/ui/shared/helpers/validation.helper';
import { useFormDefault } from 'src/ui/shared/hooks/form-default.hook';
import { useEventsEnabled } from '../../hooks/events-enabled.hook';

interface Props {
    eventId: number;
    onSubmit: (data: EntityNote) => void;
    onCancel?: () => void;
    state: LoaderState;
    entityNote?: EntityNote;
}

export const EventChangeRequestForm = ({
    onSubmit,
    onCancel,
    state,
    entityNote,
    eventId,
}: Props): React.ReactElement<any, any> | null => {
    const isActioned = useMemo(() => entityNote && entityNote.action !== 'None', [entityNote]);
    const { isSkillsEnabled } = useEventsEnabled();
    const { isEnabled: endorsementsEnabled } = useEndorsementsIsEnabled();

    const formMethods = useFormDefault<EntityNote>({
        error: state.error,
        defaultValues: entityNote || {
            attachedToEntityId: eventId,
            publishDate: dateHelper.formatInUtc(new Date()),
        },
        disabled: isActioned,
    });

    const options: SelectOption<EntityNoteEventType>[] = useMemo(() => {
        return ENTITY_NOTE_EVENT_TYPE_OPTIONS.filter(
            x => x !== 'EventSkillsChangeRequest' || isSkillsEnabled
        )
            .filter(x => x !== 'EventEndorsementsChangeRequest' || endorsementsEnabled)
            .map(x => ({
                label: entityNoteEventTypeMeta[x],
                value: x,
            }));
    }, [endorsementsEnabled, isSkillsEnabled]);

    return (
        <HookForm {...formMethods} onSubmit={onSubmit}>
            <HookForm.ErrorAlert error={state.error} />
            {isActioned && (
                <AlertWrapper icon={faInfoCircle} variant="info">
                    This change request has already been actioned, and cannot be edited.
                </AlertWrapper>
            )}

            <HookForm.HiddenId />
            <HookForm.HiddenId name="attachedToEntityId" />
            <HookForm.HiddenId name="publishDate" />

            <HookForm.Row>
                <HookForm.Col xs={12} md={6}>
                    <HookForm.Select
                        label="Event section"
                        name="type"
                        allowEmpty={true}
                        options={options}
                        rules={{ required: true }}
                    />
                </HookForm.Col>
            </HookForm.Row>

            <HookForm.Textarea
                label="Description"
                name="body"
                minRows={3}
                rules={{ required: true, maxLength: createMaxLengthRule(4000) }}
            />

            <HookForm.Buttons onCancel={onCancel} showSpinner={state.loading} />
        </HookForm>
    );
};
