import { useMemo } from 'react';
import { useOrganisation } from 'src/ui/features/organisation/hooks/organisation.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

export const useCentralAlreadyPublished = (id: number) => {
    return useRootSelector(state => !!state.central.publishedEntities[id]);
};

export const useCentralAlreadyPublishedCentralId = (id: number | undefined) => {
    const { centralOrganisationId: accessTokenCentralOrganisationId } = useOrganisation();

    const alreadyPublishedId = useRootSelector(state => {
        if (!id) {
            return undefined;
        }

        return state.central.publishedEntities[id]
            ? state.central.publishedEntities[id].centralId
            : undefined;
    });

    const centralOrganisationId = useMemo(
        () => accessTokenCentralOrganisationId || alreadyPublishedId,
        [accessTokenCentralOrganisationId, alreadyPublishedId]
    );

    return centralOrganisationId;
};

export const useIsCentralOrganisationOrAlreadyPublished = () => {
    const { organisation } = useOrganisation();
    const centralOrganisationId = useCentralAlreadyPublishedCentralId(organisation?.id);

    return useMemo(() => !!centralOrganisationId, [centralOrganisationId]);
};
