import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Contact } from 'src/models/contacts/contact.model';
import { useClaimValues } from 'src/ui/features/authentication/hooks/claim-values.hook';
import { HookForm } from 'src/ui/shared/components/forms/HookForm';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { ContactEmailAppend } from './ContactEmailAppend';

interface Props {
    contact?: Partial<Contact>;
    isRegistration?: boolean;
}

export const ContactEmailControl = ({
    contact,
    isRegistration,
}: Props): React.ReactElement<any, any> | null => {
    const isEdit = !!contact;

    const claimValues = useClaimValues();

    const {
        contact: { form },
    } = useStrings();

    // just have to show this error somewhere... no matter where I put it it's going to look trash. (it shows an error for the ContactEmailAppend component)
    const userInfoError = useRootSelector(state => state.emailClaims.userInfoFetch.error);

    return (
        <>
            {isRegistration && claimValues && claimValues.email.length > 1 ? (
                <HookForm.Select
                    name="email"
                    {...form.email}
                    autoFocus={true}
                    options={claimValues.email}
                    append={isRegistration ? <ContactEmailAppend /> : undefined}
                />
            ) : (
                <HookForm.Input
                    name="email"
                    {...form.email}
                    append={isRegistration ? <ContactEmailAppend /> : undefined}
                    autoFocus={true}
                    disabled={isEdit}
                    readonly={isRegistration}
                />
            )}
            {userInfoError && (
                <div className="text-danger">
                    <Icon icon={faExclamationTriangle}>{userInfoError.message}</Icon>
                </div>
            )}
        </>
    );
};
