import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { eventRequestCreateAsync } from 'src/logic/features/event-unions/event-union.actions';
import { EventRequest } from 'src/models/events/event-request.model';
import { CardWrapper } from 'src/ui/shared/components/bootstrap/cards/CardWrapper';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { ApplicationPageContent } from 'src/ui/shared/components/page-components/ApplicationPageContent';
import { useActiveEntity } from 'src/ui/shared/hooks/active-entity.hook';
import { useFormButton } from 'src/ui/shared/hooks/form-button.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { EventRequestForm, EventRequestFormResponse } from '../components/forms/EventRequestForm';

export const EventCopyPage = (): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();

    const onSubmit = (value: EventRequestFormResponse) => {
        dispatch(eventRequestCreateAsync.request(value, { redirectToPublish: true }));
    };

    const onSaveDraft = (value: EventRequestFormResponse) => {
        dispatch(eventRequestCreateAsync.request(value, { redirectToPublish: false }));
    };

    const { onCancel } = useFormButton();

    const { create } = useRootSelector(state => state.eventUnions);
    const { fetch, entity } = useActiveEntity(state => state.eventUnions);

    const duplicatedEventRequest = useMemo(() => {
        if (!entity?.event) {
            return;
        }

        const {
            title,
            eventTypeId,
            summary,
            details,
            bookingType,
            bookingLimit,
            externalWebsiteUrl,
            externalWebsiteName,
            externalInstructions,
            primarySkillIds,
            secondarySkillIds,
            id: eventId,
        } = entity.event;

        const eventRequestBookingType =
            bookingType === 'External'
                ? 'External'
                : bookingType === 'SelfManaged'
                ? 'Internal'
                : undefined;

        const er: Partial<EventRequest> = {
            title,
            eventTypeId,
            summary,
            details,
            bookingType: eventRequestBookingType,
            bookingLimit,
            bookingInstructions: externalInstructions,
            bookingName: externalWebsiteName,
            bookingUrl: externalWebsiteUrl,
            duplicateFromEventId: eventId,
            primarySkillIds,
            secondarySkillIds,
        };
        return er;
    }, [entity?.event]);

    return (
        <ApplicationPageContent>
            <Loader state={fetch} useCard={true}>
                <CardWrapper>
                    <EventRequestForm
                        onSubmit={onSubmit}
                        onSaveDraft={onSaveDraft}
                        onCancel={onCancel}
                        state={create}
                        eventRequest={duplicatedEventRequest as any}
                    />
                </CardWrapper>
            </Loader>
        </ApplicationPageContent>
    );
};
