import { replace } from 'connected-react-router';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { authenticationSetRedirect } from 'src/logic/features/authentication/authentication.actions';
import { registerPaths } from 'src/routes/register/register.paths';
import { useAuthentication } from '../hooks/authentication.hook';

interface Props {
    children: React.ReactNode;
}

export const CareerHubRegistrationRedirectGuard = ({
    children,
}: Props): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    const { identities, hasOidcUser, identityListState, identitySetUserFetching, initComplete } =
        useAuthentication();

    const shouldRedirect = useMemo(() => {
        if (!initComplete) {
            return false;
        }

        if (identityListState.loading || identityListState.error) {
            return false;
        }

        if (identitySetUserFetching) {
            return false;
        }

        return hasOidcUser && identities.filter(x => !x.isDisabled).length === 0;
    }, [
        hasOidcUser,
        identities,
        identityListState.error,
        identityListState.loading,
        identitySetUserFetching,
        initComplete,
    ]);

    const { pathname, search } = useLocation();

    useEffect(() => {
        if (shouldRedirect) {
            dispatch(authenticationSetRedirect({ to: pathname + search }));
            dispatch(replace(registerPaths.start));
        }
    }, [dispatch, pathname, search, shouldRedirect]);

    return <>{children}</>;
};
