import { DetailsProperty } from './DetailsProperty';

interface Props {
    label: string;
    value?: string[];
}

export const DetailsPropertyArray = ({
    label,
    value,
}: Props): React.ReactElement<any, any> | null => {
    return value && value.length > 0 ? (
        <DetailsProperty label={label}>
            <div className="mb-3">
                {value.map((item, index) => (
                    <div key={index}>{item}</div>
                ))}
            </div>
        </DetailsProperty>
    ) : null;
};
