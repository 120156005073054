import { getClientConfig } from 'src/clients-internal/configuration.init';
import { ApiResponse } from '../../../models/api/response.model';
import { ObsApiExpected } from '../../../models/api/service.model';
import { Individual } from '../../../models/individual/individual.model';
import { fetchHelper } from '../../helpers/fetch.helper';

const basePath = () => `${getClientConfig().careerHubBasePath}/services/employers/v1/individual`;

export function get(): ObsApiExpected<ApiResponse<Individual>> {
    return fetchHelper.get(basePath());
}

export function update(individual: Individual): ObsApiExpected<ApiResponse<Individual>> {
    return fetchHelper.put(basePath(), individual);
}
