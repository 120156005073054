import { Container } from 'react-bootstrap';
import { ApplicationLayoutContent } from './ApplicationLayoutContent';
import { ApplicationLayoutSideNav } from './ApplicationLayoutSideNav';

interface Props {
    children?: React.ReactNode;
}

export const ApplicationLayoutContainer = ({
    children,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <Container fluid className="p-0 d-flex">
            {children}
        </Container>
    );
};

ApplicationLayoutContainer.SideNav = ApplicationLayoutSideNav;
ApplicationLayoutContainer.Content = ApplicationLayoutContent;
