import { createAction, createAsyncAction } from 'typesafe-actions';
import { ApiResponse, ApiSearchResponse } from '../../../models/api/response.model';
import { ContactIdentity, IndividualIdentity } from '../../../models/authentication/identity.model';
import { ErrorNormalized } from '../../../models/errors/error.model';
import { Individual } from '../../../models/individual/individual.model';
import { OrganisationSimilar } from '../../../models/organisation/organisation-similar.model';
import { Organisation } from '../../../models/organisation/organisation.model';
import { RegisterName } from '../../../models/register/register-name.model';
import { RegisterOrganisation } from '../../../models/register/register-organisation.model';
import { RegisterRequestAccess } from '../../../models/register/register-request-access.model';
import { ActiveListState } from '../../../models/store-models/entity-list-state.model';
import { SimilarOrganisationsRequest } from './register.service';

export const registerNameStore = createAction('REGISTER_NAME_STORE')<RegisterName>();
export const registerDetailsStore = createAction('REGISTER_DETAILS_STORE')<{
    organisation: Organisation;
    logo: File | undefined;
}>();
export const registerSimilarListSetActive = createAction('REGISTER_SIMILAR:SET_ACTIVE')<
    ActiveListState<SimilarOrganisationsRequest>
>();
export const registerResetState = createAction('REGISTER_RESET_STATE')();

export const registerSimilarAsync = createAsyncAction(
    'ORGANISATION_SIMILAR:REQUEST',
    'ORGANISATION_SIMILAR:SUCCESS',
    'ORGANISATION_SIMILAR:FAILURE',
    'ORGANISATION_SIMILAR:CANCEL'
)<
    SimilarOrganisationsRequest,
    ApiSearchResponse<OrganisationSimilar, SimilarOrganisationsRequest>,
    [ErrorNormalized, SimilarOrganisationsRequest],
    undefined
>();

export const registerLegacyLoginShow = createAction('LEGACY_LOGIN:SHOW')<{ show: boolean }>();
export const registerRequestAccessShow = createAction('ORGANISATION_REQUEST_ACCESS:SHOW')<{
    show: boolean;
}>();

export const registerRequestAccessAsync = createAsyncAction(
    'ORGANISATION_REQUEST_ACCESS:REQUEST',
    'ORGANISATION_REQUEST_ACCESS:SUCCESS',
    'ORGANISATION_REQUEST_ACCESS:FAILURE'
)<RegisterRequestAccess, ApiResponse<RegisterRequestAccess>, ErrorNormalized>();

export const registerOrganisationAsync = createAsyncAction(
    'REGISTER_ORGANISATION:REQUEST',
    'REGISTER_ORGANISATION:SUCCESS',
    'REGISTER_ORGANISATION:FAILURE'
)<RegisterOrganisation, ApiResponse<ContactIdentity>, ErrorNormalized>();

export const registerIndividualAsync = createAsyncAction(
    'REGISTER_INDIVIDUAL:REQUEST',
    'REGISTER_INDIVIDUAL:SUCCESS',
    'REGISTER_INDIVIDUAL:FAILURE'
)<Individual, ApiResponse<IndividualIdentity>, ErrorNormalized>();
