import { CountryCode } from 'src/clients-internal/configuration.model';
import { DatePickerLocale } from 'src/ui/shared/components/forms/controls/datepicker/DatePicker.helper';

const REGION_CODES_AU = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'] as const;
type RegionCodeAuTuple = typeof REGION_CODES_AU;
export type RegionCodeAu = RegionCodeAuTuple[number];

const REGION_CODES_NZ = [
    'Auckland',
    'Bay of Plenty',
    'Canterbury',
    'Chatham Islands',
    'Gisborne',
    'Hawkes Bay',
    'Manawatu-Wanganui',
    'Marlborough',
    'Nelson',
    'Northland',
    'Otago',
    'Southland',
    'Taranaki',
    'Tasman',
    'Waikato',
    'Wellington',
    'Westcoast',
] as const;
type RegionCodeNzTuple = typeof REGION_CODES_NZ;
type RegionCodeNz = RegionCodeNzTuple[number];

const REGION_CODES_GB = [
    'East of England',
    'East Midlands',
    'London',
    'North West',
    'North East',
    'Northern Ireland',
    'Scotland',
    'South East',
    'South West',
    'Wales',
    'West Midlands',
    'Yorkshire and the Humber',
] as const;
type RegionCodeGbTuple = typeof REGION_CODES_GB;
type RegionCodeGb = RegionCodeGbTuple[number];

export type LocalRegionCodes = RegionCodeAu | RegionCodeNz | RegionCodeGb;

interface RegionMeta {
    regionLabel: string;
    regions: string[] | { [key in RegionCodeAu]: string };
    dateLocale: DatePickerLocale;
}

export const COUNTRY_REGION_META: { [key in CountryCode]: RegionMeta | undefined } = {
    AU: {
        regionLabel: 'State',
        regions: {
            ACT: 'Australian Capital Territory',
            NSW: 'New South Wales',
            NT: 'Northern Territory',
            QLD: 'Queensland',
            SA: 'South Australia',
            TAS: 'Tasmania',
            VIC: 'Victoria',
            WA: 'Western Australia',
        },
        dateLocale: 'en-AU',
    },
    GB: {
        regionLabel: 'Region',
        regions: [...REGION_CODES_GB],
        dateLocale: 'en-GB',
    },
    CA: {
        regionLabel: 'Region',
        regions: [],
        dateLocale: 'en-CA',
    },
    ZA: {
        regionLabel: 'Region',
        regions: [],
        dateLocale: 'en-ZA',
    },
    US: {
        regionLabel: 'Region',
        regions: [],
        dateLocale: 'en-US',
    },
};
