import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { push } from 'connected-react-router';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { registerNameStore } from 'src/logic/features/register/register.actions';
import { RegisterName } from 'src/models/register/register-name.model';
import { registerPaths } from 'src/routes/register/register.paths';
import { AlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/AlertWrapper';
import { Markdown } from 'src/ui/shared/components/details/Markdown';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { ThinPageContent } from '../../../shared/components/page-components/ThinPageContent';
import { RegisterStartBreadcrumb } from '../components/breadcrumbs/RegisterStartBreadcrumb';
import { RegisterNameForm } from '../components/forms/RegisterNameForm';
import { RegisterWizardWrapper } from '../components/nav/RegisterWizardWrapper';

export const RegisterNamePage = (): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    const {
        register: {
            name: { title, limitAlert },
            wizard: { buttons },
        },
    } = useStrings();

    const onSubmit = useCallback(
        (data: RegisterName) => {
            dispatch(registerNameStore(data));
        },
        [dispatch]
    );

    const onCancel = () => {
        dispatch(push(registerPaths.start));
    };

    const storedDetails = useRootSelector(state => state.register.name);

    const { length } = useRootSelector(state => state.authentication.identities);
    const isAtLimit = useMemo(() => length > 4, [length]);

    return (
        <ThinPageContent>
            <h1>
                <RegisterStartBreadcrumb />
                {title}
            </h1>

            <RegisterWizardWrapper>
                {isAtLimit && (
                    <AlertWrapper icon={faInfoCircle} variant="info">
                        <Markdown source={limitAlert} />
                    </AlertWrapper>
                )}

                <RegisterNameForm
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    cancelText={buttons.back}
                    submitText={buttons.continue}
                    disabled={isAtLimit}
                    registerName={storedDetails || undefined}
                />
            </RegisterWizardWrapper>
        </ThinPageContent>
    );
};
