import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { AuthenticationExpireToast } from './AuthenticationExpireToast';
import { ToastDisplay } from './ToastDisplay';

export const ToastContainer = (): React.ReactElement<any, any> | null => {
    const { expiresSoon, expiresSoonDismissed } = useRootSelector(state => state.authentication);
    const { toasts } = useRootSelector(state => state.session);

    return (
        <div className="py-2 mx-3">
            {expiresSoon && !expiresSoonDismissed && <AuthenticationExpireToast />}
            {toasts.map(toast => (
                <ToastDisplay key={toast.id} toast={toast} />
            ))}
        </div>
    );
};
