import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Individual } from '../../../../../models/individual/individual.model';
import { Details } from '../../../../shared/components/details/Details';
import { DetailsProperty } from '../../../../shared/components/details/DetailsProperty';
import { Icon } from '../../../../shared/components/icon/Icon';
import { useAddress } from '../../../../shared/hooks/address.hook';

interface Props {
    individual: Individual;
}

export const IndividualDetails = ({ individual }: Props): React.ReactElement<any, any> | null => {
    const address = useAddress(individual);

    return (
        <Details
            header={
                <>
                    <h2 className="font-weight-normal">
                        {individual.firstName} {individual.lastName}
                    </h2>
                    <p className="mb-1">
                        <Icon icon={faMapMarkerAlt}>{address}</Icon>
                    </p>
                </>
            }
            properties={<></>}
        >
            <DetailsProperty label="Email">{individual.email}</DetailsProperty>
            <DetailsProperty label="Phone">{individual.phone}</DetailsProperty>
        </Details>
    );
};
