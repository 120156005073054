import { HomePage } from '../../ui/features/content/pages/HomePage';
import { ContentLayout } from '../../ui/shared/components/layout/ContentLayout';
import { LayoutContext } from '../RouteList';

export const homePath = '/';

export const HomeRouteLayout = () => (
    <LayoutContext.Provider value={{ isApplicationLayout: false, isHomePage: true }}>
        <ContentLayout className="home-content-layout">
            <HomePage />
        </ContentLayout>
    </LayoutContext.Provider>
);
