import { DeletedCard } from 'src/ui/shared/components/details/DeletedCard';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { ApplicationPageContent } from 'src/ui/shared/components/page-components/ApplicationPageContent';
import { useActiveEntity } from 'src/ui/shared/hooks/active-entity.hook';
import { EventDetailsTabNav } from '../nav/EventDetailsTabNav';
import { EventCancelledAlert } from './EventCancelledAlert';

interface Props {
    children: React.ReactNode;
}

export const EventDetailsPageContent = ({
    children,
}: Props): React.ReactElement<any, any> | null => {
    const { entity: eventUnion, fetch, deleted } = useActiveEntity(state => state.eventUnions);

    return (
        <ApplicationPageContent>
            {deleted && <DeletedCard />}
            <EventCancelledAlert />

            <Loader state={fetch} useCard={true}>
                {eventUnion && (
                    <div className={eventUnion.event ? `tab-wrapper` : ''}>
                        {eventUnion.event && <EventDetailsTabNav event={eventUnion.event} />}
                        {children}
                    </div>
                )}
            </Loader>
        </ApplicationPageContent>
    );
};
