import { useMemo } from 'react';
import { Badge } from 'react-bootstrap';
import { Job } from '../../../../../models/jobs/job.model';

interface Props {
    job: Job;
}

export const JobStatusBadge = ({ job }: Props): React.ReactElement<any, any> | null => {
    const status = job.status;

    const badgeClass = useMemo(() => {
        switch (job.status) {
            case 'Draft':
            case 'Prelisted':
            case 'Pending':
                return 'outline-secondary';
            case 'Active':
                return 'outline-success';
            case 'Expired':
            case 'Withdrawn':
                return 'outline-danger';
            default:
                return 'outline-secondary';
        }
    }, [job.status]);

    return (
        <Badge variant={badgeClass} className="font-weight-normal">
            {status}
        </Badge>
    );
};
