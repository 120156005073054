import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import 'particles.js';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Job } from 'src/models/jobs/job.model';
import { centralPathCreator } from 'src/routes/employer/paths/central.paths';
import { ButtonLink } from 'src/ui/shared/components/bootstrap/buttons/ButtonLink';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useClient } from 'src/ui/shared/hooks/client.hook';
import { useLogo } from 'src/ui/shared/hooks/logo.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useTimer } from 'src/ui/shared/hooks/timer.hook';
import { useWindowSize } from 'src/ui/shared/hooks/window-size.hook';
import { AustraliaCentralAdvertisementInfo } from './AustraliaCentralAdvertisementInfo';
import { UkCentralAdvertisementInfo } from './UkCentralAdvertisementInfo';

declare const particlesJS: any;

/* particlesJS.load(@dom-id, @path-json, @callback (optional)); */
const loadParticles = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    particlesJS('particles-js', {
        particles: {
            number: {
                value: 110,
                density: {
                    enable: true,
                    value_area: 800,
                },
            },
            color: {
                value: '#ffffff',
            },
            shape: {
                type: 'circle',
                stroke: {
                    width: 0,
                    color: '#000000',
                },
                polygon: {
                    nb_sides: 5,
                },
            },
            opacity: {
                value: 0.1,
                random: false,
                anim: {
                    enable: false,
                    speed: 1,
                    opacity_min: 0.1,
                    sync: false,
                },
            },
            size: {
                value: 5,
                random: true,
                anim: {
                    enable: false,
                    speed: 20,
                    size_min: 0.1,
                    sync: false,
                },
            },
            line_linked: {
                enable: true,
                distance: 150,
                color: '#ffffff',
                opacity: 0.13,
                width: 1,
            },
            move: {
                enable: true,
                speed: 1.3,
                direction: 'none',
                random: false,
                straight: false,
                out_mode: 'bounce',
                attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200,
                },
            },
        },
        interactivity: {
            detect_on: 'canvas',
            events: {
                onhover: {
                    enable: true,
                    mode: 'grab',
                },
                onclick: {
                    enable: true,
                    mode: 'push',
                },
                resize: true,
            },
            modes: {
                grab: {
                    distance: 400,
                    line_linked: {
                        opacity: 0.1,
                    },
                },
                bubble: {
                    distance: 400,
                    size: 40,
                    duration: 2,
                    opacity: 0.05,
                    speed: 3,
                },
                repulse: {
                    distance: 200,
                },
                push: {
                    particles_nb: 4,
                },
                remove: {
                    particles_nb: 2,
                },
            },
        },
        retina_detect: true,
        config_demo: {
            hide_card: true,
            background_color: '#FFF',
            background_image: '',
            background_position: '50% 50%',
            background_repeat: 'no-repeat',
            background_size: 'cover',
        },
    });
};

interface Props {
    job: Job;
}

export const PublishToCentralMessage = ({ job }: Props): React.ReactElement<any, any> | null => {
    const path = centralPathCreator.publish({ jobId: job.id });
    const { src: logoSrc } = useLogo();
    const [loaded, setLoaded] = useState(false);
    const windowSize = useWindowSize();
    const { triggerDelay } = useTimer(200);

    const { countryCode } = useClient();

    const institutionName = useRootSelector(
        state => state.strings.careerHubStrings['institution.name']
    );

    // no one is going to care that this will fix itself when someone resizes
    // but it does, so there!
    useEffect(() => {
        if (!particlesJS || loaded) {
            return;
        }

        loadParticles();
        setLoaded(true);
    }, [loaded]);

    useEffect(() => {
        if (!particlesJS || !loaded) {
            return;
        }
        triggerDelay(loadParticles);
    }, [loaded, triggerDelay, windowSize]);

    return (
        <Card className="mt-4 border-0">
            <Card.Body className="bg-central rounded px-4">
                <div className="particles-container">
                    <div id="particles-js"></div>
                    <Row>
                        <Col
                            sm={12}
                            lg={7}
                            className="text-white d-flex flex-column"
                            style={{
                                fontFamily: '"Open Sans", sans-serif',
                            }}
                        >
                            <div>
                                <strong>{institutionName}</strong> is a member of the CareerHub
                                Central network
                            </div>
                            <div className="my-auto">
                                <div style={{ maxWidth: '230px' }} className="mb-4">
                                    <img src={logoSrc} alt="CareerHub Central" />
                                </div>
                                {countryCode === 'AU' && <AustraliaCentralAdvertisementInfo />}
                                {countryCode === 'GB' && <UkCentralAdvertisementInfo />}
                            </div>
                            <div className="mb-3">
                                <ButtonLink to={path} variant="light" className="text-central">
                                    Publish this job to CareerHub Central
                                    <Icon iconClassName="ml-2" icon={faArrowRight} />
                                </ButtonLink>
                            </div>
                        </Col>
                        <Col sm={12} lg={5} className="text-center mt-5 mt-lg-0">
                            {countryCode === 'AU' && (
                                <img
                                    src="https://cdn.content.careerhub.com.au/_core/australiamap.svg"
                                    alt="CareerHub Central Australia"
                                    style={{ maxHeight: '300px' }}
                                />
                            )}
                            {countryCode === 'GB' && (
                                <img
                                    src="https://cdn.content.careerhub.co.uk/_core/uk-map-home.svg"
                                    alt="CareerHub Central UK"
                                    style={{ maxHeight: '500px' }}
                                />
                            )}
                        </Col>
                    </Row>
                </div>
            </Card.Body>
        </Card>
    );
};
