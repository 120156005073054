import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { push } from 'connected-react-router';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { registerNameStore } from 'src/logic/features/register/register.actions';
import { RegisterName } from 'src/models/register/register-name.model';
import { registerPathCreator, registerPaths } from 'src/routes/register/register.paths';
import { AlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/AlertWrapper';
import { CardWrapper } from 'src/ui/shared/components/bootstrap/cards/CardWrapper';
import { Markdown } from 'src/ui/shared/components/details/Markdown';
import { ListGroupWrapper } from 'src/ui/shared/components/lists/ListGroupWrapper';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { ListPaginationWrapper } from 'src/ui/shared/components/page-components/ListPaginationWrapper';
import { useActiveEntityList } from 'src/ui/shared/hooks/active-entity-list.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { ThinPageContent } from '../../../shared/components/page-components/ThinPageContent';
import { RegisterStartBreadcrumb } from '../components/breadcrumbs/RegisterStartBreadcrumb';
import { RegisterNameForm } from '../components/forms/RegisterNameForm';
import { OrganisationSimilarListItem } from '../components/list/OrganisationSimilarListItem';
import { RequestAccessModal } from '../components/modal/RequestAccessModal';
import { useRequestAccess } from '../hooks/request-access.hook';

export const RegisterRequestAccessPage = (): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const {
        entities: items,
        fetch,
        pagination,
    } = useActiveEntityList(state => state.register.similar);

    const onSubmit = useCallback(
        (data: RegisterName) => {
            setHasSubmitted(true);
            dispatch(registerNameStore(data));
            dispatch(push(registerPathCreator.requestAccessList(data)));
        },
        [dispatch]
    );

    const onCancel = () => {
        dispatch(push(registerPaths.start));
    };

    const {
        register: {
            requestAccess: { title, buttons, similarResults, form },
        },
    } = useStrings();

    const { onRequestAccessClick, requestOrganisationId } = useRequestAccess();

    return (
        <ThinPageContent>
            <h1 className="mb-4">
                <RegisterStartBreadcrumb />
                {title}
            </h1>
            <CardWrapper className="mb-4">
                <RegisterNameForm
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    submitText={buttons.continue}
                    cancelText={buttons.back}
                    disabled={fetch.loading}
                    disableBusinessIdValidator={true}
                    removeEmployerType={true}
                />
            </CardWrapper>
            {hasSubmitted && (
                <Loader state={fetch} useCard={true}>
                    <AlertWrapper variant="info" icon={faInfoCircle}>
                        {/* This is using the same string as what's shown in the form. */}
                        <Markdown source={form.info} />
                    </AlertWrapper>
                    <ListPaginationWrapper pagination={pagination}>
                        <h3>{similarResults.title}</h3>
                    </ListPaginationWrapper>

                    <ListGroupWrapper items={items}>
                        {items.map(i => (
                            <OrganisationSimilarListItem
                                key={i.id}
                                organisation={i}
                                onClick={() => onRequestAccessClick(i.id)}
                            />
                        ))}
                    </ListGroupWrapper>
                </Loader>
            )}
            {requestOrganisationId && <RequestAccessModal organisationId={requestOrganisationId} />}
        </ThinPageContent>
    );
};
