import { faHashtag, faUsers } from '@fortawesome/free-solid-svg-icons';
import { LocationView } from 'src/ui/features/locations/components/LocationView';
import { useWorkGroup } from 'src/ui/features/work-groups/hooks/work-group.hook';
import { JobContractHoursMeta } from '../../../../../models/jobs/job-types.model';
import { Job } from '../../../../../models/jobs/job.model';
import { ExternalLink } from '../../../../shared/components/anchors/ExternalLink';
import { DetailsProperty } from '../../../../shared/components/details/DetailsProperty';
import { DetailsPropertyArray } from '../../../../shared/components/details/DetailsPropertyArray';
import { JobHistoryDetailsProperty } from './JobHistoryDetailsProperty';

interface Props {
    job: Job;
}

export const JobDetailsProperties = ({ job }: Props): React.ReactElement<any, any> | null => {
    const typesOfWork = job.typesOfWork.map(i => i.name);
    const occupations = job.occupations.map(i => i.name);

    const workgroup = useWorkGroup(job.workGroupId);

    return (
        <>
            <h2 className="font-weight-normal">Other Information</h2>
            <DetailsProperty label="Job Reference" icon={faHashtag}>
                {job.externalReference}
            </DetailsProperty>
            {workgroup && !workgroup.isDefault && (
                <DetailsProperty label="Work Group" icon={faUsers}>
                    {workgroup.name}
                </DetailsProperty>
            )}
            <DetailsProperty label="Location">
                {job.locations.map((loc, i) => (
                    <span key={i}>
                        <LocationView value={loc} />
                    </span>
                ))}
            </DetailsProperty>
            <DetailsPropertyArray label="Type of work" value={typesOfWork} />
            <DetailsProperty label="Contract">{job.contractType}</DetailsProperty>
            <DetailsProperty label="Contract Hours">
                {JobContractHoursMeta[job.contractHours]}
            </DetailsProperty>
            <DetailsProperty label="Available Positions">{job.positionsAvailable}</DetailsProperty>
            <DetailsProperty label="Remuneration">{job.remuneration}</DetailsProperty>
            <DetailsProperty label="Commences">{job.commences}</DetailsProperty>
            <DetailsPropertyArray label="Occupations" value={occupations} />
            <DetailsProperty label="Residency">{job.residency}</DetailsProperty>
            {job.url && (
                <DetailsProperty label="Website">
                    <ExternalLink href={job.url} />
                </DetailsProperty>
            )}
            <JobHistoryDetailsProperty job={job} />
        </>
    );
};
