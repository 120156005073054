import {
    CollectionContent,
    ContentKey,
    createElementId,
    navigationIgnoreVariants,
    SiteContextConfig,
    sortByPriorityThenAddedDate,
} from '@careerhub/content-shared';
import { useCallback, useMemo } from 'react';
import { contentPathCreator } from 'src/routes/content/content.paths';
import { useClient } from 'src/ui/shared/hooks/client.hook';
import { useDomPurify } from 'src/ui/shared/hooks/dom-purify.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';

export const useContent = (key: ContentKey) => {
    const { sanitizeHtml } = useDomPurify();

    const { activeCollectionId, context, firstCollectionId, fetchState } = useRootSelector(
        state => state.content[key]
    );

    const { collections, components, sections } = context;

    const activeCollection = useMemo(
        () => collections.find(c => c.id === activeCollectionId),
        [activeCollectionId, collections]
    );

    const { disableApplication } = useClient();

    const {
        meta: { title, disabledApplicationTitle },
    } = useStrings();

    const documentTitle = useMemo(() => {
        const titleToUse = disableApplication ? disabledApplicationTitle : title;
        return activeCollection ? `${titleToUse} - ${activeCollection.title} ` : titleToUse;
    }, [activeCollection, disableApplication, disabledApplicationTitle, title]);

    const count = collections.length;
    const getSectionTitles = useCallback(
        (collection: CollectionContent | undefined) => {
            if (!collection) {
                return [];
            }
            return components
                .filter(v => v.collectionId === collection.id)
                .filter(v => !navigationIgnoreVariants.includes(v.variant))
                .sort((a, b) => sortByPriorityThenAddedDate(a, b, collection.componentPriority))
                .flatMap(v =>
                    sections
                        .filter(s => s.componentId === v.id)
                        .filter(s => !!s.header)
                        .sort((a, b) => sortByPriorityThenAddedDate(a, b, v.sectionPriority))
                )
                .map(s => ({
                    header: s.header!,
                    id: createElementId(s.id),
                    to: contentPathCreator.contentPage(key, collection, s.header),
                }));
        },
        [key, sections, components]
    );

    const firstCollection = useMemo(
        () => collections.find(c => c.id === firstCollectionId),
        [collections, firstCollectionId]
    );

    const orderedCollections = useMemo(() => {
        return collections.sort((a, b) =>
            sortByPriorityThenAddedDate(a, b, context.site?.collectionPriority || [])
        );
    }, [collections, context.site?.collectionPriority]);

    const config: SiteContextConfig = useMemo(() => {
        return {
            sanitizeHtml,
        };
    }, [sanitizeHtml]);

    return {
        activeCollection,
        activeCollectionId,
        context,
        config,
        orderedCollections,
        count,
        documentTitle,
        getSectionTitles,
        firstCollectionId,
        firstCollection,
        fetchState,
    };
};
