import { useMemo } from 'react';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useAllContacts } from '../../contacts/hooks/all-contacts.hook';

export const useEventProviders = (eventId: number) => {
    const { contacts } = useAllContacts();
    const providers = useRootSelector(state => state.eventUnions.providers[eventId] || []);

    const providerContacts = contacts.filter(c => providers.map(p => p.id).includes(c.id));

    // ignores admin providers, and organisation provider
    const isOnlyProvider = useMemo(() => {
        const contactIds = contacts.map(x => x.id);

        return providers
            .filter(x => x.typeName === 'OrganisationContact')
            .every(x => contactIds.includes(x.id));
    }, [contacts, providers]);

    return {
        providerContacts,
        isOnlyProvider,
    };
};
