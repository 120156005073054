import { PageTitle } from '../../../shared/components/page-components/PageTitle';
import { useContent } from '../hooks/content.hook';

export const PrivateContentPageNav = (): React.ReactElement<any, any> | null => {
    const { activeCollection } = useContent('employerProtected');

    // the scrollspy didn't really work here
    return activeCollection ? (
        <PageTitle className="mr-2">{activeCollection.title}</PageTitle>
    ) : null;
};
