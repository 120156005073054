import { ApplicationRouteGuard } from 'src/ui/features/authentication/components/ApplicationRouteGuard';
import { useIsCentralPostingEnabled } from 'src/ui/features/central/hooks/is-central-posting-enabled.hook';
import { useAuthentication } from '../../ui/features/authentication/hooks/authentication.hook';
import { ApplicationLayout } from '../../ui/shared/components/layout/ApplicationLayout';
import { contentPaths } from '../content/content.paths';
import { PrivateContentRouteList } from '../content/ContentRouteList';
import { CareerHubRouter } from '../employer/CareerHubRouter';
import { CentralRouter } from '../employer/CentralRouter';
import { ContactRouter } from '../employer/ContactRouter';
import { EventRouter } from '../employer/EventRouter';
import { FormRouter } from '../employer/FormRouter';
import { IndividualRouter as IndividualRouteList } from '../employer/IndividualRouter';
import { JobRouteList } from '../employer/JobRouter';
import { OrganisationRouter } from '../employer/OrganisationRouter';
import { careerhubPaths } from '../employer/paths/careerhub.paths';
import { centralPaths } from '../employer/paths/central.paths';
import { contactPaths } from '../employer/paths/contact.paths';
import { eventPaths } from '../employer/paths/event.paths';
import { formPaths } from '../employer/paths/form.paths';
import { jobPaths } from '../employer/paths/job.paths';
import { organisationPaths } from '../employer/paths/organisation.paths';
import { settingsPaths } from '../employer/paths/settings.paths';
import { SettingsRouter } from '../employer/SettingsRouter';
import { PrivateIdentityRouter } from '../identity/AuthenticationRouteList';
import { identityPaths } from '../identity/identity.paths';
import { LayoutContext } from '../RouteList';

// it is terrible that this has to exist, be better Craig!
export const applicationBasePaths = [
    jobPaths.list,
    contactPaths.list,
    organisationPaths.details,
    formPaths.submissions,
    formPaths.list,
    contentPaths.protected,
    identityPaths.listAuthenticated,
    settingsPaths.settings,
    centralPaths.publish,
    eventPaths.list,
    careerhubPaths.redirect,
];

export const ApplicationRouteLayout = () => {
    const { isIndividual, isOrganisation } = useAuthentication();
    const isCentralPositingEnabled = useIsCentralPostingEnabled();

    return (
        <ApplicationRouteGuard>
            <LayoutContext.Provider value={{ isApplicationLayout: true, isHomePage: false }}>
                <ApplicationLayout>
                    <JobRouteList />

                    {isIndividual && <IndividualRouteList />}
                    {isOrganisation && (
                        <>
                            <OrganisationRouter />
                            <ContactRouter />
                            <EventRouter />
                            {isCentralPositingEnabled && <CentralRouter />}
                        </>
                    )}
                    <SettingsRouter />
                    <FormRouter />
                    <CareerHubRouter />
                    <PrivateContentRouteList />
                    <PrivateIdentityRouter />
                </ApplicationLayout>
            </LayoutContext.Provider>
        </ApplicationRouteGuard>
    );
};
