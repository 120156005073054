import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { contactPathCreator } from 'src/routes/employer/paths/contact.paths';
import { ContactName } from 'src/ui/features/contacts/components/shared/ContactName';
import { A } from 'src/ui/shared/components/anchors/A';
import { DetailsProperty } from 'src/ui/shared/components/details/DetailsProperty';
import { useEventProviders } from '../../hooks/event-providers.hook';

interface Props {
    eventId: number;
}

export const EventProviderProperty = ({ eventId }: Props): React.ReactElement<any, any> | null => {
    const { providerContacts } = useEventProviders(eventId);

    if (providerContacts.length === 0) {
        return null;
    }

    return (
        <DetailsProperty label="Contact" icon={faUserTie}>
            {providerContacts.map(c => (
                <A key={c.id} href={contactPathCreator.edit(c.id)}>
                    <ContactName contact={c} />
                </A>
            ))}
        </DetailsProperty>
    );
};
