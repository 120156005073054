import { Organisation } from 'src/models/organisation/organisation.model';
import { OrganisationNameDivision } from 'src/ui/features/organisation/components/details/OrganisationNameDivision';
import { Markdown } from 'src/ui/shared/components/details/Markdown';
import { useCategoryLabel } from 'src/ui/shared/hooks/category-label.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useDynamicString, useStrings } from 'src/ui/shared/hooks/strings.hook';
import { useIsCentralOrganisationOrAlreadyPublished } from '../../hooks/central-already-published.hook';
import { useOrganisationCentralCategories } from '../../hooks/organisation-central-categories.hook';
import { CentralPublishInfo } from '../publish/CentralPublishInfo';
import { CentralPublishSectionTitle } from '../publish/CentralPublishSectionTitle';
import { CategoryMappings } from './CategoryMappings';

interface Props {
    organisation: Organisation;
}

export const CentralPublishOrganisationInfo = ({
    organisation,
}: Props): React.ReactElement<any, any> | null => {
    const state = useRootSelector(state => state.central.register);
    const verifiedEmail = useRootSelector(
        state => state.authentication.oidcUser?.profile.preferred_username
    );

    const complete = useIsCentralOrganisationOrAlreadyPublished();
    const {
        central: {
            publish: { organisation: organisationStrings },
        },
    } = useStrings();

    const contactMessage = useDynamicString(organisationStrings.bullets.contact, {
        $email: verifiedEmail || '',
    });

    const industryLabel = useCategoryLabel('industries');
    const { hasOrganisationIndustryMappings } = useOrganisationCentralCategories(organisation);

    return (
        <>
            <CentralPublishSectionTitle state={state} complete={complete}>
                <span className="mr-2">{organisationStrings.label}</span>
                <OrganisationNameDivision organisation={organisation} />
            </CentralPublishSectionTitle>
            {!state.loading && !complete && (
                <>
                    <ul>
                        <li>{organisationStrings.bullets.register}</li>
                        <li className="markdown-list-hack">
                            <Markdown source={contactMessage} />
                        </li>
                        {hasOrganisationIndustryMappings && (
                            <li>
                                <div>
                                    {industryLabel}
                                    <CategoryMappings
                                        options={organisation.industries}
                                        categoryKey="industries"
                                    />
                                </div>
                            </li>
                        )}
                    </ul>

                    <p className="mb-0">
                        <CentralPublishInfo />
                    </p>
                </>
            )}
            <hr />
        </>
    );
};
