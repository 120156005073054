import { getLogoObjectUrl } from 'src/logic/features/register/register.service';
import { SingleImageDropzone } from 'src/ui/shared/components/forms/controls/images/SingleImageControl';
import { useIsCareerHubVersion } from 'src/ui/shared/hooks/is-careerhub-version.hook';
import { LOGO_DISPLAY_SIZE, LOGO_IMAGE_SIZE } from '../../constants/logo.constants';
import { useOrganisationLogoUrl } from '../../hooks/organisation-logo-url.hook';

interface Props {
    onAction: (file: File | undefined) => void;
    defaultFileId?: number;
    disabled?: boolean;
}

export const OrganisationLogoControl = ({
    onAction,
    defaultFileId,
    disabled,
}: Props): React.ReactElement | null => {
    const url = useOrganisationLogoUrl(defaultFileId);

    const registrationStoredLogoUrl = getLogoObjectUrl();

    const v5_13 = useIsCareerHubVersion(5, 13);

    if (!v5_13) {
        return null;
    }

    return (
        <SingleImageDropzone
            onAction={onAction}
            width={LOGO_DISPLAY_SIZE}
            height={LOGO_DISPLAY_SIZE}
            imageHeight={LOGO_IMAGE_SIZE}
            imageWidth={LOGO_IMAGE_SIZE}
            defaultImageUrl={url || registrationStoredLogoUrl}
            disabled={disabled}
        />
    );
};
