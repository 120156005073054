import { useMemo } from 'react';
import { useEventsEnabled } from 'src/ui/features/events/hooks/events-enabled.hook';
import { DescriptionSelectLabel } from './DescriptionSelectLabel';
import {
    ReactSelectOption,
    SingleSelectControl,
    SingleSelectControlProps,
} from './SingleSelectControl';

type EventTypeSelectControlProps = Omit<SingleSelectControlProps, 'options'>;

export const EventTypeSelectControl = (
    props: EventTypeSelectControlProps
): React.ReactElement<any, any> | null => {
    const { isEventsEnabled, canCreateEventRequests, eventTypes } = useEventsEnabled();

    const options = useMemo(
        (): ReactSelectOption[] =>
            eventTypes
                .filter(x => x.allowEventRequest)
                .map(x => ({
                    label: (
                        <DescriptionSelectLabel
                            key={x.id}
                            title={x.name}
                            description={x.eventRequestDescription}
                        />
                    ),
                    value: x.id,
                    tokens: x.name.toLowerCase().split(' '),
                })),
        [eventTypes]
    );

    if (!isEventsEnabled || !canCreateEventRequests) {
        return null;
    }

    return <SingleSelectControl {...props} options={options} />;
};
