import { Dropdown } from 'react-bootstrap';

export type ActionDropdownSize = 'xs' | 'sm' | 'md' | 'xl';

interface Props {
    className?: string;
    size: ActionDropdownSize;
    disableToggle?: boolean;
    children: React.ReactNode;
}

export const ActionDropdown = ({
    size,
    children,
    disableToggle,
    className,
    ...props
}: Props): React.ReactElement<any, any> | null => {
    return (
        <Dropdown className={className}>
            <Dropdown.Toggle className={`ml-2 btn-${size}`} disabled={disableToggle}>
                Actions
            </Dropdown.Toggle>
            {children}
        </Dropdown>
    );
};
