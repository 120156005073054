import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { zEx } from 'src/logic/helpers/zod.helper';
import { geolocationSchema } from 'src/models/locations/geolocation.model';
import { ORGANISATION_TYPE_OPTIONS } from 'src/models/organisation/organisation-types.model';
import { AddressFieldsetGeo } from 'src/ui/features/locations/components/AddressFieldset';
import { StringsAlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/StringsAlertWrapper';
import { useIsCareerHubVersion } from 'src/ui/shared/hooks/is-careerhub-version.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { z } from 'zod';
import { ORGANISATION_TYPE_SELECT_OPTIONS } from '../../../../../constants/select-options.constants';
import { ErrorNormalized } from '../../../../../models/errors/error.model';
import { Organisation } from '../../../../../models/organisation/organisation.model';
import {
    SocialProperty,
    socialPropertySchema,
    SocialType,
} from '../../../../../models/organisation/social-property.model';
import { HookForm } from '../../../../shared/components/forms/HookForm';
import { abnRule, emailPatternRule } from '../../../../shared/helpers/validation.helper';
import { useBusinessIdValidator } from '../../../../shared/hooks/business-id-validator.hook';
import { useFormDefault } from '../../../../shared/hooks/form-default.hook';
import { useShowBusinessIdHook } from '../../hooks/show-business-id-field.hook';
import { OrganisationLogoControl } from './OrganisationLogoControl';

const getSocialValue = (properties: SocialProperty[] | undefined, type: SocialType) =>
    properties ? properties.find(i => i.socialType === type) : undefined;

interface Props {
    organisation?: Partial<Organisation>;
    onCancel?: () => any;
    onSubmit: (organisation: Organisation, logo: File | undefined) => any;
    showSpinner?: boolean;
    submitText?: string;
    cancelText?: string;
    error?: ErrorNormalized;
    disabled?: boolean;
    validateOnLoad?: boolean;
}

const idObjectSchema = z.object({
    id: z.number(),
});

// I'm really not happy with this, but I have to move on.
// The problem here is that the object won't know to update if the oragnaistion record
// ever changes.
const organisationFormSchema = z
    .object({
        id: z.number().optional(),
        division: z.string().max(100).optional(),
        acronym: z.string().max(10).optional(),
        description: zEx.stringMin().max(1000),
        businessId: z.string().max(30).optional().nullish(),
        employerType: z.enum(ORGANISATION_TYPE_OPTIONS),
        // this can change once this: https://github.com/colinhacks/zod/pull/2157 has been released in Zod
        email: zEx.stringMin().max(70).regex(emailPatternRule.value, emailPatternRule.message),
        phone: zEx.stringMin().max(30),
        url: z.union([z.literal(''), zEx.stringMin().max(300).url()]),
        private: z.boolean().optional(),

        size: idObjectSchema,
        scope: idObjectSchema,
        industries: zEx.arrayMin(idObjectSchema),

        // this is a hack and allows [undefined, undefined] in the social array, which
        // is fixed on submit, but should probably just never happen.
        socialProperties: socialPropertySchema.or(z.undefined()).array().optional(),

        street: zEx.stringMin(),
        city: zEx.stringMin(),
        region: zEx.stringMin(),
        postcode: zEx.stringMin(),
        countryCode: zEx.stringMin(),
        geolocation: geolocationSchema.optional().nullish(),
    })
    .passthrough();

export const organisationValidator = organisationFormSchema.superRefine((data, context) => {
    if (data.countryCode !== 'AU') {
        return;
    }

    if (!data.businessId) {
        context.addIssue({
            code: 'custom',
            message: 'ABN is required for an Australian organisation',
            path: ['businessId'],
        });
        return;
    }

    const message = abnRule(data.businessId);
    if (message) {
        context.addIssue({
            code: 'custom',
            message: message as string,
            path: ['businessId'],
        });
    }
});

export const OrganisationDetailsForm = ({
    organisation,
    onCancel,
    onSubmit,
    showSpinner,
    submitText,
    cancelText,
    error,
    disabled,
    validateOnLoad,
}: Props): React.ReactElement<any, any> => {
    const [logo, setLogo] = useState<File | undefined>();

    const {
        organisation: { form },
    } = useStrings();
    const clone = cloneDeep(organisation);
    const [hasTriggered, setHasTriggered] = useState(false);

    const formMethods = useFormDefault<Organisation>({
        defaultValues: clone,
        error,
        disabled,
        zod: organisationValidator,
    });

    const { businessIdFormLabel, businessIdValidator } = useBusinessIdValidator(formMethods);

    const onSubmitInner = (data: Organisation) => {
        data.socialProperties = data.socialProperties?.filter(i => !!i);
        return onSubmit(data, logo);
    };

    useEffect(() => {
        if (validateOnLoad && !hasTriggered) {
            void formMethods.trigger();
            setHasTriggered(true);
        }
    }, [formMethods, hasTriggered, validateOnLoad]);

    const countryCodeValue = formMethods.watch('countryCode');
    const showBusinessIdField = useShowBusinessIdHook(countryCodeValue);
    const v5_13 = useIsCareerHubVersion(5, 13);

    return (
        <HookForm {...formMethods} onSubmit={onSubmitInner} disabled={disabled}>
            <HookForm.ErrorAlert error={error} />
            <StringsAlertWrapper alerts={form.alerts} />

            <HookForm.HiddenId />
            <HookForm.Input {...form.name} readonly={true} name="name" />

            <Form.Row>
                {showBusinessIdField && (
                    <Col xs={12} sm={5}>
                        <HookForm.Input
                            {...form.businessId}
                            label={businessIdFormLabel}
                            name="businessId"
                            rules={{
                                validate: { customBusinessId: businessIdValidator },
                            }}
                        />
                    </Col>
                )}
                <Col xs={6} sm={5}>
                    <HookForm.Input {...form.division} name="division" />
                </Col>
                <Col xs={6} sm={2}>
                    <HookForm.Input {...form.acronym} name="acronym" />
                </Col>
            </Form.Row>

            <div className="d-sm-flex">
                {v5_13 && (
                    <div className="mr-3 mb-3">
                        <Form.Label>Logo</Form.Label>
                        <OrganisationLogoControl
                            onAction={setLogo}
                            defaultFileId={organisation?.bannerFileId}
                            disabled={disabled}
                        />
                    </div>
                )}
                <div className="flex-grow-1">
                    <HookForm.Textarea minRows={3} {...form.description} name="description" />
                </div>
            </div>

            <Form.Row>
                <Col xs={12} sm={4}>
                    <HookForm.HiddenId name="employerType" />
                    <HookForm.DisabledInput
                        {...form.organisationType}
                        value={
                            ORGANISATION_TYPE_SELECT_OPTIONS.find(
                                i => i.value === organisation?.employerType
                            )?.label
                        }
                    />
                </Col>
                <Col xs={12} sm={4}>
                    <HookForm.CategorySelect
                        name="size"
                        {...form.organisationSize}
                        allowEmpty={true}
                        categoryKey="organisationSizes"
                    />
                </Col>
                <Col xs={12} sm={4}>
                    <HookForm.CategorySelect
                        name="scope"
                        {...form.organisationScope}
                        allowEmpty={true}
                        categoryKey="organisationScopes"
                    />
                </Col>
            </Form.Row>

            <HookForm.CategoryMultiSelect
                name="industries"
                {...form.industries}
                categoryKey="industries"
            />
            <hr />

            <AddressFieldsetGeo defaultValues={clone} />

            <fieldset>
                <legend>Contact Details</legend>
                <Form.Row>
                    <Col>
                        <HookForm.Input name="phone" {...form.phone} />
                    </Col>
                    <Col>
                        <HookForm.Input name="email" {...form.email} />
                    </Col>
                </Form.Row>

                <HookForm.Input name="url" {...form.website} />
                <hr />
            </fieldset>

            <fieldset>
                <legend>Social</legend>
                <Form.Row>
                    <Col sm={6}>
                        <HookForm.Social
                            name="socialProperties[0]"
                            socialType="Facebook"
                            defaultValue={getSocialValue(
                                organisation?.socialProperties,
                                'Facebook'
                            )}
                        />
                    </Col>

                    <Col sm={6}>
                        <HookForm.Social
                            name="socialProperties[1]"
                            socialType="Twitter"
                            defaultValue={getSocialValue(organisation?.socialProperties, 'Twitter')}
                        />
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col sm={6}>
                        <HookForm.Social
                            name="socialProperties[2]"
                            socialType="LinkedIn"
                            defaultValue={getSocialValue(
                                organisation?.socialProperties,
                                'LinkedIn'
                            )}
                        />
                    </Col>
                    <Col sm={6}>
                        <HookForm.Social
                            name="socialProperties[3]"
                            socialType="Instagram"
                            defaultValue={getSocialValue(
                                organisation?.socialProperties,
                                'Instagram'
                            )}
                        />
                    </Col>
                </Form.Row>
                <hr />
            </fieldset>

            <HookForm.Checkbox name="private" {...form.private} />

            <HookForm.Buttons
                onCancel={onCancel}
                showSpinner={showSpinner}
                submitText={submitText}
                cancelText={cancelText}
                disabled={disabled}
            >
                <HookForm.ErrorSpan error={error} />
            </HookForm.Buttons>
        </HookForm>
    );
};
