import { useEffect, useState } from 'react';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
// import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

export function handleFetchResponse(response: Response) {
    const { MiniProfiler } = window as any;
    const profilerResultsExists = document.querySelector('.profiler-results') !== null;

    if (MiniProfiler && profilerResultsExists) {
        try {
            const mpids = response.headers.get('X-MiniProfiler-Ids');
            if (mpids) {
                const idArray = JSON.parse(mpids) as string[];
                if (Array.isArray(idArray) && idArray.length > 0) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    MiniProfiler.fetchResults(JSON.parse(mpids) as string[]);
                }
            }
        } catch (err: any) {
            console.error('error on mini-profiler fetch', err);
        }
    }
}

function appendScript(basePath: string) {
    let script = document.getElementById('mini-profiler') as HTMLScriptElement;

    if (!script) {
        script = document.createElement('script');
        script.id = 'mini-profiler';
        script.type = 'text/javascript';
        script.src = basePath + '/mini-profiler-resources/includes.js';
        script.async = true;
        // script.onload = () => onScriptLoad(token);

        const dataset = {
            'current-id': '',
            ids: '',
            version: 'ySF6M98CBehTtL86BbiEmys9yxR1HKazhe2sznfdUWQ=',
            path: basePath + '/mini-profiler-resources/',
            position: 'bottomright',
            authorized: 'true',
            'max-traces': '15',
            'toggle-shortcut': 'Alt+P',
            'trivial-milliseconds': '2.0',
            'ignored-duplicate-execute-types': 'Open,OpenAsync,Close,CloseAsync',
            scheme: 'Light',
        };

        Object.keys(dataset).forEach(key => {
            script.setAttribute('data-' + key, (dataset as any)[key]);
        });

        document.body.appendChild(script);
    }
}

export const MiniProfilerComponent = (): React.ReactElement<any, any> | null => {
    // const isSupport = useIsSupportAdmin();
    // const isImpersonating = useIsImpersonating();
    // onst token = useRootSelector(state => state.authentication.oidcUser?.access_token);
    const [loaded, setLoaded] = useState(false);
    const basePath = useRootSelector(state => state.configuration.value.careerHubBasePath);
    const clientCode = useRootSelector(state => state.configuration.clientCode);

    useEffect(() => {
        if (!loaded && basePath && clientCode === 'local') {
            // ToDo: allow if running locally ?
            //if (isSupport || isImpersonating) {
            appendScript(basePath);
            //}
            setLoaded(true);
        }
    }, [basePath, clientCode, loaded]);

    return null;
};
