import { useMemo } from 'react';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { identityHelper } from '../helpers/identity.helper';

export const useIsIndividual = () => {
    const activeIdentity = useRootSelector(state => state.authentication.activeIdentity);

    const isIndividual = useMemo(() => {
        return activeIdentity ? identityHelper.isIndividual(activeIdentity) : false;
    }, [activeIdentity]);

    return isIndividual;
};
