import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { isActionOf } from 'typesafe-actions';
import { RootEpic } from '../../epic.root-index';
import * as actions from '../central.actions';

export const jobAttachmentDownloadBlobAsyncEpic = asyncEpicBase(
    actions.centralFromCareerHubJobAttachmentBlobAsync,
    (services, action) => services.api.attachments.downloadAsBlob(action.payload.id),
    {
        success: (result, ra) =>
            actions.centralFromCareerHubJobAttachmentBlobAsync.success(
                new File([result.blob!], ra.meta.fileMeta.title, {
                    // this uses CareerHub's response to set the file blob
                    // without this, the downstream upload will default to application/octect-stream
                    // as the browser will not be able to determine the file type
                    type: result.response.headers.get('Content-Type') ?? 'application/octet-stream',
                }),
                ra.meta
            ),
        failure: (error, ra) =>
            actions.centralFromCareerHubJobAttachmentBlobAsync.failure(error, ra.meta),
    }
);

export const centralJobAttachmentTriggerUploadEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(actions.centralFromCareerHubJobAttachmentBlobAsync.success)),
        mergeMap(action =>
            of(
                actions.centralJobAttachmentUploadAsync.request(
                    { file: action.payload, title: action.meta.fileMeta.title },
                    action.meta
                )
            )
        )
    );
};

export const jobAttachmentCreateAsyncEpic = asyncEpicBase(
    actions.centralJobAttachmentUploadAsync,
    (services, action, state$) =>
        services.central.api.jobAttachmentCreate(
            state$.value.central.publish.centralJobId!,
            action.payload.file
        ),
    {
        success: (result, ra) =>
            actions.centralJobAttachmentUploadAsync.success(result.json, ra.meta),
        failure: (result, ra) => actions.centralJobAttachmentUploadAsync.failure(result, ra.meta),
    }
);
