import { CareerHubEvent } from 'src/models/events/event.model';
import { EndorsementDetailsDisplay } from 'src/ui/features/endorsements/components/display/EndorsementDetailsDisplay';
import { SkillDetailsDisplay } from 'src/ui/features/skills/components/SkillDetailsDisplay';
import { Details } from 'src/ui/shared/components/details/Details';
import { Html } from 'src/ui/shared/components/html/Html';
import { EventBannerHeader } from './EventBannerHeader';
import { EventDateDisplay } from './EventDateDisplay';
import { EventLocationDisplay } from './EventLocationDisplay';
import { EventProperties } from './EventProperties';

interface Props {
    event: CareerHubEvent;
}

export const EventDetails = ({ event }: Props): React.ReactElement<any, any> | null => {
    return (
        <>
            <EventBannerHeader bannerKey={event.bannerKey} isEvent={true}>
                <h1 className="display-5">{event.title}</h1>
                <div className="mb-1">
                    <EventDateDisplay event={event} />
                </div>
                <div className="mb-1">
                    <EventLocationDisplay item={event} />
                </div>
            </EventBannerHeader>
            <Details properties={<EventProperties event={event} />}>
                <h2 className="font-weight-normal">Summary</h2>
                <div className="mb-3">{event.summary}</div>
                <h2 className="font-weight-normal">Details</h2>
                <Html source={event.details} className="mb-3 event-html-container" />

                <SkillDetailsDisplay entity={event} />
                <EndorsementDetailsDisplay endorsementIds={event.endorsementIds || []} />
                {/* <JobDetailsAttachments jobId={job.id} /> */}
            </Details>
        </>
    );
};
