import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../../../shared/components/icon/Icon';
import { Loader } from '../../../shared/components/loader/Loader';
import { ThinPageContent } from '../../../shared/components/page-components/ThinPageContent';
import { useAuthentication } from '../hooks/authentication.hook';
import { useLogout } from '../hooks/logout.hook';

// this is a bit erroneous as when you literally "logout", you
// are just returned to the homepage. This page is only ever hit
// if you get a 401 response from the API.
// because of all the guards, it's easier to push the user to a specific
// unauthorized page, with a message.
export const LogoutPage = (): React.ReactElement<any, any> | null => {
    const { logoutTriggered } = useLogout();
    const { isCareerHubAuthenticated } = useAuthentication();

    return (
        <ThinPageContent>
            <div className="text-center">
                <Loader state={isCareerHubAuthenticated || logoutTriggered} size="sm">
                    <Icon icon={faSignOutAlt}>You have been logged out.</Icon>
                </Loader>
                {logoutTriggered && <span className="ml-2">Logging out...</span>}
            </div>
        </ThinPageContent>
    );
};
