import { useCallback, useMemo } from 'react';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { IdentityType } from 'src/models/authentication/identity.model';
import { identityHelper } from '../helpers/identity.helper';
import { useIsIndividual } from './is-individual.hook';

export const useAuthentication = () => {
    const {
        activeIdentity,
        isCareerHubAuthenticated,
        initComplete,
        identities,
        identitySetUserFetching,
        identityListState,
        logoutSelected,
        expires,
        isImpersonation,
        oidcUser,
        error,
        centralIdentityIsRedirecting,
    } = useRootSelector(state => state.authentication);

    const hasEmployer = useMemo(() => !!activeIdentity, [activeIdentity]);

    const isOrganisation = useMemo(
        () => hasEmployer && !identityHelper.isIndividual(activeIdentity!),
        [activeIdentity, hasEmployer]
    );

    const isIndividual = useIsIndividual();

    const centralContactIdentity = useMemo(() => {
        if (activeIdentity && identityHelper.isCentralContact(activeIdentity)) {
            return activeIdentity;
        }

        return undefined;
    }, [activeIdentity]);

    const isCentralContact = useMemo(() => !!centralContactIdentity, [centralContactIdentity]);

    const hasIndividual = useMemo(
        () => !!identities.find(identityHelper.isIndividual),
        [identities]
    );

    const centralOrganisationId = useMemo(
        () => (centralContactIdentity ? centralContactIdentity.centralOrganisationId : undefined),
        [centralContactIdentity]
    );

    // this "useAuthentication" hook now has the MSP logic bleeding into it.
    // the centralOrganiastionId can either exist in the authentication state created on authentication
    // at start up, or as a result of an MSP push.
    // The issue is that organisations can be in a "pending" state, but also exist locally.
    // this means that technically, the org is Centralised, and should not be able to be edited.
    // HOWEVER, while in this "pending" state, if we block editing locally, we can get into situations
    // where the org simply cannot be edited.
    // Consider if the org is rejected in Central or if no jobs are distributed. A user could go through
    // the entire MSP process, hit the payment step in Central, and yeet out at that point.
    // The job will never be distributed and therefore the Organisation in Central will never be Approved (or even looked at)
    // When the user returns to the local console (but only after re-authenticating), the organsation would be locked from editing,
    // asking the user to "go to central" to update. But going to Central will do nothing.
    // SO ALAS, I now have to deal with that.
    const determineIfLocalAndCentralOrganisationInPendingState = useCallback(
        (identity: IdentityType) => {
            return (
                identityHelper.isCentralContact(identity) &&
                identity.organisationStatus === 'PendingApproval'
            );
        },
        []
    );

    const hasOidcUser = useMemo(() => !!oidcUser, [oidcUser]);

    return {
        isCareerHubAuthenticated,
        hasEmployer,
        isOrganisation,
        activeIdentity,
        isIndividual,
        isCentralContact,
        hasIndividual,
        identities,
        identitySetUserFetching,
        logoutSelected,
        expires,
        isImpersonation,
        centralOrganisationId,
        initComplete,
        hasOidcUser,
        error,
        identityListState,
        centralIdentityIsRedirecting,
        determineIfLocalAndCentralOrganisationInPendingState,
    };
};
