import { ApiListIncludeResponse } from 'src/models/api/response.model';
import { EndorementInclude, Endorsement } from 'src/models/endorsements/endorsement.model';
import { ErrorNormalized } from 'src/models/errors/error.model';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const endorsementAllAsync = createAsyncAction(
    'ENDORSEMENT_ALL:REQUEST',
    'ENDORSEMENT_ALL:SUCCESS',
    'ENDORSEMENT_ALL:FAILURE'
)<undefined, ApiListIncludeResponse<Endorsement, EndorementInclude>, ErrorNormalized>();

export const endorsementResetState = createAction('ENDORSEMENT_RESET_STATE')();
