import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useAddress } from 'src/ui/shared/hooks/address.hook';
import { OrganisationTypeMeta } from '../../../../../models/organisation/organisation-types.model';
import { Organisation } from '../../../../../models/organisation/organisation.model';
import { ExternalLink } from '../../../../shared/components/anchors/ExternalLink';
import { MailTo } from '../../../../shared/components/anchors/MailTo';
import { Phone } from '../../../../shared/components/anchors/Phone';
import { DetailsProperty } from '../../../../shared/components/details/DetailsProperty';
import { SocialPropertyDisplay } from './SocialPropertyDisplay';

interface Props {
    organisation: Organisation;
}

export const OrganisationDetailsProperties = ({
    organisation,
}: Props): React.ReactElement<any, any> | null => {
    const address = useAddress(organisation);

    return (
        <>
            <h2 className="font-weight-normal">Other Information</h2>
            <DetailsProperty label="Location">
                <Icon icon={faMapMarkerAlt}>{address}</Icon>
            </DetailsProperty>
            <DetailsProperty label="Business Id">{organisation.businessId}</DetailsProperty>
            <DetailsProperty label="Acronym">{organisation.acronym}</DetailsProperty>
            <DetailsProperty label="Type">
                {OrganisationTypeMeta[organisation.employerType]}
            </DetailsProperty>
            {(organisation.size || organisation.scope) && (
                <DetailsProperty label="Scope, size">
                    {organisation.size?.name}, {organisation.scope?.name}
                </DetailsProperty>
            )}

            <DetailsProperty label="Contact details">
                <ExternalLink href={organisation.url} className="d-block mb-1" />
                <MailTo href={organisation.email} className="d-block mb-1" />
                <Phone href={organisation.phone} className="d-block mb-1" />
            </DetailsProperty>

            {organisation.socialProperties.length > 0 && (
                <DetailsProperty label="Social">
                    {organisation.socialProperties.map(s => (
                        <SocialPropertyDisplay key={s.socialType} item={s} />
                    ))}
                </DetailsProperty>
            )}
        </>
    );
};
