import { getClientConfig } from 'src/clients-internal/configuration.init';
import { EntityPageRequest } from '../../../models/api/request.model';
import { ApiSearchResponse } from '../../../models/api/response.model';
import { ObsApiExpected } from '../../../models/api/service.model';
import { FormSubmission } from '../../../models/forms/form-submission.model';
import { apiStringify } from '../../helpers/api-query-string.helper';
import { fetchHelper } from '../../helpers/fetch.helper';

const basePath = () =>
    `${getClientConfig().careerHubBasePath}/services/employers/v1/formsubmissions`;

export function list(
    request: EntityPageRequest
): ObsApiExpected<ApiSearchResponse<FormSubmission, EntityPageRequest>> {
    return fetchHelper.get(`${basePath()}${apiStringify(request)}`);
}
