import { ContactValidationGuard } from 'src/ui/features/contacts/components/entity-integrity/ContactValidationGuard';
import { ComponentDisabledGuard } from 'src/ui/shared/components/layout/ComponentDisabledGuard';
import { ThinLayout } from 'src/ui/shared/components/layout/ThinLayout';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { OrganisationValidationGuard } from '../../organisation/components/guards/OrganisationValidationGuard';
import { useAuthenticationLoading } from '../hooks/authentication-loading.hook';
import { useAuthentication } from '../hooks/authentication.hook';
import { ActiveIdentityRedirectGuard } from './ActiveIdentityRedirectGuard';
import { AuthenticationErrorMessage } from './AuthenticationErrorMessage';
import { AuthenticationFallback } from './AuthenticationFallback';
import { CareerHubRegistrationRedirectGuard } from './CareerHubRegistrationRedirectGuard';
import { CentralIdentityRedirectGuard } from './CentralIdentityRedirectGuard';
import { GuardLoader } from './GuardLoader';

interface Props {
    children: React.ReactNode;
}

export const ApplicationRouteGuard = ({ children }: Props): React.ReactElement<any, any> | null => {
    const { isCareerHubAuthenticated, error } = useAuthentication();
    const authLoading = useAuthenticationLoading();
    const apiConfiguationState = useRootSelector(state => state.apiConfiguration.fetch);
    const loading = apiConfiguationState.loading || !isCareerHubAuthenticated || authLoading;

    return loading ? (
        <ThinLayout>
            <ComponentDisabledGuard>
                <CentralIdentityRedirectGuard>
                    <CareerHubRegistrationRedirectGuard>
                        <ActiveIdentityRedirectGuard>
                            <GuardLoader state={{ loading, error }} />

                            <AuthenticationErrorMessage error={error} />

                            <AuthenticationFallback />
                        </ActiveIdentityRedirectGuard>
                    </CareerHubRegistrationRedirectGuard>
                </CentralIdentityRedirectGuard>
            </ComponentDisabledGuard>
        </ThinLayout>
    ) : (
        <OrganisationValidationGuard>
            <ContactValidationGuard>{children}</ContactValidationGuard>
        </OrganisationValidationGuard>
    );
};
