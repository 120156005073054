import { useMemo } from 'react';
import { StoredFile } from 'src/models/jobs/job-stored-file.model';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { useCentralAlreadyPublished } from '../../hooks/central-already-published.hook';
import { CentralPublishSectionTitle } from '../publish/CentralPublishSectionTitle';

interface Props {
    attachment: StoredFile;
    index: number;
    jobId: number;
}

export const CentralPublishJobAttachmentInfo = ({
    attachment,
    index,
    jobId,
}: Props): React.ReactElement<any, any> | null => {
    const jobAlreadyPublished = useCentralAlreadyPublished(jobId);

    const state = useRootSelector(state => state.central.jobAttachmentState[index]);
    const currentStep = useRootSelector(state => state.central.currentStep);
    const {
        central: {
            publish: {
                jobAttachment: { message },
            },
        },
    } = useStrings();

    // this logic is a bit wishy-washy
    // I basically don't have a good way of knowing if attachments already exist on central
    // there's just the assumption that the attachments were uploaded correctly and haven't since changed
    const complete = useMemo(
        () =>
            (currentStep.step !== 'jobAttachment' && jobAlreadyPublished) ||
            (!!state && !state.loading && !state.error),
        [jobAlreadyPublished, state, currentStep]
    );

    return (
        <>
            <CentralPublishSectionTitle
                state={state}
                complete={complete}
                titleClassName="mb-0 d-inline"
            >
                {attachment.title}
                {!complete && (
                    <small className="ml-2">
                        <small>{message}</small>
                    </small>
                )}
            </CentralPublishSectionTitle>
            <hr />
        </>
    );
};
