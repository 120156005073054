import { Loader } from 'src/ui/shared/components/loader/Loader';
import { LoaderState } from 'src/models/errors/error.model';

interface Props {
    state: LoaderState[] | LoaderState | boolean;
}

export const GuardLoader = ({ state }: Props): React.ReactElement<any, any> | null => {
    return (
        <div className="m-5 text-center">
            <Loader state={state} />
        </div>
    );
};
