export type DropzoneErrorCode = 'file-too-large' | 'file-invalid-type';

interface DropzoneErrorCodeMetadata {
    message: string;
}

export const DROPZONE_ERRORS: { [key in DropzoneErrorCode]: DropzoneErrorCodeMetadata } = {
    'file-too-large': {
        message: 'File is larger than 4mbs',
    },
    'file-invalid-type': {
        message: 'File is invalid type',
    },
};
