interface Props {
    label: string;
    value: string | undefined;
}

export const CentralSimilarProperty = ({
    label,
    value,
}: Props): React.ReactElement<any, any> | null => {
    return value ? (
        <div>
            <span className="text-muted">{label}:</span>
            <span> {value}</span>
        </div>
    ) : null;
};
