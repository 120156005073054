import {
    EmployerIdentity,
    ContactIdentity,
    CentralContactIdentity,
    IndividualIdentity,
    IdentitySetUserRequest,
} from '../../../../models/authentication/identity.model';

// Guards to help typescript know which interface the returning identity is.
const isContact = (identity: EmployerIdentity): identity is ContactIdentity =>
    (identity as ContactIdentity).contactId !== undefined &&
    (identity as CentralContactIdentity).centralContactId === undefined;

const isCentralContact = (identity: EmployerIdentity): identity is CentralContactIdentity =>
    (identity as CentralContactIdentity).centralContactId !== undefined;

const isIndividual = (identity: EmployerIdentity): identity is IndividualIdentity =>
    (identity as IndividualIdentity).individualId !== undefined;

const createIdentitySetUserRequest: (
    identity: EmployerIdentity
) => IdentitySetUserRequest = identity => {
    if (isCentralContact(identity)) {
        return { centralContactId: identity.centralContactId };
    }

    if (isContact(identity)) {
        return { contactId: identity.contactId };
    }

    if (isIndividual(identity)) {
        return { individualId: identity.individualId };
    }

    throw new Error('Identity not in correct format.');
};

export const identityHelper = {
    isContact,
    isCentralContact,
    isIndividual,
    createIdentitySetUserRequest,
};
