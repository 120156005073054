import omit from 'lodash/omit';
import { useEffect, useMemo, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { zEx } from 'src/logic/helpers/zod.helper';
import { useClaimValues } from 'src/ui/features/authentication/hooks/claim-values.hook';
import { StringsAlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/StringsAlertWrapper';
import { emailPatternRule } from 'src/ui/shared/helpers/validation.helper';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { z } from 'zod';
import { Contact } from '../../../../../models/contacts/contact.model';
import { ErrorNormalized } from '../../../../../models/errors/error.model';
import { HookForm } from '../../../../shared/components/forms/HookForm';
import { HookFormButtonsProps } from '../../../../shared/components/forms/shared/HookFormButtons';
import { useFormDefault } from '../../../../shared/hooks/form-default.hook';
import { useIdentityContact } from '../../../authentication/hooks/identity-contact.hook';
import { ContactEmailAddModal } from './ContactEmailAddModal';
import { ContactEmailControl } from './ContactEmailControl';

const toZodSchema = z.object({
    // this can change once this: https://github.com/colinhacks/zod/pull/2157 has been released in Zod
    email: zEx.stringMin().regex(emailPatternRule.value, emailPatternRule.message),
    phone: z.string().max(50).optional(),
    firstName: zEx.stringMin().max(50),
    lastName: zEx.stringMin().max(50),
    positionTitle: zEx.stringMin().max(100),
    active: z.boolean(),
    hasWebAccess: z.boolean(),
});

export const contactFormSchema = toZodSchema.passthrough();

const contactRegistrationSchema = contactFormSchema.extend({
    terms: z.literal(true, {
        errorMap: (issue, context) => {
            if (issue.code === 'invalid_literal') {
                return { message: 'You must accept the terms and conditions' };
            }

            return { message: context.defaultError };
        },
    }),
});

interface Props extends HookFormButtonsProps {
    contact?: Partial<Contact>;
    onSubmit: (contact: Contact) => void;
    onCancel?: () => void;
    showSpinner?: boolean;
    useClaims?: boolean;
    error?: ErrorNormalized;
    isRegistration?: boolean;
    validateOnLoad?: boolean;
}

export const ContactForm = (props: Props): React.ReactElement<any, any> | null => {
    const { onSubmit, contact, useClaims, error, isRegistration, disabled, validateOnLoad } = props;

    const isEdit = !!contact;

    const [hasTriggered, setHasTriggered] = useState(false);
    const claimValues = useClaimValues();
    const { email: emailClaimsEmail } = useRootSelector(state => state.emailClaims);

    const { isIdentityContact } = useIdentityContact();
    const isIdentity = isIdentityContact(contact);
    const {
        register: {
            contact: { terms },
        },
        contact: { form },
    } = useStrings();

    const defaultValues = useMemo(() => {
        const claimIntial: Partial<Contact> = {
            ...(omit(claimValues, 'email') || {}),
            email: claimValues && claimValues.email[0],
            hasWebAccess: true,
            active: true,
        };
        return isEdit
            ? contact
            : useClaims
            ? claimIntial
            : {
                  hasWebAccess: false,
                  active: true,
              };
    }, [claimValues, contact, isEdit, useClaims]);

    const formMethods = useFormDefault<Contact>({
        defaultValues,
        error,
        disabled,
        zod: isRegistration ? contactRegistrationSchema : contactFormSchema,
    });

    const emailFormValue = formMethods.watch('email');

    useEffect(() => {
        if (!isRegistration || !emailClaimsEmail) {
            return;
        }

        if (emailFormValue === emailClaimsEmail) {
            return;
        }

        if (claimValues?.email.includes(emailClaimsEmail)) {
            formMethods.setValue('email', emailClaimsEmail);
        }
    }, [claimValues?.email, emailClaimsEmail, emailFormValue, formMethods, isRegistration]);

    useEffect(() => {
        if (validateOnLoad && !hasTriggered) {
            void formMethods.trigger();
            setHasTriggered(true);
        }
    }, [formMethods, hasTriggered, validateOnLoad]);

    return (
        <>
            <HookForm {...formMethods} onSubmit={onSubmit}>
                <HookForm.ErrorAlert error={error} />
                <StringsAlertWrapper alerts={form.alerts} />

                <HookForm.HiddenId />
                <HookForm.HiddenId name="active" forceRender={true} />

                <Form.Row>
                    <Col>
                        <ContactEmailControl isRegistration={isRegistration} contact={contact} />
                    </Col>
                    <Col>
                        {!isRegistration && (
                            <div className="mr-3">
                                <HookForm.Checkbox
                                    name="hasWebAccess"
                                    {...form.hasWebAccess}
                                    disabled={isIdentity}
                                    includeEmptyLabel={true}
                                />
                            </div>
                        )}
                    </Col>
                </Form.Row>

                <hr />
                <Form.Row>
                    <Col xs={12} sm={4}>
                        <HookForm.Input name="firstName" {...form.firstName} />
                    </Col>
                    <Col xs={12} sm={4}>
                        <HookForm.Input name="lastName" {...form.lastName} />
                    </Col>
                    <Col xs={12} sm={4}>
                        <HookForm.Input name="phone" {...form.phone} />
                    </Col>
                    <Col xs={12} sm={4}>
                        <HookForm.Input name="positionTitle" {...form.positionTitle} />
                    </Col>
                </Form.Row>

                {isRegistration && <HookForm.Checkbox {...terms} name="terms" />}

                <HookForm.Buttons {...props} />
            </HookForm>
            <ContactEmailAddModal />
        </>
    );
};
