import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ClientStrings } from 'src/clients-internal/strings.model';
import { AlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/AlertWrapper';
import { Markdown } from 'src/ui/shared/components/details/Markdown';
import { useDynamicString, useStrings } from 'src/ui/shared/hooks/strings.hook';
import { useCentralLink } from '../hooks/central-link.hook';

interface Props {
    stringSelector: (strings: ClientStrings) => string | string[];
    centralPath: string | string[];
}

// this assumes that there is only ever dynamic property: $link
// it also relys on whatever's displaying it to load and decide whether or not this should be displayed
export const ManagedByCentralAlert = ({
    stringSelector,
    centralPath,
}: Props): React.ReactElement<any, any> | null => {
    const strings = useStrings();
    const text = stringSelector(strings);
    const link = useCentralLink(centralPath);
    const dynamic = useDynamicString(text, { $link: link });

    return (
        <AlertWrapper icon={faInfoCircle} variant="info">
            <Markdown source={dynamic} />
        </AlertWrapper>
    );
};
