// see: https://github.com/react-cropper/react-cropper
// p.s. it was easier to use this then use the actual version here: https://github.com/fengyuanchen/cropperjs/tree/v2
// and deal with mapping the custom web components

import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { RefObject, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { useResizeDetector } from 'react-resize-detector';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useWindowSize } from 'src/ui/shared/hooks/window-size.hook';

// random careerhub banner ratio
export const BANNER_RATIO = {
    width: 1170,
    height: 436,
};

interface ZoomContext {
    zoomBy: 'height' | 'width';
    increment: number;
}

interface Props {
    onCancel: (reason: 'user' | 'dimensions-too-small') => void;
    imageUrl: string;
    cropperRef: RefObject<ReactCropperElement>;
}

export const CropperDisplay = ({
    onCancel,
    imageUrl,
    cropperRef,
}: Props): React.ReactElement<any, any> | null => {
    const { width: divWidth, ref: divRef } = useResizeDetector({ refreshMode: 'debounce' });
    const [zoomContext, setZoomContext] = useState<ZoomContext | undefined>(undefined);

    const onReady = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;

        const canvusData = cropper?.getCanvasData();

        if (!canvusData) {
            return;
        }

        if (
            canvusData.naturalWidth < BANNER_RATIO.width ||
            canvusData.naturalHeight < BANNER_RATIO.height
        ) {
            onCancel('dimensions-too-small');
            return;
        }

        if (
            // javascript does some real weird height and width adjustments that can cause values
            // like '436.000000000006'
            Math.floor(canvusData.naturalWidth) !== BANNER_RATIO.width ||
            Math.floor(canvusData.naturalHeight) !== BANNER_RATIO.height
        ) {
            /* 
            calc whatever is smallest by ratio
            whichever dimension is smallest minus the banner_ratio amount divided by 100
            that's how much we can zoom
            */
            const ratioWidth = canvusData.naturalWidth / BANNER_RATIO.width;
            const ratioHeight = canvusData.naturalHeight / BANNER_RATIO.height;
            const zoomBy = ratioWidth < ratioHeight ? 'width' : 'height';
            const increment =
                zoomBy === 'height'
                    ? (canvusData.naturalHeight - BANNER_RATIO.height) / 100
                    : (canvusData.naturalWidth - BANNER_RATIO.width) / 100;

            setZoomContext({
                zoomBy,
                increment,
            });
        }
    };

    const onZoomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // value is a number between 0 and 100
        const value = Number(e.target.value);
        if (isNaN(value)) {
            return;
        }
        const cropper = cropperRef?.current?.cropper;
        if (!cropper) {
            return;
        }

        if (!zoomContext) {
            return;
        }

        if (zoomContext.zoomBy === 'width') {
            cropper.setCanvasData({
                width: BANNER_RATIO.width + zoomContext.increment * value,
            });
        } else {
            cropper.setCanvasData({
                height: BANNER_RATIO.height + zoomContext.increment * value,
            });
        }
        // if 0 , width = 1600
        // if 100 , width = 1170
        // if 50 , width = 1600 - 1170 ||| 430 * (50/100)
    };

    // const onRotateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = Number(e.target.value);
    //     if (isNaN(value)) {
    //         return;
    //     }
    //     const cropper = cropperRef?.current?.cropper;
    //     if (!cropper) {
    //         return;
    //     }

    //     const valueToSet = value === 0 ? -90 : (value / 100) * 180 - 90;

    //     cropper.rotateTo(valueToSet);
    // };

    const [dimensions, setDimensions] = useState<{ width: number; height: number } | undefined>(
        undefined
    );
    const { width: windowWidth } = useWindowSize();

    useEffect(() => {
        if (!windowWidth) {
            return;
        }

        const div = divRef.current as HTMLDivElement;
        const elW = div.offsetWidth;

        const r = div.getBoundingClientRect();
        const left = r.left;
        const right = r.right;
        const visibleDivWidth = Math.max(
            0,
            right > 0 ? Math.min(elW, windowWidth - left) : Math.min(left, windowWidth)
        );

        const heightcalc = (BANNER_RATIO.height / BANNER_RATIO.width) * visibleDivWidth;
        const heightToSet = heightcalc > BANNER_RATIO.height ? BANNER_RATIO.height : heightcalc;

        if (dimensions?.height !== heightToSet) {
            const widthCalc = (BANNER_RATIO.width / BANNER_RATIO.height) * heightToSet;
            const widthToSet = widthCalc > BANNER_RATIO.width ? BANNER_RATIO.width : widthCalc;
            setDimensions({
                width: widthToSet,
                height: heightToSet,
            });

            // destroy and recreate cropper
            const imageElement = cropperRef?.current;
            const cropper = imageElement?.cropper;

            cropper?.reset();
        }
    }, [cropperRef, dimensions?.height, divRef, divWidth, windowWidth]);

    return (
        <>
            <div ref={divRef} />
            {dimensions && (
                <Cropper
                    src={imageUrl}
                    aspectRatio={BANNER_RATIO.width / BANNER_RATIO.height}
                    cropBoxMovable={false}
                    cropBoxResizable={false}
                    guides={false}
                    ref={cropperRef}
                    scalable={true}
                    dragMode="move"
                    ready={onReady}
                    background={true}
                    zoomOnWheel={false}
                    toggleDragModeOnDblclick={false}
                    viewMode={3}
                    responsive={true}
                    // minCropBoxWidth={BANNER_RATIO.width}
                    // minCropBoxHeight={blah}
                    // minCanvasWidth={BANNER_RATIO.width}
                    // minCanvasHeight={blah}
                    autoCropArea={1}
                    style={{
                        width: `${dimensions.width}px`,
                        height: `${dimensions.height}px`,
                        // aspectRatio: `${BANNER_RATIO.width} / ${BANNER_RATIO.height}`,
                        // width: '100%',
                    }}
                />
            )}
            <div style={{ maxWidth: `${BANNER_RATIO.width}px` }}>
                <Row>
                    <Col className="mt-2" xs={12} md={3}>
                        <Icon iconClassName="text-info" icon={faInfoCircle}>
                            Drag to reposition image
                        </Icon>
                    </Col>
                    {zoomContext && (
                        <Col xs={12} md={6} className="d-flex align-items-center w-100">
                            <label className="mb-0 mr-2">Zoom</label>
                            <input
                                type="range"
                                className="form-control-range"
                                defaultValue={0}
                                onChange={e => onZoomChange(e)}
                            />
                        </Col>
                    )}
                    <Col xs={12} md={3} className="text-right">
                        <Button
                            variant="link-danger"
                            onClick={() => onCancel('user')}
                            className="ml-auto pr-0"
                        >
                            <Icon icon={faTimes}>Clear Image</Icon>
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    );
};
