import { of } from 'rxjs';
import { filter, ignoreElements, mergeMap, tap } from 'rxjs/operators';
import * as actions from 'src/logic/features/authentication/authentication.actions';
import { asyncEpicStandard } from 'src/logic/helpers/epics/async.epic-helper';
import { isActionOf } from 'typesafe-actions';
import { RootEpic } from '../../epic.root-index';

// Completely different flow for impersonation
export const authenticationStartImpersonateEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(actions.impersonateStart)),
        tap(() => services.authentication.init(state$.value.configuration)),
        mergeMap(() => of(actions.impersonateGetIdentityAsync.request()))
    );
};

export const impersonateIdentityEpic = asyncEpicStandard(
    actions.impersonateGetIdentityAsync,
    ({ api }) => api.impersonate.getIdentity()
);

export const impersonateLogoutEpic = asyncEpicStandard(actions.impersonateLogoutAsync, ({ api }) =>
    api.impersonate.logout()
);

// setting the identity also ends the auth process
export const impersonateIdentitySuccessEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(actions.impersonateGetIdentityAsync.success)),
        mergeMap(action => {
            return of(
                actions.careerHubIdentitySetActive(action.payload.data, { resetData: false })
            );
        })
    );
};

export const impersonateIdentityFailureEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(actions.impersonateGetIdentityAsync.failure)),
        mergeMap(() => of(actions.careerHubIdentitySetActive(undefined, { resetData: false }))),
        tap({
            next: () => {
                // easier to just clear and reload the page
                services.storage.impersonateLocal.clear();
                services.windowService.getWindow().location.reload();
            },
        })
    );
};

export const impersonateSetLocalExpire: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(actions.authenticationExpireSet)),
        filter(() => state$.value.authentication.isImpersonation),
        tap({
            next: action => {
                services.storage.impersonateLocal.save(action.payload.expires);
            },
        }),
        ignoreElements()
    );
};

export const impersonateLogoutSuccessEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(actions.impersonateLogoutAsync.success)),
        tap(() => {
            const w = services.windowService.getWindow();
            const login = new URL(state$.value.configuration.value.careerHubBasePath + '/admin');
            services.storage.impersonateLocal.clear();

            w.location.href = login.href;
        }),
        ignoreElements()
    );
};
