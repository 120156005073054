import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState } from 'react';
import { Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { Markdown } from '../../details/Markdown';
import { Icon } from '../../icon/Icon';
import { HookControlProps } from '../HookForm';

type Props = Pick<HookControlProps, 'label' | 'name' | 'popover'>;

export const HookFormPopover = ({
    name,
    popover,
    label,
}: Props): React.ReactElement<any, any> | null => {
    const useModal = useMemo(() => {
        if (!popover) {
            return false;
        }

        const length = Array.isArray(popover) ? popover.join('').length : popover.length;

        return length > 1000;
    }, [popover]);
    const [show, setShow] = useState(false);

    const button = (
        <Button
            variant="link"
            className="p-0 btn-link-info ml-2 mr-1"
            onClick={() => setShow(true)}
            tabIndex={-1}
        >
            <Icon icon={faInfoCircle} size="lg" />
        </Button>
    );

    return useModal ? (
        <>
            <Modal
                contentClassName="p-0"
                show={show}
                onHide={() => setShow(false)}
                size="lg"
                scrollable={true}
            >
                <Modal.Header className="p-3 border-bottom">
                    <h3 className="mb-0">{label}</h3>
                </Modal.Header>
                <Modal.Body className="p-3">
                    <Markdown source={popover} />
                </Modal.Body>
            </Modal>

            {button}
        </>
    ) : (
        <OverlayTrigger
            trigger="click"
            placement="left-start"
            key={name}
            rootClose
            overlay={
                <Popover id={name}>
                    {label && <Popover.Title as="h3">{label}</Popover.Title>}
                    <Popover.Content>
                        <Markdown source={popover} />
                    </Popover.Content>
                </Popover>
            }
        >
            {button}
        </OverlayTrigger>
    );
};
