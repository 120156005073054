import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useEventOnCampusValues } from '../../hooks/event-on-campus-values.hook';

interface Props {
    locationId: number;
}

export const OnCampusLocationDisplay = ({
    locationId,
}: Props): React.ReactElement<any, any> | null => {
    const { locations, buildings, campuses } = useEventOnCampusValues();

    const display = useMemo(() => {
        const location = locations.find(x => x.id === locationId);

        if (!location) {
            return 'Archived location';
        }

        const campus = campuses.find(x => x.id === location.campusId);
        const building = buildings.find(x => x.id === location.buildingId);

        return [campus, building, location]
            .filter(i => i !== undefined)
            .map(i => i!.name)
            .join(' / ');
    }, [buildings, campuses, locationId, locations]);

    return <Icon icon={faMapMarkerAlt}>{display}</Icon>;
};
