import { useEffect, useState, useCallback, createRef } from 'react';
import { useFormContext } from 'react-hook-form';
import Select from 'react-select';

export const useReactSelect = <IsMulti extends boolean>(
    name: string,
    options: { autoFocus?: boolean; isMulti: IsMulti }
) => {
    const { trigger } = useFormContext();

    const onBlur = useCallback(() => {
        void trigger(name);
    }, [trigger, name]);

    const {
        formState: { isSubmitted, errors },
    } = useFormContext();
    const [ref] = useState(createRef<Select<any, IsMulti>>());

    const refFocus = useCallback(() => {
        ref.current && typeof ref.current.focus === 'function' && ref.current.focus();
    }, [ref]);

    useEffect(() => {
        if (options.autoFocus) {
            refFocus();
        }
    }, [options.autoFocus, refFocus]);

    useEffect(() => {
        if (!errors || !isSubmitted) {
            return;
        }

        if (Object.keys(errors)[0] === name) {
            refFocus();
        }
    }, [isSubmitted, errors, name, refFocus]);

    return {
        ref,
        onBlur,
    };
};
