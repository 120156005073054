import { useMemo } from 'react';
import { useAuthentication } from './authentication.hook';
import { useLogout } from './logout.hook';

export const useAuthenticationLoading = () => {
    const { logoutTriggered } = useLogout();
    const {
        identitySetUserFetching,
        hasOidcUser,
        initComplete,
        identityListState,
        centralIdentityIsRedirecting,
    } = useAuthentication();

    const showLoading = useMemo(
        () =>
            (!hasOidcUser && !initComplete) ||
            logoutTriggered ||
            identitySetUserFetching ||
            identityListState.loading ||
            centralIdentityIsRedirecting,
        [
            centralIdentityIsRedirecting,
            hasOidcUser,
            identityListState.loading,
            identitySetUserFetching,
            initComplete,
            logoutTriggered,
        ]
    );

    return showLoading;
};
