import { entityListInitialState } from 'src/logic/helpers/initial-state.helper';
import { listReducerHandler } from 'src/logic/reducer-handlers/list.reducer-handler';
import { EntityPageRequest } from 'src/models/api/request.model';
import { Skill } from 'src/models/skills/skill.model';
import { EntityListState } from 'src/models/store-models/entity-list-state.model';
import { createReducer } from 'typesafe-actions';
import * as actions from './skill.actions';

// this is a bit hacked in, since I just get all skills on
// the event list page
export type SkillState = EntityListState<Skill, EntityPageRequest>;

const initialState: SkillState = {
    ...entityListInitialState,
};

const skillReducer = createReducer(initialState)
    .handleAction(actions.skillListAsync.request, listReducerHandler.request)
    .handleAction(actions.skillListAsync.success, listReducerHandler.success)
    .handleAction(actions.skillListAsync.failure, listReducerHandler.failure)
    .handleAction(actions.skillListAsync.cancel, listReducerHandler.cancel);

export default skillReducer;
