import { ValidateResult } from 'react-hook-form';

export const createMaxLengthRule = (max: number) => ({
    value: max,
    message: `This field must be ${max} characters or less.`,
});

export const urlPatternRule = {
    value: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
    message: 'Please provide a valid http, or https URL',
};

export const emailPatternRule = {
    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    message: 'Please provide a valid email',
};

// NOTE: this rule has been copied from the backend
const abnMessage = 'Please provide a valid ABN.';
const abnWeights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
export const abnRule = (abn: string): ValidateResult => {
    const value = abn.replace(/\s/g, '').replace('-', '');

    if (value.length !== 11) {
        return abnMessage;
    }

    let sum = 0;

    for (let i = 0; i < abnWeights.length; i++) {
        let digit = Number(value[i]);

        if (isNaN(digit)) {
            return abnMessage;
        }

        if (i === 0) {
            digit--;
        }
        sum += digit * abnWeights[i];
    }

    if (sum % 89 !== 0) {
        return abnMessage;
    }

    return;
};

export const arrayRequiredRule = (values: any[] | undefined): ValidateResult => {
    if (!values || values.length === 0) {
        return 'This field is required';
    }

    return undefined;
};
