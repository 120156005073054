import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';

export const CentralPublishInfo = (): React.ReactElement<any, any> | null => {
    const {
        central: {
            publish: { footnote },
        },
    } = useStrings();

    return (
        <Icon icon={faInfoCircle} iconClassName="text-info">
            <i>{footnote}</i>
        </Icon>
    );
};
