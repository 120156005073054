import { useCallback, useMemo } from 'react';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { Contact } from '../../../../models/contacts/contact.model';
import { identityHelper } from '../helpers/identity.helper';
import { useAuthentication } from './authentication.hook';

export const useIdentityContact = () => {
    const { activeIdentity } = useAuthentication();
    const identityContactId = useMemo(() => {
        if (!activeIdentity) {
            return undefined;
        }

        return identityHelper.isCentralContact(activeIdentity) ||
            identityHelper.isContact(activeIdentity)
            ? activeIdentity.contactId
            : undefined;
    }, [activeIdentity]);

    const isIdentityContact = useCallback(
        (contact: Partial<Contact> | undefined) => {
            if (!contact) {
                return false;
            }

            return contact.id === identityContactId;
        },
        [identityContactId]
    );

    const identityContact = useRootSelector(state =>
        identityContactId ? state.contacts.items[identityContactId] : undefined
    );

    return { identityContactId, isIdentityContact, identityContact };
};
