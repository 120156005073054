import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Upper } from 'src/ui/shared/components/text/Upper';
import { jobPaths } from '../../../../../routes/employer/paths/job.paths';
import { ButtonLink } from '../../../../shared/components/bootstrap/buttons/ButtonLink';
import { PageTitle } from '../../../../shared/components/page-components/PageTitle';
import { useStrings } from '../../../../shared/hooks/strings.hook';

export const JobListPageNav = (): React.ReactElement<any, any> | null => {
    const {
        job: { list },
        nav: { application },
    } = useStrings();

    return (
        <div className="d-flex align-items-center">
            <PageTitle>
                <Upper>{application.jobs}</Upper>
            </PageTitle>
            <ButtonLink className="ml-2" to={jobPaths.add} icon={faPlus}>
                {list.addAction}
            </ButtonLink>
        </div>
    );
};
