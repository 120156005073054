import { LOCATION_CHANGE } from 'connected-react-router';
import { of } from 'rxjs';
import { filter, ignoreElements, mergeMap, tap } from 'rxjs/operators';
import { isLocationRouteMatch } from 'src/logic/helpers/epics/location.epic-helper';
import { CentralContactIdentity, ContactIdentity } from 'src/models/authentication/identity.model';
import { centralPaths } from 'src/routes/employer/paths/central.paths';
import { identityHelper } from 'src/ui/features/authentication/helpers/identity.helper';
import { isActionOf, isOfType } from 'typesafe-actions';
import { RootEpic } from '../../epic.root-index';
import * as actions from '../central.actions';

export const centralPublishDetermineNextStepEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(
            isActionOf([
                actions.centralCreateUserConfirmation,
                actions.centralRegisterAsync.success,
                actions.centralContactInviteAsync.success,
                actions.centralJobCreateAsync.success,
                actions.centralJobAttachmentUploadAsync.success,
            ])
        ),
        mergeMap(() => {
            const { currentStep, publish } = state$.value.central;
            const payload = services.central.helper.determineNextStep({
                currentStep,
                publish,
            });
            return of(actions.centralPublishActionStep(payload));
        })
    );
};

export const centralPublishStep1OrganisationEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(actions.centralPublishActionStep)),
        filter(action => action.payload.step === 'organisation'),
        mergeMap(() => {
            const centralPublishState = state$.value.central.publish;
            const countryCode = state$.value.configuration.value.countryCode;

            const identity = state$.value.authentication.activeIdentity! as
                | ContactIdentity
                | CentralContactIdentity;

            // there should probably be a check here, to ensure this is an email, but it should always be an email
            const oidcEmail = state$.value.authentication.oidcUser?.profile.preferred_username;

            // this should be impossible to hit
            // but is necessary to send, as Central will only accept the token verified email.
            if (!oidcEmail) {
                throw new Error(
                    'User is not logged in or does not have an email according to OIDC'
                );
            }

            // this should never be hit.
            if (identityHelper.isIndividual(identity)) {
                throw new Error('Cannot create central organisation with an individual account');
            }

            const localContact = state$.value.contacts.items[identity.contactId!]!;
            const localMap = state$.value.central.configuration.clientCategoryMap;

            const centralRegistration = services.central.helper.createCentralRegistration(
                centralPublishState,
                {
                    verifiedContactEmail: oidcEmail,
                    localContact,
                    localMap,
                    countryCode,
                }
            );

            return of(actions.centralRegisterAsync.request(centralRegistration));
        })
    );
};

export const centralPublishStep2ContactEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(actions.centralPublishActionStep)),
        filter(action => action.payload.step === 'contactInvite'),
        mergeMap(action => {
            const contact =
                state$.value.contacts.items[
                    state$.value.central.publish.inviteContactIds![action.payload.index!]
                ]!;
            const centralOrganisationId = state$.value.central.publish.centralOrganisationId!;
            return of(
                actions.centralContactInviteAsync.request(
                    { centralOrganisationId, contact },
                    { index: action.payload.index! }
                )
            );
        })
    );
};

export const centralPublishStep3JobEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(actions.centralPublishActionStep)),
        filter(action => action.payload.step === 'job'),
        mergeMap(() => {
            const publishState = state$.value.central.publish;
            const localJob = publishState.job;
            const centralOrganisationId = publishState.centralOrganisationId!;
            const { centralJobEmploymentTypeIds, centralJobOccupationIds } = publishState;

            const centralOrganiastionPrimaryContactId =
                publishState.centralOrganisationPrimaryContactId;
            const identity = state$.value.authentication.activeIdentity!;

            // if the centralOrganisationPrimaryContactId exists, it indicates that the
            // organisation was just created. Therefore, we have to use it.
            // if it doesn't exist, it *should* mean that the organisation already exists
            // and the user is a contact of this organisation.
            // if it's undefined, just get them to fix it on central...? (publish and push user)
            const centralContactId = centralOrganiastionPrimaryContactId
                ? centralOrganiastionPrimaryContactId
                : identityHelper.isCentralContact(identity)
                ? identity.centralContactId
                : undefined;

            const localMapV2 = state$.value.central.configuration.clientCategoryMap;

            const centralJob = services.central.helper.createCentralJob({
                localJob: localJob!,
                centralOrganisationId,
                localMapV2,
                centralContactId,
                centralJobEmploymentTypeIds,
                centralJobOccupationIds,
            });

            return of(
                actions.centralJobCreateAsync.request({ job: centralJob, isInitialAttempt: true })
            );
        })
    );
};

export const centralPublishStep4JobAttachmentEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(actions.centralPublishActionStep)),
        filter(action => action.payload.step === 'jobAttachment'),
        mergeMap(action => {
            const attachment =
                state$.value.central.publish.job!.attachments[action.payload.index!]!;
            return of(
                actions.centralFromCareerHubJobAttachmentBlobAsync.request(
                    { id: attachment.storedFileId },
                    { index: action.payload.index!, fileMeta: attachment }
                )
            );
        })
    );
};

export const centralPublishStep5CompleteEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(actions.centralPublishActionStep)),
        filter(action => action.payload.step === 'complete'),
        tap(() => {
            const { centralOrganisationId, centralJobId, job } = state$.value.central.publish;
            const path = services.central.linkHelper.afterPublishRedirect({
                centralOrganisationId: centralOrganisationId!,
                centralJobId,
                expire: job?.expireDate,
                publish: job?.publishDate,
            });
            // this could be better
            const url = `${state$.value.configuration.value.centralAppPath}/${path}`;

            const window = services.windowService.getWindow();
            // this doesn't always work, thanks obama
            window.open(url);
        }),
        ignoreElements()
    );
};

export const centralPublishResetEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isOfType(LOCATION_CHANGE)),
        filter(() => state$.value.central.currentStep.step !== 'init'),
        filter(action => !isLocationRouteMatch(centralPaths.publish)(action)),
        mergeMap(() => of(actions.centralPublishReset()))
    );
};
