export const ORGANISATION_TYPE_OPTIONS = [
    'DirectEmployer',
    'AgentForEmployer',
    'NonProfit',
] as const;
type OrganisationTypeTuple = typeof ORGANISATION_TYPE_OPTIONS;
export type OrganisationType = OrganisationTypeTuple[number];

type OrganisationTypeMeta = { [key in OrganisationType]: string };

export const OrganisationTypeMeta: OrganisationTypeMeta = {
    AgentForEmployer: 'Agent For Employer',
    DirectEmployer: 'Organisation / Employer',
    NonProfit: 'Non-Profit Organisation',
};

// Note: this is only for Central organisations and is used in the identity
// results only
export const ORGANISATION_STATUS_OPTIONS = [
    'PendingApproval',
    'Rejected',
    'Active',
    'Disabled',
    'Deleted',
] as const;
type OrganisationStatusTuple = typeof ORGANISATION_STATUS_OPTIONS;
export type OrganisationStatus = OrganisationStatusTuple[number];
