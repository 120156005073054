import { Route, Switch } from 'react-router-dom';
import { ContactAddPage } from '../../ui/features/contacts/pages/ContactAddPage';
import { ContactDetailsPage } from '../../ui/features/contacts/pages/ContactDetailsPage';
import { ContactEditPage } from '../../ui/features/contacts/pages/ContactEditPage';
import { ContactListPage } from '../../ui/features/contacts/pages/ContactListPage';
import { contactPaths } from './paths/contact.paths';

export const ContactRouter = () => (
    <Switch>
        <Route path={contactPaths.edit} component={ContactEditPage} />
        <Route path={contactPaths.details} component={ContactDetailsPage} />
        <Route path={contactPaths.add} component={ContactAddPage} />
        <Route path={contactPaths.list} component={ContactListPage} />
    </Switch>
);
