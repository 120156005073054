import { CardWrapper } from '../../../shared/components/bootstrap/cards/CardWrapper';
import { LoaderText } from '../../../shared/components/loader/LoaderText';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';

// the logic is handled by epics bud ;)
export const FormRedirectPage = (): React.ReactElement<any, any> | null => {
    return (
        <ApplicationPageContent>
            <CardWrapper>
                <LoaderText loading={true}>You are being redirected.</LoaderText>
            </CardWrapper>
        </ApplicationPageContent>
    );
};
