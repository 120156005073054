import { createReducer } from 'typesafe-actions';
import { LoaderState } from '../../../models/errors/error.model';
import * as actions from './initialization.actions';

export type InitializationState = Readonly<{
    started: boolean;
    completed: boolean;
    loadState: LoaderState;
    stillWaiting: boolean;
}>;

const initialState: InitializationState = {
    started: false,
    completed: false,
    loadState: { loading: false },
    stillWaiting: false,
};

// bah! this can probably be simplified to a single loading state...
const initializationReducer = createReducer(initialState)
    .handleAction(actions.appInitStart, () => ({
        started: true,
        completed: false,
        loadState: { loading: true },
        stillWaiting: false,
    }))
    .handleAction(actions.appInitComplete, () => ({
        started: true,
        completed: true,
        loadState: { loading: false },
        stillWaiting: false,
    }))
    .handleAction([actions.appInitError, actions.masterEpicError], (state, action) => ({
        ...state,
        loadState: { loading: false, error: action.payload },
    }))
    .handleAction(actions.appInitStillWaiting, state => ({
        ...state,
        stillWaiting: true,
    }));

export default initializationReducer;
