import { useMemo } from 'react';
import { useRootSelector } from './root-selector.hook';

export const useStrings = () => useRootSelector(state => state.strings.value);

export const useDynamicString = (
    stringValue: string | string[],
    replacements: Record<string, string>
) => {
    const str = useStringsArray(stringValue);

    if (!str) {
        return undefined;
    }

    return Object.keys(replacements).reduce(
        (acc, current) =>
            // This isn't using regex because I want the dynamic variables to start with '$' but
            // this is obviously a regex pattern, so it can't be used.
            acc.split(current).join(replacements[current]),
        str
    );
};

export const useStringsArray = (stringValue: string | string[] | undefined) =>
    useMemo(() => {
        // Note: if you are importing from the strings state in redux
        // All the arrays are converted to strings when they are imported
        // but it's a bit too difficult to change the type definition, so i still allow it here.
        return Array.isArray(stringValue) ? stringValue.join('\n') : stringValue;
    }, [stringValue]);
