import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { LoaderState } from '../../../../models/errors/error.model';
import { AlertWrapper } from '../bootstrap/alerts/AlertWrapper';
import { CardWrapper } from '../bootstrap/cards/CardWrapper';
import { Icon } from '../icon/Icon';
import { LoaderSpinner } from './LoaderSpinner';

interface Props {
    state: LoaderState[] | LoaderState | boolean;
    useCard?: boolean;
    spinnerClassName?: string;
    size?: 'sm';
    hideSpinner?: boolean;
    loaderComponent?: React.ReactNode;
    children?: React.ReactNode;
}

export const Loader = ({
    state,
    children,
    useCard,
    spinnerClassName,
    size,
    hideSpinner,
    loaderComponent,
}: Props): React.ReactElement<any, any> | null => {
    const loading = useMemo(() => {
        if (typeof state === 'boolean') {
            return state;
        }

        if (!Array.isArray(state)) {
            return state.loading;
        }

        return state.some(loaderState => loaderState.loading);
    }, [state]);

    const errors = useMemo(() => {
        if (typeof state === 'boolean') {
            return undefined;
        }

        if (!Array.isArray(state)) {
            return state.error ? [state.error] : undefined;
        }

        const errorArray = state
            .filter(s => s.error)
            .map(s => s.error)
            .map(e => e!);

        return errorArray.length > 0 ? errorArray : undefined;
    }, [state]);

    if (errors) {
        return useCard ? (
            <AlertWrapper variant="danger" icon={faExclamationTriangle}>
                <span className="large">{errors.map(e => e.message).join(', ')}</span>
            </AlertWrapper>
        ) : (
            <span className="text-danger">
                <Icon icon={faExclamationTriangle}>{errors.map(e => e.message).join(', ')}</Icon>
            </span>
        );
    }

    if (loading && hideSpinner) {
        return null;
    }

    if (loading) {
        if (useCard) {
            return (
                <CardWrapper>
                    <LoaderSpinner spinnerClassName={spinnerClassName} size={size} />
                </CardWrapper>
            );
        }

        return loaderComponent ? (
            <>{loaderComponent}</>
        ) : (
            <LoaderSpinner spinnerClassName={spinnerClassName} size={size} />
        );
    }

    return <>{children}</>;
};
