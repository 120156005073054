import { useMemo } from 'react';
import { FieldError, FieldErrorsImpl, useFormContext } from 'react-hook-form';

// allows for dot notation in the name
export const useFormError = (name: string, errorIndex?: number): FieldError | undefined => {
    // be careful using "errors", it's always the same object, which means
    // that it's problematic to use with memos, because the reference will always be the same.
    const {
        formState: { errors },
    } = useFormContext();

    const inner = name
        ?.split('.')
        .reduce(
            (errorObj, namePartial) =>
                errorObj &&
                (errorObj[namePartial] as Partial<FieldErrorsImpl<{ [x: string]: any }>>),
            errors
        );

    const controlError = useMemo(() => {
        if (!name) {
            return undefined;
        }

        return errorIndex !== undefined && Array.isArray(inner) ? inner[errorIndex] : inner;
    }, [errorIndex, inner, name]);

    return controlError;
};
