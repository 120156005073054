export const NotFound = ({
    className,
}: {
    className?: string;
}): React.ReactElement<any, any> | null => {
    return (
        <div className={`display-4 text-secondary mb-5 ${className || ''}`}>
            404 - Page not found
        </div>
    );
};
