import { SelectOption } from './SelectControl';

interface Props {
    items: ReadonlyArray<string | number | SelectOption<any>>;
    allowEmpty?: boolean;
    placeholder?: string;
}

export const SelectOptionItems = (props: Props): React.ReactElement<any, any> | null => {
    const { items, allowEmpty, placeholder } = props;

    return (
        <>
            {placeholder && (
                <option value="" disabled hidden>
                    {placeholder}
                </option>
            )}
            {allowEmpty ? <option value=""></option> : null}
            {items.map(i =>
                typeof i !== 'object' ? (
                    <option key={i} value={i}>
                        {i}
                    </option>
                ) : (
                    <option key={i.value} value={i.value}>
                        {i.label}
                    </option>
                )
            )}
        </>
    );
};
