import { CSSProperties } from 'react';

export const Circle = (props: { style?: CSSProperties; className?: string }) => (
    <svg
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={props.style}
        className={props.className}
    >
        <path
            // eslint-disable-next-line max-len
            d="M23 45.625C35.3926 45.625 45.5 35.6055 45.5 23.125C45.5 10.7324 35.3926 0.625 23 0.625C10.5195 0.625 0.5 10.7324 0.5 23.125C0.5 35.6055 10.5195 45.625 23 45.625Z"
            fill="currentColor"
        />
    </svg>
);
