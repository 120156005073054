import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Icon } from '../../../shared/components/icon/Icon';
import { useOuterClick } from '../../../shared/hooks/outer-click.hook';
import { useContent } from '../hooks/content.hook';
import { ContentMenuItems } from './ContentMenuItems';

export const ContentMenu = (): React.ReactElement<any, any> | null => {
    const { activeCollection } = useContent('employerContent');

    const [show, setShow] = useState(false);

    const location = useLocation();

    useEffect(() => {
        setShow(false);
    }, [location]);

    const ref = useOuterClick(() => setShow(false));

    const onToggleClick = useCallback(() => {
        setShow(!show);
    }, [show]);

    return (
        <Dropdown show={show} ref={ref}>
            <Dropdown.Toggle
                id="content-search"
                onClick={onToggleClick}
                variant="link-content-nav"
                className="p-0 btn-no-focus no-icon h1 mb-0 mr-1 d-flex"
            >
                <Icon
                    icon={faCaretDown}
                    iconClassName="text-content-nav"
                    className="text-wrap text-left"
                >
                    {activeCollection?.title || 'Menu'}
                </Icon>
            </Dropdown.Toggle>
            <Dropdown.Menu className="content-nav-dropdown-menu">
                <div className="px-4 py-2">
                    <ContentMenuItems contentKey="employerContent" />
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};
