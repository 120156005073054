import { useMemo } from 'react';
import { useClient } from 'src/ui/shared/hooks/client.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

export const useIsCentralPostingEnabled = () => {
    const { countryCode } = useClient();
    const isCentralNode = useRootSelector(
        state => state.apiConfiguration.value?.settings?.centralBackgroundTaskEnabled
    );
    const isDisabledInConfig = useRootSelector(
        state => state.configuration.value.disableMultischoolPosting
    );

    const isAuOrGb = useMemo(() => countryCode === 'AU' || countryCode === 'GB', [countryCode]);

    const isEnabled = useMemo(
        () => !!isCentralNode && !isDisabledInConfig && isAuOrGb,
        [isAuOrGb, isCentralNode, isDisabledInConfig]
    );

    return isEnabled;
};
