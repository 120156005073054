import { ContentLayout } from 'src/ui/shared/components/layout/ContentLayout';
import { NotFound } from 'src/ui/shared/components/page-components/NotFound';
import { ThinPageContent } from 'src/ui/shared/components/page-components/ThinPageContent';

export const NotFoundRouteLayout = () => {
    return (
        <ContentLayout>
            <ThinPageContent>
                <NotFound className="text-center" />
            </ThinPageContent>
        </ContentLayout>
    );
};
