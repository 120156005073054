import { concat, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { asyncEpicBase, asyncEpicStandard } from 'src/logic/helpers/epics/async.epic-helper';
import { listEpic } from 'src/logic/helpers/epics/list-route.epic-helper';
import { contactPaths } from 'src/routes/employer/paths/contact.paths';
import { isActionOf } from 'typesafe-actions';
import { RootEpic } from '../../epic.root-index';
import * as actions from '../contact.actions';

export const contactListEpic = listEpic({
    path: contactPaths.list,
    getParams: (action, _, { queryParams }) =>
        queryParams.contactListParams(action.payload.location.search),
    getListState: state => state.contacts,
    setActiveActionCreator: actions.contactListSetActive,
    cancelActionCreator: actions.contactListAsync.cancel,
    requestActionCreator: actions.contactListAsync.request,
});

export const contactListRequestEpic = asyncEpicBase(
    actions.contactListAsync,
    ({ api }, { payload }) => api.contacts.list(payload),
    {
        success: result => actions.contactListAsync.success(result.json),
        failure: (error, ra) => actions.contactListAsync.failure(error, ra.payload),
    }
);

export const contactListForceNextReloadEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(
            isActionOf([
                actions.contactCreateAsync.success,
                actions.contactUpdateAsync.success,
                actions.contactDeleteAsync.success,
            ])
        ),
        mergeMap(() =>
            state$.value.router.location.pathname === contactPaths.list
                ? concat([
                      actions.contactListClear({ excludeActive: false }),
                      actions.contactListSetActive(state$.value.contacts.activeList),
                  ])
                : of(actions.contactListClear({ excludeActive: false }))
        )
    );
};

// special all call for contacts
export const contactAllEpic = asyncEpicStandard(actions.contactAllAsync, ({ api }) =>
    api.contacts.all()
);
