import { LOCATION_CHANGE } from 'connected-react-router';
import { StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { ErrorNormalized } from 'src/models/errors/error.model';
import { isActionOf } from 'typesafe-actions';
import {
    ContentPageBaseParams,
    contentPaths,
    replaceCollectionTitle,
} from '../../../routes/content/content.paths';
import { homePath } from '../../../routes/layouts/HomeRouteLayout';
import { contentAsync, contentSetActive } from './content.actions';
import { RootState } from '../reducer.root-index';
import { RootEpic } from '../epic.root-index';
import {
    delayActionUntilAppInit,
    delayTypeUntil,
    delayTypeUntilAppInit,
} from '../../helpers/epics/app-init.epic-helper';
import { getRouteMatch } from '../../../logic/helpers/epics/location.epic-helper';

const setActiveHomePath =
    (state$: StateObservable<RootState>) =>
    <T>(obs: Observable<T>) => {
        return obs.pipe(
            mergeMap(i =>
                of(
                    contentSetActive({
                        key: 'employerContent',
                        activeCollectionId: state$.value.content.employerContent.firstCollectionId,
                    })
                )
            )
        );
    };

// initial epic for app_init action
export const contentInitialActiveCollectionId: RootEpic = (action$, state$) => {
    return delayActionUntilAppInit(action$, contentAsync.success).pipe(
        filter(
            i =>
                state$.value.router.location.pathname === homePath &&
                state$.value.content.employerContent.activeCollectionId !==
                    state$.value.content.employerContent.firstCollectionId
        ),
        setActiveHomePath(state$)
    );
};

// special epic for home path
export const contentHomeEpic: RootEpic = (action$, state$) => {
    return delayTypeUntilAppInit(action$, LOCATION_CHANGE).pipe(
        filter(
            i =>
                i.payload.location.pathname === homePath &&
                state$.value.content.employerContent.activeCollectionId !==
                    state$.value.content.employerContent.firstCollectionId
        ),
        setActiveHomePath(state$)
    );
};

// todo: consider the two epics below, surely can be better
// bah! be better!
export const employerContentKeyRouteEpic: RootEpic = (action$, state$) => {
    return delayTypeUntil(action$, contentAsync.success, LOCATION_CHANGE).pipe(
        map(i => getRouteMatch<ContentPageBaseParams>(i.payload.location, contentPaths.public)),
        filter(match => !!(match && match.params.collection)),
        mergeMap(match => {
            const activeTitle = replaceCollectionTitle(match!.params.collection);
            const active = state$.value.content['employerContent'].context.collections.find(
                c => replaceCollectionTitle(c.title) === activeTitle
            );

            return of(
                contentSetActive({
                    key: 'employerContent',
                    activeCollectionId: active?.id,
                })
            );
        })
    );
};

export const employerAppKeyRouteEpic: RootEpic = (action$, state$) => {
    return delayTypeUntil(action$, contentAsync.success, LOCATION_CHANGE).pipe(
        map(i => getRouteMatch<ContentPageBaseParams>(i.payload.location, contentPaths.protected)),
        filter(match => !!(match && match.params.collection)),
        mergeMap(match => {
            const activeTitle = replaceCollectionTitle(match!.params.collection);
            const active = state$.value.content['employerProtected'].context.collections.find(
                c => replaceCollectionTitle(c.title) === activeTitle
            );

            return of(
                contentSetActive({
                    key: 'employerProtected',
                    activeCollectionId: active?.id,
                })
            );
        })
    );
};

// this calls for the employer protected content once the other
// content is found. This is probably unnecessary, but whatever.
// the 'employerContent' content is always called on start up
// NOTE: I originally attempted to do this on location naviagtion
// but kept getting weird 'Cannot access 'applicationBasePaths' before initialization' errors
export const contentAsyncRequestEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(contentAsync.success)),
        filter(action => action.meta.key === 'employerContent'),
        filter(() => {
            const contentState = state$.value.content['employerProtected'];
            return !contentState.loaded && !contentState.fetchState.loading;
        }),
        mergeMap(() => of(contentAsync.request({ key: 'employerProtected' })))
    );
};

export const contentAsyncEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(contentAsync.request)),
        switchMap(action =>
            services.content.get(action.payload.key, state$.value.configuration).pipe(
                map(result => contentAsync.success(result.json, { key: action.payload.key })),
                catchError((error: ErrorNormalized) =>
                    of(contentAsync.failure(error, action.payload))
                )
            )
        )
    );
};
