import { SiteDisplay } from '@careerhub/content-shared';
import { Helmet } from 'react-helmet';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { NotFound } from 'src/ui/shared/components/page-components/NotFound';
import { ContentFooter } from '../components/ContentFooter';
import { useContentAnchorOverride } from '../hooks/content-anchor-override.hook';
import { useContentScroll } from '../hooks/content-scroll.hook';
import { useContent } from '../hooks/content.hook';

export const PublicContentPage = (): React.ReactElement<any, any> | null => {
    const { context, documentTitle, activeCollectionId, fetchState, config } =
        useContent('employerContent');
    const { onClick } = useContentAnchorOverride();

    useContentScroll('employerContent');

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            {fetchState.loading && (
                <div className="text-center my-5">
                    <Loader state={fetchState} />
                </div>
            )}
            <Loader state={fetchState} hideSpinner={true}>
                {activeCollectionId ? (
                    <div className="content-wrapper" onClick={onClick}>
                        <SiteDisplay
                            context={context}
                            config={config}
                            activeCollectionId={activeCollectionId}
                            contentClassName="mx-auto"
                        />
                        <ContentFooter />
                    </div>
                ) : (
                    <NotFound className="mt-5 text-center" />
                )}
            </Loader>
        </>
    );
};
