import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useActiveEntityList } from 'src/ui/shared/hooks/active-entity-list.hook';
import { Loader } from '../../../loader/Loader';
import { HookControlProps } from '../../HookForm';
import { MultiSelectControl } from './MultiSelectControl';
import { ReactSelectOption } from './SingleSelectControl';

interface Props extends HookControlProps {
    maxItems?: number;
    otherSkillControlName?: string; // surely there's a better name than this
}

export const SkillMultiSelectControl = (props: Props): React.ReactElement<any, any> | null => {
    const { fetch, entities: skills } = useActiveEntityList(state => state.skills);
    const { watch } = useFormContext();
    const otherSkillValue = props.otherSkillControlName
        ? (watch(props.otherSkillControlName) as number[] | undefined)
        : undefined;

    const options: ReactSelectOption<number>[] = useMemo(
        () =>
            skills
                ? skills
                      .filter(skill =>
                          otherSkillValue ? !otherSkillValue.includes(skill.id) : true
                      )
                      .map(skill => ({
                          label: skill.name,
                          value: skill.id,
                          tokens: skill.definition?.toLowerCase().split(' ') || [],
                      }))
                : [],
        [otherSkillValue, skills]
    );

    return (
        <Loader state={fetch} useCard={true}>
            <MultiSelectControl {...props} options={options} />
        </Loader>
    );
};
