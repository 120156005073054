import { useAuthentication } from '../hooks/authentication.hook';
import { GuardLoader } from './GuardLoader';

interface Props {
    children: React.ReactNode;
}

export const IdentityRouteGuard = ({ children }: Props): React.ReactElement<any, any> | null => {
    const { hasOidcUser } = useAuthentication();

    return hasOidcUser ? <>{children}</> : <GuardLoader state={true} />;
};
