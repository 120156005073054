import { LOCATION_CHANGE, push } from 'connected-react-router';
import { concat, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { delayTypeUntilAuthenticated } from 'src/logic/helpers/epics/app-init.epic-helper';
import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { routeEpics } from 'src/logic/helpers/epics/entity-route.epic-helper';
import { listEpic } from 'src/logic/helpers/epics/list-route.epic-helper';
import { getRouteMatch, isLocationRouteMatch } from 'src/logic/helpers/epics/location.epic-helper';
import { redirectEpic } from 'src/logic/helpers/epics/redirect.epic-helper';
import { EventRequestPublish } from 'src/models/events/event-request.model';
import {
    EventPageBaseParams,
    eventPathCreator,
    eventPaths,
} from 'src/routes/employer/paths/event.paths';
import { isActionOf } from 'typesafe-actions';
import { RootEpic } from '../epic.root-index';
import * as actions from './event-union.actions';

export const eventUnionForceNextReloadEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(
            isActionOf([
                actions.eventRequestCreateAsync.success,
                actions.eventRequestUpdateAsync.success,
                actions.eventRequestDeleteAsync.success,
                actions.eventRequestRevertAsync.success,
                actions.eventRequestPublishAsync.success,
            ])
        ),
        mergeMap(() =>
            state$.value.router.location.pathname === eventPaths.list
                ? concat([
                      actions.eventUnionListClear({ excludeActive: false }),
                      actions.eventUnionListSetActive(state$.value.eventUnions.activeList),
                  ])
                : of(actions.eventUnionListClear({ excludeActive: false }))
        )
    );
};

export const eventUnionListEpic = listEpic({
    path: eventPaths.list,
    getParams: (action, _, { queryParams }) =>
        queryParams.eventUnionListParams(action.payload.location.search),
    getListState: state => state.eventUnions,
    setActiveActionCreator: actions.eventUnionListSetActive,
    cancelActionCreator: actions.eventUnionListAsync.cancel,
    requestActionCreator: actions.eventUnionListAsync.request,
});

export const eventUnionListAsyncEpic = asyncEpicBase(
    actions.eventUnionListAsync,
    (services, ra) => services.api.eventUnions.list(ra.payload),
    {
        success: result => actions.eventUnionListAsync.success(result.json),
        failure: (error, ra) => actions.eventUnionListAsync.failure(error, ra.payload),
    }
);

export const eventUnionRouteEpics = routeEpics<EventPageBaseParams>({
    getMatch: location => getRouteMatch(location.payload.location, eventPaths.details),
    getListState: state => state.eventUnions,
    cancelActionCreator: actions.eventUnionAsync.cancel,
    getId: match => Number(match.params.eventId),
    setActiveActionCreator: actions.eventUnionSetActive,
    requestActionCreator: actions.eventUnionAsync.request,
});

export const eventUnionAsyncEpic = asyncEpicBase(
    actions.eventUnionAsync,
    ({ api }, { payload }) => api.eventUnions.single(payload),
    {
        success: result => actions.eventUnionAsync.success(result.json),
        failure: (error, ra) => actions.eventUnionAsync.failure(error, ra.payload),
    },
    {
        cancelFilter: (cancelAction, requestAction) =>
            cancelAction.payload.id === requestAction.payload.id,
    }
);

export const eventRequestCreateAsyncEpic = asyncEpicBase(
    actions.eventRequestCreateAsync,
    (services, ra) => {
        if (ra.payload.banner) {
            return services.api.eventUnions
                .bannerUpload(ra.payload.banner.fileName, ra.payload.banner.blob)
                .pipe(
                    mergeMap(result =>
                        services.api.eventUnions.create({
                            ...ra.payload.eventRequest,
                            bannerKey: result.json.data.key,
                        })
                    )
                );
        }

        return services.api.eventUnions.create(ra.payload.eventRequest);
    },
    {
        success: (result, ra) => actions.eventRequestCreateAsync.success(result.json, ra.meta),
        failure: (error, ra) => actions.eventRequestCreateAsync.failure(error, ra.meta),
    }
);

export const eventRequestCreateRedirectEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(
            isActionOf([
                actions.eventRequestCreateAsync.success,
                actions.eventRequestUpdateAsync.success,
            ])
        ),
        mergeMap(action =>
            action.meta.redirectToPublish
                ? of(push(eventPathCreator.publish(action.payload.data.id)))
                : of(push(eventPathCreator.details(action.payload.data.id)))
        )
    );
};

export const eventRequestPublishAsyncEpic = asyncEpicBase(
    actions.eventRequestPublishAsync,
    ({ api }, { payload }) => api.eventUnions.publish(payload),
    {
        success: (result, ra) => actions.eventRequestPublishAsync.success(result.json, ra.payload),
        failure: (error, ra) => actions.eventRequestPublishAsync.failure(error, ra.payload),
    }
);

export const eventRequestRevertAsyncEpic = asyncEpicBase(
    actions.eventRequestRevertAsync,
    ({ api }, { payload }, state$) => {
        const er = state$.value.eventUnions.items[payload.id]!.eventRequest!;
        // this is pretty dodgy, could absolutely cause concurrency issues.
        const publishObj: EventRequestPublish = {
            id: er.id,
            additionalNote: er.additionalNote,
            targetingNote: er.targetingNote,
            contactId: er.contactId,
            publish: null as any,
        };

        return api.eventUnions.publish(publishObj);
    },
    {
        success: (result, ra) => actions.eventRequestRevertAsync.success(result.json, ra.payload),
        failure: (error, ra) => actions.eventRequestRevertAsync.failure(error, ra.payload),
    }
);

export const eventRequestUpdatePublishRedirectEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(actions.eventRequestPublishAsync.success)),
        mergeMap(action => of(push(eventPathCreator.publishConfirmation(action.payload.data.id))))
    );
};

export const eventRequestUpdateAsyncEpic = asyncEpicBase(
    actions.eventRequestUpdateAsync,
    ({ api }, { payload }) => {
        if (payload.banner) {
            return api.eventUnions.bannerUpload(payload.banner.fileName, payload.banner.blob).pipe(
                mergeMap(result =>
                    api.eventUnions.update({
                        ...payload.eventRequest,
                        bannerKey: result.json.data.key,
                    })
                )
            );
        }

        return api.eventUnions.update(payload.eventRequest);
    },
    {
        success: (result, ra) => actions.eventRequestUpdateAsync.success(result.json, ra.meta),
        failure: (error, ra) => actions.eventRequestUpdateAsync.failure(error, ra.meta),
    }
);

// see job epics for why this is relevant
export const eventRequestAddPageResetActiveId: RootEpic = (action$, state$) => {
    return delayTypeUntilAuthenticated(action$, state$, LOCATION_CHANGE).pipe(
        filter(isLocationRouteMatch(eventPaths.add)),
        mergeMap(() => of(actions.eventUnionSetActive({ id: undefined })))
    );
};

export const eventRequestDeleteEpic = asyncEpicBase(
    actions.eventRequestDeleteAsync,
    ({ api }, { payload }) => api.eventUnions.del(payload),
    {
        success: result => actions.eventRequestDeleteAsync.success(result.json),
        failure: (error, requestAction) =>
            actions.eventRequestDeleteAsync.failure(error, requestAction.payload),
    }
);

export const eventRequestDeleteRedirectEpic = redirectEpic({
    when: actions.eventRequestDeleteAsync.success,
    from: eventPaths.details,
    to: eventPathCreator.listDefault(),
});

export const eventCancelAsyncEpic = asyncEpicBase(
    actions.eventCancelAsync,
    (services, ra) => services.api.eventUnions.cancel(ra.payload),
    {
        success: (result, ra) => actions.eventCancelAsync.success(result.json),
        failure: (error, ra) => actions.eventCancelAsync.failure(error, ra.payload),
    }
);

export const eventCancelRedirectEpic = redirectEpic({
    when: actions.eventCancelAsync.success,
    from: [eventPaths.details, eventPaths.list],
    to: eventPathCreator.listDefault(),
});
