import { appStringify } from '../../router.helper';

type CentralPageBaseParams = {
    jobId?: number;
};

export const centralPaths = {
    publish: '/central/publish',
};

export const centralPathCreator = {
    publish: (params?: CentralPageBaseParams) => `/central/publish${appStringify(params)}`,
};
