import { Container } from 'react-bootstrap';
import { ContentTopNav } from '../navigation/content-navigation/ContentTopNav';
import { ComponentDisabledGuard } from './ComponentDisabledGuard';

interface Props {
    children: React.ReactNode;
}

export const ThinLayout = ({ children }: Props): React.ReactElement<any, any> | null => {
    return (
        <ComponentDisabledGuard>
            <Container fluid className="p-0 thin-layout-container">
                <ContentTopNav />

                <div>{children}</div>
            </Container>
        </ComponentDisabledGuard>
    );
};
