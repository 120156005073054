import { HookControlProps } from '../../HookForm';
import { ControlFormGroup } from '../../shared/ControlFormGroup';
import { RadioControlItem } from './RadioControlItem';

export interface RadioOption {
    key: string | number;
    label: React.ReactNode;
}

interface RadioControlProps extends HookControlProps {
    options: RadioOption[];
}

export const RadioControl = (props: RadioControlProps): React.ReactElement<any, any> | null => {
    const { options, rules, name, tabIndex, disabled } = props;

    return (
        <ControlFormGroup {...props}>
            {options.map(o => (
                <RadioControlItem
                    key={o.key}
                    option={o}
                    name={name}
                    rules={rules}
                    tabIndex={tabIndex}
                    disabled={disabled}
                />
            ))}
        </ControlFormGroup>
    );
};
