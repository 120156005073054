import { asyncEpicStandard } from 'src/logic/helpers/epics/async.epic-helper';
import * as actions from '../central.actions';

export const centralOrganisationListAsyncEpic = asyncEpicStandard(
    actions.centralOrganisationListAsync,
    (services, action) => services.central.api.organisationQuery(action.payload)
);

export const centralSimilarOrganisationListAsyncEpic = asyncEpicStandard(
    actions.centralSimilarOrganisationListAsync,
    (services, action) => services.central.api.organisationSimilarQuery(action.payload)
);

export const centralRegisterAsyncEpic = asyncEpicStandard(
    actions.centralRegisterAsync,
    (services, action) => services.central.api.register(action.payload)
);
