import { Col, Form } from 'react-bootstrap';
import { HookForm } from '../../../../shared/components/forms/HookForm';

export const JobDetailsContactControls = (): React.ReactElement<any, any> | null => {
    return (
        <>
            <Form.Row>
                <Col>
                    <HookForm.ContactSelect
                        label="Administrative Contact"
                        name="adminContactId"
                        allowEmpty={true}
                        rules={{ required: true }}
                    />
                </Col>
                <Col>
                    <HookForm.ContactSelect
                        label="Display Contact"
                        name="displayContactId"
                        allowEmpty={true}
                    />
                </Col>
            </Form.Row>
        </>
    );
};
