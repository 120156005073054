import { LOCATION_CHANGE, replace } from 'connected-react-router';
import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { asyncEpicStandard } from 'src/logic/helpers/epics/async.epic-helper';
import { isLocationRouteMatch } from 'src/logic/helpers/epics/location.epic-helper';
import { registerPaths } from 'src/routes/register/register.paths';
import { isOfType } from 'typesafe-actions';
import { RootEpic } from '../../epic.root-index';
import { registerOrganisationAsync } from '../register.actions';

export const registerOrganisationEpic = asyncEpicStandard(
    registerOrganisationAsync,
    ({ api }, { payload }) => api.register.registerOrganisation(payload)
);

export const registerRedirectToStartEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isOfType(LOCATION_CHANGE)),
        filter(
            isLocationRouteMatch([
                registerPaths.contact,
                registerPaths.organisation,
                registerPaths.similar,
            ])
        ),
        filter(() => !state$.value.register.name),
        mergeMap(() => of(replace(registerPaths.start)))
    );
};
