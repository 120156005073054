import { getClientConfig } from 'src/clients-internal/configuration.init';
import { ApiResponse } from 'src/models/api/response.model';
import { ObsApiExpected } from 'src/models/api/service.model';
import { WorkGroup } from 'src/models/work-groups/work-group.model';
import { fetchHelper } from '../../helpers/fetch.helper';

const basePath = '/services/employers/v1/workgroups';

export function list(): ObsApiExpected<ApiResponse<WorkGroup[]>> {
    return fetchHelper.get(`${getClientConfig().careerHubBasePath}${basePath}`);
}
