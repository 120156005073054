import { useMemo } from 'react';
import { useClient } from '../../../shared/hooks/client.hook';

export const useCentralLink = (paths: string | string[]) => {
    const { centralAppPath } = useClient();

    return useMemo(() => {
        const pathString = Array.isArray(paths) ? paths.join('/') : paths;
        return `${centralAppPath}/${pathString}`;
    }, [centralAppPath, paths]);
};
