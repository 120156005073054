import { useMemo } from 'react';

interface Props {
    source: string;
}
// there's probably a more efficient way to do this.
export const BulletPointDetails = ({ source }: Props): React.ReactElement<any, any> => {
    // creates an array of strings and string arrays
    // the strings are paragraphs, and the string arrays are 'uls'
    const array = useMemo(() => {
        const lines = source.split('\n');

        const inner: (string | string[])[] = [];
        lines.forEach(value => {
            if (value.startsWith('*')) {
                if (inner.length === 0) {
                    inner.push([value]);
                } else if (Array.isArray(inner[inner.length - 1])) {
                    (inner[inner.length - 1] as string[]).push(value);
                } else {
                    inner.push([value]);
                }
            } else {
                inner.push(value);
            }
        });

        return inner.filter(x => x !== '');
    }, [source]);

    return (
        <>
            {array.map((line, index) => (
                <BulletPointDetailLine key={index} line={line} />
            ))}
        </>
    );
};

const BulletPointDetailLine = ({ line }: { line: string | string[] }) => {
    if (typeof line === 'string') {
        return line.length > 0 ? <p>{line}</p> : null;
    }

    return (
        <ul>
            {line.map((value, index) => (
                <li key={index}>{value.length > 0 ? value.substring(1) : value}</li>
            ))}
        </ul>
    );
};
