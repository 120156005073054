import { getClientConfig } from 'src/clients-internal/configuration.init';

export type FormRedirectParams = {
    formId: string;
    submissionId?: string;
};

export const formPaths = {
    // list is only used as a base path, but it's likely this will
    // be expanded in the future
    list: '/forms',
    redirect: '/forms/:formId(\\d+)',
    redirectSubmission: '/forms/:formId(\\d+)/submissions/:submissionId(\\d+)',
    submissions: '/form-submissions',
};

export const formPathExternalCreator = {
    form: (id: number) => `${getClientConfig().careerHubBasePath}/Form.aspx?id=${id}`,
    submission: (formId: number, submissionId: number) =>
        `${getClientConfig().careerHubBasePath}/Form.aspx?id=${formId}&sid=${submissionId}`,
};
