import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback } from 'react';
import { InputGroup } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useFormError } from 'src/ui/shared/hooks/form-error.hook';
import { IconDefinitionAll } from '../../icon/Icon';
import { HookControlProps } from '../HookForm';
import { ControlFormGroup } from '../shared/ControlFormGroup';

export interface HookInputControlProps extends HookControlProps {
    inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
    hidden?: boolean;
    normalize?: (val: string) => string;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    icon?: IconDefinitionAll;
    append?: React.ReactNode;
    prepend?: React.ReactNode;
    removeMaxCount?: boolean;
    readonly?: boolean;
    type?: string;
    // used with type datetime-local, can also be used with numeric
    min?: string;
    max?: string;
}

export const InputControl = (props: HookInputControlProps): React.ReactElement<any, any> | null => {
    const {
        icon,
        rules,
        name,
        errorName,
        errorIndex,
        placeholder,
        disabled,
        tabIndex,
        autoFocus,
        inputMode,
        hidden,
        normalize,
        onKeyDown,
        append,
        prepend,
        readonly,
        type,
        min,
        max,
    } = props;
    const { register } = useFormContext();

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!normalize || !event.target.value) {
                return;
            }

            event.target.value = normalize(event.target.value);
        },
        [normalize]
    );

    const controlError = useFormError(errorName || name, errorIndex);

    return (
        <ControlFormGroup {...props}>
            <InputGroup>
                {(icon || prepend) && (
                    <>
                        {icon && (
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={icon} size="lg" />
                            </InputGroup.Text>
                        )}
                        {prepend}
                    </>
                )}
                <input
                    disabled={disabled}
                    readOnly={readonly}
                    tabIndex={tabIndex}
                    className={`form-control ${controlError ? 'is-invalid' : ''}`}
                    inputMode={inputMode}
                    hidden={hidden}
                    placeholder={placeholder}
                    onKeyDown={onKeyDown ? onKeyDown : undefined}
                    type={type}
                    autoFocus={autoFocus}
                    min={min}
                    max={max}
                    {...register(name, { ...rules, onChange: onChange })}
                />
                {append}
            </InputGroup>
        </ControlFormGroup>
    );
};
