import { push } from 'connected-react-router';
import { EMPTY, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { registerPaths } from 'src/routes/register/register.paths';
import { isActionOf } from 'typesafe-actions';
import { RootEpic } from '../../epic.root-index';
import { registerDetailsStore } from '../register.actions';

export const redirectAfterDetailsEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(registerDetailsStore)),
        mergeMap(() => of(push(registerPaths.contact)))
    );
};

export const afterDetailsStoreLogoEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(registerDetailsStore)),
        filter(action => !!action.payload.logo),
        mergeMap(action => {
            services.api.register.storeLogo(action.payload.logo!);
            return EMPTY;
        })
    );
};
