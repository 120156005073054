import { useMemo } from 'react';
import { Redirect } from 'react-router';
import { eventPathCreator } from 'src/routes/employer/paths/event.paths';
import { jobPathCreator } from 'src/routes/employer/paths/job.paths';
import { organisationPaths } from 'src/routes/employer/paths/organisation.paths';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { ThinPageContent } from 'src/ui/shared/components/page-components/ThinPageContent';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

export const AuthLandingPage = (): React.ReactElement<any, any> | null => {
    const { value } = useApiConfiguration();
    const apiConfigState = useRootSelector(state => state.apiConfiguration.fetch);

    const redirect = useMemo(() => {
        if (!value) {
            return undefined;
        }

        // have to cater for previous versions where these settings did not exist
        // if the setting doesn't exist, it won't be equal to 'true', and therefore will
        // redirect to jobs. Which is what we want
        if (value.settings?.disableJobs !== true) {
            return jobPathCreator.listDefault();
        }

        if (value.settings?.disableEvents !== true) {
            return eventPathCreator.listDefault();
        }

        // this is the same path for individuals and orgs
        // bit hacky to not check this
        return organisationPaths.details;
    }, [value]);

    return (
        <ThinPageContent>
            <Loader state={apiConfigState}>{redirect && <Redirect to={redirect} />}</Loader>
        </ThinPageContent>
    );
};
