import { createAction, createAsyncAction } from 'typesafe-actions';
import { ApiResponse } from '../../../models/api/response.model';
import { ErrorNormalized } from '../../../models/errors/error.model';
import { Setting } from '../../../models/settings/setting.model';

export const settingsResetState = createAction('SETTINGS_RESET_STATE')();

export const settingsAsync = createAsyncAction(
    'SETTINGS:REQUEST',
    'SETTINGS:SUCCESS',
    'SETTINGS:FAILURE',
    'SETTINGS:CANCEL'
)<undefined, ApiResponse<Setting[]>, ErrorNormalized, undefined>();

export const settingsUpdateAsync = createAsyncAction(
    'SETTINGS_UPDATE:REQUEST',
    'SETTINGS_UPDATE:SUCCESS',
    'SETTINGS_UPDATE:FAILURE'
)<Setting[], ApiResponse<Setting[]>, ErrorNormalized>();
