import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CardWrapper } from '../bootstrap/cards/CardWrapper';
import { Icon } from '../icon/Icon';

export const DeletedCard = (): React.ReactElement<any, any> | null => {
    return (
        <CardWrapper>
            <Icon icon={faTimes} iconClassName="text-danger">
                This entity has been deleted
            </Icon>
        </CardWrapper>
    );
};
