import { push } from 'connected-react-router';
import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { contactPaths } from 'src/routes/employer/paths/contact.paths';
import { isActionOf } from 'typesafe-actions';
import { RootEpic } from '../../epic.root-index';
import { contactDeleteAsync } from '../contact.actions';

export const contactDeleteEpic = asyncEpicBase(
    contactDeleteAsync,
    ({ api }, { payload }) => api.contacts.deleteContact(payload),
    {
        success: result => contactDeleteAsync.success(result.json),
        failure: (error, ra) => contactDeleteAsync.failure(error, ra.payload),
    }
);

export const contactDeleteRedirectEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(contactDeleteAsync.success)),
        mergeMap(() => of(push(contactPaths.list)))
    );
};
