import { useDispatch } from 'react-redux';
import { eventRequestCreateAsync } from 'src/logic/features/event-unions/event-union.actions';
import { CardWrapper } from 'src/ui/shared/components/bootstrap/cards/CardWrapper';
import { ApplicationPageContent } from 'src/ui/shared/components/page-components/ApplicationPageContent';
import { useFormButton } from 'src/ui/shared/hooks/form-button.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { EventRequestForm, EventRequestFormResponse } from '../components/forms/EventRequestForm';

export const EventAddPage = (): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();

    const onSubmit = (value: EventRequestFormResponse) => {
        dispatch(eventRequestCreateAsync.request(value, { redirectToPublish: true }));
    };

    const onSaveDraft = (value: EventRequestFormResponse) => {
        dispatch(eventRequestCreateAsync.request(value, { redirectToPublish: false }));
    };

    const { onCancel } = useFormButton();

    const { create } = useRootSelector(state => state.eventUnions);

    return (
        <ApplicationPageContent className="event-add-page">
            <CardWrapper>
                <EventRequestForm
                    onSubmit={onSubmit}
                    onSaveDraft={onSaveDraft}
                    onCancel={onCancel}
                    state={create}
                />
            </CardWrapper>
        </ApplicationPageContent>
    );
};
