import { createAction } from 'typesafe-actions';
import { ErrorNormalized } from '../../../models/errors/error.model';

export const appInitStart = createAction('APP_INIT:START')<undefined>();
export const appInitComplete = createAction('APP_INIT:COMPLETE')<undefined>();
export const appInitError = createAction('APP_INIT:FAILURE')<ErrorNormalized>();

// I couldn't think of a good name for this.
export const appInitStillWaiting = createAction('APP_INIT:STILL_WAITING')();
export const masterEpicError = createAction('MASTER_EPIC_ERROR')<ErrorNormalized>();
