import {
    faFacebookF,
    faInstagram,
    faLinkedinIn,
    faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { IconDefinitionAll } from '../../ui/shared/components/icon/Icon';
import { z } from 'zod';

export const SOCIAL_TYPE_OPTIONS = ['Facebook', 'Twitter', 'Instagram', 'LinkedIn'] as const;
type SocialTypeTuple = typeof SOCIAL_TYPE_OPTIONS;
export type SocialType = SocialTypeTuple[number];

export const socialPropertySchema = z.object({
    socialType: z.enum(SOCIAL_TYPE_OPTIONS),
    value: z.string().max(100),
});

export type SocialProperty = z.infer<typeof socialPropertySchema>;

interface SocialTypeMeta {
    label: string;
    icon: IconDefinitionAll;
    linkPrefix: string;
    inputPrefix?: string;
    placeholder?: string;
}

export const SOCIAL_TYPE_META: { [key in SocialType]: SocialTypeMeta } = {
    Facebook: {
        icon: faFacebookF,
        label: 'Facebook',
        linkPrefix: 'https://www.facebook.com/',
        inputPrefix: 'facebook.com/',
        placeholder: 'page name',
    },
    LinkedIn: {
        icon: faLinkedinIn,
        label: 'LinkedIn',
        linkPrefix: 'https://linkedin.com/company/',
        inputPrefix: 'linkedin.com/company/',
        placeholder: 'company',
    },
    Instagram: {
        icon: faInstagram,
        label: 'Instagram',
        linkPrefix: 'https://instagram.com/',
        inputPrefix: 'instagram.com/',
        placeholder: 'handle',
    },
    Twitter: {
        icon: faTwitter,
        label: 'Twitter',
        linkPrefix: 'https://twitter.com/',
        placeholder: '@handle',
    },
};
