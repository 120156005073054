import { Route, Switch } from 'react-router-dom';
import { AuthLandingPage } from 'src/ui/features/authentication/pages/AuthLandingPage';
import { PrivateIdentityListPage } from 'src/ui/features/authentication/pages/PrivateIdentityListPage';
import { ImpersonatePage } from '../../ui/features/authentication/pages/ImpersonatePage';
import { LogoutPage } from '../../ui/features/authentication/pages/LogoutPage';
import { PublicIdentityListPage } from '../../ui/features/authentication/pages/PublicIdentityListPage';
import { identityPaths } from './identity.paths';

export const PublicIdentityRouter = () => {
    const { list, logout, impersonate, landing } = identityPaths;

    return (
        <Switch>
            <Route path={list} component={PublicIdentityListPage} />
            <Route path={logout} component={LogoutPage} />
            <Route path={impersonate} component={ImpersonatePage} />
            <Route path={landing} component={AuthLandingPage} />
        </Switch>
    );
};

export const PrivateIdentityRouter = () => {
    return (
        <Switch>
            <Route path={identityPaths.listAuthenticated} component={PrivateIdentityListPage} />
        </Switch>
    );
};
