import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useClient } from 'src/ui/shared/hooks/client.hook';
import { useLogo } from 'src/ui/shared/hooks/logo.hook';

export const CentralMarketingAlert = (): React.ReactElement<any, any> | null => {
    const { centralMarketingPath } = useClient();
    const { src } = useLogo();

    return (
        <div className="bg-central p-3 d-flex w-100 rounded align-items-center">
            <img src={src} alt="CareerHub Central" style={{ maxWidth: '230px' }} />
            <a
                href={centralMarketingPath}
                className="btn btn-link-light p-0 pl-2 ml-auto"
                target="_blank"
                rel="noreferrer"
            >
                <Icon icon={faInfoCircle}>Find out more</Icon>
            </a>
        </div>
    );
};
