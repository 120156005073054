import { Badge } from 'react-bootstrap';
import { CentralContactIdentity } from '../../../../../models/authentication/identity.model';
import { OrganisationStatus } from '../../../../../models/organisation/organisation-types.model';
import { useAuthentication } from '../../hooks/authentication.hook';

interface Props {
    identity: CentralContactIdentity;
}

export const CentralContactIdentityBadges = ({
    identity,
}: Props): React.ReactElement<any, any> | null => {
    const { determineIfLocalAndCentralOrganisationInPendingState } = useAuthentication();
    const isLocalAndCentralOrganisationInPendingState =
        determineIfLocalAndCentralOrganisationInPendingState(identity);

    const dangerStatus: OrganisationStatus[] = ['Deleted', 'Disabled', 'Rejected'];

    return (
        <>
            {!isLocalAndCentralOrganisationInPendingState && (
                <>
                    <Badge className="ml-2" variant="outline-info">
                        Managed by Central
                    </Badge>
                    {identity.organisationStatus === 'PendingApproval' && (
                        <Badge className="ml-2" variant="outline-warning">
                            Pending Approval
                        </Badge>
                    )}
                </>
            )}
            {isLocalAndCentralOrganisationInPendingState && (
                <Badge className="ml-2" variant="outline-warning">
                    Pending in Central
                </Badge>
            )}
            {dangerStatus.includes(identity.organisationStatus) && (
                <Badge className="ml-2" variant="outline-danger">
                    {identity.organisationStatus}
                </Badge>
            )}
            {!identity.contactActive && (
                <Badge className="ml-2" variant="outline-danger">
                    Contact inactive
                </Badge>
            )}
        </>
    );
};
