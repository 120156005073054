import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FileRejection } from 'react-dropzone';
import { DROPZONE_ERRORS, DropzoneErrorCode } from 'src/constants/job-attachment.constants';
import { AlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/AlertWrapper';

interface Props {
    rejections: FileRejection[];
    onDismiss: () => void;
}

export const RejectedFileAlert = ({
    rejections,
    onDismiss,
}: Props): React.ReactElement<any, any> | null => {
    if (rejections.length === 0) {
        return null;
    }
    
    return (
        <AlertWrapper
            variant="danger"
            icon={faExclamationTriangle}
            dismissible={true}
            onDismiss={onDismiss}
        >
            <p>The following files will not be uploaded.</p>
            <ul>
                {rejections.map((r, i) => (
                    <li key={i}>
                        <strong>{r.file.name} - </strong>
                        {r.errors
                            .map(
                                i =>
                                    DROPZONE_ERRORS[i.code as DropzoneErrorCode]?.message ||
                                    i.message
                            )
                            .join(', ')}
                    </li>
                ))}
            </ul>
        </AlertWrapper>
    );
};
