import { useMemo } from 'react';
import { useAllContacts } from 'src/ui/features/contacts/hooks/all-contacts.hook';
import { Contact } from 'src/models/contacts/contact.model';
import { ContactStatusBadge } from '../../../../../features/contacts/components/badges/ContactStatusBadge';
import { Loader } from '../../../loader/Loader';
import { SingleSelectControl, SingleSelectControlProps } from './SingleSelectControl';

type ContactSelectControlProps = Omit<SingleSelectControlProps, 'options'>;

export const useContactSelectOptions = (contacts: Contact[]) =>
    useMemo(
        () =>
            contacts
                .filter(c => c.active)
                .map(c => ({
                    label: (
                        <>
                            {c.firstName} {c.lastName} <ContactStatusBadge contact={c} />
                        </>
                    ),
                    value: c.id,
                    tokens: [c.firstName, c.lastName],
                })),
        [contacts]
    );

export const ContactSelectControl = (
    props: ContactSelectControlProps
): React.ReactElement<any, any> | null => {
    const { fetch, contacts } = useAllContacts();

    const options = useContactSelectOptions(contacts);

    return (
        <Loader useCard={true} state={fetch}>
            <SingleSelectControl {...props} options={options} />
        </Loader>
    );
};
