import { Route, Switch } from 'react-router-dom';
import { useEventsEnabled } from 'src/ui/features/events/hooks/events-enabled.hook';
import { EventAddPage } from 'src/ui/features/events/pages/EventAddPage';
import { EventBookingListPage } from 'src/ui/features/events/pages/EventBookingListPage';
import { EventChangeRequestAddPage } from 'src/ui/features/events/pages/EventChangeRequestAddPage';
import { EventChangeRequestEditPage } from 'src/ui/features/events/pages/EventChangeRequestEditPage';
import { EventChangeRequestListPage } from 'src/ui/features/events/pages/EventChangeRequestListPage';
import { EventCopyPage } from 'src/ui/features/events/pages/EventCopyPage';
import { EventDetailsPage } from 'src/ui/features/events/pages/EventDetailsPage';
import { EventEditPage } from 'src/ui/features/events/pages/EventEditPage';
import { EventListPage } from 'src/ui/features/events/pages/EventListPage';
import { EventPublishConfirmationPage } from 'src/ui/features/events/pages/EventPublishConfirmationPage';
import { EventPublishPage } from 'src/ui/features/events/pages/EventPublishPage';
import { EventSessionCheckInPage } from 'src/ui/features/events/pages/EventSessionCheckInPage';
import { EventSessionListPage } from 'src/ui/features/events/pages/EventSessionListPage';
import { eventPaths } from './paths/event.paths';

export const EventRouter = () => {
    const { isEventsEnabled, canCreateEventRequests, isBookingListEnabled } = useEventsEnabled();

    if (!isEventsEnabled) {
        return null;
    }

    return (
        <Switch>
            {isBookingListEnabled && (
                <Route path={eventPaths.bookingList} component={EventBookingListPage} />
            )}
            <Route path={eventPaths.sessionCheckIn} component={EventSessionCheckInPage} />
            <Route path={eventPaths.sessionList} component={EventSessionListPage} />
            {canCreateEventRequests && [
                <Route key={eventPaths.edit} path={eventPaths.edit} component={EventEditPage} />,
                <Route
                    key={eventPaths.publish}
                    path={eventPaths.publish}
                    component={EventPublishPage}
                />,
                <Route
                    key={eventPaths.publishConfirmation}
                    path={eventPaths.publishConfirmation}
                    component={EventPublishConfirmationPage}
                />,
                <Route key={eventPaths.copy} path={eventPaths.copy} component={EventCopyPage} />,
                <Route
                    key={eventPaths.changeRequestEdit}
                    path={eventPaths.changeRequestEdit}
                    component={EventChangeRequestEditPage}
                />,
                <Route
                    key={eventPaths.changeRequestAdd}
                    path={eventPaths.changeRequestAdd}
                    component={EventChangeRequestAddPage}
                />,
                <Route
                    key={eventPaths.changeRequestList}
                    path={eventPaths.changeRequestList}
                    component={EventChangeRequestListPage}
                />,
            ]}
            <Route path={eventPaths.details} component={EventDetailsPage} />
            {canCreateEventRequests && <Route path={eventPaths.add} component={EventAddPage} />}
            <Route path={eventPaths.list} component={EventListPage} />
        </Switch>
    );
};
