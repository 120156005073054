interface Props {
    children?: React.ReactNode;
}

export const ApplicationTopNavContainer = ({
    children,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <div className="d-flex d-print-none w-100 align-items-center ch-shadow bg py-1 border-bottom">
            {children}
        </div>
    );
};
