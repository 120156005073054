import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Component, ErrorInfo, PropsWithChildren } from 'react';
import { logError } from '../../ui/features/sentry/helpers/sentry.helper';
import { Icon } from '../../ui/shared/components/icon/Icon';

export class DynamicErrorBoundary extends Component<
    PropsWithChildren<Record<string, unknown> & { componentKey: string }>,
    { errorId: string | undefined }
> {
    constructor(props: any) {
        super(props);
        this.state = { errorId: undefined };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        const errorId = logError(
            error,
            { ...errorInfo, componentKey: this.props.componentKey },
            { isDynamicComponent: 'true' }
        );
        this.setState({ errorId: errorId });
    }

    public render() {
        if (this.state.errorId) {
            return (
                <div className="text-danger">
                    <Icon icon={faExclamationTriangle}>
                        The component {this.props.componentKey} was unable to load.
                    </Icon>
                </div>
            );
        }

        return this.props.children || null;
    }
}
