import { LOCATION_CHANGE, push } from 'connected-react-router';
import { concat, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { delayTypeUntilAuthenticated } from 'src/logic/helpers/epics/app-init.epic-helper';
import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { isLocationRouteMatch } from 'src/logic/helpers/epics/location.epic-helper';
import { isActionOf } from 'typesafe-actions';
import {
    JobAddPageQueryParams,
    jobPathCreator,
    jobPaths,
} from '../../../../routes/employer/paths/job.paths';
import { RootEpic } from '../../epic.root-index';
import {
    jobAsync,
    jobCreateAsync,
    jobRepublishIdSetActive,
    jobSetActive,
    jobUpdateAsync,
} from '../job.actions';

export const jobCreateEpic = asyncEpicBase(
    jobCreateAsync,
    ({ api }, { payload }) =>
        api.jobs.create(payload.job).pipe(
            mergeMap(jobResponse => {
                return payload.attachments.length === 0
                    ? of(jobResponse)
                    : api.attachments.add(jobResponse.json.data.id, payload.attachments).pipe(
                          mergeMap(() => api.jobs.single({ id: jobResponse.json.data.id }))
                          //   catchError((error: ErrorNormalized) => {
                          //       // TODO: needs to be fixed!
                          //       throw error;
                          //   })
                      );
            })
        ),
    {
        success: (result, requestAction) => jobCreateAsync.success(result.json, requestAction.meta),
        failure: (error, requestAction) => jobCreateAsync.failure(error, requestAction.meta),
    }
);

export const jobUpdateEpic = asyncEpicBase(
    jobUpdateAsync,
    ({ api }, { payload }) =>
        payload.attachments.length === 0
            ? api.jobs.update(payload.job)
            : api.attachments
                  .add(payload.job.id, payload.attachments)
                  .pipe(mergeMap(() => api.jobs.update(payload.job))),
    {
        success: (result, requestAction) => jobUpdateAsync.success(result.json, requestAction.meta),
        failure: (error, requestAction) => jobUpdateAsync.failure(error, requestAction.meta),
    }
);

export const jobCreateUpdateRedirectEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf([jobCreateAsync.success, jobUpdateAsync.success])),
        mergeMap(action =>
            action.meta.redirectToPublish
                ? of(push(jobPathCreator.publish(action.payload.data.id)))
                : of(push(jobPathCreator.details(action.payload.data.id)))
        )
    );
};

// this fixes a bug with republishing jobs
// the republished job Id is always set to the activeId, and has loaded state,
// after creating a new job, the new JobId is set to Active, and will also
// always have state.
// on the publish page, the inital form gets set to the first id as the default
// value, because there is nothing to load and the form gets displayed. Updating
// to the next active id, does not update the form.
// This epic just ensures that on the job Add path, we set the active Id to undefined
export const jobAddPageResetActiveId: RootEpic = (action$, state$) => {
    return delayTypeUntilAuthenticated(action$, state$, LOCATION_CHANGE).pipe(
        filter(isLocationRouteMatch(jobPaths.add)),
        mergeMap(() => of(jobSetActive({ id: undefined })))
    );
};

export const jobRepublishedIdSetActiveEpic: RootEpic = (action$, state$, services) => {
    return delayTypeUntilAuthenticated(action$, state$, LOCATION_CHANGE).pipe(
        filter(action => action.payload.location.pathname === jobPaths.add),
        map(action =>
            services.queryParams.parseExpected<JobAddPageQueryParams>(
                action.payload.location.search
            )
        ),
        mergeMap(params => {
            if (!params.republishId) {
                return of(jobRepublishIdSetActive({ republishId: undefined }));
            }

            const replublishJobExists = state$.value.jobs.items[params.republishId];
            const setActiveAction = jobRepublishIdSetActive({
                republishId: params.republishId,
            });

            return replublishJobExists
                ? of(setActiveAction)
                : concat([jobAsync.request({ id: params.republishId }), setActiveAction]);
        })
    );
};
