import { EventUnion } from 'src/models/events/event-union.model';
import { ActionDropdownSize } from 'src/ui/shared/components/bootstrap/dropdown/ActionDropdown';
import { EventActionDropdown } from './EventActionDropdown';
import { EventRequestActionDropdown } from './EventRequestActionDropdown';

interface Props {
    eventUnion: EventUnion | undefined;
    size: ActionDropdownSize;
}

export const EventUnionActionDropdown = ({
    eventUnion,
    size,
}: Props): React.ReactElement<any, any> | null => {
    if (!eventUnion) {
        return null;
    }

    if (eventUnion.event) {
        return <EventActionDropdown event={eventUnion.event} size={size} />;
    }

    if (eventUnion.eventRequest) {
        return <EventRequestActionDropdown eventRequest={eventUnion.eventRequest} size={size} />;
    }

    return null;
};
