import { CSSProperties } from 'react';

export const Hexagon = (props: { style?: CSSProperties; className?: string }) => (
    <svg
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={props.style}
        className={props.className}
    >
        <path
            // eslint-disable-next-line max-len
            d="M2.038,19.792c-1.142,2.021 -1.142,4.395 0,6.328l7.734,13.447c1.143,2.022 3.252,3.164 5.45,3.164l15.556,0c2.285,0 4.307,-1.142 5.45,-3.164l7.734,-13.447c1.142,-1.933 1.142,-4.307 -0,-6.328l-7.734,-13.359c-1.143,-2.022 -3.165,-3.164 -5.45,-3.164l-15.556,-0c-2.285,-0 -4.307,1.142 -5.45,3.164l-7.734,13.359Z"
            fill="currentColor"
        />
    </svg>
);
