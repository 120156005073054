import { push } from 'connected-react-router';
import { useCallback, useMemo } from 'react';
import { Pagination } from 'react-bootstrap';
import { shallowEqual, useDispatch } from 'react-redux';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { JobStatus, JOB_STATUS_OPTIONS } from '../../../../../models/jobs/job-types.model';
import { jobPathCreator } from '../../../../../routes/employer/paths/job.paths';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';

export const JobListStatusFilter = (): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    const config = useApiConfiguration();

    const activeStatuses = useRootSelector(
        state => state.jobs.activeList.query.status || [],
        shallowEqual
    );

    const onSetStatus = useCallback(
        (status: JobStatus) => {
            const findIndex = activeStatuses.indexOf(status);

            let statuses: JobStatus[] | undefined =
                findIndex !== -1
                    ? activeStatuses.filter((_, i) => i !== findIndex)
                    : [...activeStatuses, status];

            statuses =
                statuses.length > 0 ? statuses.sort((a, b) => a.localeCompare(b)) : undefined;

            dispatch(push(jobPathCreator.list({ status: statuses })));
        },
        [activeStatuses, dispatch]
    );

    const isActive = useCallback(
        (status: JobStatus) => {
            return activeStatuses.includes(status);
        },
        [activeStatuses]
    );

    const jobStatusesToShow = useMemo(() => {
        if (config.value?.settings?.hasPendingJobFix) {
            return JOB_STATUS_OPTIONS;
        }

        return JOB_STATUS_OPTIONS.filter(x => x !== 'Pending');
    }, [config.value?.settings?.hasPendingJobFix]);

    return (
        <Pagination size="sm" className="mr-3 pagination-button-group flex-wrap">
            {jobStatusesToShow.map(i => (
                <Pagination.Item
                    activeLabel="active label"
                    key={i}
                    onClick={() => onSetStatus(i)}
                    active={isActive(i)}
                >
                    {i}
                </Pagination.Item>
            ))}
        </Pagination>
        
    );
};
