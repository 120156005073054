import { useIsCentralPostingEnabled } from 'src/ui/features/central/hooks/is-central-posting-enabled.hook';
import { CardWrapper } from 'src/ui/shared/components/bootstrap/cards/CardWrapper';
import { ConfirmationTitle } from 'src/ui/shared/components/confirmation/ConfirmationTitle';
import { Markdown } from 'src/ui/shared/components/details/Markdown';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { ApplicationPageContent } from 'src/ui/shared/components/page-components/ApplicationPageContent';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { PublishConfirmationModal } from '../components/publish-confirmation/PublishConfirmationModal';
import { useActiveJob } from '../hooks/active-job.hook';

export const JobPublishConfirmationPage = (): React.ReactElement<any, any> | null => {
    const { fetch, job } = useActiveJob();
    const {
        job: {
            publishConfirmation: { title, message },
        },
    } = useStrings();
    const isCentralPositingEnabled = useIsCentralPostingEnabled();

    return (
        <ApplicationPageContent>
            <Loader useCard={true} state={fetch}>
                {job && (
                    <>
                        <CardWrapper className="mb-2">
                            <ConfirmationTitle title={title} />
                            <Markdown source={message} />
                        </CardWrapper>

                        {isCentralPositingEnabled && <PublishConfirmationModal job={job} />}
                    </>
                )}
            </Loader>
        </ApplicationPageContent>
    );
};
