import { push } from 'connected-react-router';
import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { organisationPaths } from 'src/routes/employer/paths/organisation.paths';
import { isActionOf } from 'typesafe-actions';
import { asyncEpicBase, asyncEpicStandard } from '../../helpers/epics/async.epic-helper';
import { RootEpic } from '../epic.root-index';
import {
    organisationAsync,
    organisationLogoAsync,
    organisationLogoDeleteAsync,
    organisationUpdateAsync,
} from './organisation.actions';

export const organisationEpic = asyncEpicStandard(organisationAsync, ({ api }) =>
    api.organisation.get()
);

export const organisationUpdateEpic = asyncEpicBase(
    organisationUpdateAsync,
    ({ api }, { payload }) => api.organisation.update(payload.organisation),
    {
        success: (result, ra) => organisationUpdateAsync.success(result.json, ra.payload),
        failure: error => organisationUpdateAsync.failure(error),
    }
);

export const organisationUpdateRedirect: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(organisationUpdateAsync.success)),
        mergeMap(() => of(push(organisationPaths.details)))
    );
};

export const organisationLogoAsyncEpic = asyncEpicStandard(organisationLogoAsync, (services, ra) =>
    services.api.organisation.logo(ra.payload.file)
);

export const organisationLogoDeleteAsyncEpic = asyncEpicStandard(
    organisationLogoDeleteAsync,
    services => services.api.organisation.logoDelete()
);

export const organisationLogoUpdateEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(organisationUpdateAsync.success)),
        filter(action => !!action.meta.logo),
        mergeMap(action => of(organisationLogoAsync.request({ file: action.meta.logo! })))
    );
};

export const organisationLogoDeleteEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(organisationUpdateAsync.success)),
        filter(action => !!action.meta.organisation.bannerFileId && !action.meta.logo),
        mergeMap(() => of(organisationLogoDeleteAsync.request()))
    );
};
