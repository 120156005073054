import { CSSProperties } from 'react';

export const Burst = (props: { style?: CSSProperties; className?: string }) => (
    <svg
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={props.style}
        className={props.className}
    >
        <path
            // eslint-disable-next-line max-len
            d="M19.0449 1.32812C18.5176 0.712891 17.7266 0.537109 16.9355 0.712891C16.2324 0.888672 15.6172 1.50391 15.4414 2.29492L14.0352 7.74414L8.58594 6.25C7.88281 5.98633 7.0918 6.25 6.56445 6.77734C6.03711 7.30469 5.77344 8.0957 6.03711 8.79883L7.53125 14.248L2.08203 15.6543C1.29102 15.8301 0.763672 16.4453 0.5 17.1484C0.324219 17.9395 0.587891 18.6426 1.11523 19.1699L5.1582 23.125L1.11523 27.0801C0.587891 27.6074 0.324219 28.3984 0.5 29.1895C0.763672 29.8926 1.29102 30.5078 2.08203 30.6836L7.53125 32.0898L6.03711 37.5391C5.77344 38.2422 6.03711 39.0332 6.56445 39.5605C7.0918 40.0879 7.88281 40.3516 8.58594 40.0879L14.0352 38.5938L15.4414 44.043C15.6172 44.834 16.2324 45.3613 16.9355 45.625C17.7266 45.8008 18.4297 45.5371 19.0449 45.0098L23 40.9668L26.9551 45.0098C27.4824 45.5371 28.2734 45.8008 28.9766 45.625C29.6797 45.3613 30.2949 44.834 30.4707 44.043L31.877 38.5938L37.3262 40.0879C38.0293 40.3516 38.8203 40.0879 39.3477 39.5605C39.875 39.0332 40.1387 38.2422 39.875 37.5391L38.3809 32.0898L43.8301 30.6836C44.6211 30.5078 45.1484 29.8926 45.4121 29.1895C45.5879 28.3984 45.4121 27.6953 44.7969 27.0801L40.7539 23.125L44.7969 19.1699C45.4121 18.6426 45.5879 17.8516 45.4121 17.1484C45.1484 16.4453 44.6211 15.8301 43.8301 15.6543L38.3809 14.248L39.875 8.79883C40.1387 8.0957 39.875 7.30469 39.3477 6.77734C38.8203 6.25 38.0293 5.98633 37.3262 6.25L31.877 7.74414L30.4707 2.29492C30.2949 1.50391 29.6797 0.976562 28.9766 0.712891C28.1855 0.537109 27.4824 0.712891 26.9551 1.32812L23 5.37109L19.0449 1.32812Z"
            fill="currentColor"
        />
    </svg>
);
