import { EventRequest } from 'src/models/events/event-request.model';
import { ContactDetailsProperty } from 'src/ui/features/contacts/components/details/ContactDetailsProperty';
import { DatePropertyDisplay } from 'src/ui/shared/components/details/DatePropertyDisplay';
import { DetailsProperty } from 'src/ui/shared/components/details/DetailsProperty';
import { EventTypeProperty } from './EventTypeProperty';

interface Props {
    eventRequest: EventRequest;
}

export const EventRequestProperties = ({
    eventRequest,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <>
            <h2 className="font-weight-normal">Other Information</h2>
            <EventTypeProperty eventTypeId={eventRequest.eventTypeId} />

            {eventRequest.contactId && <ContactDetailsProperty id={eventRequest.contactId} />}
            {eventRequest.bookingType !== 'NotRequired' &&
                eventRequest.bookingType !== 'Unknown' && (
                    <DetailsProperty label="Booking System">
                        <div>{eventRequest.bookingType}</div>
                        {eventRequest.bookingLimit && <div>Limit: {eventRequest.bookingLimit}</div>}
                    </DetailsProperty>
                )}

            <DetailsProperty label="History">
                <DatePropertyDisplay label="Added" date={eventRequest.added} />
            </DetailsProperty>
        </>
    );
};
