import { ContentKey } from '@careerhub/content-shared';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { homePath } from 'src/routes/layouts/HomeRouteLayout';
import { useContent } from './content.hook';

const HOME_CONTENT_SCROLL_OFFSET = -100;
const CONTENT_SCROLL_OFFSET = -50;

// this is all a bit hacky...
// the offset should probably be based off something programatic, at the moment it's just making assumptions about margin lengths...
export const useContentScrollOffset = () => {
    const { pathname } = useLocation();

    return useMemo(
        () => (pathname === homePath ? HOME_CONTENT_SCROLL_OFFSET : CONTENT_SCROLL_OFFSET),
        [pathname]
    );
};

export const useContentScroll = (contentKey: ContentKey) => {
    const location = useLocation();
    const { getSectionTitles, activeCollection } = useContent(contentKey);
    const sectionTitles = useMemo(
        () => getSectionTitles(activeCollection),
        [activeCollection, getSectionTitles]
    );

    const contentScrollOffset = useContentScrollOffset();

    useEffect(() => {
        const sectionItem = location.search
            ? sectionTitles.find(s => s.to.indexOf(location.search) !== -1)
            : undefined;

        if (!sectionItem) {
            window.scrollTo(0, 0);
            return;
        }

        const element = document.getElementById(sectionItem.id);

        if (!element) {
            window.scrollTo(0, 0);
            return;
        }

        const yOffset = contentScrollOffset;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
    }, [location, sectionTitles, contentScrollOffset]);
};
