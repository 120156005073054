import { format } from 'date-fns';
import { useMemo } from 'react';
import { HookControlProps } from '../../HookForm';
import { InputControl } from '../InputControl';

interface Props extends HookControlProps {
    min?: Date | string;
    max?: Date | string;
}

export const datetimelocalFormat = "yyyy-MM-dd'T'HH:mm";

export const DatePickerLocalControl = ({
    min,
    max,
    ...props
}: Props): React.ReactElement<any, any> | null => {
    const minString = useMemo(() => {
        if (typeof min === 'string') {
            return min;
        }

        return min ? format(min, datetimelocalFormat) : undefined;
    }, [min]);

    const maxString = useMemo(() => {
        if (typeof max === 'string') {
            return max;
        }

        return max ? format(max, datetimelocalFormat) : undefined;
    }, [max]);

    return <InputControl {...props} type="datetime-local" min={minString} max={maxString} />;
};
