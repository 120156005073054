import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { listEpic } from 'src/logic/helpers/epics/list-route.epic-helper';
import { eventPaths } from 'src/routes/employer/paths/event.paths';
import { appStringify } from 'src/routes/router.helper';
import { eventUnionSetActive } from '../event-unions/event-union.actions';
import * as actions from './event-booking.actions';

export const eventBookingListEpic = listEpic({
    path: eventPaths.bookingList,
    extraFilter: (action, state) =>
        state.apiConfiguration.value?.events?.enableBookingList === true,
    delayUntil: eventUnionSetActive,
    getParams: (action, state, { queryParams }) => ({
        ...queryParams.entityListParams(action.payload.location.search),
        eventIds: [state.eventUnions.activeId!],
    }),
    overrideRequestId: (action, state) =>
        appStringify({
            search: action.payload.location.search,
            eventId: state.eventUnions.activeId,
        }),
    getListState: state => state.eventBookings,
    setActiveActionCreator: actions.eventBookingListSetActive,
    requestActionCreator: actions.eventBookingListAsync.request,
    cancelActionCreator: actions.eventBookingListAsync.cancel,
});

export const eventBookingListAsyncEpic = asyncEpicBase(
    actions.eventBookingListAsync,
    (services, ra) => services.api.eventBookings.list(ra.payload),
    {
        success: result => actions.eventBookingListAsync.success(result.json),
        failure: (error, ra) => actions.eventBookingListAsync.failure(error, ra.payload),
    }
);
