import { getClientConfig } from 'src/clients-internal/configuration.init';
import { fetchHelper } from 'src/logic/helpers/fetch.helper';
import { EntityRequest } from 'src/models/api/request.model';
import { ApiResponse } from 'src/models/api/response.model';
import { ObsApiExpected } from 'src/models/api/service.model';
import { EntityNote } from 'src/models/entity-notes/entity-note.model';

const baseUrl = () => getClientConfig().careerHubBasePath;

export const create = (entityNote: EntityNote): ObsApiExpected<ApiResponse<EntityNote>> => {
    return fetchHelper.post(`${baseUrl()}/services/employers/v1/entitynotes`, entityNote);
};

export const update = (entityNote: EntityNote): ObsApiExpected<ApiResponse<EntityNote>> => {
    return fetchHelper.put(
        `${baseUrl()}/services/employers/v1/entitynotes/${entityNote.id}`,
        entityNote
    );
};

export const del = (request: EntityRequest): ObsApiExpected<ApiResponse<EntityNote>> => {
    return fetchHelper.del(`${baseUrl()}/services/employers/v1/entitynotes/${request.id}`);
};
