import { PageTitle } from 'src/ui/shared/components/page-components/PageTitle';
import { EventListBreadcrumb } from '../breadcrumbs/EventListBreadcrumb';

export const EventAddPageNav = (): React.ReactElement<any, any> | null => {
    return (
        <PageTitle>
            <EventListBreadcrumb />
            Request event
        </PageTitle>
    );
};
