import { SessionContainer } from '../../../features/session/components/SessionContainer';
import { ApplicationSideNav } from '../navigation/application-navigation/ApplicationSideNav';
import { ApplicationTopNav } from '../navigation/application-navigation/ApplicationTopNav';
import { ApplicationLayoutContainer } from './ApplicationLayoutContainer';

interface Props {
    children: React.ReactNode;
}

export const ApplicationLayout = ({ children }: Props): React.ReactElement<any, any> | null => {
    return (
        <ApplicationLayoutContainer>
            <ApplicationLayoutContainer.SideNav>
                <ApplicationSideNav />
            </ApplicationLayoutContainer.SideNav>
            <ApplicationLayoutContainer.Content>
                <ApplicationTopNav />
                <SessionContainer />
                {children}
            </ApplicationLayoutContainer.Content>
        </ApplicationLayoutContainer>
    );
};
