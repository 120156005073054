import { combineLatest, concat, of } from 'rxjs';
import { delay, filter, ignoreElements, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { executeStringsInit } from 'src/clients-internal/strings.init';
import { configurationInit } from 'src/logic/features/configuration/configuration.actions';
import { contentAsync } from 'src/logic/features/content/content.actions';
import { stringsInit } from 'src/logic/features/strings/string.actions';
import { isActionOf } from 'typesafe-actions';
import * as authActions from '../authentication/authentication.actions';
import * as actions from './initialization.actions';
import { apiConfigurationAsync } from '../api-configuration/api-configuration.actions';
import { RootEpic } from '../epic.root-index';

// This triggers the initial APP_INIT:START action
export const appStartEpic: RootEpic = action$ => {
    return action$.pipe(
        take(1),
        mergeMap(() => of(actions.appInitStart()))
    );
};

// timer that can be used to show an action to the user if the initialization logic is stuck, or taking too long
export const appStartTimeoutEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(actions.appInitStart)),
        delay(8000), // 8 seconds
        takeUntil(action$.pipe(filter(isActionOf(actions.appInitComplete)))),
        mergeMap(() => of(actions.appInitStillWaiting()))
    );
};

// The main APP_INIT flow
// One thing is not contained in these epics, and that's the dispatch to the configurationInit action.
// This is triggered manually (in the index.tsx file) on app load
// this epic is simply waiting for the configurationInit and stringsInit to fire, then calling
// the next actions that are necessary
export const appStartAuthenticationStartEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(actions.appInitStart)),
        mergeMap(() =>
            combineLatest([
                action$.pipe(filter(isActionOf(stringsInit))),
                action$.pipe(filter(isActionOf(configurationInit))),
            ])
        ),
        mergeMap(([_, config]) => {
            if (config.payload.disableApplication) {
                return concat([
                    contentAsync.request({ key: 'employerContent' }),
                    actions.appInitComplete(),
                    authActions.authenticationComplete(),
                ]);
            }

            return concat([
                contentAsync.request({ key: 'employerContent' }),
                authActions.authenticationInit(),
                apiConfigurationAsync.request(),
                actions.appInitComplete(),
            ]);
        })
    );
};

// trigger strings after config init
// this is a bit more tricky than just triggering an action
// as it dynamic loads the strings based on region and client
export const appStringsInit: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(configurationInit)),
        map(() =>
            executeStringsInit(
                state$.value.configuration.clientCode,
                state$.value.configuration.value.countryCode
            )
        ),
        ignoreElements()
    );
};

export const appFailureEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(() => !state$.value.init.completed),
        filter(isActionOf(apiConfigurationAsync.failure)),
        mergeMap(action => of(actions.appInitError(action.payload)))
    );
};
