import { CardWrapper } from 'src/ui/shared/components/bootstrap/cards/CardWrapper';
import { useActiveEntity } from 'src/ui/shared/hooks/active-entity.hook';
import { EventDetails } from '../components/details/EventDetails';
import { EventDetailsPageContent } from '../components/details/EventDetailsPageContent';
import { EventRequestDetails } from '../components/details/EventRequestDetails';

export const EventDetailsPage = (): React.ReactElement<any, any> | null => {
    const { entity: eventUnion } = useActiveEntity(state => state.eventUnions);

    return (
        <EventDetailsPageContent>
            <CardWrapper style={{ maxWidth: '1170px' }}>
                {eventUnion && eventUnion.entityType === 'Event' && (
                    <EventDetails event={eventUnion.event!} />
                )}
                {eventUnion && eventUnion.entityType === 'EventRequest' && (
                    <EventRequestDetails eventRequest={eventUnion.eventRequest!} />
                )}
            </CardWrapper>
        </EventDetailsPageContent>
    );
};
