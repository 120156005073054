import { createReducer } from 'typesafe-actions';
import { EntityPageRequest } from '../../../models/api/request.model';
import { FormSubmission } from '../../../models/forms/form-submission.model';
import { EntityListState } from '../../../models/store-models/entity-list-state.model';
import * as actions from './form-submission.actions';
import { entityListInitialState } from '../../helpers/initial-state.helper';
import { listReducerHandler } from 'src/logic/reducer-handlers/list.reducer-handler';

export type FormSubmissionState = EntityListState<FormSubmission, EntityPageRequest>;

const initialState: FormSubmissionState = {
    ...entityListInitialState,
};

const formSubmissionReducer = createReducer(initialState)
    .handleAction(actions.formSubmissionListSetActive, listReducerHandler.setActive)

    .handleAction(actions.formSubmissionListAsync.request, listReducerHandler.request)
    .handleAction(actions.formSubmissionListAsync.success, listReducerHandler.success)
    .handleAction(actions.formSubmissionListAsync.cancel, listReducerHandler.cancel)
    .handleAction(actions.formSubmissionListAsync.failure, listReducerHandler.failure)

    .handleAction(actions.formSubmissionResetState, () => initialState);

export default formSubmissionReducer;
