import { Col, Form } from 'react-bootstrap';
import { HookForm } from 'src/ui/shared/components/forms/HookForm';
import { arrayRequiredRule } from 'src/ui/shared/helpers/validation.helper';
import { useCategoryLabel } from 'src/ui/shared/hooks/category-label.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { Organisation } from 'src/models/organisation/organisation.model';
import { useOrganisationCentralCategories } from '../../hooks/organisation-central-categories.hook';
import { CentralCategorySelectLabel } from './CentralCategorySelectLabel';

interface Props {
    organisation: Organisation;
    disabled: boolean | undefined;
}

// It's possible for local categories to not be mapped to Central categories
export const LocalToCentralOrganisationCategoryFormControls = ({
    organisation,
    disabled,
}: Props): React.ReactElement<any, any> | null => {
    const industryLabel = useCategoryLabel('industries');
    const {
        hasOrganisationIndustryMappings,
        industryOptions,
        hasValidScope,
        hasValidSize,
        sizeOptions,
        scopeOptions,
    } = useOrganisationCentralCategories(organisation);

    const {
        organisation: {
            form: {
                organisationSize: { label: sizeLabel },
                organisationScope: { label: scopeLabel },
            },
        },
    } = useStrings();

    return (
        <>
            {!hasOrganisationIndustryMappings && (
                <Form.Row>
                    <Col xs={12} md={6}>
                        <CentralCategorySelectLabel
                            label={industryLabel}
                            categories={organisation.industries || []}
                        />
                        <HookForm.MultiSelect
                            name="centralOrganisationIndustryIds"
                            rules={{ validate: { required: arrayRequiredRule } }}
                            options={industryOptions}
                            disabled={disabled}
                        />
                    </Col>
                </Form.Row>
            )}
            {(!hasValidSize || !hasValidScope) && (
                <Form.Row>
                    {!hasValidSize && (
                        <Col xs={12} md={6}>
                            <CentralCategorySelectLabel
                                label={sizeLabel}
                                categories={organisation.size ? [organisation.size] : []}
                            />
                            <HookForm.SingleSelect
                                name="centralOrganisationSize"
                                rules={{ required: true }}
                                options={sizeOptions}
                                disabled={disabled}
                            />
                        </Col>
                    )}
                    {!hasValidScope && (
                        <Col xs={12} md={6}>
                            <CentralCategorySelectLabel
                                label={scopeLabel}
                                categories={organisation.scope ? [organisation.scope] : []}
                            />
                            <HookForm.SingleSelect
                                name="centralOrganisationScope"
                                rules={{ required: true }}
                                options={scopeOptions}
                                disabled={disabled}
                            />
                        </Col>
                    )}
                </Form.Row>
            )}
        </>
    );
};
