import * as analytics from './analytics/analytics.service';
import * as options from './api-configuration/api-configuration.service';
import * as attachments from './attachments/attachment.service';
import * as authentication from './authentication/services/authentication.service';
import * as identity from './authentication/services/identity.service';
import * as impersonateLocal from './authentication/services/impersonate-local.service';
import * as impersonate from './authentication/services/impersonate.service';
import * as centralApi from './central/central.service';
import { centralLinkHelper } from './central/helpers/central-link.helper';
import * as centralHelper from './central/helpers/central.helper';
import * as contacts from './contacts/contact.service';
import * as content from './content/content.service';
import * as emailClaims from './email-claims/email-claims.service';
import * as eventBookings from './event-bookings/event-booking.service';
import * as eventUnions from './event-unions/event-union.service';
import * as formSubmissions from './form-submissions/form-submissions.service';
import * as individual from './individuals/individual.service';
import * as jobs from './jobs/job.service';
import * as organisation from './organisations/organisation.service';
import * as register from './register/register.service';
import * as settings from './settings/setting.service';
import * as queryParams from './shared/query-params.service';
import * as windowService from './shared/window.service';
import * as workGroups from './work-groups/work-group.service';
import * as uploads from './uploads/upload.service';
import * as entityNotes from './entity-notes/entity-note.service';
import * as skills from './skills/skill.service';
import * as endorsements from './endorsements/endorsement.service';

const services = {
    queryParams,
    authentication,
    emailClaims,
    api: {
        jobs,
        organisation,
        individual,
        contacts,
        register,
        settings,
        options,
        attachments,
        identity,
        formSubmissions,
        workGroups,
        impersonate,
        eventUnions,
        eventBookings,
        uploads,
        entityNotes,
        skills,
        endorsements,
    },
    storage: {
        impersonateLocal,
    },
    central: {
        api: centralApi,
        helper: centralHelper,
        linkHelper: centralLinkHelper,
    },
    content,
    analytics,
    windowService,
};

export type Services = typeof services;

export default services;
