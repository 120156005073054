import { getClientConfig } from 'src/clients-internal/configuration.init';
import { ApiResponse } from '../../../../models/api/response.model';
import { FetchHelperOptions, ObsApiExpected } from '../../../../models/api/service.model';
import {
    CentralContactIdentity,
    ContactIdentity,
    IdentityListResponse,
    IndividualIdentity,
} from '../../../../models/authentication/identity.model';
import { fetchHelper } from '../../../helpers/fetch.helper';

const basePath = '/services/employers/v1/identity';

const options: FetchHelperOptions = { includeAccessToken: true, credentials: 'include' };

export function getIdentityList(): ObsApiExpected<ApiResponse<IdentityListResponse>> {
    return fetchHelper.get(`${getClientConfig().careerHubBasePath}${basePath}`, options);
}

export function setContactIdentity(
    contactId: number
): ObsApiExpected<ApiResponse<ContactIdentity>> {
    return fetchHelper.post(
        `${getClientConfig().careerHubBasePath}${basePath}/contact/${contactId}`,
        undefined,
        options
    );
}

export function setIndividualIdentity(
    individualId: number
): ObsApiExpected<ApiResponse<IndividualIdentity>> {
    return fetchHelper.post(
        `${getClientConfig().careerHubBasePath}${basePath}/individual/${individualId}`,
        undefined,
        options
    );
}

export function setCentralContactIdentity(
    centralContactId: number
): ObsApiExpected<ApiResponse<CentralContactIdentity>> {
    return fetchHelper.post(
        `${getClientConfig().careerHubBasePath}${basePath}/centralContact/${centralContactId}`,
        undefined,
        options
    );
}
