import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { addMinutes, format, getDate, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useCallback, useMemo } from 'react';
import { ApiTimeZone } from 'src/models/api-configuration/api-configuration.model';
import { Icon } from '../icon/Icon';

interface Props {
    date: string;
    showTime?: boolean;
    timeZone?: ApiTimeZone;
    endDate?: string;
}

const dateFormat = 'PP';
const timeFormat = 'h:mm aaa';
const dateTimeFormat = `${dateFormat} - ${timeFormat}`;

export const DateDisplay = ({
    date,
    showTime,
    endDate,
    timeZone,
}: Props): React.ReactElement<any, any> | null => {
    const parse = useCallback(
        (toParse: string) => {
            const parsed = parseISO(toParse);

            if (timeZone?.ianaId) {
                return utcToZonedTime(parsed, timeZone.ianaId);
            }

            return timeZone?.utcOffset
                ? addMinutes(parsed, parsed.getTimezoneOffset() + timeZone.utcOffset)
                : parsed;
        },
        [timeZone]
    );

    const dateObj = parse(date);

    const formattedDate = useMemo(() => {
        return format(dateObj, showTime ? dateTimeFormat : dateFormat);
    }, [dateObj, showTime]);

    const formattedEndDate = useMemo(() => {
        if (!endDate) {
            return;
        }
        const endDateObj = parse(endDate);
        const sameDay = getDate(dateObj) === getDate(endDateObj);

        if (sameDay) {
            return showTime ? format(endDateObj, timeFormat) : undefined;
        }

        return format(endDateObj, showTime ? dateTimeFormat : dateFormat);
    }, [dateObj, endDate, parse, showTime]);

    return (
        <>
            {formattedDate}
            {formattedEndDate && (
                <>
                    <Icon iconClassName="mx-2 small" icon={faArrowRight} />
                    {formattedEndDate}
                </>
            )}
        </>
    );
};
