import { useMemo } from 'react';
import { createFilter } from 'react-select';
import { useCountryList } from '../../../../hooks/country-list.hook';
import { Loader } from '../../../loader/Loader';
import { TypeaheadControl, TypeaheadControlProps, TypeaheadOption } from './TypeaheadControl';

type Props = Omit<TypeaheadControlProps, 'options'>;

export const CountrySelectControl = (props: Props): React.ReactElement<any, any> | null => {
    const { list, fetch } = useCountryList();

    const options: TypeaheadOption[] | undefined = useMemo(() => {
        if (list) {
            // the list is already ordered by relevance so dont worry about that
            return list.map(item => ({
                label: item.name,
                value: item.code,
                tokens: [
                    item.name.toLowerCase(),
                    item.code.toLowerCase(),
                    ...item.aliases.map(i => i.toLowerCase()),
                ],
            }));
        }

        return undefined;
    }, [list]);

    const myFilter: ReturnType<typeof createFilter> = (candidate, rawInput) => {
        const lower = rawInput.toLowerCase();
        return (candidate.data as TypeaheadOption).tokens.some(t => t.startsWith(lower));
    };

    return (
        <Loader state={fetch} useCard={true}>
            {list && options && <TypeaheadControl {...props} filter={myFilter} options={options} />}
        </Loader>
    );
};
