import { useMemo } from 'react';
import { CareerHubEvent } from 'src/models/events/event.model';

export const useCanRequestChanges = (event: CareerHubEvent | null | undefined) => {
    return useMemo(() => {
        if (!event) {
            return false;
        }

        return event.status === 'Upcoming' || event.status === 'Active';
    }, [event]);
};
