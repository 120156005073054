import { faBan, faCheckCircle, faKey, faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import {
    contactDeleteAsync,
    contactSetPrimaryAsync,
    contactUpdateAsync,
} from '../../../../../logic/features/contacts/contact.actions';
import { Contact } from '../../../../../models/contacts/contact.model';
import { contactPathCreator } from '../../../../../routes/employer/paths/contact.paths';
import {
    ActionDropdown,
    ActionDropdownSize,
} from '../../../../shared/components/bootstrap/dropdown/ActionDropdown';
import { DropdownLink } from '../../../../shared/components/bootstrap/dropdown/DropdownLink';
import { DeleteModal } from '../../../../shared/components/bootstrap/modals/DeleteModal';
import { Icon } from '../../../../shared/components/icon/Icon';
import { Loader } from '../../../../shared/components/loader/Loader';
import { useIdentityContact } from '../../../authentication/hooks/identity-contact.hook';
import { useIsPrimaryContact } from '../../hooks/is-primary-contact.hook';

interface Props {
    contact: Contact;
    size: ActionDropdownSize;
}

export const ContactActionDropdown = ({
    contact,
    size,
}: Props): React.ReactElement<any, any> | null => {
    const [showConfirm, setShowConfirm] = useState(false);
    const dispatch = useDispatch();

    const deleteState = useRootSelector(state => state.contacts.single[contact.id]?.deleteState);

    const updateState = useRootSelector(state => state.contacts.single[contact.id]?.update);

    const isPrimaryContact = useIsPrimaryContact(contact.id);
    const { isIdentityContact } = useIdentityContact();
    const isIdentity = isIdentityContact(contact);

    const onConfirmCancel = () => setShowConfirm(false);
    const onConfirm = () => {
        dispatch(contactDeleteAsync.request({ id: contact.id }));
    };

    const onSetActive = (active: boolean) => {
        const updateModel: Contact = { ...contact, active };
        dispatch(contactUpdateAsync.request(updateModel));
    };

    const onSetPrimary = () => {
        dispatch(contactSetPrimaryAsync.request(contact));
    };

    return (
        <div className="d-flex">
            {updateState && <Loader state={updateState} size="sm" />}
            {deleteState && <Loader state={deleteState} size="sm" />}
            <ActionDropdown size={size} disableToggle={updateState?.loading}>
                <Dropdown.Menu>
                    <DropdownLink to={contactPathCreator.edit(contact.id)} icon={faPen}>
                        Edit
                    </DropdownLink>

                    {contact.active && !isPrimaryContact && !contact.isDistributed && (
                        <>
                            <Dropdown.Item onClick={() => onSetPrimary()}>
                                <Icon icon={faKey}>Set Primary</Icon>
                            </Dropdown.Item>
                            {!isIdentity && (
                                <Dropdown.Item onClick={() => onSetActive(false)}>
                                    <Icon icon={faBan}>Set Inactive</Icon>
                                </Dropdown.Item>
                            )}
                        </>
                    )}

                    {!contact.active && !contact.isDistributed && (
                        <Dropdown.Item onClick={() => onSetActive(true)}>
                            <Icon icon={faCheckCircle}>Set Active</Icon>
                        </Dropdown.Item>
                    )}

                    {!isPrimaryContact &&
                        !isIdentity &&
                        !contact.userId &&
                        !contact.hasWebAccess &&
                        !contact.isDistributed && (
                            <>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                    className="text-danger"
                                    onClick={() => setShowConfirm(true)}
                                >
                                    <Icon icon={faTimes}>Delete</Icon>
                                </Dropdown.Item>
                            </>
                        )}
                </Dropdown.Menu>
                {showConfirm && deleteState && (
                    <DeleteModal
                        loading={deleteState.loading}
                        onCancel={onConfirmCancel}
                        onConfirm={onConfirm}
                    />
                )}
            </ActionDropdown>
        </div>
    );
};
