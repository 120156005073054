import { LOCATION_CHANGE } from 'connected-react-router';
import { StateObservable } from 'redux-observable';
import { of, timer } from 'rxjs';
import { delayWhen, filter, map, mergeMap } from 'rxjs/operators';
import { contactAllAsync } from 'src/logic/features/contacts/contact.actions';
import { jobAsync } from 'src/logic/features/jobs/job.actions';
import { organisationAsync } from 'src/logic/features/organisations/organisation.actions';
import {
    delayActionUntilAuthenticated,
    delayTypeUntilAuthenticated,
} from 'src/logic/helpers/epics/app-init.epic-helper';
import { asyncEpicStandard } from 'src/logic/helpers/epics/async.epic-helper';
import { isLocationRouteMatch } from 'src/logic/helpers/epics/location.epic-helper';
import { centralPaths } from 'src/routes/employer/paths/central.paths';
import { identityHelper } from 'src/ui/features/authentication/helpers/identity.helper';
import { isActionOf } from 'typesafe-actions';
import { RootEpic } from '../../epic.root-index';
import { RootState } from '../../reducer.root-index';
import * as actions from '../central.actions';

// any call to central requires the user to be authenticated with Identity
// NOT JUST CAREERHUB
const isCentralAuthenticated = (state$: StateObservable<RootState>) => {
    return !!state$.value.authentication.oidcUser;
};

// ensure user is authenticated by Identity (Central Identity, not CareerHub)
// i.e. There is a valid access bearer token
export const centralAuthenticationRouteEpic: RootEpic = (action$, state$) => {
    return delayTypeUntilAuthenticated(action$, state$, LOCATION_CHANGE).pipe(
        filter(isLocationRouteMatch(centralPaths.publish)),
        filter(() => !isCentralAuthenticated(state$)),
        mergeMap(() => of(actions.centralSetRequiresAuthentication()))
    );
};

// ensure central configuration is loaded
export const centralConfigurationRouteEpic: RootEpic = (action$, state$) => {
    return delayTypeUntilAuthenticated(action$, state$, LOCATION_CHANGE).pipe(
        filter(isLocationRouteMatch(centralPaths.publish)),
        filter(() => isCentralAuthenticated(state$)),
        filter(() => !state$.value.central.configuration.value),
        mergeMap(() => of(actions.centralConfigurationAsync.request()))
    );
};

// sets the jobId in state for use in other epics
export const centralLocalJobSetActiveEpic: RootEpic = (action$, state$, services) => {
    return delayTypeUntilAuthenticated(action$, state$, LOCATION_CHANGE).pipe(
        filter(isLocationRouteMatch(centralPaths.publish)),
        map(() =>
            services.queryParams.parseExpected<{ jobId: string }>(
                state$.value.router.location.search
            )
        ),
        mergeMap(({ jobId }) =>
            of(
                actions.centralCreateJobSetActive({
                    jobId: jobId ? Number(jobId) : undefined,
                })
            )
        )
    );
};

// ensures that the job is loaded if it's requested
export const centralEnsureJobLoadedEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(actions.centralCreateJobSetActive)),
        filter(action => !!action.payload.jobId && !state$.value.jobs.items[action.payload.jobId]),
        mergeMap(action => of(jobAsync.request({ id: action.payload.jobId! })))
    );
};

// ensure all contacts are loaded
export const centralEnsureContactLoadedEpic: RootEpic = (action$, state$) => {
    return delayTypeUntilAuthenticated(action$, state$, LOCATION_CHANGE).pipe(
        filter(isLocationRouteMatch(centralPaths.publish)),
        filter(() => isCentralAuthenticated(state$)),
        filter(() => !identityHelper.isIndividual(state$.value.authentication.activeIdentity!)),
        filter(() => !state$.value.contacts.all.fetched),
        mergeMap(() => of(contactAllAsync.request()))
    );
};

// ensure the organisation doesn't already exist on central
// and only call if the organisation has been loaded
export const centralOrganiastionNodeQueryEpic: RootEpic = (action$, state$) => {
    return delayTypeUntilAuthenticated(action$, state$, LOCATION_CHANGE).pipe(
        filter(isLocationRouteMatch(centralPaths.publish)),
        filter(() => isCentralAuthenticated(state$)),
        delayWhen(() =>
            state$.value.organisation.value
                ? timer(0)
                : action$.pipe(filter(isActionOf(organisationAsync.success)))
        ),
        filter(() => !state$.value.central.publishedEntities[state$.value.organisation.value!.id]),
        mergeMap(() =>
            of(
                actions.centralOrganisationListAsync.request({
                    localId: state$.value.organisation.value!.id,
                    clientCode: state$.value.central.configuration.clientCode,
                })
            )
        )
    );
};

// call the similar organisation endpoint only if the centralOrganisationListAsync response
// returns (successful) and the organisation Id doesn't exist in the response
export const centralSimilarOrganiastionNodeQueryEpic: RootEpic = (action$, state$) => {
    return delayActionUntilAuthenticated(
        action$,
        state$,
        actions.centralOrganisationListAsync.success
    ).pipe(
        filter(() => isCentralAuthenticated(state$)),
        filter(() => !state$.value.central.publishedEntities[state$.value.organisation.value!.id]),

        mergeMap(() => {
            const org = state$.value.organisation.value!;
            return of(
                actions.centralSimilarOrganisationListAsync.request({
                    name: org.name,
                    businessId: org.businessId,
                    acronym: org.acronym,
                    countryCode: org.countryCode,
                    division: org.division,
                    url: org.url,
                    take: 100,
                    skip: 0,
                })
            );
        })
    );
};

// ensure that the job doesn't already exist on central
export const centralJobNodeQueryEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(actions.centralCreateJobSetActive)),
        filter(action => !!action.payload.jobId),
        filter(() => isCentralAuthenticated(state$)),
        mergeMap(action =>
            of(
                actions.centralJobListAsync.request({
                    localId: action.payload.jobId!,
                    clientCode: state$.value.central.configuration.clientCode,
                })
            )
        )
    );
};

export const centralConfigurationAsyncEpic = asyncEpicStandard(
    actions.centralConfigurationAsync,
    services => services.central.api.configurationGet()
);
