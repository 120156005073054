import {
    faCalendarCheck,
    faCalendarTimes,
    faClipboardList,
    faEye,
    faPen,
    faRedo,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { centralPathCreator } from 'src/routes/employer/paths/central.paths';
import { useIsCentralPostingEnabled } from 'src/ui/features/central/hooks/is-central-posting-enabled.hook';
import { CareerHubIcon } from 'src/ui/shared/components/icon/CareerHubIcon';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { jobDeleteAsync, jobWithdrawAsync } from '../../../../../logic/features/jobs/job.actions';
import { Job } from '../../../../../models/jobs/job.model';
import { jobPathCreator } from '../../../../../routes/employer/paths/job.paths';
import { DropdownLink } from '../../../../shared/components/bootstrap/dropdown/DropdownLink';
import { DeleteModal } from '../../../../shared/components/bootstrap/modals/DeleteModal';
import { Icon } from '../../../../shared/components/icon/Icon';
import { jobComponentIdentifier } from '../../identifiers/job-component.identifier';

interface Props {
    job: Job;
}

const ViewItemDropdownLink = ({
    jobId,
}: {
    jobId: number;
}): React.ReactElement<any, any> | null => (
    <DropdownLink to={jobPathCreator.details(jobId)} icon={faEye} iconClassName="fa-fw">
        View
    </DropdownLink>
);

export const JobActionDropdownMenu = ({ job }: Props): React.ReactElement<any, any> | null => {
    const [showConfirm, setShowConfirm] = useState(false);
    const deleting = useRootSelector(state => !!state.jobs.single[job.id]?.deleteState?.loading);
    const withdrawing = useRootSelector(state => !!state.jobs.single[job.id]?.update?.loading);
    const dispatch = useDispatch();
    const isCentralPositingEnabled = useIsCentralPostingEnabled();
    const centralString = useRootSelector(state => state.strings.careerHubStrings.central);

    const onDelete = useCallback(() => {
        dispatch(jobDeleteAsync.request({ id: job.id }));
    }, [dispatch, job]);

    const onConfirmCancel = () => {
        setShowConfirm(false);
    };

    const onWithdraw = () => {
        dispatch(jobWithdrawAsync.request({ id: job.id }));
    };

    switch (job.status) {
        case 'Draft':
            return (
                <>
                    <Dropdown.Menu>
                        <DropdownLink
                            to={jobPathCreator.edit(job.id)}
                            icon={faPen}
                            iconClassName="fa-fw"
                            className={jobComponentIdentifier.notUnique.editButton}
                        >
                            Edit
                        </DropdownLink>
                        <DropdownLink
                            to={jobPathCreator.publish(job.id)}
                            icon={faCalendarCheck}
                            iconClassName="fa-fw"
                        >
                            Publish
                        </DropdownLink>
                        <Dropdown.Divider />
                        <Dropdown.Item className="text-danger" onClick={() => setShowConfirm(true)}>
                            <Icon icon={faTimes} iconClassName="fa-fw">
                                Delete
                            </Icon>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                    {showConfirm && (
                        <DeleteModal
                            loading={deleting}
                            onCancel={onConfirmCancel}
                            onConfirm={onDelete}
                        />
                    )}
                </>
            );
        case 'Prelisted':
        case 'Active':
            return (
                <>
                    <Dropdown.Menu>
                        <ViewItemDropdownLink jobId={job.id} />
                        <DropdownLink
                            to={jobPathCreator.changeRequest(job.id)}
                            icon={faClipboardList}
                            iconClassName="fa-fw"
                        >
                            Change request
                        </DropdownLink>
                        {isCentralPositingEnabled && (
                            <DropdownLink to={centralPathCreator.publish({ jobId: job.id })}>
                                <div className="d-flex align-items-center">
                                    <span
                                        style={{
                                            fontSize: '0.6rem',
                                            marginLeft: '-1px',
                                            marginRight: '11px',
                                            marginTop: '-3px',
                                        }}
                                    >
                                        <CareerHubIcon />
                                    </span>
                                    Publish to {centralString}
                                </div>
                            </DropdownLink>
                        )}
                        <Dropdown.Divider />
                        <Dropdown.Item className="text-danger" onClick={() => setShowConfirm(true)}>
                            <Icon icon={faCalendarTimes} iconClassName="fa-fw">
                                Withdraw
                            </Icon>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                    {showConfirm && (
                        <DeleteModal
                            loading={withdrawing}
                            onCancel={onConfirmCancel}
                            onConfirm={onWithdraw}
                        />
                    )}
                </>
            );
        case 'Expired':
        case 'Withdrawn':
            return (
                <Dropdown.Menu>
                    <ViewItemDropdownLink jobId={job.id} />
                    <DropdownLink
                        to={jobPathCreator.create({ republishId: job.id })}
                        icon={faRedo}
                        iconClassName="fa-fw"
                    >
                        Republish
                    </DropdownLink>
                </Dropdown.Menu>
            );
        default:
            return (
                <Dropdown.Menu>
                    <ViewItemDropdownLink jobId={job.id} />
                </Dropdown.Menu>
            );
    }
};
