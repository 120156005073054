export const JOB_STATUS_OPTIONS = ['Draft', 'Pending', 'Prelisted', 'Active', 'Withdrawn', 'Expired'] as const;
type JobStatusTuple = typeof JOB_STATUS_OPTIONS;
export type JobStatus = JobStatusTuple[number];

export const JOB_POSITIONS_AVAILABLE_OPTIONS = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '10-20',
    '20-50',
    'over 50',
] as const;
type JobPositionsAvailableTuple = typeof JOB_POSITIONS_AVAILABLE_OPTIONS;
export type JobPositionsAvailable = JobPositionsAvailableTuple[number];

export const JOB_CONTRACT_TYPE_OPTIONS = ['Permanent', 'Temporary'] as const;
type JobContractTypeTuple = typeof JOB_CONTRACT_TYPE_OPTIONS;
export type JobContractType = JobContractTypeTuple[number];

export const JOB_CONTRACT_HOURS_OPTIONS = ['FullTime', 'PartTime', 'Casual'] as const;
type JobContractHoursTuple = typeof JOB_CONTRACT_HOURS_OPTIONS;
export type JobContractHours = JobContractHoursTuple[number];
type JobContractHoursMeta = { [key in JobContractHours]: string };
export const JobContractHoursMeta: JobContractHoursMeta = {
    Casual: 'Casual',
    FullTime: 'Full Time',
    PartTime: 'Part Time',
};
