import { push } from 'connected-react-router';
import { useCallback, useMemo } from 'react';
import { Pagination } from 'react-bootstrap';
import { shallowEqual, useDispatch } from 'react-redux';
import {
    EventRequestStatus,
    EventStatus,
    EventUnionStatus,
    EVENT_REQUEST_STATUS_OPTIONS,
    EVENT_STATUS_OPTIONS,
} from 'src/models/events/event-status.model';
import { eventPathCreator } from 'src/routes/employer/paths/event.paths';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

const filterableStatuses: EventUnionStatus[] = [
    'Draft',
    'Pending',
    'Upcoming',
    'Active',
    'Cancelled',
    'Expired',
    'Rejected',
];

// ToDo: this can be folded into something more generic with the job status filter
export const EventListStatusFilter = (): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();

    const activeEventStatuses = useRootSelector(
        state => state.eventUnions.activeList.query.eventStatus || [],
        shallowEqual
    );
    const activeEventRequestStatuses = useRootSelector(
        state => state.eventUnions.activeList.query.eventRequestStatus || [],
        shallowEqual
    );

    const activeStatuses = useMemo(
        () => [...activeEventStatuses, ...activeEventRequestStatuses],
        [activeEventRequestStatuses, activeEventStatuses]
    );

    const onSetStatus = useCallback(
        (status: EventUnionStatus) => {
            const findIndex = activeStatuses.indexOf(status);

            let statuses: EventUnionStatus[] | undefined =
                findIndex !== -1
                    ? activeStatuses.filter((_, i) => i !== findIndex)
                    : [...activeStatuses, status];

            statuses =
                statuses.length > 0 ? statuses.sort((a, b) => a.localeCompare(b)) : undefined;

            const newRequestStatuses: EventRequestStatus[] | undefined = statuses
                ?.filter(i => EVENT_REQUEST_STATUS_OPTIONS.includes(i as any))
                .map(i => i as EventRequestStatus);
            const newEventStatuses: EventStatus[] | undefined = statuses
                ?.filter(i => EVENT_STATUS_OPTIONS.includes(i as any))
                .map(i => i as EventStatus);

            dispatch(
                push(
                    eventPathCreator.list({
                        eventStatus: newEventStatuses,
                        eventRequestStatus: newRequestStatuses,
                    })
                )
            );
        },
        [activeStatuses, dispatch]
    );

    const isActive = useCallback(
        (status: EventUnionStatus) => {
            return activeStatuses.includes(status);
        },
        [activeStatuses]
    );

    return (
        <Pagination size="sm" className="mr-3 pagination-button-group flex-wrap">
            {filterableStatuses.map(i => (
                <Pagination.Item
                    activeLabel="active label"
                    key={i}
                    onClick={() => onSetStatus(i)}
                    active={isActive(i)}
                >
                    {i}
                </Pagination.Item>
            ))}
        </Pagination>
    );
};
