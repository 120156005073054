import { getClientConfig } from 'src/clients-internal/configuration.init';
import { EntityPageRequest } from '../../../models/api/request.model';
import { ApiResponse, ApiSearchResponse } from '../../../models/api/response.model';
import { FetchHelperOptions, ObsApiExpected } from '../../../models/api/service.model';
import { ContactIdentity, IndividualIdentity } from '../../../models/authentication/identity.model';
import { Individual } from '../../../models/individual/individual.model';
import { OrganisationSimilar } from '../../../models/organisation/organisation-similar.model';
import { RegisterName } from '../../../models/register/register-name.model';
import { RegisterOrganisation } from '../../../models/register/register-organisation.model';
import { RegisterRequestAccess } from '../../../models/register/register-request-access.model';
import { apiStringify } from '../../helpers/api-query-string.helper';
import { fetchHelper } from '../../helpers/fetch.helper';

const basePath = '/services/employers/v1/register';

const options: FetchHelperOptions = { includeAccessToken: true, credentials: 'include' };

export interface SimilarOrganisationsRequest extends EntityPageRequest, RegisterName {}

export function similar(
    request: SimilarOrganisationsRequest
): ObsApiExpected<ApiSearchResponse<OrganisationSimilar, SimilarOrganisationsRequest>> {
    return fetchHelper.get(
        `${getClientConfig().careerHubBasePath}${basePath}/similar${apiStringify(request)}`,
        options
    );
}

export function requestAccess(
    request: RegisterRequestAccess
): ObsApiExpected<ApiResponse<RegisterRequestAccess>> {
    return fetchHelper.post(
        `${getClientConfig().careerHubBasePath}${basePath}/request-access`,
        request,
        options
    );
}

// debatable whether this should go here or with organisations
export function registerOrganisation(
    request: RegisterOrganisation
): ObsApiExpected<ApiResponse<ContactIdentity>> {
    return fetchHelper.post(
        `${getClientConfig().careerHubBasePath}${basePath}/organisation`,
        request,
        options
    );
}

export function createIndividual(
    request: Individual
): ObsApiExpected<ApiResponse<IndividualIdentity>> {
    return fetchHelper.post(
        `${getClientConfig().careerHubBasePath}${basePath}/individual`,
        request,
        options
    );
}

let fileRepository: File | undefined = undefined;
let fileObjectUrl: string | undefined = undefined;
export const storeLogo = (file: File) => {
    removeLogo();
    fileRepository = file;
    fileObjectUrl = URL.createObjectURL(file);
};
export const getLogo = (): File | undefined => {
    return fileRepository;
};
export const getLogoObjectUrl = (): string | undefined => {
    return fileObjectUrl;
};
export const removeLogo = () => {
    if (fileObjectUrl) {
        URL.revokeObjectURL(fileObjectUrl);
        fileObjectUrl = undefined;
    }
    fileRepository = undefined;
};
