import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { AlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/AlertWrapper';
import { Markdown } from 'src/ui/shared/components/details/Markdown';
import { useClient } from 'src/ui/shared/hooks/client.hook';
import { useDynamicString, useStrings } from 'src/ui/shared/hooks/strings.hook';
import { ErrorNormalized } from 'src/models/errors/error.model';

interface Props {
    error?: ErrorNormalized;
}

export const CentralValidationErrorAlert = ({
    error,
}: Props): React.ReactElement<any, any> | null => {
    const { centralAppPath } = useClient();
    const {
        central: {
            publish: { errorMessageLink },
        },
    } = useStrings();

    const message = useDynamicString(errorMessageLink, { $link: centralAppPath });

    if (!error) {
        return null;
    }

    return (
        <AlertWrapper icon={faExclamationTriangle} variant="danger">
            <div>
                {!error.validationErrors && <strong>{error.message}</strong>}
                {error.validationErrors &&
                    Object.keys(error.validationErrors).map(key => {
                        if (key.endsWith('nodeId')) {
                            return <div key={key}>This item already exists in Central.</div>;
                        } else {
                            return <div key={key}>{error.validationErrors![key]}</div>;
                        }
                    })}
            </div>
            <div>
                <Markdown source={message} />
            </div>
        </AlertWrapper>
    );
};
