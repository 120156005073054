import { LoaderState } from 'src/models/errors/error.model';
import { createReducer } from 'typesafe-actions';
import * as actions from './upload.actions';

export type UploadState = {
    create: LoaderState;
    activeKey: string | undefined;
};
const initialState: UploadState = {
    create: { loading: false },
    activeKey: undefined,
};

const uploadReducer = createReducer(initialState)
    .handleAction(actions.uploadCreateAsync.request, (state, action) => ({
        ...state,
        create: { loading: true },
    }))
    .handleAction(actions.uploadCreateAsync.failure, (state, action) => ({
        ...state,
        create: {
            loading: false,
            error: action.payload,
        },
    }))
    .handleAction(actions.uploadCreateAsync.success, (state, action) => ({
        ...state,
        create: { loading: false },
        activeKey: action.payload.data.key,
    }))
    .handleAction(actions.uploadResetState, () => initialState);

export default uploadReducer;
