import { Container } from 'react-bootstrap';
import { FooterDynamic } from '../../../../clients-internal/dynamic-components/FooterDynamic';
import { HeaderDynamic } from '../../../../clients-internal/dynamic-components/HeaderDynamic';
import { ContentTopNav } from '../navigation/content-navigation/ContentTopNav';

interface Props {
    className?: string;
    children?: React.ReactNode;
}

export const ContentLayout = ({
    children,
    className,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <div
            className={`content-public-container min-vh-100 d-flex flex-column ${
                className ? className : ''
            }`}
        >
            <div className="z-2">
                <HeaderDynamic />
            </div>
            <Container fluid className="p-0">
                <ContentTopNav />
                {children}
            </Container>
            <FooterDynamic />
        </div>
    );
};
