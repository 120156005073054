import { connectRouter, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { CombinedState, combineReducers, Reducer } from 'redux';
import analytics, { AnalyticsState } from './analytics/analytics.reducer';
import apiConfiguration, {
    ApiConfigurationState,
} from './api-configuration/api-configuration.reducer';
import attachments, { AttachmentState } from './attachments/attachment.reducer';
import authentication, { AuthenticationState } from './authentication/authentication.reducer';
import central, { CentralState } from './central/central.reducer';
import configuration, { ConfigurationState } from './configuration/configuration.reducer';
import contacts, { ContactState } from './contacts/contact.reducer';
import content, { ContentState } from './content/content.reducer';
import emailClaims, { EmailClaimsState } from './email-claims/email-claims.reducer';
import entityNotes, { EntityNoteState } from './entity-notes/entity-note.reducer';
import { eventBookingReducer, EventBookingState } from './event-bookings/event-booking.reducer';
import eventUnions, { EventUnionState } from './event-unions/event-union.reducer';
import formSubmissions, { FormSubmissionState } from './form-submissions/form-submission.reducer';
import individual, { IndividualState } from './individuals/individual.reducer';
import init, { InitializationState } from './initialization/initialization.reducer';
import jobs, { JobState } from './jobs/job.reducer';
import organisation, { OrganisationState } from './organisations/organisation.reducer';
import register, { RegisterState } from './register/register.reducer';
import session, { SessionState } from './sessions/session.reducer';
import settings, { SettingState } from './settings/setting.reducer';
import strings, { StringState } from './strings/string.reducer';
import workGroups, { WorkGroupState } from './work-groups/work-group.reducer';
import uploads, { UploadState } from './uploads/upload.reducer';
import skills, { SkillState } from './skills/skill.reducer';
import endorsements, { EndorsementState } from './endorsements/endorsement.reducer';

export const routerHistory = createBrowserHistory();
const routerReducer = connectRouter(routerHistory);

export type RootState = {
    router: RouterState<unknown>;
    init: InitializationState;
    authentication: AuthenticationState;
    jobs: JobState;
    organisation: OrganisationState;
    individual: IndividualState;
    contacts: ContactState;
    register: RegisterState;
    eventUnions: EventUnionState;
    eventBookings: EventBookingState;
    settings: SettingState;
    apiConfiguration: ApiConfigurationState;
    content: ContentState;
    attachments: AttachmentState;
    formSubmissions: FormSubmissionState;
    session: SessionState;
    central: CentralState;
    strings: StringState;
    emailClaims: EmailClaimsState;
    configuration: ConfigurationState;
    workGroups: WorkGroupState;
    entityNotes: EntityNoteState;
    uploads: UploadState;
    skills: SkillState;
    endorsements: EndorsementState;
    analytics: AnalyticsState;
};

type RootReducer = Reducer<CombinedState<RootState>>;

const rootReducer: RootReducer = combineReducers({
    router: routerReducer,
    init,
    authentication,
    jobs,
    organisation,
    individual,
    contacts,
    register,
    eventUnions,
    eventBookings: eventBookingReducer,
    settings,
    apiConfiguration,
    content,
    attachments,
    formSubmissions,
    session,
    central,
    strings,
    emailClaims,
    workGroups,
    entityNotes,
    configuration,
    uploads,
    skills,
    endorsements,
    analytics,
});

export default rootReducer;
