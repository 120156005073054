import { Job } from '../../../../../models/jobs/job.model';
import {
    ActionDropdown,
    ActionDropdownSize,
} from '../../../../shared/components/bootstrap/dropdown/ActionDropdown';
import { JobActionDropdownMenu } from './JobActionDropdownMenu';

interface Props {
    job: Job;
    size: ActionDropdownSize;
    className?: string;
}

export const JobActionDropdown = ({
    job,
    size,
    className,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <ActionDropdown size={size} className={className}>
            <JobActionDropdownMenu job={job} />
        </ActionDropdown>
    );
};
