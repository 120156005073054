import { faFileUpload, faUpload } from '@fortawesome/free-solid-svg-icons';
import concat from 'lodash/concat';
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, ListGroup, Row } from 'react-bootstrap';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { RejectedFileAlert } from 'src/ui/shared/components/bootstrap/alerts/RejectedFileAlert';
import { AttachmentCreate } from '../../../../../models/attachments/attachment-create.model';
import { HookForm } from '../../../../shared/components/forms/HookForm';
import { Icon } from '../../../../shared/components/icon/Icon';
import { createMaxLengthRule } from '../../../../shared/helpers/validation.helper';
import { useJobAttachments } from '../../hooks/job-attachments.hook';
import { JobAttachmentExistingListItem } from './JobAttachmentExistingListItem';

const ACCEPTED_TYPES = [
    '.gif',
    '.jpg',
    '.jpeg',
    '.png',
    '.pdf',
    '.doc',
    '.docx',
    '.rtf',
    '.txt',
    '.ppt',
    '.pptx',
    '.pps',
    '.xls',
    '.xlsx',
    '.csv',
];

const ATTACHMENT_INPUT_NAME = 'attachmentTitle';

// stackoverflow lol
function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

interface Props {
    setNewAttachments: (attachments: AttachmentCreate[]) => void;
    jobId?: number;
    label?: string;
}

export const JobAttachmentControl = ({
    setNewAttachments,
    jobId,
    label,
}: Props): React.ReactElement<any, any> | null => {
    const existing = useJobAttachments(jobId);

    const { watch, setValue } = useFormContext();

    const [accepted, setAccepted] = useState<File[]>([]);
    const [rejected, setRejected] = useState<FileRejection[]>([]);

    const onDrop = useCallback(
        (newAccepted: File[], newRejected: FileRejection[]) => {
            const fileList = [...accepted, ...newAccepted];

            setAccepted(fileList);
            setRejected(newRejected);

            setValue(
                ATTACHMENT_INPUT_NAME,
                fileList.map(i => i.name)
            );
        },
        [setValue, accepted]
    );

    const titleListValue = watch(ATTACHMENT_INPUT_NAME) as string[];
    useEffect(() => {
        if (titleListValue === undefined) {
            return;
        }

        setNewAttachments(
            accepted.map((file, index) => ({
                file,
                title: titleListValue[index],
            }))
        );
    }, [accepted, titleListValue, setNewAttachments]);

    // 4194304 = 4mbs
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxSize: 4194304,
        accept: ACCEPTED_TYPES,
    });

    const uniqueTitle: (value: string) => string | undefined = value => {
        const valid =
            concat(
                existing.map(i => i.title),
                titleListValue
            ).filter(title => title === value).length === 1;

        // ToDo: move to a resource string area?
        return valid ? undefined : 'File titles must be unique';
    };

    const showColumn = accepted.length + existing.length + rejected.length > 0;

    const removeAttachment = (index: number) => {
        setAccepted(accepted.filter((_, i) => i !== index));
    };

    return (
        <Form.Group>
            {label && <Form.Label>{label}</Form.Label>}
            <Row>
                <Col sm={showColumn ? 6 : 12}>
                    <div {...getRootProps()} className="dropzone-container">
                        <input {...getInputProps()} />
                        <div className={'dropzone' + (isDragActive ? ' drag-hover' : '')}>
                            <Icon icon={faUpload} />
                            <div>
                                <div className="mb-2">
                                    Add attachments <small>(Click or drop files)</small>
                                </div>
                                <small>
                                    <div>
                                        Accepted file types: gif, jpg, jpeg, png, pdf, doc, docx,
                                        rtf, txt, ppt, pptx, pps, xls, xlsx, csv.
                                    </div>
                                    <div>Max size: 4mbs</div>
                                </small>
                            </div>
                        </div>
                    </div>
                </Col>
                {showColumn && (
                    <Col sm={6}>
                        {rejected.length > 0 && (
                            <RejectedFileAlert
                                rejections={rejected}
                                onDismiss={() => setRejected([])}
                            />
                        )}

                        {existing.length > 0 && (
                            <ListGroup className="mb-3">
                                {existing.map(file => (
                                    <JobAttachmentExistingListItem
                                        key={file.storedFileId}
                                        file={file}
                                    />
                                ))}
                            </ListGroup>
                        )}

                        {accepted.map((file, index) => (
                            <HookForm.Input
                                key={index}
                                name={`${ATTACHMENT_INPUT_NAME}[${index}]`}
                                errorName={ATTACHMENT_INPUT_NAME}
                                errorIndex={index}
                                rules={{
                                    required: true,
                                    maxLength: createMaxLengthRule(255),
                                    validate: {
                                        uniqueTitle,
                                    },
                                }}
                                removeMaxCount={true}
                                prepend={
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            <Icon
                                                icon={faFileUpload}
                                                size="lg"
                                                iconClassName="mr-2"
                                            />
                                            {formatBytes(file.size)}
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                }
                                append={
                                    <InputGroup.Append>
                                        <Button
                                            variant="outline-input"
                                            onClick={() => removeAttachment(index)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </Button>
                                    </InputGroup.Append>
                                }
                            ></HookForm.Input>
                        ))}
                    </Col>
                )}
            </Row>
        </Form.Group>
    );
};
