import { format, utcToZonedTime } from 'date-fns-tz';

const formatInTimeZone = (date: string | number | Date, fmt: string, timezone: string) =>
    format(utcToZonedTime(date, timezone), fmt, { timeZone: timezone });

// this is specifically in a format that CareerHub will parse to UTC DateTimeKind
// https://stackoverflow.com/questions/58561169/date-fns-how-do-i-format-to-utc
// https://www.npmjs.com/package/date-fns-tz#format
// tl;dr: it's a bit hacky
export const formatInUtc = (date: string | number | Date) =>
    formatInTimeZone(date, "yyyy-MM-dd'T'HH:mm:ss'Z'", 'UTC');

export const dateHelper = {
    formatInUtc,
};
