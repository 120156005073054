import { useMemo } from 'react';
import { useCountryList } from './country-list.hook';

interface Address {
    street?: string;
    city?: string;
    postcode?: string;
    region?: string;
    countryCode?: string;
}

export const useAddress = (address: Address) => {
    const { getName } = useCountryList();

    const addressString = useMemo(() => {
        const foundInList = getName(address.countryCode);
        return [
            address.street,
            address.city,
            address.postcode,
            address.region,
            foundInList ? foundInList : address.countryCode,
        ]
            .filter(i => i)
            .join(', ');
    }, [address, getName]);

    return addressString;
};
