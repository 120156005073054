import { faBell, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ListGroup } from 'react-bootstrap';
import { AlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/AlertWrapper';
import { Markdown } from 'src/ui/shared/components/details/Markdown';
import { useClient } from 'src/ui/shared/hooks/client.hook';
import { useDynamicString, useStrings } from 'src/ui/shared/hooks/strings.hook';
import { CentralSimilarOrganisationListItem } from './CentralSimilarOrganisationListItem';
import { SimilarOrganisationModel as CentralSimilarOrganisation } from '@symplicity/central-types';

interface Props {
    items: CentralSimilarOrganisation[];
    exactSimilarId: number | undefined;
}

export const CentralSimilarOrganisationInfo = ({
    items,
    exactSimilarId,
}: Props): React.ReactElement<any, any> | null => {
    const {
        central: {
            publish: {
                similar: { alertWarning, aboveMessage, belowMessage, exactAlert },
            },
        },
    } = useStrings();
    const { centralContactUsPath } = useClient();

    const dynamicString = useDynamicString(belowMessage, {
        $link: centralContactUsPath,
    });

    return (
        <>
            {exactSimilarId ? (
                <AlertWrapper variant="danger" icon={faExclamationTriangle}>
                    <Markdown source={exactAlert} />
                </AlertWrapper>
            ) : (
                <AlertWrapper variant="warning" icon={faBell}>
                    <Markdown source={alertWarning} />
                </AlertWrapper>
            )}
            <Markdown source={aboveMessage} />
            <ListGroup className="mb-3">
                {items.map(organisation => (
                    <CentralSimilarOrganisationListItem
                        key={organisation.id}
                        organisation={organisation}
                        exactSimilarId={exactSimilarId}
                    />
                ))}
            </ListGroup>
            <Markdown source={dynamicString} />
        </>
    );
};
