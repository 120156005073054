import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Dropdown, ListGroup } from 'react-bootstrap';
import { identityPaths } from 'src/routes/identity/identity.paths';
import { useLayout } from 'src/routes/RouteList';
import { useClaimValues } from 'src/ui/features/authentication/hooks/claim-values.hook';
import { useClient } from 'src/ui/shared/hooks/client.hook';
import { useLogout } from '../../../../features/authentication/hooks/logout.hook';
import { Loader } from '../../loader/Loader';
import { ApplicationNavItems } from '../application-navigation/ApplicationNavItems';
import { PrivateContentNavItems } from '../application-navigation/PrivateContentNavItems';
import { UserNavListGroup } from '../user-navigation/UserNavItems';

export const MobileNav = (): React.ReactElement<any, any> | null => {
    /** see comment in UserNav.tsx file */
    const claimValues = useClaimValues();
    const { logoutTriggered } = useLogout();
    const showSpinner = logoutTriggered;
    const { isApplicationLayout } = useLayout();
    const { disableApplication } = useClient();

    const [show, setShow] = useState(false);
    return (
        <div className="ml-auto pr-1">
            {showSpinner && <Loader state={true} spinnerClassName="mr-2" />}

            {!claimValues && !showSpinner && (
                <Button
                    variant={isApplicationLayout ? 'primary' : 'content-nav'}
                    className="mr-2"
                    href={identityPaths.landing}
                    disabled={logoutTriggered || disableApplication}
                >
                    Login
                </Button>
            )}

            {claimValues && !showSpinner && (
                <Dropdown
                    className="py-2 position-static"
                    show={show}
                    onToggle={show => setShow(show)}
                >
                    <Dropdown.Toggle
                        variant={isApplicationLayout ? 'link-primary' : 'link-content-nav'}
                        id="user-dropdown"
                        className="no-icon btn-no-focus"
                    >
                        <FontAwesomeIcon className="mr-2" icon={faBars} size="2x" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        className="w-100 px-4 mobile-dropdown-menu"
                        onClick={() => setShow(false)}
                    >
                        <div>
                            <ListGroup variant="flush">
                                <ApplicationNavItems as="list" />
                                <PrivateContentNavItems as="list" />
                            </ListGroup>
                        </div>

                        <hr className="mx-n4 border-dashed" />

                        <UserNavListGroup variant="flush" />
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </div>
    );
};
