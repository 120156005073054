import { Profile } from 'oidc-client';
import { getClientConfig } from 'src/clients-internal/configuration.init';
import { ObsApiExpected } from 'src/models/api/service.model';
import { fetchHelper } from '../../helpers/fetch.helper';

// oidc does not expost the userInfo endpoint, it follows the strict protocol standards and only
// calls automatically if configured to do so.
export const getUserInfo = (): ObsApiExpected<Partial<Profile>> => {
    const url = getClientConfig().identityAuthority + '/connect/userinfo';
    return fetchHelper.get(url, { includeAccessToken: true, credentials: 'omit' });
};

export const addEmailClaim = (data: { email: string }): ObsApiExpected<undefined> => {
    const url = getClientConfig().identityAuthority + '/api/v1/emailclaims';

    return fetchHelper.post(url, data, { includeAccessToken: true, credentials: 'omit' });
};
