import {
    faEnvelope,
    faRandom,
    faSignOutAlt,
    faUserCog,
    faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { Button, Dropdown, ListGroup, ListGroupProps } from 'react-bootstrap';
import { centralLinkHelper } from 'src/logic/features/central/helpers/central-link.helper';
import { useLayoutContext } from 'src/routes/RouteList';
import { centralPathCreator } from 'src/routes/employer/paths/central.paths';
import { identityPaths } from 'src/routes/identity/identity.paths';
import { registerPaths } from 'src/routes/register/register.paths';
import { identityHelper } from 'src/ui/features/authentication/helpers/identity.helper';
import { useCentralAlreadyPublishedCentralId } from 'src/ui/features/central/hooks/central-already-published.hook';
import { useCentralLink } from 'src/ui/features/central/hooks/central-link.hook';
import { useIsCentralPostingEnabled } from 'src/ui/features/central/hooks/is-central-posting-enabled.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { settingsPaths } from '../../../../../routes/employer/paths/settings.paths';
import { useAuthentication } from '../../../../features/authentication/hooks/authentication.hook';
import { useLogout } from '../../../../features/authentication/hooks/logout.hook';
import { useStrings } from '../../../hooks/strings.hook';
import { CareerHubIcon } from '../../icon/CareerHubIcon';
import { Icon } from '../../icon/Icon';
import { Loader } from '../../loader/Loader';
import { LinkWrapper, LinkWrapperProps } from '../../router/LinkWrapper';

interface MenuItemLinkWrapperProps extends LinkWrapperProps {
    label: string;
}

const MenuItemLinkWrapper = (
    props: MenuItemLinkWrapperProps
): React.ReactElement<any, any> | null => (
    <LinkWrapper {...props} iconSize="lg">
        {props.label}
    </LinkWrapper>
);

const CareerHubDropDownWrapper = ({
    label,
    info,
}: {
    label: string;
    info: string;
}): React.ReactElement<any, any> | null => (
    <div className="d-flex align-items-start">
        <div style={{ margin: '2px 3px 0 -3px', fontSize: '95%' }}>
            <CareerHubIcon />
        </div>
        <div className="ml-2">
            <div>{label}</div>
            <div className="small text-muted">{info}</div>
        </div>
    </div>
);

const useSharedMenuItems = () => {
    const { isApplicationLayout } = useLayoutContext();

    const menuItemsConfig: { [key: string]: Omit<LinkWrapperProps, 'as'> } = useMemo(
        () => ({
            settings: {
                icon: faEnvelope,
                to: settingsPaths.settings,
            },
            switchIdentity: {
                icon: faRandom,
                to: isApplicationLayout ? identityPaths.listAuthenticated : identityPaths.list,
            },
            register: {
                icon: faUserPlus,
                to: registerPaths.start,
            },
        }),
        [isApplicationLayout]
    );

    return menuItemsConfig;
};

export const UserNavDropdownMenu = ({
    onLinkClick,
}: {
    onLinkClick: () => void;
}): React.ReactElement<any, any> | null => {
    const { logout } = useLogout();
    const { activeIdentity, identities } = useAuthentication();
    const identityBase = useRootSelector(
        state => state.apiConfiguration.value?.settings?.identityUrl
    );
    const isCentralPositingEnabled = useIsCentralPostingEnabled();
    const menuItemsConfig = useSharedMenuItems();
    const clientCode = useRootSelector(state => state.configuration.clientCode);
    const {
        nav: { user },
    } = useStrings();

    const identityUrl = useMemo(
        () => `${identityBase!}?clientId=${clientCode}`,
        [clientCode, identityBase]
    );

    // bah, this is all a bit hacky, it's been made super complicated with MSP
    const localOrgId = activeIdentity
        ? identityHelper.isContact(activeIdentity)
            ? activeIdentity.organisationId
            : undefined
        : undefined;

    const centralOrganisationId = useCentralAlreadyPublishedCentralId(localOrgId);

    const centralLink = useCentralLink(
        centralLinkHelper.jobList(centralOrganisationId ? centralOrganisationId : -1)
    );

    return (
        <Dropdown.Menu alignRight={true} rootCloseEvent="click">
            <a
                className="dropdown-item"
                href={identityUrl}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Icon icon={faUserCog} size="lg">
                    {user.identity}
                </Icon>
            </a>
            {activeIdentity && (
                <MenuItemLinkWrapper
                    onClick={onLinkClick}
                    {...menuItemsConfig.settings}
                    label={user.communicationSettings}
                    as="dropdown"
                />
            )}
            {!!centralOrganisationId && (
                <a
                    href={centralLink}
                    className="dropdown-item"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <CareerHubDropDownWrapper label={user.central} info={user.centralInfo} />
                </a>
            )}
            {isCentralPositingEnabled && !centralOrganisationId && (
                <LinkWrapper to={centralPathCreator.publish()} onClick={onLinkClick} as="dropdown">
                    <div className="d-flex align-items-start">
                        <CareerHubDropDownWrapper label={user.central} info={user.centralInfo} />
                    </div>
                </LinkWrapper>
            )}
            <Dropdown.Divider />
            {identities.length > 1 && (
                <MenuItemLinkWrapper
                    {...menuItemsConfig.switchIdentity}
                    onClick={onLinkClick}
                    label={user.switchIdentity}
                    as="dropdown"
                />
            )}
            <MenuItemLinkWrapper
                {...menuItemsConfig.register}
                onClick={onLinkClick}
                label={user.register}
                as="dropdown"
            />
            <Dropdown.Divider />
            {activeIdentity && (
                <div className="px-4 py-2">
                    <small>{user.loggedInLabel}</small>
                    <div>
                        {activeIdentity.firstName} {activeIdentity.lastName}
                    </div>
                </div>
            )}
            <div className="px-4 py-2 d-flex">
                <Button size="sm" variant="outline-primary" block onClick={logout}>
                    <Icon icon={faSignOutAlt} size="lg">
                        {user.logout}
                    </Icon>
                </Button>
            </div>
        </Dropdown.Menu>
    );
};

export const UserNavListGroup = (props: ListGroupProps): React.ReactElement<any, any> | null => {
    const { logout, logoutTriggered } = useLogout();
    const { activeIdentity, identities } = useAuthentication();
    const {
        nav: { user },
    } = useStrings();
    const identityUrl = useRootSelector(
        state => state.apiConfiguration.value?.settings?.identityUrl
    );
    const menuItemsConfig = useSharedMenuItems();

    return (
        <Loader state={logoutTriggered}>
            <ListGroup {...props}>
                <a
                    className="list-group-item-action list-group-item"
                    href={identityUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Icon icon={faUserCog} size="lg">
                        {user.identity}
                    </Icon>
                </a>
                {activeIdentity && (
                    <MenuItemLinkWrapper
                        {...menuItemsConfig.settings}
                        label={user.communicationSettings}
                        as="list"
                    />
                )}
                {identities.length > 1 && (
                    <MenuItemLinkWrapper
                        {...menuItemsConfig.switchIdentity}
                        label={user.switchIdentity}
                        as="list"
                    />
                )}
                <MenuItemLinkWrapper
                    {...menuItemsConfig.register}
                    label={user.register}
                    as="list"
                />
                <ListGroup.Item action={true} onClick={logout}>
                    <Icon icon={faSignOutAlt} size="lg">
                        {user.logout}
                    </Icon>
                </ListGroup.Item>
            </ListGroup>
        </Loader>
    );
};
