import { replace } from 'connected-react-router';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAuthentication } from '../hooks/authentication.hook';
import { identityPaths } from 'src/routes/identity/identity.paths';

export const ActiveIdentityRedirectGuard = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    const { initComplete, identities, activeIdentity } = useAuthentication();

    const shouldRedirectToIdentityList = useMemo(() => {
        if (!initComplete) {
            return false;
        }

        return !activeIdentity && identities.length > 1;
    }, [activeIdentity, identities.length, initComplete]);

    useEffect(() => {
        if (shouldRedirectToIdentityList) {
            dispatch(replace(identityPaths.list));
        }
    }, [dispatch, shouldRedirectToIdentityList]);

    return <>{children}</>;
};
