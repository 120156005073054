import { useContext, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useEventOnCampusValues } from 'src/ui/features/events/hooks/event-on-campus-values.hook';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';
import { HookForm } from '../../HookForm';
import { ReactSelectOption } from '../react-selects/SingleSelectControl';
import { EventLocationControlContext, EventLocationForm } from './EventLocationControl';

const campusIdName: keyof EventLocationForm = 'onCampusId';
const locationIdName: keyof EventLocationForm = 'onCampusLocationId';

export const EventLocationOnCampusControl = (): React.ReactElement<any, any> | null => {
    const { watch, setValue } = useFormContext();
    const { campuses, buildings, locations } = useEventOnCampusValues();
    const controlName = useContext(EventLocationControlContext);
    const controlCampusIdName = `${controlName}.${campusIdName}`;
    const controlLocationIdName = `${controlName}.${locationIdName}`;
    const { value: config } = useApiConfiguration();
    const onCampusOptionExists = !config?.events?.disableOnCampusLocation;

    const selectedCampusId = watch(controlCampusIdName) as number | undefined;
    const selectedLocationId = watch(controlLocationIdName) as number | undefined;

    const campusOptions: ReactSelectOption[] = useMemo(() => {
        return campuses.map(i => ({
            label: i.name,
            value: i.id,
            tokens: i.name.toLowerCase().split(' '),
        }));
    }, [campuses]);

    const locationOptions: ReactSelectOption[] = useMemo(() => {
        return locations
            .filter(i => (selectedCampusId ? i.campusId === selectedCampusId : true))
            .map(location => {
                const building = buildings.find(b => b.id === location.buildingId);
                return {
                    label: (
                        <>
                            {building && <span className="text-muted">{building.name} / </span>}
                            <span>{location.name}</span>
                            {location.capacity && (
                                <span className="ml-2 small text-muted">
                                    Capacity: {location.capacity}
                                </span>
                            )}
                        </>
                    ),
                    value: location.id,
                    tokens: building ? [location.name, building.name] : [location.name],
                };
            });
    }, [buildings, locations, selectedCampusId]);

    useEffect(() => {
        if (!selectedCampusId || !selectedLocationId) {
            return;
        }

        const location = locations.find(i => i.id === selectedLocationId)!;
        if (location.campusId === selectedCampusId) {
            return;
        }
        setValue(locationIdName, null, {
            shouldDirty: true,
        });
    }, [campusOptions, locations, selectedCampusId, selectedLocationId, setValue]);

    return (
        <>
            <HookForm.Row>
                <HookForm.Col sm={4}>
                    <HookForm.SingleSelect
                        label="Campus"
                        name={controlCampusIdName}
                        options={campusOptions}
                        rules={{ required: true }}
                        helpText={
                            onCampusOptionExists
                                ? "If you cannot find your location on the list, please use the 'On campus request' option"
                                : undefined
                        }
                    />
                </HookForm.Col>
                <HookForm.Col sm={8}>
                    <HookForm.SingleSelect
                        label="Location"
                        name={controlLocationIdName}
                        options={locationOptions}
                        disabled={!selectedCampusId}
                        rules={{ required: true }}
                    />
                </HookForm.Col>
            </HookForm.Row>
        </>
    );
};
