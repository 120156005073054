import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { centralLinkHelper } from 'src/logic/features/central/helpers/central-link.helper';
import { ManagedByCentralAlert } from 'src/ui/features/central/components/ManagedByCentralAlert';
import { useOrganisation } from 'src/ui/features/organisation/hooks/organisation.hook';
import { AlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/AlertWrapper';
import { Markdown } from 'src/ui/shared/components/details/Markdown';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { contactCreateAsync } from '../../../../logic/features/contacts/contact.actions';
import { Contact } from '../../../../models/contacts/contact.model';
import { CardWrapper } from '../../../shared/components/bootstrap/cards/CardWrapper';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';
import { useFormButton } from '../../../shared/hooks/form-button.hook';
import { useAuthentication } from '../../authentication/hooks/authentication.hook';
import { ContactForm } from '../components/forms/ContactForm';
import { useAllContacts } from '../hooks/all-contacts.hook';

export const ContactAddPage = (): React.ReactElement<any, any> | null => {
    const { error, loading } = useRootSelector(state => state.contacts.create);
    const { centralOrganisationId, fetch, organisation } = useOrganisation();
    const { fetch: contactsAllFetch, contacts } = useAllContacts();
    const { isCentralContact } = useAuthentication();

    const dispatch = useDispatch();

    const onSubmit = useCallback(
        (contact: Contact) => {
            dispatch(contactCreateAsync.request(contact));
        },
        [dispatch]
    );

    const { onCancel } = useFormButton();

    const showUnapprovedContactLimitAlert = useMemo(() => {
        if (organisation && !organisation.isApproved && contacts.length > 9) {
            return true;
        }

        return false;
    }, [contacts.length, organisation]);

    const showApprovedContactLimitAlert = useMemo(() => {
        if (organisation && organisation.isApproved && contacts.length > 99) {
            return true;
        }

        return false;
    }, [contacts.length, organisation]);

    const {
        contact: {
            add: { approvedLimitAlert, unapprovedLimitAlert },
        },
    } = useStrings();

    return (
        <ApplicationPageContent className="contact-add-page">
            <Loader state={[fetch, contactsAllFetch]} useCard={true}>
                {isCentralContact && (
                    <ManagedByCentralAlert
                        stringSelector={strings => strings.contact.add.managedByCentralInfo}
                        centralPath={centralLinkHelper.orgContacts(centralOrganisationId!)}
                    />
                )}

                <CardWrapper>
                    {showUnapprovedContactLimitAlert && (
                        <AlertWrapper icon={faInfoCircle} variant="info">
                            <Markdown source={unapprovedLimitAlert} />
                        </AlertWrapper>
                    )}

                    {showApprovedContactLimitAlert && (
                        <AlertWrapper icon={faInfoCircle} variant="info">
                            <Markdown source={approvedLimitAlert} />
                        </AlertWrapper>
                    )}

                    <ContactForm
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                        error={error}
                        showSpinner={loading}
                        disabled={showUnapprovedContactLimitAlert || showApprovedContactLimitAlert}
                    />
                </CardWrapper>
            </Loader>
        </ApplicationPageContent>
    );
};
