import { format } from 'date-fns';
import { useMemo } from 'react';
import { ErrorNormalized } from 'src/models/errors/error.model';

interface Props {
    error?: ErrorNormalized;
}

export const AuthenticationErrorMessage = ({
    error,
}: Props): React.ReactElement<any, any> | null => {
    const now = format(new Date(), 'PPPP pp');

    // i suppose this might be a bit problematic if we ever try a different language
    const pastOrFuture: 'past' | 'future' | undefined = useMemo(() => {
        if (error?.message.includes('is in the past')) {
            return 'past';
        }
        if (error?.message.includes('is in the future')) {
            return 'future';
        }

        return undefined;
    }, [error?.message]);

    if (!error || !pastOrFuture) {
        return null;
    }

    return (
        <div className="px-5 pb-5 text-center">
            <h2>Your clock is {pastOrFuture === 'past' ? 'ahead' : 'behind'}</h2>
            <p>
                A private connection to{' '}
                <a href={window.location.origin}>{window.location.origin}</a> can't be established
                because your computer's date and time (
                <code className="align-center font-weight-bold">{now}</code>) are incorrect.
            </p>
        </div>
    );
};
