import { EntityRequest } from 'src/models/api/request.model';
import { ApiResponse } from 'src/models/api/response.model';
import { ErrorNormalized } from 'src/models/errors/error.model';
import { WorkGroup } from 'src/models/work-groups/work-group.model';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const workGroupSetDefault = createAction('WORKGROUP:SET_DEFAULT')<EntityRequest>();

export const workGroupListAsync = createAsyncAction(
    'WORKGROUPS:REQUEST',
    'WORKGROUPS:SUCCESS',
    'WORKGROUPS:FAILURE',
    'WORKGROUPS:CANCEL'
)<undefined, ApiResponse<WorkGroup[]>, ErrorNormalized, undefined>();

export const workGroupResetState = createAction('WORKGROUP_RESET_STATE')();
