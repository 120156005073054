import { useCallback, useMemo } from 'react';
import { ApiConfigurationCategories } from 'src/models/api-configuration/api-configuration.model';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';
import { Loader } from '../../../loader/Loader';
import { HookSelectControlProps } from '../selects/SelectControl';
import { ReactSelectOption, SingleSelectControl } from './SingleSelectControl';

type CategorySelectControlProps = Omit<HookSelectControlProps, 'options'> & {
    categoryKey: keyof ApiConfigurationCategories;
};

export const CategorySelectControl = (
    props: CategorySelectControlProps
): React.ReactElement<any, any> | null => {
    const { categoryKey } = props;

    const { fetch, includes } = useApiConfiguration();

    const options: ReactSelectOption<{ id: number }>[] = useMemo(() => {
        return ((includes && includes[categoryKey]) || []).map(i => ({
            label: i.name,
            value: i,
            tokens: i.name.toLowerCase().split(' '),
        }));
    }, [categoryKey, includes]);

    const compareOverride = useCallback((value: { id: number }, other: any) => {
        return value?.id === other?.id;
    }, []);

    return (
        <Loader useCard={true} state={fetch}>
            <SingleSelectControl {...props} options={options} compareOverride={compareOverride} />
        </Loader>
    );
};
