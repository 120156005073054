import { ListGroup } from 'react-bootstrap';
import { useStrings } from '../../hooks/strings.hook';
import { CardWrapper } from '../bootstrap/cards/CardWrapper';
import { Markdown } from '../details/Markdown';

interface Props {
    items: any[];
    whenEmptyComponent?: React.ReactNode;
    children: React.ReactNode;
}

export const ListGroupWrapper = ({
    children,
    items,
    whenEmptyComponent,
}: Props): React.ReactElement<any, any> | null => {
    const {
        shared: {
            lists: { empty },
        },
    } = useStrings();

    if (items && items.length === 0) {
        return whenEmptyComponent ? (
            <>{whenEmptyComponent}</>
        ) : (
            <CardWrapper>
                <Markdown source={empty} />
            </CardWrapper>
        );
    }

    return items ? <ListGroup>{children}</ListGroup> : null;
};
