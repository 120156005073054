import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { LoaderState } from 'src/models/errors/error.model';
import { EventRequest } from 'src/models/events/event-request.model';
import { useEndorsementsIsEnabled } from 'src/ui/features/endorsements/hooks/endorsements-is-enabled.hook';
import { useEndorsements } from 'src/ui/features/endorsements/hooks/endorsements.hook';
import { EventLocationForm } from 'src/ui/shared/components/forms/controls/event-location/EventLocationControl';
import { HookForm } from 'src/ui/shared/components/forms/HookForm';
import { WorkGroupFormRow } from 'src/ui/shared/components/forms/shared/WorkGroupFormRow';
import { LoaderText } from 'src/ui/shared/components/loader/LoaderText';
import { createMaxLengthRule } from 'src/ui/shared/helpers/validation.helper';
import { useIsCareerHubVersion } from 'src/ui/shared/hooks/is-careerhub-version.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { useEventRequestForm } from '../../hooks/event-request-form.hook';
import { useEventsEnabled } from '../../hooks/events-enabled.hook';

export interface EventRequestFormResponse {
    banner?: {
        fileName: string;
        blob: Blob;
    };
    eventRequest: Partial<EventRequest>;
}

export interface EventRequestFormProps {
    eventRequest?: EventRequest;
    onSubmit: (data: EventRequestFormResponse) => void;
    onSaveDraft: (data: EventRequestFormResponse) => void;
    onCancel?: () => void;
    state: LoaderState;
}

export type EventRequestFormData = Pick<
    EventRequest,
    | 'bannerKey'
    | 'bookingInstructions'
    | 'bookingLimit'
    | 'bookingType'
    | 'bookingUrl'
    | 'bookingName'
    | 'start'
    | 'end'
    | 'title'
    | 'eventTypeId'
    | 'summary'
    | 'details'
    | 'workGroupId'
    | 'timeZoneId'
> & {
    location: EventLocationForm;
};

const locationControlName: keyof EventRequestFormData = 'location';

export const EventRequestForm = (
    props: EventRequestFormProps
): React.ReactElement<any, any> | null => {
    const v5_10 = useIsCareerHubVersion(5, 10);
    const v5_13 = useIsCareerHubVersion(5, 13);
    const { state, onCancel } = props;
    const {
        formMethods,
        defaultLocation,
        onSubmit,
        onSubmitAsDraft,
        savingDraftClicked,
        cropperRef,
        minDate,
        startValue,
        setBannerFileName,
        isBlobing,
        hasCampusId,
        disableTimeZoneControl,
        defaultTimeZoneId,
    } = useEventRequestForm(props);

    const {
        shared: {
            forms: { buttons },
        },
    } = useStrings();

    const { isSkillsEnabled } = useEventsEnabled();
    useEndorsements();
    const { hasActiveManagedIds } = useEndorsementsIsEnabled();

    const locationValue = formMethods.watch(locationControlName);
    const timeZoneValue = formMethods.watch('timeZoneId');
    const timeZoneDirty = formMethods.formState.dirtyFields.timeZoneId;

    // sets the timezone to the system default
    // if the user selects away from 'on campus'
    useEffect(() => {
        if (timeZoneDirty) {
            return;
        }

        if (timeZoneValue === defaultTimeZoneId) {
            return;
        }

        if (locationValue.select === 'onCampus') {
            return;
        }

        formMethods.setValue('timeZoneId', defaultTimeZoneId);
    }, [defaultTimeZoneId, formMethods, locationValue, timeZoneDirty, timeZoneValue]);

    return (
        <HookForm {...formMethods} onSubmit={onSubmit}>
            <HookForm.ErrorAlert error={state.error} />
            {/* <StringsAlertWrapper alerts={form.alerts} /> */}

            <HookForm.HiddenId />
            <HookForm.HiddenId name="duplicateFromEventId" />

            <WorkGroupFormRow name="workGroupId" entityType="event" />

            <HookForm.Input
                label="Title"
                name="title"
                autoFocus={true}
                rules={{ required: true, maxLength: createMaxLengthRule(100) }}
            />

            <HookForm.Row>
                <HookForm.Col xs="12" md="6">
                    <HookForm.EventTypeSelect
                        label="Event Type"
                        name="eventTypeId"
                        rules={{ required: true }}
                    />
                </HookForm.Col>
            </HookForm.Row>

            <HookForm.EventLocation
                label="Location"
                name={locationControlName}
                rules={{ required: true }}
                defaultLocation={defaultLocation}
            />

            <hr />

            <HookForm.Row>
                <HookForm.Col lg={3} md={4} sm={6}>
                    <HookForm.DatePickerLocal
                        label="Start"
                        name="start"
                        min={minDate}
                        rules={{ required: true }}
                    />
                </HookForm.Col>
                <HookForm.Col lg={3} md={4} sm={6}>
                    <HookForm.DatePickerLocal
                        label="End"
                        name="end"
                        min={startValue}
                        rules={{ required: true }}
                    />
                </HookForm.Col>
                {v5_13 && (
                    <HookForm.Col lg={3} md={4} sm={6}>
                        <HookForm.TimeZoneSelect
                            label="Time Zone"
                            name="timeZoneId"
                            disabled={disableTimeZoneControl}
                            rules={{ required: true }}
                            helpText={
                                hasCampusId
                                    ? 'Time Zone has been set autoamatically by the selected Campus'
                                    : undefined
                            }
                        />
                    </HookForm.Col>
                )}
            </HookForm.Row>

            <HookForm.Textarea
                label="Summary"
                name="summary"
                minRows={3}
                rules={{ required: true, maxLength: createMaxLengthRule(300) }}
            />

            <HookForm.Html label="Details" name="details" rules={{ required: true }} />

            <hr />

            <HookForm.EventBooking
                rules={{ required: true }}
                locationControlName={locationControlName}
            />

            <hr />

            {isSkillsEnabled && (
                <>
                    <HookForm.Row>
                        <HookForm.Col md={6} sm={12}>
                            <HookForm.SkillMultiSelect
                                label="Primary skills"
                                name="primarySkillIds"
                                otherSkillControlName="secondarySkillIds"
                                helpText="The key skills you are aiming to help students develop from your event."
                                maxItems={2}
                            />
                        </HookForm.Col>
                        <HookForm.Col md={6} sm={12}>
                            <HookForm.SkillMultiSelect
                                label="Secondary skills"
                                name="secondarySkillIds"
                                otherSkillControlName="primarySkillIds"
                                helpText="Any additional skills students may develop from your event."
                                maxItems={3}
                            />
                        </HookForm.Col>
                    </HookForm.Row>
                    {!hasActiveManagedIds && <hr />}
                </>
            )}

            {hasActiveManagedIds && (
                <HookForm.Row>
                    <HookForm.Col md={6} sm={12}>
                        <HookForm.EndorsementMultiSelect
                            label="Endorsements"
                            name="endorsementIds"
                        />
                    </HookForm.Col>
                </HookForm.Row>
            )}

            {v5_10 && (
                <>
                    <HookForm.Cropper
                        name="bannerKey"
                        cropperRef={cropperRef}
                        onFileNameChange={fileName => setBannerFileName(fileName)}
                    />
                    <hr />
                </>
            )}
            <HookForm.Buttons
                showSpinner={(state.loading || isBlobing) && !savingDraftClicked}
                disabled={state.loading || isBlobing}
                submitText={buttons.saveAndContinue}
            >
                <HookForm.ErrorSpan error={state.error} />
                <Button variant="link" onClick={onCancel}>
                    {buttons.cancel}
                </Button>
                <Button
                    className="mr-2"
                    onClick={onSubmitAsDraft}
                    disabled={state.loading || isBlobing}
                >
                    <LoaderText loading={(state.loading || isBlobing) && savingDraftClicked}>
                        {buttons.saveAsDraft}
                    </LoaderText>
                </Button>
            </HookForm.Buttons>
        </HookForm>
    );
};
