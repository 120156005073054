import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Icon, IconDefinitionAll } from '../../icon/Icon';

interface Props {
    to: string;
    icon?: IconDefinitionAll;
    disabled?: boolean;
    className?: string;
    variant?: string;
    id?: string;
    onClick?: () => void;
    children: React.ReactNode;
}

export const ButtonLink = ({
    to,
    disabled,
    className,
    icon,
    children,
    variant,
    onClick,
    id,
    ...props
}: Props): React.ReactElement<any, any> | null => {
    const btnClassName = useMemo(() => {
        return `btn btn-${variant || 'primary'} ${className || ''} ${disabled ? 'disabled' : ''}`;
    }, [variant, className, disabled]);

    return to.startsWith('/') ? (
        <Link to={to} className={btnClassName} onClick={onClick} id={id}>
            {icon ? <Icon icon={icon}>{children}</Icon> : children}
        </Link>
    ) : (
        <a
            id={id}
            href={to}
            rel="noreferrer"
            target="_blank"
            className={btnClassName}
            onClick={onClick}
        >
            {icon ? <Icon icon={icon}>{children}</Icon> : children}
        </a>
    );
};
