import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { registerPaths } from 'src/routes/register/register.paths';

interface Tab {
    label: string;
    disabled?: boolean;
    active?: boolean;
    hidden?: boolean;
    path: string;
}

export const useRegisterWizard = () => {
    const { name, details } = useRootSelector(state => state.register);
    const hasSimilarItems = useRootSelector(state => {
        return state.register.similar.activeList.requestId
            ? !!(
                  (state.register.similar.list[state.register.similar.activeList.requestId]?.ids
                      ?.length || 0) > 0
              )
            : false;
    });
    const { pathname } = useLocation();

    const { register: registerStrings } = useStrings();

    const initial: Tab[] = useMemo(
        () => [
            {
                label: registerStrings.name.tab,
                path: registerPaths.name,
            },
            {
                label: registerStrings.similar.tab,
                hidden: !hasSimilarItems,
                disabled: !name,
                path: registerPaths.similar,
            },
            {
                label: registerStrings.details.tab,
                disabled: !name,
                path: registerPaths.organisation,
            },
            {
                label: registerStrings.contact.tab,
                disabled: !name || !details,
                path: registerPaths.contact,
            },
        ],
        [details, hasSimilarItems, name, registerStrings]
    );

    const tabs: Tab[] = initial.map(tab => ({
        ...tab,
        active: !!matchPath(pathname, tab.path),
    }));

    return { tabs, hasSimilarItems };
};
