import { faBuilding, faUser } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useLayout } from 'src/routes/RouteList';
import { useClaimValues } from 'src/ui/features/authentication/hooks/claim-values.hook';
import { useOuterClick } from 'src/ui/shared/hooks/outer-click.hook';
import { useAuthentication } from '../../../../features/authentication/hooks/authentication.hook';
import { useIndividual } from '../../../../features/individual/hooks/individual.hook';
import { useOrganisation } from '../../../../features/organisation/hooks/organisation.hook';
import { Icon } from '../../icon/Icon';
import { Loader } from '../../loader/Loader';
import { ImpersonationDropdownMenu } from './ImpersonationDropdownMenu';
import { UserNavDropdownMenu } from './UserNavItems';

// ack! this is messy, well the component itself is ok, but the logic is a bit all over the place...
export const ActiveIdentityDropdown = (): React.ReactElement<any, any> | null => {
    const { activeIdentity, identitySetUserFetching, isImpersonation } = useAuthentication();
    const claimValues = useClaimValues();
    const { organisation } = useOrganisation();
    const { individual } = useIndividual();
    const [show, setShow] = useState(false);
    const { navLinkVariant } = useLayout();

    const ref = useOuterClick(() => setShow(false));

    // todo: move into useAuth maybe, need to kill fakes to do this
    const nameClaimDisplay = useMemo(() => {
        if (!claimValues) {
            return 'Account';
        }

        if (claimValues.firstName && claimValues.lastName) {
            return `${claimValues?.firstName} ${claimValues?.lastName}`;
        }

        if (claimValues.email) {
            return claimValues.email;
        }

        return `Account ${claimValues.sub}`;
    }, [claimValues]);

    return (
        <Dropdown show={show} ref={ref}>
            <Loader state={identitySetUserFetching}>
                <Dropdown.Toggle
                    variant={navLinkVariant}
                    id="user-dropdown"
                    className="no-icon text-long-title"
                    onClick={() => setShow(s => !s)}
                >
                    {!activeIdentity && (
                        <Icon icon={faUser} size="lg">
                            {nameClaimDisplay}
                        </Icon>
                    )}

                    {organisation && (
                        <Icon icon={faBuilding} size="lg" title={organisation.name}>
                            {organisation.name}
                        </Icon>
                    )}
                    {individual && (
                        <Icon
                            icon={faUser}
                            size="lg"
                            title={`${individual.firstName} ${individual.lastName}`}
                        >
                            {individual.firstName} {individual.lastName}
                        </Icon>
                    )}
                </Dropdown.Toggle>
                {!isImpersonation && (
                    <UserNavDropdownMenu
                        onLinkClick={() => {
                            setShow(false);
                        }}
                    />
                )}
                {isImpersonation && <ImpersonationDropdownMenu />}
            </Loader>
        </Dropdown>
    );
};
