import { Col, ListGroup, Row } from 'react-bootstrap';
import { contentPathCreator } from 'src/routes/content/content.paths';
import { LinkWrapper } from 'src/ui/shared/components/router/LinkWrapper';
import { useContent } from '../hooks/content.hook';
import { ContentCentralLink } from './ContentCentralLink';

export const ContentFooter = (): React.ReactElement<any, any> | null => {
    const { context, count } = useContent('employerContent');
    if (count < 2) {
        return null;
    }

    return (
        <div className="content-background border-top content-footer">
            <div className="py-5">
                <Row className="content-width mx-auto">
                    {context.collections.map(c => (
                        <Col md={4} xs={12} lg={3} key={c.title}>
                            <ListGroup variant="flush">
                                <LinkWrapper
                                    as="list"
                                    ignoreActive={true}
                                    to={contentPathCreator.contentPage('employerContent', c)}
                                    className="text-primary border-primary"
                                >
                                    {c.title}
                                </LinkWrapper>
                            </ListGroup>
                        </Col>
                    ))}
                </Row>
            </div>
            <ContentCentralLink />
        </div>
    );
};
