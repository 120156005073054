import { ListGroup } from 'react-bootstrap';
import { Contact } from '../../../../../models/contacts/contact.model';
import { contactPathCreator } from '../../../../../routes/employer/paths/contact.paths';
import { MailTo } from '../../../../shared/components/anchors/MailTo';
import { Phone } from '../../../../shared/components/anchors/Phone';
import { ListGroupItemHeader } from '../../../../shared/components/bootstrap/lists/ListGroupItemHeader';
import { ContactActionDropdown } from '../actions/ContactActionDropdown';
import { ContactStatusBadge } from '../badges/ContactStatusBadge';
import { ContactName } from '../shared/ContactName';

interface Props {
    contact: Contact;
}

export const ContactListGroupItem = ({ contact }: Props): React.ReactElement<any, any> | null => {
    const { edit } = contactPathCreator;

    return (
        <ListGroup.Item className={`${contact.active ? '' : 'bg-disabled'}`}>
            <ListGroupItemHeader>
                <ListGroupItemHeader.Title to={edit(contact.id)}>
                    <ContactName contact={contact} />
                </ListGroupItemHeader.Title>
                <ContactStatusBadge contact={contact} badgeClassName="mr-2" />
                <ListGroupItemHeader.Actions>
                    <ContactActionDropdown contact={contact} size="xs" />
                </ListGroupItemHeader.Actions>
            </ListGroupItemHeader>

            <span>{contact.positionTitle}</span>
            <MailTo className={contact.positionTitle ? 'ml-3' : ''} href={contact.email}>
                {contact.email}
            </MailTo>
            <Phone className="ml-3" href={contact.phone}>
                {contact.phone}
            </Phone>
        </ListGroup.Item>
    );
};
