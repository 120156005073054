import { createReducer } from 'typesafe-actions';
import * as actions from './analytics.actions';

export type TrackerType = 'ga' | 'ga4';

export type AnalyticsState = Readonly<{
    showConfirmation: boolean;
    userConfirmAction?: boolean;
    tracker?: TrackerType | undefined;
}>;

const initialState: AnalyticsState = {
    showConfirmation: false,
};

const analyticsReducer = createReducer<AnalyticsState>(initialState)
    .handleAction(actions.analyticsInit, () => ({
        showConfirmation: true,
    }))
    .handleAction(actions.analyticsConfirmation, (state, action) => ({
        showConfirmation: false,
        userConfirmAction: action.payload.confirm,
    }))
    .handleAction(actions.analyticsComplete, (state, action) => ({
        ...state,
        tracker: action.payload.tracker,
    }));

export default analyticsReducer;
