import { useMemo } from 'react';
import { useDomPurify } from '../../hooks/dom-purify.hook';

interface Props {
    source: string;
    className?: string;
}

export const Html = ({ source, className }: Props): React.ReactElement<any, any> | null => {
    const { sanitizeHtml } = useDomPurify();

    const html = useMemo(() => {
        const sourceStr = Array.isArray(source) ? source.join('') : source;
        return sanitizeHtml ? sanitizeHtml(sourceStr) : sourceStr;
    }, [sanitizeHtml, source]);

    return (
        <div
            className={className}
            dangerouslySetInnerHTML={{
                __html: html,
            }}
        ></div>
    );
};
