import { ListGroupWrapper } from 'src/ui/shared/components/lists/ListGroupWrapper';
import { EventChangeRequestListItem } from '../components/change-requests/EventChangeRequestListItem';
import { EventDetailsPageContent } from '../components/details/EventDetailsPageContent';
import { useEventChangeRequests } from '../hooks/event-change-requests.hook';

export const EventChangeRequestListPage = (): React.ReactElement<any, any> | null => {
    const changeRequests = useEventChangeRequests();

    return (
        <EventDetailsPageContent>
            <ListGroupWrapper items={changeRequests}>
                {changeRequests.map(x => (
                    <EventChangeRequestListItem key={x.id} entityNote={x} />
                ))}
            </ListGroupWrapper>
        </EventDetailsPageContent>
    );
};
