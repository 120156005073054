import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { contentPathCreator } from '../../../../../routes/content/content.paths';
import { useContent } from '../../../../features/content/hooks/content.hook';
import { LinkWrapper, LinkWrapperOptions } from '../../router/LinkWrapper';

interface Props {
    as: LinkWrapperOptions;
    className?: string;
}

export const PrivateContentNavItems = ({
    as,
    className,
}: Props): React.ReactElement<any, any> | null => {
    const {
        context: { collections },
    } = useContent('employerProtected');

    return (
        <>
            {collections.map(collection => (
                <LinkWrapper
                    key={collection.title}
                    as={as}
                    icon={faCaretRight}
                    iconSize="lg"
                    to={contentPathCreator.contentPage('employerProtected', collection, undefined)}
                    className={className}
                >
                    {collection.title}
                </LinkWrapper>
            ))}
        </>
    );
};
