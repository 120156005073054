import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch } from 'react-redux';
import { centralCreateUserConfirmation } from 'src/logic/features/central/central.actions';
import { CentralPublishState } from 'src/logic/features/central/central.reducer';
import { centralLinkHelper } from 'src/logic/features/central/helpers/central-link.helper';
import { Job } from 'src/models/jobs/job.model';
import { Organisation } from 'src/models/organisation/organisation.model';
import { HookForm } from 'src/ui/shared/components/forms/HookForm';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useClient } from 'src/ui/shared/hooks/client.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { useCentralAlreadyPublishedCentralId } from '../../hooks/central-already-published.hook';
import { useCentralLink } from '../../hooks/central-link.hook';
import { useCentralPublishErrors } from '../../hooks/central-publish-errors.hook';
import { LocalToCentralJobCategoryFormControls } from './LocalToCentralJobCategoryFormControls';
import { LocalToCentralOrganisationCategoryFormControls } from './LocalToCentralOrganisationCategoryFormControls';

type CentralAdditionalFields = Pick<
    CentralPublishState,
    | 'subscribeCentral'
    | 'subscribeCentralApps'
    | 'centralJobOccupationIds'
    | 'centralJobEmploymentTypeIds'
    | 'centralOrganisationIndustryIds'
    | 'centralOrganisationSize'
    | 'centralOrganisationScope'
>;

interface Props {
    organisation: Organisation;
    job: Job | undefined;
    inviteContactIds: number[];
}

export const CentralPublishConfirmationForm = ({
    organisation,
    job,
    inviteContactIds,
}: Props): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    const { centralMarketingPath } = useClient();

    const {
        currentStep: { step },
        publishedEntities,
    } = useRootSelector(state => state.central);

    // the shallow equals is necessary here as the root object doesn't change
    // only it's innards
    const jobAttachmentState = useRootSelector(
        state => state.central.jobAttachmentState,
        shallowEqual
    );

    const { hasError } = useCentralPublishErrors();
    const isActioning = useMemo(() => step !== 'init' && step !== 'complete', [step]);

    const organisationAlreadyPublishedCentralId = useCentralAlreadyPublishedCentralId(
        organisation.id
    );

    const redirectPath = useMemo(() => {
        // organisation doesn't exist in central yet
        if (!organisationAlreadyPublishedCentralId) {
            return undefined;
        }

        const jobCentralMeta = job && publishedEntities[job.id];

        // job "exists" but doesn't exist in central yet
        if (job && !jobCentralMeta?.centralId) {
            return undefined;
        }

        // make sure none of the attachement calls have errored or are uploading
        if (
            Object.keys(jobAttachmentState)
                .map(key => !!jobAttachmentState[key]?.loading || !!jobAttachmentState[key]?.error)
                .some(loadingOrErrored => loadingOrErrored)
        ) {
            return undefined;
        }

        return centralLinkHelper.afterPublishRedirect({
            centralOrganisationId: organisationAlreadyPublishedCentralId,
            centralJobId: jobCentralMeta?.centralId,
            centralJobHasPublishDate: jobCentralMeta?.hasPublishDate,
            publish: job?.publishDate,
            expire: job?.expireDate,
        });
    }, [organisationAlreadyPublishedCentralId, job, publishedEntities, jobAttachmentState]);

    const redirectUrl = useCentralLink(redirectPath ?? '');

    // todo: this should be moved to the strings
    const buttonText = useMemo(() => {
        if (!organisationAlreadyPublishedCentralId && job) {
            return 'Register my organisation and submit job';
        }

        if (job) {
            return 'Submit my job for approval';
        }

        return 'Register my organisation';
    }, [job, organisationAlreadyPublishedCentralId]);

    const triggerCentralInit = (formFields: CentralAdditionalFields) => {
        window.scrollTo(0, 0);

        dispatch(
            centralCreateUserConfirmation({
                organisation,
                job,
                inviteContactIds,
                centralOrganisationId: organisationAlreadyPublishedCentralId,
                ...formFields,
            })
        );
    };

    const {
        central: {
            publish: { confirm, organisation: organisationStrings },
        },
    } = useStrings();

    const formMethods = useForm<CentralAdditionalFields>();

    return (
        <>
            <HookForm {...formMethods} onSubmit={triggerCentralInit}>
                {!redirectPath && job && (
                    <LocalToCentralJobCategoryFormControls job={job} disabled={isActioning} />
                )}

                {!organisationAlreadyPublishedCentralId && (
                    <>
                        <LocalToCentralOrganisationCategoryFormControls
                            organisation={organisation}
                            disabled={isActioning}
                        />

                        <HookForm.Checkbox
                            disabled={isActioning}
                            formGroupClassName="mb-0"
                            name="subscribeCentral"
                            {...organisationStrings.subscibeCentral}
                        />
                        <HookForm.Checkbox
                            disabled={isActioning}
                            formGroupClassName="mb-0"
                            name="subscribeCentralApps"
                            {...organisationStrings.subscibeCentralApps}
                        />
                    </>
                )}

                {redirectPath ? (
                    <>
                        <p>{confirm.afterCompleteMessage}</p>
                        <a
                            href={redirectUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-primary"
                        >
                            {confirm.afterCompleteSubmitText}
                        </a>
                    </>
                ) : (
                    <HookForm.Buttons
                        disabled={isActioning}
                        showSpinner={isActioning && !hasError}
                        textAlign="left"
                        submitText={buttonText}
                        after={
                            <a
                                href={centralMarketingPath}
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-link"
                            >
                                <Icon icon={faExternalLinkAlt}>Find out more</Icon>
                            </a>
                        }
                    />
                )}
            </HookForm>
        </>
    );
};
