import { createAction } from 'typesafe-actions';
import { TrackerType } from './analytics.reducer';

export const analyticsInit = createAction('ANALYTICS_INIT:START')();
export const analyticsConfirmation = createAction('ANALYTICS_INIT:CONFIRMATION')<{
    confirm: boolean;
}>();
export const analyticsComplete = createAction('ANALYTICS_INIT:COMPLETE')<{
    tracker: TrackerType | undefined;
}>();
