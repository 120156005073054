import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { eventDismissCancelMessage } from 'src/logic/features/event-unions/event-union.actions';
import { AlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/AlertWrapper';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

export const EventCancelledAlert = (): React.ReactElement<any, any> | null => {
    const { title, showMessage } = useRootSelector(state => state.eventUnions.cancelled);
    const dispatch = useDispatch();

    const onDismiss = () => {
        dispatch(eventDismissCancelMessage());
    };

    if (!showMessage) {
        return null;
    }

    return (
        <AlertWrapper variant="info" icon={faInfoCircle} onDismiss={onDismiss} dismissible>
            The event "{title}" has been cancelled.
        </AlertWrapper>
    );
};
