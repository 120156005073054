import { createReducer } from 'typesafe-actions';
import { Individual } from '../../../models/individual/individual.model';
import { EntitySingleState } from '../../../models/store-models/entity-single-state.model';
import * as actions from './individual.actions';
import { singleReducerHandler } from '../../reducer-handlers/single.reducer-handler';

export type IndividualState = EntitySingleState<Individual>;

const initialState: IndividualState = {
    request: { fetch: { loading: false }, update: { loading: false } },
    value: undefined,
};

const individualReducer = createReducer(initialState)
    .handleAction(actions.individualAsync.request, singleReducerHandler.request)
    .handleAction(actions.individualAsync.success, singleReducerHandler.success)
    .handleAction(actions.individualAsync.failure, singleReducerHandler.failure)
    .handleAction(actions.individualAsync.cancel, singleReducerHandler.cancel)
    .handleAction(actions.individualUpdateAsync.request, singleReducerHandler.updateRequest)
    .handleAction(actions.individualUpdateAsync.success, singleReducerHandler.success)
    .handleAction(actions.individualUpdateAsync.failure, singleReducerHandler.updateFailure)
    .handleAction(actions.individualResetState, () => initialState);

export default individualReducer;
