import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { push } from 'connected-react-router';
import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { jobChangeRequestAsync } from '../job.actions';
import { RootEpic } from '../../epic.root-index';
import { isRouteMatch } from 'src/logic/helpers/epics/location.epic-helper';
import { jobPathCreator, jobPaths } from 'src/routes/employer/paths/job.paths';

export const jobChangeRequestEpic = asyncEpicBase(
    jobChangeRequestAsync,
    ({ api }, { payload }) => api.jobs.changeRequest(payload),
    {
        success: result => jobChangeRequestAsync.success(result.json),
        failure: (error, ra) => jobChangeRequestAsync.failure(error, ra.payload),
    }
);

export const jobChangeRequestRedirectEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(jobChangeRequestAsync.success)),
        filter(() => isRouteMatch(state$.value.router.location, jobPaths.changeRequest)),
        mergeMap(() => of(push(jobPathCreator.listDefault())))
    );
};
