import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import {
    CentralContactIdentity,
    ContactIdentity,
} from '../../../../../models/authentication/identity.model';
import { identityHelper } from '../../helpers/identity.helper';
import { CentralContactIdentityBadges } from './CentralContactIdentityBadges';
import { ContactIdentityBadges } from './ContactIdentityBadges';
import { IdentityListItem, IdentityListItemBaseProps } from './IdentityListItem';
import { UserIdentityBadges } from './UserIdentityBadges';

// NOTE: This component handles both central and non-central organisation contacts
interface Props extends IdentityListItemBaseProps {
    identity: ContactIdentity | CentralContactIdentity;
}

export const ContactIdentityListItem = (props: Props): React.ReactElement<any, any> | null => {
    const { identity } = props;

    return (
        <IdentityListItem {...props} icon={faBuilding}>
            <div className="d-flex">
                <div className="mr-2">{identity.organisationName}</div>
                {identity.organisationDivision && (
                    <div className="mr-2">- {identity.organisationDivision}</div>
                )}
                <div className="ml-auto">
                    {identityHelper.isCentralContact(identity) && (
                        <CentralContactIdentityBadges identity={identity} />
                    )}
                    {identityHelper.isContact(identity) && (
                        <ContactIdentityBadges identity={identity} />
                    )}
                    <UserIdentityBadges identity={identity} />
                </div>
            </div>
        </IdentityListItem>
    );
};
