import { BulletPointDetails } from 'src/ui/shared/components/details/BulletPointDetails';
import { Job } from '../../../../../models/jobs/job.model';
import { Details } from '../../../../shared/components/details/Details';
import { ToggleWell } from '../../../../shared/components/toggle-well/ToggleWell';
import { JobDetailsAttachments } from './JobDetailsAttachments';
import { JobDetailsHeader } from './JobDetailsHeader';
import { JobDetailsProperties } from './JobDetailsProperties';

interface Props {
    job: Job;
}

export const JobDetails = ({ job }: Props): React.ReactElement<any, any> | null => {
    return (
        <Details
            header={<JobDetailsHeader job={job} />}
            properties={<JobDetailsProperties job={job} />}
        >
            <h2 className="font-weight-normal">Details</h2>
            <BulletPointDetails source={job.details} />

            <JobDetailsAttachments jobId={job.id} />

            <ToggleWell label="How to Apply" className="mb-3">
                <BulletPointDetails source={job.procedures} />
            </ToggleWell>
        </Details>
    );
};
