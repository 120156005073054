import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import { useFormError } from 'src/ui/shared/hooks/form-error.hook';
import { HookControlProps, useCustomFormContext } from '../HookForm';
import { ControlFormGroup } from '../shared/ControlFormGroup';

interface HookTextareaProps extends HookControlProps {
    minRows: number;
}

export const TextareaControl = (props: HookTextareaProps): React.ReactElement<any, any> | null => {
    const {
        rules,
        name,
        errorName,
        placeholder,
        minRows,
        disabled,
        tabIndex,
        autoFocus,
        errorIndex,
    } = props;
    const { control, getValues, setValue } = useFormContext();
    const { disabled: contextDisabled } = useCustomFormContext();
    const [initialValueFix, setInitialValueFix] = useState(false);

    const hasError = useFormError(errorName || name, errorIndex);

    // fix for null values returned from the api, and TextareaAutosize erroring
    // on null
    useEffect(() => {
        if (initialValueFix) {
            return;
        }

        const initialValue = getValues(name);
        if (initialValue === null) {
            setValue(name, undefined);
        }

        setInitialValueFix(true);
    }, [getValues, initialValueFix, name, setValue]);

    return (
        <ControlFormGroup {...props}>
            {initialValueFix && (
                <Controller
                    name={name}
                    rules={rules}
                    control={control}
                    render={({ field: { ref, ...fieldWithoutRef } }) => (
                        <TextareaAutosize
                            {...fieldWithoutRef}
                            inputRef={ref}
                            className={`form-control ${hasError ? 'is-invalid' : ''}`}
                            placeholder={placeholder}
                            minRows={minRows}
                            autoFocus={autoFocus}
                            disabled={disabled || contextDisabled}
                            tabIndex={tabIndex}
                        />
                    )}
                />
            )}
        </ControlFormGroup>
    );
};
