import { eventPathCreator } from 'src/routes/employer/paths/event.paths';
import { BreadcrumbLink } from 'src/ui/shared/components/breadcrumbs/BreadcrumbLink';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';

export const EventListBreadcrumb = (): React.ReactElement<any, any> | null => {
    const {
        nav: {
            application: { events },
        },
    } = useStrings();

    return <BreadcrumbLink to={eventPathCreator.listDefault()}>{events}</BreadcrumbLink>;
};
