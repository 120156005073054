import { DateDisplay } from 'src/ui/shared/components/details/DateDisplay';
import { formPathExternalCreator } from '../../../../routes/employer/paths/form.paths';
import { ListGroupWrapper } from '../../../shared/components/lists/ListGroupWrapper';
import { Loader } from '../../../shared/components/loader/Loader';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';
import { ListPaginationWrapper } from '../../../shared/components/page-components/ListPaginationWrapper';
import { useActiveEntityList } from '../../../shared/hooks/active-entity-list.hook';

// terrible page. should have a dedicated flow for employers with forms.
export const FormSubmissionListPage = (): React.ReactElement<any, any> | null => {
    const {
        entities: submissions,
        fetch,
        pagination,
    } = useActiveEntityList(state => state.formSubmissions);

    return (
        <ApplicationPageContent className="form-submission-list-page">
            <ListPaginationWrapper pagination={pagination} />

            <Loader state={fetch} useCard={true}>
                <ListGroupWrapper items={submissions}>
                    <table className="table table-bordered bg-white">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Attached to</th>
                                <th scope="col">Submitted by</th>
                                <th scope="col">Submitted on</th>
                            </tr>
                        </thead>
                        <tbody>
                            {submissions.map(submission => (
                                <tr key={submission.id}>
                                    <th scope="row">
                                        <a
                                            href={formPathExternalCreator.submission(
                                                submission.formId,
                                                submission.id
                                            )}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {submission.formTitle}
                                        </a>
                                    </th>
                                    <td>{submission.attachedToName}</td>
                                    <td>{submission.submittedByName}</td>
                                    <td>
                                        {submission.submitted && (
                                            <DateDisplay date={submission.submitted} />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </ListGroupWrapper>
            </Loader>
        </ApplicationPageContent>
    );
};
