import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { organisationUpdateAsync } from 'src/logic/features/organisations/organisation.actions';
import { Organisation } from 'src/models/organisation/organisation.model';
import { useIsIndividual } from 'src/ui/features/authentication/hooks/is-individual.hook';
import { AlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/AlertWrapper';
import { ThinLayout } from 'src/ui/shared/components/layout/ThinLayout';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { ThinPageContent } from 'src/ui/shared/components/page-components/ThinPageContent';
import { useOrganisation } from '../../hooks/organisation.hook';
import { OrganisationDetailsForm, organisationValidator } from '../forms/OrganisationDetailsForm';

interface Props {
    children?: React.ReactNode;
}

export const OrganisationValidationGuard = ({
    children,
}: Props): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    const isIndividual = useIsIndividual();
    const { fetch, update, organisation } = useOrganisation();
    const [hasSkipped, setHasSkipped] = useState(false);

    const isValid = useMemo(() => {
        if (isIndividual || !organisation || organisation.isDistributed) {
            return true;
        }

        const result = organisationValidator.safeParse(organisation);
        return result.success;
    }, [isIndividual, organisation]);

    const onSubmit = (organisation: Organisation, logo: File | undefined) => {
        dispatch(organisationUpdateAsync.request({ organisation, logo }));
    };

    const onCancel = () => {
        setHasSkipped(true);
    };

    if (isValid || hasSkipped) {
        return <>{children}</>;
    }

    return (
        <Loader state={fetch}>
            <ThinLayout>
                <ThinPageContent>
                    <AlertWrapper variant="info" icon={faInfoCircle}>
                        Your organisation details are incomplete. Please complete the following form
                        to continue.
                    </AlertWrapper>
                    <div className="p-3 border">
                        <OrganisationDetailsForm
                            organisation={organisation}
                            onSubmit={onSubmit}
                            cancelText="Continue without updating"
                            onCancel={onCancel}
                            error={update?.error}
                            showSpinner={update?.loading}
                            validateOnLoad
                        />
                    </div>
                </ThinPageContent>
            </ThinLayout>
        </Loader>
    );
};
