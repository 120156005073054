import { useContext } from 'react';
import { createMaxLengthRule } from 'src/ui/shared/helpers/validation.helper';
import { HookForm } from '../../HookForm';
import { EventLocationControlContext, EventLocationForm } from './EventLocationControl';

const onCampusRequestName: keyof EventLocationForm = 'onCampusRequest';

export const EventLocationOnCampusRequestControl = (): React.ReactElement<any, any> | null => {
    const controlName = useContext(EventLocationControlContext);

    return (
        <>
            <HookForm.Textarea
                minRows={3}
                label="Describe the venue and facilities you need"
                name={`${controlName}.${onCampusRequestName}`}
                helpText="Add the room details or describe the facilities you require."
                rules={{ maxLength: createMaxLengthRule(4000), required: true }}
            />
        </>
    );
};
