import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useState } from 'react';
import { Button, ListGroup, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { StoredFile } from '../../../../../models/jobs/job-stored-file.model';
import { attachmentDeleteAsync } from '../../../../../logic/features/attachments/attachment.actions';
import { DeleteModal } from '../../../../shared/components/bootstrap/modals/DeleteModal';
import { Icon } from '../../../../shared/components/icon/Icon';
import { createDownloadUrl } from '../../../../shared/helpers/download.helper';

interface Props {
    file: StoredFile;
}

export const JobAttachmentExistingListItem = ({
    file,
}: Props): React.ReactElement<any, any> | null => {
    const [showConfirm, setShowConfirm] = useState(false);

    const deletingState = useRootSelector(
        state => state.attachments.deleteState[file.storedFileId]
    );

    const dispatch = useDispatch();
    const { storedFileId } = file;

    const onDeleteClick = useCallback(() => {
        setShowConfirm(true);
    }, []);

    const onConfirm = useCallback(() => {
        dispatch(attachmentDeleteAsync.request({ id: storedFileId }));
        setShowConfirm(false);
    }, [storedFileId, dispatch]);

    const onConfirmCancel = useCallback(() => {
        setShowConfirm(false);
    }, []);

    return deletingState?.deleted ? null : (
        <ListGroup.Item>
            <div className="d-flex align-items-center">
                {deletingState?.deleting ? (
                    <>
                        <Icon icon={faFileDownload}>{file.title}</Icon>
                        <div className="ml-auto">
                            <Spinner animation="border" variant="danger" size="sm" />
                        </div>
                    </>
                ) : (
                    <>
                        <a href={createDownloadUrl(storedFileId)}>
                            <Icon icon={faFileDownload}>{file.title}</Icon>
                        </a>
                        <Button
                            variant="link"
                            className="ml-auto p-0 text-danger"
                            onClick={onDeleteClick}
                        >
                            <span aria-hidden="true">&times;</span> delete
                        </Button>
                    </>
                )}
            </div>
            {showConfirm && (
                <DeleteModal loading={false} onCancel={onConfirmCancel} onConfirm={onConfirm} />
            )}
        </ListGroup.Item>
    );
};
