import keyBy from 'lodash/keyBy';
import { createReducer } from 'typesafe-actions';
import { Contact } from '../../../models/contacts/contact.model';
import { LoaderState } from '../../../models/errors/error.model';
import { EntityListState } from '../../../models/store-models/entity-list-state.model';
import { ContactPageRequest } from './contact.service';
import * as actions from './contact.actions';
import { entityListInitialState } from '../../helpers/initial-state.helper';
import { listSingleReducerHandler } from '../../reducer-handlers/list-single.reducer-handler';
import { listReducerHandler } from 'src/logic/reducer-handlers/list.reducer-handler';

export type ContactState = EntityListState<Contact, ContactPageRequest> &
    Readonly<{
        primaryContactId: number | undefined;
        all: {
            fetch: LoaderState;
            fetched: boolean;
            ids: number[];
        };
    }>;

const initialState: ContactState = {
    ...entityListInitialState,
    primaryContactId: undefined,
    all: {
        fetch: { loading: false },
        fetched: false,
        ids: [],
    },
};

const contactListReducer = createReducer(initialState)
    .handleAction(actions.contactSetActive, listSingleReducerHandler.setActive)
    .handleAction(actions.contactListSetActive, listReducerHandler.setActive)

    .handleAction(actions.contactListAsync.request, listReducerHandler.request)
    .handleAction(actions.contactListAsync.success, listReducerHandler.success)
    .handleAction(actions.contactListAsync.cancel, listReducerHandler.cancel)
    .handleAction(actions.contactListAsync.failure, listReducerHandler.failure)
    .handleAction(actions.contactListClear, listReducerHandler.clear)

    .handleAction(actions.contactAsync.request, listSingleReducerHandler.request)
    .handleAction(actions.contactAsync.success, listSingleReducerHandler.success)
    .handleAction(actions.contactAsync.cancel, listSingleReducerHandler.cancel)
    .handleAction(actions.contactAsync.failure, listSingleReducerHandler.failure)

    .handleAction(actions.contactCreateAsync.request, listSingleReducerHandler.create)
    .handleAction(actions.contactCreateAsync.success, (state, action) => ({
        ...listSingleReducerHandler.success(state, action),
        all: {
            ...state.all,
            ids: [...state.all.ids, action.payload.data.id],
        },
    }))
    .handleAction(actions.contactCreateAsync.failure, listSingleReducerHandler.createFailure)

    .handleAction(actions.contactUpdateAsync.request, listSingleReducerHandler.updateRequest)
    .handleAction(actions.contactUpdateAsync.success, listSingleReducerHandler.success)
    .handleAction(actions.contactUpdateAsync.failure, listSingleReducerHandler.updateFailure)

    .handleAction(actions.contactDeleteAsync.request, listSingleReducerHandler.deleteRequest)
    .handleAction(actions.contactDeleteAsync.success, listSingleReducerHandler.deleteSuccess)
    .handleAction(actions.contactDeleteAsync.failure, listSingleReducerHandler.deleteFailure)

    .handleAction(actions.contactSetPrimaryAsync.request, listSingleReducerHandler.updateRequest)
    .handleAction(actions.contactSetPrimaryAsync.success, listSingleReducerHandler.success)
    .handleAction(actions.contactSetPrimaryAsync.failure, listSingleReducerHandler.updateFailure)

    .handleAction(actions.contactAllAsync.request, state => ({
        ...state,
        all: {
            fetch: { loading: true },
            fetched: false,
            ids: [],
        },
    }))
    .handleAction(actions.contactAllAsync.success, (state, action) => ({
        ...state,
        all: {
            fetch: { loading: false },
            fetched: true,
            ids: action.payload.data.map(i => i.id),
        },
        items: {
            ...state.items,
            ...keyBy(action.payload.data, item => item.id),
        },
    }))
    .handleAction(actions.contactAllAsync.failure, (state, action) => ({
        ...state,
        all: {
            ...state.all,
            fetch: { loading: false, error: action.payload },
        },
    }))
    .handleAction(actions.contactSetPrimarySetActive, (state, action) => ({
        ...state,
        primaryContactId: action.payload.id,
    }))
    .handleAction(actions.contactResetState, () => initialState);

export default contactListReducer;
