import { format, parseISO } from 'date-fns';
import { appStringify } from 'src/routes/router.helper';

const orgDetails = (centralOrganisationId: number) => `organisations/${centralOrganisationId}`;
const orgContacts = (centralOrganisationId: number) =>
    `contacts?organisationIds=${centralOrganisationId}`;
const orgEdit = (centralOrganisationId: number) => `organisations/${centralOrganisationId}/edit`;
const jobList = (centralOrganisationId: number) => `jobs?organisationIds=${centralOrganisationId}`;
const jobPublish = (centralJobId: number, publish?: string, expire?: string) =>
    `jobs/${centralJobId}/publish${appStringify({
        publish,
        expire,
        validate: true,
    })}`;
const jobDetails = (centralJobId: number) => `jobs/${centralJobId}`;

const afterPublishRedirect = (options: {
    centralOrganisationId: number;
    centralJobId?: number;
    centralJobHasPublishDate?: boolean;
    publish?: string;
    expire?: string;
}) => {
    const { centralOrganisationId, centralJobId, centralJobHasPublishDate, publish, expire } =
        options;

    if (centralJobId) {
        if (centralJobHasPublishDate) {
            return jobDetails(centralJobId);
        }

        const publishIso = publish ? format(parseISO(publish), 'yyyy-MM-dd') : undefined;
        const expireIso = expire ? format(parseISO(expire), 'yyyy-MM-dd') : undefined;

        return jobPublish(centralJobId, publishIso, expireIso);
    }

    return orgDetails(centralOrganisationId);
};

export const centralLinkHelper = {
    orgDetails,
    orgContacts,
    orgEdit,
    jobList,
    jobDetails,
    jobPublish,
    afterPublishRedirect,
};
