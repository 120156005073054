import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { CountryCode } from 'src/clients-internal/configuration.model';
import { COUNTRY_REGION_META } from 'src/constants/region.constants';
import { useFormError } from 'src/ui/shared/hooks/form-error.hook';
import { getRegions } from '../../../helpers/region.helper';
import { HookControlProps } from '../HookForm';
import { ControlFormGroup } from '../shared/ControlFormGroup';

interface Props extends HookControlProps {
    countryCode?: string;
    allowEmpty?: boolean;
}

export const RegionControl = (props: Props): React.ReactElement<any, any> | null => {
    const {
        countryCode,
        name,
        errorName,
        rules,
        placeholder,
        disabled,
        allowEmpty,
        autoFocus,
        errorIndex,
    } = props;

    const { register } = useFormContext();

    const regionMeta = countryCode
        ? COUNTRY_REGION_META[countryCode.toUpperCase() as CountryCode]
        : undefined;

    const label = (regionMeta && regionMeta.regionLabel) || props.label;

    const regions = useMemo(() => {
        return countryCode ? getRegions(countryCode) : [];
    }, [countryCode]);

    const controlError = useFormError(errorName || name, errorIndex);

    return (
        <ControlFormGroup {...props} label={label}>
            {useMemo(
                () =>
                    regions.length > 0 ? (
                        <select
                            disabled={disabled}
                            className={`form-control ${controlError ? 'is-invalid' : ''}`}
                            autoFocus={autoFocus}
                            {...register(name, rules)}
                        >
                            {placeholder && (
                                <option value="" disabled hidden>
                                    {placeholder}
                                </option>
                            )}
                            {allowEmpty ? <option value=""></option> : null}
                            {regions.map(r => (
                                <option key={r.key} value={r.key}>
                                    {r.name}
                                </option>
                            ))}
                        </select>
                    ) : (
                        <input
                            disabled={disabled}
                            className={`form-control ${controlError ? 'is-invalid' : ''}`}
                            {...register(name, rules)}
                            placeholder={placeholder}
                        />
                    ),
                [
                    name,
                    autoFocus,
                    allowEmpty,
                    disabled,
                    placeholder,
                    regions,
                    register,
                    controlError,
                    rules,
                ]
            )}
        </ControlFormGroup>
    );
};
