import { Nav } from 'react-bootstrap';
import { CareerHubEvent } from 'src/models/events/event.model';
import { eventPathCreator, eventPaths } from 'src/routes/employer/paths/event.paths';
import { TabLink } from 'src/ui/shared/components/tabs/TabLink';
import { useIsCareerHubVersion } from 'src/ui/shared/hooks/is-careerhub-version.hook';
import { useEventSessions } from '../../hooks/event-sessions.hook';
import { useEventStats } from '../../hooks/event-stats.hook';
import { useEventsEnabled } from '../../hooks/events-enabled.hook';

interface Props {
    event: CareerHubEvent;
}

export const EventDetailsTabNav = ({ event }: Props): React.ReactElement<any, any> | null => {
    const stats = useEventStats(event.id);
    const sessions = useEventSessions(event.id);
    const { isBookingListEnabled } = useEventsEnabled();

    const showChangeRequests = useIsCareerHubVersion(5, 10);

    return (
        <Nav variant="tabs" defaultActiveKey="details">
            <TabLink to={eventPathCreator.details(event.id)} matchPath={eventPaths.details} exact>
                Details
            </TabLink>
            {sessions.length > 0 && (
                <TabLink
                    to={eventPathCreator.sessionList(event.id)}
                    matchPath={eventPaths.sessionList}
                >
                    Sessions
                </TabLink>
            )}
            {showChangeRequests && (
                <TabLink
                    to={eventPathCreator.changeRequestList(event.id)}
                    matchPath={eventPaths.changeRequestList}
                >
                    Change requests
                </TabLink>
            )}
            {isBookingListEnabled && (
                <TabLink
                    to={eventPathCreator.bookings(event.id)}
                    matchPath={eventPaths.bookingList}
                    exact
                >
                    Bookings {stats && stats.bookings > 0 && <>({stats.bookings})</>}
                </TabLink>
            )}
        </Nav>
    );
};
