import RedactorX from '@visualeyes/redactor-x';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFormError } from 'src/ui/shared/hooks/form-error.hook';
import { HookControlProps } from '../HookForm';
import { ControlFormGroup } from '../shared/ControlFormGroup';
import DOMPurify from 'dompurify';

const allowedBlockTags = ['p', 'h1', 'h2', 'h3', 'ul', 'ol', 'li'] as const;
const allowedInlineTags = ['i', 'del', 'a', 'strong', 'b'] as const;

const traverseChildNodes = (nodeList: NodeListOf<ChildNode>) => {
    nodeList.forEach(node => {
        if (node.nodeType === Node.TEXT_NODE) {
            const lines = node.textContent?.replace(/(\r\n|\n|\r)/gm, '\n').split('\n') || [];
            if (lines.length < 2) {
                return;
            }
            const pTags = lines.map(line => {
                const pTag = document.createElement('p');
                pTag.textContent = line;
                return pTag;
            });
            node.replaceWith(...pTags);
        }

        if (node.hasChildNodes()) {
            traverseChildNodes(node.childNodes);
        }
    });
};

// https://imperavi.com/redactor/docs/events/editor/#s-editor.before.paste
// OK, I know this looks gross, and it is gross.
// The double purification is probably really inefficient, but
// I couldn't figure out how to get the RedactorX editor to
// accept a dom fragment as a paste event, or how to convert a
// dom fragment into a string.
//
// The problem being solved here, is that new lines "\n" etc, are being
// accpeted, and then converted into <br> tags.
// The <br> tags not allowed, so a validation error is thrown.
// What is strange is that I do not allow <br> tags at all.
// What I mean by this is that IF the <br> tags were in the original
// pasted string, they would be removed, but since it's a convertion of
// a new line INTO a <br>, it happens after the "paste" has validated the
// pasted content.
// i.e.
// 1: pasted content is pasted in
// 2. <br> tags are removed (along with any other disallowed tags)
// 3. new lines are converted to <br> tags
// 4. validation error is thrown because <br> tags exist.
const editorBeforePaste = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const html = e.get('html');

    const purifiedHtml = DOMPurify.sanitize(html, {
        ALLOWED_TAGS: [...allowedBlockTags, ...allowedInlineTags, '#text'],
        ADD_ATTR: ['target'],
        RETURN_DOM_FRAGMENT: true,
    });

    traverseChildNodes(purifiedHtml.childNodes);

    const purified = DOMPurify.sanitize(purifiedHtml, {
        ALLOWED_TAGS: [...allowedBlockTags, ...allowedInlineTags, '#text'],
        ADD_ATTR: ['target'],
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    e.set('html', purified);
};

export const HtmlControl = (props: HookControlProps) => {
    const { name, placeholder, rules } = props;

    const [loaded, setLoaded] = useState(false);
    const ref = useRef<any>(null);

    const { register, getValues, setValue } = useFormContext();

    const controlError = useFormError(name);

    const editorChange = useCallback(
        e => {
            setValue(name, e?.params?.html, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
            });
        },
        [name, setValue]
    );

    useEffect(() => {
        if (!RedactorX || loaded) {
            return;
        }
        ref.current = RedactorX(`[name=html-${name}]`, {
            // events
            subscribe: {
                'editor.change': editorChange,
                'editor.before.paste': editorBeforePaste,
            },

            // extremely specific paste tags
            paste: {
                images: false,
                formTags: [],
                blockTags: allowedBlockTags,
                inlineTags: allowedInlineTags,
            },
            // disabling almost all settings
            source: false,
            deleted: false,
            addbar: false,
            topbar: false,
            toolbar: {
                sticky: false,
            },
            buttons: {
                // my god this was hard to figure out, just to stop the
                // stupid buttons from disappearing !!
                editor: [],
            },
            table: false,
            embed: false,
            image: false,
            pre: false,
            quote: false,
            line: false,
            // placeholder
            placeholder,
        });

        // set initial value if it exists
        const initialValue = getValues(name);
        if (initialValue) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            ref.current.editor.setContent({ html: initialValue });
        }

        setLoaded(true);
    }, [editorChange, loaded, name, placeholder, getValues]);

    useEffect(() => {
        return () => {
            if (ref.current) {
                // I'm not sure what this does, but in the examples
                // they mention that this should be done if you are
                // using a routing engine, which I definitely am
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                (ref.current as unknown as any).destroy?.();
                setLoaded(false);
            }
        };
    }, []);

    useEffect(() => {
        register(name, rules);
    }, [name, register, rules]);

    return (
        <ControlFormGroup {...props}>
            <div className={controlError ? 'border border-danger' : ''}>
                <textarea
                    disabled={props.disabled}
                    tabIndex={props.tabIndex}
                    autoFocus={props.autoFocus}
                    name={`html-${name}`}
                ></textarea>
            </div>
        </ControlFormGroup>
    );
};
