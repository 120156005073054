import { JobContractHours, JobContractHoursMeta } from '../models/jobs/job-types.model';
import {
    OrganisationType,
    OrganisationTypeMeta,
} from '../models/organisation/organisation-types.model';
import { SelectOption } from '../ui/shared/components/forms/controls/selects/SelectControl';

export const JOB_CONTRACT_HOURS_SELECT_OPTIONS: SelectOption<JobContractHours>[] = [
    { value: 'FullTime', label: JobContractHoursMeta['FullTime'] },
    { value: 'PartTime', label: JobContractHoursMeta['PartTime'] },
    { value: 'Casual', label: JobContractHoursMeta['Casual'] },
];

export const ORGANISATION_TYPE_SELECT_OPTIONS: SelectOption<OrganisationType>[] = [
    { value: 'AgentForEmployer', label: OrganisationTypeMeta['AgentForEmployer'] },
    {
        value: 'DirectEmployer',
        label: OrganisationTypeMeta['DirectEmployer'],
    },
    { value: 'NonProfit', label: OrganisationTypeMeta['NonProfit'] },
];
