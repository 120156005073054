import { useEffect, useMemo } from 'react';
import { useFormContext, ValidationRule } from 'react-hook-form';
import { EventRequestBookingType } from 'src/models/events/event-request.model';
import { EventRequestFormData } from 'src/ui/features/events/components/forms/EventRequestForm';
import { useEventOnCampusValues } from 'src/ui/features/events/hooks/event-on-campus-values.hook';
import { createMaxLengthRule, urlPatternRule } from 'src/ui/shared/helpers/validation.helper';
import { useApiIncrement } from 'src/ui/shared/hooks/apiIncrement.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { HookControlProps, HookForm } from '../../HookForm';
import { EventLocationForm } from '../event-location/EventLocationControl';
import { ReactSelectOption, SingleSelectControl } from '../react-selects/SingleSelectControl';

const bookingSelectName: keyof EventRequestFormData = 'bookingType';
const bookingUrlName: keyof EventRequestFormData = 'bookingUrl';
const bookingSiteName: keyof EventRequestFormData = 'bookingName';
const bookingInstructionsName: keyof EventRequestFormData = 'bookingInstructions';
const bookingLimitName: keyof EventRequestFormData = 'bookingLimit';

type Props = Pick<HookControlProps, 'rules'> & {
    locationControlName: string;
};

export const EventBookingControl = ({
    rules,
    locationControlName,
}: Props): React.ReactElement<any, any> | null => {
    const {
        watch,
        trigger,
        formState: { dirtyFields },
    } = useFormContext();
    const bookingType: EventRequestFormData['bookingType'] = watch(bookingSelectName);
    const location: EventLocationForm | undefined = watch(locationControlName);
    const { locations } = useEventOnCampusValues();
    const apiIncrement = useApiIncrement();

    const {
        event: {
            form: { bookingExternalOption, bookingInternalOption, bookingNotRequiredOption },
        },
    } = useStrings();
    const options = useMemo(() => {
        const internalOption: ReactSelectOption<EventRequestBookingType> = {
            label: bookingInternalOption,
            value: 'Internal',
            tokens: bookingInternalOption.split(' '),
        };
        const externalOption: ReactSelectOption<EventRequestBookingType> = {
            label: bookingExternalOption,
            value: 'External',
            tokens: bookingExternalOption.split(' '),
        };

        const notRequiredOption: ReactSelectOption<EventRequestBookingType> = {
            label: bookingNotRequiredOption,
            value: 'NotRequired',
            tokens: bookingNotRequiredOption.split(' '),
        };

        // apiIncreament 3 was added 2023-12-11 into 5.11
        return apiIncrement >= 3
            ? [internalOption, externalOption, notRequiredOption]
            : [internalOption, externalOption];
    }, [apiIncrement, bookingExternalOption, bookingInternalOption, bookingNotRequiredOption]);

    const capacity = useMemo(() => {
        if (location?.select !== 'onCampus' || !location?.onCampusLocationId) {
            return undefined;
        }

        return locations.find(x => x.id === location.onCampusLocationId)?.capacity;
    }, [location, locations]);

    const bookingLimitMaxRule: ValidationRule<number> | undefined = useMemo(() => {
        if (!capacity) {
            return undefined;
        }

        const rule: ValidationRule<number> = {
            value: capacity,
            message: `Value must be less than ${capacity}`,
        };

        return rule;
    }, [capacity]);

    const dirtyField = dirtyFields[bookingLimitName];
    useEffect(() => {
        if (dirtyField) {
            void trigger(bookingLimitName);
        }
    }, [dirtyField, trigger, location?.onCampusLocationId]);

    return (
        <>
            <HookForm.Row>
                <HookForm.Col md={6} xs={12}>
                    <SingleSelectControl
                        label="Event booking system"
                        name={bookingSelectName}
                        options={options}
                        rules={rules}
                    />
                </HookForm.Col>
                {bookingType === 'Internal' && (
                    <HookForm.Col md={6} xs={12}>
                        <HookForm.Input
                            type="number"
                            label={capacity ? `Booking limit (max: ${capacity})` : 'Booking limit'}
                            name={bookingLimitName}
                            rules={{ required: true, max: bookingLimitMaxRule, min: 0 }}
                        />
                    </HookForm.Col>
                )}
            </HookForm.Row>
            {bookingType === 'External' && (
                <>
                    <HookForm.Row>
                        <HookForm.Col xs={12} md={6}>
                            <HookForm.Input
                                label="Site booking name"
                                name={bookingSiteName}
                                rules={{
                                    maxLength: createMaxLengthRule(50),
                                }}
                            />
                        </HookForm.Col>
                        <HookForm.Col xs={12} md={6}>
                            <HookForm.Input
                                label="Site link/ url"
                                name={bookingUrlName}
                                rules={{
                                    required: true,
                                    pattern: urlPatternRule,
                                    maxLength: createMaxLengthRule(300),
                                }}
                            />
                        </HookForm.Col>
                    </HookForm.Row>
                    <HookForm.Textarea
                        label="Booking instructions"
                        name={bookingInstructionsName}
                        minRows={3}
                        rules={
                            apiIncrement >= 2
                                ? { maxLength: createMaxLengthRule(4000) }
                                : { required: true, maxLength: createMaxLengthRule(4000) }
                        }
                    />
                </>
            )}
        </>
    );
};
