import { lazy, Suspense, useMemo } from 'react';
import { client } from 'src';
import { NODE_ENV_IS_DEVELOPMENT, REACT_APP_CLIENT_STYLES } from '../environment.constants';
import { DynamicErrorBoundary } from './DynamicErrorBoundary';

type DynamicComponentKey = 'Header' | 'Footer' | 'Logo' | 'Hero';

interface Props {
    componentKey: DynamicComponentKey;
}

// Note: the string has to be executed inside the import or you get a "critical warning"
const useDynamicComponent = (componentKey: DynamicComponentKey) => {
    const toReturn = useMemo(() => {
        return lazy(() => {
            // this breaks single responsibility and is pretty hacky. I only allow it in
            // development mode, but the logic really shouldn't be here, but it's easier...
            // There's so much configuration that running "as a client" locally has become annoying
            // Most of the time, you just want to view the styles, so I created a npm run client-styles helper
            // however, the dynamic components are esentially part of the "client styles", so I dip into that logic
            // here. Again, it's not where it should be, makes this component a little messier, introduces environment-based
            // logic, etc. But it's easy, and only occurs in dev mode.
            const clientKey =
                NODE_ENV_IS_DEVELOPMENT && REACT_APP_CLIENT_STYLES
                    ? REACT_APP_CLIENT_STYLES
                    : client;

            return import(`../../clients/${clientKey}/${componentKey}`).catch(() =>
                Promise.resolve({ default: () => null })
            );
        });
    }, [componentKey]);

    return toReturn;
};

export const Dynamic = ({ componentKey }: Props): React.ReactElement<any, any> | null => {
    const DynamicInner = useDynamicComponent(componentKey);

    return (
        <DynamicErrorBoundary componentKey={componentKey}>
            <Suspense fallback={null}>
                <DynamicInner />
            </Suspense>
        </DynamicErrorBoundary>
    );
};
