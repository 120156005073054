import { useMemo } from 'react';
import { WorkGroup } from 'src/models/work-groups/work-group.model';
import { useEventsEnabled } from 'src/ui/features/events/hooks/events-enabled.hook';
import { useWorkGroups } from 'src/ui/features/work-groups/hooks/work-groups.hook';
import { Loader } from '../../../loader/Loader';
import { DescriptionSelectLabel } from './DescriptionSelectLabel';
import { SingleSelectControl, SingleSelectControlProps } from './SingleSelectControl';

type WorkGroupSelectControlProps = Omit<SingleSelectControlProps, 'options'> & {
    entityType: 'job' | 'event';
};

const useWorkGroupSelectOptions = (workgroups: WorkGroup[]) =>
    useMemo(
        () =>
            workgroups.map(w => ({
                label: (
                    <DescriptionSelectLabel
                        key={w.id}
                        title={w.name}
                        description={w.descriptionForEmployers}
                    />
                ),
                value: w.id,
                tokens: w.name.toLowerCase().split(' '),
            })),
        [workgroups]
    );

export const WorkGroupSelectControl = (
    props: WorkGroupSelectControlProps
): React.ReactElement<any, any> | null => {
    const { fetch, publishableWorkGroups } = useWorkGroups();
    const { eventTypeWorkGroups } = useEventsEnabled();

    // this needs to be cleaned up, it should ignore the entityType
    // and always use the "publishable" list
    // BUT - should selecting a event type that has a workgroup
    // set the workgroup automatically? idk
    const workGroups = useMemo(() => {
        switch (props.entityType) {
            case 'job':
                return publishableWorkGroups;
            case 'event':
                return eventTypeWorkGroups;
        }
    }, [props.entityType, publishableWorkGroups, eventTypeWorkGroups]);

    const options = useWorkGroupSelectOptions(workGroups);

    if (workGroups.length === 0) {
        return null;
    }

    return (
        <Loader useCard={true} state={fetch}>
            <SingleSelectControl {...props} options={options} />
        </Loader>
    );
};
