import { ApplicationPageContent } from 'src/ui/shared/components/page-components/ApplicationPageContent';
import { CentralMarketingAlert } from '../components/CentralMarketingAlert';
import { CentralPublish } from '../components/publish/CentralPublish';
import { CentralReauthenticationGuard } from '../components/publish/CentralReauthenticationGuard';

export const CentralPublishPage = (): React.ReactElement<any, any> | null => {
    return (
        <ApplicationPageContent>
            <div className="mb-2">
                <CentralMarketingAlert />
            </div>
            <CentralReauthenticationGuard>
                <CentralPublish />
            </CentralReauthenticationGuard>
        </ApplicationPageContent>
    );
};
