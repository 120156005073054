import omit from 'lodash/omit';
import { Col, Form } from 'react-bootstrap';
import { useClaimValues } from 'src/ui/features/authentication/hooks/claim-values.hook';
import { AddressFieldsetGeo } from 'src/ui/features/locations/components/AddressFieldset';
import { StringsAlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/StringsAlertWrapper';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { ErrorNormalized } from '../../../../../models/errors/error.model';
import { Individual } from '../../../../../models/individual/individual.model';
import { HookForm } from '../../../../shared/components/forms/HookForm';
import { createMaxLengthRule } from '../../../../shared/helpers/validation.helper';
import { useFormDefault } from '../../../../shared/hooks/form-default.hook';

interface Props {
    individual?: Individual;
    onSubmit: (data: Individual) => void;
    onCancel?: () => void;
    showSpinner?: boolean;
    error?: ErrorNormalized;
}

export const IndividualDetailsForm = ({
    individual,
    onSubmit,
    onCancel,
    showSpinner,
    error,
}: Props): React.ReactElement<any, any> | null => {
    const isEdit = !!individual;
    const claimValues = useClaimValues();
    const {
        individual: { form },
    } = useStrings();

    const defaultValues: Partial<Individual> | undefined =
        individual ||
        (claimValues
            ? {
                  ...omit(claimValues, 'claims'),
                  email: claimValues.email[0],
              }
            : undefined);

    const formMethods = useFormDefault<Individual>({
        defaultValues: defaultValues,
        error,
    });

    return (
        <HookForm {...formMethods} onSubmit={onSubmit}>
            <HookForm.ErrorAlert error={error} />
            <StringsAlertWrapper alerts={form.alerts} />
            <Form.Row>
                <Col>
                    {!isEdit && claimValues && claimValues.email.length > 1 ? (
                        <HookForm.Select {...form.email} name="email" options={claimValues.email} />
                    ) : (
                        <HookForm.Input
                            {...form.email}
                            readonly={true}
                            name="email"
                            disabled={isEdit}
                        />
                    )}
                </Col>
                <Col>
                    <HookForm.Input
                        name="firstName"
                        {...form.firstName}
                        rules={{ required: true, maxLength: createMaxLengthRule(50) }}
                    />
                </Col>
                <Col>
                    <HookForm.Input
                        name="lastName"
                        {...form.lastName}
                        rules={{ required: true, maxLength: createMaxLengthRule(50) }}
                    />
                </Col>
            </Form.Row>

            <Form.Row>
                <Col xs={4}>
                    <HookForm.Input
                        name="phone"
                        {...form.phone}
                        rules={{ required: true, maxLength: createMaxLengthRule(30) }}
                    />
                </Col>
            </Form.Row>
            <hr />

            <AddressFieldsetGeo defaultValues={individual} />

            {!individual && (
                <HookForm.Checkbox {...form.terms} name="terms" rules={{ required: true }} />
            )}

            <HookForm.Buttons onCancel={onCancel} showSpinner={showSpinner} />
        </HookForm>
    );
};
