import { BBox2D } from '@symplicity/geocoding';
import { GeolocationModel } from 'src/models/locations/geolocation.model';

export const bboxToGeolocation = (bbox: BBox2D): GeolocationModel => ({
    left: bbox[0],
    bottom: bbox[1],
    right: bbox[2],
    top: bbox[3],
});

export const geolocationToBBox = (geo: GeolocationModel): BBox2D => [
    geo.left,
    geo.bottom,
    geo.right,
    geo.top,
];
