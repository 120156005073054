import { JobDetailsBreadcrumb } from '../breadcrumbs/JobDetailsBreadcrumb';
import { JobListBreadcrumb } from '../breadcrumbs/JobListBreadcrumb';
import { PageTitle } from '../../../../shared/components/page-components/PageTitle';
import { Job } from '../../../../../models/jobs/job.model';

interface Props {
    job?: Job;
}

export const JobEditTitle = ({ job }: Props): React.ReactElement<any, any> | null => {
    return (
        <PageTitle>
            <JobListBreadcrumb />
            {job && <JobDetailsBreadcrumb job={job} />}
            Edit
        </PageTitle>
    );
};
