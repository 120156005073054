import { CSSProperties } from 'react';

export const Cloud = (props: { style?: CSSProperties; className?: string }) => (
    <svg
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={props.style}
        className={props.className}
    >
        <path
            // eslint-disable-next-line max-len
            d="M30.3828 5.19531C28.9766 2.4707 26.1641 0.625 23 0.625C19.748 0.625 16.9355 2.4707 15.5293 5.19531C12.6289 4.22852 9.37695 4.93164 7.0918 7.2168C4.80664 9.50195 4.10352 12.8418 4.98242 15.7422C2.25781 17.1484 0.5 19.9609 0.5 23.125C0.5 26.377 2.25781 29.1895 4.98242 30.5957C4.10352 33.4961 4.80664 36.748 7.0918 39.0332C9.37695 41.3184 12.6289 42.0215 15.5293 41.1426C16.9355 43.8672 19.748 45.625 23 45.625C26.1641 45.625 28.9766 43.8672 30.3828 41.1426C33.2832 42.0215 36.623 41.3184 38.9082 39.0332C41.1934 36.748 41.8965 33.4961 40.9297 30.5957C43.6543 29.1895 45.5 26.377 45.5 23.125C45.5 19.9609 43.6543 17.1484 40.9297 15.7422C41.8965 12.8418 41.1934 9.50195 38.9082 7.2168C36.623 4.93164 33.2832 4.22852 30.3828 5.19531Z"
            fill="currentColor"
        />
    </svg>
);
