// attachments
// attachments are weird. Mostly attachments are handled in the Job Create and Job Update
// actions/ epics. If you are looking for attachment logic, you probably want to go to the job-create-update epic.

import { EntityRequest } from '../../../models/api/request.model';
import { ErrorNormalized } from '../../../models/errors/error.model';
import { createAsyncAction } from 'typesafe-actions';
import { ApiResponse } from '../../../models/api/response.model';

export const attachmentDeleteAsync = createAsyncAction(
    'ATTACHMENT_DELETE:REQUEST',
    'ATTACHMENT_DELETE:SUCCESS',
    'ATTACHMENT_DELETE:FAILURE'
)<EntityRequest, ApiResponse<EntityRequest>, ErrorNormalized>();
