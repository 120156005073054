import { Badge } from 'react-bootstrap';
import { Contact } from '../../../../../models/contacts/contact.model';
import { useIdentityContact } from '../../../authentication/hooks/identity-contact.hook';
import { useIsPrimaryContact } from '../../hooks/is-primary-contact.hook';

interface Props {
    contact: Contact;
    badgeClassName?: string;
}

export const ContactStatusBadge = ({
    contact,
    badgeClassName,
}: Props): React.ReactElement<any, any> | null => {
    const isPrimaryContact = useIsPrimaryContact(contact.id);
    const { isIdentityContact } = useIdentityContact();
    const isIdentity = isIdentityContact(contact);

    const className = badgeClassName || '';

    return (
        <>
            {isPrimaryContact && (
                <Badge variant="outline-info" className={`font-weight-normal ${className}`}>
                    Primary
                </Badge>
            )}
            {isIdentity && (
                <Badge variant="outline-info" className={`font-weight-normal ${className}`}>
                    Me
                </Badge>
            )}
            {!contact.active && (
                <Badge variant="outline-secondary" className={`font-weight-normal ${className}`}>
                    Inactive
                </Badge>
            )}
            {contact.active && !contact.emailConfirmed && (
                <Badge variant="outline-warning" className={`font-weight-normal ${className}`}>
                    Email Unconfirmed
                </Badge>
            )}
        </>
    );
};
