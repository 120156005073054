import { push } from 'connected-react-router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { registerDetailsStore } from '../../../../logic/features/register/register.actions';
import { Organisation } from '../../../../models/organisation/organisation.model';
import { registerPaths } from '../../../../routes/register/register.paths';
import { ThinPageContent } from '../../../shared/components/page-components/ThinPageContent';
import { OrganisationDetailsForm } from '../../organisation/components/forms/OrganisationDetailsForm';
import { RegisterStartBreadcrumb } from '../components/breadcrumbs/RegisterStartBreadcrumb';
import { RegisterWizardWrapper } from '../components/nav/RegisterWizardWrapper';
import { useRegisterWizard } from '../hooks/register-wizard.hook';

export const RegisterOrganisationPage = (): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();

    const partialOrg = useRootSelector(state => state.register.name);
    const storedOrg = useRootSelector(state => state.register.details);
    const { hasSimilarItems } = useRegisterWizard();
    const {
        register: {
            details: { title },
            wizard: { buttons },
        },
    } = useStrings();

    const onSubmit = useCallback(
        (organisation: Organisation, logo: File | undefined) => {
            dispatch(registerDetailsStore({ organisation, logo }));
        },
        [dispatch]
    );

    const onCancel = () => {
        dispatch(push(hasSimilarItems ? registerPaths.similar : registerPaths.name));
    };

    return (
        <ThinPageContent>
            <h1>
                <RegisterStartBreadcrumb />
                {title}
            </h1>
            <RegisterWizardWrapper>
                <OrganisationDetailsForm
                    organisation={storedOrg || partialOrg || undefined}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    cancelText={buttons.back}
                    submitText={buttons.continue}
                />
            </RegisterWizardWrapper>
        </ThinPageContent>
    );
};
