import { entityListInitialState } from 'src/logic/helpers/initial-state.helper';
import { listSingleReducerHandler } from 'src/logic/reducer-handlers/list-single.reducer-handler';
import { listReducerHandler } from 'src/logic/reducer-handlers/list.reducer-handler';
import { EntityNote } from 'src/models/entity-notes/entity-note.model';
import { EntityListState } from 'src/models/store-models/entity-list-state.model';
import { createReducer } from 'typesafe-actions';
import { eventUnionAsync, eventUnionListAsync } from '../event-unions/event-union.actions';
import * as actions from './entity-note.actions';

export type EntityNoteState = EntityListState<EntityNote, undefined>;

const initialState: EntityNoteState = {
    ...entityListInitialState,
};

const entityNoteReducer = createReducer(initialState)
    // special intercepts for includes (all entity notes come from includes, or create/update/delete actions)
    .handleAction(eventUnionAsync.success, (state, action) =>
        listReducerHandler.successItemsOnly(state, action.payload.include.notes || [])
    )
    .handleAction(eventUnionListAsync.success, (state, action) =>
        listReducerHandler.successItemsOnly(state, action.payload.include.notes || [])
    )

    .handleAction(actions.entityNoteCreateAsync.request, listSingleReducerHandler.create)
    .handleAction(actions.entityNoteCreateAsync.success, listSingleReducerHandler.success)
    .handleAction(actions.entityNoteCreateAsync.failure, listSingleReducerHandler.createFailure)

    .handleAction(actions.entityNoteUpdateAsync.request, listSingleReducerHandler.updateRequest)
    .handleAction(actions.entityNoteUpdateAsync.success, listSingleReducerHandler.success)
    .handleAction(actions.entityNoteUpdateAsync.failure, listSingleReducerHandler.updateFailure)

    .handleAction(actions.entityNoteDeleteAsync.request, listSingleReducerHandler.deleteRequest)
    .handleAction(actions.entityNoteDeleteAsync.success, listSingleReducerHandler.deleteSuccess)
    .handleAction(actions.entityNoteDeleteAsync.failure, listSingleReducerHandler.deleteFailure);

export default entityNoteReducer;
