import { useCallback } from 'react';
import { Pagination } from 'react-bootstrap';
import { ListPaginationState } from '../../../../../models/store-models/entity-list-state.model';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import qs from 'qs';

const repeat = function (n: number): any[] {
    return new Array(n).fill(undefined);
};

interface Props {
    pagination: ListPaginationState;
}

export const ListPagination = ({ pagination }: Props): React.ReactElement<any, any> | null => {
    const { search } = useLocation();
    const dispatch = useDispatch();

    const onChange = useCallback(
        (page: number) => {
            if (pagination.page === page) {
                return;
            }
            const params = qs.parse(search, { ignoreQueryPrefix: true });

            dispatch(
                push({
                    search: qs.stringify(
                        { ...params, page: page === 1 ? undefined : page },
                        { addQueryPrefix: true, encode: false }
                    ),
                })
            );
        },
        [search, dispatch, pagination]
    );

    const onPrev = () => {
        onChange(pagination.page - 1);
    };

    const onNext = () => {
        onChange(pagination.page + 1);
    };

    return (
        <Pagination size="sm" className="mb-0">
            <Pagination.Prev disabled={pagination.page === 1} onClick={onPrev} />
            {repeat(pagination.pageTotal).map((_, i) => {
                const page = i + 1;
                return (
                    <Pagination.Item
                        key={i}
                        active={pagination.page === page}
                        onClick={() => onChange(page)}
                    >
                        {page}
                    </Pagination.Item>
                );
            })}
            <Pagination.Next disabled={pagination.page === pagination.pageTotal} onClick={onNext} />
        </Pagination>
    );
};
