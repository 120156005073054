import { ClientStrings } from 'src/clients-internal/strings.model';
import { stringHelper } from 'src/ui/shared/helpers/string.helper';
import { createReducer } from 'typesafe-actions';
import defaultStrings from '../../../clients-internal/_defaults/_defaults.strings.json';
import { EmployerStringDictionary } from '../../../models/api-configuration/api-configuration.model';
import { apiConfigurationAsync } from '../api-configuration/api-configuration.actions';
import { stringsInit } from './string.actions';

const exp = new RegExp(/{{(.+?)}}/gi);

const isTitleCase = (str: string) => {
    if (str.length === 0) {
        return false;
    }
    const char = str.charAt(0);

    return char.toUpperCase() === char;
};

const replaceAll = (str: string, replacements: Record<string, string>) => {
    return str.replace(exp, (match, name) => {
        const replacement = typeof name === 'string' ? replacements[name.toLowerCase()] : undefined;
        if (!replacement) {
            return match;
        }

        return isTitleCase(match.substring(2))
            ? stringHelper.toTitleCase(replacement)
            : replacement;
    });
};

const replaceStrings = <T extends Record<string, any>>(
    baseStringTemplate: T,
    overrides: EmployerStringDictionary,
    builder: Record<string, string | string[] | Record<string, any>> = {}
) => {
    Object.keys(baseStringTemplate).forEach(key => {
        const value = baseStringTemplate[key];
        if (typeof value === 'string') {
            builder[key] = replaceAll(value, overrides);
        } else if (Array.isArray(value)) {
            // NOTE: this uses the \n delimiter because it assumes that anything
            // in strings that is stored as an array is meant for MARKDOWN
            // but this is a straight up assumption.
            builder[key] = replaceAll(value.join('\n'), overrides);
        } else {
            builder[key] = replaceStrings(value, overrides);
        }
    });

    return builder as T;
};

export type StringState = {
    careerHubStrings: EmployerStringDictionary;
    value: ClientStrings;
};

const initialState: StringState = {
    careerHubStrings: {} as any, // this is handled by the strings init action
    value: defaultStrings,
};

const stringReducer = createReducer(initialState)
    .handleAction(stringsInit, (state, action) => {
        return {
            ...state,
            value: replaceStrings(action.payload.clientStrings, state.careerHubStrings),
        };
    })
    .handleAction(apiConfigurationAsync.success, (state, action) => {
        const careerHubStrings = action.payload.data.strings;
        const newValue = replaceStrings(state.value, careerHubStrings);

        return {
            careerHubStrings,
            value: newValue,
        };
    });

export default stringReducer;
