import { Form } from 'react-bootstrap';

interface ReadonlyControlProps {
    value: string | undefined;
    label?: string;
}

export const DisabledInputControl = ({
    value,
    label,
}: ReadonlyControlProps): React.ReactElement<any, any> | null => {
    return (
        <Form.Group>
            {label && <Form.Label className="mb-1">{label}</Form.Label>}
            <input className="form-control" value={value} readOnly={true} />
        </Form.Group>
    );
};
