export const CareerHubIcon = (): React.ReactElement<any, any> | null => {
    return (
        <svg
            id="logoCareerHub"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 33 33"
            className="svg-inline--fa fa-w-20 fa-lg"
        >
            <path
                id="Path_4592"
                fill="rgba(0,0,0,0)"
                fillRule="evenodd"
                data-name="Path 4592"
                d="M0,23.805A23.805,23.805,0,1,1,23.8,47.61,23.805,23.805,0,0,1,0,23.805Z"
            />
            <path
                id="Path_4593"
                fill="currentColor"
                fillRule="evenodd"
                data-name="Path 4593"
                d="M25.315,21.032l-1.107-.079a8.76,8.76,0,0,1-4.982-3.243c-2.056-3.005-2.294-6.248-3.559-7.751a3.583,3.583,0,0,0-4.9-1.107,2.971,2.971,0,0,0-.87,4.192c.712,1.107,1.028.949,3.717,2.61a30.117,30.117,0,0,1,4.033,2.61c1.344,2.214,4.033,7.909-1.424,12.575-.712.316-1.661.633-3.005,1.265C9.656,33.686,9.34,33.37,8.233,34.635a3.783,3.783,0,0,0,.237,5.378c1.9,1.661,4.35,1.186,6.169-.475,1.5-1.424,2.294-4.35,4.271-7.2a11.707,11.707,0,0,1,5.932-1.819h0a10.874,10.874,0,0,1,5.773,2.135c1.74,2.926,2.294,5.852,3.638,7.355a4.267,4.267,0,0,0,5.932.791,3.8,3.8,0,0,0,.633-5.22c-.949-1.265-1.265-1.028-4.666-2.768-1.265-.633-2.135-1.028-2.768-1.344-4.982-4.982-1.9-10.439-.4-12.575,1.186-.949,1.819-1.107,4.192-2.294,2.768-1.5,3.005-1.265,3.8-2.294a2.981,2.981,0,0,0-.554-4.192,3.509,3.509,0,0,0-4.824.712c-1.344,1.424-1.819,4.666-4.113,7.434a8.647,8.647,0,0,1-5.22,2.847l-.949-.079Z"
                transform="translate(-1.51 -1.735)"
            />
            <path
                id="Path_4594"
                fill="currentColor"
                fillRule="evenodd"
                data-name="Path 4594"
                d="M23.8,11.72A5.278,5.278,0,0,1,29.569,6.9a5.375,5.375,0,0,1,4.982,5.694,5.266,5.266,0,0,1-5.694,4.9A5.409,5.409,0,0,1,23.8,11.72Z"
                transform="translate(-4.973 -1.438)"
            />
            <path
                id="Path_4595"
                fill="rgba(0,0,0,0)"
                fillRule="evenodd"
                data-name="Path 4595"
                d="M24.483,29.949a3.98,3.98,0,0,1,3.243-4.666,4.018,4.018,0,1,1-3.243,4.667Z"
                transform="translate(-5.106 -5.274)"
            />
            <path
                id="Path_4596"
                fill="currentColor"
                fillRule="evenodd"
                data-name="Path 4596"
                d="M25.586,30a3.224,3.224,0,0,1,2.531-3.717,3.144,3.144,0,0,1,3.638,2.531,3.224,3.224,0,0,1-2.531,3.717A3.032,3.032,0,0,1,25.586,30Z"
                transform="translate(-5.34 -5.487)"
            />
        </svg>
    );
};
