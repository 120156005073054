import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { HookFormRules } from '../components/forms/HookForm';

export const useMaxLengthCount = (name: string, options?: HookFormRules) => {
    const { control } = useFormContext();

    const value = useWatch({
        name,
        control,
        defaultValue: '',
    });

    const maxLength = useMemo(() => {
        if (!options || !options.maxLength || typeof options.maxLength === 'string') {
            return undefined;
        }

        if (typeof options.maxLength === 'number') {
            return options.maxLength;
        }

        if (typeof options.maxLength.value === 'number') {
            return options.maxLength.value;
        }

        return undefined;
    }, [options]);

    if (!maxLength) {
        return {
            maxLength: undefined,
            length: undefined,
            greater: false,
        };
    }

    const length = value?.length || 0;

    const greater = length > maxLength;

    return {
        maxLength,
        length,
        greater,
    };
};
