import { CareerHubIcon } from '../icon/CareerHubIcon';

interface Props {
    children?: React.ReactNode;
}

export const CareerHubIconContainer = ({
    children,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <div className="d-flex align-items-center">
            <span className="mr-1" style={{ marginTop: '-5px', fontSize: '1rem' }}>
                <CareerHubIcon />
            </span>
            <span className="ml-2">{children}</span>
        </div>
    );
};
