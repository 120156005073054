import { useActiveEntity } from 'src/ui/shared/hooks/active-entity.hook';
import { EventDetailsPageContent } from '../components/details/EventDetailsPageContent';
import { EventSessionList } from '../components/event-sessions/EventSessionList';
import { useEventSessions } from '../hooks/event-sessions.hook';

export const EventSessionListPage = (): React.ReactElement<any, any> | null => {
    const { id } = useActiveEntity(state => state.eventUnions);
    const sessions = useEventSessions(id || -1);

    if (!id) {
        return null;
    }

    return (
        <EventDetailsPageContent>
            <EventSessionList eventId={id} sessions={sessions} />
        </EventDetailsPageContent>
    );
};
