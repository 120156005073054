import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { abnRule } from '../helpers/validation.helper';
import { useRootSelector } from './root-selector.hook';

const singaporeRegex = new RegExp(/^([0-9]{8,9}|[TS][0-9]{2}[A-Z]{2}[0-9]{4})[A-Z]$/);

export const useBusinessIdLabel = (countryCode?: string) => {
    // special AU business label ?
    // surely this can be better?
    // NOTE: can't rely on client here, the name is based on the selected country field.
    if (countryCode && countryCode.toUpperCase() === 'AU') {
        return 'ABN (Australian Business Number)';
    }

    return 'Company number';
};

export const useBusinessIdValidator = (
    formMethods: UseFormReturn<any>,
    options?: { disableBusinessIdValidator?: boolean }
) => {
    const { watch, trigger, formState } = formMethods;

    const [businessIdTouched, setBuisnessIdTouched] = useState(false);
    const clientCode = useRootSelector(state => state.configuration.clientCode);

    const countryCode = watch('countryCode') as string | undefined;
    const buisnessIdDirty = formState.dirtyFields['businessId'];

    useEffect(() => {
        if (!businessIdTouched && buisnessIdDirty) {
            setBuisnessIdTouched(true);
        }
    }, [buisnessIdDirty, businessIdTouched]);

    useEffect(() => {
        if (businessIdTouched) {
            void trigger('businessId');
        }
    }, [trigger, countryCode, businessIdTouched]);

    const isAu = useMemo(() => {
        return countryCode === 'AU';
    }, [countryCode]);

    // HACK (I was super busy when this issue: https://visualeyes.atlassian.net/browse/CE-1232 was logged)
    // I don't really see a way to fix this without a backend change and redesigning the logic here
    // on the form. You can't rely on the "countryCode" here from the settings
    // because the country code is actually the region where this page is hosted.
    // Singapore does not have it's own region.
    // probably we need to get the country code of the connected careerhub instance.
    const isNanyang = useMemo(() => {
        return countryCode === 'SG' && clientCode === 'Nanyang';
    }, [clientCode, countryCode]);

    const label = useBusinessIdLabel(countryCode);
    const businessIdFormLabel = useMemo(() => {
        if (options?.disableBusinessIdValidator) {
            return label;
        }

        if (isAu || isNanyang) {
            return label + ' *';
        }

        return label
    }, [isAu, isNanyang, label, options?.disableBusinessIdValidator]);

    const businessIdValidator = useCallback(
        (businessId: string) => {
            if (isAu) {
                if (!businessId) {
                    return 'ABN is required for an Australian organisation';
                }

                return abnRule(businessId);
            }

            
            if (isNanyang) {
                if (!businessId) {
                    return 'Company number is required for a Singapore organisation';
                }

                if (!singaporeRegex.test(businessId)) {
                    return 'Invalid Singapore UEN format';
                }
            }

            return undefined;
        },
        [isAu, isNanyang]
    );

    return {
        businessIdValidator,
        businessIdFormLabel,
    };
};
