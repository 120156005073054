import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { Contact } from '../../../../models/contacts/contact.model';
import { registerPaths } from '../../../../routes/register/register.paths';
import { registerOrganisationAsync } from '../../../../logic/features/register/register.actions';
import { ContactForm } from '../../contacts/components/forms/ContactForm';
import { ThinPageContent } from '../../../shared/components/page-components/ThinPageContent';
import { RegisterStartBreadcrumb } from '../components/breadcrumbs/RegisterStartBreadcrumb';
import { RegisterWizardWrapper } from '../components/nav/RegisterWizardWrapper';

export const RegisterContactPage = (): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    const {
        register: {
            contact: { tab },
            wizard: { buttons },
        },
    } = useStrings();
    const organisation = useRootSelector(state => state.register.details);

    const onCancel = () => {
        dispatch(push(registerPaths.organisation));
    };

    const onSubmit = (contact: Contact) => {
        dispatch(
            registerOrganisationAsync.request({
                organisation: organisation!,
                contact,
            })
        );
    };
    const { fetch } = useRootSelector(state => state.register.registerOrganisation);

    return (
        <ThinPageContent>
            <h1>
                <RegisterStartBreadcrumb />
                {tab}
            </h1>
            <RegisterWizardWrapper>
                <ContactForm
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    cancelText={buttons.back}
                    submitText={buttons.continue}
                    useClaims={true}
                    showSpinner={fetch.loading}
                    error={fetch.error}
                    isRegistration={true}
                />
            </RegisterWizardWrapper>
        </ThinPageContent>
    );
};
