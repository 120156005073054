import { useMemo } from 'react';
import { useBannerImageUrl } from 'src/ui/features/events/hooks/banner-image-url.hook';

interface Props {
    isEvent: boolean;
    bannerKey: string | undefined;
    children?: React.ReactNode;
}

export const EventBannerHeader = ({
    bannerKey,
    children,
    isEvent,
}: Props): React.ReactElement<any, any> | null => {
    const url = useBannerImageUrl(bannerKey, isEvent ? 'Event' : 'EventRequest');

    const background = useMemo(() => (url ? `url('${url}') no-repeat` : undefined), [url]);

    return (
        <div
            className="p-4 bg-dark mb-3 text-white d-flex align-items-center position-relative"
            style={{
                background,
                backgroundSize: 'cover',
                minHeight: '436px',
            }}
        >
            {/* NOTE: This background curtain is temporary, the style for event banner is changing, and this will
            be unnecessary. Until that happens, this is mimicing the student view */}
            <div
                className="position-absolute w-100 h-100 tl-0 z-0"
                style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}
            ></div>
            <div className="z-1">{children}</div>
        </div>
    );
};
