import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { individualAsync } from '../../../../logic/features/individuals/individual.actions';
import { useAuthentication } from '../../authentication/hooks/authentication.hook';

export const useIndividual = () => {
    const { isIndividual } = useAuthentication();

    const individual = useRootSelector(state => state.individual.value);
    const { fetch, update } = useRootSelector(state => state.individual.request);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isIndividual && !individual && !fetch.loading && !fetch.error) {
            dispatch(individualAsync.request());
        }
    }, [isIndividual, individual, fetch, dispatch]);

    return {
        individual,
        fetch,
        update,
    };
};
