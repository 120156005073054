import keyBy from 'lodash/keyBy';
import { Endorsement } from 'src/models/endorsements/endorsement.model';
import { LoaderState } from 'src/models/errors/error.model';
import { createReducer } from 'typesafe-actions';
import * as actions from './endorsement.actions';

export type EndorsementState = Readonly<{
    all: {
        fetch: LoaderState;
        fetched: boolean;
        ids: number[];
    };
    items: { [id: number]: Endorsement | undefined };
    managedEndorsementIds: number[];
}>;

const initialState: EndorsementState = {
    all: {
        fetch: { loading: false },
        fetched: false,
        ids: [],
    },
    items: {},
    managedEndorsementIds: [],
};

const endorsementReducer = createReducer(initialState)
    .handleAction(actions.endorsementAllAsync.request, state => ({
        ...state,
        all: {
            ...state.all,
            fetch: { loading: true },
        },
    }))
    .handleAction(actions.endorsementAllAsync.success, (state, action) => ({
        ...state,
        all: {
            fetch: { loading: false },
            fetched: true,
            ids: action.payload.data.map(x => x.id),
        },
        items: {
            ...state.items,
            ...keyBy(action.payload.data, x => x.id),
        },
        managedEndorsementIds: [
            ...action.payload.include.managedEndorsements.map(x => x.endorsementId),
        ],
    }))
    .handleAction(actions.endorsementAllAsync.failure, (state, action) => ({
        ...state,
        all: {
            ...state.all,
            fetch: { loading: false, error: action.payload },
        },
    }))
    .handleAction(actions.endorsementResetState, () => initialState);

export default endorsementReducer;
