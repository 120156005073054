import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { Individual } from '../../../../models/individual/individual.model';
import { registerIndividualAsync } from '../../../../logic/features/register/register.actions';
import { IndividualDetailsForm } from '../../individual/components/forms/IndividualDetailsForm';
import { CardWrapper } from '../../../shared/components/bootstrap/cards/CardWrapper';
import { ThinPageContent } from '../../../shared/components/page-components/ThinPageContent';
import { RegisterStartBreadcrumb } from '../components/breadcrumbs/RegisterStartBreadcrumb';

export const RegisterIndividualPage = (): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();

    const onSubmit = useCallback(
        (data: Individual) => {
            dispatch(registerIndividualAsync.request(data));
        },
        [dispatch]
    );

    const { fetch } = useRootSelector(state => state.register.createIndividual);

    return (
        <ThinPageContent>
            <h1>
                <RegisterStartBreadcrumb />
                Details
            </h1>
            <CardWrapper>
                <IndividualDetailsForm
                    onSubmit={onSubmit}
                    showSpinner={fetch.loading}
                    error={fetch.error}
                />
            </CardWrapper>
        </ThinPageContent>
    );
};
