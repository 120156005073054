import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { LoaderSpinner } from 'src/ui/shared/components/loader/LoaderSpinner';
import { LoaderState } from 'src/models/errors/error.model';

interface Props {
    state: LoaderState | LoaderState[] | undefined;
    complete: boolean;
    titleClassName?: string;
    children: React.ReactNode;
}

export const CentralPublishSectionTitle = ({
    state,
    complete,
    children,
    titleClassName,
}: Props): React.ReactElement<any, any> | null => {
    const stateArray = Array.isArray(state) ? state : state ? [state] : [];
    const errorArray = stateArray.filter(s => !!s.error).map(i => i.error!);
    const error = errorArray.length > 0 ? errorArray[0] : undefined;

    const loading = stateArray.some(i => i.loading);

    return (
        <h3 className={`${titleClassName || ''} ${loading || complete ? 'mb-0' : ''}`}>
            {complete && !error && <Icon icon={faCheck} iconClassName="mr-2 text-success" />}
            {loading && <LoaderSpinner size="sm" spinnerClassName="mr-2 mb-1" />}
            {children}
        </h3>
    );
};
