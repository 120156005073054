import { useMemo } from 'react';
import { Organisation } from 'src/models/organisation/organisation.model';
import { ReactSelectOption } from 'src/ui/shared/components/forms/controls/react-selects/SingleSelectControl';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useCentralCategoryMappings } from './central-mappings.hook';

export const useOrganisationCentralCategories = (organisation: Organisation) => {
    const { hasCentralMapping: hasCentralMappingV2 } = useCentralCategoryMappings();

    const { industries, organisationSizes, organisationScopes } = useRootSelector(
        state => state.central.configuration.value!
    );

    const organisationIndustryIds = useMemo(
        () => organisation.industries?.map(i => i.id) || [],
        [organisation]
    );

    const hasOrganisationIndustryMappings = useMemo(
        () => hasCentralMappingV2('industries', organisationIndustryIds),
        [hasCentralMappingV2, organisationIndustryIds]
    );

    // size and scope are not categories but you know what, I don't care. They may as well be here too.
    const hasValidSize = useMemo(
        () =>
            !!organisation.size &&
            (organisationSizes || []).map(i => i.value).includes(organisation.size.name),
        [organisation.size, organisationSizes]
    );
    const hasValidScope = useMemo(() => {
        if (!organisation.scope) {
            return false;
        }
        // special case for UK universities
        // this in handled in the central.helper class
        if (organisation.scope.name.toLowerCase() === 'multinational') {
            return true;
        }

        return (organisationScopes || []).map(i => i.name).includes(organisation.scope.name);
    }, [organisation.scope, organisationScopes]);

    const industryOptions: ReactSelectOption[] = useMemo(
        () =>
            (industries || []).map(i => ({
                label: i.name,
                value: i.value,
                tokens: i.name.toLowerCase().split(' '),
            })),
        [industries]
    );

    const sizeOptions: ReactSelectOption[] = useMemo(
        () =>
            (organisationSizes || []).map(i => ({
                label: i.name,
                value: i.value,
                tokens: i.name.toLowerCase().split(' '),
            })),
        [organisationSizes]
    );

    const scopeOptions: ReactSelectOption[] = useMemo(
        () =>
            (organisationScopes || []).map(i => ({
                label: i.name,
                value: i.value,
                tokens: i.name.toLowerCase().split(' '),
            })),
        [organisationScopes]
    );

    return {
        hasOrganisationIndustryMappings,
        industryOptions,
        hasValidScope,
        hasValidSize,
        sizeOptions,
        scopeOptions,
    };
};
