import { push } from 'connected-react-router';
import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { isRouteMatch } from 'src/logic/helpers/epics/location.epic-helper';
import { eventPathCreator, eventPaths } from 'src/routes/employer/paths/event.paths';
import { isActionOf } from 'typesafe-actions';
import { RootEpic } from '../epic.root-index';
import * as actions from './entity-note.actions';

export const entityNoteCreateAsyncEpic = asyncEpicBase(
    actions.entityNoteCreateAsync,
    (services, ra) => services.api.entityNotes.create(ra.payload),
    {
        success: result => actions.entityNoteCreateAsync.success(result.json),
        failure: (error, ra) => actions.entityNoteCreateAsync.failure(error, ra.payload),
    }
);

export const entityNoteUpdateAsyncEpic = asyncEpicBase(
    actions.entityNoteUpdateAsync,
    (services, ra) => services.api.entityNotes.update(ra.payload),
    {
        success: result => actions.entityNoteUpdateAsync.success(result.json),
        failure: (error, ra) => actions.entityNoteUpdateAsync.failure(error, ra.payload),
    }
);

export const entityNoteDeleteAsyncEpic = asyncEpicBase(
    actions.entityNoteDeleteAsync,
    (services, ra) => services.api.entityNotes.del(ra.payload),
    {
        success: result => actions.entityNoteDeleteAsync.success(result.json),
        failure: (error, ra) => actions.entityNoteDeleteAsync.failure(error, ra.payload),
    }
);

export const entityNoteRedirectOnCreateEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(actions.entityNoteCreateAsync.success)),
        filter(() => isRouteMatch(state$.value.router.location, eventPaths.changeRequestAdd)),
        mergeMap(action =>
            of(push(eventPathCreator.changeRequestList(action.payload.data.attachedToEntityId)))
        )
    );
};

export const entityNoteRedirectOnUpdateEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(actions.entityNoteUpdateAsync.success)),
        filter(() => isRouteMatch(state$.value.router.location, eventPaths.changeRequestEdit)),
        mergeMap(action =>
            of(push(eventPathCreator.changeRequestList(action.payload.data.attachedToEntityId)))
        )
    );
};
