import * as sentry from '@sentry/browser';

export const logError = (
    error: Error,
    extraData: Record<string, unknown> = {},
    tags: Record<string, string> | undefined = undefined
) => {
    let errorId: string | undefined = undefined;

    sentry.withScope(scope => {
        if (tags) {
            scope.setTags(tags);
        }

        scope.setExtras(extraData);
        errorId = sentry.captureException(error);
    });

    return errorId;
};

export const logMessage = (
    message: string,
    severity: sentry.Severity,
    extraData: Record<string, unknown> = {},
    tags: Record<string, string> | undefined = undefined
) => {
    sentry.withScope(scope => {
        if (tags) {
            scope.setTags(tags);
        }
        scope.setExtras(extraData);
        sentry.captureMessage(message, severity);
    });
};
