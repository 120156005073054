import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

export const useEventChangeRequests = () => {
    const changeRequests = useRootSelector(state => {
        const ids = (state.eventUnions.notes[state.eventUnions.activeId || -1] || []).map(
            x => x.id
        );
        return (ids || [])
            .map(id => state.entityNotes.items[id])
            .filter(x => x !== undefined)
            .map(x => x!);
    });

    return changeRequests;
};
