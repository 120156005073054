import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import * as actions from './upload.actions';

export const uploadAsyncEpic = asyncEpicBase(
    actions.uploadCreateAsync,
    (services, ra) => services.api.uploads.create(ra.payload),
    {
        success: result => actions.uploadCreateAsync.success(result.json),
        failure: error => actions.uploadCreateAsync.failure(error),
    }
);
