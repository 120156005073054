import { push } from 'connected-react-router';
import { useCallback } from 'react';
import { Pagination } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { contactPathCreator } from '../../../../../routes/employer/paths/contact.paths';

export const ContactListStatusFilter = (): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();

    const queryIsActive = useRootSelector(state => state.contacts.activeList.query.isActive);

    const onSetActive = useCallback(
        (isActive: boolean | undefined) => {
            const toSend =
                queryIsActive === undefined
                    ? isActive
                    : queryIsActive === isActive
                    ? undefined
                    : isActive;

            dispatch(push(contactPathCreator.list({ isActive: toSend })));
        },
        [dispatch, queryIsActive]
    );

    return (
        <Pagination size="sm" className="mr-3 pagination-button-group">
            <Pagination.Item
                activeLabel="active label"
                onClick={() => onSetActive(true)}
                active={queryIsActive === true}
            >
                Active
            </Pagination.Item>
            <Pagination.Item
                activeLabel="active label"
                onClick={() => onSetActive(false)}
                active={queryIsActive === false}
            >
                Inactive
            </Pagination.Item>
        </Pagination>
    );
};
