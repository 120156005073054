import { CardWrapper } from '../../../shared/components/bootstrap/cards/CardWrapper';
import { Loader } from '../../../shared/components/loader/Loader';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';
import { JobChangeRequestForm } from '../components/forms/JobChangeRequestForm';
import { useActiveJob } from '../hooks/active-job.hook';

export const JobChangeRequestPage = (): React.ReactElement<any, any> | null => {
    const { job, fetch } = useActiveJob();

    return (
        <ApplicationPageContent>
            <Loader state={fetch} useCard={true}>
                <CardWrapper>{job && <JobChangeRequestForm jobId={job.id} />}</CardWrapper>
            </Loader>
        </ApplicationPageContent>
    );
};
