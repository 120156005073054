import * as sentry from '@sentry/react';
import { RootState } from '../features/reducer.root-index';

export const sentryEnhancer = sentry.createReduxEnhancer({
    actionTransformer: action => {
        const payloadByteLength = new TextEncoder().encode(JSON.stringify(action.payload)).length;
        if (action.payload && payloadByteLength > 8192) {
            return {
                type: action.type,
                payload: null,
            };
        }

        return action;
    },
    configureScopeWithState: (scope, state: RootState) => {
        const {
            authentication: { activeIdentity, oidcUser },
        } = state;

        scope.setTag('hasActiveIdentity', activeIdentity ? 'true' : 'false');
        scope.setTag('isIFrame', window.self !== window.top ? 'true' : 'false');

        if (activeIdentity) {
            scope.setUser({
                id: activeIdentity.userId?.toString(),
                email: activeIdentity.email,
                username: `${activeIdentity.firstName} ${activeIdentity.lastName}`,
            });
        } else if (oidcUser) {
            const email =
                typeof oidcUser.profile.email === 'string'
                    ? oidcUser.profile
                    : oidcUser.profile[0] || '';

            scope.setUser({
                id: oidcUser.profile.sub,
                email: email,
                username: `${oidcUser.profile.given_name || 'no_given_name'} ${
                    oidcUser.profile.family_name || 'no_family_name'
                }`,
            });
        }
    },
    stateTransformer: (state: RootState) => {
        return null;
    },
});
