import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FieldError } from 'react-hook-form';
import { Icon } from '../../icon/Icon';

const ControlValidationInvalidSpan = ({
    message,
    className,
}: {
    message?: string;
    className?: string;
}): React.ReactElement<any, any> | null => (
    <span className={`invalid-feedback ${className || ''}`}>
        <Icon icon={faExclamationTriangle}>{message || 'This field is invalid.'}</Icon>
    </span>
);

const ControlValidationDisplay = ({
    error,
}: {
    error: FieldError | undefined | string;
}): React.ReactElement<any, any> | null => {
    if (!error) {
        return null;
    }

    if (typeof error === 'string') {
        return <ControlValidationInvalidSpan message={error} />;
    }

    if (error.type === 'required') {
        return <ControlValidationInvalidSpan message="This field is required." />;
    }

    if (!error.types) {
        return <ControlValidationInvalidSpan message={error.message as string} />;
    }

    return (
        <>
            {Object.keys(error.types).map(key => (
                /* This could be anything, but I'm assuming it's a string */
                <ControlValidationInvalidSpan
                    key={key}
                    className="d-block"
                    message={
                        typeof error.types![key] === 'string'
                            ? (error.types![key] as string)
                            : undefined
                    }
                />
            ))}
        </>
    );
};

export default ControlValidationDisplay;
