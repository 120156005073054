import { CSSProperties } from 'react';

export const Square = (props: { style?: CSSProperties; className?: string }) => (
    <svg
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={props.style}
        className={props.className}
    >
        <path
            // eslint-disable-next-line max-len
            d="M3.313,8.938c-0.001,-3.077 2.46,-5.626 5.624,-5.626l28.126,0c3.076,0 5.624,2.549 5.624,5.626l0,28.124c0,3.165 -2.548,5.625 -5.625,5.625l-28.125,0c-3.164,0 -5.625,-2.46 -5.625,-5.625l0,-28.125Z"
            fill="currentColor"
        />
    </svg>
);
