import { TitleLoader } from 'src/ui/shared/components/loader/TitleLoader';
import { PageTitle } from 'src/ui/shared/components/page-components/PageTitle';

interface Props {
    title?: string;
    before?: React.ReactNode;
    after?: React.ReactNode;
    hideLoader?: boolean;
}

export const EntityDetailsTitle = ({
    title,
    before,
    after,
    hideLoader,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <div className="d-flex align-items-center">
            <PageTitle>
                {before && <>{before}</>}
                {title && (
                    <div className="text-long-title mr-2" title={title}>
                        {title}
                    </div>
                )}
            </PageTitle>
            {!title && !hideLoader && <TitleLoader />}
            {after && <>{after}</>}
        </div>
    );
};
