import { entityListInitialState } from 'src/logic/helpers/initial-state.helper';
import { listReducerHandler } from 'src/logic/reducer-handlers/list.reducer-handler';
import { EventBooking } from 'src/models/events/event-booking.model';
import { EntityListState } from 'src/models/store-models/entity-list-state.model';
import { createReducer } from 'typesafe-actions';
import { EventBookingPageRequest } from './event-booking.service';
import * as actions from './event-booking.actions';

export type EventBookingState = EntityListState<EventBooking, EventBookingPageRequest>;

const initialState: EventBookingState = {
    ...entityListInitialState,
};

export const eventBookingReducer = createReducer(initialState)
    .handleAction(actions.eventBookingListSetActive, listReducerHandler.setActive)
    .handleAction(actions.eventBookingListAsync.request, listReducerHandler.request)
    .handleAction(actions.eventBookingListAsync.failure, listReducerHandler.failure)
    .handleAction(actions.eventBookingListAsync.cancel, listReducerHandler.cancel)
    .handleAction(actions.eventBookingListAsync.success, listReducerHandler.success)

    .handleAction(actions.eventBookingResetState, () => initialState);
