import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { listEpic } from 'src/logic/helpers/epics/list-route.epic-helper';
import { formPaths } from 'src/routes/employer/paths/form.paths';
import { formSubmissionListAsync, formSubmissionListSetActive } from '../form-submission.actions';

export const formSubmissionListEpic = listEpic({
    path: formPaths.submissions,
    getParams: (action, _, { queryParams }) =>
        queryParams.entityListParams(action.payload.location.search),
    getListState: state => state.formSubmissions,
    setActiveActionCreator: formSubmissionListSetActive,
    cancelActionCreator: formSubmissionListAsync.cancel,
    requestActionCreator: formSubmissionListAsync.request,
});

export const formSubmissionListAsyncEpic = asyncEpicBase(
    formSubmissionListAsync,
    ({ api }, { payload }) => api.formSubmissions.list(payload),
    {
        success: result => formSubmissionListAsync.success(result.json),
        failure: (error, ra) => formSubmissionListAsync.failure(error, ra.payload),
    }
);
