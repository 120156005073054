import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { useLogout } from '../hooks/logout.hook';

export const AuthenticationFallback = (): React.ReactElement<any, any> | null => {
    const { loadState, stillWaiting } = useRootSelector(state => state.init);
    const oidcExists = useRootSelector(state => state.authentication.oidcUser);
    const { logoutBecauseOfError } = useLogout();

    const {
        nav: { user },
    } = useStrings();

    const onClick = () => {
        window.location.reload();
    };

    return (
        <>
            {/* This allows the user to call into CareerHub (which is the only way you can 
                clear both the CareerHub cookie (secure) and Central Identity )  */}
            {loadState.error && oidcExists && (
                <div className="text-center">
                    You may need to{' '}
                    <Button size="sm" variant="outline-primary" onClick={logoutBecauseOfError}>
                        <Icon icon={faSignOutAlt} size="lg">
                            {user.logout}
                        </Icon>
                    </Button>{' '}
                    and try again.
                </div>
            )}

            <div
                className={`mx-5 text-center fade ${
                    stillWaiting && !loadState.error ? 'show' : ''
                }`}
            >
                This site is taking longer than normal to load. Click{' '}
                <Button className="p-0 align-baseline" variant="link" onClick={onClick}>
                    here
                </Button>{' '}
                to reload.
            </div>
        </>
    );
};
