import { faBell } from '@fortawesome/free-solid-svg-icons';
import { push } from 'connected-react-router';
import { useCallback } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Markdown } from 'src/ui/shared/components/details/Markdown';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { registerPaths } from '../../../../routes/register/register.paths';
import { AlertWrapper } from '../../../shared/components/bootstrap/alerts/AlertWrapper';
import { ButtonLink } from '../../../shared/components/bootstrap/buttons/ButtonLink';
import { ListPagination } from '../../../shared/components/bootstrap/pagination/ListPagination';
import { Loader } from '../../../shared/components/loader/Loader';
import { ListPaginationWrapper } from '../../../shared/components/page-components/ListPaginationWrapper';
import { ThinPageContent } from '../../../shared/components/page-components/ThinPageContent';
import { useActiveEntityList } from '../../../shared/hooks/active-entity-list.hook';
import { RegisterStartBreadcrumb } from '../components/breadcrumbs/RegisterStartBreadcrumb';
import { OrganisationSimilarListItem } from '../components/list/OrganisationSimilarListItem';
import { RequestAccessModal } from '../components/modal/RequestAccessModal';
import { RegisterWizardWrapper } from '../components/nav/RegisterWizardWrapper';
import { useRequestAccess } from '../hooks/request-access.hook';

export const RegisterSimilarPage = (): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    const { requestOrganisationId, onRequestAccessClick } = useRequestAccess();

    const activeState = useRootSelector(state => state.register.similar.activeList);

    const {
        entities: items,
        fetch,
        pagination,
    } = useActiveEntityList(state => state.register.similar);

    const onBack = useCallback(() => {
        dispatch(push(registerPaths.name));
    }, [dispatch]);

    const {
        register: { similar, wizard },
    } = useStrings();

    return (
        <ThinPageContent>
            <ListPaginationWrapper pagination={pagination}>
                <h1>
                    <RegisterStartBreadcrumb />
                    {similar.title}
                </h1>
            </ListPaginationWrapper>

            <RegisterWizardWrapper>
                <Loader state={fetch}>
                    {items && (
                        <>
                            <AlertWrapper icon={faBell} variant="warning">
                                <div>
                                    <Markdown source={similar.warning} />
                                </div>
                            </AlertWrapper>

                            <ListGroup>
                                {items.map(i => (
                                    <OrganisationSimilarListItem
                                        key={i.id}
                                        organisation={i}
                                        onClick={() => onRequestAccessClick(i.id)}
                                    />
                                ))}
                            </ListGroup>

                            <div className="mt-5 d-flex">
                                {activeState.pagination.pageTotal > 1 && (
                                    <ListPagination pagination={activeState.pagination} />
                                )}
                                <Button variant="link" className="ml-auto" onClick={onBack}>
                                    {wizard.buttons.back}
                                </Button>
                                <ButtonLink to={registerPaths.organisation}>
                                    {wizard.buttons.continue}
                                </ButtonLink>
                            </div>
                        </>
                    )}
                </Loader>
            </RegisterWizardWrapper>

            {requestOrganisationId && <RequestAccessModal organisationId={requestOrganisationId} />}
        </ThinPageContent>
    );
};
