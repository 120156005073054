import { faExclamationTriangle, faUser } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { identityPaths } from 'src/routes/identity/identity.paths';
import { useLayout } from 'src/routes/RouteList';
import { useAuthenticationLoading } from 'src/ui/features/authentication/hooks/authentication-loading.hook';
import { useClaimValues } from 'src/ui/features/authentication/hooks/claim-values.hook';
import { useLogout } from 'src/ui/features/authentication/hooks/logout.hook';
import { useAuthentication } from '../../../../features/authentication/hooks/authentication.hook';
import { useStrings } from '../../../hooks/strings.hook';
import { Icon } from '../../icon/Icon';
import { TitleLoader } from '../../loader/TitleLoader';
import { ContentApplicationLink } from '../content-navigation/ContentApplicationLink';
import { ActiveIdentityDropdown } from './ActiveIdentityDropdown';
import { UserHistoryDropdown } from './UserHistoryDropdown';
import { UserNavConfigurationGuard } from './UserNavConfigurationGuard';

export const UserNav = (): React.ReactElement<any, any> | null => {
    // NOTE: Don't rely on the "isAuthenticated" value from the useAuthentication
    // hook. This returns true only if the user has exchanged the "access_token" from
    // central to a careerhub auth cookie. It's possible to be inbetween these
    // two states. Therefore, we use the claims value to differ state.
    const claimValues = useClaimValues();

    const { isImpersonation, error } = useAuthentication();
    const { logoutBecauseOfError } = useLogout();

    const { nav } = useStrings();

    const { navButtonVariant } = useLayout();

    const showLoading = useAuthenticationLoading();

    const showLoginButton = !showLoading && !claimValues && !isImpersonation && !error;

    const showMenu = !showLoading && (claimValues || isImpersonation) && !error;

    return (
        <div className="ml-auto pr-3">
            {showLoading && <TitleLoader />}
            {error && (
                <Button variant="link-danger" onClick={logoutBecauseOfError}>
                    <div>
                        <Icon icon={faExclamationTriangle}>There was a problem authenticating</Icon>
                    </div>
                    Click here to try again.
                </Button>
            )}

            {showLoginButton && (
                <UserNavConfigurationGuard>
                    <Button id="btn-login" variant={navButtonVariant} href={identityPaths.landing}>
                        <Icon icon={faUser} size="lg">
                            {nav.login}
                        </Icon>
                    </Button>
                </UserNavConfigurationGuard>
            )}

            {showMenu && (
                <div className="d-flex align-items-center py-2">
                    <ContentApplicationLink />
                    <UserHistoryDropdown />
                    <ActiveIdentityDropdown />
                </div>
            )}
        </div>
    );
};
