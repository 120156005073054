import { useDispatch } from 'react-redux';
import { eventRequestPublishAsync } from 'src/logic/features/event-unions/event-union.actions';
import { EventRequestPublish } from 'src/models/events/event-request.model';
import { CardWrapper } from 'src/ui/shared/components/bootstrap/cards/CardWrapper';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { ApplicationPageContent } from 'src/ui/shared/components/page-components/ApplicationPageContent';
import { useActiveEntity } from 'src/ui/shared/hooks/active-entity.hook';
import { useFormButton } from 'src/ui/shared/hooks/form-button.hook';
import { EventRequestPublishForm } from '../components/forms/EventRequestPublishForm';

export const EventPublishPage = (): React.ReactElement<any, any> | null => {
    const { entity, fetch, update } = useActiveEntity(state => state.eventUnions);
    const dispatch = useDispatch();

    const onSubmit = (data: EventRequestPublish) => {
        dispatch(eventRequestPublishAsync.request(data));
    };

    const { onCancel } = useFormButton();

    return (
        <ApplicationPageContent>
            <Loader useCard={true} state={fetch}>
                {entity && entity.eventRequest && (
                    <CardWrapper>
                        <EventRequestPublishForm
                            eventRequest={entity.eventRequest}
                            onSubmit={onSubmit}
                            onCancel={onCancel}
                            state={update}
                        />
                    </CardWrapper>
                )}
            </Loader>
        </ApplicationPageContent>
    );
};
