interface Props {
    loading?: boolean;
    children?: React.ReactNode;
}

export const LoaderText = ({ loading, children }: Props): React.ReactElement<any, any> | null => {
    return (
        <div className="d-flex align-items-center">
            {loading && <div className="spinner-border spinner-border-sm mr-1" />}
            {children}
        </div>
    );
};
