interface Props {
    label: React.ReactNode;
    value: React.ReactNode;
}

export const DetailsPropertyValueDisplay = ({
    label,
    value,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <div className="d-table-row">
            <div className="d-table-cell small pr-2">{label}</div>
            <div className="d-table-cell">{value}</div>
        </div>
    );
};
