import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { BBox2D } from '@symplicity/geocoding';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { FieldError, useFieldArray, useFormContext } from 'react-hook-form';
import { LocationModel } from 'src/models/locations/location.model';
import { LocationView } from 'src/ui/features/locations/components/LocationView';
import { HookControlProps } from 'src/ui/shared/components/forms/HookForm';
import { ControlFormGroup } from 'src/ui/shared/components/forms/shared/ControlFormGroup';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { geolocationToBBox } from '../helpers/geolocation.helpers';
import { useCountryOption } from '../hooks/useCountryOption';
import { LocationFields } from './LocationFields';
import { LocationInputGroup } from './LocationInputGroup';
import { LocationMap } from './LocationMap';

type LocationValue = LocationModel & { added?: boolean };

const getBBoxes = (values: LocationValue[] | undefined): BBox2D[] => {
    return values
        ? values.filter(x => x.geolocation).map(x => geolocationToBBox(x.geolocation!))
        : [];
};

export const GeoLocationMultiControl = (props: HookControlProps): ReactElement => {
    const { name, tabIndex, disabled } = props;
    const {
        control,
        formState: { errors },
        getValues,
    } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        name,
        control,
    });
    const [bboxes, setBBoxes] = useState<BBox2D[]>([]);
    const controlError = errors[name] as FieldError;
    const countryCode = useRootSelector(state => state.organisation.value?.countryCode);
    const countryOption = useCountryOption(countryCode);

    const updateBBoxes = useCallback(() => {
        const values = getValues(name) as LocationValue[];
        const bboxes = getBBoxes(values);
        setBBoxes(bboxes);
    }, [getValues, name]);

    useEffect(() => {
        setBBoxes(getBBoxes(fields as LocationValue[]));
    }, [fields]);

    const onAddClick = useCallback(() => {
        append({
            countryCode,
            geolocation: countryOption?.geolocation,
            added: true,
        });
    }, [append, countryCode, countryOption?.geolocation]);

    useEffect(() => {
        const initialValue = getValues(name) as LocationValue[] | undefined;
        if (!initialValue) {
            onAddClick();
        }
    }, [getValues, name, onAddClick]);

    return (
        <ControlFormGroup {...props}>
            <div className="mb-2">
                <LocationMap bboxes={bboxes} />
            </div>
            {fields.map((field, index) => {
                const value = field as LocationValue;
                return (
                    <LocationInputGroup
                        key={field.id}
                        className="mb-2"
                        variant={value.added ? 'white' : 'light'}
                        onRemove={() => remove(index)}
                    >
                        {value.added ? (
                            <LocationFields
                                name={`${name}[${index}]`}
                                defaultValues={value}
                                onChange={() => updateBBoxes()}
                            />
                        ) : (
                            <LocationView value={value} />
                        )}
                    </LocationInputGroup>
                );
            })}
            <Form.Row>
                <Col sm={6} md={5} lg={4} xl={3} className="mb-2">
                    <Button
                        variant="outline-input"
                        tabIndex={tabIndex}
                        disabled={disabled}
                        className={`w-100 text-left text-dark ${
                            controlError ? 'border-danger' : ''
                        }`}
                        onClick={onAddClick}
                    >
                        <Icon icon={faPlus}>Add new location</Icon>
                    </Button>
                </Col>
            </Form.Row>
        </ControlFormGroup>
    );
};
