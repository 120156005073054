import { createAsyncAction, createAction } from 'typesafe-actions';
import { ApiResponse } from '../../../models/api/response.model';
import { ErrorNormalized } from '../../../models/errors/error.model';
import { Individual } from '../../../models/individual/individual.model';

export const individualResetState = createAction('INDIVIDUAL_RESET_STATE')();

export const individualAsync = createAsyncAction(
    'INDIVIDUAL:REQUEST',
    'INDIVIDUAL:SUCCESS',
    'INDIVIDUAL:FAILURE',
    'INDIVIDUAL:CANCEL'
)<undefined, ApiResponse<Individual>, ErrorNormalized, undefined>();

export const individualUpdateAsync = createAsyncAction(
    'INDIVIDUAL_UPDATE:REQUEST',
    'INDIVIDUAL_UPDATE:SUCCESS',
    'INDIVIDUAL_UPDATE:FAILURE'
)<Individual, ApiResponse<Individual>, ErrorNormalized>();
