import { appStringify } from '../../router.helper';

export type CareerHubRedirectParams = {
    returnUrl?: number;
};

export const careerhubPaths = {
    redirect: '/careerhub/redirect',
};

export const careerhubRedirectPathCreator = {
    publish: (params?: CareerHubRedirectParams) => `/careerhub/redirect${appStringify(params)}`,
};
