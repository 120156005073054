import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import {
    ConfigurationModelIncludes as CentralConfigurationInclude,
    Node as CentralNode,
    OrganisationScope as CentralOrganisationScope,
    SimilarOrganisationModel as CentralSimilarOrganisation,
} from '@symplicity/central-types';
import { LocalCategoryMapV2 } from 'src/models/central/central-mapping.model';
import { LoaderState } from 'src/models/errors/error.model';
import { Job } from 'src/models/jobs/job.model';
import { Organisation } from 'src/models/organisation/organisation.model';
import { EntityItemsState } from 'src/models/store-models/entity-list-state.model';
import { createReducer } from 'typesafe-actions';
import { configurationInit } from '../configuration/configuration.actions';
import * as actions from './central.actions';
import { mapCentralCategoriesToLocalIds } from './helpers/central-mapping.helper';
import { shouldRetryJobFailure } from './helpers/central.helper';

export interface CentralPublishState {
    organisation?: Organisation;
    job?: Job;
    centralOrganisationId?: number;
    centralJobId?: number;
    centralOrganisationPrimaryContactId?: number;

    // below are all selected by the user
    subscribeCentral?: boolean;
    subscribeCentralApps?: boolean;
    inviteContactIds?: number[];
    centralOrganisationIndustryIds?: number[];
    centralJobOccupationIds?: number[];
    centralJobEmploymentTypeIds?: number[];
    centralOrganisationSize?: string;
    centralOrganisationScope?: CentralOrganisationScope;
}

export type CentralState = Readonly<{
    requiresReauthentication: boolean;
    register: LoaderState;

    jobListFetch: LoaderState;
    organisationListFetch: LoaderState;

    jobCreateState: LoaderState;
    jobAttachmentState: {
        [key: string]: LoaderState | undefined;
    };
    contactInviteState: {
        [key: number]: LoaderState | undefined;
    };

    localJobId?: number;
    currentStep: actions.CentralPublishNextStepPayload;

    publish: CentralPublishState;

    similar: {
        fetch: LoaderState | undefined;
        ids: number[];
        items: EntityItemsState<CentralSimilarOrganisation & { id: number }>;
    };

    // the below setting are maintained through the reset action (along with mappings)
    publishedEntities: { [key: number]: { centralId: number; hasPublishDate: boolean } };

    configuration: {
        fetch: LoaderState;
        value: Omit<CentralConfigurationInclude, 'nodes'> | undefined;
        clientCode: string;
        clientNode: CentralNode | undefined;
        clientCategoryMap: LocalCategoryMapV2;
    };
}>;

const initialState: CentralState = {
    requiresReauthentication: false,
    register: { loading: false },

    jobListFetch: { loading: false },
    organisationListFetch: { loading: false },
    jobCreateState: { loading: false },
    jobAttachmentState: {},
    contactInviteState: {},
    similar: {
        fetch: undefined,
        ids: [],
        items: {},
    },
    configuration: {
        fetch: { loading: true },
        value: undefined,
        clientCode: '__',
        clientNode: undefined,
        clientCategoryMap: {
            industries: {},
            occupations: {},
            typesOfWork: {},
        },
    },
    currentStep: {
        step: 'init',
    },
    publish: {},
    publishedEntities: {},
};

const centralReducer = createReducer<CentralState>(initialState)
    // hijack normal config call to set the clientCode
    .handleAction(configurationInit, (state, action) => ({
        ...state,
        configuration: {
            ...state.configuration,
            clientCode: action.payload.identityOverrideClientId || action.meta.clientCode,
        },
    }))

    .handleAction(actions.centralSetRequiresAuthentication, state => ({
        ...state,
        requiresReauthentication: true,
    }))
    .handleAction(actions.centralCreateJobSetActive, (state, action) => ({
        ...state,
        localJobId: action.payload.jobId,
    }))
    .handleAction(actions.centralCreateUserConfirmation, (state, action) => ({
        ...state,
        publish: {
            ...state.publish,
            ...action.payload,
        },
    }))
    .handleAction(actions.centralPublishActionStep, (state, action) => ({
        ...state,
        currentStep: action.payload,
    }))

    // configuration v2
    .handleAction(actions.centralConfigurationAsync.request, state => ({
        ...state,
        configuration: {
            ...state.configuration,
            fetch: { loading: true },
        },
    }))
    .handleAction(actions.centralConfigurationAsync.success, (state, action) => {
        const clientCode = state.configuration.clientCode;
        const clientNode = (action.payload.include?.nodes || []).find(
            i => i.key === state.configuration.clientCode
        )!;

        return {
            ...state,
            configuration: {
                clientCode,
                clientNode,
                fetch: { loading: false },
                value: omit(action.payload.include, 'nodes'),
                clientCategoryMap: mapCentralCategoriesToLocalIds(clientNode),
            },
        };
    })
    .handleAction(actions.centralConfigurationAsync.failure, (state, action) => ({
        ...state,
        configuration: {
            ...state.configuration,
            fetch: { loading: false, error: action.payload },
        },
    }))

    .handleAction(actions.centralRegisterAsync.request, (state, action) => ({
        ...state,
        register: { loading: true },
    }))
    .handleAction(actions.centralRegisterAsync.success, (state, action) => ({
        ...state,
        register: { loading: false },
        publish: {
            ...state.publish,
            centralOrganisationId: action.payload.data.organisation.id,
            centralOrganisationPrimaryContactId: action.payload.data.contact.id,
        },
        publishedEntities: {
            ...state.publishedEntities,
            [Number(action.payload.data.organisation.nodeEntityId)]: {
                centralId: action.payload.data.organisation.id,
                hasPublishDate: false,
            },
            [Number(action.payload.data.contact.nodeEntityId)]: {
                centralId: action.payload.data.contact.id,
                hasPublishDate: false,
            },
        },
    }))
    .handleAction(actions.centralRegisterAsync.failure, (state, action) => ({
        ...state,
        register: { loading: false, error: action.payload },
    }))

    // contact invite actions
    .handleAction(actions.centralContactInviteAsync.request, (state, action) => ({
        ...state,
        contactInviteState: {
            ...state.contactInviteState,
            [action.meta.index]: {
                loading: true,
            },
        },
    }))
    .handleAction(actions.centralContactInviteAsync.success, (state, action) => ({
        ...state,
        contactInviteState: {
            ...state.contactInviteState,
            [action.meta.index]: {
                loading: false,
            },
        },
    }))
    .handleAction(actions.centralContactInviteAsync.failure, (state, action) => ({
        ...state,
        contactInviteState: {
            ...state.contactInviteState,
            [action.meta.index]: {
                loading: false,
                error: action.payload,
            },
        },
    }))

    // job create actions
    .handleAction(actions.centralJobCreateAsync.request, (state, action) => ({
        ...state,
        jobCreateState: {
            loading: true,
        },
    }))
    .handleAction(actions.centralJobCreateAsync.success, (state, action) => ({
        ...state,
        jobCreateState: {
            loading: false,
        },
        publish: {
            ...state.publish,
            centralJobId: action.payload.data.id,
        },
        publishedEntities: {
            ...state.publishedEntities,
            [Number(action.payload.data.nodeEntityId!)]: {
                centralId: action.payload.data.id,
                hasPublishDate: !!action.payload.data.publishDate,
            },
        },
    }))
    .handleAction(actions.centralJobCreateAsync.failure, (state, action) =>
        shouldRetryJobFailure(action)
            ? state
            : {
                  ...state,
                  jobCreateState: {
                      loading: false,
                      error: action.payload,
                  },
              }
    )

    // job attachement actions
    // NOTE: theses actions are not all from the same async action creator
    // centralJobAttachment---Blob---Async.request flows into centralJobAttachment---Upload---Async.success
    .handleAction(actions.centralFromCareerHubJobAttachmentBlobAsync.request, (state, action) => ({
        ...state,
        jobAttachmentState: {
            ...state.jobAttachmentState,
            [action.meta.index]: {
                loading: true,
            },
        },
    }))
    .handleAction(actions.centralJobAttachmentUploadAsync.success, (state, action) => ({
        ...state,
        jobAttachmentState: {
            ...state.jobAttachmentState,
            [action.meta.index]: {
                loading: false,
            },
        },
    }))
    .handleAction(
        [
            actions.centralFromCareerHubJobAttachmentBlobAsync.failure,
            actions.centralJobAttachmentUploadAsync.failure,
        ],
        (state, action) => ({
            ...state,
            jobAttachmentState: {
                ...state.jobAttachmentState,
                [action.meta.index]: {
                    loading: false,
                    error: action.payload,
                },
            },
        })
    )

    // organiastion query actions
    .handleAction(actions.centralOrganisationListAsync.request, state => ({
        ...state,
        organisationListFetch: {
            loading: true,
        },
    }))
    .handleAction(actions.centralOrganisationListAsync.success, (state, action) => ({
        ...state,
        organisationListFetch: {
            loading: false,
        },
        publishedEntities: {
            ...state.publishedEntities,
            ...action.payload.data
                .filter(i => !!i.nodeEntityId)
                .reduce(
                    (acc, current) => ({
                        ...acc,
                        [current.nodeEntityId!]: {
                            centralId: current.id,
                            hasPublishDate: false,
                        },
                    }),
                    {}
                ),
        },
    }))
    .handleAction(actions.centralOrganisationListAsync.failure, (state, action) => ({
        ...state,
        organisationListFetch: {
            loading: false,
            error: action.payload,
        },
    }))

    // similar organisation query actions
    .handleAction(actions.centralSimilarOrganisationListAsync.request, (state, action) => ({
        ...state,
        similar: {
            ...state.similar,
            fetch: { loading: true },
        },
    }))
    .handleAction(actions.centralSimilarOrganisationListAsync.success, (state, action) => ({
        ...state,
        similar: {
            ...state.similar,
            fetch: { loading: false },
            ids: action.payload.data.map(i => i.id),
            items: keyBy(action.payload.data, i => i.id),
        },
    }))
    .handleAction(actions.centralSimilarOrganisationListAsync.failure, (state, action) => ({
        ...state,
        similar: {
            ...state.similar,
            fetch: { loading: false, error: action.payload },
        },
    }))

    // job query actions
    .handleAction(actions.centralJobListAsync.request, state => ({
        ...state,
        jobListFetch: {
            loading: true,
        },
    }))
    .handleAction(actions.centralJobListAsync.success, (state, action) => ({
        ...state,
        jobListFetch: {
            loading: false,
        },
        publishedEntities: {
            ...state.publishedEntities,
            ...action.payload.data
                .filter(i => !!i.nodeEntityId)
                .reduce(
                    (acc, current) => ({
                        ...acc,
                        [current.nodeEntityId!]: {
                            centralId: current.id,
                            hasPublishDate: !!current.publishDate,
                        },
                    }),
                    {}
                ),
        },
    }))
    .handleAction(actions.centralJobListAsync.failure, (state, action) => ({
        ...state,
        jobListFetch: {
            loading: false,
            error: action.payload,
        },
    }))

    // always remember what was published, reset everything else
    .handleAction(actions.centralPublishReset, state => ({
        ...initialState,
        publishedEntities: state.publishedEntities,
        // also no point reload config
        configuration: state.configuration,
    }));

export default centralReducer;
