import { useActiveEntity } from '../../../shared/hooks/active-entity.hook';

export const useActiveContact = () => {
    const { id, entity, fetch, update, deleted } = useActiveEntity(state => state.contacts);

    return {
        id,
        contact: entity,
        fetch,
        update,
        deleted,
    };
};
