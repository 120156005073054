import { getClientConfig } from 'src/clients-internal/configuration.init';
import { apiStringify } from 'src/logic/helpers/api-query-string.helper';
import { fetchHelper } from 'src/logic/helpers/fetch.helper';
import { EntityPageRequest, EntityRequest } from 'src/models/api/request.model';
import {
    ApiIncludeResponse,
    ApiResponse,
    ApiSearchIncludeResponse,
} from 'src/models/api/response.model';
import { ObsApiExpected } from 'src/models/api/service.model';
import {
    EventInclude,
    EventRequest,
    EventRequestPublish,
} from 'src/models/events/event-request.model';
import { EventRequestStatus, EventStatus } from 'src/models/events/event-status.model';
import { EventUnion } from 'src/models/events/event-union.model';
import { CareerHubEvent } from 'src/models/events/event.model';

const baseUrl = () => getClientConfig().careerHubBasePath;

export interface EventUnionPageRequest extends EntityPageRequest {
    eventStatus?: EventStatus[];
    eventRequestStatus?: EventRequestStatus[];
}

export const list = (
    request: EventUnionPageRequest
): ObsApiExpected<ApiSearchIncludeResponse<EventUnion, EventUnionPageRequest, EventInclude>> => {
    return fetchHelper.get(
        `${baseUrl()}/services/employers/v1/eventunions${apiStringify(request)}`
    );
};

export const single = (
    request: EntityRequest
): ObsApiExpected<ApiIncludeResponse<EventUnion, EventInclude>> => {
    return fetchHelper.get(`${baseUrl()}/services/employers/v1/eventunions/${request.id}`);
};

export const create = (
    request: Partial<EventRequest>
): ObsApiExpected<ApiResponse<EventRequest>> => {
    return fetchHelper.post(`${baseUrl()}/services/employers/v1/eventrequests`, request);
};

export const update = (
    request: Partial<EventRequest> & { id: number }
): ObsApiExpected<ApiResponse<EventRequest>> => {
    return fetchHelper.put(
        `${baseUrl()}/services/employers/v1/eventrequests/${request.id}`,
        request
    );
};

export const publish = (
    request: EventRequestPublish
): ObsApiExpected<ApiResponse<EventRequest>> => {
    return fetchHelper.put(
        `${baseUrl()}/services/employers/v1/eventrequests/${request.id}/publish`,
        request
    );
};

export const del = (request: EntityRequest): ObsApiExpected<ApiResponse<EventRequest>> => {
    return fetchHelper.del(`${baseUrl()}/services/employers/v1/eventrequests/${request.id}`);
};

export const cancel = (request: EntityRequest): ObsApiExpected<ApiResponse<CareerHubEvent>> => {
    return fetchHelper.post(`${baseUrl()}/services/employers/v1/events/${request.id}/cancel`, null);
};

export const bannerUpload = (
    filename: string,
    blob: Blob
): ObsApiExpected<ApiResponse<{ key: string }>> => {
    const formData = new FormData();
    formData.append('file', blob, filename);
    return fetchHelper.post(`${baseUrl()}/services/employers/v1/banners`, formData);
};
