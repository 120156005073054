import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../../../../shared/components/icon/Icon';
import { createDownloadUrl } from '../../../../shared/helpers/download.helper';
import { useJobAttachments } from '../../hooks/job-attachments.hook';

interface Props {
    jobId: number;
}

export const JobDetailsAttachments = ({ jobId }: Props): React.ReactElement<any, any> | null => {
    const attachments = useJobAttachments(jobId);

    return attachments.length === 0 ? null : (
        <div className="mb-2">
            <h2 className="font-weight-normal">Attachments</h2>
            {attachments.map(attach => (
                <div key={attach.storedFileId} className="d-flex align-items-center">
                    <a className="btn btn-link pl-0" href={createDownloadUrl(attach.storedFileId)}>
                        <Icon icon={faFileDownload}>{attach.title}</Icon>
                    </a>
                </div>
            ))}
        </div>
    );
};
