import { Button, Modal } from 'react-bootstrap';
import { IdentitierType } from 'src/ui/shared/helpers/identifier.helper';
import { BaseModal } from './BaseModal';

interface Props extends IdentitierType {
    onConfirm: () => any;
    onCancel: () => any;
    loading: boolean;
    id?: string;
    body?: string;
}

export const DeleteModal = ({
    onCancel,
    onConfirm,
    loading,
    id,
    body,
}: Props): React.ReactElement<any, any> | null => {
    const message = body || 'Are you sure?';

    return (
        <BaseModal loading={loading} id={id}>
            <Modal.Header>
                <Modal.Title>Confirm</Modal.Title>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={onCancel}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="danger" className="ml-0" onClick={onConfirm}>
                    OK
                </Button>
            </Modal.Footer>
        </BaseModal>
    );
};
