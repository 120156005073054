import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { AlertWrapper } from '../../../shared/components/bootstrap/alerts/AlertWrapper';
import { CardWrapper } from '../../../shared/components/bootstrap/cards/CardWrapper';
import { Loader } from '../../../shared/components/loader/Loader';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';
import { SettingsForm } from '../components/forms/SettingsForm';

export const SettingsPage = (): React.ReactElement<any, any> | null => {
    const {
        request: { fetch },
        value,
    } = useRootSelector(state => state.settings);

    return (
        <ApplicationPageContent>
            <CardWrapper>
                <Loader state={fetch}>
                    <AlertWrapper variant="info" icon={faInfoCircle}>
                        System and automatic emails cannot be unsubscribed
                    </AlertWrapper>
                    {value && <SettingsForm settings={value} />}
                </Loader>
            </CardWrapper>
        </ApplicationPageContent>
    );
};
