import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { useLogout } from 'src/ui/features/authentication/hooks/logout.hook';
import { AlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/AlertWrapper';
import { CardWrapper } from 'src/ui/shared/components/bootstrap/cards/CardWrapper';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

interface Props {
    children: React.ReactNode;
}

export const CentralReauthenticationGuard = ({ children }: Props): React.ReactElement<any, any> => {
    const requiresReauthentication = useRootSelector(
        state => state.central.requiresReauthentication
    );
    const isImpersonation = useRootSelector(state => state.authentication.isImpersonation);

    const { logout } = useLogout();

    const message = isImpersonation
        ? 'You cannot perform this action while impersonating via CareerHub. You need to impersonate via Identity.'
        : 'Performing this action requires you to reauthenticate.';

    if (!requiresReauthentication) {
        return <>{children}</>;
    }

    const onClick = () => {
        logout();
    };

    return (
        <CardWrapper>
            <AlertWrapper variant="info" icon={faCircleInfo}>
                <p>{message}</p>
            </AlertWrapper>
            <Button onClick={onClick}>PLease click here to reauthenticate</Button>
        </CardWrapper>
    );
};
