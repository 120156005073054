export const EVENT_STATUS_OPTIONS = ['None', 'Upcoming', 'Active', 'Cancelled', 'Expired'] as const;
type EventStatusTuple = typeof EVENT_STATUS_OPTIONS;
export type EventStatus = EventStatusTuple[number];

export const EVENT_REQUEST_STATUS_OPTIONS = [
    'Unknown',
    'Draft',
    'Pending',
    'Rejected',
    'Approved',
] as const;
type EventRequestStatusTuple = typeof EVENT_REQUEST_STATUS_OPTIONS;
export type EventRequestStatus = EventRequestStatusTuple[number];

export const EVENT_UNION_STATUS_OPTIONS = [
    ...EVENT_STATUS_OPTIONS,
    ...EVENT_REQUEST_STATUS_OPTIONS,
] as const;
type EventUnionStatusTuple = typeof EVENT_UNION_STATUS_OPTIONS;
export type EventUnionStatus = EventUnionStatusTuple[number];

export const EVENT_BOOKING_STATUS_OPTIONS = ['Unspecified', 'Attended', 'Absent'] as const;
type EventBookingStatusTuple = typeof EVENT_BOOKING_STATUS_OPTIONS;
export type EventBookingStatus = EventBookingStatusTuple[number];
