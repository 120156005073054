import { format, isValid } from 'date-fns';
import { enAU, enCA, enGB, enUS, enZA } from 'date-fns/locale';
import { CountryCode } from 'src/clients-internal/configuration.model';
import { COUNTRY_REGION_META } from 'src/constants/region.constants';

export type DatePickerLocale = 'en-AU' | 'en-GB' | 'en-US' | 'en-CA' | 'en-ZA';

const getStringLocale = (countryCode: string) => {
    return COUNTRY_REGION_META[countryCode as CountryCode]?.dateLocale || 'en-AU';
};

// todo todo todo, this needs to be regionalised, not all imported at once!
const getClientLocale: (locale: DatePickerLocale | undefined) => Locale = locale => {
    switch (locale) {
        case 'en-AU':
            return enAU;
        case 'en-GB':
            return enGB;
        case 'en-US':
            return enUS;
        case 'en-CA':
            return enCA;
        case 'en-ZA':
            return enZA;
        default:
            return enAU;
    }
};

const dateFormat = function (
    date: number | Date | string,
    locale: DatePickerLocale,
    formatStr = 'P'
): string {
    if (typeof date === 'string') {
        const parsedDate = new Date(date);
        return isValid(parsedDate) ? dateFormat(parsedDate, locale, formatStr) : 'Invalid Date';
    }

    return format(date, formatStr, { locale: getClientLocale(locale) });
};

const apiFormat = 'yyyy-MM-dd';

export const datePickerHelper = {
    format: dateFormat,
    getClientLocale,
    getStringLocale,
    apiFormat,
};
