import { Table } from 'react-bootstrap';
import { EventBooking } from 'src/models/events/event-booking.model';
import { DateDisplay } from 'src/ui/shared/components/details/DateDisplay';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { ListPaginationWrapper } from 'src/ui/shared/components/page-components/ListPaginationWrapper';
import { useActiveEntityList } from 'src/ui/shared/hooks/active-entity-list.hook';
import { EventDetailsPageContent } from '../components/details/EventDetailsPageContent';
// import { ListPaginationWrapper } from 'src/ui/shared/components/page-components/ListPaginationWrapper';

const EventBookingNameDisplay = ({
    booking,
}: {
    booking: EventBooking;
}): React.ReactElement<any, any> | null => {
    return !booking.firstName ? (
        <i>Anonymous</i>
    ) : (
        <>
            {booking.firstName} {booking.lastName}
        </>
    );
};

export const EventBookingListPage = (): React.ReactElement<any, any> | null => {
    const {
        entities: eventBookings,
        fetch,
        pagination,
    } = useActiveEntityList(state => state.eventBookings);

    return (
        <EventDetailsPageContent>
            <div className="border-left border-right border-bottom">
                <ListPaginationWrapper pagination={pagination} />
                <Loader state={fetch}>
                    <Table responsive striped variant="light">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Booked</th>
                            </tr>
                        </thead>
                        <tbody>
                            {eventBookings.map(i => (
                                <tr key={i.id}>
                                    <td>
                                        <EventBookingNameDisplay booking={i} />
                                    </td>
                                    <td>
                                        <DateDisplay date={i.added} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    {/* <ListGroupWrapper items={eventBookings}>
                    {eventBookings.map(i => (
                        <EventBookingListItem key={i.id} booking={i} />
                    ))}
                </ListGroupWrapper> */}
                </Loader>
            </div>
        </EventDetailsPageContent>
    );
};
