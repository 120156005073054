import { Badge } from 'react-bootstrap';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';
import { ContactIdentity } from '../../../../../models/authentication/identity.model';

interface Props {
    identity: ContactIdentity;
}

export const ContactIdentityBadges = ({ identity }: Props): React.ReactElement<any, any> | null => {
    const config = useApiConfiguration();

    return (
        <>
            {identity.organisationRejected && (
                <Badge className="ml-2" variant="outline-danger">
                    Rejected
                </Badge>
            )}
            {!identity.contactActive && (
                <Badge className="ml-2" variant="outline-danger">
                    Contact inactive
                </Badge>
            )}
            {!identity.organisationActive && config.value?.settings?.hasDisabledOrganisationFix && (
                <Badge className="ml-2" variant="outline-danger">
                    Disabled
                </Badge>
            )}
        </>
    );
};
