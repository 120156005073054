import { createReducer } from 'typesafe-actions';
import { Organisation } from '../../../models/organisation/organisation.model';
import { EntitySingleState } from '../../../models/store-models/entity-single-state.model';
import * as actions from './organisation.actions';
import { singleReducerHandler } from '../../reducer-handlers/single.reducer-handler';
import { singleEntityState } from '../../helpers/initial-state.helper';
import { LoaderState } from 'src/models/errors/error.model';

export type OrganisationState = EntitySingleState<Organisation> & {
    logoState: LoaderState;
};
const initialState: OrganisationState = { ...singleEntityState, logoState: { loading: false } };

const organisationReducer = createReducer(initialState)
    .handleAction(actions.organisationAsync.request, singleReducerHandler.request)
    .handleAction(actions.organisationAsync.success, singleReducerHandler.success)
    .handleAction(actions.organisationAsync.cancel, singleReducerHandler.cancel)
    .handleAction(actions.organisationAsync.failure, singleReducerHandler.failure)
    .handleAction(actions.organisationUpdateAsync.request, singleReducerHandler.updateRequest)
    .handleAction(actions.organisationUpdateAsync.failure, singleReducerHandler.updateFailure)
    .handleAction(actions.organisationUpdateAsync.success, singleReducerHandler.success)

    .handleAction([actions.organisationLogoAsync.request, actions.organisationLogoDeleteAsync.request], state => ({
        ...state,
        logoState: { loading: true }
    }))
    .handleAction([actions.organisationLogoAsync.success, actions.organisationLogoDeleteAsync.success], (state, action) => ({
        ...singleReducerHandler.success(state, action),
        logoState: { loading: false }
    }))
    .handleAction([actions.organisationLogoAsync.failure, actions.organisationLogoDeleteAsync.failure], (state, action) => ({
        ...state,
        logoState: { loading: false, error: action.payload }
    }))

    .handleAction(actions.organisationResetState, () => initialState);

export default organisationReducer;
