const toTitleCase = (str: string) => {
    if (str.length === 0) {
        return str;
    }

    return str.charAt(0).toUpperCase() + str.substr(1);
};

export const stringHelper = {
    toTitleCase,
};
