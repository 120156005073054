import { EmployerIdentity } from '../../../../../models/authentication/identity.model';
import { Badge } from 'react-bootstrap';

interface Props {
    identity: EmployerIdentity;
}

export const UserIdentityBadges = ({ identity }: Props): React.ReactElement<any, any> | null => {
    return (
        <>{!identity.userActive && <Badge variant="outline-danger ml-2">User inactive</Badge>}</>
    );
};
