import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { routeEpics } from 'src/logic/helpers/epics/entity-route.epic-helper';
import { getRouteMatch } from 'src/logic/helpers/epics/location.epic-helper';
import { ContactPageBaseParams, contactPaths } from 'src/routes/employer/paths/contact.paths';
import { contactAsync, contactSetActive } from '../contact.actions';

export const contactRouteEpics = routeEpics<ContactPageBaseParams>({
    getMatch: locationAction =>
        getRouteMatch(locationAction.payload.location, contactPaths.details),
    getListState: state => state.contacts,
    cancelActionCreator: contactAsync.cancel,
    getId: match => Number(match.params.contactId),
    setActiveActionCreator: contactSetActive,
    requestActionCreator: contactAsync.request,
});

export const contactEpic = asyncEpicBase(
    contactAsync,
    ({ api }, { payload }) => api.contacts.single(payload),
    {
        success: result => contactAsync.success(result.json),
        failure: (error, ra) => contactAsync.failure(error, ra.payload),
    },
    {
        cancelFilter: (cancelAction, requestAction) =>
            cancelAction.payload.id === requestAction.payload.id,
    }
);
