import { ApiResponse } from 'src/models/api/response.model';
import { ErrorNormalized } from 'src/models/errors/error.model';
import { UploadKey, UploadCreate } from 'src/models/uploads/upload.model';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const uploadCreateAsync = createAsyncAction(
    'UPLOAD_CREATE:REQUEST',
    'UPLOAD_CREATE:SUCCESS',
    'UPLOAD_CREATE:FAILURE'
)<UploadCreate, ApiResponse<UploadKey>, ErrorNormalized>();

export const uploadResetState = createAction('UPLOAD_RESET_STATE')();
