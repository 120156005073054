import { Job } from '../../../../../models/jobs/job.model';
import { jobPathCreator } from '../../../../../routes/employer/paths/job.paths';
import { BreadcrumbLink } from '../../../../shared/components/breadcrumbs/BreadcrumbLink';

interface Props {
    job: Job;
}

export const JobDetailsBreadcrumb = ({ job }: Props): React.ReactElement<any, any> | null => (
    <BreadcrumbLink
        to={jobPathCreator.details(job.id)}
        className="text-long-title"
        title={job.title}
    >
        {job.title}
    </BreadcrumbLink>
);
