import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { redirectEpic } from 'src/logic/helpers/epics/redirect.epic-helper';
import { contactPathCreator, contactPaths } from '../../../../routes/employer/paths/contact.paths';
import { contactCreateAsync, contactUpdateAsync } from '../contact.actions';

export const contactCreateEpic = asyncEpicBase(
    contactCreateAsync,
    ({ api }, { payload }) => api.contacts.create(payload),
    {
        success: result => contactCreateAsync.success(result.json),
        failure: (error, ra) => contactCreateAsync.failure(error, ra.payload),
    }
);

export const contactUpdateEpic = asyncEpicBase(
    contactUpdateAsync,
    ({ api }, { payload }) => api.contacts.update(payload),
    {
        success: result => contactUpdateAsync.success(result.json),
        failure: (error, ra) => contactUpdateAsync.failure(error, ra.payload),
    }
);

export const contactCreateUpdateRedirectEpic = redirectEpic({
    when: [contactCreateAsync.success, contactUpdateAsync.success],
    from: [contactPaths.add, contactPaths.edit],
    to: contactPathCreator.list({ isActive: true }),
});
