import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { memo, useMemo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Icon, IconDefinitionAll } from '../icon/Icon';

export type LinkWrapperOptions = 'dropdown' | 'list';

export interface LinkWrapperProps {
    to: string;
    icon?: IconDefinitionAll;
    iconSize?: SizeProp;
    iconClassName?: string;
    as: LinkWrapperOptions;
    className?: string;
    exact?: boolean;
    onClick?: () => void;
    ignoreActive?: boolean;
    children?: React.ReactNode;
}

// eslint-disable-next-line react/display-name
export const LinkWrapper = memo((props: LinkWrapperProps) => {
    const {
        to,
        icon,
        as,
        children,
        className,
        iconSize,
        exact,
        onClick,
        ignoreActive,
        iconClassName,
    } = props;

    const match = useRouteMatch({
        path: to.split('?')[0],
        exact: exact,
        sensitive: false,
    });

    const classNameExt = useMemo(() => {
        switch (as) {
            case 'dropdown':
                return `dropdown-item ${className || ''}`;
            case 'list':
                return `list-group-item-action list-group-item ${
                    match && !ignoreActive ? 'active' : ''
                } ${className || ''}`;
            default:
                throw new Error('Not Implemented');
        }
    }, [match, as, className, ignoreActive]);

    return (
        <Link to={to} className={classNameExt} onClick={onClick}>
            <div className="d-flex align-items-center">
                {icon && (
                    <Icon icon={icon} size={iconSize} iconClassName={iconClassName}>
                        {children}
                    </Icon>
                )}
                {!icon && <div>{children}</div>}
            </div>
        </Link>
    );
});
