import { faBell, faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { AlertStrings } from 'src/clients-internal/strings.model';
import { Markdown } from '../../details/Markdown';
import { AlertWrapper } from './AlertWrapper';

interface Props {
    alerts: AlertStrings;
}

export const StringsAlertWrapper = ({
    alerts: { info, warning, danger },
}: Props): React.ReactElement<any, any> | null => {
    return (
        <>
            {danger && (
                <AlertWrapper variant="danger" icon={faExclamationTriangle}>
                    <Markdown source={danger} />
                </AlertWrapper>
            )}
            {warning && (
                <AlertWrapper variant="warning" icon={faBell}>
                    <Markdown source={warning} />
                </AlertWrapper>
            )}
            {info && (
                <AlertWrapper variant="info" icon={faInfoCircle}>
                    <Markdown source={info} />
                </AlertWrapper>
            )}
        </>
    );
};
