import { IconDefinition as IconDefinitionBrands } from '@fortawesome/free-brands-svg-icons';
import { IconDefinition as IconDefinitionCore, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition as IconDefinitionRegular } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type IconDefinitionAll = IconDefinitionCore | IconDefinitionBrands | IconDefinitionRegular;

interface IconProps {
    icon: IconDefinitionAll;
    size?: SizeProp;
    title?: string;
    iconClassName?: string;
    className?: string;
    children?: React.ReactNode;
}

export const Icon = ({
    icon,
    size,
    title,
    children,
    iconClassName,
    className,
}: IconProps): React.ReactElement<any, any> | null => (
    <>
        <FontAwesomeIcon title={title} icon={icon} size={size} className={iconClassName} />
        {children && (
            <span className={`ml-2 ${className || ''}`} title={title}>
                {children}
            </span>
        )}
    </>
);
