import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { LocationModel } from 'src/models/locations/location.model';
import { useCountryList } from 'src/ui/shared/hooks/country-list.hook';
import { Icon } from 'src/ui/shared/components/icon/Icon';

const locationToAddressLabel = (location: LocationModel, countryName: string | undefined) => {
    const parts = [
        location.street,
        location.city,
        location.region,
        location.postcode,
        countryName || location.countryCode,
    ].filter(v => v);

    if (parts.length === 1) {
        return `${parts[0]!} (Nationwide)`;
    }

    return parts.join(', ');
};

export const LocationView = ({ value }: { value: LocationModel }) => {
    const { list } = useCountryList();
    const country = list?.find(i => i.code === value.countryCode);

    return <Icon icon={faMapMarkerAlt}>{locationToAddressLabel(value, country?.name)}</Icon>;
};
