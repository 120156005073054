import { EndorsementBadgeShape } from 'src/models/endorsements/endorsement.model';
import { Burst } from '../shapes/Burst';
import { Circle } from '../shapes/Circle';
import { Cloud } from '../shapes/Cloud';
import { Hexagon } from '../shapes/Hexagon';
import { Octagon } from '../shapes/Octagon';
import { Shield } from '../shapes/Shield';
import { Square } from '../shapes/Square';
import styles from '../styles/endorsement-badge.module.scss';

interface Props {
    shape: EndorsementBadgeShape;
}

export const EndorsementShape = ({ shape }: Props): React.ReactElement<any, any> | null => {
    switch (shape) {
        case 'Burst':
            return <Burst className={styles.shape} />;
        case 'Circle':
            return <Circle className={styles.shape} />;
        case 'Cloud':
            return <Cloud className={styles.shape} />;
        case 'Hexagon':
            return <Hexagon className={styles.shape} />;
        case 'Octagon':
            return <Octagon className={styles.shape} />;
        case 'Shield':
            return <Shield className={styles.shape} />;
        case 'Square':
            return <Square className={styles.shape} />;
        default:
            return <Circle className={styles.shape} />;
    }
};
