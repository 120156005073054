import { IdentityRouteGuard } from 'src/ui/features/authentication/components/IdentityRouteGuard';
import { ThinLayout } from '../../ui/shared/components/layout/ThinLayout';
import { RegisterRouter } from './RegisterRouteList';

export const RegisterRouteLayout = () => (
    <IdentityRouteGuard>
        <ThinLayout>
            <RegisterRouter />
        </ThinLayout>
    </IdentityRouteGuard>
);
