import { ApiResponse } from 'src/models/api/response.model';
import { ErrorNormalized } from 'src/models/errors/error.model';
import { EntitySingleState } from 'src/models/store-models/entity-single-state.model';
import { PayloadAction } from 'typesafe-actions';

const request = <TEnity, TState extends EntitySingleState<TEnity>>(
    state: TState
): Readonly<TState> => ({
    ...state,
    request: {
        ...state.request,
        fetch: { loading: true },
    },
});

const success = <TEnity, TState extends EntitySingleState<TEnity>>(
    state: TState,
    action: Pick<PayloadAction<any, ApiResponse<TEnity>>, 'payload'>
): Readonly<TState> => ({
    ...state,
    request: {
        ...state.request,
        fetch: { loading: false },
        update: { loading: false },
    },
    value: action.payload.data,
});

const updateRequest = <TEnity, TState extends EntitySingleState<TEnity>>(
    state: TState
): Readonly<TState> => ({
    ...state,
    request: {
        ...state.request,
        update: { loading: true },
    },
});

const failure = <TEnity, TState extends EntitySingleState<TEnity>>(
    state: TState,
    action: Pick<PayloadAction<any, ErrorNormalized>, 'payload'>
): Readonly<TState> => ({
    ...state,
    request: {
        ...state.request,
        fetch: { loading: false, error: action.payload },
    },
});

const updateFailure = <TEnity, TState extends EntitySingleState<TEnity>>(
    state: TState,
    action: Pick<PayloadAction<any, ErrorNormalized>, 'payload'>
): Readonly<TState> => ({
    ...state,
    request: {
        ...state.request,
        update: { loading: false, error: action.payload },
    },
});

const cancel = <TEnity, TState extends EntitySingleState<TEnity>>(
    state: TState
): Readonly<TState> => ({
    ...state,
    request: {
        ...state.request,
        fetch: { loading: false },
    },
});

export const singleReducerHandler = {
    request,
    cancel,
    success,
    failure,
    updateRequest,
    updateFailure,
};
