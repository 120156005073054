import { CardWrapper } from '../../../../shared/components/bootstrap/cards/CardWrapper';
import { RegisterWizardTabs } from './RegisterWizardTabs';

interface Props {
    children: React.ReactNode;
}

export const RegisterWizardWrapper = ({ children }: Props): React.ReactElement<any, any> | null => {
    return (
        <div className="tab-wrapper">
            <RegisterWizardTabs />
            <CardWrapper className="force-rounded-bottom">{children}</CardWrapper>
        </div>
    );
};
