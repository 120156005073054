import { LOCATION_CHANGE } from 'connected-react-router';
import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { delayTypeUntilAuthenticated } from 'src/logic/helpers/epics/app-init.epic-helper';
import { asyncEpicStandard } from 'src/logic/helpers/epics/async.epic-helper';
import { isLocationRouteMatch } from 'src/logic/helpers/epics/location.epic-helper';
import { eventPaths } from 'src/routes/employer/paths/event.paths';
import { RootEpic } from '../epic.root-index';
import * as actions from './skill.actions';

export const skillListRouteEpic: RootEpic = (action$, state$, services) => {
    return delayTypeUntilAuthenticated(action$, state$, LOCATION_CHANGE).pipe(
        filter(isLocationRouteMatch(eventPaths.list)),
        // ToDo: extract to common helper
        filter(() => {
            if (state$.value.configuration.value.disableVersionChecks) {
                return true;
            }
            const { major, minor } = state$.value.apiConfiguration.version;
            if (major > 5) {
                return true;
            }
            return major === 5 && minor >= 10;
        }),
        filter(() => !state$.value.skills.activeList.fetch.loading),
        filter(
            () => state$.value.skills.list[state$.value.skills.activeList.requestId] === undefined
        ),
        mergeMap(() => of(actions.skillListAsync.request({})))
    );
};

export const skillListAsyncEpic = asyncEpicStandard(actions.skillListAsync, (services, ra) =>
    services.api.skills.list()
);
