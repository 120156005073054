import { BBox2D } from '@symplicity/geocoding';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { LocationModel } from 'src/models/locations/location.model';
import { geolocationToBBox } from '../helpers/geolocation.helpers';
import { LocationFields } from './LocationFields';
import { LocationInputGroup } from './LocationInputGroup';
import { LocationMap } from './LocationMap';
import { LocationView } from './LocationView';

interface Props {
    defaultValues: LocationModel | undefined;
}

export const AddressFieldsetGeo = (props: Props): ReactElement => {
    const defaultValues = props.defaultValues || {};
    const {
        setValue,
        formState: { errors },
    } = useFormContext<LocationModel>();

    const [showFields, setShowFields] = useState<boolean>(
        !defaultValues.region &&
            !defaultValues.city &&
            !defaultValues.postcode &&
            !defaultValues.street
    );
    const [bboxes, setBBoxes] = useState<BBox2D[]>(
        defaultValues.geolocation ? [geolocationToBBox(defaultValues.geolocation)] : []
    );

    // reset to default values
    const onRemove = useCallback(() => {
        setValue('region', '');
        setValue('city', '');
        setValue('postcode', '');
        setValue('street', '');
        setValue('geolocation', undefined);
        setShowFields(true);
    }, [setValue]);

    const onChange = useCallback((val: LocationModel) => {
        setBBoxes(val.geolocation ? [geolocationToBBox(val.geolocation)] : []);
    }, []);

    useEffect(() => {
        if (showFields) {
            return;
        }

        if (Object.keys(errors).length > 0) {
            setShowFields(true);
        }
    }, [errors, showFields]);

    return (
        <fieldset>
            <legend>Address</legend>
            <div className="mb-2">
                <LocationMap bboxes={bboxes} />
            </div>
            <div className="mb-2">
                {showFields ? (
                    <LocationFields
                        defaultValues={{ countryCode: defaultValues.countryCode }}
                        onChange={onChange}
                        required={true}
                    />
                ) : (
                    <LocationInputGroup variant="light" onRemove={onRemove}>
                        <LocationView value={defaultValues} />
                    </LocationInputGroup>
                )}
            </div>
            <hr />
        </fieldset>
    );
};
