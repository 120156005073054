import { createAction, createAsyncAction } from 'typesafe-actions';
import {
    EntityPageRequest,
    EntityRequest,
    EntityRequestOrUndefined,
} from '../../../models/api/request.model';
import {
    ApiResponse,
    ApiListResponse,
    ApiSearchResponse,
} from '../../../models/api/response.model';
import { Contact } from '../../../models/contacts/contact.model';
import { ErrorNormalized } from '../../../models/errors/error.model';
import { ActiveListState } from '../../../models/store-models/entity-list-state.model';
import { ContactPageRequest } from './contact.service';

export const contactResetState = createAction('CONTACT_RESET_STATE')();
export const contactSetActive = createAction('CONTACT:SET_ACTIVE')<EntityRequestOrUndefined>();

export const contactListSetActive =
    createAction('CONTACT_LIST:SET_ACTIVE')<ActiveListState<EntityPageRequest>>();
export const contactListClear = createAction('CONTACT_LIST:CLEAR')<{ excludeActive: boolean }>();

export const contactAllAsync = createAsyncAction(
    'CONTACT_ALL:REQUEST',
    'CONTACT_ALL:SUCCESS',
    'CONTACT_ALL:FAILURE',
    'CONTACT_ALL:CANCEL'
)<undefined, ApiListResponse<Contact>, ErrorNormalized, undefined>();

export const contactListAsync = createAsyncAction(
    'CONTACT_LIST:REQUEST',
    'CONTACT_LIST:SUCCESS',
    'CONTACT_LIST:FAILURE',
    'CONTACT_LIST:CANCEL'
)<
    EntityPageRequest,
    ApiSearchResponse<Contact, ContactPageRequest>,
    [ErrorNormalized, ContactPageRequest],
    undefined
>();

export const contactAsync = createAsyncAction(
    'CONTACT:REQUEST',
    'CONTACT:SUCCESS',
    'CONTACT:FAILURE',
    'CONTACT:CANCEL'
)<EntityRequest, ApiResponse<Contact>, [ErrorNormalized, EntityRequest], EntityRequest>();

export const contactCreateAsync = createAsyncAction(
    'CONTACT_CREATE:REQUEST',
    'CONTACT_CREATE:SUCCESS',
    'CONTACT_CREATE:FAILURE'
)<Contact, ApiResponse<Contact>, [ErrorNormalized, Contact]>();

export const contactUpdateAsync = createAsyncAction(
    'CONTACT_UPDATE:REQUEST',
    'CONTACT_UPDATE:SUCCESS',
    'CONTACT_UPDATE:FAILURE'
)<Contact, ApiResponse<Contact>, [ErrorNormalized, Contact]>();

export const contactDeleteAsync = createAsyncAction(
    'CONTACT_DELETE:REQUEST',
    'CONTACT_DELETE:SUCCESS',
    'CONTACT_DELETE:FAILURE'
)<EntityRequest, ApiResponse<Contact>, [ErrorNormalized, EntityRequest]>();

export const contactSetPrimaryAsync = createAsyncAction(
    'CONTACT_SET_PRIMARY:REQUEST',
    'CONTACT_SET_PRIMARY:SUCCESS',
    'CONTACT_SET_PRIMARY:FAILURE'
)<Contact, ApiResponse<Contact>, [ErrorNormalized, Contact]>();

export const contactSetPrimarySetActive = createAction(
    'CONTACT_SET_PRIMARY:SET_ACTIVE'
)<EntityRequest>();
