import { useCallback } from 'react';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { LocalCategoryMapV2 } from 'src/models/central/central-mapping.model';
import { getCentralMappingsV2 } from 'src/logic/features/central/helpers/central-mapping.helper';

export const useCentralCategoryMappings = () => {
    const localV2 = useRootSelector(state => state.central.configuration.clientCategoryMap);

    const hasCentralMapping = useCallback(
        (categoryKey: keyof LocalCategoryMapV2, ids: number[]) => {
            return getCentralMappingsV2(localV2, categoryKey, ids).length > 0;
        },
        [localV2]
    );

    return { hasCentralMapping };
};
