import { ContentKey, SiteContext } from '@careerhub/content-shared';
import { ObsApiExpected } from 'src/models/api/service.model';
import { ConfigurationState } from 'src/logic/features/configuration/configuration.reducer';
import { fetchHelper } from '../../helpers/fetch.helper';

export const get = (
    key: ContentKey,
    configurationState: ConfigurationState
): ObsApiExpected<SiteContext> => {
    const client =
        configurationState.value.contentOverrideClientId || configurationState.clientCode;

    return fetchHelper.get(
        `${configurationState.value.contentBasePath}/${client}/${key}.content.json`,
        {
            credentials: 'omit',
        }
    );
};
