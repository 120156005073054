// this is for the endorsement stuff, I have to dynamically load everything
// because I'm not sure which icons are used by which university.

import { Helmet } from 'react-helmet';
import { useEndorsementsIsEnabled } from '../../hooks/endorsements-is-enabled.hook';

export const FontAwesomeScript = (): React.ReactElement<any, any> | null => {
    const { isEnabled } = useEndorsementsIsEnabled();

    if (!isEnabled) {
        return null;
    }

    // see: https://fontawesome.com/v5/docs/web/use-with/wordpress/install-manually
    // and: https://fontawesome.com/account/cdn
    return (
        <Helmet>
            <link
                rel="stylesheet"
                href="https://pro.fontawesome.com/releases/v5.15.4/css/regular.css"
                integrity="sha384-sDvgA98ePLM7diZOYxIrDEITlUxoFxdt0CPuqjdLr/w62pPuOc73uFoigWEnVpDa"
                crossOrigin="anonymous"
            />
            <link
                rel="stylesheet"
                href="https://pro.fontawesome.com/releases/v5.15.4/css/fontawesome.css"
                integrity="sha384-ig3RKyui4sECvuz+WE8EmFYy7sjRvEvy82mmhfV7ljRieb+0f8eEZKxHv2KC0+io"
                crossOrigin="anonymous"
            />
        </Helmet>
    );
};
