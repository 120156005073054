import { ListGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useOuterClick } from 'src/ui/shared/hooks/outer-click.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { sessionShowHistory } from '../../../../logic/features/sessions/session.actions';
import { HistoryItem } from './HistoryItem';

export const HistoryContainer = (): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    const ref = useOuterClick(() => {
        dispatch(sessionShowHistory({ showHistory: false }));
    });

    const { history } = useRootSelector(state => state.session);

    return (
        <div ref={ref}>
            <ListGroup className="mx-3 my-2 overflow-y" style={{ maxHeight: '50vh' }}>
                {history.map(item => (
                    <HistoryItem key={item.id} item={item} />
                ))}
            </ListGroup>
        </div>
    );
};
