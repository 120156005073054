import { useMemo } from 'react';
import { useClient } from './client.hook';

// this is a bit pointless, but whatever, the logo exists in both buckets
export const useLogo = () => {
    const config = useClient();
    const src = useMemo(
        () =>
            config.countryCode === 'AU'
                ? 'https://cdn.content.careerhub.com.au/_core/white_careerhub-central.svg'
                : 'https://cdn.content.careerhub.co.uk/_core/white_careerhub-central.svg',
        [config.countryCode]
    );

    return {
        src,
    };
};
