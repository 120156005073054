import { getClientConfig } from 'src/clients-internal/configuration.init';
import { apiStringify } from 'src/logic/helpers/api-query-string.helper';
import { fetchHelper } from 'src/logic/helpers/fetch.helper';
import { EntityPageRequest } from 'src/models/api/request.model';
import { ApiSearchResponse } from 'src/models/api/response.model';
import { ObsApiExpected } from 'src/models/api/service.model';
import { EventBooking } from 'src/models/events/event-booking.model';

const baseUrl = () => getClientConfig().careerHubBasePath;

export interface EventBookingPageRequest extends EntityPageRequest {
    eventIds: number[];
}

export const list = (
    request: EventBookingPageRequest
): ObsApiExpected<ApiSearchResponse<EventBooking, EventBookingPageRequest>> => {
    return fetchHelper.get(
        `${baseUrl()}/services/employers/v1/eventbookings${apiStringify(request)}`
    );
};
