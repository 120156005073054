import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { registerRequestAccessShow } from 'src/logic/features/register/register.actions';

export const useRequestAccess = () => {
    const dispatch = useDispatch();

    const [requestOrganisationId, setRequestOrganisationId] = useState<number | undefined>(
        undefined
    );

    const onRequestAccessClick = (organisationId: number) => {
        setRequestOrganisationId(organisationId);
        dispatch(registerRequestAccessShow({ show: true }));
    };

    return {
        requestOrganisationId,
        onRequestAccessClick,
    };
};
