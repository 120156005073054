import { Badge, ListGroup } from 'react-bootstrap';
import { ListGroupItemHeader } from 'src/ui/shared/components/bootstrap/lists/ListGroupItemHeader';
import { useAddress } from 'src/ui/shared/hooks/address.hook';
import { useBusinessIdLabel } from 'src/ui/shared/hooks/business-id-validator.hook';
import { CentralSimilarProperty } from './CentralSimilarProperty';
import { SimilarOrganisationModel as CentralSimilarOrganisation } from '@symplicity/central-types';

interface Props {
    organisation: CentralSimilarOrganisation;
    exactSimilarId: number | undefined;
}

export const CentralSimilarOrganisationListItem = ({
    organisation,
    exactSimilarId,
}: Props): React.ReactElement<any, any> | null => {
    const businessIdLabel = useBusinessIdLabel(organisation.countryCode);
    const address = useAddress(organisation);

    return (
        <ListGroup.Item key={organisation.id}>
            <ListGroupItemHeader>
                <ListGroupItemHeader.Title>
                    {organisation.name}
                    {organisation.acronym && <small className="ml-2">{organisation.acronym}</small>}
                </ListGroupItemHeader.Title>
                {exactSimilarId === organisation.id && <Badge variant="danger">Exact match</Badge>}
            </ListGroupItemHeader>
            <CentralSimilarProperty label="Division" value={organisation.division} />
            <CentralSimilarProperty label={businessIdLabel} value={organisation.businessId} />
            <CentralSimilarProperty label="Url" value={organisation.url} />
            <CentralSimilarProperty label="Address" value={address} />
        </ListGroup.Item>
    );
};
