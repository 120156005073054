import { ContentKey } from '@careerhub/content-shared';
import chunk from 'lodash/chunk';
import { Col, ListGroup, Row } from 'react-bootstrap';
import { LinkWrapper } from 'src/ui/shared/components/router/LinkWrapper';
import { contentPathCreator } from 'src/routes/content/content.paths';
import { useContent } from '../hooks/content.hook';
import { ContentMenuSectionItems } from './ContentMenuSectionItems';
import { Fragment } from 'react';

interface Props {
    contentKey: ContentKey;
}

export const ContentMenuItems = ({ contentKey }: Props): React.ReactElement<any, any> | null => {
    const { orderedCollections } = useContent(contentKey);

    const chunks = chunk(orderedCollections, 3);

    return (
        <>
            {chunks.map((collections, index) => (
                <Fragment key={index}>
                    <Row className="align-items-end">
                        {collections.map(collection => (
                            <Col xs={12} lg={4} key={collection.title}>
                                <ListGroup variant="flush">
                                    <LinkWrapper
                                        as="list"
                                        ignoreActive={true}
                                        className="text-primary border-primary h2 px-1 border-bottom"
                                        to={contentPathCreator.contentPage(contentKey, collection)}
                                    >
                                        {collection.title}
                                    </LinkWrapper>
                                    <ContentMenuSectionItems
                                        collection={collection}
                                        contentKey={contentKey}
                                        className="d-block d-lg-none"
                                    />
                                </ListGroup>
                            </Col>
                        ))}
                    </Row>
                    <Row className="d-none d-lg-flex">
                        {collections.map(collection => (
                            <Col xs={12} lg={4} key={collection.title}>
                                <ListGroup variant="flush">
                                    <ContentMenuSectionItems
                                        collection={collection}
                                        contentKey={contentKey}
                                    />
                                </ListGroup>
                            </Col>
                        ))}
                    </Row>
                </Fragment>
            ))}
        </>
    );
};
