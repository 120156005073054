import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { contactUpdateAsync } from 'src/logic/features/contacts/contact.actions';
import { Contact } from 'src/models/contacts/contact.model';
import { useIdentityContact } from 'src/ui/features/authentication/hooks/identity-contact.hook';
import { useIsIndividual } from 'src/ui/features/authentication/hooks/is-individual.hook';
import { AlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/AlertWrapper';
import { ThinLayout } from 'src/ui/shared/components/layout/ThinLayout';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { ThinPageContent } from 'src/ui/shared/components/page-components/ThinPageContent';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useAllContacts } from '../../hooks/all-contacts.hook';
import { ContactForm, contactFormSchema } from '../forms/ContactForm';

interface Props {
    children?: React.ReactNode;
}

export const ContactValidationGuard = ({
    children,
}: Props): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    const isIndividual = useIsIndividual();
    const { identityContact } = useIdentityContact();
    const { fetch } = useAllContacts();
    const [hasSkipped, setHasSkipped] = useState(false);
    const updateState = useRootSelector(state => {
        if (!identityContact) {
            return undefined;
        }

        return state.contacts.single[identityContact.id]?.update;
    });

    const isValid = useMemo(() => {
        if (isIndividual || !identityContact) {
            return true;
        }

        const result = contactFormSchema.safeParse(identityContact);
        return result.success;
    }, [identityContact, isIndividual]);

    const onSubmit = (data: Contact) => {
        dispatch(contactUpdateAsync.request(data));
    };

    const onCancel = () => {
        setHasSkipped(true);
    };

    if (isValid || hasSkipped) {
        return <>{children}</>;
    }

    return (
        <Loader state={fetch}>
            <ThinLayout>
                <ThinPageContent>
                    <AlertWrapper variant="info" icon={faInfoCircle}>
                        Your contact details are incomplete. Please complete the following form to
                        continue.
                    </AlertWrapper>
                    <div className="p-3 border">
                        <ContactForm
                            contact={identityContact}
                            onSubmit={onSubmit}
                            cancelText="Continue without updating"
                            onCancel={onCancel}
                            error={updateState?.error}
                            showSpinner={updateState?.loading}
                            validateOnLoad
                        />
                    </div>
                </ThinPageContent>
            </ThinLayout>
        </Loader>
    );
};
