import { createReducer } from 'typesafe-actions';
import { LoaderState } from '../../../models/errors/error.model';
import { Job } from '../../../models/jobs/job.model';
import { EntityListState } from '../../../models/store-models/entity-list-state.model';
import { JobPageRequest } from './job.service';
import * as actions from './job.actions';
import { entityListInitialState } from '../../helpers/initial-state.helper';
import { listSingleReducerHandler } from '../../reducer-handlers/list-single.reducer-handler';
import { listReducerHandler } from '../../reducer-handlers/list.reducer-handler';

export type JobState = EntityListState<Job, JobPageRequest> &
    Readonly<{
        changeRequests: {
            [id: number]:
                | {
                      create: LoaderState;
                      created: boolean;
                  }
                | undefined;
        };
        republishId?: number;
        showPublishConfirmation: boolean;
    }>;

const initialState: JobState = {
    ...entityListInitialState,
    changeRequests: {},
    showPublishConfirmation: false,
};

const jobReducer = createReducer(initialState)
    .handleAction(actions.jobSetActive, listSingleReducerHandler.setActive)
    .handleAction(actions.jobListSetActive, listReducerHandler.setActive)

    .handleAction(actions.jobListAsync.request, listReducerHandler.request)
    .handleAction(actions.jobListAsync.success, listReducerHandler.success)
    .handleAction(actions.jobListAsync.cancel, listReducerHandler.cancel)
    .handleAction(actions.jobListAsync.failure, listReducerHandler.failure)
    .handleAction(actions.jobListClear, listReducerHandler.clear)

    .handleAction(actions.jobAsync.request, listSingleReducerHandler.request)
    .handleAction(actions.jobAsync.success, listSingleReducerHandler.success)
    .handleAction(actions.jobAsync.cancel, listSingleReducerHandler.cancel)
    .handleAction(actions.jobAsync.failure, listSingleReducerHandler.failure)

    .handleAction(actions.jobCreateAsync.request, listSingleReducerHandler.create)
    .handleAction(actions.jobCreateAsync.success, listSingleReducerHandler.success)
    .handleAction(actions.jobCreateAsync.failure, listSingleReducerHandler.createFailure)

    .handleAction(actions.jobUpdateAsync.request, (state, action) =>
        listSingleReducerHandler.updateRequest(state, {
            payload: action.payload.job,
        })
    )
    .handleAction(actions.jobUpdateAsync.success, listSingleReducerHandler.success)
    .handleAction(actions.jobUpdateAsync.failure, listSingleReducerHandler.updateFailure)

    // publish is basically an update
    .handleAction(actions.jobPublishAsync.request, listSingleReducerHandler.updateRequest)
    .handleAction(actions.jobPublishAsync.success, listSingleReducerHandler.success)
    .handleAction(actions.jobPublishAsync.failure, listSingleReducerHandler.updateFailure)

    .handleAction(actions.jobDeleteAsync.request, listSingleReducerHandler.deleteRequest)
    .handleAction(actions.jobDeleteAsync.success, listSingleReducerHandler.deleteSuccess)
    .handleAction(actions.jobDeleteAsync.failure, listSingleReducerHandler.deleteFailure)

    .handleAction(actions.jobWithdrawAsync.request, listSingleReducerHandler.updateRequest)
    .handleAction(actions.jobWithdrawAsync.success, listSingleReducerHandler.success)
    .handleAction(actions.jobWithdrawAsync.failure, listSingleReducerHandler.updateFailure)

    .handleAction(actions.jobChangeRequestAsync.request, (state, action) => ({
        ...state,
        changeRequests: {
            ...state.changeRequests,
            [action.payload.id]: {
                created: false,
                create: { loading: true },
            },
        },
    }))
    .handleAction(actions.jobChangeRequestAsync.success, (state, action) => ({
        ...state,
        changeRequests: {
            ...state.changeRequests,
            [action.payload.data.id]: {
                created: true,
                create: { loading: false },
            },
        },
    }))
    .handleAction(actions.jobChangeRequestAsync.failure, (state, action) => ({
        ...state,
        changeRequests: {
            ...state.changeRequests,
            [action.meta.id]: {
                created: false,
                create: { loading: false, error: action.payload },
            },
        },
    }))
    .handleAction(actions.jobRepublishIdSetActive, (state, action) => ({
        ...state,
        republishId: action.payload.republishId,
    }))
    .handleAction(actions.jobResetState, () => initialState);

export default jobReducer;
