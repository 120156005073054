import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { entityNoteUpdateAsync } from 'src/logic/features/entity-notes/entity-note.actions';
import { EntityNote } from 'src/models/entity-notes/entity-note.model';
import { EventChangeRequestParams } from 'src/routes/employer/paths/event.paths';
import { CardWrapper } from 'src/ui/shared/components/bootstrap/cards/CardWrapper';
import { DateDisplay } from 'src/ui/shared/components/details/DateDisplay';
import { useActiveEntity } from 'src/ui/shared/hooks/active-entity.hook';
import { useFormButton } from 'src/ui/shared/hooks/form-button.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { EntityNoteStatusBadge } from '../components/change-requests/EntityNoteStatusBadge';
import { EventChangeRequestForm } from '../components/change-requests/EventChangeRequestForm';
import { EventDetailsPageContent } from '../components/details/EventDetailsPageContent';

export const EventChangeRequestEditPage = (): React.ReactElement<any, any> | null => {
    const { id: eventId } = useActiveEntity(state => state.eventUnions);
    const { entityNoteId: entityNoteIdStr } = useParams<EventChangeRequestParams>();
    const entityNoteId = Number(entityNoteIdStr);
    const entityNote = useRootSelector(state => state.entityNotes.items[entityNoteId]);
    const state = useRootSelector(state => state.entityNotes.single[entityNoteId]?.update)!;
    const dispatch = useDispatch();

    const onSubmit = (data: EntityNote) => {
        dispatch(entityNoteUpdateAsync.request(data));
    };

    const { onCancel } = useFormButton();

    if (!eventId) {
        // should be impossible
        return null;
    }

    return (
        <EventDetailsPageContent>
            <CardWrapper>
                {entityNote && entityNote.action !== 'None' && entityNote.actionDate && (
                    <>
                        <div className="mb-3">
                            <span className="mr-2">
                                Actioned on <DateDisplay date={entityNote.actionDate} />
                            </span>
                            <EntityNoteStatusBadge entityNote={entityNote} />
                        </div>
                        <hr />
                    </>
                )}
                <EventChangeRequestForm
                    eventId={eventId}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    state={state}
                    entityNote={entityNote}
                />
            </CardWrapper>
        </EventDetailsPageContent>
    );
};
