import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { CardWrapper } from '../bootstrap/cards/CardWrapper';
import { Icon } from '../icon/Icon';

interface Props {
    label: string;
    className?: string;
    children: React.ReactNode;
}

export const ToggleWell = ({
    children,
    label,
    className,
}: Props): React.ReactElement<any, any> | null => {
    const [show, setShow] = useState(false);

    return (
        <CardWrapper bodyClassName="bg-details-alt" className={className}>
            <Button variant="link" className="pl-0" onClick={() => setShow(!show)}>
                <Icon icon={show ? faMinus : faPlus}>{label}</Icon>
            </Button>
            <div className={show ? 'd-block mt-2' : 'd-none'}>{children}</div>
        </CardWrapper>
    );
};
