import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import { ListGroup } from 'react-bootstrap';
import { jobPathCreator } from 'src/routes/employer/paths/job.paths';
import { OrganisationLogoHeaderDisplay } from 'src/ui/features/organisation/components/details/OrganisationLogoHeaderDisplay';
import { WorkGroupLabel } from 'src/ui/features/work-groups/components/WorkGroupLabel';
import { ListGroupItemHeader } from 'src/ui/shared/components/bootstrap/lists/ListGroupItemHeader';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { Job } from '../../../../../models/jobs/job.model';
import { JobActionDropdown } from '../actions/JobActionDropdown';
import { JobStatusBadge } from '../badges/JobStatusBadge';

interface Props {
    job: Job;
    hideActions?: boolean;
}

export const JobListGroupItem = ({
    job,
    hideActions,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <ListGroup.Item>
            <OrganisationLogoHeaderDisplay>
                <ListGroupItemHeader>
                    <ListGroupItemHeader.Title to={jobPathCreator.details(job.id)}>
                        {job.title}
                    </ListGroupItemHeader.Title>
                    <JobStatusBadge job={job} />
                    <ListGroupItemHeader.Actions>
                        {job.externalReference && (
                            <>
                                <Icon icon={faHashtag} />
                                <span className="ml-1">{job.externalReference}</span>
                            </>
                        )}
                        {!hideActions && <JobActionDropdown job={job} size="xs" />}
                    </ListGroupItemHeader.Actions>
                </ListGroupItemHeader>

                <div>{job.summary}</div>
                {job.workGroupId && (
                    <WorkGroupLabel className="mt-2 d-block" workGroupId={job.workGroupId} />
                )}
            </OrganisationLogoHeaderDisplay>
        </ListGroup.Item>
    );
};
