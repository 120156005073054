import { faTag } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { Badge } from 'react-bootstrap';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useActiveEntityList } from 'src/ui/shared/hooks/active-entity-list.hook';

interface Props {
    ids: number[] | undefined;
}

export const SkillBadgeList = ({ ids }: Props): React.ReactElement<any, any> | null => {
    const { entities: skills } = useActiveEntityList(state => state.skills);

    const skillList = useMemo(() => {
        if (!ids) {
            return [];
        }
        return skills.filter(x => ids.includes(x.id));
    }, [ids, skills]);

    return (
        <>
            {/* <Icon icon={faTag} iconClassName="mr-2" /> */}
            {skillList.map(skill => (
                <Badge key={skill.id} variant="outline-dark" className="mr-1">
                    <Icon icon={faTag} />
                    <span className="ml-1">{skill.name}</span>
                </Badge>
            ))}
        </>
    );
};
