import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { CareerHubEvent } from 'src/models/events/event.model';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { OffCampusLocationDisplay } from '../event-locations/OffCampusLocationDisplay';
import { OnCampusLocationDisplay } from '../event-locations/OnCampusLocationDisplay';

type EventLocation = Pick<
    CareerHubEvent,
    'offCampusLocation' | 'onCampusLocationId' | 'onlineLocation'
>;
interface Props {
    item: EventLocation;
}

export const EventLocationDisplay = ({ item }: Props): React.ReactElement<any, any> | null => {
    if (item.onCampusLocationId) {
        return <OnCampusLocationDisplay locationId={item.onCampusLocationId} />;
    }

    if (item.onlineLocation?.url) {
        return <Icon icon={faMapMarkerAlt}>Online: {item.onlineLocation.url}</Icon>;
    }

    if (item.offCampusLocation?.country) {
        return <OffCampusLocationDisplay location={item.offCampusLocation} />;
    }

    return null;
};
