import { ContentKey } from '@careerhub/content-shared';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Scrollspy from 'react-scrollspy';
import { useContentScrollOffset } from '../hooks/content-scroll.hook';
import { useContent } from '../hooks/content.hook';

interface Props {
    contentKey: ContentKey;
}

export const ContentScrollspyNav = ({ contentKey }: Props): React.ReactElement<any, any> | null => {
    const { getSectionTitles, activeCollection } = useContent(contentKey);
    const sectionTitles = getSectionTitles(activeCollection);

    const firstId = sectionTitles.length > 0 ? sectionTitles[0].id : undefined;
    const [scrollspyId, setScrollspyId] = useState<string | undefined>(firstId);
    const location = useLocation();
    const onUpdateTest = useCallback((item: HTMLElement) => {
        setScrollspyId(item?.id);
    }, []);

    const currentSection = useMemo(
        () => sectionTitles.find(s => s.id === scrollspyId),
        [scrollspyId, sectionTitles]
    );

    const contentScrollOffset = useContentScrollOffset();

    useEffect(() => {
        if (sectionTitles.length < 2) {
            setScrollspyId(undefined);
            return;
        }

        setScrollspyId(current =>
            current && sectionTitles.map(i => i.id).includes(current)
                ? current
                : sectionTitles[0].id
        );
    }, [location, sectionTitles]);

    return (
        <>
            <Scrollspy
                items={sectionTitles.map(s => s.id)}
                currentClassName="d-block"
                onUpdate={onUpdateTest}
                offset={contentScrollOffset - 1}
                componentTag={() => <></>}
            />
            {currentSection && sectionTitles.length > 1 && (
                <Dropdown>
                    <Dropdown.Toggle
                        variant="link-content-nav"
                        className="no-icon pl-0 py-0 btn-no-focus h1 mb-0"
                        style={{ fontWeight: 300 }}
                    >
                        <span className="mx-2">|</span>
                        <span>{currentSection.header}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {sectionTitles.map(s => (
                            <Dropdown.Item
                                to={s.to}
                                as={Link}
                                key={s.id}
                                active={scrollspyId === s.id}
                            >
                                {s.header}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </>
    );
};
