import { useFormContext } from 'react-hook-form';

interface Props {
    name?: string;
    forceRender?: boolean;
}

export const HiddenIdControl = ({
    name,
    forceRender,
}: Props): React.ReactElement<any, any> | null => {
    const { watch, register } = useFormContext();
    const controlName = name || 'id';

    const hasId = watch(controlName);

    if (forceRender) {
        return <input type="hidden" {...register(controlName)} />;
    }

    return hasId ? <input type="hidden" {...register(controlName)} /> : null;
};
