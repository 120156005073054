import { useCallback } from 'react';
import { useApiConfiguration } from './api-configuration.hook';

export const useCountryList = () => {
    const {
        fetch,
        includes: { countries },
    } = useApiConfiguration();

    const getName = useCallback(
        (countryCode: string | undefined) => {
            if (!countryCode) {
                return;
            }
            const found = countries && countries.find(i => i.code === countryCode);
            return found?.name;
        },
        [countries]
    );

    return {
        list: countries || [],
        fetch,
        getName,
    };
};
