import { useMemo } from 'react';
import { Badge } from 'react-bootstrap';
import { Organisation } from 'src/models/organisation/organisation.model';
import { useAuthentication } from 'src/ui/features/authentication/hooks/authentication.hook';

interface Props {
    organisation: Organisation;
}

export const OrganisationBadges = ({
    organisation,
}: Props): React.ReactElement<any, any> | null => {
    const {
        isCentralContact,
        determineIfLocalAndCentralOrganisationInPendingState,
        activeIdentity,
    } = useAuthentication();
    const isLocalAndCentralOrganisationInPendingState =
        determineIfLocalAndCentralOrganisationInPendingState(activeIdentity!);

    // this can be simplified in the future (current complete build is 5.*.95540) -
    // hotfix was implemented close to this to add isDistributed. So we can remove the "isCentralContact"
    // after a while
    const isCentral = useMemo(() => {
        return isCentralContact || organisation.isDistributed;
    }, [isCentralContact, organisation.isDistributed]);

    if (isLocalAndCentralOrganisationInPendingState) {
        return (
            <Badge as="h2" className="ml-2 mb-0" variant="outline-warning">
                Pending in Central
            </Badge>
        );
    }
    if (isCentral && organisation.isApproved && !isLocalAndCentralOrganisationInPendingState) {
        return (
            <Badge as="h2" className="ml-2 mb-0" variant="outline-info">
                Managed by Central
            </Badge>
        );
    }

    if (!isCentral && !organisation.isApproved) {
        return (
            <Badge as="h2" className="ml-2 mb-0" variant="outline-warning">
                Pending Approval
            </Badge>
        );
    }

    if (organisation.private) {
        return (
            <Badge as="h2" className="ml-2 mb-0" variant="outline-info">
                Private
            </Badge>
        );
    }

    return null;
};
