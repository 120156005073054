import { ListGroupWrapper } from '../../../shared/components/lists/ListGroupWrapper';
import { Loader } from '../../../shared/components/loader/Loader';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';
import { ListPaginationWrapper } from '../../../shared/components/page-components/ListPaginationWrapper';
import { useActiveEntityList } from '../../../shared/hooks/active-entity-list.hook';
import { ContactListGroupItem } from '../components/list/ContactListGroupItem';
import { ContactListStatusFilter } from '../components/list/ContactListStatusFilter';

export const ContactListPage = (): React.ReactElement<any, any> | null => {
    const { entities: contacts, fetch, pagination } = useActiveEntityList(state => state.contacts);

    return (
        <ApplicationPageContent className="contact-list-page">
            <ListPaginationWrapper pagination={pagination}>
                <ContactListStatusFilter />
            </ListPaginationWrapper>

            <Loader state={fetch} useCard={true}>
                <div>
                    <ListGroupWrapper items={contacts}>
                        {contacts.map(contact => (
                            <ContactListGroupItem key={contact.id} contact={contact} />
                        ))}
                    </ListGroupWrapper>
                </div>
            </Loader>
        </ApplicationPageContent>
    );
};
