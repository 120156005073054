import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { organisationAsync } from '../../../../logic/features/organisations/organisation.actions';
import { useAuthentication } from '../../authentication/hooks/authentication.hook';

export const useOrganisation = () => {
    const {
        isOrganisation,
        isCentralContact,
        centralOrganisationId,
        determineIfLocalAndCentralOrganisationInPendingState,
        activeIdentity,
    } = useAuthentication();

    const organisation = useRootSelector(state => state.organisation.value);
    const { fetch, update } = useRootSelector(state => state.organisation.request);

    const organisationType = useRootSelector(state => state.organisation.value?.employerType);

    const dispatch = useDispatch();

    // this is the only place in the entire application where I use this type of logic
    // it should probably just be loaded on app start, but technically it's not necessary for
    // some of the primary flows (adding a job being the most obvious), so here it stands
    // only loading if it's necessary to load. It's not a bad pattern, it's just stands out
    // as it's the only place where it's used.
    useEffect(() => {
        if (isOrganisation && !organisation && !fetch.loading && !fetch.error) {
            dispatch(organisationAsync.request());
        }
    }, [isOrganisation, organisation, fetch, dispatch]);

    const isAgentForEmployer = useMemo(
        () => !!organisationType && organisationType === 'AgentForEmployer',
        [organisationType]
    );

    // see comment in useAuthenticationHook
    const allowLocalEdits = useMemo(() => {
        if (!activeIdentity) {
            return false;
        }

        const isLocalAndCentralOrganisationInPendingState =
            determineIfLocalAndCentralOrganisationInPendingState(activeIdentity);

        // should always be true if this is getting called
        if (!isOrganisation) {
            return false;
        }

        const isCentral = organisation?.isDistributed || isCentralContact;

        return !isCentral || isLocalAndCentralOrganisationInPendingState;
    }, [
        activeIdentity,
        determineIfLocalAndCentralOrganisationInPendingState,
        isCentralContact,
        isOrganisation,
        organisation?.isDistributed,
    ]);

    return {
        isOrganisation,
        organisation,
        isAgentForEmployer,
        centralOrganisationId,
        fetch,
        update,
        allowLocalEdits,
    };
};
