import { useEndorsementsIsEnabled } from '../../hooks/endorsements-is-enabled.hook';
import { useEndorsements } from '../../hooks/endorsements.hook';
import { EndorsementDisplay } from './EndorsementDisplay';

interface Props {
    endorsementIds: number[];
}

export const EndorsementDetailsDisplay = ({
    endorsementIds,
}: Props): React.ReactElement<any, any> | null => {
    useEndorsements();
    const { isEnabled } = useEndorsementsIsEnabled();

    if (!isEnabled || endorsementIds.length === 0) {
        return null;
    }

    return (
        <div className="mb-3">
            <h4 className="font-weight-normal">Endorsements</h4>
            <div className="d-flex">
                {endorsementIds.map(id => (
                    <EndorsementDisplay endorsementId={id} key={id} />
                ))}
            </div>
        </div>
    );
};
