import { push } from 'connected-react-router';
import { concat, EMPTY, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { identityPaths } from 'src/routes/identity/identity.paths';
import { isActionOf } from 'typesafe-actions';
import { careerHubIdentitySetActive } from '../../authentication/authentication.actions';
import { RootEpic } from '../../epic.root-index';
import { organisationLogoAsync } from '../../organisations/organisation.actions';
import {
    registerIndividualAsync,
    registerOrganisationAsync,
    registerResetState,
} from '../register.actions';

export const registerIndividualSetIdentityEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf([registerOrganisationAsync.success, registerIndividualAsync.success])),
        mergeMap(({ payload }) =>
            // only reset the data if there was a previous set identity
            concat([
                careerHubIdentitySetActive(payload.data, {
                    resetData: !!state$.value.authentication.activeIdentity,
                }),
                push(identityPaths.landing),
            ])
        )
    );
};

export const registerOrganisationLogoEpic: RootEpic = (action$, _, services) => {
    return action$.pipe(
        filter(isActionOf(registerOrganisationAsync.success)),
        map(() => services.api.register.getLogo()),
        filter(logo => !!logo),
        mergeMap(logo => of(organisationLogoAsync.request({ file: logo! })))
    );
};

export const registerOrganisationRemoveLogoEpic: RootEpic = (action$, _, services) => {
    return action$.pipe(
        filter(isActionOf([organisationLogoAsync.failure, organisationLogoAsync.success])),
        mergeMap(() => {
            services.api.register.removeLogo();
            return EMPTY;
        })
    );
};

export const registerResetEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(careerHubIdentitySetActive)),
        filter(action => !!action.payload && !!state$.value.register.details),
        mergeMap(() => {
            services.api.register.removeLogo();
            return of(registerResetState());
        })
    );
};
