import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { workGroupListAsync } from 'src/logic/features/work-groups/work-group.actions';
import { useClient } from 'src/ui/shared/hooks/client.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

export const useWorkGroups = () => {
    const dispatch = useDispatch();
    const {
        value: workgroups,
        request: { fetch },
        queryWorkGroupId: queryWorkgroupId,
    } = useRootSelector(state => state.workGroups);

    const careerHubVersion = useRootSelector(state => state.apiConfiguration.version);
    const client = useClient();

    useEffect(() => {
        // undefined would indicate that it's never been loaded
        // this is really bad, there's no way to clear this error since it always happens in the background
        if (workgroups === undefined && !fetch.loading && !fetch.error) {
            dispatch(workGroupListAsync.request());
        }
    }, [careerHubVersion, client, dispatch, fetch.error, fetch.loading, workgroups]);

    const isDefault = useCallback(
        (id: number) => {
            const workgroup = workgroups?.find(x => x.id === id);

            if (!workgroup) {
                return false;
            }
            return workgroup.id === 1 || !!workgroup.isDefault;
        },
        [workgroups]
    );

    const publishableWorkGroups = useMemo(() => {
        return (
            (workgroups || [])
                .filter(x => !isDefault(x.id))
                // the property 'employersCanPublishJobs' was removed in 5.14
                // but will still exist in versions prior to 5.14
                .filter(x => !!(x as any).employersCanPublishJobs || !!x.employersCanPublish)
        );
    }, [isDefault, workgroups]);

    return {
        fetch,
        workgroups,
        queryWorkgroupId,
        isDefault,
        publishableWorkGroups,
    };
};
