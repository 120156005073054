import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { LoaderText } from '../../loader/LoaderText';

export interface HookFormButtonsProps {
    onCancel?: () => void;
    cancelText?: string;
    submitText?: string;
    disabled?: boolean;
    showSpinner?: boolean;
    textAlign?: 'left';
    after?: React.ReactNode;
    children?: React.ReactNode;
}

export const HookFormButtons = (
    props: HookFormButtonsProps
): React.ReactElement<any, any> | null => {
    const { onCancel, submitText, cancelText, disabled, children, showSpinner, textAlign, after } =
        props;

    const {
        formState: { isSubmitting },
    } = useFormContext();
    const {
        shared: {
            forms: { buttons },
        },
    } = useStrings();

    return (
        <div className={textAlign ? undefined : 'text-sm-right'}>
            {children}
            {onCancel && (
                <Button
                    type="button"
                    variant="link"
                    className="text-muted"
                    disabled={isSubmitting || disabled}
                    onClick={onCancel}
                >
                    {cancelText || buttons.cancel}
                </Button>
            )}
            <Button
                type="submit"
                variant="primary"
                disabled={isSubmitting || disabled || showSpinner}
            >
                <LoaderText loading={isSubmitting || showSpinner}>
                    {submitText || buttons.save}
                </LoaderText>
            </Button>
            {after}
        </div>
    );
};
