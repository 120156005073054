import { uniq } from "lodash";
import { ValidateResult } from "react-hook-form";
import { LocationModel } from "src/models/locations/location.model";
export const locationMaxFieldLengths = (values: LocationModel[] | undefined): ValidateResult => {
    if (!values) {
        return true;
    }

    const valid = values.every(l => {
        if (l.city && l.city.length > 60) {
            return false;
        }
        if (l.region && l.region.length > 30) {
            return false;
        }

        // The below rule is currently impossible, as address is created by a combination of the city and region
        // if (l.address && l.address.length > 255) { return false; }

        return true;
    });

    return valid
        ? undefined
        : 'The city field must be 60 characters or less, and the state/region field must be 30 characters or less';
};

export const locationEnsureUnique = (values: LocationModel[] | undefined): ValidateResult => {
    if (!values) {
        return true;
    }

    const valid = uniq(values).length === values.length;

    return valid ? undefined : 'Location values must be unique.';
};
