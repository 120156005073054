import {
    ActiveListState,
    EntityListState,
} from '../../models/store-models/entity-list-state.model';
import { EntitySingleState } from '../../models/store-models/entity-single-state.model';

const activeListInitialState: Readonly<ActiveListState<any>> = {
    requestId: '___',
    pagination: {
        page: 1,
        pageSize: 1,
        pageTotal: 1,
    },
    fetch: { loading: false },
    query: {},
};

export const entityListInitialState: EntityListState<any, any> = {
    activeList: activeListInitialState,
    activeId: undefined,
    list: {},
    single: {},
    items: {},
    create: { loading: false },
};

export const singleEntityState: EntitySingleState<any> = {
    request: { fetch: { loading: false }, update: { loading: false } },
    value: undefined,
};
