import { EntityRequest } from 'src/models/api/request.model';
import { ApiResponse } from 'src/models/api/response.model';
import { AttachmentCreate } from 'src/models/attachments/attachment-create.model';
// import { CentralJob, CentralJobAttachment } from 'src/models/central/central-job.model';
// import {
//     CentralOrganisationResponse,
//     CentralRegistration,
//     CentralRegistrationResponse,
//     CentralSimilarOrganisation,
//     CentralSimilarOrganisationRequest,
// } from 'src/models/central/central-organisation.model';
import { Contact } from 'src/models/contacts/contact.model';
import { ErrorNormalized } from 'src/models/errors/error.model';
import { StoredFile } from 'src/models/jobs/job-stored-file.model';
// import { CentralConfigurationResponse } from 'src/models/central/central-configuration.model';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { CentralPublishState } from './central.reducer';
import { CentralListRequest } from 'src/models/central/central-list.model';
import {
    Organisation as CentralOrganisation,
    OrganisationRegistration as CentralRegistration,
    SimilarOrganisationModel as CentralSimilarOrganisation,
    SimilarOrganisationQuery as CentralSimilarOrganisationRequest,
    ConfigurationModelIncludeModel as CentralConfigurationResponse,
    Job as CentralJob,
    Attachment as CentralJobAttachment,
} from '@symplicity/central-types';

export type CentralPublishStep =
    | 'init'
    | 'organisation'
    | 'contactInvite'
    | 'job'
    | 'jobAttachment'
    | 'complete'
    | 'error';

export interface CentralPublishNextStepPayload {
    step: CentralPublishStep;
    index?: number;
}

interface IndexMeta {
    index: number;
}

interface CentralJobAttachmentMeta {
    index: number;
    fileMeta: StoredFile;
}

export interface CentralJobCreatePayload {
    job: Partial<CentralJob>;
    isInitialAttempt: boolean;
}

export const centralSetRequiresAuthentication = createAction(
    'CENTRAL_SET_REQUIRES_REAUTHENTICATION'
)();

export const centralConfigurationAsync = createAsyncAction(
    'CENTRAL_CONFIGURATION:REQUEST',
    'CENTRAL_CONFIGURATION:SUCCESS',
    'CENTRAL_CONFIGURATION:FAILURE',
    'CENTRAL_CONFIGURATION:CANCEL'
)<undefined, CentralConfigurationResponse, ErrorNormalized>();

export const centralCreateJobSetActive = createAction('CENTRAL_PUBLISH_JOB:SET_ACTIVE')<{
    jobId?: number;
}>();

export const centralCreateUserConfirmation = createAction(
    'CENTRAL_CREATE:USER_CONFIRMATION'
)<CentralPublishState>();

export const centralPublishActionStep = createAction(
    'CENTRAL_PUBLISH:NEXT_STEP'
)<CentralPublishNextStepPayload>();

export const centralRegisterAsync = createAsyncAction(
    'CENTRAL_REGISTER:REQUEST',
    'CENTRAL_REGISTER:SUCCESS',
    'CENTRAL_REGISTER:FAILURE'
)<CentralRegistration, ApiResponse<CentralRegistration>, ErrorNormalized>();

export const centralOrganisationListAsync = createAsyncAction(
    'CENTRAL_ORGANISATION_LIST:REQUEST',
    'CENTRAL_ORGANISATION_LIST:SUCCESS',
    'CENTRAL_ORGANISATION_LIST:FAILURE'
)<CentralListRequest, ApiResponse<CentralOrganisation[]>, ErrorNormalized>();

export const centralSimilarOrganisationListAsync = createAsyncAction(
    'CENTRAL_SIMILAR_ORGANISATION_LIST:REQUEST',
    'CENTRAL_SIMILAR_ORGANISATION_LIST:SUCCESS',
    'CENTRAL_SIMILAR_ORGANISATION_LIST:FAILURE'
)<
    CentralSimilarOrganisationRequest,
    ApiResponse<(CentralSimilarOrganisation & { id: number })[]>,
    ErrorNormalized
>();

export const centralContactInviteAsync = createAsyncAction(
    'CENTRAL_CONTACT_INVITE:REQUEST',
    'CENTRAL_CONTACT_INVITE:SUCCESS',
    'CENTRAL_CONTACT_INVITE:FAILURE'
)<
    [{ centralOrganisationId: number; contact: Contact }, IndexMeta],
    [undefined, IndexMeta],
    [ErrorNormalized, IndexMeta]
>();

export const centralJobCreateAsync = createAsyncAction(
    'CENTRAL_JOB_CREATE:REQUEST',
    'CENTRAL_JOB_CREATE:SUCCESS',
    'CENTRAL_JOB_CREATE:FAILURE'
)<CentralJobCreatePayload, ApiResponse<CentralJob>, [ErrorNormalized, CentralJobCreatePayload]>();

export const centralJobListAsync = createAsyncAction(
    'CENTRAL_JOB_LIST:REQUEST',
    'CENTRAL_JOB_LIST:SUCCESS',
    'CENTRAL_JOB_LIST:FAILURE'
)<CentralListRequest, ApiResponse<CentralJob[]>, ErrorNormalized>();

export const centralJobAttachmentUploadAsync = createAsyncAction(
    'CENTRAL_JOB_ATTACHMENT_CREATE:REQUEST',
    'CENTRAL_JOB_ATTACHMENT_CREATE:SUCCESS',
    'CENTRAL_JOB_ATTACHMENT_CREATE:FAILURE'
)<
    [AttachmentCreate, CentralJobAttachmentMeta],
    [ApiResponse<CentralJobAttachment>, CentralJobAttachmentMeta],
    [ErrorNormalized, CentralJobAttachmentMeta]
>();

export const centralFromCareerHubJobAttachmentBlobAsync = createAsyncAction(
    'CENTRAL_JOB_ATTACHMENT_BLOB:REQUEST',
    'CENTRAL_JOB_ATTACHMENT_BLOB:SUCCESS',
    'CENTRAL_JOB_ATTACHMENT_BLOB:FAILURE'
)<
    [EntityRequest, CentralJobAttachmentMeta],
    [File, CentralJobAttachmentMeta],
    [ErrorNormalized, CentralJobAttachmentMeta]
>();

export const centralPublishSetRedirectUrl = createAction('CENTRAL_PUBLISH_SET_REDIRECT_URL')<{
    url: string;
}>();

export const centralPublishReset = createAction('CENTRAL_PUBLISH:RESET')();
