import { useActiveEntity } from 'src/ui/shared/hooks/active-entity.hook';

// meh, this is a bit messy
export const useEventUnionTitles = () => {
    const {
        entity: eventUnion,
        fetch,
        deleted,
        update,
    } = useActiveEntity(state => state.eventUnions);

    // clever, but lazy
    const title = eventUnion?.event?.title ?? eventUnion?.eventRequest?.title ?? undefined;
    const hideLoader = !!(deleted || fetch.error || update.error);

    return {
        eventUnion,
        title,
        hideLoader,
    };
};
