import { faMapMarkerAlt, faPencil, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
    PeliasFeature,
    PeliasLayer,
    PeliasQueryBoundary,
    PeliasQueryFocus,
    PeliasV1Endpoints,
} from '@symplicity/geocoding';
import { omit } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { InputActionMeta, OptionTypeBase, OptionsType } from 'react-select';
import AsyncCreatableSelect, { Props as AsyncCreatableProps } from 'react-select/async-creatable';
import { HookControlProps } from 'src/ui/shared/components/forms/HookForm';
import { ControlFormGroup } from 'src/ui/shared/components/forms/shared/ControlFormGroup';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useFormError } from 'src/ui/shared/hooks/form-error.hook';

export type LocationOption = OptionTypeBase & {
    created?: boolean;
    effect?: boolean;
    value: string;
    feature?: Partial<PeliasFeature>;
};

type LocationAsyncProps = AsyncCreatableProps<LocationOption, false>;

type LoadOptions = LocationAsyncProps['loadOptions'];

type RenderProps = Omit<ControllerRenderProps, 'ref'> & LocationAutocompleteProps;

export interface LocationAutocompleteProps extends HookControlProps {
    getValue?: (feature: PeliasFeature) => string | undefined;
    filter?: (feature: PeliasFeature) => boolean;
    focus?: PeliasQueryFocus;
    boundary?: PeliasQueryBoundary;
    layers?: PeliasLayer[];
    onChange?: (val: LocationOption | null) => void;
}

const SelectedInputGroup = (props: RenderProps) => {
    const { onChange } = props;
    const { value, feature, created } = props.value as LocationOption;
    const onRemove = useCallback(() => onChange(null), [onChange]);

    return (
        <InputGroup>
            <div className="form-control bg-light d-flex align-items-center">
                {feature?.geometry ? (
                    <Icon icon={faMapMarkerAlt}>{value}</Icon>
                ) : created ? (
                    <Icon icon={faPencil}>{value}</Icon>
                ) : (
                    value
                )}
            </div>
            <InputGroup.Append>
                <Button
                    variant="light"
                    className="border-input border-left-0"
                    autoFocus={props.autoFocus}
                    onClick={onRemove}
                >
                    <Icon icon={faTimes} />
                </Button>
            </InputGroup.Append>
        </InputGroup>
    );
};

const SelectWrapper = (props: RenderProps) => {
    const { onChange, boundary, layers, focus, filter, getValue } = props;
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<OptionsType<LocationOption>>([]);
    const error = useFormError(props.name);

    const createOption = useCallback(
        (input: string) => {
            onChange?.({
                value: input,
                created: true,
            });
        },
        [onChange]
    );

    const onInputChange = useCallback(
        (input: string, meta: InputActionMeta) => {
            if (meta.action === 'input-blur' && inputValue) {
                const match = options.find(
                    x =>
                        inputValue.localeCompare(x.feature?.properties?.name || '', undefined, {
                            sensitivity: 'base',
                        }) === 0
                );

                if (match) {
                    onChange(match);
                } else {
                    createOption(inputValue);
                }
            }
            setInputValue(input);
        },
        [inputValue, options, onChange, createOption]
    );

    const loadOptions: LoadOptions = useCallback(
        async input => {
            let features = await PeliasV1Endpoints.autocomplete({
                text: input,
                boundary,
                layers,
                focus,
                api_key: 'ge-c1014ce71d3b18f6',
            });

            if (filter) {
                features = features.filter(filter);
            }

            // Strip the country off the end of the label.
            // The user has already selected it making it redundant
            const opts = features.map(feature => ({
                feature,
                id: feature.properties.id,
                value: getValue?.(feature) || feature.properties.name,
                label: (
                    <Icon icon={faMapMarkerAlt}>
                        {feature.properties.label.split(', ').slice(0, -1).join(', ')}
                    </Icon>
                ),
            }));

            setOptions(opts);

            return opts;
        },
        [boundary, filter, getValue, layers, focus]
    );

    return (
        <AsyncCreatableSelect
            {...props}
            isClearable
            cacheOptions={false}
            tabIndex={props.tabIndex?.toString() || null}
            onInputChange={onInputChange}
            onCreateOption={createOption}
            noOptionsMessage={() => 'Type to search'}
            loadOptions={loadOptions}
            className={`react-select-container ${error ? 'is-invalid' : ''}`}
            classNamePrefix="react-select"
        />
    );
};

export const LocationAutocomplete = (props: LocationAutocompleteProps) => {
    const { name, rules } = props;
    const { control } = useFormContext();

    return (
        <ControlFormGroup {...props}>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={item => {
                    const onChange = (val: LocationOption | null) => {
                        props.onChange?.(val);
                        item.field.onChange(val);
                    };

                    const fieldProps = omit(item.field, 'ref');

                    if (item.field.value) {
                        return (
                            <SelectedInputGroup {...fieldProps} {...props} onChange={onChange} />
                        );
                    } else {
                        return <SelectWrapper {...fieldProps} {...props} onChange={onChange} />;
                    }
                }}
            />
        </ControlFormGroup>
    );
};
