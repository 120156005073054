import { Nav } from 'react-bootstrap';
import { Link, useRouteMatch } from 'react-router-dom';

interface Props {
    to: string;
    matchPath: string;
    title?: string;
    exact?: boolean;
    children?: React.ReactNode;
    disabled?: boolean;
}

export const TabLink = ({
    to,
    matchPath,
    title,
    exact,
    children,
    disabled,
}: Props): React.ReactElement<any, any> | null => {
    const match = useRouteMatch({ path: matchPath, exact });

    return (
        <Nav.Item>
            <Nav.Link as={Link} to={to} disabled={disabled} active={!!match} title={title}>
                {children}
            </Nav.Link>
        </Nav.Item>
    );
};
