import values from 'lodash/values';
import { useMemo } from 'react';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

export const useCentralPublishErrors = () => {
    const {
        register,
        contactInviteState: contactInviteStateArray,
        jobCreateState,
        jobAttachmentState: jobAttachmentStateArray,
    } = useRootSelector(state => state.central);

    const contactStates = values(contactInviteStateArray)
        .filter(i => i !== undefined)
        .map(i => i!);

    const jobAttachmentStates = values(jobAttachmentStateArray)
        .filter(i => i !== undefined)
        .map(i => i!);

    const hasError = useMemo(
        () =>
            [register, ...contactStates, jobCreateState, ...jobAttachmentStates].some(
                i => !!i.error
            ),
        [contactStates, jobAttachmentStates, jobCreateState, register]
    );

    return {
        register,
        contactStates,
        jobCreateState,
        jobAttachmentStates,
        hasError,
    };
};
