import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useWorkGroup } from '../hooks/work-group.hook';

interface Props {
    workGroupId: number | undefined;
    className?: string;
}

export const WorkGroupLabel = ({
    workGroupId,
    className,
}: Props): React.ReactElement<any, any> | null => {
    const { name, isDefault } = useWorkGroup(workGroupId);

    if (!name || isDefault) {
        return null;
    }

    return (
        <span className={className}>
            <Icon icon={faUsers} title="Work Group">
                {name}
            </Icon>
        </span>
    );
};
