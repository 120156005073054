import { useRef, useEffect } from 'react';

export const useOuterClick = (callback: () => void) => {
    const innerRef = useRef<any>(null);
    const callbackRef = useRef<(e: MouseEvent) => void>();

    // set current callback in ref, before second useEffect uses it
    useEffect(() => {
        // useEffect wrapper to be safe for concurrent mode
        callbackRef.current = callback;
    });

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);

        // read most recent callback and innerRef dom node from refs
        function handleClick(e: MouseEvent) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) {
                callbackRef.current(e);
            }
        }
    }, []); // no need for callback + innerRef dep

    return innerRef;
};
