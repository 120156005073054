import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { eventPathCreator } from 'src/routes/employer/paths/event.paths';
import { ButtonLink } from 'src/ui/shared/components/bootstrap/buttons/ButtonLink';
import { PageTitle } from 'src/ui/shared/components/page-components/PageTitle';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { useEventsEnabled } from '../../hooks/events-enabled.hook';

export const EventListPageNav = (): React.ReactElement<any, any> | null => {
    const {
        nav: { application },
        event: { list },
    } = useStrings();

    const { canCreateEventRequests } = useEventsEnabled();

    return (
        <div className="d-flex align-items-center">
            <PageTitle>{application.events}</PageTitle>
            {canCreateEventRequests && (
                <ButtonLink className="ml-2" to={eventPathCreator.add()} icon={faPlus}>
                    {list.addAction}
                </ButtonLink>
            )}
        </div>
    );
};
