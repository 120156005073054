import ReactGA4 from 'react-ga4';
import { InitOptions } from 'react-ga4/types/ga4';
import { TrackerType } from 'src/logic/features/analytics/analytics.reducer';
import { ConfigurationState } from 'src/logic/features/configuration/configuration.reducer';
import { storageHelper } from '../../helpers/storage.helper';

// careerhub stuff is all temp
const clientTrackerName = 'client';

const sessionStorageKey = 'cew:analytics';
const localStoreKey = (centralUserId: number) => `cew:analytics:${centralUserId}`;

const { available } = storageHelper;

export const configure = (configState: ConfigurationState): TrackerType | undefined => {
    // if local or sessions isn't available, just disable it
    if (!available('localStorage') && !available('sessionStorage')) {
        return undefined;
    }

    const gaType: TrackerType | undefined = configState.value.gaTrackingId?.startsWith('G-')
        ? 'ga4'
        : undefined;

    if (gaType === 'ga4') {
        const careerHubGA4: InitOptions = {
            trackingId: 'G-37MZ5C3MGX',
            gaOptions: {
                name: 'careerhub',
                allowLinker: true,
                siteSpeedSampleRate: 100,
            },
        };

        const clientTrackerGA4: InitOptions = {
            trackingId: configState.value.gaTrackingId!,
            gaOptions: {
                siteSpeedSampleRate: 100,
                name: clientTrackerName,
            },
        };

        // anonymizeIP is for GDPR complicance
        ReactGA4.set({ anonymizeIp: true });
        ReactGA4.initialize([careerHubGA4, clientTrackerGA4] as any);
    }

    return gaType;
};

export const pageView = (tracker: TrackerType | undefined, url: string) => {
    if (!tracker) {
        return;
    }

    if (tracker === 'ga4') {
        ReactGA4.send({
            hitType: 'pageview',
            page: url,
        });
        return;
    }
};

// logic exists to minimise annoyance. If the user is not logged in, but clicks on a confirm option
// we store the confirmation as a session
// then if the user logs in afterwards we store the confirmation against the user in local storage (for persistence)
// If you are logged in already, and you exist in local storage (as that user) we use the stored value
// If you already have a value in storage, but chose a different value before logging in, we update the value.
export const storeConfirmation = (centralUserId: number | undefined, confirm: boolean) => {
    // always set the session, this caters for logout logic
    sessionStorage.setItem(sessionStorageKey, confirm.toString());

    if (!centralUserId) {
        return;
    }

    const localKey = localStoreKey(centralUserId);
    localStorage.setItem(localKey, confirm.toString());
};

export const getConfirmationFromStorage = (
    centralUserId: number | undefined
): boolean | undefined => {
    if (!centralUserId) {
        const sessionValue = sessionStorage.getItem(sessionStorageKey);
        return sessionValue === null ? undefined : Boolean(sessionValue);
    }

    const localKey = localStoreKey(centralUserId);
    const localValue = localStorage.getItem(localKey);
    return localValue === null ? undefined : Boolean(localValue);
};
