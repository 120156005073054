import { CSSProperties } from 'react';

export const Octagon = (props: { style?: CSSProperties; className?: string }) => (
    <svg
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={props.style}
        className={props.className}
    >
        <path
            // eslint-disable-next-line max-len
            d="M17.2871 0.712891C15.6172 0.712891 14.0352 1.32812 12.8047 2.55859L2.3457 13.0176C1.11523 14.248 0.5 15.8301 0.5 17.5V28.8379C0.5 30.5078 1.11523 32.0898 2.3457 33.3203L12.8047 43.7793C14.0352 45.0098 15.6172 45.625 17.2871 45.625H28.625C30.2949 45.625 31.877 45.0098 33.1074 43.7793L43.5664 33.3203C44.7969 32.0898 45.4121 30.5078 45.4121 28.8379V17.5C45.4121 15.8301 44.7969 14.248 43.5664 13.0176L33.1074 2.55859C31.877 1.32812 30.2949 0.712891 28.625 0.712891H17.2871Z"
            fill="currentColor"
        />
    </svg>
);
