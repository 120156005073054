import { Link } from 'react-router-dom';

interface Props {
    to: string;
    className?: string;
    title?: string;
    children: React.ReactNode;
}

export const BreadcrumbLink = ({
    to,
    children,
    className,
    title,
}: Props): React.ReactElement<any, any> | null => (
    <>
        <Link to={to} className={className} title={title}>
            {children}
        </Link>
        <span className="mx-2">/</span>
    </>
);
