import { ListGroupItemActions } from './ListGroupItemActions';
import { ListGroupItemTitle } from './ListGroupItemTitle';

interface Props {
    children: React.ReactNode;
}

export const ListGroupItemHeader = ({ children }: Props): React.ReactElement<any, any> | null => {
    return <div className="d-flex align-items-end mb-2">{children}</div>;
};

ListGroupItemHeader.Title = ListGroupItemTitle;
ListGroupItemHeader.Actions = ListGroupItemActions;
