import { goBack, push, replace } from 'connected-react-router';
import * as H from 'history';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { ConfirmModal } from '../bootstrap/modals/ConfirmModal';

interface InterceptedRouterAction {
    location: H.Location;
    action: H.Action;
}

// well, this is a bit cross-library-ish
export const HookFormPrompt = (): React.ReactElement<any, any> | null => {
    const { formState } = useFormContext();

    const { isSubmitSuccessful, dirtyFields, isSubmitting } = formState;

    const [routerAction, setRouterAction] = useState<InterceptedRouterAction | undefined>(
        undefined
    );

    const [show, setShow] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const dispatch = useDispatch();

    const promptMessage = useCallback(
        (location: H.Location, action: H.Action) => {
            // this should be able to use formState.isDirty, but there
            // is a change in the current version that doesn't check the dirtyFields state
            // should get fixed in a future release.
            const isDirtyAlt = !!Object.keys(dirtyFields).length;
            if (isSubmitSuccessful || isSubmitting || !isDirtyAlt) {
                return true;
            }

            if (!show) {
                setRouterAction({ location, action });
                setShow(true);
                setConfirmed(false);
            }

            if (!confirmed) {
                return false;
            }

            return true;
        },
        [confirmed, dirtyFields, isSubmitSuccessful, isSubmitting, show]
    );

    const onConfirm = useCallback(() => {
        if (!routerAction) {
            throw new Error('router action cannot be undefined here');
        }

        setConfirmed(true);

        setTimeout(() => {
            switch (routerAction.action) {
                case 'POP':
                    dispatch(goBack());
                    break;
                case 'PUSH':
                    dispatch(push(routerAction.location));
                    break;
                case 'REPLACE':
                    dispatch(replace(routerAction.location));
                    break;
            }
        });
    }, [dispatch, routerAction]);

    return (
        <>
            {show && (
                <ConfirmModal
                    onConfirm={() => onConfirm()}
                    onCancel={() => setShow(false)}
                    title="Leave without saving"
                    message="You have unsaved changes, continue without saving?"
                />
            )}
            <Prompt message={promptMessage} />
        </>
    );
};
