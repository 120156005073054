export const ENTITY_NOTE_EVENT_TYPE_OPTIONS = [
    // 'JobChangeRequest',
    'EventChangeRequest',
    'EventDetailsChangeRequest',
    'EventDateChangeRequest',
    'EventLocationChangeRequest',
    'EventSessionChangeRequest',
    'EventBookingChangeRequest',
    'EventProviderChangeRequest',
    'EventCoverImageChangeRequest',
    'EventTargetingChangeRequest',
    'EventSkillsChangeRequest',
    'EventEndorsementsChangeRequest',
] as const;
type EntityNoteEventTypeTuple = typeof ENTITY_NOTE_EVENT_TYPE_OPTIONS;
export type EntityNoteEventType = EntityNoteEventTypeTuple[number];

type EntityNoteEventTypeMeta = {
    [key in EntityNoteEventType]: string;
};
export const entityNoteEventTypeMeta: EntityNoteEventTypeMeta = {
    EventBookingChangeRequest: 'Bookings',
    EventChangeRequest: 'General',
    EventCoverImageChangeRequest: 'Cover image',
    EventDateChangeRequest: 'Dates',
    EventDetailsChangeRequest: 'Details',
    EventLocationChangeRequest: 'Location',
    EventProviderChangeRequest: 'Providers',
    EventSessionChangeRequest: 'Sessions',
    EventTargetingChangeRequest: 'Targeting',
    EventSkillsChangeRequest: 'Skills',
    EventEndorsementsChangeRequest: 'Endorsements',
};
