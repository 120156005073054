import { HookForm } from '../HookForm';

interface Props {
    name: string;
    entityType: 'job' | 'event';
}

export const WorkGroupFormRow = ({
    name,
    entityType,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <HookForm.Row>
            <HookForm.Col sm="12" md="6">
                <HookForm.WorkGroupSelect
                    label="Restrict to group"
                    name={name}
                    allowEmpty={true}
                    placeholder="No restriction"
                    entityType={entityType}
                />
            </HookForm.Col>
        </HookForm.Row>
    );
};
