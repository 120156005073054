import { DeletedCard } from 'src/ui/shared/components/details/DeletedCard';
import { CardWrapper } from '../../../shared/components/bootstrap/cards/CardWrapper';
import { Loader } from '../../../shared/components/loader/Loader';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';
import { ContactDetails } from '../components/details/ContactDetails';
import { useActiveContact } from '../hooks/active-contact.hook';

export const ContactDetailsPage = (): React.ReactElement<any, any> | null => {
    const { contact, fetch, deleted } = useActiveContact();

    return (
        <ApplicationPageContent>
            {deleted && <DeletedCard />}

            <Loader state={fetch} useCard={true}>
                {contact && (
                    <CardWrapper>
                        <ContactDetails contact={contact} />
                    </CardWrapper>
                )}
            </Loader>
        </ApplicationPageContent>
    );
};
