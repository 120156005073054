import { EventRequest } from 'src/models/events/event-request.model';
import { EndorsementDetailsDisplay } from 'src/ui/features/endorsements/components/display/EndorsementDetailsDisplay';
import { SkillDetailsDisplay } from 'src/ui/features/skills/components/SkillDetailsDisplay';
import { Details } from 'src/ui/shared/components/details/Details';
import { Html } from 'src/ui/shared/components/html/Html';
import { ToggleWell } from 'src/ui/shared/components/toggle-well/ToggleWell';
import { EventBannerHeader } from './EventBannerHeader';
import { EventLocationDisplay } from './EventLocationDisplay';
import { EventRequestProperties } from './EventRequestProperties';
import { EventStartEndDisplay } from './EventStartEndDisplay';

interface Props {
    eventRequest: EventRequest;
}

export const EventRequestDetails = ({
    eventRequest,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <>
            <EventBannerHeader bannerKey={eventRequest.bannerKey} isEvent={false}>
                <h1 className="display-5">{eventRequest.title}</h1>
                <div className="mb-1">
                    <EventStartEndDisplay
                        start={eventRequest.start}
                        end={eventRequest.end}
                        timeZoneId={eventRequest.timeZoneId}
                    />
                </div>
                <div className="mb-1">
                    <EventLocationDisplay item={eventRequest} />
                </div>
            </EventBannerHeader>
            <Details properties={<EventRequestProperties eventRequest={eventRequest} />}>
                <h2 className="font-weight-normal">Summary</h2>
                <div className="mb-3">{eventRequest.summary}</div>

                <h2 className="font-weight-normal">Details</h2>
                <Html source={eventRequest.details} className="mb-3 event-html-container" />

                <SkillDetailsDisplay entity={eventRequest} />
                <EndorsementDetailsDisplay endorsementIds={eventRequest.endorsementIds || []} />

                {(eventRequest.targetingNote ||
                    eventRequest.additionalNote ||
                    eventRequest.onlineLocation) && (
                    <ToggleWell label="View notes" className="mb-3">
                        {eventRequest.targetingNote && (
                            <>
                                <div className="font-weight-bold">Targeting note</div>
                                <div className="mb-3">{eventRequest.targetingNote}</div>
                            </>
                        )}
                        {eventRequest.additionalNote && (
                            <>
                                <div className="font-weight-bold">Additional note</div>
                                {eventRequest.additionalNote}
                            </>
                        )}
                        {eventRequest.onlineLocation && (
                            <>
                                <div className="font-weight-bold">Online instructions</div>
                                {eventRequest.onlineLocation.onlineInstructions}
                            </>
                        )}
                    </ToggleWell>
                )}
            </Details>
        </>
    );
};
