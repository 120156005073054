export const identityPaths = {
    list: '/identities',
    listAuthenticated: '/protected-identities',
    logout: '/logout',
    impersonate: '/impersonate',
    landing: '/auth',
};

export const identityBasePaths = [
    identityPaths.list,
    identityPaths.impersonate,
    identityPaths.logout,
    identityPaths.landing,
];
