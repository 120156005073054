import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { AuthenticationErrorMessage } from './AuthenticationErrorMessage';
import { AuthenticationFallback } from './AuthenticationFallback';
import { GuardLoader } from './GuardLoader';
import { Initialization503ErrorMessage } from './Initialization503ErrorMessage';

interface Props {
    children: React.ReactNode;
}

export const InitializationRouteGuard = ({
    children,
}: Props): React.ReactElement<any, any> | null => {
    const { completed, loadState } = useRootSelector(state => state.init);

    return completed && !loadState.error ? (
        <>{children}</>
    ) : (
        <>
            <GuardLoader state={loadState} />

            <AuthenticationErrorMessage error={loadState.error} />
            <Initialization503ErrorMessage error={loadState.error} />

            <AuthenticationFallback />
        </>
    );
};
