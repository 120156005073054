import { getClientConfig } from 'src/clients-internal/configuration.init';
import { ApiResponse } from '../../../models/api/response.model';
import { ObsApiExpected } from '../../../models/api/service.model';
import { Setting } from '../../../models/settings/setting.model';
import { fetchHelper } from '../../helpers/fetch.helper';

const basePath = () =>
    `${getClientConfig().careerHubBasePath}/services/employers/v1/communications`;

export function list(): ObsApiExpected<ApiResponse<Setting[]>> {
    return fetchHelper.get(basePath());
}

export function update(request: Setting[]): ObsApiExpected<ApiResponse<Setting[]>> {
    return fetchHelper.put(basePath(), request);
}
