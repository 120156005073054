import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useCallback } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { jobChangeRequestAsync } from '../../../../../logic/features/jobs/job.actions';
import { JobChangeRequest } from '../../../../../models/jobs/job-change-request.model';
import { AlertWrapper } from '../../../../shared/components/bootstrap/alerts/AlertWrapper';
import { HookForm } from '../../../../shared/components/forms/HookForm';
import { Loader } from '../../../../shared/components/loader/Loader';
import { createMaxLengthRule } from '../../../../shared/helpers/validation.helper';
import { useFormButton } from '../../../../shared/hooks/form-button.hook';
import { useFormDefault } from '../../../../shared/hooks/form-default.hook';
import { useFormServerValidation } from '../../../../shared/hooks/form-server-validation.hook';

interface Props {
    jobId: number;
}

// eh, this form is a bit more adhoc than the others, particularly with the loader/ errors
export const JobChangeRequestForm = ({ jobId }: Props): React.ReactElement<any, any> | null => {
    const formMethods = useFormDefault<JobChangeRequest>({
        defaultValues: { id: jobId },
        error: undefined,
    });

    const dispatch = useDispatch();
    const onSubmit = useCallback(
        (data: JobChangeRequest) => {
            dispatch(jobChangeRequestAsync.request(data));
        },
        [dispatch]
    );
    const { onCancel } = useFormButton();

    const changeRequestState = useRootSelector(
        state => state.jobs.changeRequests[jobId],
        shallowEqual
    );
    const showLoader = !!changeRequestState && changeRequestState.create.loading;

    useFormServerValidation(changeRequestState?.create.error, formMethods.setError);

    return (
        <Loader state={showLoader}>
            <HookForm {...formMethods} onSubmit={onSubmit}>
                <HookForm.ErrorAlert error={changeRequestState?.create.error} />

                <AlertWrapper variant="info" icon={faInfoCircle} dismissible={true}>
                    <p className="mb-1">
                        This form is to be used exclusively to outline the change(s) you wish to
                        make to one particular ad.
                    </p>

                    <p className="mb-0">
                        Please do not submit general CareerHub feedback or questions via this form.
                        If you wish to provide feedback or make a general enquiry, please email us.
                    </p>
                </AlertWrapper>

                <HookForm.HiddenId />
                <HookForm.Textarea
                    minRows={3}
                    name="change"
                    label="Change Request"
                    rules={{ required: true, maxLength: createMaxLengthRule(4000) }}
                />

                <HookForm.Buttons onCancel={onCancel} />
            </HookForm>
        </Loader>
    );
};
