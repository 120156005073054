import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { jobWithdrawAsync } from '../job.actions';

export const jobWithdrawEpic = asyncEpicBase(
    jobWithdrawAsync,
    ({ api }, { payload }) => api.jobs.withdraw(payload),
    {
        success: result => jobWithdrawAsync.success(result.json),
        failure: (error, ra) => jobWithdrawAsync.failure(error, ra.payload),
    }
);
