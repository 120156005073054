import { CollectionContent, ContentKey } from '@careerhub/content-shared';
import { LinkWrapper } from 'src/ui/shared/components/router/LinkWrapper';
import { contentPathCreator } from 'src/routes/content/content.paths';
import { useContent } from '../hooks/content.hook';

interface Props {
    collection: CollectionContent;
    contentKey: ContentKey;
    className?: string;
}

export const ContentMenuSectionItems = ({
    collection,
    contentKey,
    className,
}: Props): React.ReactElement<any, any> | null => {
    const { getSectionTitles } = useContent('employerContent');
    const sectionTitles = getSectionTitles(collection);

    return (
        <>
            {sectionTitles.map((section, sectionIndex) => (
                <LinkWrapper
                    ignoreActive={true}
                    as="list"
                    key={sectionIndex}
                    className={`p-1 ${className || ''}`}
                    to={contentPathCreator.contentPage(contentKey, collection, section.header)}
                >
                    {section.header}
                </LinkWrapper>
            ))}
        </>
    );
};
