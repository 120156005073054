import { useRootSelector } from './root-selector.hook';

/*
    I suppose I should record what the changes are somewhere.
    
    ApiIncrement 2
    - implemented in 5.11
    - removed required validation on event request location "online instructions"
    - removed required validation on event request booking instructions
*/

export const useApiIncrement = () => {
    return useRootSelector(state => state.apiConfiguration.value?.apiIncrement || 0);
};
