import { LOCATION_CHANGE } from 'connected-react-router';
import { filter, ignoreElements, map, tap } from 'rxjs/operators';
import { getRouteMatch, isLocationRouteMatch } from 'src/logic/helpers/epics/location.epic-helper';
import {
    formPathExternalCreator,
    formPaths,
    FormRedirectParams,
} from '../../../../routes/employer/paths/form.paths';
import { delayTypeUntilAuthenticated } from '../../../helpers/epics/app-init.epic-helper';
import { RootEpic } from '../../epic.root-index';

// handles both form and form submission redirects
export const redirectToExternalSparseFormEpic: RootEpic = (action$, state$, services) => {
    return delayTypeUntilAuthenticated(action$, state$, LOCATION_CHANGE).pipe(
        filter(isLocationRouteMatch([formPaths.redirect, formPaths.redirectSubmission])),
        map(action =>
            // NOTE: the order matters
            getRouteMatch<Required<FormRedirectParams>>(action.payload.location, [
                formPaths.redirectSubmission,
                formPaths.redirect,
            ])
        ),
        tap({
            next: routeParams => {
                // eeeeh, this could be better, should probably check types and return
                // a redirectErrorAction to show to the user.
                // this assumes everything is sent correctly
                const w = services.windowService.getWindow();
                const params = routeParams!.params;

                w.location.href = params.submissionId
                    ? formPathExternalCreator.submission(
                          Number(params.formId),
                          Number(params.submissionId)
                      )
                    : formPathExternalCreator.form(Number(params.formId));
            },
        }),
        ignoreElements()
    );
};
