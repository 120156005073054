// not sure if this is worth it, but this is used
// directly by the e2e-testing project
export const jobComponentIdentifier = {
    unique: {
        dropdownActions: (id: number) => `job-actions-${id}`,
        topNavAddButton: 'btn-job-top-nav-add',
        detailsPage: 'job-details-page',
        editPage: 'job-edit-page',
        addPage: 'job-add-page',
        publishPage: 'job-publish-page',
        listPage: 'job-list-page',
        changeRequestPage: 'job-change-request-page',
        publishConfirmationModal: 'job-publish-confirmation-modal',
        withdrawModal: 'job-withdraw-modal',
        deleteModal: 'job-delete-modal',
    },
    notUnique: {
        detailsFormSaveButton: 'btn-job-save',
        editButton: 'btn-job-edit',
        publishConfirmationJobListButton: 'btn-job-list',
        changeRequestButton: 'btn-job-change-request',
        withdrawButton: 'btn-job-withdraw',
        republishButton: 'btn-job-republish',
        modalConfirmButton: 'btn-modal-confirm',
        deleteButton: 'btn-job-delete',
    },
};
