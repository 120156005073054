import { ApiSearchResponse } from 'src/models/api/response.model';
import { ErrorNormalized } from 'src/models/errors/error.model';
import { EventBooking } from 'src/models/events/event-booking.model';
import { ActiveListState } from 'src/models/store-models/entity-list-state.model';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { EventUnionPageRequest } from '../event-unions/event-union.service';
import { EventBookingPageRequest } from './event-booking.service';

export const eventBookingListSetActive = createAction('EVENT_BOOKING_LIST:SET_ACTIVE')<
    ActiveListState<EventBookingPageRequest>
>();

export const eventBookingListAsync = createAsyncAction(
    'EVENT_BOOKING_LIST:REQUEST',
    'EVENT_BOOKING_LIST:SUCCESS',
    'EVENT_BOOKING_LIST:FAILURE',
    'EVENT_BOOKING_LIST:CANCEL'
)<
    EventBookingPageRequest,
    ApiSearchResponse<EventBooking, EventBookingPageRequest>,
    [ErrorNormalized, EventUnionPageRequest],
    undefined
>();

export const eventBookingResetState = createAction('EVENT_BOOKING_RESET_STATE')();
