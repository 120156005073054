import { BBox2D } from '@symplicity/geocoding';
import { GeoJson, GeoJsonFeature, Map, MapProps, Marker, Point } from 'pigeon-maps';
import { useCallback, useEffect, useRef, useState } from 'react';

const color = '#326e83'; // primary
const height = 300;
const paddingFactor = 1.5;
const svgAttributes = {
    fill: color + '33',
    strokeWidth: '1',
    stroke: color,
    r: '20',
};

export function LocationMap({ bboxes }: { bboxes: BBox2D[] }) {
    const [center, setCenter] = useState<[number, number]>([0, 0]);
    const [zoom, setZoom] = useState(2);
    const ref = useRef<HTMLDivElement>(null);
    const width = ref.current?.offsetWidth || 0;

    const onBoundsChanged: Required<MapProps>['onBoundsChanged'] = useCallback(
        ev => {
            // without the ref check we get the error:
            // "Can't perform a React state update on an unmounted component"
            if (ref.current) {
                setZoom(ev.zoom);
                setCenter(ev.center);
            }
        },
        [setZoom, setCenter]
    );

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        if (bboxes.length === 0) {
            setCenter([0, 0]);
            setZoom(2);
            return;
        }

        const bbox = BBox2D.union(...bboxes)!;
        const bboxZoom = BBox2D.osmZoomLevel(bbox, [width || height, height], paddingFactor);
        const [lon, lat] = BBox2D.mercatorProjectionCenter(bbox);

        setCenter([lat, lon]);
        setZoom(bboxZoom);
    }, [bboxes, width]);

    return (
        <div ref={ref} style={{ height }}>
            <Map
                center={center}
                zoom={zoom}
                onBoundsChanged={onBoundsChanged}
                mouseEvents={false}
                touchEvents={false}
            >
                {width &&
                    bboxes.map((bbox, i) =>
                        isMarker(bbox, zoom, [width, height]) ? (
                            <Marker key={i} width={40} anchor={getPoint(bbox)} color={color} />
                        ) : (
                            <GeoJson key={i} svgAttributes={svgAttributes}>
                                <GeoJsonFeature feature={BBox2D.toFeature(bbox)} />
                            </GeoJson>
                        )
                    )}
            </Map>
        </div>
    );
}

function getPoint(bbox: BBox2D): Point {
    const [lon, lat] = BBox2D.centroid(bbox);
    return [lat, lon];
}

function isMarker(bbox: BBox2D, zoom: number, viewport: [number, number]): boolean {
    return BBox2D.isPoint(bbox) || BBox2D.osmZoomLevel(bbox, viewport, paddingFactor) - zoom > 2;
}
