import { getClientConfig } from 'src/clients-internal/configuration.init';
import { ApiResponse } from '../../../../models/api/response.model';
import { ObsApiExpected } from '../../../../models/api/service.model';
import { IdentityType } from '../../../../models/authentication/identity.model';
import { fetchHelper } from '../../../helpers/fetch.helper';

const baseUrl = () => `${getClientConfig().careerHubBasePath}/services/employers/v1/impersonate`;

export function getIdentity(): ObsApiExpected<ApiResponse<IdentityType>> {
    return fetchHelper.get(baseUrl());
}

export function logout(): ObsApiExpected<ApiResponse<undefined>> {
    return fetchHelper.get(`${baseUrl()}/logout`);
}
