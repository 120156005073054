interface Props {
    title: string;
}

export const ConfirmationTitle = ({ title }: Props): React.ReactElement<any, any> | null => {
    return (
        <div className="d-flex align-items-center mb-2">
            <span className="large">{title}</span>
        </div>
    );
};
