import { CardWrapper } from '../../../shared/components/bootstrap/cards/CardWrapper';
import { Loader } from '../../../shared/components/loader/Loader';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';
import { IndividualDetails } from '../components/details/IndividualDetails';
import { useIndividual } from '../hooks/individual.hook';

export const IndividualDetailsPage = (): React.ReactElement<any, any> | null => {
    const { individual, fetch } = useIndividual();

    return (
        <ApplicationPageContent>
            <Loader state={fetch} useCard={true}>
                <CardWrapper>
                    {individual && <IndividualDetails individual={individual} />}
                </CardWrapper>
            </Loader>
        </ApplicationPageContent>
    );
};
