import { useMemo } from 'react';
import { Col, Form } from 'react-bootstrap';
import { ReactSelectOption } from 'src/ui/shared/components/forms/controls/react-selects/SingleSelectControl';
import { HookForm } from 'src/ui/shared/components/forms/HookForm';
import { arrayRequiredRule } from 'src/ui/shared/helpers/validation.helper';
import { useCategoryLabel } from 'src/ui/shared/hooks/category-label.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { Job } from 'src/models/jobs/job.model';
import { useJobCentralMappings } from '../../hooks/job-central-mappings.hook';
import { CentralCategorySelectLabel } from './CentralCategorySelectLabel';

interface Props {
    job: Job;
    disabled: boolean | undefined;
}

export const LocalToCentralJobCategoryFormControls = ({
    job,
    disabled,
}: Props): React.ReactElement<any, any> | null => {
    const occupationLabel = useCategoryLabel('occupations');
    const typeOfWorkLabel = useCategoryLabel('typesOfWork');

    const { hasJobOccupationMappings, hasTypesOfWorkMappings } = useJobCentralMappings(job);
    const { occupations, employmentTypes } = useRootSelector(
        state => state.central.configuration.value!
    );
    const occupationOptions: ReactSelectOption[] = useMemo(
        () =>
            (occupations || []).map(i => ({
                label: i.name,
                value: i.value,
                tokens: i.name.toLowerCase().split(' '),
            })),
        [occupations]
    );
    const employmentTypeOptions: ReactSelectOption[] = useMemo(
        () =>
            (employmentTypes || []).map(i => ({
                label: i.name,
                value: i.value,
                tokens: i.name.toLowerCase().split(' '),
            })),
        [employmentTypes]
    );

    return (
        <Form.Row>
            {!hasJobOccupationMappings && (
                <Col xs={12} md={6}>
                    <CentralCategorySelectLabel
                        label={occupationLabel}
                        categories={job.occupations}
                    />
                    <HookForm.MultiSelect
                        name="centralJobOccupationIds"
                        rules={{ validate: { required: arrayRequiredRule } }}
                        options={occupationOptions}
                        disabled={disabled}
                    />
                </Col>
            )}
            {!hasTypesOfWorkMappings && (
                <Col xs={12} md={6}>
                    <CentralCategorySelectLabel
                        label={typeOfWorkLabel}
                        categories={job.typesOfWork}
                    />
                    <HookForm.MultiSelect
                        name="centralJobEmploymentTypeIds"
                        rules={{ validate: { required: arrayRequiredRule } }}
                        options={employmentTypeOptions}
                        disabled={disabled}
                    />
                </Col>
            )}
        </Form.Row>
    );
};
