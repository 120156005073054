import { Badge } from 'react-bootstrap';
import { EmployerOption } from 'src/models/api-configuration/employer-option.model';

interface Props {
    label: string;
    categories: EmployerOption[];
}

export const CentralCategorySelectLabel = ({
    categories,
    label,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <div className="mb-1">
            <span className="d-block">{label} *</span>
            <small>
                Please select an option that best matches the following categories:{' '}
                {categories.map(i => (
                    <Badge key={i.id} variant="outline-secondary">
                        {i.name}
                    </Badge>
                ))}
                .
            </small>
        </div>
    );
};
