import { memo } from 'react';
import { LogoDynamic } from '../../../../clients-internal/dynamic-components/LogoDynamic';

// eslint-disable-next-line react/display-name
export const Logo = memo(() => {
    return (
        <div className="h-100 w-100 d-flex align-items-center">
            <LogoDynamic />
        </div>
    );
});
