import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { CardWrapper } from '../../../shared/components/bootstrap/cards/CardWrapper';
import { ThinPageContent } from '../../../shared/components/page-components/ThinPageContent';
import { RegisterStart } from '../components/start/RegisterStart';

export const RegisterStartPage = (): React.ReactElement<any, any> | null => {
    const {
        register: { title },
    } = useStrings();

    return (
        <ThinPageContent>
            <div className="content-small-width mx-auto">
                <h1>{title}</h1>
                <CardWrapper>
                    <RegisterStart />
                </CardWrapper>
            </div>
        </ThinPageContent>
    );
};
