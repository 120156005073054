import { Store } from 'redux';
import { RootState } from './features/reducer.root-index';

let storeValue: Store<RootState> | undefined;

const store = (value: Store<RootState>) => {
    storeValue = value;
};

// while it is possible for this to be defined, it really should never, ever be.
// if it is, the entire app breaks down.
const get = () => storeValue!;

export const storeRegistry = {
    store,
    get,
};
