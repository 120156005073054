import { push } from 'connected-react-router';
import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { jobPathCreator } from 'src/routes/employer/paths/job.paths';
import { isActionOf } from 'typesafe-actions';
import { RootEpic } from '../../epic.root-index';
import { jobPublishAsync } from '../job.actions';

export const jobPublishEpic = asyncEpicBase(
    jobPublishAsync,
    ({ api }, { payload }) => api.jobs.publish(payload),
    {
        success: result => jobPublishAsync.success(result.json),
        failure: (error, requestAction) => jobPublishAsync.failure(error, requestAction.payload),
    }
);

// if I'm using a modal, this whole page seems unnecessary, it exists to display a modal.
// Normally I wouldn't use modals, but the whole point of this page is to be an adertisement
// Am I really sacrificing my UI/UX Morals Highground in the name of marketing? Yes. Yes I am.
export const jobPublishRedirectEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(jobPublishAsync.success)),
        mergeMap(action => of(push(jobPathCreator.publishConfirmation(action.payload.data.id))))
    );
};
