import { Route, Switch } from 'react-router-dom';
import { IndividualDetailsPage } from '../../ui/features/individual/pages/IndividualDetailsPage';
import { IndividualEditPage } from '../../ui/features/individual/pages/IndividualEditPage';
import { individualPaths } from './paths/individual.paths';

export const IndividualRouter = () => {
    const { edit, details } = individualPaths;

    return (
        <Switch>
            <Route path={edit} component={IndividualEditPage} />
            <Route path={details} component={IndividualDetailsPage} />
        </Switch>
    );
};
