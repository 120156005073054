import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { EndorsementItem } from './EndorsementItem';

interface Props {
    endorsementId: number;
}

export const EndorsementDisplay = ({
    endorsementId,
}: Props): React.ReactElement<any, any> | null => {
    const endorsement = useRootSelector(state => state.endorsements.items[endorsementId]);

    if (!endorsement) {
        return null;
    }

    return <EndorsementItem endorsement={endorsement} size={70} />;
};
