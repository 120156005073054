import {
    EntityItemsState,
    EntityListState,
} from '../../../models/store-models/entity-list-state.model';
import { EntityBase } from '../../../models/store-models/entity-state.model';

export const listSelector: <TEntity extends EntityBase>(
    entityState: EntityListState<TEntity, any>,
    requestId: string
) => TEntity[] = (entityState, requestId) => {
    const listState = entityState.list[requestId];

    if (!listState) {
        return [];
    }

    return listItemSelector(entityState.items, listState.ids);
};

export const listItemSelector: <TEntity extends EntityBase>(
    itemsState: EntityItemsState<TEntity>,
    ids: number[]
) => TEntity[] = (itemsState, ids) => {
    return ids
        .map(id => itemsState[id])
        .filter(i => i !== undefined)
        .map(i => i!);
};
