import { useMemo } from 'react';
import { LocalCategoryMapV2 } from 'src/models/central/central-mapping.model';
import { useStrings } from './strings.hook';

export const useCategoryLabel = (categoryKey: keyof LocalCategoryMapV2) => {
    const {
        job: {
            form: { jobCategories, occupationCategories },
        },
        organisation: {
            form: { industries },
        },
    } = useStrings();

    return useMemo(() => {
        switch (categoryKey) {
            case 'industries':
                return industries.label;
            case 'occupations':
                return occupationCategories.label;
            case 'typesOfWork':
                return jobCategories.label;
        }
    }, [categoryKey, industries.label, jobCategories.label, occupationCategories.label]);
};
