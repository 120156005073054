import { faBell, faExclamationTriangle, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { Toast } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ToastModel } from '../../../../models/session/toast.model';
import { sessionToastDelete } from '../../../../logic/features/sessions/session.actions';
import { Icon } from '../../../shared/components/icon/Icon';
import { useSessionText } from '../hooks/session.hook';

interface Props {
    toast: ToastModel;
}

export const ToastDisplay = ({ toast }: Props): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();

    const { iconColor, icon } = useMemo(() => {
        switch (toast.variant) {
            case 'error':
                return {
                    iconColor: 'text-danger',
                    icon: faExclamationTriangle,
                };
            case 'warning':
                return {
                    iconColor: 'text-warning',
                    icon: faBell,
                };
            case 'success':
                return {
                    iconColor: 'text-success',
                    icon: faThumbsUp,
                };
        }
    }, [toast.variant]);

    const text = useSessionText(toast.type, toast.action);

    const onClose = () => {
        dispatch(sessionToastDelete({ id: toast.id }));
    };

    return (
        <Toast onClose={onClose} show={true} delay={4000} autohide={toast.autoHide}>
            <Toast.Header>
                <Icon iconClassName={`${iconColor} mr-2`} icon={icon} size="lg" />
                <span className="mr-2">{text}</span>
            </Toast.Header>
        </Toast>
    );
};
