import { of } from 'rxjs';
import { delay, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import * as actions from './email-claims.actions';
import { RootEpic } from '../epic.root-index';
import { asyncEpicBase } from '../../helpers/epics/async.epic-helper';

export const emailClaimsAddAsyncEpic = asyncEpicBase(
    actions.emailClaimsAddAsync,
    (services, action) => services.emailClaims.addEmailClaim(action.payload),
    {
        success: () => actions.emailClaimsAddAsync.success(),
        failure: error => actions.emailClaimsAddAsync.failure(error),
    }
);

export const userInfoAsyncEpic = asyncEpicBase(
    actions.userInfoAsync,
    services => services.emailClaims.getUserInfo(),
    {
        success: result => actions.userInfoAsync.success(result.json),
        failure: error => actions.userInfoAsync.failure(error),
    }
);

export const userInfoPollStartEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(actions.emailClaimsAddAsync.success)),
        mergeMap(() => of(actions.userInfoAsync.request()))
    );
};

export const userInfoPollContinueEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(actions.userInfoAsync.success)),
        delay(1500),
        filter(() => state$.value.emailClaims.modal.show),
        mergeMap(() => of(actions.userInfoAsync.request()))
    );
};

export const userInfoPollCheckEmailEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(actions.userInfoAsync.success)),
        filter(() => !!state$.value.emailClaims.email),
        filter(action => {
            const emailToCheck = state$.value.emailClaims.email!;
            // oidc states that this should be a string
            // but it can be an array. I assume that the offical standard
            // states that this should only ever be one value. But the library
            // does handle multiple values, it simply does not use the "correct" type definition (string | string[] | undefined)
            // Also, the email_verified will return as an array of booleans if there is more than one value
            const emails = action.payload.email;

            return (
                (Array.isArray(emails) && emails.includes(emailToCheck)) || emailToCheck === emails
            );
        }),
        mergeMap(() => of(actions.emailClaimsShowModal({ show: false })))
    );
};

export const userInfoCancelOnModalCloseEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(actions.emailClaimsShowModal)),
        filter(action => !action.payload.show && state$.value.emailClaims.userInfoFetch.loading),
        mergeMap(() => of(actions.userInfoAsync.cancel()))
    );
};

// TODO, this won't work if I want to auto select the email in the select list, revise!
export const emailClaimsResetEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(actions.emailClaimsShowModal)),
        filter(action => !action.payload.show),
        mergeMap(() => of(actions.emailClaimsReset()))
    );
};
