import { push } from 'connected-react-router';
import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { isRouteMatch } from 'src/logic/helpers/epics/location.epic-helper';
import { isActionOf } from 'typesafe-actions';
import { jobPathCreator, jobPaths } from '../../../../routes/employer/paths/job.paths';
import { RootEpic } from '../../epic.root-index';
import { jobDeleteAsync } from '../job.actions';

export const jobDeleteEpic = asyncEpicBase(
    jobDeleteAsync,
    ({ api }, { payload }) => api.jobs.deleteJob(payload),
    {
        success: result => jobDeleteAsync.success(result.json),
        failure: (error, requestAction) => jobDeleteAsync.failure(error, requestAction.payload),
    }
);

export const jobDeleteRedirectEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(jobDeleteAsync.success)),
        filter(() => isRouteMatch(state$.value.router.location, jobPaths.details)),
        mergeMap(() => of(push(jobPathCreator.listDefault())))
    );
};
