import { useDispatch } from 'react-redux';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { settingsUpdateAsync } from '../../../../../logic/features/settings/setting.actions';
import { Setting } from '../../../../../models/settings/setting.model';
import { HookForm } from '../../../../shared/components/forms/HookForm';
import { useFormDefault } from '../../../../shared/hooks/form-default.hook';

interface Props {
    settings: Setting[];
}

interface FormValue {
    [name: string]: boolean;
}

export const SettingsForm = ({ settings }: Props): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();

    const { update } = useRootSelector(state => state.settings.request);
    const defaultValues: FormValue = {};
    settings.forEach(setting => (defaultValues[setting.name.toString()] = !!setting.subscribed));

    const formMethods = useFormDefault<FormValue>({
        defaultValues,
        error: update.error,
    });

    const onSubmit = (data: FormValue) => {
        const toSubmit: Setting[] = settings.map(i => ({
            ...i,
            subscribed: !!data[i.name],
        }));

        dispatch(settingsUpdateAsync.request(toSubmit));
    };

    return (
        <HookForm {...formMethods} onSubmit={onSubmit}>
            <HookForm.ErrorAlert error={update.error} />
            {settings.map(setting => (
                <div key={setting.id}>
                    <HookForm.Checkbox label={setting.name} name={setting.name.toString()} />
                </div>
            ))}
            <HookForm.Buttons showSpinner={update.loading} />
        </HookForm>
    );
};
