import { SiteDisplay } from '@careerhub/content-shared';
import { Helmet } from 'react-helmet';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { HeroDynamic } from '../../../../clients-internal/dynamic-components/HeroDynamic';
import { ContentFooter } from '../components/ContentFooter';
import { useContentAnchorOverride } from '../hooks/content-anchor-override.hook';
import { useContentScroll } from '../hooks/content-scroll.hook';
import { useContent } from '../hooks/content.hook';

export const HomePage = (): React.ReactElement<any, any> | null => {
    const { context, documentTitle, firstCollectionId, fetchState, config } =
        useContent('employerContent');
    const { onClick } = useContentAnchorOverride();
    useContentScroll('employerContent');

    return (
        <div onClick={onClick}>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>

            <HeroDynamic />
            <div className="content-wrapper">
                {fetchState.loading && (
                    <div className="text-center my-5">
                        <Loader state={fetchState} />
                    </div>
                )}
                {firstCollectionId && (
                    <SiteDisplay
                        context={context}
                        config={config}
                        activeCollectionId={firstCollectionId}
                        contentClassName="mx-auto"
                    />
                )}
                <ContentFooter />
            </div>
        </div>
    );
};
