import { useDispatch } from 'react-redux';
import { jobPublishAsync } from '../../../../logic/features/jobs/job.actions';
import { JobPublishRequest } from '../../../../models/jobs/job-publish-request.model';
import { CardWrapper } from '../../../shared/components/bootstrap/cards/CardWrapper';
import { Loader } from '../../../shared/components/loader/Loader';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';
import { useFormButton } from '../../../shared/hooks/form-button.hook';
import { JobPublishForm } from '../components/forms/JobPublishForm';
import { useActiveJob } from '../hooks/active-job.hook';

export const JobPublishPage = (): React.ReactElement<any, any> | null => {
    const { job, fetch, update } = useActiveJob();
    const dispatch = useDispatch();
    const onSubmit = (data: JobPublishRequest) => dispatch(jobPublishAsync.request(data));
    const { onCancel } = useFormButton();

    return (
        <ApplicationPageContent>
            <Loader useCard={true} state={fetch}>
                {job && (
                    <CardWrapper>
                        <JobPublishForm
                            job={job}
                            onSubmit={onSubmit}
                            onCancel={onCancel}
                            showSpinner={update.loading}
                            error={update.error}
                        />
                    </CardWrapper>
                )}
            </Loader>
        </ApplicationPageContent>
    );
};
