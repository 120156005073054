import { useDispatch } from 'react-redux';
import { eventRequestUpdateAsync } from 'src/logic/features/event-unions/event-union.actions';
import { CardWrapper } from 'src/ui/shared/components/bootstrap/cards/CardWrapper';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { ApplicationPageContent } from 'src/ui/shared/components/page-components/ApplicationPageContent';
import { useActiveEntity } from 'src/ui/shared/hooks/active-entity.hook';
import { useFormButton } from 'src/ui/shared/hooks/form-button.hook';
import { EventRequestForm, EventRequestFormResponse } from '../components/forms/EventRequestForm';

export const EventEditPage = (): React.ReactElement<any, any> | null => {
    const disptach = useDispatch();
    const { entity, fetch, update } = useActiveEntity(state => state.eventUnions);
    const { onCancel } = useFormButton();

    const saveAsDraft = (data: EventRequestFormResponse) => {
        disptach(
            eventRequestUpdateAsync.request(
                data as EventRequestFormResponse & { eventRequest: { id: number } },
                {
                    id: data.eventRequest.id!,
                    redirectToPublish: false,
                }
            )
        );
    };

    const onSubmit = (data: EventRequestFormResponse) => {
        disptach(
            eventRequestUpdateAsync.request(
                data as EventRequestFormResponse & { eventRequest: { id: number } },
                {
                    id: data.eventRequest.id!,
                    redirectToPublish: true,
                }
            )
        );
    };

    return (
        <ApplicationPageContent>
            <Loader state={fetch} useCard={true}>
                {entity && entity.eventRequest && (
                    <CardWrapper>
                        <EventRequestForm
                            eventRequest={entity.eventRequest}
                            onSubmit={onSubmit}
                            onSaveDraft={saveAsDraft}
                            onCancel={onCancel}
                            state={update}
                        />
                    </CardWrapper>
                )}
            </Loader>
        </ApplicationPageContent>
    );
};
