import { FieldError } from 'react-hook-form';

interface Props {
    helpText?: React.ReactNode;
    className?: string;
    error: FieldError | FieldError[] | undefined;
}

const ControlHelpText = ({
    helpText,
    error,
    className,
}: Props): React.ReactElement<any, any> | null => {
    return helpText && !error ? (
        <small className={`text-muted ${className ? className : ''}`}>{helpText}</small>
    ) : null;
};

export default ControlHelpText;
