import { LoaderState } from 'src/models/errors/error.model';
import { useCentralPublishErrors } from '../../hooks/central-publish-errors.hook';
import { CentralValidationErrorAlert } from '../publish/CentralValidationErrorAlert';

interface Props {
    state: LoaderState | LoaderState[] | undefined;
}

export const CentralPublishErrorInner = ({ state }: Props): React.ReactElement<any, any> | null => {
    const stateArray = Array.isArray(state) ? state : state ? [state] : [];
    const errorArray = stateArray.filter(s => !!s.error).map(i => i.error!);
    const error = errorArray.length > 0 ? errorArray[0] : undefined;

    return <CentralValidationErrorAlert error={error} />;
};

export const CentralPublishErrors = (): React.ReactElement<any, any> | null => {
    const { register, contactStates, jobCreateState, jobAttachmentStates } =
        useCentralPublishErrors();

    return (
        <>
            <CentralPublishErrorInner state={register} />
            <CentralPublishErrorInner state={contactStates} />
            <CentralPublishErrorInner state={jobCreateState} />
            <CentralPublishErrorInner state={jobAttachmentStates} />
        </>
    );
};
