import { DescriptionSelectLabel } from '../react-selects/DescriptionSelectLabel';
import { ReactSelectOption } from '../react-selects/SingleSelectControl';

export type EventLocationSelectValue = 'online' | 'onCampus' | 'onCampusRequest' | 'offCampus';

export const eventLocationOptions: ReactSelectOption<EventLocationSelectValue>[] = [
    {
        label: (
            <DescriptionSelectLabel
                key="1"
                title="Online"
                description="To be held via an online service."
            />
        ),
        value: 'online',
        tokens: ['online'],
    },
    {
        label: (
            <DescriptionSelectLabel
                key="2"
                title="On campus location"
                description="I have booked, or know the venue I wish to use."
            />
        ),
        value: 'onCampus',
        tokens: ['on campus location'],
    },
    {
        label: (
            <DescriptionSelectLabel
                key="3"
                title="On campus request"
                description="Request a location to be provided or added to the location list."
            />
        ),
        value: 'onCampusRequest',
        tokens: ['on campus request'],
    },
    {
        label: (
            <DescriptionSelectLabel
                key="4"
                title="Off campus"
                description="I will organise my own venue."
            />
        ),
        value: 'offCampus',
        tokens: ['off campus'],
    },
];
