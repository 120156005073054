import { EntityDetailsTitle } from 'src/ui/shared/components/titles/EntityDetailsTitle';
import { Job } from '../../../../../models/jobs/job.model';
import { JobActionDropdown } from '../actions/JobActionDropdown';
import { JobListBreadcrumb } from '../breadcrumbs/JobListBreadcrumb';

interface Props {
    job?: Job;
    hideLoader?: boolean;
}

export const JobDetailsTitle = ({
    job,
    hideLoader,
}: Props): React.ReactElement<any, any> | null => {
    if (!job) {
        return null;
    }

    return (
        <EntityDetailsTitle
            title={job.title}
            hideLoader={hideLoader}
            before={<JobListBreadcrumb />}
            after={<JobActionDropdown job={job} size="md" />}
        />
    );
};
