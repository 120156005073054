import { eventPathCreator } from 'src/routes/employer/paths/event.paths';
import { BreadcrumbLink } from 'src/ui/shared/components/breadcrumbs/BreadcrumbLink';
import { useEventUnionTitles } from '../../hooks/event-union-titles.hook';
import { EventSubPageTitle } from '../breadcrumbs/EventSubPageTitle';

export const EventChangeRequestAddPageNav = (): React.ReactElement<any, any> | null => {
    const { eventUnion } = useEventUnionTitles();

    return (
        <EventSubPageTitle>
            {eventUnion && (
                <>
                    <BreadcrumbLink
                        to={eventPathCreator.changeRequestList(eventUnion?.id)}
                        className="text-long-title"
                    >
                        Change requests
                    </BreadcrumbLink>
                    Add
                </>
            )}
        </EventSubPageTitle>
    );
};
