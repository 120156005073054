import { useMemo } from 'react';
import { eventLocationOptions } from 'src/ui/shared/components/forms/controls/event-location/EventLocationOptions';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';

export const useEventLocationOptions = () => {
    const { value } = useApiConfiguration();

    const options = useMemo(
        () =>
            eventLocationOptions.filter(
                x =>
                    (x.value === 'offCampus' && !value?.events?.disableOffCampusLocation) ||
                    (x.value === 'onCampus' && !value?.events?.disableOnCampusLocation) ||
                    (x.value === 'onCampusRequest' && !value?.events?.disableOnCampusRequest) ||
                    (x.value === 'online' && !value?.events?.disableOnlineLocation)
            ),
        [
            value?.events?.disableOffCampusLocation,
            value?.events?.disableOnCampusLocation,
            value?.events?.disableOnCampusRequest,
            value?.events?.disableOnlineLocation,
        ]
    );

    return options;
};
