import { faUser } from '@fortawesome/free-solid-svg-icons';
import { IndividualIdentity } from '../../../../../models/authentication/identity.model';
import { IdentityListItem, IdentityListItemBaseProps } from './IdentityListItem';

interface Props extends IdentityListItemBaseProps {
    identity: IndividualIdentity;
}

export const IndividualIdentityListItem = (props: Props): React.ReactElement<any, any> | null => {
    const { identity } = props;

    return (
        <IdentityListItem {...props} icon={faUser}>
            {identity.firstName} {identity.lastName}
        </IdentityListItem>
    );
};
