import { ApplicationPageContent } from 'src/ui/shared/components/page-components/ApplicationPageContent';
import { IdentityList } from '../components/identity-list/IdentityList';

export const PrivateIdentityListPage = (): React.ReactElement<any, any> | null => {
    return (
        <ApplicationPageContent>
            <IdentityList />
        </ApplicationPageContent>
    );
};
