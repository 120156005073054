import { useMemo } from 'react';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';
import { useIsCareerHubVersion } from 'src/ui/shared/hooks/is-careerhub-version.hook';
import { useWorkGroups } from '../../work-groups/hooks/work-groups.hook';

// I pulled this into it's own hook
// because it can be confusing when the API simple doesn't return the "disableEvents" property
// which can happen prior to version 5.9
// and doing a !disableEvents is not correct because !undefined returns true.
export const useEventsEnabled = () => {
    const { value: config, includes } = useApiConfiguration();
    const isEventsEnabled = config?.settings?.disableEvents === false;
    const isBookingListEnabled = config?.events?.enableBookingList === true;
    const v5_10 = useIsCareerHubVersion(5, 10);

    const isSkillsEnabled = useMemo(() => {
        if (!v5_10) {
            return false;
        }

        return config?.events?.disableSkills === false;
    }, [config?.events?.disableSkills, v5_10]);

    const { workgroups, publishableWorkGroups } = useWorkGroups();

    const validEventTypes = useMemo(() => {
        const workGroupIds = workgroups?.map(x => x.id) || [];
        return (includes.eventTypes || [])
            .filter(i => !i.isArchived)
            .filter(x => !x.workGroupId || workGroupIds.includes(x.workGroupId));
    }, [includes.eventTypes, workgroups]);

    const eventTypeWorkGroups = useMemo(() => {
        const workGroupIds = validEventTypes.filter(x => !!x.workGroupId).map(x => x.workGroupId);

        return publishableWorkGroups.filter(x => workGroupIds.includes(x.id));
    }, [publishableWorkGroups, validEventTypes]);

    const canCreateEventRequests = useMemo(() => {
        return isEventsEnabled && validEventTypes.some(i => i.allowEventRequest);
    }, [isEventsEnabled, validEventTypes]);

    return {
        isEventsEnabled,
        isBookingListEnabled,
        isSkillsEnabled,
        canCreateEventRequests,
        eventTypes: validEventTypes,
        eventTypeWorkGroups,
    };
};
