import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { organisationPaths } from 'src/routes/employer/paths/organisation.paths';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useIsCareerHubVersion } from 'src/ui/shared/hooks/is-careerhub-version.hook';
import { LOGO_DISPLAY_SIZE } from '../../constants/logo.constants';
import { useOrganisationLogoUrl } from '../../hooks/organisation-logo-url.hook';
import { useOrganisation } from '../../hooks/organisation.hook';
import { OrganisationLogoDisplay } from './OrganisationLogoDisplay';

interface Props {
    children: React.ReactNode;
}

const logoSizePx = `${LOGO_DISPLAY_SIZE}px`;

export const OrganisationLogoHeaderDisplay = ({
    children,
}: Props): React.ReactElement<any, any> => {
    const { organisation } = useOrganisation();

    const logoUrl = useOrganisationLogoUrl(organisation?.bannerFileId);
    const v5_13 = useIsCareerHubVersion(5, 13);

    if (!v5_13 || !organisation) {
        return <>{children}</>;
    }

    return (
        <div className="d-flex align-items-start mb-3">
            <div className="mr-3">
                {logoUrl ? (
                    <OrganisationLogoDisplay url={logoUrl} />
                ) : (
                    <OverlayTrigger
                        placement="right"
                        overlay={porps => (
                            <Tooltip id="org-logo-tooltip" {...porps}>
                                <div>Add your organisation logo</div>
                            </Tooltip>
                        )}
                    >
                        <Link
                            to={organisationPaths.edit}
                            className="d-flex align-items-center justify-content-center bg-details-alt"
                            style={{ width: logoSizePx, height: logoSizePx }}
                        >
                            <Icon iconClassName="text-muted" size="lg" icon={faBuilding} />
                        </Link>
                    </OverlayTrigger>
                )}
            </div>
            <div className="flex-grow-1">{children}</div>
        </div>
    );
};
