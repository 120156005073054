import { CardWrapper } from 'src/ui/shared/components/bootstrap/cards/CardWrapper';
import { LoaderText } from 'src/ui/shared/components/loader/LoaderText';
import { ApplicationPageContent } from 'src/ui/shared/components/page-components/ApplicationPageContent';

// the redirect logic is handled in the epics
export const CareerHubRedirectPage = (): React.ReactElement<any, any> | null => {
    return (
        <ApplicationPageContent>
            <CardWrapper>
                <LoaderText loading={true}>You are being redirected.</LoaderText>
            </CardWrapper>
        </ApplicationPageContent>
    );
};
