import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { centralIdentityRedirect } from 'src/logic/features/authentication/authentication.actions';
import { useAuthentication } from '../hooks/authentication.hook';

export const CentralIdentityRedirectGuard = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    const { initComplete, hasOidcUser, isImpersonation } = useAuthentication();

    const shouldRedirectToIdentity = useMemo(() => {
        if (!initComplete) {
            return false;
        }

        return !isImpersonation && !hasOidcUser;
    }, [hasOidcUser, initComplete, isImpersonation]);

    useEffect(() => {
        if (shouldRedirectToIdentity) {
            dispatch(centralIdentityRedirect());
        }
    }, [dispatch, shouldRedirectToIdentity]);

    return <>{children}</>;
};
