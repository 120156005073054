import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useOrganisation } from 'src/ui/features/organisation/hooks/organisation.hook';
import { CardWrapper } from 'src/ui/shared/components/bootstrap/cards/CardWrapper';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useIsCentralOrganisationOrAlreadyPublished } from '../../hooks/central-already-published.hook';
import { useCentralSimilarOrganisations } from '../../hooks/central-similar-organisations.hook';
import { CentralPublishConfirmationForm } from '../publish-sections/CentralPublishConfirmationForm';
import { CentralPublishContactInviteInfo } from '../publish-sections/CentralPublishContactInviteInfo';
import { CentralPublishErrors } from '../publish-sections/CentralPublishErrors';
import { CentralPublishJobAttachmentInfo } from '../publish-sections/CentralPublishJobAttachmentInfo';
import { CentralPublishJobInfo } from '../publish-sections/CentralPublishJobInfo';
import { CentralPublishOrganisationInfo } from '../publish-sections/CentralPublishOrganisationInfo';
import { CentralSimilarOrganisationInfo } from '../similar/CentralSimilarOrganisationInfo';

export const CentralPublish = (): React.ReactElement<any, any> => {
    const [showMore, setShowMore] = useState(false);
    const {
        localJobId,
        organisationListFetch,
        jobListFetch,
        similar: { fetch: similarListFetch },
        configuration: { fetch: configurationFetch },
    } = useRootSelector(state => state.central);
    const { fetch: orgFetch, organisation } = useOrganisation();
    const jobFetch = useRootSelector(state =>
        localJobId ? state.jobs.single[localJobId]?.fetch : undefined
    );
    const job = useRootSelector(state => (localJobId ? state.jobs.items[localJobId] : undefined));
    const contactAllFetch = useRootSelector(state => state.contacts.all.fetch);
    const [inviteContactIds, setInviteContactIds] = useState<number[]>([]);

    const onContactInviteUpdate = (ids: number[]) => setInviteContactIds(ids);
    const orgComplete = useIsCentralOrganisationOrAlreadyPublished();

    const {
        show: showSimilar,
        setHasIgnored,
        items: similarItems,
        hasExactSimilarId,
    } = useCentralSimilarOrganisations(organisation);

    // todo: this should be moved to the strings
    const messageToShow = useMemo(() => {
        if (!orgComplete && localJobId) {
            return 'Your organisation will be registered and your job will be submitted for approval in CareerHub Central.';
        }

        if (localJobId) {
            return 'Your job will be submitted for approval in CareerHub Central.';
        }

        return 'Your organisation will be registered in CareerHub Central';
    }, [localJobId, orgComplete]);

    // jesus christ, look how many states this god damn page relies on
    // MSP is a going to be so god damn painful
    const loadingStates = [
        orgFetch,
        jobFetch,
        contactAllFetch,
        organisationListFetch,
        jobListFetch,
        similarListFetch,
        configurationFetch,
    ]
        .filter(i => i !== undefined)
        .map(i => i!);

    return (
        <Loader state={loadingStates} useCard={true}>
            {showSimilar && (
                <CardWrapper className="mb-2">
                    <CentralSimilarOrganisationInfo
                        items={similarItems}
                        exactSimilarId={hasExactSimilarId}
                    />
                    <Button
                        onClick={() => setHasIgnored(true)}
                        disabled={!!hasExactSimilarId}
                        title={
                            hasExactSimilarId
                                ? 'You cannot register an organistion with the same Name, Divison and Country as an organisation that already exists.'
                                : undefined
                        }
                    >
                        Ignore similar organisations
                    </Button>
                </CardWrapper>
            )}

            {!showSimilar && (
                <CardWrapper>
                    <CentralPublishErrors />
                    <p className="mb-1">{messageToShow}</p>
                    <Button variant="link" onClick={() => setShowMore(v => !v)} className="px-0">
                        <Icon icon={showMore ? faCaretDown : faCaretRight}>
                            {showMore ? 'Show less' : 'Show more'}
                        </Icon>
                    </Button>
                    {showMore && (
                        <>
                            {organisation && (
                                <CentralPublishOrganisationInfo organisation={organisation} />
                            )}

                            {organisation && (
                                <CentralPublishContactInviteInfo
                                    organisationId={organisation.id}
                                    onUpdate={onContactInviteUpdate}
                                />
                            )}

                            {job && <CentralPublishJobInfo job={job} />}

                            {job &&
                                job.attachments.map((attachment, index) => (
                                    <CentralPublishJobAttachmentInfo
                                        key={index}
                                        attachment={attachment}
                                        index={index}
                                        jobId={job.id}
                                    />
                                ))}
                        </>
                    )}

                    {!showMore && <hr />}
                    {organisation && (
                        <CentralPublishConfirmationForm
                            organisation={organisation}
                            job={job}
                            inviteContactIds={inviteContactIds}
                        />
                    )}
                </CardWrapper>
            )}
        </Loader>
    );
};
