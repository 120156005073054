import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Container } from 'react-bootstrap';
import { useRootSelector } from '../../hooks/root-selector.hook';
import { AlertWrapper } from '../bootstrap/alerts/AlertWrapper';
import { ContentTopNav } from '../navigation/content-navigation/ContentTopNav';

interface Props {
    children: React.ReactNode;
}

export const ComponentDisabledGuard = ({
    children,
}: Props): React.ReactElement<any, any> | null => {
    const disableApplication = useRootSelector(
        state => state.apiConfiguration.value?.settings?.disableApplication
    );

    return disableApplication ? (
        <Container fluid className="p-0">
            <ContentTopNav />

            <div className="d-flex p-5">
                <AlertWrapper className="mx-auto" variant="danger" icon={faExclamationTriangle}>
                    This service is currently unavailable.
                </AlertWrapper>
            </div>
        </Container>
    ) : (
        <>{children}</>
    );
};
