import { Route, Switch } from 'react-router-dom';
import { RegisterContactPage } from '../../ui/features/register/pages/RegisterContactPage';
import { RegisterIndividualPage } from '../../ui/features/register/pages/RegisterIndividualPage';
import { RegisterNamePage } from '../../ui/features/register/pages/RegisterNamePage';
import { RegisterOrganisationPage } from '../../ui/features/register/pages/RegisterOrganisationPage';
import { RegisterRequestAccessPage } from '../../ui/features/register/pages/RegisterRequestAccessPage';
import { RegisterSimilarPage } from '../../ui/features/register/pages/RegisterSimilarPage';
import { RegisterStartPage } from '../../ui/features/register/pages/RegisterStartPage';
import { registerPaths } from './register.paths';

export const RegisterRouter = () => {
    const { start, name, similar, requestAccess, organisation, contact, individual } =
        registerPaths;

    return (
        <Switch>
            <Route path={name} component={RegisterNamePage} />
            <Route path={similar} component={RegisterSimilarPage} />
            <Route path={requestAccess} component={RegisterRequestAccessPage} />
            <Route path={organisation} component={RegisterOrganisationPage} />
            <Route path={contact} component={RegisterContactPage} />
            <Route path={individual} component={RegisterIndividualPage} />
            <Route path={start} component={RegisterStartPage} />
        </Switch>
    );
};
