import { Contact } from '../../../../../models/contacts/contact.model';
import { PageTitle } from '../../../../shared/components/page-components/PageTitle';
import { ContactListBreadcrumb } from '../breadcrumbs/ContactListBreadcrumb';
import { ContactName } from '../shared/ContactName';
import { ContactActionDropdown } from '../actions/ContactActionDropdown';
import { TitleLoader } from '../../../../shared/components/loader/TitleLoader';

interface Props {
    contact?: Contact;
}

export const ContactDetailsTitle = ({ contact }: Props): React.ReactElement<any, any> | null => {
    return (
        <div className="d-flex align-items-center">
            <PageTitle>
                <ContactListBreadcrumb />
                {contact && <ContactName contact={contact} />}
            </PageTitle>
            {!contact && <TitleLoader />}
            {contact && <ContactActionDropdown contact={contact} size="md" />}
        </div>
    );
};
