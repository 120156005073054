import { useMemo } from 'react';
import { Badge } from 'react-bootstrap';
import { EventRequest } from 'src/models/events/event-request.model';

interface Props {
    eventRequest: EventRequest;
}

export const EventRequestStatusBadge = ({
    eventRequest,
}: Props): React.ReactElement<any, any> | null => {
    const badgeClass = useMemo(() => {
        switch (eventRequest.status) {
            case 'Draft':
            case 'Pending':
                return 'outline-secondary';
            case 'Approved':
                return 'outline-success';
            case 'Rejected':
                return 'outline-danger';
            default:
                return 'outline-secondary';
        }
    }, [eventRequest.status]);

    return (
        <Badge variant={badgeClass} className="font-weight-normal">
            {eventRequest.status}
        </Badge>
    );
};
