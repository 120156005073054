import { ListGroupWrapper } from 'src/ui/shared/components/lists/ListGroupWrapper';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { ApplicationPageContent } from 'src/ui/shared/components/page-components/ApplicationPageContent';
import { ListPaginationWrapper } from 'src/ui/shared/components/page-components/ListPaginationWrapper';
import { useActiveEntityList } from 'src/ui/shared/hooks/active-entity-list.hook';
import { EventUnionListItem } from '../components/list/EventUnionListItem';
import { EventListStatusFilter } from '../components/list/EventUnionStatusFilter';
import { EventCancelledAlert } from '../components/details/EventCancelledAlert';

export const EventListPage = (): React.ReactElement<any, any> | null => {
    const {
        entities: eventUnions,
        fetch,
        pagination,
    } = useActiveEntityList(state => state.eventUnions);

    return (
        <ApplicationPageContent className="event-list-page">
            <EventCancelledAlert />
            <ListPaginationWrapper pagination={pagination}>
                <EventListStatusFilter />
            </ListPaginationWrapper>
            <Loader state={fetch} useCard={true}>
                <ListGroupWrapper items={eventUnions}>
                    {eventUnions.map(i => (
                        <EventUnionListItem key={i.id} eventUnion={i} />
                    ))}
                </ListGroupWrapper>
            </Loader>
        </ApplicationPageContent>
    );
};
