import { Organisation } from 'src/models/organisation/organisation.model';

interface Props {
    organisation: Organisation;
}

export const OrganisationNameDivision = ({
    organisation,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <span>
            <span>{organisation.name}</span>
            {organisation.division && <small> - {organisation.division}</small>}
        </span>
    );
};
