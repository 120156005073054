import { useMemo } from 'react';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';

export const useEventOnCampusValues = () => {
    const { includes } = useApiConfiguration();

    const validBuildings = useMemo(() => {
        return includes.campusBuildings?.filter(i => !i.isArchived) || [];
    }, [includes.campusBuildings]);

    const validLocations = useMemo(() => {
        return (includes.campusLocations || [])
            .filter(i => !i.isArchived)
            .filter(i => !i.buildingId || validBuildings.map(b => b.id).includes(i.buildingId));
    }, [includes.campusLocations, validBuildings]);

    const validCampuses = useMemo(() => {
        return (includes.campuses || [])
            .filter(i => !i.isArchived && !i.isOnline)
            .filter(i => validLocations.map(x => x.campusId).includes(i.id));
    }, [includes.campuses, validLocations]);

    return {
        buildings: validBuildings,
        locations: validLocations,
        campuses: validCampuses,
    };
};
