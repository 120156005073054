import {
    Contact as CentralContact,
    Job as CentralJob,
    OrganisationRegistration as CentralRegistration,
    OrganisationScope as CentralOrganisationScope,
    OrganisationSize,
} from '@symplicity/central-types';
import { CountryCode } from 'src/clients-internal/configuration.model';
import {
    centralJobCreateAsync,
    CentralPublishNextStepPayload,
} from 'src/logic/features/central/central.actions';
import { CentralPublishState } from 'src/logic/features/central/central.reducer';
import { LocalCategoryMapV2 } from 'src/models/central/central-mapping.model';
import { Contact } from 'src/models/contacts/contact.model';
import { Job } from 'src/models/jobs/job.model';
import { SocialType } from 'src/models/organisation/social-property.model';
import {
    getCentralMappingsV2,
    mapCentralGeolocation,
    mapCentralLocation,
} from './central-mapping.helper';

export const createCentralRegistration = (
    centralPublishState: CentralPublishState,
    extras: {
        verifiedContactEmail: string;
        localContact: Contact;
        localMap: LocalCategoryMapV2;
        countryCode: CountryCode;
    }
): CentralRegistration => {
    const {
        organisation: localOrg,
        centralOrganisationIndustryIds: selectedIndustryIds,
        centralOrganisationSize: selectedSize,
        centralOrganisationScope: selectedScope,
    } = centralPublishState;

    const { verifiedContactEmail, localContact, localMap } = extras;

    if (!localOrg) {
        // should be impossible to hit.
        throw new Error('localOrg is required.');
    }

    // this will fail if it's actually empty...
    const localIndustryIds = localOrg.industries?.map(i => i.id) || [];

    // special hard-coded logic because multiple UK universities have the multinational value
    // this could be done better, at the redux-state step, and stored as the value...
    const scope: CentralOrganisationScope | undefined =
        localOrg.scope?.name?.toLowerCase() === 'multinational'
            ? 'International'
            : (localOrg.scope?.name as CentralOrganisationScope);

    // we have to create the organisation first
    const getSocialProperty = (type: SocialType) =>
        localOrg.socialProperties?.find(x => x.socialType === type)?.value;

    const centralOrganisation: Omit<
        CentralRegistration['organisation'],
        'isApproved' | 'id' | 'addedDate' | 'isManager' | 'status'
    > = {
        // common fields with common types
        acronym: localOrg.acronym,
        businessId: localOrg.businessId,
        city: localOrg.city,
        countryCode: localOrg.countryCode!,
        description: localOrg.description,
        division: localOrg.division,
        email: localOrg.email,
        name: localOrg.name,
        phone: localOrg.phone,
        postcode: localOrg.postcode,
        private: localOrg.private,
        region: localOrg.region,
        street: localOrg.street,
        url: localOrg.url,
        type: localOrg.employerType,
        scope: selectedScope ? selectedScope : scope,

        size: (selectedSize || localOrg.size?.name) as OrganisationSize,
        facebookPageName: getSocialProperty('Facebook'),
        instagramHandle: getSocialProperty('Instagram'),
        linkedInCompany: getSocialProperty('LinkedIn'),
        twitterHandle: getSocialProperty('Twitter'),
        industryIds:
            selectedIndustryIds || getCentralMappingsV2(localMap, 'industries', localIndustryIds),
        geolocation: mapCentralGeolocation(localOrg.geolocation),

        // importante! because they are required and are used for future validation (and future merging)
        nodeEntityId: localOrg.id.toString(),
    };

    const centralContact = createCentralContact(localContact, centralPublishState, {
        email: verifiedContactEmail,
        keyContact: true,
    });

    return {
        contact: centralContact as CentralContact,
        organisation: centralOrganisation as CentralRegistration['organisation'],
    };
};

export const createCentralContact = (
    localContact: Contact,
    centralPublishState: Pick<CentralPublishState, 'subscribeCentral' | 'subscribeCentralApps'>,
    overrides: { email: string; keyContact: boolean }
) => {
    const { subscribeCentral, subscribeCentralApps } = centralPublishState;

    const contact: Omit<
        CentralContact,
        'organisationId' | 'status' | 'id' | 'addedDate' | 'emailConfirmed'
    > = {
        firstName: localContact.firstName,
        lastName: localContact.lastName,
        email: overrides.email,
        keyContact: overrides.keyContact,
        position: localContact.positionTitle,
        subscribeCentral: !!subscribeCentral,
        subscribeApps: !!subscribeCentralApps,
        nodeEntityId: localContact.id.toString(),
    };

    return contact;
};

export const createCentralJob = (options: {
    localJob: Job;
    centralOrganisationId: number;
    centralContactId: number | undefined;
    localMapV2: LocalCategoryMapV2;
    centralJobOccupationIds: number[] | undefined;
    centralJobEmploymentTypeIds: number[] | undefined;
}) => {
    const {
        localJob,
        centralOrganisationId,
        centralContactId,
        localMapV2,
        centralJobEmploymentTypeIds: selectedEmploymentTypeIds,
        centralJobOccupationIds: selectedOccupationIds,
    } = options;

    const typesOfWorkIds = localJob.typesOfWork.map(t => t.id);
    const occupationIds = localJob.occupations.map(t => t.id);

    const mappedOccupationIds = getCentralMappingsV2(localMapV2, 'occupations', occupationIds);
    const mappedEmploymentTypeIds = getCentralMappingsV2(localMapV2, 'typesOfWork', typesOfWorkIds);

    const centralJob: Partial<CentralJob> = {
        // common fields with the same types
        commences: localJob.commences,
        positionsAvailable: localJob.positionsAvailable,
        details: localJob.details,
        externalReference: localJob.externalReference,
        hideRecruitingFor: localJob.hideRecruitingFor,
        procedures: localJob.procedures,
        recruitingFor: localJob.recruitingFor,
        remuneration: localJob.remuneration,
        summary: localJob.summary,
        title: localJob.title,
        url: localJob.url,
        contractType: localJob.contractType,
        contractHours: localJob.contractHours,

        organisationId: centralOrganisationId,

        // sets both contact to the current user (the sent in contactId) always...
        displayContactId: localJob.displayContactId ? centralContactId : undefined,
        adminContactId: centralContactId,

        // mapped cats meow
        employmentTypeIds: selectedEmploymentTypeIds || mappedEmploymentTypeIds,
        occupationIds: selectedOccupationIds || mappedOccupationIds,

        locations: localJob.locations.map(mapCentralLocation),

        // importante!
        nodeEntityId: localJob.id.toString(),
    };

    return centralJob;
};

export const shouldRetryJobFailure = (
    action: ReturnType<(typeof centralJobCreateAsync)['failure']>
) => {
    return (
        action.meta.isInitialAttempt &&
        action.payload.status === 400 &&
        !!action.payload.validationErrors
    );
};

export const determineNextStep = (options: {
    currentStep: CentralPublishNextStepPayload;
    publish: CentralPublishState;
}): CentralPublishNextStepPayload => {
    const {
        publish,
        currentStep: { step, index },
    } = options;

    const centralOrganisationExists = !!publish.centralOrganisationId;
    const jobExistsInState = !!publish.job;
    const attachmentCount = publish.job ? publish.job.attachments.length : 0;
    const inviteContactIds = publish.inviteContactIds || [];

    switch (step) {
        case 'init': {
            if (!centralOrganisationExists) {
                return { step: 'organisation' };
            } else if (jobExistsInState) {
                return { step: 'job' };
            } else {
                return { step: 'complete' };
            }
        }

        case 'organisation':
        case 'contactInvite': {
            const inviteLength = inviteContactIds.length;
            if (inviteLength > 0 && index !== inviteLength - 1) {
                return {
                    step: 'contactInvite',
                    index: step === 'organisation' ? 0 : index! + 1,
                };
            }

            if (jobExistsInState) {
                return { step: 'job' };
            } else {
                return { step: 'complete' };
            }
        }

        case 'job':
        case 'jobAttachment': {
            if (attachmentCount > 0 && index !== attachmentCount - 1) {
                return {
                    step: 'jobAttachment',
                    index: step === 'job' ? 0 : index! + 1,
                };
            }

            return { step: 'complete' };
        }
        case 'complete':
        case 'error':
            throw new Error('invalid operation');
    }
};
