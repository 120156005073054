import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { individualUpdateAsync } from '../../../../logic/features/individuals/individual.actions';
import { Individual } from '../../../../models/individual/individual.model';
import { individualPaths } from '../../../../routes/employer/paths/individual.paths';
import { CardWrapper } from '../../../shared/components/bootstrap/cards/CardWrapper';
import { Loader } from '../../../shared/components/loader/Loader';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';
import { IndividualDetailsForm } from '../components/forms/IndividualDetailsForm';
import { useIndividual } from '../hooks/individual.hook';

export const IndividualEditPage = (): React.ReactElement<any, any> | null => {
    const { individual, fetch, update } = useIndividual();

    const dispatch = useDispatch();

    const onSubmit = (data: Individual) => {
        dispatch(individualUpdateAsync.request(data));
    };

    const onCancel = () => {
        dispatch(push(individualPaths.details));
    };

    return (
        <ApplicationPageContent>
            <Loader state={fetch} useCard={true}>
                <CardWrapper>
                    <IndividualDetailsForm
                        individual={individual}
                        onCancel={onCancel}
                        onSubmit={onSubmit}
                        showSpinner={update.loading}
                        error={update.error}
                    />
                </CardWrapper>
            </Loader>
        </ApplicationPageContent>
    );
};
