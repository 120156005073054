import { Icon, IconDefinitionAll } from '../icon/Icon';

interface Props {
    label: React.ReactNode;
    icon?: IconDefinitionAll;
    collapse?: boolean;
    children?: React.ReactNode;
}

export const DetailsProperty = ({
    label,
    children,
    icon,
    collapse,
}: Props): React.ReactElement<any, any> | null => {
    return children ? (
        <>
            {!collapse && <strong>{label}</strong>}
            <div
                className={collapse ? 'mb-1' : 'mb-3'}
                title={typeof label === 'string' ? label : undefined}
            >
                {icon ? <Icon icon={icon}>{children}</Icon> : children}
            </div>
        </>
    ) : null;
};
