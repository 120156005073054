import { OrganisationPendingAlert } from 'src/ui/features/organisation/components/alerts/OrganisationPendingAlert';

interface Props {
    children: React.ReactNode;
    className?: string;
}

export const ApplicationPageContent = ({
    children,
    className,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <div className={`p-4 content-width ${className || ''}`}>
            <OrganisationPendingAlert />
            {children}
        </div>
    );
};
