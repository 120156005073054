import { JobPageRequest } from '../../../logic/features/jobs/job.service';
import { appStringify } from '../../router.helper';

// this was causing a super weird error by being in the tsx file
// I should move the other routes into separate files in the future
export const jobPaths = {
    publishConfirmation: '/jobs/:jobId(\\d+)/publish-confirmation',
    publish: '/jobs/:jobId(\\d+)/publish',
    edit: '/jobs/:jobId(\\d+)/edit',
    changeRequest: '/jobs/:jobId(\\d+)/change-request',
    details: '/jobs/:jobId(\\d+)',
    add: '/jobs/add',
    list: '/jobs',
} as const;

export type JobPageBaseParams = {
    jobId?: string;
};

export type JobAddPageQueryParams = {
    republishId?: number;
};

const defaultJobListParams: JobPageRequest = {
    status: ['Active', 'Draft', 'Prelisted', 'Pending'],
};
const defaultJobListPath = `/jobs${appStringify(defaultJobListParams)}`;

export const jobPathCreator = {
    list: (params?: JobPageRequest) => `/jobs${appStringify(params)}`,
    listDefault: () => defaultJobListPath,
    details: (jobId: number) => `/jobs/${jobId}`,
    publish: (jobId: number) => `/jobs/${jobId}/publish`,
    publishConfirmation: (jobId: number) => `/jobs/${jobId}/publish-confirmation`,
    edit: (jobId: number) => `/jobs/${jobId}/edit`,
    changeRequest: (jobId: number) => `/jobs/${jobId}/change-request`,
    create: (params?: JobAddPageQueryParams) => `/jobs/add${appStringify(params)}`,
};
