import { useMemo } from 'react';
import { EndorsementItem } from 'src/ui/features/endorsements/components/display/EndorsementItem';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { Loader } from '../../../loader/Loader';
import { HookControlProps } from '../../HookForm';
import { MultiSelectControl } from './MultiSelectControl';
import { ReactSelectOption } from './SingleSelectControl';

export const EndorsementMultiSelectControl = (
    props: HookControlProps
): React.ReactElement<any, any> | null => {
    const {
        all: { fetch },
        managedEndorsementIds,
        items,
    } = useRootSelector(state => state.endorsements);

    const options: ReactSelectOption<number>[] = useMemo(() => {
        return managedEndorsementIds
            .map(id => items[id])
            .filter(x => !!x)
            .map(x => x!)
            .map(x => ({
                label: (
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                            <EndorsementItem endorsement={x} />
                        </div>
                        <span className="mx-2">{x.name}</span>
                    </div>
                ),
                value: x.id,
                tokens: [],
            }));
    }, [items, managedEndorsementIds]);

    return (
        <Loader state={fetch} useCard={true}>
            <MultiSelectControl {...props} options={options} />
        </Loader>
    );
};
