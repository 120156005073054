import { createContext, useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import { useClient } from 'src/ui/shared/hooks/client.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { contentPaths } from './content/content.paths';
import { identityBasePaths } from './identity/identity.paths';
import { applicationBasePaths, ApplicationRouteLayout } from './layouts/ApplicationRouteLayout';
import { homePath, HomeRouteLayout } from './layouts/HomeRouteLayout';
import { IdentityRouteLayout } from './layouts/IdentityRouteLayout';
import { NotFoundRouteLayout } from './layouts/NotFoundRouteLayout';
import { PublicContentRouteLayout } from './layouts/PublicContentRouteLayout';
import { registerPaths } from './register/register.paths';
import { RegisterRouteLayout } from './register/RegisterRouteLayout';

export const LayoutContext = createContext({
    isApplicationLayout: false,
    isHomePage: false,
});

export const useLayoutContext = () => useContext(LayoutContext);

export const useLayout = () => {
    const { isApplicationLayout, isHomePage } = useLayoutContext();

    const navButtonVariant = useMemo(
        () => (isApplicationLayout ? 'outline-primary' : 'outline-content-nav'),
        [isApplicationLayout]
    );
    const navLinkVariant = useMemo(
        () => (isApplicationLayout ? 'link' : 'link-content-nav'),
        [isApplicationLayout]
    );

    return {
        isApplicationLayout,
        navButtonVariant,
        navLinkVariant,
        isHomePage,
    };
};

export const RouteList = () => {
    const {
        meta: { description, title, disabledApplicationTitle },
    } = useStrings();
    const configState = useRootSelector(state => state.apiConfiguration.fetch);

    const { disableApplication } = useClient();

    const titleToUse = useMemo(() => {
        if (disableApplication || configState.loading) {
            return disabledApplicationTitle;
        }
        return title;
    }, [configState.loading, disableApplication, disabledApplicationTitle, title]);

    return (
        <>
            <Helmet>
                <title>{titleToUse}</title>
                <meta name="description" content={description} />
            </Helmet>

            <LayoutContext.Provider value={{ isApplicationLayout: false, isHomePage: false }}>
                <Switch>
                    <Route path={homePath} exact component={HomeRouteLayout} />
                    <Route path={contentPaths.public} component={PublicContentRouteLayout} />
                    <Route path={registerPaths.start} component={RegisterRouteLayout} />
                    <Route path={identityBasePaths} component={IdentityRouteLayout} />
                    <Route path={applicationBasePaths} component={ApplicationRouteLayout} />
                    <Route component={NotFoundRouteLayout} />
                </Switch>
            </LayoutContext.Provider>
        </>
    );
};
