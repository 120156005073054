import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import * as actions from '../central.actions';

export const centralContactAsyncEpic = asyncEpicBase(
    actions.centralContactInviteAsync,
    (services, action) =>
        services.central.api.contactInvite(
            action.payload.centralOrganisationId,
            action.payload.contact
        ),
    {
        success: (result, ra) => actions.centralContactInviteAsync.success(undefined, ra.meta),
        failure: (error, ra) => actions.centralContactInviteAsync.failure(error, ra.meta),
    }
);
