import { useDispatch } from 'react-redux';
import { centralLinkHelper } from 'src/logic/features/central/helpers/central-link.helper';
import { ManagedByCentralAlert } from 'src/ui/features/central/components/ManagedByCentralAlert';
import { useOrganisation } from 'src/ui/features/organisation/hooks/organisation.hook';
import { contactUpdateAsync } from '../../../../logic/features/contacts/contact.actions';
import { Contact } from '../../../../models/contacts/contact.model';
import { CardWrapper } from '../../../shared/components/bootstrap/cards/CardWrapper';
import { Loader } from '../../../shared/components/loader/Loader';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';
import { useFormButton } from '../../../shared/hooks/form-button.hook';
import { useAuthentication } from '../../authentication/hooks/authentication.hook';
import { ContactForm } from '../components/forms/ContactForm';
import { useActiveContact } from '../hooks/active-contact.hook';

export const ContactEditPage = (): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    const { contact, update, fetch } = useActiveContact();
    const { fetch: orgFetch, centralOrganisationId } = useOrganisation();
    const { isCentralContact } = useAuthentication();

    const onSubmit = (contact: Contact) => {
        dispatch(contactUpdateAsync.request(contact));
    };
    const { onCancel } = useFormButton();

    return (
        <ApplicationPageContent>
            <Loader state={[fetch, orgFetch]} useCard={true}>
                {isCentralContact && (
                    <ManagedByCentralAlert
                        stringSelector={strings => strings.contact.edit.managedByCentralInfo}
                        centralPath={centralLinkHelper.orgEdit(centralOrganisationId!)}
                    />
                )}

                {contact && (
                    <CardWrapper>
                        <ContactForm
                            contact={contact}
                            onSubmit={onSubmit}
                            onCancel={onCancel}
                            showSpinner={update.loading}
                            error={update.error}
                            disabled={contact.isDistributed}
                        />
                    </CardWrapper>
                )}
            </Loader>
        </ApplicationPageContent>
    );
};
