import { ContentKey, SiteContext } from '@careerhub/content-shared';
import { ErrorNormalized } from 'src/models/errors/error.model';
import { createAction, createAsyncAction } from 'typesafe-actions';

interface ContentKeyRequest {
    key: ContentKey;
}

export const contentAsync = createAsyncAction(
    'CONTENT:REQUEST',
    'CONTENT:SUCCESS',
    'CONTENT:FAILURE'
)<ContentKeyRequest, [SiteContext, ContentKeyRequest], [ErrorNormalized, ContentKeyRequest]>();

export const contentSetActive = createAction('CONTENT:SET_ACTIVE')<{
    key: ContentKey;
    activeCollectionId: string | undefined;
}>();
