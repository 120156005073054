import { stringsInit } from 'src/logic/features/strings/string.actions';
import { storeRegistry } from 'src/logic/store.registry';
import { ClientConfig } from './configuration.model';
import { ClientStrings } from './strings.model';
import stringsAU from './_defaults/regions/AU.strings.json';
import stringsCA from './_defaults/regions/CA.strings.json';
import stringsGB from './_defaults/regions/GB.strings.json';
import stringsUS from './_defaults/regions/US.strings.json';
import stringsZA from './_defaults/regions/ZA.strings.json';
import defaultStrings from './_defaults/_defaults.strings.json';

const isObjectAndNotArray = (item: any): boolean => {
    return item && typeof item === 'object' && !Array.isArray(item);
};

const deepMerge = (existing: any, overrides: any): any => {
    const output = { ...existing };
    Object.keys(overrides).forEach(key => {
        if (isObjectAndNotArray(overrides[key])) {
            output[key] = deepMerge(existing[key], overrides[key]);
        } else {
            Object.assign(output, { [key]: overrides[key] });
        }
    });
    return output;
};

export const executeStringsInit = (client: string, region: ClientConfig['countryCode']) => {
    // hmm, this is not very dynamic...
    let regionStrings = defaultStrings;

    const regionMappings = {
        AU: stringsAU,
        GB: stringsGB,
        CA: stringsCA,
        ZA: stringsZA,
        US: stringsUS,
    };

    if (region in regionMappings) {
        regionStrings = deepMerge(regionStrings, regionMappings[region]);
    }

    const store = storeRegistry.get();

    import(`../clients/${client}/${client}.strings.json`)
        .then((result: { default: Partial<ClientStrings> }) => {
            const clientStrings = deepMerge(regionStrings, result.default);
            store.dispatch(stringsInit({ clientStrings }));
        })
        .catch(e => {
            store.dispatch(stringsInit({ clientStrings: regionStrings }));
        });
};
