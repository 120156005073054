import { LocationChangeAction, LOCATION_CHANGE } from 'connected-react-router';
import compact from 'lodash/compact';
import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { RootEpic } from 'src/logic/features/epic.root-index';
import { RootState } from 'src/logic/features/reducer.root-index';
import { Services } from 'src/logic/features/service.root-index';
import {
    ActionCreator,
    EmptyActionCreator,
    isActionOf,
    isOfType,
    PayloadActionCreator,
} from 'typesafe-actions';
import { EntityPageRequest } from '../../../models/api/request.model';
import {
    ActiveListState,
    EntityListState,
} from '../../../models/store-models/entity-list-state.model';
import {
    delayActionUntilAuthenticated,
    delayTypeUntil,
    delayTypeUntilAuthenticated,
} from './app-init.epic-helper';
import { isLocationRouteMatch } from './location.epic-helper';

interface ListRouteOptions {
    path: string | string[];
    getListState: (state: RootState) => EntityListState<any, any>;
    cancelActionCreator: EmptyActionCreator<any>;
    disabledDelayUntilAuthenticated?: boolean;
}

interface ListRouteSetActiveOptions<TQuery extends EntityPageRequest> extends ListRouteOptions {
    getParams: (action: LocationChangeAction<any>, state: RootState, services: Services) => TQuery;
    setActiveActionCreator: PayloadActionCreator<any, ActiveListState<TQuery>>;
    overrideRequestId?: (
        action: LocationChangeAction<any>,
        state: RootState,
        services: Services
    ) => string;
    disabledDelayUntilAuthenticated?: boolean;
    extraFilter?: (action: LocationChangeAction<unknown>, state: RootState) => boolean;
    delayUntil?: ActionCreator;
}

interface ListRouteRequestOptions {
    getListState: (state: RootState) => EntityListState<any, any>;
    setActiveActionCreator: PayloadActionCreator<any, ActiveListState<any>>;
    requestActionCreator: PayloadActionCreator<any, any>;
    cancelActionCreator: EmptyActionCreator<any>;
    disabledDelayUntilAuthenticated?: boolean;
}

type AllTheInterfaces<TQuery extends EntityPageRequest> = ListRouteSetActiveOptions<TQuery> &
    ListRouteRequestOptions;

const listRouteEpic = <TQuery extends EntityPageRequest>(
    options: ListRouteSetActiveOptions<TQuery>
): RootEpic => {
    const {
        path,
        getParams,
        getListState,
        setActiveActionCreator,
        cancelActionCreator,
        overrideRequestId,
        disabledDelayUntilAuthenticated,
        extraFilter,
        delayUntil,
    } = options;

    const pathArray = Array.isArray(path) ? path : [path];

    return (action$, state$, services) => {
        const initialObs = delayUntil
            ? delayTypeUntil(action$, delayUntil, LOCATION_CHANGE)
            : disabledDelayUntilAuthenticated
            ? action$.pipe(filter(isOfType(LOCATION_CHANGE)))
            : delayTypeUntilAuthenticated(action$, state$, LOCATION_CHANGE);

        return initialObs.pipe(
            filter(isLocationRouteMatch(pathArray)),
            filter(action => (extraFilter ? extraFilter(action, state$.value) : true)),
            mergeMap(action => {
                const { search } = action.payload.location;
                const requestId = overrideRequestId
                    ? overrideRequestId(action, state$.value, services)
                    : search;
                const params = getParams(action, state$.value, services);
                const pageNumberToRequest = services.queryParams.getPageParam(search) || 1;

                // used for pagination, get from requestId if it exists, otherwise use the current activestate
                const listState = getListState(state$.value);
                const listStateValue = listState.list[requestId];
                const pagination = listStateValue?.pagination || listState.activeList.pagination;

                // only set active if the request has changed OR if the value (result) doesn't exists
                const setActiveAction =
                    requestId !== listState.activeList.requestId || !listStateValue
                        ? setActiveActionCreator({
                              fetch: { loading: false },
                              requestId: requestId,
                              query: params,
                              pagination: {
                                  ...pagination,
                                  page: pageNumberToRequest,
                              },
                          })
                        : undefined;

                // only cancel if it's currently fetching
                const cancelAction = listState.activeList.fetch.loading
                    ? cancelActionCreator()
                    : undefined;

                return compact([cancelAction, setActiveAction]);
            })
        );
    };
};

const listRouteCancelEpic = (options: ListRouteOptions): RootEpic => {
    const { path, getListState, cancelActionCreator, disabledDelayUntilAuthenticated } = options;

    const pathArray = Array.isArray(path) ? path : [path];

    return (action$, state$) => {
        const initialObs = disabledDelayUntilAuthenticated
            ? action$.pipe(filter(isOfType(LOCATION_CHANGE)))
            : delayTypeUntilAuthenticated(action$, state$, LOCATION_CHANGE);

        return initialObs.pipe(
            filter(
                action =>
                    !isLocationRouteMatch(pathArray)(action) &&
                    getListState(state$.value).activeList.fetch.loading
            ),
            mergeMap(() => of(cancelActionCreator()))
        );
    };
};

const listRouteRequestEpic = (options: ListRouteRequestOptions): RootEpic => {
    const {
        getListState,
        setActiveActionCreator,
        requestActionCreator,
        cancelActionCreator,
        disabledDelayUntilAuthenticated,
    } = options;

    return (action$, state$) => {
        const initialObs = disabledDelayUntilAuthenticated
            ? action$.pipe(filter(isActionOf(setActiveActionCreator)))
            : delayActionUntilAuthenticated(action$, state$, setActiveActionCreator);

        return initialObs.pipe(
            filter(({ payload }) => !getListState(state$.value).list[payload.requestId]),
            mergeMap(({ payload }) =>
                of(requestActionCreator(payload.query)).pipe(
                    takeUntil(action$.pipe(filter(isActionOf(cancelActionCreator))))
                )
            )
        );
    };
};

export const listEpic = <TQuery extends EntityPageRequest>(
    options: AllTheInterfaces<TQuery>
): RootEpic => {
    return combineEpics(
        listRouteEpic(options),
        listRouteRequestEpic(options),
        listRouteCancelEpic(options)
    );
};
