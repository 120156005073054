import { Profile } from 'oidc-client';
import { ErrorNormalized } from 'src/models/errors/error.model';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const emailClaimsShowModal = createAction('EMAIL_CLAIMS_SHOW_MODAL')<{ show: boolean }>();

export const emailClaimsAddAsync = createAsyncAction(
    'EMAIL_CLAIMS:REQUEST',
    'EMAIL_CLAIMS:SUCCESS',
    'EMAIL_CLAIMS:FAILURE'
)<{ email: string }, undefined, ErrorNormalized>();

export const userInfoAsync = createAsyncAction(
    'USER_INFO:REQUEST',
    'USER_INFO:SUCCESS',
    'USER_INFO:FAILURE',
    'USER_INFO:CANCEL'
)<undefined, Partial<Profile>, ErrorNormalized, undefined>();

export const emailClaimsReset = createAction('EMAIL_CLAIMS_RESET')();
