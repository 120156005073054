import keyBy from 'lodash/keyBy';
import { PAGINATION_DEFAULT_SIZE } from 'src/constants/pagination.constants';
import { EntityPageRequest } from 'src/models/api/request.model';
import { ApiSearchResponse } from 'src/models/api/response.model';
import { ErrorNormalized } from 'src/models/errors/error.model';
import {
    ActiveListState,
    EntityListState,
    ListPaginationState,
} from 'src/models/store-models/entity-list-state.model';
import { EntityBase, EntityState } from 'src/models/store-models/entity-state.model';
import { EmptyAction, PayloadAction } from 'typesafe-actions';

const createDefaultSingleState = (id: number): EntityState => ({
    id,
    fetch: { loading: false },
    update: { loading: false },
    deleteState: { loading: false },
    deleted: false,
});

const createPaginationFromResponse = <TEntity extends EntityBase>(
    response: ApiSearchResponse<TEntity, EntityPageRequest>
): ListPaginationState => {
    const { skip, take } = response.query;
    const takeOrDefault = take || PAGINATION_DEFAULT_SIZE;

    const pageTotal = Math.ceil(response.total / takeOrDefault);
    const page = skip ? skip / takeOrDefault + 1 : 1;

    return {
        page,
        pageTotal,
        pageSize: takeOrDefault,
    };
};

const setActive = <
    TEntity extends EntityBase,
    TQuery,
    TState extends EntityListState<TEntity, TQuery>
>(
    state: TState,
    action: PayloadAction<any, ActiveListState<TQuery>>
): Readonly<TState> => ({
    ...state,
    activeList: action.payload,
});

const request: <
    TEntity extends EntityBase,
    TQuery,
    TState extends EntityListState<TEntity, TQuery>
>(
    state: TState,
    action: PayloadAction<any, any>
) => Readonly<TState> = state => ({
    ...state,
    activeList: {
        ...state.activeList,
        fetch: { loading: true },
    },
});

const successItemsOnly = <TEntity extends EntityBase, TState extends EntityListState<TEntity, any>>(
    state: TState,
    items: TEntity[]
): Readonly<TState> => ({
    ...state,
    single: {
        ...state.single,
        ...keyBy(
            items.map(i => createDefaultSingleState(i.id)),
            item => item.id
        ),
    },
    items: { ...state.items, ...keyBy(items, item => item.id) },
});

const success = <
    TEntity extends EntityBase,
    TQuery extends EntityPageRequest,
    TState extends EntityListState<TEntity, TQuery>
>(
    state: TState,
    action: PayloadAction<any, ApiSearchResponse<TEntity, TQuery>>
): Readonly<TState> => {
    const { data, query } = action.payload;
    const pagination = createPaginationFromResponse(action.payload);

    const toReturn: TState = {
        ...successItemsOnly(state, data),
        list: {
            ...state.list,
            [state.activeList.requestId]: {
                ids: data.map(i => i.id),
                pagination: pagination,
            },
        },
        activeList: {
            ...state.activeList,
            query: query,
            pagination: pagination,
            fetch: { loading: false },
        },
    };

    return toReturn;
};

const cancel: <TEntity extends EntityBase, TQuery, TState extends EntityListState<TEntity, TQuery>>(
    state: TState,
    action: EmptyAction<any>
) => Readonly<TState> = state => {
    return {
        ...state,
        activeList: {
            ...state.activeList,
            fetch: { loading: false },
        },
        list: {
            ...state.list,
            [state.activeList.requestId]: undefined,
        },
    };
};

const failure: <
    TEntity extends EntityBase,
    TQuery,
    TState extends EntityListState<TEntity, TQuery>
>(
    state: TState,
    action: PayloadAction<any, ErrorNormalized>
) => Readonly<TState> = (state, action) => {
    return {
        ...state,
        activeList: {
            ...state.activeList,
            fetch: { loading: false, error: action.payload },
        },
    };
};

const clear: <TEntity extends EntityBase, TQuery, TState extends EntityListState<TEntity, TQuery>>(
    state: TState,
    action: PayloadAction<any, { excludeActive: boolean }>
) => Readonly<TState> = (state, action) => ({
    ...state,
    list: action.payload.excludeActive
        ? {
              [state.activeList.requestId]: state.list[state.activeList.requestId],
          }
        : {},
});

export const listReducerHandler = {
    setActive,
    request,
    success,
    successItemsOnly,
    cancel,
    failure,
    clear,
};
