import { EMPTY, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { getType, isActionOf } from 'typesafe-actions';
import { RootEpic } from '../../epic.root-index';
import * as actions from '../contact.actions';

export const contactSetPrimaryEpic = asyncEpicBase(
    actions.contactSetPrimaryAsync,
    ({ api }, { payload }) => api.contacts.setPrimary(payload),
    {
        success: result => actions.contactSetPrimaryAsync.success(result.json),
        failure: (error, ra) => actions.contactSetPrimaryAsync.failure(error, ra.payload),
    }
);

export const contactSetPrimarySetActiveEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(
            isActionOf([
                actions.contactSetPrimaryAsync.success,
                actions.contactUpdateAsync.success,
                actions.contactCreateAsync.success,
                actions.contactListAsync.success,
            ])
        ),
        mergeMap(action => {
            let primaryId: number | undefined;

            switch (action.type) {
                case getType(actions.contactListAsync.success): {
                    const find = action.payload.data.find(i => i.primary);
                    primaryId = find && find.id;
                    break;
                }
                default: {
                    primaryId = action.payload.data.primary ? action.payload.data.id : undefined;
                    break;
                }
            }

            return primaryId ? of(actions.contactSetPrimarySetActive({ id: primaryId })) : EMPTY;
        })
    );
};
