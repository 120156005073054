import { ListGroup } from 'react-bootstrap';
import { Logo } from '../../logo/Logo';
import { ApplicationNavItems } from './ApplicationNavItems';
import { PrivateContentNavItems } from './PrivateContentNavItems';

export const ApplicationSideNav = (): React.ReactElement<any, any> | null => {
    return (
        <div className="px-4 pt-4 pb-2">
            <div className="mb-3 logo-container">
                <Logo />
            </div>

            <ListGroup variant="flush">
                <ApplicationNavItems as="list" className="px-2 text-first-upper" />
                <PrivateContentNavItems as="list" className="px-2" />
            </ListGroup>
        </div>
    );
};
