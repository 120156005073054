import merge from 'lodash/merge';
import { configurationInit } from 'src/logic/features/configuration/configuration.actions';
import { storeRegistry } from 'src/logic/store.registry';
import { logError } from 'src/ui/features/sentry/helpers/sentry.helper';
import { ClientConfig } from './configuration.model';
import configAU from './_defaults/regions/AU.configuration.json';
import configGB from './_defaults/regions/GB.configuration.json';
import configCA from './_defaults/regions/CA.configuration.json';
import configZA from './_defaults/regions/ZA.configuration.json';
import configUS from './_defaults/regions/US.configuration.json';
import defaultConfig from './_defaults/_defaults.configuration.json';

export const executeConfigurationInit = (client: string) => {
    import(`../clients/${client}/${client}.configuration.json`)
        .then((config: { default: Partial<ClientConfig> }) => {
            // this is a bit odd, because it is using the dynamic loaded
            // client config with the country code, to dynamically inject
            // the region configuration, and then override with the client setting
            const countryCode =
                config.default.countryCode ||
                (defaultConfig as unknown as ClientConfig).countryCode;
            const regionConfig: Partial<ClientConfig> =
                countryCode === 'AU'
                    ? (configAU as Partial<ClientConfig>)
                    : countryCode === 'GB'
                    ? (configGB as Partial<ClientConfig>)
                    : countryCode === 'CA'
                    ? (configCA as Partial<ClientConfig>)
                    : countryCode === 'ZA'
                    ? (configZA as Partial<ClientConfig>)
                    : countryCode === 'US'
                    ? (configUS as Partial<ClientConfig>)
                    : {};

            const clientConfig = merge({}, defaultConfig, regionConfig, config.default);

            const store = storeRegistry.get();
            store.dispatch(configurationInit(clientConfig, { clientCode: client }));
        })
        .catch(e => {
            console.log('Error on configuration load');
            console.error(e);
            logError(e, { client }, { isConfiguration: 'true' });
            // if this breaks, there's really nothing that can be done at this point.
            // maybe I could throw a specific config error action
            // but honestly, if this fails, absolutely everything will be broken.
        });
};

export const getClientConfig = () => storeRegistry.get().getState().configuration.value;
