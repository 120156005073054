import { Route, Switch } from 'react-router-dom';
import { JobPublishConfirmationPage } from 'src/ui/features/jobs/pages/JobPublishConfirmationPage';
import { JobAddPage } from '../../ui/features/jobs/pages/JobAddPage';
import { JobChangeRequestPage } from '../../ui/features/jobs/pages/JobChangeRequestPage';
import { JobDetailsPage } from '../../ui/features/jobs/pages/JobDetailsPage';
import { JobEditPage } from '../../ui/features/jobs/pages/JobEditPage';
import { JobListPage } from '../../ui/features/jobs/pages/JobListPage';
import { JobPublishPage } from '../../ui/features/jobs/pages/JobPublishPage';
import { jobPaths } from './paths/job.paths';

export const JobRouteList = () => (
    <Switch>
        <Route path={jobPaths.publish} component={JobPublishPage} />
        <Route path={jobPaths.publishConfirmation} component={JobPublishConfirmationPage} />
        <Route path={jobPaths.edit} component={JobEditPage} />
        <Route path={jobPaths.changeRequest} component={JobChangeRequestPage} />
        <Route path={jobPaths.details} component={JobDetailsPage} />
        <Route path={jobPaths.add} component={JobAddPage} />
        <Route path={jobPaths.list} component={JobListPage} />
    </Switch>
);
