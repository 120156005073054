import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { AlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/AlertWrapper';
import { Markdown } from 'src/ui/shared/components/details/Markdown';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { useOrganisation } from '../../hooks/organisation.hook';

export const OrganisationPendingAlert = (): React.ReactElement<any, any> | null => {
    const { organisation, isOrganisation } = useOrganisation();
    const {
        shared: {
            alerts: { pending },
        },
    } = useStrings();

    if (!isOrganisation || organisation?.isApproved || !pending) {
        return null;
    }

    return (
        <AlertWrapper icon={faInfoCircle} variant="info">
            <Markdown source={pending} />
        </AlertWrapper>
    );
};
