import { useMemo } from 'react';
import { CareerHubEvent } from 'src/models/events/event.model';
import { DatePropertyDisplay } from 'src/ui/shared/components/details/DatePropertyDisplay';
import { DetailsProperty } from 'src/ui/shared/components/details/DetailsProperty';
import { EventProviderProperty } from './EventProviderProperty';
import { EventStatisticProperty } from './EventStatisticProperty';
import { EventTypeProperty } from './EventTypeProperty';

interface Props {
    event: CareerHubEvent;
}

export const EventProperties = ({ event }: Props): React.ReactElement<any, any> | null => {
    const bookingSystem = useMemo(() => {
        switch (event.bookingType) {
            case 'NotRequired':
                return 'Not required';
            case 'AdminManaged':
                return 'Administrator managed';
            case 'External':
            case 'ExternalWebsite':
                return 'External';
            case 'SelfManaged':
                return 'Internal';
            default:
                return 'Unknown';
        }
    }, [event.bookingType]);

    return (
        <>
            <h2 className="font-weight-normal">Other Information</h2>
            <EventTypeProperty eventTypeId={event.eventTypeId} />
            {/* <ContactDetailsProperty id={eventRequest.contactId} /> */}
            {/* <DetailsProperty label="Property">properties go here</DetailsProperty> */}
            {/* <ContactDetailsProperty /> */}
            <EventProviderProperty eventId={event.id} />
            <EventStatisticProperty eventId={event.id} />
            <DetailsProperty label="Booking System">
                <div>{bookingSystem}</div>
                {event.bookingLimit && <div>Limit: {event.bookingLimit}</div>}
            </DetailsProperty>
            <DetailsProperty label="History">
                <DatePropertyDisplay label="Added/ approved" date={event.added} />
                <DatePropertyDisplay label="Last updated" date={event.lastUpdated} />
                {event.publish && <DatePropertyDisplay label="Publish" date={event.publish} />}
                {event.expire && <DatePropertyDisplay label="Expire" date={event.expire} />}
            </DetailsProperty>
        </>
    );
};
