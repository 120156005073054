import { DateDisplay } from './DateDisplay';
import { DetailsPropertyValueDisplay } from './DetailsPropertyValueDisplay';

interface Props {
    label: string;
    date: string;
}

export const DatePropertyDisplay = ({
    label,
    date,
}: Props): React.ReactElement<any, any> | null => (
    <DetailsPropertyValueDisplay label={label} value={<DateDisplay date={date} />} />
);
