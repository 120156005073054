import { Spinner } from 'react-bootstrap';

interface Props {
    spinnerClassName?: string;
    size?: 'sm';
}

export const LoaderSpinner = ({
    spinnerClassName,
    size,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <Spinner animation="border" variant="secondary" className={spinnerClassName} size={size} />
    );
};
