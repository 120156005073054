import Color from 'color';
import { useMemo } from 'react';
import styles from '../styles/endorsement-badge.module.scss';

interface Props {
    icon?: string;
    acronym?: string;
    colour: string;
    size: number;
}

export const EndorsementIcon = ({
    icon,
    acronym,
    colour,
    size,
}: Props): React.ReactElement<any, any> | null => {
    const textColour = useMemo(() => (Color(colour).isDark() ? 'white' : 'black'), [colour]);

    const iconCssString = useMemo(() => {
        if (!icon) {
            return;
        }

        return 'far ' + icon.replace(/[A-Z]/g, m => '-' + m.toLowerCase());
    }, [icon]);

    if (acronym) {
        return (
            <div
                className={styles.acronym}
                style={{
                    color: textColour,
                    fontSize: size * 0.225,
                    fontWeight: 900,
                }}
            >
                {acronym}
            </div>
        );
    }

    if (iconCssString) {
        return (
            <div
                className={styles.icon}
                style={{
                    color: textColour,
                    fontSize: size * 0.3,
                }}
            >
                <i className={iconCssString}></i>
            </div>
        );
    }

    return null;
};
