import { eventPathCreator } from 'src/routes/employer/paths/event.paths';
import { BreadcrumbLink } from '../../../../shared/components/breadcrumbs/BreadcrumbLink';

interface Props {
    id: number;
    title: string;
}

export const EventDetailsBreadcrumb = ({
    id,
    title,
}: Props): React.ReactElement<any, any> | null => (
    <BreadcrumbLink to={eventPathCreator.details(id)} className="text-long-title" title={title}>
        {title}
    </BreadcrumbLink>
);
