import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useClient } from 'src/ui/shared/hooks/client.hook';

export const UkCentralAdvertisementInfo = (): React.ReactElement<any, any> | null => {
    const { centralMarketingPath } = useClient();

    return (
        <>
            <h1 className="mb-3" style={{ fontSize: '20px' }}>
                Post your job to more universities
            </h1>
            <p>
                4 million+ students and recent graduates from 45+ universities across the United
                Kingdom and Ireland.
            </p>
            <div className="mb-2">
                <a
                    href={centralMarketingPath}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-link-light pl-0"
                >
                    <Icon icon={faExternalLinkAlt}>Find out more</Icon>
                </a>
            </div>
        </>
    );
};
