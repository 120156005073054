import { PageTitle } from '../../../../shared/components/page-components/PageTitle';
import { IndividualDetailsBreadcrumb } from '../breadcrumbs/IndividualDetailsBreadcrumb';

export const IndividualEditPageNav = (): React.ReactElement<any, any> | null => {
    return (
        <PageTitle>
            <IndividualDetailsBreadcrumb />
            Edit
        </PageTitle>
    );
};
