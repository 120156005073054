import { useCallback, useRef, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { GeoLocationMultiControl } from 'src/ui/features/locations/components/GeoLocationMultiControl';
import {
    locationEnsureUnique,
    locationMaxFieldLengths,
} from 'src/ui/features/locations/helpers/location.helpers';
import { StringsAlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/StringsAlertWrapper';
import { WorkGroupFormRow } from 'src/ui/shared/components/forms/shared/WorkGroupFormRow';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { JOB_CONTRACT_HOURS_SELECT_OPTIONS } from '../../../../../constants/select-options.constants';
import { AttachmentCreate } from '../../../../../models/attachments/attachment-create.model';
import { LoaderState } from '../../../../../models/errors/error.model';
import { JobCreateModel } from '../../../../../models/jobs/job-create.model';
import {
    JOB_CONTRACT_TYPE_OPTIONS,
    JOB_POSITIONS_AVAILABLE_OPTIONS,
} from '../../../../../models/jobs/job-types.model';
import { Job } from '../../../../../models/jobs/job.model';
import { HookForm } from '../../../../shared/components/forms/HookForm';
import { Loader } from '../../../../shared/components/loader/Loader';
import { LoaderText } from '../../../../shared/components/loader/LoaderText';
import {
    arrayRequiredRule,
    createMaxLengthRule,
    urlPatternRule,
} from '../../../../shared/helpers/validation.helper';
import { useFormDefault } from '../../../../shared/hooks/form-default.hook';
import { useStrings } from '../../../../shared/hooks/strings.hook';
import { useAuthentication } from '../../../authentication/hooks/authentication.hook';
import { useOrganisation } from '../../../organisation/hooks/organisation.hook';
import { JobAttachmentControl } from '../attachments/JobAttachmentControl';
import { JobDetailsContactControls } from './JobDetailsContactControls';
import { useWorkGroups } from 'src/ui/features/work-groups/hooks/work-groups.hook';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';

interface Props {
    job?: Job;
    onSubmit: (data: JobCreateModel) => void;
    onSaveDraft: (data: JobCreateModel) => void;
    onCancel: () => void;
    state: LoaderState;
    // this is a bit of a hack, we can't currently transfer
    // attachments to a new job from duplicate jobs.
    ignoreAttachments?: boolean;
}

export const JobDetailsForm = ({
    job,
    onSubmit,
    onSaveDraft,
    onCancel,
    state,
    ignoreAttachments,
}: Props): React.ReactElement<any, any> | null => {
    const { isIndividual } = useAuthentication();
    const { isAgentForEmployer, fetch: fetchOrgState } = useOrganisation();
    const [savingDraftClicked, setSavingDraftClicked] = useState(false);
    const defaultWorkGroupId = useRootSelector(state => state.workGroups.queryWorkGroupId);
    const { publishableWorkGroups } = useWorkGroups();
    const {
        job: { form },
        shared: {
            forms: { location, buttons },
        },
    } = useStrings();

    const disabledHideRecruitingFor = useRootSelector(
        state => state.apiConfiguration.value?.settings?.disableHideRecruitingFor
    );

    const {
        includes: { residencyOptions },
    } = useApiConfiguration();
    const hasResidencyOptions = residencyOptions && residencyOptions.length > 0;

    // this is a hack and can be removed once all employer sites are 5.10 or above
    // I will also hotfix this into 5.8, 5.9
    // the problem: the job is being returned with the default workgroup, however
    // the default workgroup property 'employersCanPublish' is always set to false
    // the user cannot update the property, because it will not appear in the workgroup list
    // however, prior to 5.10, it was not ignored.
    // if we remove it here. It won't be sent in the update request.
    // bascially the logic here is, if the user can't select a workgroup, it must be default, so it's safe to ignore it.
    const ignoreWorkGroup = publishableWorkGroups.length === 0;

    const formMethods = useFormDefault<Job>({
        defaultValues: {
            ...job,
            workGroupId: defaultWorkGroupId || ignoreWorkGroup ? undefined : job?.workGroupId,
        },
        error: state.error,
    });

    const {
        formState: { isSubmitting },
        handleSubmit,
    } = formMethods;

    const filesRef = useRef<AttachmentCreate[]>([]);
    const setNewAttachments = useCallback(
        (attachments: AttachmentCreate[]) => {
            filesRef.current = [...attachments];
        },
        [filesRef]
    );

    const onSaveInner = useCallback(() => {
        setSavingDraftClicked(true);
        void handleSubmit(job =>
            onSaveDraft({
                job: job,
                attachments: filesRef.current,
            })
        )();
    }, [onSaveDraft, filesRef, handleSubmit]);

    const onSubmitWrapper = useCallback(
        (job: Job) => {
            onSubmit({
                job,
                attachments: filesRef.current,
            });
        },
        [onSubmit, filesRef]
    );

    return (
        <Loader state={fetchOrgState}>
            <HookForm {...formMethods} onSubmit={onSubmitWrapper}>
                <HookForm.ErrorAlert error={state.error} />
                <StringsAlertWrapper alerts={form.alerts} />

                <HookForm.HiddenId />
                {/* This isn't necessary to send, but it's just useful for the fakes */}
                <HookForm.HiddenId name="status" />

                {!ignoreWorkGroup && (
                    <>
                        <WorkGroupFormRow name="workGroupId" entityType="job" />
                        <hr />
                    </>
                )}

                <Form.Row>
                    <Col sm="12" md="9">
                        <HookForm.Input
                            {...form.position}
                            name="title"
                            autoFocus={true}
                            rules={{ required: true, maxLength: createMaxLengthRule(100) }}
                        />
                    </Col>
                    <Col sm="12" md="3">
                        <HookForm.Input
                            {...form.reference}
                            name="externalReference"
                            rules={{ maxLength: createMaxLengthRule(20) }}
                        />
                    </Col>
                </Form.Row>
                {isAgentForEmployer && (
                    <Form.Row>
                        <Col>
                            <HookForm.Input
                                {...form.recruitingFor}
                                name="recruitingFor"
                                rules={{ required: true, maxLength: createMaxLengthRule(100) }}
                            />
                        </Col>
                        <Col className="d-flex align-items-end">
                            <div className={disabledHideRecruitingFor ? 'd-none' : ''}>
                                <HookForm.Checkbox
                                    name="hideRecruitingFor"
                                    {...form.hideRecruitingFor}
                                />
                            </div>
                        </Col>
                    </Form.Row>
                )}
                {!isIndividual && <JobDetailsContactControls />}
                <hr />
                <Form.Row>
                    <Col xs="12" md="6">
                        <HookForm.CategoryMultiSelect
                            {...form.jobCategories}
                            name="typesOfWork"
                            categoryKey="employmentTypes"
                            rules={{ required: true }}
                        />
                    </Col>
                    <Col xs="12" md="6">
                        <HookForm.CategoryMultiSelect
                            {...form.occupationCategories}
                            name="occupations"
                            categoryKey="occupations"
                            rules={{ required: true }}
                        />
                    </Col>
                </Form.Row>
                <HookForm.Textarea
                    {...form.summary}
                    name="summary"
                    minRows={3}
                    rules={{ required: true, maxLength: createMaxLengthRule(300) }}
                />
                <HookForm.Textarea
                    {...form.description}
                    name="details"
                    minRows={8}
                    rules={{ maxLength: createMaxLengthRule(4000), required: true }}
                />
                <HookForm.Textarea
                    {...form.procedures}
                    name="procedures"
                    minRows={8}
                    rules={{ required: true, maxLength: createMaxLengthRule(4000) }}
                />
                <HookForm.Input
                    {...form.website}
                    name="url"
                    rules={{ pattern: urlPatternRule, maxLength: createMaxLengthRule(300) }}
                />
                <GeoLocationMultiControl
                    label={location.label}
                    name="locations"
                    rules={{
                        validate: {
                            ensureUnique: locationEnsureUnique,
                            required: arrayRequiredRule,
                            maxLength: locationMaxFieldLengths,
                        },
                    }}
                />
                <hr />
                <Form.Row>
                    <Col>
                        <HookForm.Input
                            {...form.commences}
                            name="commences"
                            rules={{ required: true, maxLength: createMaxLengthRule(50) }}
                        />
                    </Col>
                    <Col>
                        <HookForm.Input
                            {...form.remuneration}
                            name="remuneration"
                            rules={{ required: true, maxLength: createMaxLengthRule(50) }}
                        />
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <HookForm.Select
                            {...form.contractType}
                            name="contractType"
                            allowEmpty={true}
                            rules={{ required: true }}
                            options={JOB_CONTRACT_TYPE_OPTIONS}
                        />
                    </Col>
                    <Col>
                        <HookForm.Select
                            {...form.contractHours}
                            name="contractHours"
                            allowEmpty={true}
                            rules={{ required: true }}
                            options={JOB_CONTRACT_HOURS_SELECT_OPTIONS}
                        />
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col>
                        <HookForm.Select
                            {...form.positionsAvailable}
                            name="positionsAvailable"
                            allowEmpty={true}
                            rules={{ required: true }}
                            options={JOB_POSITIONS_AVAILABLE_OPTIONS}
                        />
                    </Col>

                    <Col>
                        {residencyOptions && hasResidencyOptions && (
                            <HookForm.Select
                                {...form.residency}
                                name="residency"
                                // CareerHub has empty options hardcoded therefore you
                                // you pretty much have to rely on this being true forever. (if it exists)
                                // allowEmpty={true}
                                options={residencyOptions}
                            />
                        )}
                    </Col>
                </Form.Row>
                <hr />
                <div className="mb-3">
                    <JobAttachmentControl
                        {...form.attachments}
                        setNewAttachments={setNewAttachments}
                        jobId={ignoreAttachments ? undefined : job?.id}
                    />
                </div>
                <HookForm.Buttons
                    submitText={buttons.saveAndContinue}
                    showSpinner={state.loading && !savingDraftClicked}
                    disabled={savingDraftClicked}
                >
                    <HookForm.ErrorSpan error={state.error} />
                    <Button variant="link" onClick={onCancel}>
                        {buttons.cancel}
                    </Button>
                    <Button
                        className="mr-2"
                        disabled={isSubmitting || state.loading}
                        onClick={onSaveInner}
                    >
                        <LoaderText loading={state.loading && savingDraftClicked}>
                            {buttons.saveAsDraft}
                        </LoaderText>
                    </Button>
                </HookForm.Buttons>
            </HookForm>
        </Loader>
    );
};
