// see: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
// localstorage _should_ always be available, but there are instances where this is not true
// For example: IPAD - while private browsing
const available = (type: 'localStorage' | 'sessionStorage'): boolean => {
    let storage;
    try {
        storage = window[type];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return !!(
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage &&
            storage.length !== 0
        );
    }
};

const get = (): Storage => {
    if (available('localStorage')) {
        return localStorage;
    }

    if (available('sessionStorage')) {
        return sessionStorage;
    }

    throw new Error('Localstorage and sessionStorage unavailable');
};

export const storageHelper = {
    available,
    get,
};
