interface Props {
    title: string;
    description: string;
}

export const DescriptionSelectLabel = ({
    title,
    description,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <>
            <div>{title}</div>
            <div className="employer-description-select-control-value">
                <small>{description}</small>
            </div>
        </>
    );
};
