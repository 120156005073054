import omit from 'lodash/omit';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Job } from 'src/models/jobs/job.model';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { jobCreateAsync } from '../../../../logic/features/jobs/job.actions';
import { JobCreateModel } from '../../../../models/jobs/job-create.model';
import { CardWrapper } from '../../../shared/components/bootstrap/cards/CardWrapper';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';
import { useFormButton } from '../../../shared/hooks/form-button.hook';
import { JobDetailsForm } from '../components/forms/JobDetailsForm';

export const JobAddPage = (): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    const onSubmit = useCallback(
        (values: JobCreateModel) => {
            dispatch(jobCreateAsync.request(values, { redirectToPublish: true }));
        },
        [dispatch]
    );

    const onSaveDraft = useCallback(
        (values: JobCreateModel) => {
            dispatch(jobCreateAsync.request(values, { redirectToPublish: false }));
        },
        [dispatch]
    );

    const { onCancel } = useFormButton();

    const { create, republishId } = useRootSelector(state => state.jobs);

    const replublishJobState = useRootSelector(state =>
        republishId ? state.jobs.single[republishId]?.fetch : undefined
    );

    const republishJob = useRootSelector(state =>
        republishId ? state.jobs.items[republishId] : undefined
    );

    const modifiedRepublishedJob = useMemo(() => {
        if (!republishJob) {
            return undefined;
        }

        return omit(republishJob, 'id');
    }, [republishJob]);

    return (
        <ApplicationPageContent className="job-add-page">
            <Loader state={replublishJobState || false} useCard={true}>
                <CardWrapper>
                    <JobDetailsForm
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                        onSaveDraft={onSaveDraft}
                        state={create}
                        job={modifiedRepublishedJob as Job}
                        ignoreAttachments={true}
                    />
                </CardWrapper>
            </Loader>
        </ApplicationPageContent>
    );
};
