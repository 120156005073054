import { PageTitle } from 'src/ui/shared/components/page-components/PageTitle';
import { useActiveJob } from '../../hooks/active-job.hook';
import { JobDetailsBreadcrumb } from '../breadcrumbs/JobDetailsBreadcrumb';
import { JobListBreadcrumb } from '../breadcrumbs/JobListBreadcrumb';

export const JobPublishConfirmationPageNav = (): React.ReactElement<any, any> | null => {
    const { job } = useActiveJob();

    return (
        <PageTitle>
            <JobListBreadcrumb />
            {job && <JobDetailsBreadcrumb job={job} />}
            Publish confirmation
        </PageTitle>
    );
};
