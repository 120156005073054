import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';

export const useFormButton = () => {
    const dispatch = useDispatch();

    const onCancel = useCallback(() => {
        dispatch(goBack());
    }, [dispatch]);

    return {
        onCancel,
    };
};
