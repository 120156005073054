import { createAction, createAsyncAction } from 'typesafe-actions';
import { EntityPageRequest } from '../../../models/api/request.model';
import { ApiSearchResponse } from '../../../models/api/response.model';
import { ErrorNormalized } from '../../../models/errors/error.model';
import { FormSubmission } from '../../../models/forms/form-submission.model';
import { ActiveListState } from '../../../models/store-models/entity-list-state.model';

export const formSubmissionResetState = createAction('FORM_SUBMISSION_RESET_STATE')();
export const formSubmissionListSetActive = createAction('FORM_SUBMISSION_LIST:SET_ACTIVE')<
    ActiveListState<EntityPageRequest>
>();

export const formSubmissionListAsync = createAsyncAction(
    'FORM_SUBMISSION_LIST:REQUEST',
    'FORM_SUBMISSION_LIST:SUCCESS',
    'FORM_SUBMISSION_LIST:FAILURE',
    'FORM_SUBMISSION_LIST:CANCEL'
)<
    EntityPageRequest,
    ApiSearchResponse<FormSubmission, EntityPageRequest>,
    [ErrorNormalized, EntityPageRequest],
    undefined
>();
