import { useCallback, useMemo } from 'react';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';
import { ApiConfigurationCategories } from '../../../../../../models/api-configuration/api-configuration.model';
import { Loader } from '../../../loader/Loader';
import { HookControlProps } from '../../HookForm';
import { MultiSelectControl } from './MultiSelectControl';
import { ReactSelectOption } from './SingleSelectControl';

interface CategoryMultiSelectControlProps extends HookControlProps {
    categoryKey: keyof ApiConfigurationCategories;
}

type CategorySelectOption = ReactSelectOption<{ id: number }>;

export const CategoryMultiSelectControl = (
    props: CategoryMultiSelectControlProps
): React.ReactElement<any, any> | null => {
    const { categoryKey } = props;

    const { includes, fetch } = useApiConfiguration();

    const options: CategorySelectOption[] = useMemo(
        () =>
            (includes[categoryKey] || []).map(option => ({
                label: option.name,
                value: { id: option.id },
                tokens: option.name.toLowerCase().split(' '),
            })),
        [includes, categoryKey]
    );

    const compareOverride = useCallback((value: { id: number }, other: any) => {
        return value?.id === other?.id;
    }, []);

    return (
        <Loader state={fetch} useCard={true}>
            <MultiSelectControl {...props} options={options} compareOverride={compareOverride} />
        </Loader>
    );
};
