import { push, replace } from 'connected-react-router';
import { add, isValid, parseISO } from 'date-fns';
import { concat, Observable, of } from 'rxjs';
import {
    catchError,
    delay,
    filter,
    ignoreElements,
    map,
    mergeMap,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { workGroupResetState } from 'src/logic/features/work-groups/work-group.actions';
import { asyncEpicStandard } from 'src/logic/helpers/epics/async.epic-helper';
import { isRouteMatch } from 'src/logic/helpers/epics/location.epic-helper';
import { ErrorNormalized } from 'src/models/errors/error.model';
import { identityPaths } from 'src/routes/identity/identity.paths';
import { logError } from 'src/ui/features/sentry/helpers/sentry.helper';
import { isActionOf } from 'typesafe-actions';
import { identityHelper } from '../../../../ui/features/authentication/helpers/identity.helper';
import { contactResetState } from '../../contacts/contact.actions';
import { endorsementResetState } from '../../endorsements/endorsement.actions';
import { RootEpic } from '../../epic.root-index';
import { eventUnionResetState } from '../../event-unions/event-union.actions';
import { formSubmissionResetState } from '../../form-submissions/form-submission.actions';
import { individualResetState } from '../../individuals/individual.actions';
import { jobResetState } from '../../jobs/job.actions';
import { organisationResetState } from '../../organisations/organisation.actions';
import { sessionReset } from '../../sessions/session.actions';
import { settingsResetState } from '../../settings/setting.actions';
import * as actions from '../authentication.actions';

const handleOidcAuthError = <T>(obs: Observable<T>) => {
    return obs.pipe(
        catchError(error => {
            let message = 'Unknown authentication error';

            if (error instanceof Error) {
                logError(error);
                message = error.message;
            }

            // todo? maybe make this better?
            const normalized: ErrorNormalized = {
                status: -1,
                statusText: 'Authentication Error',
                message,
            };
            return of(actions.centralIdentityFailure(normalized));
        })
    );
};

export const authenticationInitEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(actions.authenticationInit)),
        mergeMap(() => {
            if (
                isRouteMatch(state$.value.router.location, identityPaths.impersonate) ||
                services.storage.impersonateLocal.isValid()
            ) {
                return of(actions.impersonateStart());
            }

            if (!services.authentication.preInitStorageAvailableCheck()) {
                return of(
                    actions.centralIdentityFailure({
                        status: -1,
                        message:
                            'Local Storage and Session Storage are unavailable. You may need to use a different browser, or disable private browsing.',
                        statusText: 'Storage Unavailable',
                    })
                );
            }

            return of(actions.authenticationStart());
        })
    );
};

// this epic controls the authentication flow
// it checks if the access token exists
// then it will emit the identities call
// The identities epic will take care of the identities call
export const authenticationStartEpic: RootEpic = (
    action$,
    state$,
    { queryParams, authentication }
) => {
    return action$.pipe(
        filter(isActionOf(actions.authenticationStart)),
        // always initialize the auth config
        map(() => authentication.init(state$.value.configuration)),
        switchMap(() => {
            const routerLocation = state$.value.router.location;

            const parsedSearch = queryParams.parse(routerLocation.search);

            // first check for an emailToken
            // if it exists, force an instant redirect.
            const emailTokenFromUrl = parsedSearch.emailToken;
            if (emailTokenFromUrl && typeof emailTokenFromUrl === 'string') {
                return authentication
                    .signinRedirect({ emailToken: emailTokenFromUrl })
                    .pipe(handleOidcAuthError, ignoreElements());
            }

            // this would indicate that it's a authentication redirect callback
            if (routerLocation.hash.startsWith('#id_token')) {
                return authentication.signinRedirectCallback().pipe(
                    switchMap(user => {
                        // redirectUrl in state should always exist
                        const redirectUrl = user.state.redirectUrl || identityPaths.landing;
                        return concat([
                            actions.authenticationSetRedirect({ to: redirectUrl }),
                            replace(redirectUrl),
                            actions.centralIdentityComplete({ user }),
                        ]);
                    }),
                    handleOidcAuthError
                );
            }

            // now just check oidc to see if a user exists
            return authentication.getUser().pipe(
                mergeMap(user => of(actions.centralIdentitySetUser({ user }))),
                handleOidcAuthError
            );
        })
    );
};

export const authenticationValidateUserEpic: RootEpic = (action$, state$, { authentication }) => {
    return action$.pipe(
        filter(isActionOf(actions.centralIdentitySetUser)),
        filter(action => action.payload.user !== null),
        mergeMap(action => {
            const user = action.payload.user!;

            if (user.expired) {
                return authentication.removeUser().pipe(
                    mergeMap(() => of(actions.centralIdentitySetUser({ user: null }))),
                    handleOidcAuthError
                );
            }

            return of(actions.centralIdentityComplete({ user }));
        }),
        handleOidcAuthError
    );
};

export const authenticationNullUserEpic: RootEpic = (action$, state$, { authentication }) => {
    return action$.pipe(
        filter(isActionOf(actions.centralIdentitySetUser)),
        filter(action => action.payload.user === null),
        mergeMap(() => authentication.signinSilent()),
        mergeMap(user => {
            if (!user) {
                return of(actions.careerHubIdentitySetActive(undefined, { resetData: false }));
            }

            if (user.expired) {
                return authentication.removeUser().pipe(
                    handleOidcAuthError,
                    mergeMap(() =>
                        of(actions.careerHubIdentitySetActive(undefined, { resetData: false }))
                    )
                );
            }

            return of(actions.centralIdentityComplete({ user }));
        }),
        handleOidcAuthError
    );
};

export const authenticationRedirectEpic: RootEpic = (action$, state$, { authentication }) => {
    return action$.pipe(
        filter(isActionOf(actions.centralIdentityRedirect)),
        switchMap(() => authentication.signinRedirect()),
        handleOidcAuthError,
        ignoreElements()
    );
};

export const careerhubAuthenticationInitEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(actions.centralIdentityComplete)),
        mergeMap(() => of(actions.careerhubIdentityListAsync.request()))
    );
};

export const identityListEpic = asyncEpicStandard(actions.careerhubIdentityListAsync, ({ api }) =>
    api.identity.getIdentityList()
);

// This hooks into the identites success action
// and runs some logic based on the result
export const identityListSuccessEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(actions.careerhubIdentityListAsync.success)),
        mergeMap(action => {
            const { userId, identities } = action.payload.data;
            const validIdentities = identities.filter(i => !i.isDisabled);
            const hasUserId = !!userId;

            if (identities.length === 0) {
                return of(actions.careerHubIdentitySetActive(undefined, { resetData: false }));
            }

            // it's possible for a user to be logged in to CarrerHub, and also logged into
            // identity, and it's possible that these two do not overlap
            // for example. I can be logged into CarrerHub as an Admin, and then go to
            // identity and log in as a normal employer. The "userId" will be set to my CarrerHub Admin userId
            // but my Identities will be a collection of identities related to my Identity Employer.
            const userIdIdentity = hasUserId
                ? validIdentities.find(i => i.userId === userId)
                : undefined;

            // if the userId has a matching identity, the user is already logged in
            // therefore, set the auth and complete the app init.
            if (userIdIdentity) {
                // return authentication compelete with this identity
                return of(actions.careerHubIdentitySetActive(userIdIdentity, { resetData: false }));
            }

            // if the above is not true, but there is only 1 identity, we can log the user in
            // as this identity
            if (validIdentities.length === 1) {
                const request = identityHelper.createIdentitySetUserRequest(validIdentities[0]);
                return of(actions.careerhubIdentitySetUserAsync.request(request));
            }

            return of(actions.careerHubIdentitySetActive(undefined, { resetData: false }));
        })
    );
};

// based on the identity, call the relevant api endpoint
export const identitySetUserAsyncEpic = asyncEpicStandard(
    actions.careerhubIdentitySetUserAsync,
    ({ api }, { payload }) => {
        if (payload.contactId) {
            return api.identity.setContactIdentity(payload.contactId);
        }

        if (payload.individualId) {
            return api.identity.setIndividualIdentity(payload.individualId);
        }

        if (payload.centralContactId) {
            return api.identity.setCentralContactIdentity(payload.centralContactId);
        }

        // this should never get hit, and if it is hit, it will be logged downstream
        throw new Error(
            'Not Supported, identitySetUserAsync.request must have a contactId, individualId or centralContactId'
        );
    }
);

// On success, set the active identity
export const identitySetUserSuccessSetAuthenticationEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(actions.careerhubIdentitySetUserAsync.success)),
        mergeMap(({ payload }) =>
            of(actions.careerHubIdentitySetActive(payload.data, { resetData: true }))
        )
    );
};

// handle eventual redirect but only if user has a selected identity
// and only do this once
export const authenticationHandleInitialRedirect: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(actions.careerHubIdentitySetActive)),
        filter(action => !!action.payload),
        filter(
            () =>
                !state$.value.authentication.initCompleteHasRedirected &&
                !!state$.value.authentication.initCompleteRedirectTo
        ),
        mergeMap(() =>
            concat([
                push(state$.value.authentication.initCompleteRedirectTo),
                actions.authenticationHasRedirected(),
            ])
        )
    );
};

export const authenticationCompleteEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(
            isActionOf([
                actions.careerHubIdentitySetActive,
                actions.careerhubIdentitySetUserAsync.failure,
                actions.centralIdentityFailure,
                actions.careerhubIdentityListAsync.failure,
            ])
        ),
        filter(() => !state$.value.authentication.initComplete),
        mergeMap(() => of(actions.authenticationComplete()))
    );
};

// When the identity changes, many areas of state need to be reset back to their intial values.
// god damn this is a mess. You also need to check the initialisation epics for after app-complete redirects
// that should only happen once.
// it is super important that this reset gets called for users with multiple identities
export const identitySetActiveCleanUpEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(actions.careerHubIdentitySetActive)),
        // ignore this epic if it's the initial app load
        filter(({ meta }) => state$.value.authentication.initComplete && meta.resetData),
        mergeMap(() =>
            concat([
                // ToDo: consider, this is fairly hidden away, if new entities are added to the app
                // this is very VERY easy to miss...
                jobResetState(),
                contactResetState(),
                organisationResetState(),
                individualResetState(),
                formSubmissionResetState(),
                settingsResetState(),
                sessionReset(),
                workGroupResetState(),
                eventUnionResetState(),
                endorsementResetState(),

                // this is a bit weird of a place for this action, but it should redirect to somewhere after a reset
                push(identityPaths.landing),
            ])
        )
    );
};

// fire an action x minutes before the authentication jwt cookie expires
export const authenticationNearlyExpiredEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(actions.authenticationExpireSet)),
        map(action => parseISO(action.payload.expires)),
        filter(expireDate => isValid(expireDate)),
        mergeMap(expireDate => {
            const soonDate = add(expireDate, {
                minutes: -(
                    state$.value.apiConfiguration?.value?.settings?.slidingExpiryMinutes || 3
                ),
            });
            return of(actions.authenticationExpireSoon()).pipe(
                delay(soonDate),
                takeUntil(action$.pipe(filter(isActionOf(actions.authenticationExpireSet))))
            );
        })
    );
};

// fire an action when the authentication jwt cookie expires
export const authenticationExpiredSetEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(actions.authenticationExpireSet)),
        map(action => parseISO(action.payload.expires)),
        filter(expireDate => isValid(expireDate)),
        mergeMap(expireDate => {
            return concat([
                actions.authenticationExpireExpired(),
                actions.authenticationExpireSoonDismissed(),
            ]).pipe(
                delay(expireDate),
                takeUntil(action$.pipe(filter(isActionOf(actions.authenticationExpireSet))))
            );
        })
    );
};

// clear id_token if response returns with 401
// this probably needs to do more (i.e. we probably need to show that something is happening?)
// Note: 403 should almost never be hit, but it is possible, and when it does get hit, clearing
// the id is probably the safest bet.
export const authenticationLogoutEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(
            isActionOf([
                actions.authenticationLogoutSelected,
                actions.authenticationNotAuthorized,
                actions.authenticationForbidden,
                actions.authenticationExpireExpired,
            ])
        ),
        filter(action => {
            return (
                !isActionOf(actions.authenticationForbidden)(action) ||
                action.payload.message === 'You must be an employer.'
            );
        }),
        mergeMap(() => services.authentication.removeUser()),
        mergeMap(() => of(push(identityPaths.logout))),
        tap(() => {
            const idToken = state$.value.authentication.oidcUser?.id_token;
            const isCareerHubAuthenticated = state$.value.authentication.isCareerHubAuthenticated;
            const w = services.windowService.getWindow();

            // just to be sure, clear the identity token
            w.localStorage.clear();

            // if the user is careerhub authenticated
            // hit the special endpoint that clears the secure cookie
            if (idToken || isCareerHubAuthenticated) {
                const logout = new URL(
                    state$.value.configuration.value.careerHubBasePath +
                        '/employers/CentralAuth/AppLogout'
                );
                logout.searchParams.append('redirectUri', w.location.origin);
                if (idToken) {
                    logout.searchParams.append('idTokenHint', idToken);
                }

                w.location.href = logout.href;
            } else {
                // otherwise, just clear the state and reload
                // and hope to hell it resolves itself.
                w.location.reload();
            }
        })
    );
};

// on central identity failure, check to see if the "id_token" exists in the hash
// if it does, remove it. As any additional reload will cause a user not found in state issue
export const clearIdTokenFromHashOnFailure: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(actions.centralIdentityFailure)),
        filter(() => !!state$.value.router.location.hash),
        mergeMap(() => of(replace(state$.value.router.location.pathname)))
    );
};

export const clearUserOnFailure: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(actions.centralIdentityFailure)),
        filter(() => !!state$.value.authentication.oidcUser),
        tap(() => services.authentication.removeUser()),
        ignoreElements()
    );
};
