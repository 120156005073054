import { ListGroup } from 'react-bootstrap';
import { CareerHubEvent } from 'src/models/events/event.model';
import { eventPathCreator } from 'src/routes/employer/paths/event.paths';
import { WorkGroupLabel } from 'src/ui/features/work-groups/components/WorkGroupLabel';
import { ListGroupItemHeader } from 'src/ui/shared/components/bootstrap/lists/ListGroupItemHeader';
import { EventActionDropdown } from '../actions/EventActionDropdown';
import { EventStatusBadge } from '../badges/EventStatusBadge';
import { EventDateDisplay } from '../details/EventDateDisplay';

interface Props {
    event: CareerHubEvent;
}

export const EventListItem = ({ event }: Props): React.ReactElement<any, any> | null => {
    return (
        <ListGroup.Item>
            <ListGroupItemHeader>
                <ListGroupItemHeader.Title to={eventPathCreator.details(event.id)}>
                    {event.title}
                </ListGroupItemHeader.Title>
                <EventStatusBadge event={event} />
                <ListGroupItemHeader.Actions>
                    <EventActionDropdown event={event} size="xs" />
                </ListGroupItemHeader.Actions>
            </ListGroupItemHeader>
            <div className="mb-2">
                <EventDateDisplay event={event} />
            </div>
            <div>{event.summary}</div>
            {event.workGroupId && (
                <WorkGroupLabel className="mt-2 d-block" workGroupId={event.workGroupId} />
            )}
        </ListGroup.Item>
    );
};
