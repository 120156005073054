import { useMemo } from 'react';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

export const useEndorsementsIsEnabled = () => {
    const { value } = useApiConfiguration();
    const {
        all: { ids },
        managedEndorsementIds,
    } = useRootSelector(state => state.endorsements);

    const isEnabled = !!(value && value.settings && value.settings.disableEndorsements === false);

    const hasActiveManagedIds = useMemo(() => {
        if (!isEnabled) {
            return false;
        }

        return managedEndorsementIds.filter(id => ids.includes(id)).length > 0;
    }, [ids, isEnabled, managedEndorsementIds]);

    return {
        isEnabled,
        hasActiveManagedIds,
    };
};
