import { EntityBase } from 'src/models/store-models/entity-state.model';
import { PayloadAction, PayloadMetaAction } from 'typesafe-actions';
import { EntityRequest, EntityRequestOrUndefined } from '../../models/api/request.model';
import { ApiResponse } from '../../models/api/response.model';
import { ErrorNormalized } from '../../models/errors/error.model';
import { EntityListState } from '../../models/store-models/entity-list-state.model';

const setActive = <TState extends EntityListState<any, any>>(
    state: TState,
    action: Pick<PayloadAction<any, EntityRequestOrUndefined>, 'payload'>
): Readonly<TState> => ({
    ...state,
    activeId: action.payload.id,
});

const request = <TState extends EntityListState<any, any>>(
    state: TState,
    action: Pick<PayloadAction<any, EntityRequest>, 'payload'>
): Readonly<TState> => ({
    ...state,
    single: {
        ...state.single,
        [action.payload.id]: {
            ...state.single[action.payload.id],
            id: action.payload.id,
            fetch: { loading: true },
        },
    },
});

const create = <TState extends EntityListState<any, any>>(state: TState): Readonly<TState> => ({
    ...state,
    create: { loading: true },
});

const deleteRequest = <TState extends EntityListState<any, any>>(
    state: TState,
    action: Pick<PayloadAction<any, EntityRequest>, 'payload'>
): Readonly<TState> => ({
    ...state,
    single: {
        ...state.single,
        [action.payload.id]: {
            ...state.single[action.payload.id],
            deleteState: { loading: true },
        },
    },
});

// Note: this doesn't actually clear the entity, it just marks it as deleted
// this fixes some race conditions (setting the item to "undefined" would cause any component
// on the page that thinks it requires the entity to instantly request the entity.)
const deleteSuccess = <
    TEntityBase extends EntityBase,
    TState extends EntityListState<TEntityBase, any>
>(
    state: TState,
    action: Pick<PayloadAction<any, ApiResponse<TEntityBase>>, 'payload'>
): Readonly<TState> => ({
    ...state,
    single: {
        ...state.single,
        [action.payload.data.id]: {
            ...state.single[action.payload.data.id],
            deleteState: { loading: false },
            deleted: true,
        },
    },
    items: {
        ...state.items,
        [action.payload.data.id]: undefined,
    },
});

const updateRequest = <TState extends EntityListState<any, any>>(
    state: TState,
    action: Pick<
        PayloadAction<any, EntityRequest> | PayloadMetaAction<any, EntityRequest, any>,
        'payload'
    >
): Readonly<TState> => ({
    ...state,
    single: {
        ...state.single,
        [action.payload.id]: {
            ...state.single[action.payload.id],
            id: action.payload.id,
            fetch: { loading: false },
            update: { loading: true },
        },
    },
});

const success = <TEntityBase extends EntityBase, TState extends EntityListState<TEntityBase, any>>(
    state: TState,
    action: Pick<PayloadAction<any, ApiResponse<TEntityBase>>, 'payload'>
): Readonly<TState> => {
    const { data } = action.payload;

    return {
        ...state,
        create: { loading: false, error: undefined },
        single: {
            ...state.single,
            [data.id]: {
                ...state.single[data.id],
                id: data.id,
                fetch: { loading: false },
                update: { loading: false },
            },
        },
        items: {
            ...state.items,
            [data.id]: data,
        },
    };
};

const cancel = <TState extends EntityListState<any, any>>(
    state: TState,
    action: Pick<
        PayloadAction<any, EntityRequest> | PayloadMetaAction<any, EntityRequest, any>,
        'payload'
    >
): Readonly<TState> => ({
    ...state,
    single: {
        ...state.single,
        [action.payload.id]: undefined,
    },
});

const failure = <TState extends EntityListState<any, any>, TFailureMeta extends EntityRequest>(
    state: TState,
    action: Pick<PayloadMetaAction<any, ErrorNormalized, TFailureMeta>, 'payload' | 'meta'>
): Readonly<TState> => ({
    ...state,
    single: {
        ...state.single,
        [action.meta.id]: {
            ...state.single[action.meta.id],
            fetch: { loading: false, error: action.payload },
        },
    },
});

const updateFailure = <
    TState extends EntityListState<any, any>,
    TFailureMeta extends EntityRequest
>(
    state: TState,
    action: Pick<PayloadMetaAction<any, ErrorNormalized, TFailureMeta>, 'payload' | 'meta'>
): Readonly<TState> => ({
    ...state,
    single: {
        ...state.single,
        [action.meta.id]: {
            ...state.single[action.meta.id],
            update: { loading: false, error: action.payload },
        },
    },
});

const createFailure = <TState extends EntityListState<any, any>>(
    state: TState,
    action: Pick<PayloadAction<any, ErrorNormalized>, 'payload'>
): Readonly<TState> => ({
    ...state,
    create: { loading: false, error: action.payload },
});

const deleteFailure = <
    TState extends EntityListState<any, any>,
    TFailureMeta extends EntityRequest
>(
    state: TState,
    action: Pick<PayloadMetaAction<any, ErrorNormalized, TFailureMeta>, 'payload' | 'meta'>
): Readonly<TState> => ({
    ...state,
    single: {
        ...state.single,
        [action.meta.id]: {
            ...state.single[action.meta.id],
            deleteState: { loading: false, error: action.payload },
        },
    },
});

export const listSingleReducerHandler = {
    request,
    failure,
    success,
    cancel,
    updateRequest,
    updateFailure,
    create,
    createFailure,
    deleteRequest,
    deleteSuccess,
    deleteFailure,
    setActive,
};
