import values from 'lodash/values';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

export const useJobAttachments = (id?: number) => {
    const attachments = useRootSelector(state => state.jobs.items[id || -1]?.attachments || []);

    const deletedIds = useRootSelector(state =>
        values(state.attachments.deleteState)
            .filter(i => !!i && i.deleted)
            .map(i => i!.id)
    );

    return attachments.filter(i => !deletedIds.includes(i.storedFileId));
};
