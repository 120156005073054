import { PageTitle } from 'src/ui/shared/components/page-components/PageTitle';
import { useEventUnionTitles } from '../../hooks/event-union-titles.hook';
import { EventDetailsBreadcrumb } from './EventDetailsBreadcrumb';
import { EventListBreadcrumb } from './EventListBreadcrumb';

interface Props {
    children?: React.ReactNode;
}

export const EventSubPageTitle = ({ children }: Props): React.ReactElement<any, any> | null => {
    const { eventUnion, title } = useEventUnionTitles();

    return (
        <PageTitle>
            <EventListBreadcrumb />
            {eventUnion && title && <EventDetailsBreadcrumb id={eventUnion.id} title={title} />}
            {children}
        </PageTitle>
    );
};
