import { useCallback } from 'react';
import DOMPurify from 'dompurify';

export const useDomPurify = () => {
    const sanitizeHtml = useCallback(source => {
        return DOMPurify.sanitize(source, {
            USE_PROFILES: { html: true },
            ADD_ATTR: ['target'],
        });
    }, []);

    return {
        sanitizeHtml,
    };
};
