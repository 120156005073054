import { EntityRequest, EntityRequestOrUndefined } from 'src/models/api/request.model';
import {
    ApiIncludeResponse,
    ApiResponse,
    ApiSearchIncludeResponse,
} from 'src/models/api/response.model';
import { ErrorNormalized } from 'src/models/errors/error.model';
import {
    EventInclude,
    EventRequest,
    EventRequestPublish,
} from 'src/models/events/event-request.model';
import { EventUnion } from 'src/models/events/event-union.model';
import { CareerHubEvent } from 'src/models/events/event.model';
import { ActiveListState } from 'src/models/store-models/entity-list-state.model';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { EventUnionPageRequest } from './event-union.service';

interface EventBannerPayload {
    blob: Blob;
    fileName: string;
}

interface EventRequestCreatePayload {
    eventRequest: Partial<EventRequest>;
    banner?: EventBannerPayload;
}

interface EventRequestUpdatePayload {
    eventRequest: Partial<EventRequest> & EntityRequest;
    banner?: EventBannerPayload;
}

interface EventRequestCreateMeta {
    redirectToPublish: boolean;
}

interface EventRequestUpdateMeta extends EventRequestCreateMeta {
    id: number;
}

export const eventUnionResetState = createAction('EVENT_UNION_RESET_STATE')();

export const eventUnionSetActive =
    createAction('EVENT_UNION:SET_ACTIVE')<EntityRequestOrUndefined>();
export const eventUnionListSetActive = createAction('EVENT_UNION_LIST:SET_ACTIVE')<
    ActiveListState<EventUnionPageRequest>
>();
export const eventUnionListClear = createAction('EVENT_UNION_LIST:CLEAR')<{
    excludeActive: boolean;
}>();

export const eventUnionListAsync = createAsyncAction(
    'EVENT_UNION_LIST:REQUEST',
    'EVENT_UNION_LIST:SUCCESS',
    'EVENT_UNION_LIST:FAILURE',
    'EVENT_UNION_LIST:CANCEL'
)<
    EventUnionPageRequest,
    ApiSearchIncludeResponse<EventUnion, EventUnionPageRequest, EventInclude>,
    [ErrorNormalized, EventUnionPageRequest],
    undefined
>();

export const eventUnionAsync = createAsyncAction(
    'EVENT_UNION:REQUEST',
    'EVENT_UNION:SUCCESS',
    'EVENT_UNION:FAILURE',
    'EVENT_UNION:CANCEL'
)<
    EntityRequest,
    ApiIncludeResponse<EventUnion, EventInclude>,
    [ErrorNormalized, EntityRequest],
    EntityRequest
>();

export const eventRequestCreateAsync = createAsyncAction(
    'EVENT_REQUEST_CREATE:REQUEST',
    'EVENT_REQUEST_CREATE:SUCCESS',
    'EVENT_REQUEST_CREATE:FAILURE'
)<
    [EventRequestCreatePayload, EventRequestCreateMeta],
    [ApiResponse<EventRequest>, EventRequestCreateMeta],
    [ErrorNormalized, EventRequestCreateMeta]
>();

export const eventRequestDeleteAsync = createAsyncAction(
    'EVENT_REQUEST_DELETE:REQUEST',
    'EVENT_REQUEST_DELETE:SUCCESS',
    'EVENT_REQUEST_DELETE:FAILURE'
)<EntityRequest, ApiResponse<EventRequest>, [ErrorNormalized, EntityRequest]>();

export const eventRequestPublishAsync = createAsyncAction(
    'EVENT_REQUEST_PUBLISH:REQUEST',
    'EVENT_REQUEST_PUBLISH:SUCCESS',
    'EVENT_REQUEST_PUBLISH:FAILURE'
)<
    EventRequestPublish,
    [ApiResponse<EventRequest>, EventRequestPublish],
    [ErrorNormalized, EventRequestPublish]
>();

export const eventRequestRevertAsync = createAsyncAction(
    'EVENT_REQUEST_REVERT:REQUEST',
    'EVENT_REQUEST_REVERT:SUCCESS',
    'EVENT_REQUEST_REVERT:FAILURE'
)<EntityRequest, [ApiResponse<EventRequest>, EntityRequest], [ErrorNormalized, EntityRequest]>();

export const eventRequestUpdateAsync = createAsyncAction(
    'EVENT_REQUEST_UPDATE:REQUEST',
    'EVENT_REQUEST_UPDATE:SUCCESS',
    'EVENT_REQUEST_UPDATE:FAILURE'
)<
    [EventRequestUpdatePayload, EventRequestUpdateMeta],
    [ApiResponse<EventRequest>, EventRequestUpdateMeta],
    [ErrorNormalized, EventRequestUpdateMeta]
>();

export const eventCancelAsync = createAsyncAction(
    'EVENT_CANCEL:REQUEST',
    'EVENT_CANCEL:SUCCESS',
    'EVENT_CANCEL:FAILURE'
)<EntityRequest, ApiResponse<CareerHubEvent>, [ErrorNormalized, EntityRequest]>();

export const eventDismissCancelMessage = createAction('EVENT_CANCEL_MESSAGE:DISMISS')();
