import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { contactPathCreator } from 'src/routes/employer/paths/contact.paths';
import { A } from 'src/ui/shared/components/anchors/A';
import { DetailsProperty } from 'src/ui/shared/components/details/DetailsProperty';
import { useAllContacts } from '../../hooks/all-contacts.hook';
import { ContactName } from '../shared/ContactName';

interface Props {
    id: number;
}

export const ContactDetailsProperty = ({ id }: Props): React.ReactElement<any, any> | null => {
    const { contacts } = useAllContacts();

    const contact = useMemo(() => {
        return contacts.find(x => x.id === id);
    }, [contacts, id]);

    return !contact ? null : (
        <DetailsProperty label="Contact" icon={faUserTie}>
            <A href={contactPathCreator.edit(contact.id)}>
                <ContactName contact={contact} />
            </A>
        </DetailsProperty>
    );
};
