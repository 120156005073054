import { getClientConfig } from 'src/clients-internal/configuration.init';
import { ApiResponse } from '../../../models/api/response.model';
import { ObsApiExpected } from '../../../models/api/service.model';
import { Organisation } from '../../../models/organisation/organisation.model';
import { fetchHelper } from '../../helpers/fetch.helper';

const basePath = () => `${getClientConfig().careerHubBasePath}/services/employers/v1`;

export function get(): ObsApiExpected<ApiResponse<Organisation>> {
    return fetchHelper.get(`${basePath()}/organisation`);
}

export function update(organisation: Organisation): ObsApiExpected<ApiResponse<Organisation>> {
    return fetchHelper.put(`${basePath()}/organisation`, organisation);
}

export const logo = (file: File): ObsApiExpected<ApiResponse<Organisation>> => {
    const formData = new FormData();
    formData.append('file', file);

    return fetchHelper.post(`${basePath()}/images/logo`, formData);
};

export const logoDelete = (): ObsApiExpected<ApiResponse<Organisation>> => {
    return fetchHelper.del(`${basePath()}/images/logo`);
}