import { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { HookForm } from '../../HookForm';
import { EventLocationControlContext, EventLocationForm } from './EventLocationControl';

const countryName: keyof EventLocationForm = 'offCampusCountry';
const regionName: keyof EventLocationForm = 'offCampusRegion';
const cityName: keyof EventLocationForm = 'offCampusCity';
const addressName: keyof EventLocationForm = 'offCampusAddress';

export const EventLocationOffCampusControl = (): React.ReactElement<any, any> | null => {
    const { watch } = useFormContext();
    const controlName = useContext(EventLocationControlContext);
    const countryControlName = useMemo(() => `${controlName}.${countryName}`, [controlName]);
    const hasCountryValue = watch(countryControlName) as string | undefined;

    const {
        shared: {
            forms: { location },
        },
    } = useStrings();

    return (
        <>
            <HookForm.Row>
                <HookForm.Col sm={4}>
                    <HookForm.CountrySelect
                        name={countryControlName}
                        label={location.country}
                        rules={{ required: true }}
                    />
                </HookForm.Col>
                <HookForm.Col sm={4}>
                    <HookForm.Region
                        name={`${controlName}.${regionName}`}
                        label={location.region}
                        allowEmpty={true}
                        disabled={!hasCountryValue}
                        countryCode={hasCountryValue}
                        rules={{ required: true }}
                    />
                </HookForm.Col>
                <HookForm.Col sm={4}>
                    <HookForm.Input
                        name={`${controlName}.${cityName}`}
                        disabled={!hasCountryValue}
                        label={location.city}
                        rules={{ required: true }}
                    />
                </HookForm.Col>
            </HookForm.Row>
            <HookForm.Input
                label="Address"
                name={`${controlName}.${addressName}`}
                rules={{ required: true }}
            />
        </>
    );
};
