import { createReducer } from 'typesafe-actions';
import { LoaderState } from '../../../models/errors/error.model';
import * as actions from './email-claims.actions';

export type EmailClaimsState = Readonly<{
    modal: {
        show: boolean;
    };
    email?: string | undefined;
    emailAdd: LoaderState;
    emailAddSuccess: boolean;
    userInfoFetch: LoaderState;
}>;

const initialState: EmailClaimsState = {
    modal: {
        show: false,
    },
    emailAdd: {
        loading: false,
    },
    emailAddSuccess: false,
    userInfoFetch: {
        loading: false,
    },
};

const emailClaimsReducer = createReducer(initialState)
    .handleAction(actions.emailClaimsShowModal, (state, action) => ({
        ...state,
        modal: {
            ...state.modal,
            show: action.payload.show,
        },
        emailAdd: initialState.emailAdd,
    }))
    .handleAction(actions.emailClaimsAddAsync.request, (state, action) => ({
        ...state,
        email: action.payload.email,
        emailAdd: {
            loading: true,
        },
    }))
    // The response is handled in the authentication reducer, as it
    // can map directly to the object created by oidc-client
    .handleAction(actions.emailClaimsAddAsync.success, state => ({
        ...state,
        emailAdd: {
            loading: false,
        },
        emailAddSuccess: true,
    }))
    .handleAction(actions.emailClaimsAddAsync.failure, (state, action) => ({
        ...state,
        emailAdd: {
            loading: false,
            error: action.payload,
        },
    }))
    .handleAction(actions.userInfoAsync.request, state => ({
        ...state,
        userInfoFetch: {
            loading: true,
        },
    }))
    .handleAction(actions.userInfoAsync.success, state => ({
        ...state,
        userInfoFetch: {
            loading: false,
        },
    }))
    .handleAction(actions.userInfoAsync.failure, (state, action) => ({
        ...state,
        userInfoFetch: {
            loading: false,
            error: action.payload,
        },
    }))
    .handleAction(actions.userInfoAsync.cancel, state => ({
        ...state,
        userInfoFetch: {
            loading: false,
        },
    }))
    .handleAction(actions.emailClaimsReset, () => initialState);

export default emailClaimsReducer;
