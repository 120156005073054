import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Badge } from 'react-bootstrap';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { LocalCategoryMapV2 } from 'src/models/central/central-mapping.model';
import { EmployerOption } from 'src/models/api-configuration/employer-option.model';
import { useCentralCategoryNames } from '../../hooks/central-category-names.hook';

interface Props {
    options: EmployerOption[] | undefined;
    categoryKey: keyof LocalCategoryMapV2;
}

export const CategoryMappings = ({
    options,
    categoryKey,
}: Props): React.ReactElement<any, any> | null => {
    const { getCentralCategoryNames } = useCentralCategoryNames();

    return (
        <>
            {options?.map(i => (
                <div key={i.id} className="d-flex align-items-center mb-2">
                    <Badge variant="outline-input" className="text-nowrap">
                        {i.name}
                    </Badge>
                    <Icon icon={faArrowRight} iconClassName="mx-2" />{' '}
                    <div>
                        {getCentralCategoryNames(categoryKey, i.id).map(central => (
                            <Badge key={central} variant="outline-primary" className="mr-1">
                                {central}
                            </Badge>
                        ))}
                    </div>
                </div>
            ))}
        </>
    );
};
