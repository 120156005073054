import { push } from 'connected-react-router';
import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { registerPaths } from 'src/routes/register/register.paths';
import { isActionOf } from 'typesafe-actions';
import { RootEpic } from '../../epic.root-index';
import { registerNameStore } from '../register.actions';

export const redirectAfterNameEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(registerNameStore)),
        filter(() => state$.value.router.location.pathname === registerPaths.name),
        mergeMap(() => of(push(registerPaths.similar)))
    );
};
