import { CollectionContent, ContentKey } from '@careerhub/content-shared';
import { stringify } from 'qs';
import { storeRegistry } from 'src/logic/store.registry';

export type ContentPageBaseParams = {
    collection?: string;
};

export const contentPaths = {
    public: '/content/:collection',
    protected: '/protected/:collection',
};

const createSectionTitleQuery = (sectionTitle: string | undefined) =>
    stringify({ section: sectionTitle?.trimEnd() }, { addQueryPrefix: true, format: 'RFC1738' });

export const replaceCollectionTitle = (title: string | undefined) =>
    title?.toLowerCase().trimEnd().replace(/\s/g, '+');

export const contentPathCreator = {
    contentPage: (contentKey: ContentKey, collection: CollectionContent, sectionTitle?: string) => {
        const searchString = createSectionTitleQuery(sectionTitle);

        // this is a bit of a cheat, you aren't suppose to change spaces to + in the path part of the url
        // also it has to be decoded on the other side (in the epic)
        const collectonPath = replaceCollectionTitle(collection.title);
        const store = storeRegistry.get();

        if (
            contentKey === 'employerContent' &&
            store.getState().content[contentKey].firstCollectionId === collection.id
        ) {
            return `/${searchString}`;
        }

        const contentPath = contentKey === 'employerContent' ? 'content' : 'protected';

        return `/${contentPath}/${collectonPath!}${searchString}`;
    },
};
