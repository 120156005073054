import { createAction, createAsyncAction } from 'typesafe-actions';
import { EntityRequest, EntityRequestOrUndefined } from '../../../models/api/request.model';
import { ApiResponse, ApiSearchResponse } from '../../../models/api/response.model';
import { ErrorNormalized } from '../../../models/errors/error.model';
import { JobChangeRequest } from '../../../models/jobs/job-change-request.model';
import { JobCreateModel } from '../../../models/jobs/job-create.model';
import { JobPublishRequest } from '../../../models/jobs/job-publish-request.model';
import { Job } from '../../../models/jobs/job.model';
import { ActiveListState } from '../../../models/store-models/entity-list-state.model';
import { JobPageRequest } from './job.service';

interface JobCreateMeta {
    redirectToPublish: boolean;
}
interface JobUpdateMeta extends JobCreateMeta {
    id: number;
}

export const jobResetState = createAction('JOB_RESET_STATE')();

export const jobSetActive = createAction('JOB:SET_ACTIVE')<EntityRequestOrUndefined>();
export const jobListSetActive =
    createAction('JOB_LIST:SET_ACTIVE')<ActiveListState<JobPageRequest>>();
export const jobListClear = createAction('JOB_LIST:CLEAR')<{ excludeActive: boolean }>();
export const jobRepublishIdSetActive = createAction('JOB_REPUBLISH_ID:SET_ACTIVE')<{
    republishId: number | undefined;
}>();

export const jobListAsync = createAsyncAction(
    'JOB_LIST:REQUEST',
    'JOB_LIST:SUCCESS',
    'JOB_LIST:FAILURE',
    'JOB_LIST:CANCEL'
)<
    JobPageRequest,
    ApiSearchResponse<Job, JobPageRequest>,
    [ErrorNormalized, JobPageRequest],
    undefined
>();

export const jobAsync = createAsyncAction(
    'JOB:REQUEST',
    'JOB:SUCCESS',
    'JOB:FAILURE',
    'JOB:CANCEL'
)<EntityRequest, ApiResponse<Job>, [ErrorNormalized, EntityRequest], EntityRequest>();

export const jobCreateAsync = createAsyncAction(
    'JOB_CREATE:REQUEST',
    'JOB_CREATE:SUCCESS',
    'JOB_CREATE:FAILURE'
)<
    [JobCreateModel, JobCreateMeta],
    [ApiResponse<Job>, JobCreateMeta],
    [ErrorNormalized, JobCreateMeta]
>();

export const jobUpdateAsync = createAsyncAction(
    'JOB_UPDATE:REQUEST',
    'JOB_UPDATE:SUCCESS',
    'JOB_UPDATE:FAILURE'
)<
    [JobCreateModel, JobUpdateMeta],
    [ApiResponse<Job>, JobUpdateMeta],
    [ErrorNormalized, JobUpdateMeta]
>();

export const jobDeleteAsync = createAsyncAction(
    'JOB_DELETE:REQUEST',
    'JOB_DELETE:SUCCESS',
    'JOB_DELETE:FAILURE'
)<EntityRequest, ApiResponse<Job>, [ErrorNormalized, EntityRequest]>();

export const jobPublishAsync = createAsyncAction(
    'JOB_PUBLISH:REQUEST',
    'JOB_PUBLISH:SUCCESS',
    'JOB_PUBLISH:FAILURE'
)<JobPublishRequest, ApiResponse<Job>, [ErrorNormalized, JobPublishRequest]>();

export const jobWithdrawAsync = createAsyncAction(
    'JOB_WITHDRAW:REQUEST',
    'JOB_WITHDRAW:SUCCESS',
    'JOB_WITHDRAW:FAILURE'
)<EntityRequest, ApiResponse<Job>, [ErrorNormalized, EntityRequest]>();

export const jobChangeRequestAsync = createAsyncAction(
    'JOB_CHANGE_REQUEST:REQUEST',
    'JOB_CHANGE_REQUEST:SUCCESS',
    'JOB_CHANGE_REQUEST:FAILURE'
)<JobChangeRequest, ApiResponse<JobChangeRequest>, [ErrorNormalized, JobChangeRequest]>();
