import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { HistoryContainer } from './HistoryContainer';
import { ToastContainer } from './ToastContainer';

export const SessionContainer = (): React.ReactElement<any, any> | null => {
    const { showHistory } = useRootSelector(state => state.session);

    return (
        <div className="position-sticky tr-0 z-100">
            <div className="position-absolute tr-0 z-100">
                {showHistory ? <HistoryContainer /> : <ToastContainer />}
            </div>
        </div>
    );
};
