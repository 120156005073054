import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { push } from 'connected-react-router';
import { useEffect, useMemo, useState } from 'react';
import { ListGroup, Pagination } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { careerhubIdentitySetUserAsync } from 'src/logic/features/authentication/authentication.actions';
import {
    CentralContactIdentity,
    ContactIdentity,
    EmployerIdentity,
} from 'src/models/authentication/identity.model';
import { identityPaths } from 'src/routes/identity/identity.paths';
import { registerPaths } from 'src/routes/register/register.paths';
import { useLayout } from 'src/routes/RouteList';
import { ButtonLink } from 'src/ui/shared/components/bootstrap/buttons/ButtonLink';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { LoaderSpinner } from 'src/ui/shared/components/loader/LoaderSpinner';
import { PageTitle } from 'src/ui/shared/components/page-components/PageTitle';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { identityHelper } from '../../helpers/identity.helper';
import { useAuthentication } from '../../hooks/authentication.hook';
import { ContactIdentityListItem } from './ContactIdentityListItem';
import { IndividualIdentityListItem } from './IndividualIdentityListItem';

export const IdentityList = (): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    const { isApplicationLayout } = useLayout();

    const { identities, identitySetUserFetching, activeIdentity, identityListState } =
        useAuthentication();

    const [showDisabled, setShowDisabled] = useState(false);

    const disabledCount = useMemo(() => identities.filter(i => i.isDisabled).length, [identities]);

    const {
        identity: {
            list: { title },
        },
        nav: {
            user: { register },
        },
    } = useStrings();

    useEffect(() => {
        // if there are only disabled account, may as well show em
        if (disabledCount > 0 && disabledCount === identities.length) {
            setShowDisabled(true);
        }
    }, [disabledCount, identities]);

    const individuals = identities.filter(identityHelper.isIndividual);
    const orgContacts = identities.filter(i => !identityHelper.isIndividual(i)) as (
        | CentralContactIdentity
        | ContactIdentity
    )[];

    const onSetAction = (identity: EmployerIdentity) => {
        if (identity.isDisabled) {
            return;
        }

        if (activeIdentity && activeIdentity.userId === identity.userId) {
            dispatch(push(identityPaths.landing));
        } else {
            const request = identityHelper.createIdentitySetUserRequest(identity);
            dispatch(careerhubIdentitySetUserAsync.request(request));
        }
    };

    return (
        <Loader
            state={[{ loading: identitySetUserFetching }, identityListState]}
            loaderComponent={
                <div className="text-center mx-auto">
                    <LoaderSpinner />
                </div>
            }
        >
            <div className="d-flex align-items-end mb-2">
                <PageTitle className="mr-2 mr-md-4">{title}</PageTitle>
                {disabledCount > 0 && (
                    <Pagination size="sm" className="pagination-button-group ml-auto mb-0">
                        <Pagination.Item
                            active={showDisabled}
                            onClick={() => setShowDisabled(!showDisabled)}
                        >
                            {showDisabled ? 'Hide' : 'Show'} disabled ({disabledCount})
                        </Pagination.Item>
                    </Pagination>
                )}
            </div>
            <ListGroup className="mb-2">
                {identities.length > 0 && (
                    <>
                        {individuals
                            .filter(i => (showDisabled ? true : !i.isDisabled))
                            .map((i, index) => (
                                <IndividualIdentityListItem
                                    identity={i}
                                    key={index}
                                    action={true}
                                    onClick={() => onSetAction(i)}
                                />
                            ))}
                        {orgContacts
                            .filter(i => (showDisabled ? true : !i.isDisabled))
                            .map((i, index: number) => (
                                <ContactIdentityListItem
                                    key={index}
                                    action={true}
                                    onClick={() => onSetAction(i)}
                                    identity={i}
                                    // if it's pending approval and doesn't exist locally
                                    // it can be pending approval and exist locally if the organisation
                                    // was created via MSP
                                    // I don't think this is necessary anymore, central should never allow this
                                    // I retract the previous sentence, this is possible and probably I need to
                                    // display more information to the user.
                                    forceDisable={
                                        (i as CentralContactIdentity)?.organisationStatus ===
                                            'PendingApproval' && !i.contactId
                                    }
                                />
                            ))}
                    </>
                )}
            </ListGroup>
            {!isApplicationLayout && (
                <div className="text-center mt-4">
                    <ButtonLink icon={faUserPlus} variant="link" to={registerPaths.start}>
                        {register}
                    </ButtonLink>
                </div>
            )}
        </Loader>
    );
};
