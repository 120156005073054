import { createAsyncAction, createAction } from 'typesafe-actions';
import { ApiResponse } from '../../../models/api/response.model';
import { Organisation } from '../../../models/organisation/organisation.model';
import { ErrorNormalized } from '../../../models/errors/error.model';

interface OrganisationLogoUpdate { organisation: Organisation, logo: File | undefined }

export const organisationResetState = createAction('ORGANISATION_RESET_STATE')();

export const organisationAsync = createAsyncAction(
    'ORGANISATION:REQUEST',
    'ORGANISATION:SUCCESS',
    'ORGANISATION:FAILURE',
    'ORGANISATION:CANCEL'
)<undefined, ApiResponse<Organisation>, ErrorNormalized, undefined>();

export const organisationUpdateAsync = createAsyncAction(
    'ORGANISATION_UPDATE:REQUEST',
    'ORGANISATION_UPDATE:SUCCESS',
    'ORGANISATION_UPDATE:FAILURE'
)<OrganisationLogoUpdate, [ApiResponse<Organisation>, OrganisationLogoUpdate], ErrorNormalized>();

export const organisationLogoAsync = createAsyncAction(
    'ORGANISATION_LOGO:REQUEST',
    'ORGANISATION_LOGO:SUCCESS',
    'ORGANISATION_LOGO:FAILURE'
)<
    { file: File },
    ApiResponse<Organisation>,
    ErrorNormalized
>();

export const organisationLogoDeleteAsync = createAsyncAction(
    'ORGANISATION_LOGO_DELETE:REQUEST',
    'ORGANISATION_LOGO_DELETE:SUCCESS',
    'ORGANISATION_LOGO_DELETE:FAILURE'
)<
    undefined,
    ApiResponse<Organisation>,
    ErrorNormalized
>();