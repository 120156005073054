import { z } from 'zod';

export const geolocationSchema = z.object({
    top: z.number(),
    left: z.number(),
    bottom: z.number(),
    right: z.number(),
});

export type GeolocationModel = z.infer<typeof geolocationSchema>;
