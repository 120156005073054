import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Icon, IconDefinitionAll } from '../icon/Icon';

export interface AnchorProps {
    href: string | undefined;
    className?: string;
    rel?: string;
    children?: React.ReactNode;
}

interface Props extends AnchorProps {
    prefix?: 'tel' | 'mailto';
    icon?: IconDefinitionAll;
    target?: string;
}

export const A = ({
    href,
    children,
    prefix,
    icon,
    className,
    rel,
    target,
}: Props): React.ReactElement<any, any> | null => {
    const hrefValue = useMemo(() => {
        if (!href) {
            return null;
        }

        let innerHref = href;
        if (href.startsWith(window.origin)) {
            // remember, replace only ever modifies the first instance
            innerHref = href.replace(window.origin, '');
            if (innerHref === '') {
                innerHref = '/';
            }
        }

        return prefix ? prefix + ':' + innerHref : innerHref;
    }, [href, prefix]);

    // not really sure if this should be handled by this component, but whatever
    const isRelative = useMemo(() => {
        if (!hrefValue) {
            return false;
        }

        return !hrefValue.startsWith('https://') && !hrefValue.startsWith('http://');
    }, [hrefValue]);

    if (!hrefValue) {
        return null;
    }

    // see: https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-target-blank.md
    const relToUse = rel ? rel : target === '_blank' ? 'noreferrer' : rel;

    const link = isRelative ? (
        <Link to={hrefValue}>{children || href}</Link>
    ) : (
        <a href={hrefValue} rel={relToUse} target={target}>
            {children || href}
        </a>
    );

    return <span className={className}>{icon ? <Icon icon={icon}>{link}</Icon> : link}</span>;
};
