interface Props {
    children?: React.ReactNode;
}

export const ThinPageContent = ({ children }: Props): React.ReactElement<any, any> | null => {
    return (
        <div className="text-left bg-white">
            <div className="pt-4 pb-5 register-width px-md-5 mx-auto">{children}</div>
        </div>
    );
};
