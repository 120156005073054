import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useLayout } from 'src/routes/RouteList';
import { sessionShowHistory } from '../../../../../logic/features/sessions/session.actions';
import { Icon } from '../../icon/Icon';

export const UserHistoryDropdown = (): React.ReactElement<any, any> | null => {
    const { showHistory, history } = useRootSelector(state => state.session);
    const dispatch = useDispatch();
    const { navLinkVariant } = useLayout();

    const onClick = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        dispatch(sessionShowHistory({ showHistory: !showHistory }));

        // this is a bit of a hack
        e.stopPropagation();
    };

    return history.length > 0 ? (
        <Dropdown>
            <Dropdown.Toggle
                variant={navLinkVariant}
                className={`no-icon no-focus ${showHistory ? 'focus' : ''}`}
                onClick={onClick}
            >
                <Icon icon={faHistory} size="lg" />
            </Dropdown.Toggle>
        </Dropdown>
    ) : null;
};
