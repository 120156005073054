import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import values from 'lodash/values';
import { ErrorNormalized } from '../../../../../models/errors/error.model';
import { AlertWrapper } from '../../bootstrap/alerts/AlertWrapper';

interface Props {
    error: ErrorNormalized | undefined;
}

// Note: this will only appear on Server Error responses (so it should really never appear)
export const HookFormErrorAlert = ({ error }: Props): React.ReactElement<any, any> | null => {
    return error ? (
        <AlertWrapper variant="danger" icon={faExclamationTriangle}>
            <h4 className="alert-heading">{error?.message}</h4>
            {error.validationErrors && (
                <ul className="alert-text">
                    {values(error.validationErrors).map((validationError, index) => (
                        <li key={index}>{validationError}</li>
                    ))}
                </ul>
            )}
        </AlertWrapper>
    ) : null;
};
