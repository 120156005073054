import { faHashtag, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { shallowEqual } from 'react-redux';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { OrganisationSimilar } from '../../../../../models/organisation/organisation-similar.model';
import { ListGroupItemHeader } from '../../../../shared/components/bootstrap/lists/ListGroupItemHeader';
import { Icon } from '../../../../shared/components/icon/Icon';
import { useCountryList } from '../../../../shared/hooks/country-list.hook';

interface Props {
    organisation: OrganisationSimilar;
    onClick: () => void;
}

export const OrganisationSimilarListItem = ({
    organisation,
    onClick,
}: Props): React.ReactElement<any, any> | null => {
    const requestedIds = useRootSelector(state => state.register.requestAccess.ids, shallowEqual);
    const requested = useMemo(
        () => requestedIds.includes(organisation.id),
        [requestedIds, organisation]
    );

    const { getName } = useCountryList();
    const {
        register: { requestAccess },
    } = useStrings();

    return (
        <ListGroup.Item>
            <ListGroupItemHeader>
                <ListGroupItemHeader.Title>
                    {organisation.name}
                    {organisation.acronym && <small className="ml-2">{organisation.acronym}</small>}
                </ListGroupItemHeader.Title>

                <ListGroupItemHeader.Actions>
                    {requested && (
                        <Button className="btn-sm" disabled={true}>
                            {requestAccess.requested}
                        </Button>
                    )}
                    {!requested && (
                        <Button className="btn-sm" onClick={() => onClick()}>
                            {requestAccess.action}
                        </Button>
                    )}
                </ListGroupItemHeader.Actions>
            </ListGroupItemHeader>

            {organisation.division && <div>{organisation.division}</div>}
            <div>
                <Icon icon={faMapMarkerAlt}>
                    {getName(organisation.countryCode) || organisation.countryCode}
                </Icon>
                {organisation.businessId && (
                    <Icon icon={faHashtag} iconClassName="ml-3">
                        {organisation.businessId}
                    </Icon>
                )}
            </div>
        </ListGroup.Item>
    );
};
