import { useMemo } from 'react';
import { Col, Form } from 'react-bootstrap';
import { ErrorNormalized } from 'src/models/errors/error.model';
import { useShowBusinessIdHook } from 'src/ui/features/organisation/hooks/show-business-id-field.hook';
import { StringsAlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/StringsAlertWrapper';
import { HookFormButtonsProps } from 'src/ui/shared/components/forms/shared/HookFormButtons';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { ORGANISATION_TYPE_SELECT_OPTIONS } from '../../../../../constants/select-options.constants';
import { RegisterName } from '../../../../../models/register/register-name.model';
import { HookForm } from '../../../../shared/components/forms/HookForm';
import { createMaxLengthRule } from '../../../../shared/helpers/validation.helper';
import { useBusinessIdValidator } from '../../../../shared/hooks/business-id-validator.hook';
import { useFormDefault } from '../../../../shared/hooks/form-default.hook';

interface Props extends HookFormButtonsProps {
    registerName?: RegisterName;
    onSubmit: (data: RegisterName) => void;
    onCancel: () => void;
    error?: ErrorNormalized;
    disableBusinessIdValidator?: boolean;
    removeEmployerType?: boolean;
}

export const RegisterNameForm = ({
    registerName,
    error,
    onCancel,
    onSubmit,
    cancelText,
    submitText,
    showSpinner,
    disableBusinessIdValidator,
    removeEmployerType,
}: Props): React.ReactElement<any, any> | null => {
    const settings = useRootSelector(state => state.apiConfiguration.value?.settings);

    const formMethods = useFormDefault<RegisterName>({
        defaultValues: registerName || undefined,
        error,
    });

    const organisationTypeSelectOptions = useMemo(() => {
        if (!settings) {
            return ORGANISATION_TYPE_SELECT_OPTIONS;
        }

        return ORGANISATION_TYPE_SELECT_OPTIONS.filter(i =>
            settings.disableAgentEmployers ? i.value !== 'AgentForEmployer' : true
        );
    }, [settings]);

    const { businessIdFormLabel, businessIdValidator } = useBusinessIdValidator(formMethods, {
        disableBusinessIdValidator,
    });

    const {
        register: {
            name: { form },
        },
    } = useStrings();

    const countryCodeValue = formMethods.watch('countryCode');
    const showBusinessIdField = useShowBusinessIdHook(countryCodeValue);

    return (
        <HookForm {...formMethods} onSubmit={onSubmit}>
            <StringsAlertWrapper alerts={form.alerts} />

            <Form.Row>
                <Col xs={12} md={6}>
                    <HookForm.Input
                        {...form.name}
                        name="name"
                        rules={{ required: true, maxLength: createMaxLengthRule(255) }}
                    />
                </Col>
                {!removeEmployerType && (
                    <Col xs={12} md={6}>
                        <HookForm.Select
                            {...form.organisationType}
                            name="employerType"
                            allowEmpty={true}
                            options={organisationTypeSelectOptions}
                            rules={{ required: true }}
                        />
                    </Col>
                )}
            </Form.Row>
            <Form.Row>
                <Col xs={12} md={6}>
                    <HookForm.CountrySelect
                        label={form.country.label}
                        name="countryCode"
                        rules={{ required: true }}
                    />
                </Col>
                {/* this uses opacity because buisnessId cannot be null, it will cause an error
                in careerhub versions lower than 5.9 (this can be updated when all clients > 5.8) */}
                <Col xs={12} md={6} style={{ opacity: showBusinessIdField ? 1 : 0 }}>
                    <HookForm.Input
                        {...form.businessId}
                        label={businessIdFormLabel}
                        name="businessId"
                        rules={{
                            validate: disableBusinessIdValidator
                                ? undefined
                                : { customBusinessId: businessIdValidator },
                            maxLength: createMaxLengthRule(30),
                        }}
                    />
                </Col>
            </Form.Row>

            <HookForm.Buttons
                onCancel={onCancel}
                cancelText={cancelText}
                submitText={submitText}
                showSpinner={showSpinner}
            />
        </HookForm>
    );
};
