import { useMemo } from 'react';
import { Job } from 'src/models/jobs/job.model';
import { useCentralCategoryMappings } from './central-mappings.hook';

export const useJobCentralMappings = (job: Job) => {
    const { hasCentralMapping: hasCentralMappingV2 } = useCentralCategoryMappings();

    const jobOccupationIds = useMemo(() => (job && job.occupations?.map(i => i.id)) || [], [job]);

    const jobTypeOfWorkIds = useMemo(() => (job && job.typesOfWork?.map(i => i.id)) || [], [job]);

    const hasJobOccupationMappings = useMemo(
        () => hasCentralMappingV2('occupations', jobOccupationIds),
        [hasCentralMappingV2, jobOccupationIds]
    );
    const hasTypesOfWorkMappings = useMemo(
        () => hasCentralMappingV2('typesOfWork', jobTypeOfWorkIds),
        [hasCentralMappingV2, jobTypeOfWorkIds]
    );

    return { hasJobOccupationMappings, hasTypesOfWorkMappings };
};
