import { ListGroupItem } from 'react-bootstrap';
import { IdentityType } from '../../../../../models/authentication/identity.model';
import { Icon, IconDefinitionAll } from '../../../../shared/components/icon/Icon';

export interface IdentityListItemBaseProps {
    onClick?: () => void;
    action?: boolean;
    forceDisable?: boolean;
}

interface IdentityListItemProps extends IdentityListItemBaseProps {
    identity: IdentityType;
    icon: IconDefinitionAll;
    children?: React.ReactNode;
}

export const IdentityListItem = ({
    onClick,
    action,
    identity,
    children,
    icon,
    forceDisable,
}: IdentityListItemProps): React.ReactElement<any, any> | null => {
    const isDisabled = identity.isDisabled || forceDisable;
    return (
        <ListGroupItem action={action && !isDisabled} disabled={isDisabled} onClick={onClick}>
            <div className="d-flex align-items-start mb-2">
                <Icon size="lg" icon={icon} iconClassName="mr-2" />
                <div className="w-100">{children}</div>
            </div>
        </ListGroupItem>
    );
};
