import { useCallback, useState } from 'react';
import { fetchHelper, FetchHelperMethods } from 'src/logic/helpers/fetch.helper';
import { ErrorNormalized, LoaderState } from 'src/models/errors/error.model';

export const useSimpleApiRequest = <TResponse>() => {
    const [requestState, setRequestState] = useState<LoaderState>({ loading: false });
    const [response, setResponse] = useState<Partial<TResponse> | undefined>(undefined);

    const request = useCallback(
        (
            url: string,
            options?: {
                method?: FetchHelperMethods;
                body?: any;
                onSuccess?: (result: Partial<TResponse> | undefined) => void;
            }
        ) => {
            setRequestState({ loading: true });

            fetchHelper
                .fetch(url, options?.method || 'GET', options?.body)
                .toPromise()
                .then(resp => {
                    // use partial to be extra careful with the response
                    const result = resp.json as Partial<TResponse> | undefined;
                    setResponse(result);
                    setRequestState({ loading: false });
                    options?.onSuccess?.(result);
                })
                .catch((error: ErrorNormalized) => {
                    setRequestState({ loading: false, error });
                });
        },
        []
    );

    return {
        requestState,
        response,
        request,
    };
};
