import { SiteDisplay } from '@careerhub/content-shared';
import { Helmet } from 'react-helmet';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { NotFound } from 'src/ui/shared/components/page-components/NotFound';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';
import { useContentAnchorOverride } from '../hooks/content-anchor-override.hook';
import { useContentScroll } from '../hooks/content-scroll.hook';
import { useContent } from '../hooks/content.hook';

export const PrivateContentPage = (): React.ReactElement<any, any> | null => {
    const { context, config, documentTitle, fetchState, activeCollectionId } =
        useContent('employerProtected');

    const { onClick } = useContentAnchorOverride();
    useContentScroll('employerProtected');

    return (
        <ApplicationPageContent>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <Loader state={fetchState} spinnerClassName="m-5">
                {activeCollectionId ? (
                    <div onClick={onClick}>
                        <SiteDisplay
                            context={context}
                            config={config}
                            activeCollectionId={activeCollectionId}
                        />
                    </div>
                ) : (
                    <NotFound className="m-5 text-left" />
                )}
            </Loader>
        </ApplicationPageContent>
    );
};
