import { EventUnion } from 'src/models/events/event-union.model';
import { EventListItem } from './EventListItem';
import { EventRequestListItem } from './EventRequestListItem';

interface Props {
    eventUnion: EventUnion;
}

export const EventUnionListItem = ({ eventUnion }: Props): React.ReactElement<any, any> | null => {
    if (eventUnion.event) {
        return <EventListItem key={eventUnion.event.id} event={eventUnion.event} />;
    }

    if (eventUnion.eventRequest) {
        return (
            <EventRequestListItem
                key={eventUnion.eventRequest.id}
                eventRequest={eventUnion.eventRequest}
            />
        );
    }

    return null;
};
