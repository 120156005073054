import { push } from 'connected-react-router';
import { Nav } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useRegisterWizard } from '../../hooks/register-wizard.hook';

export const RegisterWizardTabs = (): React.ReactElement<any, any> | null => {
    const { tabs } = useRegisterWizard();

    const dispatch = useDispatch();
    const onClick = (path: string) => dispatch(push(path));

    return (
        <Nav variant="tabs">
            {tabs
                .filter(i => !i.hidden)
                .map((tab, index) => (
                    <Nav.Item key={index}>
                        <Nav.Link
                            disabled={tab.disabled}
                            className={tab.active ? 'active' : ''}
                            onClick={() => onClick(tab.path)}
                        >
                            <div className="nav-link-before-inner" />
                            <span>
                                {index + 1}
                                <span className="nav-link-wizard-label">. {tab.label}</span>
                            </span>
                            <div className="nav-link-after-inner" />
                        </Nav.Link>
                    </Nav.Item>
                ))}
        </Nav>
    );
};
