import { useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { EventLocationControlContext } from './EventLocationControl';
import { EventLocationOffCampusControl } from './EventLocationOffCampusControl';
import { EventLocationOnCampusControl } from './EventLocationOnCampusControl';
import { EventLocationOnCampusRequestControl } from './EventLocationOnCampusRequestControl';
import { EventLocationOnlineControl } from './EventLocationOnlineControl';
import { EventLocationSelectValue } from './EventLocationOptions';

interface Props {
    selectControlName: string;
}

export const EventLocationInnerControl = ({ selectControlName }: Props) => {
    const { control } = useFormContext();
    const controlName = useContext(EventLocationControlContext);
    const value = useWatch({
        control,
        name: `${controlName}.${selectControlName}`,
    }) as EventLocationSelectValue;

    switch (value) {
        case 'online':
            return <EventLocationOnlineControl />;
        case 'onCampus':
            return <EventLocationOnCampusControl />;

        case 'onCampusRequest':
            return <EventLocationOnCampusRequestControl />;
        case 'offCampus':
            return <EventLocationOffCampusControl />;

        default:
            return null;
    }
};
