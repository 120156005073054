import { LOCATION_CHANGE, replace } from 'connected-react-router';
import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { listEpic } from 'src/logic/helpers/epics/list-route.epic-helper';
import { registerPaths } from 'src/routes/register/register.paths';
import { appStringify } from 'src/routes/router.helper';
import { isActionOf, isOfType } from 'typesafe-actions';
import { RootEpic } from '../../epic.root-index';
import { registerSimilarAsync, registerSimilarListSetActive } from '../register.actions';

// handles the situation when a user hard loads into this page url without setting the
// "Register Name" form.
export const registerSimilarInvalidStateRedirectEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isOfType(LOCATION_CHANGE)),
        filter(action => action.payload.location.pathname === registerPaths.similar),
        filter(() => state$.value.register.name === null),
        mergeMap(() => of(replace(registerPaths.start)))
    );
};

// Redirects the user to the details form when there are no similar results.
export const registerSimilarNoResultsRedirectEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(registerSimilarAsync.success)),
        filter(() => state$.value.router.location.pathname === registerPaths.similar),
        filter(action => action.payload.total === 0),
        mergeMap(() => of(replace(registerPaths.organisation)))
    );
};

// Sigh. This deals with the situation when the user goes past the similar results page,
// then navigates back to the first register page, changes nothing, and continues to the
// "similar" page (which would have been skipped initially (but the results are cached))
export const registerSimilarNoResultsRedirectWhenCachedEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isOfType(LOCATION_CHANGE)),
        filter(action => action.payload.location.pathname === registerPaths.similar),
        filter(() => {
            const similarState = state$.value.register.similar;
            const requestId = similarState.activeList.requestId;
            return similarState.list[requestId]?.ids.length === 0;
        }),
        mergeMap(() => of(replace(registerPaths.organisation)))
    );
};

export const registerSimilarAsyncEpic = asyncEpicBase(
    registerSimilarAsync,
    ({ api }, { payload }) => api.register.similar(payload),
    {
        success: result => registerSimilarAsync.success(result.json),
        failure: (error, ra) => registerSimilarAsync.failure(error, ra.payload),
    }
);

// Note: word of warning, this epic is a bit trickier than it looks, and it looks
// pretty damn tricky already.
// Just remember this is a sort of "hack-list" for similar organisations
// which are used in both the normal registration flow, and the "request-access" registration flow.
// There is an expectation that the 'register.name' state will always exist by this point,
// but also an allowance for query string parameters to override the values in state for the similar-org API call
export const similarListEpic = listEpic({
    overrideRequestId: (_, state) => appStringify(state.register.name || {}),
    path: [registerPaths.similar, registerPaths.requestAccess],
    getParams: (action, state, { queryParams }) => {
        const params = queryParams.similarListParams(
            action.payload.location.search,
            state.register.name!
        );
        return params;
    },
    getListState: state => state.register.similar,
    setActiveActionCreator: registerSimilarListSetActive,
    cancelActionCreator: registerSimilarAsync.cancel,
    requestActionCreator: registerSimilarAsync.request,
    disabledDelayUntilAuthenticated: true,
    extraFilter: (_, state) => !!state.register.name,
});
