import { concat, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { listEpic } from 'src/logic/helpers/epics/list-route.epic-helper';
import { jobPaths } from 'src/routes/employer/paths/job.paths';
import { isActionOf } from 'typesafe-actions';
import { RootEpic } from '../../epic.root-index';
import * as actions from '../job.actions';

export const jobListForceNextReloadEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(
            isActionOf([
                actions.jobCreateAsync.success,
                actions.jobUpdateAsync.success,
                actions.jobDeleteAsync.success,
                actions.jobWithdrawAsync.success,
                actions.jobPublishAsync.success,
            ])
        ),
        mergeMap(() =>
            state$.value.router.location.pathname === jobPaths.list
                ? concat([
                      actions.jobListClear({ excludeActive: false }),
                      actions.jobListSetActive(state$.value.jobs.activeList),
                  ])
                : of(actions.jobListClear({ excludeActive: false }))
        )
    );
};

export const jobListEpic = listEpic({
    path: jobPaths.list,
    getParams: (action, _, { queryParams }) =>
        queryParams.jobListParams(action.payload.location.search),
    getListState: state => state.jobs,
    setActiveActionCreator: actions.jobListSetActive,
    cancelActionCreator: actions.jobListAsync.cancel,
    requestActionCreator: actions.jobListAsync.request,
});

export const jobListAsyncEpic = asyncEpicBase(
    actions.jobListAsync,
    ({ api }, { payload }) => api.jobs.list(payload),
    {
        success: result => actions.jobListAsync.success(result.json),
        failure: (error, requestAction) =>
            actions.jobListAsync.failure(error, requestAction.payload),
    }
);
