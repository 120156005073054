import { Button, Dropdown } from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import { useDispatch } from 'react-redux';
import { impersonateLogoutAsync } from '../../../../../logic/features/authentication/authentication.actions';
import { useAuthentication } from '../../../../features/authentication/hooks/authentication.hook';

export const ImpersonationDropdownMenu = (): React.ReactElement<any, any> | null => {
    const { activeIdentity } = useAuthentication();
    const dispatch = useDispatch();

    const onClick = () => dispatch(impersonateLogoutAsync.request());

    return (
        <DropdownMenu>
            {activeIdentity && (
                <Dropdown.Item className="px-4 py-2">
                    <small>You are impersonating:</small>
                    <div>
                        {activeIdentity.firstName} {activeIdentity.lastName}
                    </div>
                </Dropdown.Item>
            )}
            <div className="px-4 py-2 d-flex">
                <Button
                    size="sm"
                    variant="outline-content-nav"
                    className="mx-auto"
                    onClick={onClick}
                >
                    Return to CareerHub
                </Button>
            </div>
        </DropdownMenu>
    );
};
