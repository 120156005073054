// sets Google analytics if the client has requested it

import { LOCATION_CHANGE } from 'connected-react-router';
import { EMPTY, of } from 'rxjs';
import { filter, ignoreElements, mergeMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import * as actions from './analytics.actions';
import { centralIdentityComplete } from '../authentication/authentication.actions';
import { appInitComplete } from '../initialization/initialization.actions';
import { delayTypeUntil } from '../../helpers/epics/app-init.epic-helper';
import { RootEpic } from '../epic.root-index';

// this could probably be better
const isProduction = process.env.NODE_ENV === 'production';

export const analyticsInitEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(appInitComplete)),
        // only initialize if environment is production and tracking Id exists
        filter(() => isProduction),
        filter(() => !!state$.value.configuration.value.gaTrackingId),
        mergeMap(() => of(actions.analyticsInit()))
    );
};

export const analyticsInitAutoConfirmationEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(actions.analyticsInit)),
        mergeMap(() => {
            // only require confirmation on UK sites
            // so we can just auto trigger confirmation for non-uk sites
            if (state$.value.configuration.value.countryCode !== 'GB') {
                return of(actions.analyticsConfirmation({ confirm: true }));
            }

            const centralUserId = state$.value.authentication.activeIdentity?.centralUserId;
            const sessionValue = services.analytics.getConfirmationFromStorage(centralUserId);

            // if the value doesn't exist in session, do nothing
            if (sessionValue === undefined) {
                return EMPTY;
            }

            // otherwise, set the value automatically
            return of(actions.analyticsConfirmation({ confirm: sessionValue }));
        })
    );
};

// handles logic when user logs in, creates a local version of the user's choice which can then be
// remembered, or if it already exists, it can be set.
export const analyticsHandleUserAuthenticationEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(centralIdentityComplete)),
        mergeMap(action => {
            const centralUserId = Number(action.payload.user.profile.sub);
            const userConfirmedAction = state$.value.analytics.userConfirmAction;

            // if the user selected an action already, just update the storage state
            if (userConfirmedAction !== undefined) {
                services.analytics.storeConfirmation(centralUserId, userConfirmedAction);
                return EMPTY;
            }

            const existing = services.analytics.getConfirmationFromStorage(centralUserId);
            if (existing === undefined) {
                return EMPTY;
            }

            return of(actions.analyticsConfirmation({ confirm: existing }));
        })
    );
};

export const analyticsInitConfirmationEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(actions.analyticsConfirmation)),
        mergeMap(action => {
            const sub = state$.value.authentication.oidcUser?.profile.sub;
            const centralUserId = sub ? Number(sub) : undefined;
            services.analytics.storeConfirmation(centralUserId, action.payload.confirm);

            if (action.payload.confirm) {
                const tracker = services.analytics.configure(state$.value.configuration);
                return of(actions.analyticsComplete({ tracker }));
            }

            return of(actions.analyticsComplete({ tracker: undefined }));
        })
    );
};

export const gaPageViewEpic: RootEpic = (action$, state$, services) => {
    return delayTypeUntil(action$, actions.analyticsComplete, LOCATION_CHANGE).pipe(
        filter(() => !!state$.value.analytics.tracker),
        tap(() =>
            services.analytics.pageView(
                state$.value.analytics.tracker,
                state$.value.router.location.pathname + state$.value.router.location.search
            )
        ),
        ignoreElements()
    );
};
