import { User } from 'oidc-client';
import { OidcEventLog } from 'src/models/authentication/oidc-event.model';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { ApiResponse } from '../../../models/api/response.model';
import { TokenState } from '../../../models/authentication/id-token.model';
import {
    IdentityListResponse,
    IdentitySetUserRequest,
    IdentityType,
} from '../../../models/authentication/identity.model';
import { ErrorNormalized } from '../../../models/errors/error.model';

export const authenticationInit = createAction('AUTHENTICATION:INIT')();
export const authenticationStart = createAction('AUTHENTICATION:START')();
export const authenticationComplete = createAction('AUTHENTICATION:COMPLETE')();

export const centralIdentitySetUser = createAction('CENTRAL_IDENTITY:SET_USER')<{
    user: User | null;
}>();
export const centralIdentityComplete = createAction('CENTRAL_IDENTITY:COMPLETE')<{
    user: User;
}>();
export const centralIdentityFailure = createAction('CENTRAL_IDENTITY:FAILURE')<ErrorNormalized>();
export const centralIdentityRedirect = createAction('CENTRAL_IDENTITY:REDIRECT')();

export const careerHubIdentitySetActive = createAction('CAREERHUB_IDENTITY:SET_ACTIVE')<
    IdentityType | undefined,
    { resetData: boolean }
>();

export const authenticationSetRedirect = createAction('AUTHENTICATION_SET_REDIRECT')<{
    to: string;
}>();
export const authenticationHasRedirected = createAction('AUTHENTICATION_HAS_REDIRECTED')();

export const authenticationLogoutSelected = createAction('AUTHENTICATION:LOGOUT')();

export const authenticationNotAuthorized = createAction(
    'AUTHENTICATION_NOT_AUTHORIZED'
)<ErrorNormalized>();
export const authenticationForbidden = createAction('AUTHENTICATION_FORBIDDEN')<ErrorNormalized>();

export const authenticationExpireSet = createAction('AUTHENTICATION_EXPIRE:SET')<{
    expires: string;
}>();
export const authenticationExpireSoon = createAction('AUTHENTICATION_EXPIRE:SOON')();
export const authenticationExpireSoonDismissed = createAction(
    'AUTHENTICATION_EXPIRE:SOON_DISMISSED'
)();
export const authenticationExpireExpired = createAction('AUTHENTICATION_EXPIRE:EXPIRED')();

export const careerhubIdentityListAsync = createAsyncAction(
    'CAREERHUB_IDENTITY_LIST:REQUEST',
    'CAREERHUB_IDENTITY_LIST:SUCCESS',
    'CAREERHUB_IDENTITY_LIST:FAILURE'
)<undefined, ApiResponse<IdentityListResponse>, ErrorNormalized>();

export const tokensInit = createAction('TOKENS:INIT')<TokenState | undefined>();
export const tokensClear = createAction('TOKENS:CLEAR')();

export const careerhubIdentitySetUserAsync = createAsyncAction(
    'CAREERHUB_IDENTITY_SET_USER:REQUEST',
    'CAREERHUB_IDENTITY_SET_USER:SUCCESS',
    'CAREERHUB_IDENTITY_SET_USER:FAILURE'
)<IdentitySetUserRequest, ApiResponse<IdentityType>, ErrorNormalized>();

export const impersonateStart = createAction('IMPERSONATE:START')();

export const impersonateGetIdentityAsync = createAsyncAction(
    'IMPERSONATE_IDENTITY:REQUEST',
    'IMPERSONATE_IDENTITY:SUCCESS',
    'IMPERSONATE_IDENTITY:FAILURE'
)<undefined, ApiResponse<IdentityType>, ErrorNormalized>();

export const impersonateLogoutAsync = createAsyncAction(
    'IMPERSONATE_LOGOUT:REQUEST',
    'IMPERSONATE_LOGOUT:SUCCESS',
    'IMPERSONATE_LOGOUT:FAILURE'
)<undefined, ApiResponse<undefined>, ErrorNormalized>();

export const oidcEvent = createAction('OIDC_EVENT')<OidcEventLog>();
