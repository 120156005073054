import { CountryCode } from 'src/clients-internal/configuration.model';
import { COUNTRY_REGION_META, RegionCodeAu } from '../../../constants/region.constants';

export const getRegions = (countryCode: string) => {
    const country = COUNTRY_REGION_META[countryCode.toUpperCase() as CountryCode];
    if (!country) {
        return [];
    }

    const { regions } = country;

    if (!regions) {
        return [];
    }

    if (regions instanceof Array) {
        return regions.map(key => ({ key, name: key }));
    }

    return Object.keys(regions).map(key => ({ key, name: regions[key as RegionCodeAu] }));
};
