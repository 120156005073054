import { CSSProperties } from 'react';

export const Shield = (props: { style?: CSSProperties; className?: string }) => (
    <svg
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={props.style}
        className={props.className}
    >
        <path
            // eslint-disable-next-line max-len
            d="M23.002,0.588c0.351,-0 0.791,0.088 1.142,0.264l16.524,7.031c1.933,0.879 3.428,2.724 3.428,5.01c-0.088,8.789 -3.692,24.697 -18.809,31.992c-1.494,0.703 -3.164,0.703 -4.658,-0c-15.117,-7.295 -18.721,-23.203 -18.721,-31.992c-0.088,-2.286 1.406,-4.131 3.34,-5.01l16.523,-7.031c0.352,-0.176 0.791,-0.264 1.231,-0.264Z"
            fill="currentColor"
        />
    </svg>
);
