import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import noop from 'lodash/noop';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Job } from 'src/models/jobs/job.model';
import { jobPathCreator } from 'src/routes/employer/paths/job.paths';
import { ButtonLink } from 'src/ui/shared/components/bootstrap/buttons/ButtonLink';
import { CardWrapper } from 'src/ui/shared/components/bootstrap/cards/CardWrapper';
import { ConfirmationTitle } from 'src/ui/shared/components/confirmation/ConfirmationTitle';
import { Markdown } from 'src/ui/shared/components/details/Markdown';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { PublishToCentralMessage } from './PublishToCentralMessage';

interface Props {
    job: Job;
}

export const PublishConfirmationModal = ({ job }: Props): React.ReactElement<any, any> | null => {
    const {
        job: {
            publishConfirmation: { title, message },
        },
    } = useStrings();

    const jobListPath = jobPathCreator.listDefault();

    return (
        <Modal
            show={true}
            animation={false}
            size="xl"
            dialogClassName="central-publish-modal-dialog"
            contentClassName="modal-content-reset"
            onHide={noop}
        >
            <CardWrapper bodyClassName="d-flex">
                <Icon size="4x" icon={faCheckCircle} iconClassName="text-primary" />
                <div className="ml-3">
                    <div className="d-flex">
                        <ConfirmationTitle title={title} />
                        <ButtonLink
                            variant="link-secondary"
                            className="ml-auto pt-0 pr-lg-0"
                            to={jobListPath}
                        >
                            <Icon icon={faTimes} size="lg" />
                        </ButtonLink>
                    </div>
                    <Link className="mr-2" to={jobPathCreator.details(job.id)}>
                        {job.title}
                    </Link>
                    <div className="mt-3">
                        <Markdown source={message} />
                        <ButtonLink to={jobListPath}>Ok, back to jobs list</ButtonLink>
                    </div>
                </div>
            </CardWrapper>

            <PublishToCentralMessage job={job} />
            <div className="text-center text-white opacity-5 mt-4">
                <ButtonLink icon={faTimes} variant="link" className="text-white" to={jobListPath}>
                    close modal
                </ButtonLink>
            </div>
        </Modal>
    );
};
