import { EntityPageRequest } from 'src/models/api/request.model';
import { ApiSearchResponse } from 'src/models/api/response.model';
import { ErrorNormalized } from 'src/models/errors/error.model';
import { Skill } from 'src/models/skills/skill.model';
import { createAsyncAction } from 'typesafe-actions';

export const skillListAsync = createAsyncAction(
    'SKILL_LIST:REQUEST',
    'SKILL_LIST:SUCCESS',
    'SKILL_LIST:FAILURE',
    'SKILL_LIST:CANCEL'
)<EntityPageRequest, ApiSearchResponse<Skill, EntityPageRequest>, ErrorNormalized, undefined>();
