import { ListGroup } from 'react-bootstrap';
import { EntityNote } from 'src/models/entity-notes/entity-note.model';
import { eventPathCreator } from 'src/routes/employer/paths/event.paths';
import { ListGroupItemHeader } from 'src/ui/shared/components/bootstrap/lists/ListGroupItemHeader';
import { DatePropertyDisplay } from 'src/ui/shared/components/details/DatePropertyDisplay';
import { EntityNoteStatusBadge } from './EntityNoteStatusBadge';

interface Props {
    entityNote: EntityNote;
}

export const EventChangeRequestListItem = ({
    entityNote,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <ListGroup.Item className={entityNote.action !== 'None' ? 'disabled' : undefined}>
            <div className="d-flex align-items-center">
                <ListGroupItemHeader.Title
                    className="text-long-title"
                    to={eventPathCreator.changeRequestEdit(
                        entityNote.attachedToEntityId,
                        entityNote.id
                    )}
                >
                    {entityNote.body}
                </ListGroupItemHeader.Title>
                <EntityNoteStatusBadge entityNote={entityNote} />
                <div className="ml-auto">
                    <DatePropertyDisplay label="Added" date={entityNote.added} />
                    {entityNote.actionDate && (
                        <DatePropertyDisplay label="Actioned" date={entityNote.actionDate} />
                    )}
                </div>
            </div>
        </ListGroup.Item>
    );
};
