import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { eventPathCreator } from 'src/routes/employer/paths/event.paths';
import { ButtonLink } from 'src/ui/shared/components/bootstrap/buttons/ButtonLink';
import { useActiveEntity } from 'src/ui/shared/hooks/active-entity.hook';
import { useCanRequestChanges } from '../../hooks/can-request-changes.hook';
import { EventSubPageTitle } from '../breadcrumbs/EventSubPageTitle';

export const EventChangeRequestListPageNav = (): React.ReactElement<any, any> | null => {
    const { entity: eventUnion } = useActiveEntity(state => state.eventUnions);
    const canRequestChanges = useCanRequestChanges(eventUnion?.event);

    return (
        <div className="d-flex align-items-center">
            <EventSubPageTitle>Change request</EventSubPageTitle>
            {eventUnion && canRequestChanges && (
                <ButtonLink
                    className="ml-2"
                    to={eventPathCreator.changeRequestAdd(eventUnion.id)}
                    icon={faPlus}
                >
                    Request change
                </ButtonLink>
            )}
        </div>
    );
};
