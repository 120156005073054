import { Upper } from 'src/ui/shared/components/text/Upper';
import { PageTitle } from '../../../../shared/components/page-components/PageTitle';
import { useStrings } from '../../../../shared/hooks/strings.hook';
import { JobListBreadcrumb } from '../breadcrumbs/JobListBreadcrumb';

export const JobAddPageNav = (): React.ReactElement<any, any> | null => {
    const {
        job: {
            create: { title },
        },
    } = useStrings();

    return (
        <PageTitle>
            <JobListBreadcrumb />
            <Upper>{title}</Upper>
        </PageTitle>
    );
};
