import { faCalendar, faCalendarCheck, faCalendarTimes } from '@fortawesome/free-regular-svg-icons';
import { useMemo } from 'react';
import { CareerHubEvent } from 'src/models/events/event.model';
import { IconDefinitionAll } from 'src/ui/shared/components/icon/Icon';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';
import { EventStartEndDisplay } from './EventStartEndDisplay';

interface Props {
    event: CareerHubEvent;
}

interface IconVariant {
    icon: IconDefinitionAll;
    className: 'text-success' | 'text-danger' | undefined;
}

export const EventDateDisplay = ({ event }: Props): React.ReactElement<any, any> | null => {
    const {
        value: config,
        includes: { campuses, campusLocations },
    } = useApiConfiguration();

    const iconVariant: IconVariant = useMemo(() => {
        switch (event.status) {
            case 'Active':
                return { icon: faCalendarCheck, className: 'text-success' };
            case 'Cancelled':
            case 'Expired':
                return { icon: faCalendarTimes, className: 'text-danger' };
            default:
                return { icon: faCalendar, className: undefined };
        }
    }, [event.status]);

    // prior to 5.13 this can be undefined
    const timeZoneId = useMemo(() => {
        if (event.timeZoneId) {
            return event.timeZoneId;
        }

        if (!event.onCampusLocationId) {
            return config?.settings?.timeZoneId;
        }

        const campusId = campusLocations?.find(x => x.id === event.onCampusLocationId)?.campusId;
        return campuses?.find(x => x.id === campusId)?.timeZoneId;
    }, [
        campusLocations,
        campuses,
        config?.settings?.timeZoneId,
        event.onCampusLocationId,
        event.timeZoneId,
    ]);

    return (
        <EventStartEndDisplay
            icon={iconVariant.icon}
            className={iconVariant.className}
            start={event.start}
            end={event.end}
            timeZoneId={timeZoneId}
        />
    );
};
