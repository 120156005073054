import { isBefore, isValid as dFnsIsValid, parseISO } from 'date-fns';
import { storageHelper } from '../../../helpers/storage.helper';

const LOCALSTORAGE_IMPERSONATE_EXPIRE = 'cew:auth:impersonate';

const { available } = storageHelper;

export function isValid(): boolean {
    if (!available('localStorage')) {
        throw new Error('Localstorage must be available');
    }
    const dateStr = localStorage.getItem(LOCALSTORAGE_IMPERSONATE_EXPIRE);

    if (!dateStr) {
        return false;
    }

    const expire = parseISO(dateStr);
    if (!dFnsIsValid(expire)) {
        clear();
        return false;
    }

    if (isBefore(expire, new Date())) {
        clear();
        return false;
    }

    return true;
}

export function save(expire: string) {
    localStorage.setItem(LOCALSTORAGE_IMPERSONATE_EXPIRE, expire);
}

export function clear() {
    localStorage.removeItem(LOCALSTORAGE_IMPERSONATE_EXPIRE);
}
