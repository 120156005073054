import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { CardWrapper } from 'src/ui/shared/components/bootstrap/cards/CardWrapper';
import { Markdown } from 'src/ui/shared/components/details/Markdown';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { analyticsConfirmation } from 'src/logic/features/analytics/analytics.actions';

export const AnalyticsConfirmation = (): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    const show = useRootSelector(state => state.analytics.showConfirmation);
    const {
        analytics: {
            confirmation: { message, confirmText, cancelText },
        },
    } = useStrings();

    const onClick = (confirm: boolean) => {
        dispatch(analyticsConfirmation({ confirm }));
    };

    return show ? (
        <div className="analtyics-confirmation z-3">
            <CardWrapper
                className="analytics-inner mx-auto my-3"
                bodyClassName="d-flex align-items-center"
            >
                <Markdown source={message} />
                <div className="flex-shrink-0">
                    <Button variant="link" onClick={() => onClick(false)}>
                        {cancelText}
                    </Button>
                    <Button onClick={() => onClick(true)}>{confirmText}</Button>
                </div>
            </CardWrapper>
        </div>
    ) : null;
};
