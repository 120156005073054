import { User, UserManager, WebStorageStateStore } from 'oidc-client';
import { from, of } from 'rxjs';
import {
    authenticationLogoutSelected,
    oidcEvent,
} from 'src/logic/features/authentication/authentication.actions';
import { ConfigurationState } from 'src/logic/features/configuration/configuration.reducer';
import { storeRegistry } from 'src/logic/store.registry';
import { identityPaths } from 'src/routes/identity/identity.paths';
import { storageHelper } from '../../../helpers/storage.helper';

// clock skew defaults to 5 minutes, which should be okay but
// I am getting errors and reports from clients that user state is not being found
// after lots of digging, I've come to the conclusion that the issues is clock-skew related
// This is a work around, the correct solution is to somehow present to the user that
// there system clock is not set correctly, and they really shouldn't fuck with system time.
// How do you explain something like that to the user?
// I've set it to 10 minutes. This is probably temporary. If I continue to get the error,
// which seems likely, after I've set this, I'll have to try to communicate something to
// the user...
// The above issue also seems to be created when cookies are disabled.
const CLOCK_SKEW_IN_SECONDS = 600;

export let manager: UserManager;
// Oidc.Log.logger = console;

const { available } = storageHelper;

export const preInitStorageAvailableCheck = (): boolean => {
    return available('localStorage') || available('sessionStorage');
};

export const init = (configurationState: ConfigurationState) => {
    // should almost always be localstorage
    const storage = storageHelper.get();

    const identityClientId =
        configurationState.value.identityOverrideClientId || configurationState.clientCode;

    // wiki here: https://github.com/IdentityModel/oidc-client-js/wiki
    manager = new UserManager({
        client_id: identityClientId,
        authority: configurationState.value.identityAuthority,
        redirect_uri: window.location.origin,
        // silent redirects back to the same codebase (the same javascript file), so that the build process is easier.
        // Trust me, this is easier than having to deal with two entry points, typescript, webpack with custom logic, post-build scripts that
        // manually copy files, and dealing with the azure-pipeline into cloudformation logic.
        // Logic on the base index.tsx diverts all traffic for the silent-redirect logic, which we can determine by identifying if the code is
        // being executed on an iframe.
        silent_redirect_uri: window.location.origin,

        // logout gets handled by CareerHub so I'm pretty sure this is ignored.
        post_logout_redirect_uri: window.location.origin,
        response_type: 'token id_token',
        scope: ['openid', 'email', 'profile', 'phone', 'api.write', 'identity.tokens'].join(' '),
        // the user endpoint actually causes the name to be set twice (and turns the string to an array).
        // we don't need this as we get all relevant data in the id_token, it's true by default, so we disable
        loadUserInfo: false,
        // I had this set to false for ages because of the following reason: // "this causes seriously wack behavior so I'm disabling it"
        // The issue was that monitor session uses an IFrame to handle itself, similar to the silent_redirect logic
        // With react, or with my configuration this would cause chrome to error with a VM**** error, indicating that a non-main-window (normally an embedded iframe) was erroring
        // chrome would try to reload the framed code, and it would error like crazy, reload -> error, reload -> error to a max of four times, all while the app looked fine
        // What was happening is that the "monitor session" logic will use the "silent_redirect_url" location for the iframe, and, if you didn't set the 'silent_redirect_url'
        // the default was to use the same url as the 'redirect_url', which is my entire app! So of course it errored.
        // Anyway, fixing the silent redirect flow has fixed this, so we can enable it now.
        // NEW NOTE: everything above is meaningless, this will never work in safari, iOS, brave, and soon chrome.
        // everything needs to be reevaluated.
        monitorSession: false,
        automaticSilentRenew: false,
        clockSkew: CLOCK_SKEW_IN_SECONDS,

        // this defaults to sessionStorage by default, to protect against PII in the state object.
        // sessionStorage clears on tab change, sometimes, dependant on the browser. This can cause the famed "user state not found in storage"
        // the solution: don't store PII in the state object, and use localstorage!
        userStore: new WebStorageStateStore({ store: storage }),
        stateStore: new WebStorageStateStore({ store: storage }),
    });

    // this will intercept the monitor-session signout event and trigger our own
    // logout logic via CareerHub
    const store = storeRegistry.get();
    manager.events.addUserSignedOut(() => {
        store.dispatch(authenticationLogoutSelected());
    });

    // useful for debugging
    const authLog = (eventName: string) => (e: Error | User) => forceAuthLog(eventName, e);
    const authLog2 = (eventName: string) => () => forceAuthLog(eventName);

    manager.events.addAccessTokenExpired(authLog('addAccessTokenExpired'));
    manager.events.addAccessTokenExpiring(authLog('addAccessTokenExpiring'));
    manager.events.addSilentRenewError(authLog('addSilentRenewError'));
    manager.events.addUserLoaded(authLog('addUserLoaded'));
    manager.events.addUserSessionChanged(authLog2('addUserSessionChanged'));
    manager.events.addUserSignedIn(authLog2('addUserSignedIn'));
    manager.events.addUserSignedOut(authLog2('addUserSignedOut'));
    manager.events.addUserUnloaded(authLog2('addUserUnloaded'));
    manager.events.removeAccessTokenExpired(authLog2('removeAccessTokenExpired'));
    manager.events.removeAccessTokenExpiring(authLog2('removeAccessTokenExpiring'));
    manager.events.removeSilentRenewError(authLog2('removeSilentRenewError'));
    manager.events.removeUserLoaded(authLog('removeUserLoaded'));
    manager.events.removeUserSessionChanged(authLog2('removeUserSessionChanged'));
    manager.events.removeUserSignedIn(authLog2('removeUserSignedIn'));
    manager.events.removeUserSignedOut(authLog2('removeUserSignedOut'));
    manager.events.removeUserUnloaded(authLog2('removeUserUnloaded'));
};

// I just need more logging, these auth issues are frustratingly annoying
const forceAuthLog = (eventName: string, arg?: Error | User) => {
    // this is a bit low level, so I'm doing an extra check here.
    const store = storeRegistry.get() as ReturnType<typeof storeRegistry.get> | undefined;

    if (store?.dispatch) {
        store.dispatch(oidcEvent({ eventName, arg }));
    }

    if (store?.getState().configuration.clientCode === 'local') {
        console.log(eventName, arg);
    }
};

// this is the user manager used in all silent callback/ redirect/ etc calls
// all examples in oidc-client create a new object, without all the main configuration
// parameters in the main-frame user manager, and it somehow figures it out and works.
// The only parameter that does need to be set is the clockskew if you want to be consistant
// with the main manager (and for this parameter, you do).
export const createSilentUserManager = () => {
    return new UserManager({ response_mode: 'query', clockSkew: CLOCK_SKEW_IN_SECONDS });
};

export const signinRedirect = (options?: { emailToken?: string }) => {
    const pathSearch = window.location.pathname + window.location.search;

    const args = options?.emailToken
        ? {
              prompt: 'login',
              acr_values: `id_token:${options.emailToken}`,
              state: {
                  redirectUrl: identityPaths.landing,
              },
          }
        : {
              state: {
                  redirectUrl: pathSearch === '/' ? identityPaths.landing : pathSearch,
              },
          };

    return from(manager.signinRedirect(args));
};

export const signinSilent = () => {
    // this shouldnt be necessary/possible but I'm getting a "Error: No silent_redirect_uri configured"
    // when this is called somehow.

    if (!manager) {
        return of(undefined);
    }

    return from(
        manager.signinSilent().catch((err: Error) => {
            // bit iffy logic, but basicially the silent login
            // expects the login (main-frame userManager) to be configured for the session
            // before it starts doing logic itself. However,
            // it's a lot easier to just ask Identity if the session exists
            // by forcing the signinSilent call (but only if no user is found locally)
            // if "login_required" is returned as a message, we know that the user does
            // not exist.
            // Annoyingly there is also a "queryUserStatus", but this will return the same error
            // It makes sense when you think about it, the login-config has to be set before
            // silent signin
            // Remember, the silent sign in does NOT use the config created in the UserManager object above
            // and runs on a seperate IFrame!
            if (err.message === 'login_required') {
                return undefined;
            }

            throw err;
        })
    );
};

export const signoutRedirect = (idToken: string) => {
    return from(
        manager.signoutRedirect({
            state: {
                redirectUrl: '/',
            },
            id_token_hint: idToken,
        })
    );
};

export const signinRedirectCallback = () => {
    return from(manager.signinRedirectCallback());
};

export const getUser = () => {
    return from(manager.getUser());
};

// I've seen errors, like this one: https://errors.careerhub.support/organizations/careerhub/issues/2026729/?project=15&query=is%3Aunresolved
// that indicate that manager is undefined here somehow, needs more investigation
export const removeUser = () => {
    return from(manager.removeUser().then(() => manager.clearStaleState()));
};
