import { PageTitle } from '../../../../shared/components/page-components/PageTitle';
import { OrganisationDetailsBreadcrumb } from '../breadcrumbs/OrganisationDetailsBreadcrumb';

export const OrganisationEditPageNav = (): React.ReactElement<any, any> | null => {
    return (
        <PageTitle>
            <OrganisationDetailsBreadcrumb />
            Edit
        </PageTitle>
    );
};
