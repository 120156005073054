import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { identityPaths } from 'src/routes/identity/identity.paths';
import { homePath } from 'src/routes/layouts/HomeRouteLayout';
import { useLayout } from 'src/routes/RouteList';
import { CareerHubIconContainer } from '../../careerhub/CareerHubIconContainer';

export const ContentApplicationLink = (): React.ReactElement<any, any> | null => {
    const { navLinkVariant, isApplicationLayout } = useLayout();

    return isApplicationLayout ? (
        <Button as={Link} variant={navLinkVariant} to={homePath}>
            <CareerHubIconContainer>Go to homepage</CareerHubIconContainer>
        </Button>
    ) : (
        <Button as={Link} variant={navLinkVariant} to={identityPaths.landing}>
            <CareerHubIconContainer>Go to dashboard</CareerHubIconContainer>
        </Button>
    );
};
