import { EMPTY, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { parseExpected } from 'src/logic/features/shared/query-params.service';
import { isActionOf } from 'typesafe-actions';
import { authenticationSetRedirect } from '../authentication/authentication.actions';
import * as actions from './work-group.actions';
import { RootEpic } from '../epic.root-index';
import { asyncEpicStandard } from '../../helpers/epics/async.epic-helper';

// we can highjack the authentication set redirect epic
// to trigger the set default workgroupId action if it exists
export const workGroupSetDefaultEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(authenticationSetRedirect)),
        // this is a bit hacky
        mergeMap(action => {
            const queryIndex = action.payload.to.indexOf('?');

            if (queryIndex === -1) {
                return EMPTY;
            }

            const search = action.payload.to.substring(queryIndex);

            const { workGroupId } = parseExpected<{ workGroupId: string }>(search);
            const parsed = Number(workGroupId);

            return parsed ? of(actions.workGroupSetDefault({ id: parsed })) : EMPTY;
        })
    );
};

export const workGroupListAsyncEpic = asyncEpicStandard(actions.workGroupListAsync, services =>
    services.api.workGroups.list()
);
