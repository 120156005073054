import { Contact } from '../../../../../models/contacts/contact.model';

interface Props {
    contact: Contact;
}

export const ContactName = ({ contact }: Props): React.ReactElement<any, any> | null => {
    return (
        <>
            {contact.firstName} {contact.lastName}
        </>
    );
};
