import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { registerPaths } from '../../../../../routes/register/register.paths';
import { ButtonLink } from '../../../../shared/components/bootstrap/buttons/ButtonLink';
import { useAuthentication } from '../../../authentication/hooks/authentication.hook';

export const RegisterStart = (): React.ReactElement<any, any> | null => {
    const { hasIndividual } = useAuthentication();
    const disableIndividuals = useRootSelector(
        state => state.apiConfiguration.value?.settings?.disableIndividuals
    );
    const {
        register: { start },
    } = useStrings();

    return (
        <div className="text-center">
            <ButtonLink to={registerPaths.requestAccess} className="btn-block">
                {start.requestAccess}
            </ButtonLink>
            <div className="text-muted mt-3 mb-1">{start.requestAccessDescription}</div>
            <hr />
            <ButtonLink to={registerPaths.name} className="btn-block">
                {start.organisation}
            </ButtonLink>
            {start.organisationDescription && (
                <div className="text-muted my-3">{start.organisationDescription}</div>
            )}

            {!hasIndividual && !disableIndividuals && (
                <>
                    <hr />
                    <ButtonLink to={registerPaths.individual} className="btn-block">
                        {start.individual}
                    </ButtonLink>
                    <div className="text-muted mt-3 mb-1">{start.individualDescription}</div>
                </>
            )}
        </div>
    );
};
