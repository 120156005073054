import { formatDistance, parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ICON_TYPE_META } from 'src/constants/entity.constants';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { UserHistory } from 'src/models/session/user-history.model';
import { sessionShowHistory } from 'src/logic/features/sessions/session.actions';
import { useSessionText } from '../hooks/session.hook';

interface Props {
    item: UserHistory;
}

export const HistoryItem = ({ item }: Props): React.ReactElement<any, any> | null => {
    const dispatch = useDispatch();
    const onClick = () => {
        dispatch(sessionShowHistory({ showHistory: false }));
    };

    const text = useSessionText(item.type, item.action);

    return (
        <Link
            className="list-group-item-action list-group-item d-flex align-items-center"
            to={item.to}
            key={item.id}
            onClick={onClick}
        >
            <Icon icon={ICON_TYPE_META[item.type].icon} iconClassName="mb-1">
                {text}
            </Icon>
            <small className="text-right ml-auto pl-2">
                {formatDistance(parseISO(item.date), new Date())} ago
            </small>
        </Link>
    );
};
