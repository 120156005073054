import { useEffect } from 'react';
import { FieldValues, UseFormSetError } from 'react-hook-form';
import { ErrorNormalized } from '../../../models/errors/error.model';

export const useFormServerValidation = <TFieldValues extends FieldValues>(
    error: ErrorNormalized | undefined,
    setError: UseFormSetError<TFieldValues>
) => {
    useEffect(() => {
        if (!error || !error.validationErrors) {
            return;
        }

        Object.keys(error.validationErrors).forEach(key => {
            const errorProp = error.validationErrors![key];
            if (!errorProp) {
                return;
            }

            const arrayOfErrors = Array.isArray(errorProp) ? errorProp : [errorProp];

            arrayOfErrors.forEach(error => {
                setError(
                    key as any,
                    {
                        type: 'server',
                        message: error,
                    },
                    {
                        shouldFocus: true,
                    }
                );
            });
        });
    }, [error, setError]);
};
