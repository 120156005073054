import { EventUnionPageRequest } from 'src/logic/features/event-unions/event-union.service';
import { appStringify } from '../../router.helper';

export type EventPageBaseParams = {
    eventId?: string;
};

export type EventChangeRequestParams = {
    eventId: string;
    entityNoteId?: string;
};

export const eventPaths = {
    list: '/events',
    add: '/events/add',
    edit: '/events/:eventId(\\d+)/edit',
    publish: '/events/:eventId(\\d+)/publish',
    publishConfirmation: '/events/:eventId(\\d+)/publish-confirmation',
    copy: '/events/:eventId(\\d+)/copy',
    details: '/events/:eventId(\\d+)',
    bookingList: '/events/:eventId(\\d+)/bookings',
    sessionList: '/events/:eventId(\\d+)/sessions',
    sessionCheckIn: '/events/:eventId(\\d+)/sessions/:sessionId(\\d+)/check-in',
    changeRequestList: '/events/:eventId(\\d+)/change-requests',
    changeRequestAdd: '/events/:eventId(\\d+)/change-requests/add',
    changeRequestEdit: '/events/:eventId(\\d+)/change-requests/:entityNoteId(\\d+)/edit',
};

const defaultListParams: EventUnionPageRequest = {
    eventRequestStatus: ['Draft', 'Pending'],
    eventStatus: ['Upcoming', 'Active'],
};

export const eventPathCreator = {
    list: (params?: EventUnionPageRequest) => `/events${appStringify(params)}`,
    listDefault: () => `/events${appStringify(defaultListParams)}`,
    add: () => `/events/add`,
    edit: (eventId: number) => `/events/${eventId}/edit`,
    publish: (eventId: number) => `/events/${eventId}/publish`,
    publishConfirmation: (eventId: number) => `/events/${eventId}/publish-confirmation`,
    copy: (eventId: number) => `/events/${eventId}/copy`,
    details: (eventId: number) => `/events/${eventId}`,
    bookings: (eventId: number) => `/events/${eventId}/bookings`,
    sessionList: (eventId: number) => `/events/${eventId}/sessions`,
    sessionCheckIn: (eventId: number, sessionId: number) =>
        `/events/${eventId}/sessions/${sessionId}/check-in`,
    changeRequestList: (eventId: number) => `/events/${eventId}/change-requests`,
    changeRequestAdd: (eventId: number) => `/events/${eventId}/change-requests/add`,
    changeRequestEdit: (eventId: number, entityNoteId: number) =>
        `/events/${eventId}/change-requests/${entityNoteId}/edit`,
};
