import noop from 'lodash/noop';
import { Modal } from 'react-bootstrap';
import { Loader } from '../../loader/Loader';

interface Props {
    loading?: boolean;
    onHide?: () => any;
    id?: string;
    children: React.ReactNode;
}

export const BaseModal = ({
    children,
    loading,
    onHide,
    id,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <Modal
            id={id}
            size="sm"
            show={true}
            animation={false}
            onHide={() => (onHide ? onHide() : noop)}
        >
            <Loader state={!!loading}>{children}</Loader>
        </Modal>
    );
};
