import qs from 'qs';

export const appStringify = (params: Record<string, any> | undefined): string =>
    params
        ? qs.stringify(params, {
              addQueryPrefix: true,
              arrayFormat: 'comma',
              encode: false,
          })
        : '';
