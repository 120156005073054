import { ErrorNormalized } from 'src/models/errors/error.model';
import { createReducer } from 'typesafe-actions';
import { attachmentDeleteAsync } from './attachment.actions';

export type AttachmentState = Readonly<{
    deleteState: {
        [id: number]:
            | {
                  id: number;
                  deleting: boolean;
                  deleted: boolean;
                  error?: ErrorNormalized;
              }
            | undefined;
    };
}>;

const initialState: AttachmentState = {
    deleteState: {},
};

const attachmentReducer = createReducer<AttachmentState>(initialState)
    .handleAction(attachmentDeleteAsync.request, (state, action) => ({
        ...state,
        deleteState: {
            ...state.deleteState,
            [action.payload.id]: {
                id: action.payload.id,
                deleting: true,
                deleted: false,
            },
        },
    }))
    .handleAction(attachmentDeleteAsync.success, (state, action) => ({
        ...state,
        deleteState: {
            ...state.deleteState,
            [action.payload.data.id]: {
                id: action.payload.data.id,
                deleting: false,
                deleted: true,
            },
        },
    }));

export default attachmentReducer;
