import isEqual from 'lodash/isEqual';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import Select, { ValueType } from 'react-select';
import { useFormError } from 'src/ui/shared/hooks/form-error.hook';
import { formControlIdentifier } from 'src/ui/shared/identifiers/form-control.identifier';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { HookControlProps } from '../../HookForm';
import { ControlFormGroup } from '../../shared/ControlFormGroup';
import { useReactSelect } from './react-select.hooks';
import { ReactSelectOption } from './SingleSelectControl';

export interface MultiSelectControlProps<T> extends HookControlProps {
    options: ReactSelectOption<T>[];
    compareOverride?: (value: T, other: any) => boolean;
    maxItems?: number;
}

export const MultiSelectControl = <T,>(
    props: MultiSelectControlProps<T>
): React.ReactElement<any, any> | null => {
    const {
        name,
        options,
        rules,
        placeholder,
        tabIndex,
        disabled,
        errorName,
        compareOverride,
        errorIndex,
        maxItems,
    } = props;

    const { register, setValue, control, watch } = useFormContext();

    const controlError = useFormError(errorName ?? name, errorIndex);
    const innerRef = control._fields[name];

    useDeepCompareEffect(() => {
        register(name, rules);
    }, [register, name, rules]);

    const { ref, onBlur } = useReactSelect(name, { isMulti: true });

    const onChange = useCallback(
        (value: ValueType<ReactSelectOption<T>, true>) => {
            const extractedValue = value ? value.map(v => v.value) : [];

            setValue(name, extractedValue, {
                shouldValidate: true,
                shouldDirty: true,
            });
        },
        [setValue, name]
    );

    const valueCompare = useMemo(() => {
        const fnc = compareOverride || isEqual;
        return fnc;
    }, [compareOverride]);

    const watchValue = watch(name) as T[] | undefined;
    const getValueOption = useCallback(
        (list: ValueType<ReactSelectOption<T>, true>) => {
            if (!watchValue) {
                return [] as ValueType<ReactSelectOption<T>, true>;
            }
            // have to use isEqual as T could be an object
            // be weary of overly long lists of overly long complex objects
            return (
                list?.filter(c =>
                    watchValue.find(v =>
                        valueCompare ? valueCompare(c.value, v) : isEqual(c.value, v)
                    )
                ) || null
            );
        },
        [valueCompare, watchValue]
    );

    return (
        <ControlFormGroup {...props}>
            <Select
                ref={ref}
                isDisabled={disabled || innerRef?._f.ref.disabled}
                tabIndex={tabIndex ? tabIndex.toString() : null}
                placeholder={placeholder}
                options={options}
                isMulti={true}
                closeMenuOnSelect={false}
                onChange={onChange}
                name={`inner-select-${name}`}
                onBlur={onBlur}
                classNamePrefix="react-select"
                className={`react-select-container ${formControlIdentifier.reactSelect(name)} ${
                    controlError ? 'is-invalid' : ''
                }`}
                value={getValueOption(options)}
                isOptionDisabled={
                    maxItems && watchValue ? () => watchValue.length >= maxItems : undefined
                }
            />
        </ControlFormGroup>
    );
};
