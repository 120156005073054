import values from 'lodash/values';
import { useState, useMemo } from 'react';
import { Organisation } from 'src/models/organisation/organisation.model';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useIsCentralOrganisationOrAlreadyPublished } from './central-already-published.hook';

export const useCentralSimilarOrganisations = (localOrganisation: Organisation | undefined) => {
    const [hasIgnored, setHasIgnored] = useState(false);

    const items = useRootSelector(state => state.central.similar.items);
    const step = useRootSelector(state => state.central.currentStep.step);
    const similarOrgs = useMemo(
        () =>
            values(items)
                .filter(i => !!i)
                .map(i => i!),
        [items]
    );
    const hasSimilarOrganisations = useMemo(() => similarOrgs.length > 0, [similarOrgs]);

    const alreadyPublished = useIsCentralOrganisationOrAlreadyPublished();

    const show = useMemo(() => {
        return !alreadyPublished && hasSimilarOrganisations && step === 'init' && !hasIgnored;
    }, [alreadyPublished, hasIgnored, hasSimilarOrganisations, step]);

    const hasExactSimilarId = useMemo(() => {
        if (!localOrganisation) {
            return undefined;
        }

        return similarOrgs.find(
            similarOrg =>
                similarOrg.name === localOrganisation.name &&
                (similarOrg.division === localOrganisation.division ||
                    (!similarOrg.division && !localOrganisation.division)) &&
                similarOrg.countryCode === localOrganisation.countryCode
        )?.id;
    }, [localOrganisation, similarOrgs]);

    return {
        items: similarOrgs,
        show,
        hasIgnored,
        setHasIgnored,
        hasExactSimilarId,
    };
};
