import pick from 'lodash/pick';
import { createReducer } from 'typesafe-actions';
import {
    ApiConfigurationData,
    apiConfigurationIncludeKeys,
    ApiConfigurationIncludes,
    ApiConfigurationResponse,
    ApiConfigurationV2Response,
} from '../../../models/api-configuration/api-configuration.model';
import { LoaderState } from '../../../models/errors/error.model';
import { apiConfigurationAsync } from './api-configuration.actions';

export interface CareerHubVersion {
    major: number;
    minor: number;
    patch: number;
}

export type ApiConfigurationState = Readonly<{
    fetch: LoaderState;
    value?: Pick<ApiConfigurationData, 'apiIncrement' | 'events' | 'settings'>;

    include: ApiConfigurationIncludes;

    version: CareerHubVersion;
}>;

const initialState: ApiConfigurationState = {
    fetch: { loading: false },
    include: {},
    version: {
        major: 0,
        minor: 0,
        patch: 0,
    },
};

// This is not "True" semantic versioning, see: https://semver.org/
// I don't really care about edge cases (or like 1.0.10-alpha0), and I'm going to have to make assumptions using the
// environment anyway. So if I can't parse a number, I'm defaulting to -1 (which will fail a greater-than check)
const parseVersion = (version: string): CareerHubVersion => {
    const versionArray = version.split('.');
    const major = (versionArray[0] && Number(versionArray[0])) || -1;
    const minor = (versionArray[1] && Number(versionArray[1])) || -1;
    const patch = (versionArray[2] && Number(versionArray[2])) || -1;

    return {
        major,
        minor,
        patch,
    };
};

const isV2 = (response: ApiConfigurationResponse): response is ApiConfigurationV2Response => {
    return !!(response as ApiConfigurationV2Response).include;
};

const optionCollectionReducer = createReducer(initialState)
    .handleAction(apiConfigurationAsync.request, state => ({ ...state, fetch: { loading: true } }))
    .handleAction(apiConfigurationAsync.success, (state, action) => ({
        fetch: { loading: false },
        value: pick(action.payload.data, ['apiIncrement', 'events', 'settings']),
        version: parseVersion(action.payload.data.version),
        include: isV2(action.payload)
            ? action.payload.include
            : pick(action.payload.data, apiConfigurationIncludeKeys),
    }))
    .handleAction(apiConfigurationAsync.failure, (state, action) => ({
        ...state,
        fetch: { loading: false, error: action.payload },
    }));

export default optionCollectionReducer;
