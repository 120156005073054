import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useActiveEntity } from '../../../shared/hooks/active-entity.hook';

export const useActiveJob = () => {
    const { entity, fetch, id, update, deleted } = useActiveEntity(state => state.jobs);

    const changeRequestState = useRootSelector(
        state =>
            (state.jobs.activeId && state.jobs.changeRequests[state.jobs.activeId]?.create) ||
            undefined
    );

    return {
        job: entity,
        fetch,
        id,
        changeRequestState,
        update,
        deleted,
    };
};
