import { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useFormError } from 'src/ui/shared/hooks/form-error.hook';
import { ZodArray, ZodEffects, ZodObject, ZodString } from 'zod';
import { HookControlProps, useCustomFormContext } from '../HookForm';
import ControlHelpText from './ControlHelpText';
import ControlValidationDisplay from './ControlValidationDisplay';
import { HookFormPopover } from './HookFormPopover';
import { MaxLengthDisplay } from './MaxLengthDisplay';

interface Props extends HookControlProps {
    children?: React.ReactNode;
}

export const ControlFormGroup = (props: Props): React.ReactElement<any, any> | null => {
    const { label, children, helpText, name, errorName, errorIndex, rules, removeLabel, popover } =
        props;

    const controlError = useFormError(errorName || name, errorIndex);
    const { zod: zodShape } = useCustomFormContext();

    const hasRequiredValidationRule = useMemo(() => {
        if (
            rules &&
            (rules.required ||
                (rules.validate &&
                    typeof rules.validate !== 'function' &&
                    rules.validate['required']))
        ) {
            return true;
        }

        if (!zodShape) {
            return false;
        }

        let shape: any | undefined = undefined;
        if (zodShape instanceof ZodEffects) {
            shape = zodShape._def.schema._def.shape;
        } else if (zodShape instanceof ZodObject) {
            shape = zodShape.shape;
        }
        if (typeof shape === 'function') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            shape = shape();
        }

        if (shape) {
            const zodDef = shape[name];

            if (zodDef instanceof ZodString && zodDef.minLength !== null && zodDef.minLength > 0) {
                return true;
            }

            if (zodDef instanceof ZodArray && zodDef._def.minLength?.value === 1) {
                return true;
            }

            if (zodDef instanceof ZodObject && !zodDef.isOptional()) {
                return true;
            }
        }

        return false;
    }, [name, rules, zodShape]);

    return (
        <Form.Group>
            <div className="d-flex align-items-center mb-1">
                {label && !removeLabel && (
                    <Form.Label className="mb-0">
                        {label} {hasRequiredValidationRule ? '*' : ''}
                    </Form.Label>
                )}
                <div className="ml-auto">
                    <MaxLengthDisplay name={name} errorName={errorName} rules={rules} />
                    {popover && <HookFormPopover name={name} popover={popover} label={label} />}
                </div>
            </div>
            <div
                className={`d-flex flex-column flex-column-reverse ${
                    controlError ? 'is-invalid' : ''
                }`}
            >
                <ControlHelpText helpText={helpText} error={controlError} />
                <div className={`${controlError ? 'is-invalid' : ''}`}>{children}</div>
            </div>
            <ControlValidationDisplay error={controlError} />
        </Form.Group>
    );
};
