import { Link } from 'react-router-dom';
import { organisationPaths } from 'src/routes/employer/paths/organisation.paths';
import { OrganisationLogoHeaderDisplay } from 'src/ui/features/organisation/components/details/OrganisationLogoHeaderDisplay';
import { Job } from '../../../../../models/jobs/job.model';
import { useOrganisation } from '../../../organisation/hooks/organisation.hook';

interface Props {
    job: Job;
}

export const JobDetailsHeader = ({ job }: Props): React.ReactElement<any, any> | null => {
    const { organisation } = useOrganisation();
    const { details } = organisationPaths;

    return (
        <OrganisationLogoHeaderDisplay>
            <h2 className="font-weight-normal">{job.title}</h2>
            {organisation && (
                <h4 className="font-weight-normal">
                    <Link to={details}>{organisation?.name}</Link>
                </h4>
            )}
            <div>{job.summary}</div>
        </OrganisationLogoHeaderDisplay>
    );
};
