import { getClientConfig } from 'src/clients-internal/configuration.init';
import { EntityPageRequest, EntityRequest } from '../../../models/api/request.model';
import {
    ApiListResponse,
    ApiSearchResponse,
    ApiResponse,
} from '../../../models/api/response.model';
import { ObsApiExpected } from '../../../models/api/service.model';
import { Contact } from '../../../models/contacts/contact.model';
import { apiStringify } from '../../helpers/api-query-string.helper';
import { fetchHelper } from '../../helpers/fetch.helper';

const basePath = () => `${getClientConfig().careerHubBasePath}/services/employers/v1/contacts`;

export interface ContactPageRequest extends EntityPageRequest {
    isActive?: boolean;
}

export function all(): ObsApiExpected<ApiListResponse<Contact>> {
    return fetchHelper.get(`${basePath()}`);
}

export function list(
    request: ContactPageRequest
): ObsApiExpected<ApiSearchResponse<Contact, ContactPageRequest>> {
    return fetchHelper.get(`${basePath()}${apiStringify(request)}`);
}

export function single(request: EntityRequest): ObsApiExpected<ApiResponse<Contact>> {
    return fetchHelper.get(`${basePath()}/${request.id}`);
}

export function create(contact: Contact): ObsApiExpected<ApiResponse<Contact>> {
    return fetchHelper.post(basePath(), contact);
}

export function update(contact: Contact): ObsApiExpected<ApiResponse<Contact>> {
    return fetchHelper.put(`${basePath()}/${contact.id}`, contact);
}

export function deleteContact(request: EntityRequest): ObsApiExpected<ApiResponse<Contact>> {
    return fetchHelper.del(`${basePath()}/${request.id}`);
}

export function setPrimary(contact: Contact): ObsApiExpected<ApiResponse<Contact>> {
    return fetchHelper.put(`${basePath()}/${contact.id}`, { ...contact, primary: true });
}
