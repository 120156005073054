import { Button, InputGroup } from "react-bootstrap";
import { ReactNode } from "react";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Icon } from "src/ui/shared/components/icon/Icon";

export function LocationInputGroup({ onRemove, children, className, variant }: {
    onRemove: () => void
    children: ReactNode
    className?: string
    variant?: string
}) {
    return (
        <InputGroup className={className}>
            <div className={"form-control pt-2 bg-" + (variant ?? "white")} style={{ height: 'auto' }}>
                {children}
            </div>
            <InputGroup.Append>
                <Button
                    variant="light"
                    className="border-input"
                    onClick={onRemove}>
                    <Icon icon={faTrash} />
                </Button>
            </InputGroup.Append>
        </InputGroup>
    );
}
