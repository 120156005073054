import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { IdentitierType } from 'src/ui/shared/helpers/identifier.helper';
import { LinkWrapper } from '../../router/LinkWrapper';

interface Props extends IdentitierType {
    to: string;
    icon?: IconDefinition;
    iconSize?: SizeProp;
    iconClassName?: string;
    className?: string;
    children?: React.ReactNode;
}

export const DropdownLink = (props: Props): React.ReactElement<any, any> | null => {
    return <LinkWrapper {...props} as="dropdown" />;
};
