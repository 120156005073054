import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIsIndividual } from 'src/ui/features/authentication/hooks/is-individual.hook';
import { useIsFirstRender } from 'src/ui/shared/hooks/render.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { listItemSelector } from 'src/ui/shared/selectors/entity-list.selector';
import { contactAllAsync } from 'src/logic/features/contacts/contact.actions';

export const useAllContacts = () => {
    const dispatch = useDispatch();
    const isFirstRender = useIsFirstRender();
    const isIndividual = useIsIndividual();
    const { fetch, fetched } = useRootSelector(state => state.contacts.all);

    useEffect(() => {
        if (isFirstRender && !isIndividual && !fetch.loading && !fetch.error && !fetched) {
            dispatch(contactAllAsync.request());
        }
    }, [fetch, dispatch, isFirstRender, fetched, isIndividual]);

    const contacts = useRootSelector(state =>
        listItemSelector(state.contacts.items, state.contacts.all.ids)
    );

    return {
        fetch,
        contacts,
    };
};
