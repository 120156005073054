import { push } from 'connected-react-router';
import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { individualPaths } from '../../../routes/employer/paths/individual.paths';
import { individualAsync, individualUpdateAsync } from './individual.actions';
import { RootEpic } from '../epic.root-index';
import { asyncEpicStandard } from '../../helpers/epics/async.epic-helper';

export const individualEpic = asyncEpicStandard(individualAsync, ({ api }) => api.individual.get());

export const individualUpdateEpic = asyncEpicStandard(
    individualUpdateAsync,
    ({ api }, { payload }) => api.individual.update(payload)
);

export const individualUpdateRedirect: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(individualUpdateAsync.success)),
        mergeMap(() => of(push(individualPaths.details)))
    );
};
