import { useMemo } from 'react';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';
import { useIsCareerHubVersion } from 'src/ui/shared/hooks/is-careerhub-version.hook';
import { Loader } from '../../../loader/Loader';
import { HookSelectControlProps } from '../selects/SelectControl';
import { ReactSelectOption, SingleSelectControl } from './SingleSelectControl';

type Props = Omit<HookSelectControlProps, 'options'>;

export const TimeZoneSelectControl = (props: Props): React.ReactElement<any, any> | null => {
    const v5_13 = useIsCareerHubVersion(5, 13);

    const {
        fetch,
        includes: { timeZones },
    } = useApiConfiguration();

    const options: ReactSelectOption<string>[] = useMemo(() => {
        if (!timeZones) {
            return [];
        }

        return timeZones.map(tz => ({
            label: tz.name,
            value: tz.id,
            tokens: tz.name.toLowerCase().split(' '),
        }));
    }, [timeZones]);

    const compareOverride = (value: string, other: any): boolean => {
        if (typeof other !== 'string') {
            return false;
        }

        return value.toLowerCase() === other.toLowerCase();
    };

    if (!v5_13) {
        return null;
    }

    return (
        <Loader useCard={true} state={fetch}>
            <SingleSelectControl {...props} options={options} compareOverride={compareOverride} />
        </Loader>
    );
};
