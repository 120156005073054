import { useMemo } from 'react';
import { useClient } from 'src/ui/shared/hooks/client.hook';
import { useIsCareerHubVersion } from 'src/ui/shared/hooks/is-careerhub-version.hook';

export const useBannerImageUrl = (key: string | undefined, type: 'EventRequest' | 'Event') => {
    const { careerHubBasePath } = useClient();
    const v5_13 = useIsCareerHubVersion(5, 13);

    return useMemo(() => {
        if (!key) {
            return undefined;
        }

        // there was a change in the API, and the route was changed by mistake (i.e is a bug)
        // so now I need to cater for this here...
        const urlBugFixHack = v5_13 ? 'images/banner' : 'banners';

        return `${careerHubBasePath}/services/employers/v1/${urlBugFixHack}?type=${encodeURIComponent(
            type
        )}&key=${encodeURIComponent(key)}`;
    }, [careerHubBasePath, key, type, v5_13]);
};
