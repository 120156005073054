import ReactMarkdown, { NodeType } from 'react-markdown';
import { useStringsArray } from '../../hooks/strings.hook';
import { A } from '../anchors/A';

const allowedTypes: NodeType[] = [
    'list',
    'listItem',
    'text',
    'paragraph',
    'strong',
    'link',
    'heading',
];

interface Props {
    source?: string | string[];
}

export const Markdown = ({ source }: Props): React.ReactElement<any, any> | null => {
    const value = useStringsArray(source);

    if (!value) {
        return null;
    }

    return (
        <ReactMarkdown
            allowedTypes={allowedTypes}
            renderers={{
                link: props => (
                    <A className="markdown-anchor" href={props.href}>
                        {props.children}
                    </A>
                ),
            }}
        >
            {value}
        </ReactMarkdown>
    );
};
