import noop from 'lodash/noop';
import { CombinedState } from 'redux';
import { StateObservable } from 'redux-observable';
import { concat, Observable, of } from 'rxjs';
import {
    catchError,
    filter,
    mergeMap,
    switchMap,
    takeUntil,
    tap,
    throttleTime,
} from 'rxjs/operators';
import { Services } from 'src/logic/features/service.root-index';
import { Action, ActionCreator, isActionOf, PayloadActionCreator } from 'typesafe-actions';
import { RequiredKeys } from 'utility-types';
import { ObsApiExpected, UnwrappedJsonResponse } from '../../../models/api/service.model';
import { ErrorNormalized } from '../../../models/errors/error.model';
import { COOKIE_EXPIRATION_HEADER } from '../../../logic/helpers/fetch.helper';
import {
    authenticationExpireSet,
    authenticationForbidden,
    authenticationNotAuthorized,
} from '../../../logic/features/authentication/authentication.actions';
import { RootAction } from 'src/logic/features/action.root-index';
import { RootEpic } from 'src/logic/features/epic.root-index';
import { RootState } from 'src/logic/features/reducer.root-index';

interface AsyncEpicOptions<
    TRequest extends ActionCreator = ActionCreator,
    TCancel extends ActionCreator = ActionCreator
> {
    cancelFilter?: (
        cancelAction: ReturnType<TCancel>,
        requestAction: ReturnType<TRequest>
    ) => boolean;
    disableAutomaticAuthenticationActionCreation?: boolean;
}

type ApiInvoke<TRequestAction, TApiResult> = (
    services: Services,
    action: TRequestAction,
    state$: StateObservable<RootState>
) => ObsApiExpected<TApiResult>;

export function asyncEpicStandard<
    TApiResult,
    TRequest extends ActionCreator,
    TCancel extends ActionCreator
>(
    asyncActions: {
        request: TRequest;
        success: PayloadActionCreator<any, TApiResult>;
        failure: PayloadActionCreator<any, ErrorNormalized>;
        cancel?: TCancel;
    },
    apiCall: ApiInvoke<ReturnType<TRequest>, TApiResult>
): RootEpic {
    return asyncEpicBase(asyncActions, apiCall, {
        success: result => asyncActions.success(result.json),
        failure: error => asyncActions.failure(error),
    });
}

export function asyncEpicBase<
    TApiResult,
    TRequest extends ActionCreator,
    TCancel extends ActionCreator,
    TSuccess extends ActionCreator,
    TFailure extends ActionCreator
>(
    asyncActions: {
        request: TRequest;
        success: TSuccess;
        failure: TFailure;
        cancel?: TCancel;
    },
    apiCall: ApiInvoke<ReturnType<TRequest>, TApiResult>,
    invokes: {
        success: (
            result: UnwrappedJsonResponse<TApiResult>,
            requestAction: ReturnType<TRequest>
        ) => RootAction & ReturnType<TSuccess>;
        failure: (
            error: ErrorNormalized,
            requestAction: ReturnType<TRequest>
        ) => RootAction & ReturnType<TFailure>;
    },
    options?: AsyncEpicOptions<TRequest, TCancel>
): RootEpic {
    const { request, cancel } = asyncActions;
    const cancelFilter = options?.cancelFilter;
    const { success, failure } = invokes;

    return (action$, state$, services) => {
        const cancelObs = cancel && action$.pipe(filter(isActionOf(cancel)));

        return action$.pipe(
            filter(isActionOf(request)),
            throttleTime(1),
            mergeMap(action =>
                apiCall(services, action, state$).pipe(
                    handleResponse(state$)(result => success(result, action)),
                    handleErrors(options)(error => failure(error, action)),
                    handleCancel(cancelObs, cancelFilter, action)
                )
            )
        );
    };
}

//
const handleCancel =
    <T, TCancel, TRequest>(
        cancelObs: Observable<TCancel> | undefined,
        cancelFilter: ((cancelAction: TCancel, requestAction: TRequest) => boolean) | undefined,
        action: TRequest
    ) =>
    (source: Observable<T>) => {
        return source.pipe(
            cancelObs
                ? takeUntil(
                      cancelObs.pipe(
                          cancelFilter ? filter(ca => cancelFilter(ca, action)) : tap(noop)
                      )
                  )
                : tap(noop)
        );
    };

// This essentially mimics the "map" function
// but it looks at the response, and builds other actions if
// necessary from the results.
const handleResponse =
    (state$: StateObservable<CombinedState<RootState>>) =>
    <T>(getSuccessAction: (result: UnwrappedJsonResponse<T>) => Action) =>
    (source: Observable<UnwrappedJsonResponse<T>>) => {
        return source.pipe(
            switchMap(result => {
                const successAction = getSuccessAction(result);

                // fire the expire action if there is no value set in state, or it's different
                let expireAction: ReturnType<typeof authenticationExpireSet> | undefined =
                    undefined;
                const expiresCookieValue = result.response.headers.get(COOKIE_EXPIRATION_HEADER);
                const stateExpireValue = state$.value.authentication.expires;
                if (expiresCookieValue && expiresCookieValue !== stateExpireValue) {
                    expireAction = authenticationExpireSet({ expires: expiresCookieValue });
                }

                return expireAction ? concat([successAction, expireAction]) : of(successAction);
            })
        );
    };

// This essentially mimics the catchError function
const handleErrors =
    (options?: AsyncEpicOptions) =>
    <T>(getFailureAction: (error: ErrorNormalized) => Action) =>
    (source: Observable<T>) => {
        return source.pipe(
            // error should always be a error normalized at this point
            // but it is possible that the "success" call errors
            catchError((error: ErrorNormalized | Error) => {
                if (!isNormalizedError(error)) {
                    // should never get hit.
                    throw error;
                }

                let additionalAction:
                    | ReturnType<typeof authenticationNotAuthorized>
                    // something about 403 is off, 403 should just error, but for some reason
                    // I want it to redirect in some cases... but not all.
                    | ReturnType<typeof authenticationForbidden>
                    | undefined = undefined;

                const createAuthAction = !options?.disableAutomaticAuthenticationActionCreation;

                if (createAuthAction && error.status === 401) {
                    additionalAction = authenticationNotAuthorized(error);
                }

                if (createAuthAction && error.status === 403) {
                    additionalAction = authenticationForbidden(error);
                }

                const failureAction = getFailureAction(error);

                return additionalAction
                    ? concat([failureAction, additionalAction])
                    : of(failureAction);
            })
        );
    };

function isNormalizedError(error: any): error is ErrorNormalized {
    if (typeof error !== 'object') {
        return false;
    }

    const schemaToCheck: Record<RequiredKeys<ErrorNormalized>, string> = {
        message: 'string',
        status: 'number',
        statusText: 'string',
    };

    const hasRequiredKeys =
        Object.keys(schemaToCheck).filter(key => error[key] === undefined).length === 0;

    return hasRequiredKeys;
}
