import { CardWrapper } from '../../../shared/components/bootstrap/cards/CardWrapper';
import { Loader } from '../../../shared/components/loader/Loader';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';
import { OrganisationDetails } from '../components/details/OrganisationDetails';
import { useOrganisation } from '../hooks/organisation.hook';

export const OrganisationDetailsPage = (): React.ReactElement<any, any> | null => {
    const { organisation, fetch } = useOrganisation();

    return (
        <ApplicationPageContent className="details-page">
            <Loader state={fetch} useCard={true}>
                <CardWrapper>
                    {organisation && <OrganisationDetails organisation={organisation} />}
                </CardWrapper>
            </Loader>
        </ApplicationPageContent>
    );
};
