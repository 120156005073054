import { ContactPageRequest } from 'src/logic/features/contacts/contact.service';
import { appStringify } from 'src/routes/router.helper';

export type ContactPageBaseParams = {
    contactId?: string;
};

export const contactPaths = {
    edit: '/contacts/:contactId(\\d+)/edit',
    details: '/contacts/:contactId(\\d+)',
    add: '/contacts/add',
    list: '/contacts',
};

export const contactPathCreator = {
    details: (contactId: number) => `/contacts/${contactId}`,
    edit: (contactId: number) => `/contacts/${contactId}/edit`,
    list: (params?: ContactPageRequest) => `/contacts${appStringify(params)}`,
};
