import { faCalendarTimes, faPlus, faRedo } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState } from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { eventCancelAsync } from 'src/logic/features/event-unions/event-union.actions';
import { EventStatus } from 'src/models/events/event-status.model';
import { CareerHubEvent } from 'src/models/events/event.model';
import { eventPathCreator } from 'src/routes/employer/paths/event.paths';
import {
    ActionDropdown,
    ActionDropdownSize,
} from 'src/ui/shared/components/bootstrap/dropdown/ActionDropdown';
import { DropdownLink } from 'src/ui/shared/components/bootstrap/dropdown/DropdownLink';
import { DeleteModal } from 'src/ui/shared/components/bootstrap/modals/DeleteModal';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { useCanRequestChanges } from '../../hooks/can-request-changes.hook';
import { useEventProviders } from '../../hooks/event-providers.hook';

interface CancelDropdownItemProps {
    onClick: () => void;
    canCancelEvent: boolean;
}

const CancelDropdownItem = ({
    onClick,
    canCancelEvent,
}: CancelDropdownItemProps): React.ReactElement<any, any> | null => (
    <>
        <Dropdown.Item className="text-danger" onClick={onClick} disabled={!canCancelEvent}>
            <Icon icon={faCalendarTimes} iconClassName="fa-fw">
                Cancel
            </Icon>
        </Dropdown.Item>
    </>
);

interface WrappedCancelDropdownItemProps extends CancelDropdownItemProps {
    status: EventStatus;
    disabledCancelDescription: string | undefined;
}

const WrappedCancelDropdownItem = ({
    status,
    onClick,
    canCancelEvent,
    disabledCancelDescription,
}: WrappedCancelDropdownItemProps): React.ReactElement<any, any> | null => {
    if (status === 'Cancelled' || status === 'None') {
        return null;
    }

    if (canCancelEvent) {
        return <CancelDropdownItem onClick={onClick} canCancelEvent={canCancelEvent} />;
    }

    return (
        <>
            <Dropdown.Divider />
            <OverlayTrigger
                overlay={<Tooltip id="tooltip-cancel">{disabledCancelDescription}</Tooltip>}
            >
                {/* div must exist for tooltip to work on disabled button */}
                <div>
                    <CancelDropdownItem onClick={onClick} canCancelEvent={canCancelEvent} />
                </div>
            </OverlayTrigger>
        </>
    );
};

interface Props {
    event: CareerHubEvent;
    size: ActionDropdownSize;
}

export const EventActionDropdown = ({
    event,
    size,
}: Props): React.ReactElement<any, any> | null => {
    const [showConfirm, setShowConfirm] = useState(false);
    const dispatch = useDispatch();

    const cancelling = useRootSelector(
        state => !!state.eventUnions.single[event.id]?.update?.loading
    );

    const { isOnlyProvider } = useEventProviders(event.id);
    const {
        event: {
            deleteModal,
            cancel: { notOnlyProviderDescription, notUpcomingCancelDescription },
        },
    } = useStrings();

    const canCancelEvent = useMemo(() => {
        if (event.status !== 'Upcoming' || !isOnlyProvider) {
            return false;
        }

        return true;
    }, [event.status, isOnlyProvider]);

    const canRequestChanges = useCanRequestChanges(event);

    const disabledCancelDescription = useMemo(() => {
        if (event.status !== 'Upcoming') {
            return notUpcomingCancelDescription;
        }

        if (!isOnlyProvider) {
            return notOnlyProviderDescription;
        }

        return undefined;
    }, [event.status, isOnlyProvider, notOnlyProviderDescription, notUpcomingCancelDescription]);

    const onTriggerCancel = () => {
        dispatch(eventCancelAsync.request({ id: event.id }));
    };

    const onConfirmCancel = () => {
        setShowConfirm(false);
    };

    return (
        <>
            <ActionDropdown size={size}>
                <Dropdown.Menu>
                    {canRequestChanges && (
                        <DropdownLink
                            to={eventPathCreator.changeRequestAdd(event.id)}
                            icon={faPlus}
                            iconClassName="fa-fw"
                        >
                            Change request
                        </DropdownLink>
                    )}

                    <DropdownLink
                        to={eventPathCreator.copy(event.id)}
                        icon={faRedo}
                        iconClassName="fa-fw"
                    >
                        Duplicate
                    </DropdownLink>
                    <WrappedCancelDropdownItem
                        canCancelEvent={canCancelEvent}
                        disabledCancelDescription={disabledCancelDescription}
                        onClick={() => setShowConfirm(true)}
                        status={event.status}
                    />
                </Dropdown.Menu>
            </ActionDropdown>
            {showConfirm && (
                <DeleteModal
                    loading={cancelling}
                    onCancel={onConfirmCancel}
                    onConfirm={onTriggerCancel}
                    body={deleteModal}
                />
            )}
        </>
    );
};
