import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import omit from 'lodash/omit';
import { useState } from 'react';
import { Alert, AlertProps } from 'react-bootstrap';

// NOTE to anyone confused about "dismiss", "dissmissable", "onClose" options
// react Bootstrap has it's own dismiss properties, but the styles don't play nice with chubby
// So I am hacking my own version in.

interface Props extends Exclude<AlertProps, 'onClose'> {
    icon?: IconDefinition;
    title?: string;
    onDismiss?: () => void;
    iconSize?: SizeProp;
    className?: string;
    children: React.ReactNode;
}

export const AlertWrapper = (props: Props): React.ReactElement<any, any> | null => {
    const { title, icon, className, dismissible, children, iconSize, onDismiss } = props;
    const omitted = omit(props, 'dismissible', 'extraActions', 'title', 'onDismiss');

    const [show, setShow] = useState(true);

    const onInnerDismiss = () => {
        setShow(false);

        if (onDismiss) {
            onDismiss();
        }
    };

    return (
        <Alert show={show} {...omitted} className={className}>
            {icon && (
                <div className="flex-shrink-1">
                    <FontAwesomeIcon icon={icon} size={iconSize || '3x'} className="fa-w-18" />
                </div>
            )}

            <div className="flex-grow-1 alert-body">
                {title && <div className="mr-2 font-weight-bold">{title}</div>}
                {children}
            </div>
            {dismissible && (
                <div className="flex-shrink-1">
                    <button
                        type="button"
                        className="close alert-link"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={onInnerDismiss}
                    >
                        <span aria-hidden="true">
                            <FontAwesomeIcon icon={faTimes} />
                        </span>
                    </button>
                </div>
            )}
        </Alert>
    );
};
