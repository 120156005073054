import { PageTitle } from '../../../../shared/components/page-components/PageTitle';
import { useActiveContact } from '../../hooks/active-contact.hook';
import { ContactListBreadcrumb } from '../breadcrumbs/ContactListBreadcrumb';
import { ContactName } from '../shared/ContactName';

export const ContactEditPageNav = (): React.ReactElement<any, any> | null => {
    const { contact } = useActiveContact();

    return (
        <PageTitle>
            <ContactListBreadcrumb />
            {/* {contact && <ContactDetailsBreadcrumb contact={contact} />} */}
            <span>Edit&nbsp;</span>
            {contact && <ContactName contact={contact} />}
        </PageTitle>
    );
};
