import { isValid, parseISO } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import ReactDatePicker, {
    ReactDatePickerProps,
    registerLocale,
    setDefaultLocale,
} from 'react-datepicker';
import { useFormContext } from 'react-hook-form';
import { useClient } from 'src/ui/shared/hooks/client.hook';
import { useFormError } from 'src/ui/shared/hooks/form-error.hook';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { HookControlProps } from '../../HookForm';
import { ControlFormGroup } from '../../shared/ControlFormGroup';
import { datePickerHelper } from './DatePicker.helper';

interface HookDatePickerControlProps extends HookControlProps {
    options?: Partial<ReactDatePickerProps>;
    onChange?: (date: string | undefined) => void;
}

export const DatePickerControl = (
    props: HookDatePickerControlProps
): React.ReactElement<any, any> | null => {
    const client = useClient();
    const dateLocale = datePickerHelper.getStringLocale(client.countryCode);

    useEffect(() => {
        if (dateLocale) {
            registerLocale(dateLocale, datePickerHelper.getClientLocale(dateLocale));
            setDefaultLocale(dateLocale);
        }
    }, [client.countryCode, dateLocale]);

    const { rules, name, errorName, placeholder, options, tabIndex, onChange, errorIndex } = props;
    const { register, setValue, watch } = useFormContext();

    const currentValue = watch(name);

    const getSelected = useCallback((value: string | undefined) => {
        if (!value) {
            return null;
        }
        const selected = parseISO(value);

        if (!isValid(selected)) {
            return null;
        }

        return selected;
    }, []);

    useDeepCompareEffect(() => {
        register(name, rules);
    }, [register, name, rules]);

    const setValueForInput = useCallback(
        (date: Date | null) => {
            const valueToSave =
                date && isValid(date)
                    ? datePickerHelper.format(date, dateLocale, 'yyyy-MM-dd')
                    : undefined;
            setValue(name, valueToSave, {
                shouldValidate: true,
                shouldDirty: true,
            });
            if (onChange) {
                onChange(valueToSave);
            }
        },
        [dateLocale, setValue, name, onChange]
    );

    // this is disgusting, but disabling the input is the best options currently
    // otherwise the writing input is really filthy, by default it uses the american input format of month/day/year
    // it's incredibly difficult to make this work in a logical way
    // I will revisit this once I've updated hooks, and forms across the board TODO
    const handleDateChangeRaw = (event: React.SyntheticEvent<HTMLInputElement, Event>) => {
        event.preventDefault();
    };

    const controlError = useFormError(errorName || name, errorIndex);

    return useMemo(
        () => (
            <ControlFormGroup {...props}>
                <ReactDatePicker
                    wrapperClassName="d-block"
                    popperClassName="z-2"
                    {...options}
                    selected={getSelected(currentValue)}
                    className={`form-control ${controlError ? 'is-invalid' : ''}`}
                    dateFormat="PP"
                    locale={dateLocale}
                    placeholderText={placeholder}
                    onChange={date => setValueForInput(date)}
                    onChangeRaw={handleDateChangeRaw}
                    tabIndex={tabIndex}
                />
            </ControlFormGroup>
        ),
        [
            props,
            options,
            getSelected,
            currentValue,
            controlError,
            dateLocale,
            placeholder,
            tabIndex,
            setValueForInput,
        ]
    );
};
