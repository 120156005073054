import { HookFormRules } from '../../HookForm';
import { RadioOption } from './RadioControl';
import { useFormContext } from 'react-hook-form';

interface Props {
    option: RadioOption;
    name: string;
    rules?: HookFormRules;
    tabIndex?: number;
    disabled?: boolean;
}

export const RadioControlItem = ({
    option,
    name,
    rules,
    tabIndex,
    disabled,
}: Props): React.ReactElement<any, any> | null => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <div className="form-check mb-1">
            <input
                type="radio"
                id={option.key.toString()}
                value={option.key}
                tabIndex={tabIndex}
                disabled={disabled}
                className={`form-check-input ${errors[name] ? 'is-invalid' : ''}`}
                {...register(name, rules)}
            />
            <label className="form-check-label" htmlFor={option.key.toString()}>
                {option.label}
            </label>
        </div>
    );
};
