import { LOCATION_CHANGE } from 'connected-react-router';
import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { delayTypeUntilAuthenticated } from 'src/logic/helpers/epics/app-init.epic-helper';
import { settingsPaths } from '../../../routes/employer/paths/settings.paths';
import { asyncEpicStandard } from '../../helpers/epics/async.epic-helper';
import { RootEpic } from '../epic.root-index';
import { settingsAsync, settingsUpdateAsync } from './setting.actions';

export const settingRouteEpic: RootEpic = (action$, state$) => {
    return delayTypeUntilAuthenticated(action$, state$, LOCATION_CHANGE).pipe(
        filter(action => action.payload.location.pathname === settingsPaths.settings),
        filter(() => !state$.value.settings.value),
        mergeMap(i => of(settingsAsync.request()))
    );
};

export const settingListEpic = asyncEpicStandard(settingsAsync, ({ api }) => api.settings.list());
export const settingUpdateEpic = asyncEpicStandard(settingsUpdateAsync, ({ api }, { payload }) =>
    api.settings.update(payload)
);
