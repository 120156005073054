import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { EventSession } from 'src/models/events/event-session.model';
import { eventPathCreator } from 'src/routes/employer/paths/event.paths';
import { DateDisplay } from 'src/ui/shared/components/details/DateDisplay';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { ListGroupWrapper } from 'src/ui/shared/components/lists/ListGroupWrapper';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';

interface Props {
    eventId: number;
    sessions: EventSession[];
}

export const EventSessionList = ({
    eventId,
    sessions,
}: Props): React.ReactElement<any, any> | null => {
    const { value } = useApiConfiguration();

    return (
        <ListGroupWrapper items={sessions}>
            {sessions.map(session =>
                value?.settings?.disableCheckIn ? (
                    <ListGroup.Item key={session.id}>
                        <DateDisplay date={session.start} showTime /> -{' '}
                        <DateDisplay date={session.end} showTime />
                    </ListGroup.Item>
                ) : (
                    <ListGroup.Item
                        as={Link}
                        key={session.id}
                        to={eventPathCreator.sessionCheckIn(eventId, session.id)}
                        action
                    >
                        <Icon icon={faQrcode}>
                            <DateDisplay date={session.start} showTime /> -{' '}
                            <DateDisplay date={session.end} showTime />
                        </Icon>
                    </ListGroup.Item>
                )
            )}
        </ListGroupWrapper>
    );
};
