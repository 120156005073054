import { createReducer } from 'typesafe-actions';
import { Setting } from '../../../models/settings/setting.model';
import { EntitySingleState } from '../../../models/store-models/entity-single-state.model';
import * as actions from './setting.actions';
import { singleEntityState } from '../../helpers/initial-state.helper';
import { singleReducerHandler } from '../../reducer-handlers/single.reducer-handler';

export type SettingState = EntitySingleState<Setting[]>;
const initialState: SettingState = { ...singleEntityState };

const settingReducer = createReducer(initialState)
    .handleAction(actions.settingsAsync.request, singleReducerHandler.request)
    .handleAction(actions.settingsAsync.success, singleReducerHandler.success)
    .handleAction(actions.settingsAsync.cancel, singleReducerHandler.cancel)
    .handleAction(actions.settingsAsync.failure, singleReducerHandler.failure)
    .handleAction(actions.settingsUpdateAsync.request, singleReducerHandler.updateRequest)
    .handleAction(actions.settingsUpdateAsync.success, singleReducerHandler.success)
    .handleAction(actions.settingsUpdateAsync.failure, singleReducerHandler.updateFailure)
    .handleAction(actions.settingsResetState, () => initialState);

export default settingReducer;
