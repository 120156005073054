import { getClientConfig } from 'src/clients-internal/configuration.init';
import { fetchHelper } from 'src/logic/helpers/fetch.helper';
import { ApiResponse } from 'src/models/api/response.model';
import { ObsApiExpected } from 'src/models/api/service.model';
import { UploadKey, UploadCreate } from 'src/models/uploads/upload.model';

export const blah = '';

const basePath = () => `${getClientConfig().careerHubBasePath}/services/employers/v1/banners`;

export const create = (request: UploadCreate): ObsApiExpected<ApiResponse<UploadKey>> => {
    const formData = new FormData();
    formData.append('file', request.blob, request.title);

    return fetchHelper.post(basePath(), formData);
};
