import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { ErrorNormalized } from 'src/models/errors/error.model';
import { Icon } from '../../icon/Icon';

interface Props {
    error: ErrorNormalized | undefined;
}

export const HookFormErrorSpan = ({ error }: Props): React.ReactElement<any, any> | null => {
    const onClick = () => {
        window.scrollTo(0, 0);
    };

    return error ? (
        <Button variant="link-danger" onClick={onClick} className="p-0">
            <Icon icon={faExclamationTriangle}>
                {error?.message || 'An unexpected error occurred'}
            </Icon>
        </Button>
    ) : null;
};
