import { useRootSelector } from './root-selector.hook';

// be a bit careful using this, as it will only be
// accessible after the api-configuration call has been received
export const useApiConfiguration = () => {
    const value = useRootSelector(state => state.apiConfiguration.value);
    const includes = useRootSelector(state => state.apiConfiguration.include);
    const fetch = useRootSelector(state => state.apiConfiguration.fetch);

    return {
        value,
        includes,
        fetch,
    };
};
