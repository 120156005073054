import { getClientConfig } from 'src/clients-internal/configuration.init';
import { EntityPageRequest, EntityRequest } from '../../../models/api/request.model';
import { ApiSearchResponse, ApiResponse } from '../../../models/api/response.model';
import { ObsApiExpected } from '../../../models/api/service.model';
import { JobChangeRequest } from '../../../models/jobs/job-change-request.model';
import { JobPublishRequest } from '../../../models/jobs/job-publish-request.model';
import { JobStatus } from '../../../models/jobs/job-types.model';
import { Job } from '../../../models/jobs/job.model';
import { apiStringify } from '../../helpers/api-query-string.helper';
import { fetchHelper } from '../../helpers/fetch.helper';

const basePath = () => `${getClientConfig().careerHubBasePath}/services/employers/v1/jobs`;

export interface JobPageRequest extends EntityPageRequest {
    status?: JobStatus[];
}

export function list(
    request: JobPageRequest
): ObsApiExpected<ApiSearchResponse<Job, JobPageRequest>> {
    return fetchHelper.get(`${basePath()}${apiStringify(request)}`);
}

export function single(request: EntityRequest): ObsApiExpected<ApiResponse<Job>> {
    return fetchHelper.get(`${basePath()}/${request.id}`);
}

export function create(request: Job): ObsApiExpected<ApiResponse<Job>> {
    return fetchHelper.post(basePath(), request);
}

export function update(request: Job): ObsApiExpected<ApiResponse<Job>> {
    return fetchHelper.put(`${basePath()}/${request.id}`, request);
}

export function deleteJob(request: EntityRequest): ObsApiExpected<ApiResponse<Job>> {
    return fetchHelper.del(`${basePath()}/${request.id}`);
}

export function publish(request: JobPublishRequest): ObsApiExpected<ApiResponse<Job>> {
    return fetchHelper.post(`${basePath()}/${request.id}/publish`, request);
}

export function withdraw(request: EntityRequest): ObsApiExpected<ApiResponse<Job>> {
    return fetchHelper.post(`${basePath()}/${request.id}/withdraw`, request);
}

export function changeRequest(
    request: JobChangeRequest
): ObsApiExpected<ApiResponse<JobChangeRequest>> {
    return fetchHelper.post(`${basePath()}/${request.id}/change-request`, request);
}
