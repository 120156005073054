import Color from 'color';
import { useMemo } from 'react';
import { Endorsement } from 'src/models/endorsements/endorsement.model';
import styles from '../styles/endorsement-badge.module.scss';
import { EndorsementIcon } from './EndorsementIcon';
import { EndorsementShape } from './EndorsementShape';

interface Props {
    size?: number;
    endorsement: Endorsement;
}

export const EndorsementItem = ({
    size = 70,
    endorsement,
}: Props): React.ReactElement<any, any> | null => {
    const ribbenTextColour = useMemo(() => {
        if (!endorsement.ribbonColourHex) {
            return 'black';
        }

        return Color(endorsement.ribbonColourHex).isDark() ? 'white' : 'black';
    }, [endorsement.ribbonColourHex]);

    return (
        <div
            style={{ width: size, height: size, color: endorsement.colourHex }}
            className={styles.container}
            title={endorsement.name}
        >
            <EndorsementShape shape={endorsement.shape} />
            <EndorsementIcon
                icon={endorsement.fontAwesomeIconKey}
                colour={endorsement.colourHex}
                size={size}
                acronym={endorsement.acronym}
            />
            <div className={styles.endorsedWrapper}>
                <div
                    className={styles.endorsed}
                    style={{
                        fontWeight: 500,
                        paddingTop: size * 0.01,
                        paddingBottom: size * 0.01,
                        paddingLeft: size * 0.05,
                        paddingRight: size * 0.05 - size / 30,
                        fontSize: size * 0.1,
                        letterSpacing: size / 30,
                        boxShadow: `0 ${size * 0.04}px ${size * 0.1}px rgba(0, 0, 0, 0.25)`,
                        backgroundColor: endorsement.ribbonColourHex,
                        color: ribbenTextColour,
                    }}
                >
                    ENDORSED
                </div>
            </div>
        </div>
    );
};
