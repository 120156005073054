import { Card } from 'react-bootstrap';

interface Props {
    id?: string;
    className?: string;
    bodyClassName?: string;
    style?: React.CSSProperties | undefined;
    children: React.ReactNode;
}

export const CardWrapper = ({
    id,
    children,
    className,
    bodyClassName,
    style,
}: Props): React.ReactElement<any, any> | null => (
    <Card id={id} className={className} style={style}>
        <Card.Body className={bodyClassName ? bodyClassName : ''}>{children}</Card.Body>
    </Card>
);
