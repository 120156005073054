import { LOCATION_CHANGE } from 'connected-react-router';
import { filter, ignoreElements, map, tap } from 'rxjs/operators';
import { isLocationRouteMatch } from 'src/logic/helpers/epics/location.epic-helper';
import { careerhubPaths } from 'src/routes/employer/paths/careerhub.paths';
import { delayTypeUntilAuthenticated } from '../../../helpers/epics/app-init.epic-helper';
import { RootEpic } from '../../epic.root-index';

export const redirectToCareerHubPageEpic: RootEpic = (action$, state$, services) => {
    return delayTypeUntilAuthenticated(action$, state$, LOCATION_CHANGE).pipe(
        filter(isLocationRouteMatch(careerhubPaths.redirect)),
        map(() => state$.value.router.location.query?.returnUrl),
        tap({
            next: returnUrl => {
                // eeeeh, this could be better, should probably check types and return
                // a redirectErrorAction to show to the user.
                // this assumes everything is sent correctly
                const w = services.windowService.getWindow();
                const basePath = state$.value.configuration.value.careerHubBasePath;

                w.location.href = `${
                    basePath.endsWith('/') ? basePath.substring(0, basePath.length - 1) : basePath
                }${decodeURIComponent(returnUrl)}`;
            },
        }),
        ignoreElements()
    );
};
