import { ThinPageContent } from '../../../shared/components/page-components/ThinPageContent';
import { IdentityList } from '../components/identity-list/IdentityList';

export const PublicIdentityListPage = (): React.ReactElement<any, any> | null => {
    return (
        <ThinPageContent>
            <IdentityList />
        </ThinPageContent>
    );
};
