import { Markdown } from 'src/ui/shared/components/details/Markdown';
import { useClient } from 'src/ui/shared/hooks/client.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useDynamicString, useStrings } from 'src/ui/shared/hooks/strings.hook';

export const ContentCentralLink = (): React.ReactElement<any, any> | null => {
    const isCentralNode = useRootSelector(
        x => x.apiConfiguration.value?.settings?.centralBackgroundTaskEnabled === true
    );

    const { centralMarketingPath } = useClient();

    const {
        central: { contentFooterMessage },
    } = useStrings();

    const dynamicString = useDynamicString(contentFooterMessage, {
        $link: centralMarketingPath,
    });

    if (!isCentralNode) {
        return null;
    }

    return (
        <small className="text-center d-block content-central-link">
            <Markdown source={dynamicString} />
        </small>
    );
};
