import { createReducer } from 'typesafe-actions';
import { ClientConfig } from '../../../clients-internal/configuration.model';
import { configurationInit } from './configuration.actions';

export type ConfigurationState = {
    value: ClientConfig;
    clientCode: string;
};

const initialState: ConfigurationState = {
    value: {} as any, // this is handled by the configuration init action
    clientCode: '',
};

const configurationReducer = createReducer(initialState).handleAction(
    configurationInit,
    (state, action) => ({
        value: action.payload,
        clientCode: action.meta.clientCode,
    })
);

export default configurationReducer;
