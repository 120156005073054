import { faPen } from '@fortawesome/free-solid-svg-icons';
import { organisationPaths } from 'src/routes/employer/paths/organisation.paths';
import { ButtonLink } from '../../../../shared/components/bootstrap/buttons/ButtonLink';
import { PageTitle } from '../../../../shared/components/page-components/PageTitle';

export const OrganisationDetailsPageNav = (): React.ReactElement<any, any> | null => {
    return (
        <div className="d-flex align-items-center">
            <PageTitle>Organisation</PageTitle>
            <ButtonLink to={organisationPaths.edit} className="ml-2" icon={faPen}>
                Edit organisation
            </ButtonLink>
        </div>
    );
};
