import { useMemo } from 'react';
import { DetailsProperty } from 'src/ui/shared/components/details/DetailsProperty';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';

interface Props {
    eventTypeId: number;
}

export const EventTypeProperty = ({ eventTypeId }: Props): React.ReactElement<any, any> | null => {
    const { includes } = useApiConfiguration();
    const eventType = useMemo(() => {
        return includes.eventTypes?.find(i => i.id === eventTypeId);
    }, [eventTypeId, includes.eventTypes]);

    if (!eventType) {
        return null;
    }

    return <DetailsProperty label="Event type">{eventType.name}</DetailsProperty>;
};
