import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { routeEpics } from 'src/logic/helpers/epics/entity-route.epic-helper';
import { getRouteMatch } from 'src/logic/helpers/epics/location.epic-helper';
import { JobPageBaseParams, jobPaths } from 'src/routes/employer/paths/job.paths';
import { jobAsync, jobSetActive } from '../job.actions';

export const jobRouteEpics = routeEpics<JobPageBaseParams>({
    getMatch: location => getRouteMatch(location.payload.location, jobPaths.details),
    getListState: state => state.jobs,
    cancelActionCreator: jobAsync.cancel,
    getId: match => Number(match.params.jobId),
    setActiveActionCreator: jobSetActive,
    requestActionCreator: jobAsync.request,
});

export const jobAsyncEpic = asyncEpicBase(
    jobAsync,
    ({ api }, { payload }) => api.jobs.single(payload),
    {
        success: result => jobAsync.success(result.json),
        failure: (error, ra) => jobAsync.failure(error, ra.payload),
    },
    {
        cancelFilter: (cancelAction, requestAction) =>
            cancelAction.payload.id === requestAction.payload.id,
    }
);
