import { Logo } from '../../logo/Logo';
import { MobileNav } from '../mobile-navigation/MobileNavigation';
import { UserNav } from '../user-navigation/UserNav';
import { ApplicationTopNavContainer } from './ApplicationTopNavContainer';
import { ApplicationTopNavSwitch } from './ApplicationTopNavSwitch';

export const ApplicationTopNav = (): React.ReactElement<any, any> | null => {
    return (
        <ApplicationTopNavContainer>
            <div className="flex-grow-1 mx-4 application-top-nav-title">
                <div>
                    <div className="d-md-none mb-2 logo-container">
                        <Logo />
                    </div>
                </div>
                <ApplicationTopNavSwitch />
            </div>
            <div className="d-none d-md-block">
                <UserNav />
            </div>

            <div className="d-block d-md-none">
                <MobileNav />
            </div>
        </ApplicationTopNavContainer>
    );
};
