import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import { ApiResponse } from 'src/models/api/response.model';
import { AlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/AlertWrapper';
import { CardWrapper } from 'src/ui/shared/components/bootstrap/cards/CardWrapper';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { useActiveEntity } from 'src/ui/shared/hooks/active-entity.hook';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';
import { useClient } from 'src/ui/shared/hooks/client.hook';
import { useSimpleApiRequest } from 'src/ui/shared/hooks/simple-api-request.hook';
import { EventDateDisplay } from '../components/details/EventDateDisplay';
import { EventDetailsPageContent } from '../components/details/EventDetailsPageContent';
import { EventLocationDisplay } from '../components/details/EventLocationDisplay';

interface QrTemp {
    sessionId: number;
    token: string;
}

export const EventSessionCheckInPage = (): React.ReactElement<any, any> | null => {
    const { eventId, sessionId } = useParams<{ sessionId: string; eventId: string }>();
    const { careerHubBasePath } = useClient();
    const [qr, setQr] = useState<string | undefined>(undefined);

    const { value: config } = useApiConfiguration();

    const { requestState, response, request } = useSimpleApiRequest<ApiResponse<QrTemp>>();
    const url = useMemo(
        () => `${careerHubBasePath}/services/employers/v1/events/${eventId}/sessions/${sessionId}`,
        [careerHubBasePath, eventId, sessionId]
    );

    useEffect(() => {
        if (requestState.loading) {
            return;
        }

        if (response || requestState.error) {
            return;
        }

        request(url);
    }, [request, requestState, response, url]);

    useEffect(() => {
        if (!response?.data?.token || !!qr) {
            return;
        }
        const qrVakue = response
            ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              `${careerHubBasePath}${config?.settings?.eventQrUrlPattern.replace(
                  '{token}',
                  response.data.token
              )}`
            : undefined;

        setQr(qrVakue);
    }, [careerHubBasePath, config?.settings?.eventQrUrlPattern, qr, response]);

    const { entity: eventUnion, fetch } = useActiveEntity(state => state.eventUnions);

    return (
        <EventDetailsPageContent>
            {(fetch.loading || requestState.loading) && (
                <Loader state={[fetch, requestState]} useCard={true} />
            )}
            {(fetch.error || requestState.error) && (
                <AlertWrapper icon={faExclamationCircle} variant="danger">
                    {requestState.error
                        ? requestState.error.message
                        : fetch.error
                        ? fetch.error.message
                        : 'An unexpected error occurred.'}
                </AlertWrapper>
            )}
            {qr && eventUnion && eventUnion.event && (
                <CardWrapper>
                    <div className="text-center">
                        <h1 className="display-6 mb-2">{eventUnion.event.title}</h1>
                        <div className="mb-2">
                            <EventLocationDisplay item={eventUnion.event} />
                        </div>
                        <div className="large mb-5">
                            <EventDateDisplay event={eventUnion.event} />
                        </div>
                        <div className="mb-2">Scan the QR Code to check in</div>
                        <QRCode className="mb-5" value={qr} />
                    </div>
                </CardWrapper>
            )}
        </EventDetailsPageContent>
    );
};
