import { useMemo } from 'react';
import { DetailsProperty } from 'src/ui/shared/components/details/DetailsProperty';
import { DetailsPropertyValueDisplay } from 'src/ui/shared/components/details/DetailsPropertyValueDisplay';
import { useEventStats } from '../../hooks/event-stats.hook';

interface Props {
    eventId: number;
}

export const EventStatisticProperty = ({ eventId }: Props): React.ReactElement<any, any> | null => {
    const stats = useEventStats(eventId);

    const hide = useMemo(() => {
        if (!stats) {
            return true;
        }

        return [stats.views, stats.bookings, stats.waitlists, stats.cancellations].every(
            x => x === 0
        );
    }, [stats]);

    if (hide || !stats) {
        return null;
    }
    return (
        <DetailsProperty label="Statistics">
            {stats.views > 0 && <DetailsPropertyValueDisplay label="Views:" value={stats.views} />}
            {stats.bookings > 0 && (
                <DetailsPropertyValueDisplay label="Bookings:" value={stats.bookings} />
            )}
            {stats.waitlists > 0 && (
                <DetailsPropertyValueDisplay label="Waitlisted:" value={stats.waitlists} />
            )}
            {stats.cancellations > 0 && (
                <DetailsPropertyValueDisplay label="Cancellations:" value={stats.cancellations} />
            )}
        </DetailsProperty>
    );
};
