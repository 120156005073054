import qs, { IStringifyOptions } from 'qs';

const apiQueryStringOptions: IStringifyOptions = {
    addQueryPrefix: true,
};

export const apiStringify = (obj: any) => {
    return qs.stringify(obj, apiQueryStringOptions);
};

const centralApiQueryStringOptions: IStringifyOptions = {
    addQueryPrefix: true,
    arrayFormat: 'comma',
};

// different handlings of arrays between systems
export const centralApiStringify = (obj: any) => {
    return qs.stringify(obj, centralApiQueryStringOptions);
};
