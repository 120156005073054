import { EntityDetailsTitle } from 'src/ui/shared/components/titles/EntityDetailsTitle';
import { useEventUnionTitles } from '../../hooks/event-union-titles.hook';
import { EventUnionActionDropdown } from '../actions/EventUnionActionDropdown';
import { EventListBreadcrumb } from '../breadcrumbs/EventListBreadcrumb';

export const EventDetailsPageNav = (): React.ReactElement<any, any> | null => {
    const { eventUnion, title, hideLoader } = useEventUnionTitles();

    return (
        <EntityDetailsTitle
            title={title}
            hideLoader={hideLoader}
            before={<EventListBreadcrumb />}
            after={<EventUnionActionDropdown eventUnion={eventUnion} size="md" />}
        />
    );
};
