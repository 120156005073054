import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { individualPaths } from '../../../../../routes/employer/paths/individual.paths';
import { ButtonLink } from '../../../../shared/components/bootstrap/buttons/ButtonLink';
import { PageTitle } from '../../../../shared/components/page-components/PageTitle';

export const IndividualDetailsPageNav = (): React.ReactElement<any, any> | null => {
    return (
        <div className="d-flex align-items-center">
            <PageTitle>Details</PageTitle>
            <ButtonLink className="ml-2" to={individualPaths.edit} icon={faEdit}>
                Edit Details
            </ButtonLink>
        </div>
    );
};
