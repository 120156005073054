import { DeletedCard } from 'src/ui/shared/components/details/DeletedCard';
import { CardWrapper } from '../../../shared/components/bootstrap/cards/CardWrapper';
import { Loader } from '../../../shared/components/loader/Loader';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';
import { JobDetails } from '../components/details/JobDetails';
import { useActiveJob } from '../hooks/active-job.hook';

export const JobDetailsPage = (): React.ReactElement<any, any> | null => {
    const { job, fetch, deleted } = useActiveJob();

    return (
        <ApplicationPageContent>
            {deleted && <DeletedCard />}

            <Loader state={fetch} useCard={true}>
                {job && (
                    <CardWrapper>
                        <JobDetails job={job} />
                    </CardWrapper>
                )}
            </Loader>
        </ApplicationPageContent>
    );
};
