import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Icon } from '../../../icon/Icon';

interface Props {
    options: DropzoneOptions;
    children: React.ReactNode;
}

export const DropzoneDisplay = ({
    options,
    children,
}: Props): React.ReactElement<any, any> | null => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone(options);

    return (
        <div {...getRootProps()} className={`dropzone-container h-100 d-flex align-items-center ${isDragActive ? 'drag-hover' : ''}`}>
            <input {...getInputProps()} />
            <div className="w-100">
                <Icon icon={faUpload} />
                <div>{children}</div>
            </div>
        </div>
    );
};
