import { faBell } from '@fortawesome/free-solid-svg-icons';
import { formatDistance, isValid, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { Button, Toast } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
    authenticationExpireSoonDismissed,
    careerhubIdentitySetUserAsync,
} from '../../../../logic/features/authentication/authentication.actions';
import { identityHelper } from '../../authentication/helpers/identity.helper';
import { useAuthentication } from '../../authentication/hooks/authentication.hook';
import { Icon } from '../../../shared/components/icon/Icon';
import { Loader } from '../../../shared/components/loader/Loader';

const tryFormatDistance = (date: Date) => (isValid(date) ? formatDistance(date, new Date()) : '');

export const AuthenticationExpireToast = (): React.ReactElement<any, any> | null => {
    const { expires, activeIdentity, identitySetUserFetching } = useAuthentication();

    const [friendly, setFriendly] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        const authExpireDate = parseISO(expires || '');
        setFriendly(tryFormatDistance(authExpireDate));

        const interval = setInterval(() => {
            setFriendly(tryFormatDistance(authExpireDate));
        }, 1000 * 60);

        return () => clearInterval(interval);
    }, [expires]);

    const onClose = () => {
        dispatch(authenticationExpireSoonDismissed());
    };

    const onAction = () => {
        const request = identityHelper.createIdentitySetUserRequest(activeIdentity!);
        dispatch(careerhubIdentitySetUserAsync.request(request));
    };

    return (
        <Toast onClose={onClose}>
            <Toast.Header>
                <Icon iconClassName="text-warning mr-2" icon={faBell} size="lg" />
                <span className="mr-2">Your session will expire in {friendly}</span>
            </Toast.Header>
            <Toast.Body>
                <Button
                    variant="outline-primary"
                    size="sm"
                    disabled={identitySetUserFetching}
                    onClick={onAction}
                >
                    Continue Session
                </Button>
                <Loader size="sm" spinnerClassName="ml-2" state={identitySetUserFetching} />
            </Toast.Body>
        </Toast>
    );
};
