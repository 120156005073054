import { getClientConfig } from 'src/clients-internal/configuration.init';
import { apiStringify } from 'src/logic/helpers/api-query-string.helper';
import { fetchHelper } from 'src/logic/helpers/fetch.helper';
import { EntityPageRequest } from 'src/models/api/request.model';
import { ApiSearchResponse } from 'src/models/api/response.model';
import { ObsApiExpected } from 'src/models/api/service.model';
import { Skill } from 'src/models/skills/skill.model';

const basePath = () => `${getClientConfig().careerHubBasePath}/services/employers/v1/skills`;

export function list(): ObsApiExpected<ApiSearchResponse<Skill, EntityPageRequest>> {
    return fetchHelper.get(`${basePath()}${apiStringify({ isArchived: false })}`);
}
