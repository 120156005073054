import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { centralLinkHelper } from 'src/logic/features/central/helpers/central-link.helper';
import { organisationPaths } from 'src/routes/employer/paths/organisation.paths';
import { ManagedByCentralAlert } from 'src/ui/features/central/components/ManagedByCentralAlert';
import { organisationUpdateAsync } from '../../../../logic/features/organisations/organisation.actions';
import { Organisation } from '../../../../models/organisation/organisation.model';
import { CardWrapper } from '../../../shared/components/bootstrap/cards/CardWrapper';
import { Loader } from '../../../shared/components/loader/Loader';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';
import { OrganisationDetailsForm } from '../components/forms/OrganisationDetailsForm';
import { useOrganisation } from '../hooks/organisation.hook';

export const OrganisationEditPage = (): React.ReactElement<any, any> | null => {
    const { organisation, fetch, update, centralOrganisationId, allowLocalEdits } =
        useOrganisation();

    const dispatch = useDispatch();

    const onSubmit = (organisation: Organisation, logo: File | undefined) => {
        dispatch(organisationUpdateAsync.request({ organisation, logo }));
    };

    const onCancel = () => {
        dispatch(push(organisationPaths.details));
    };

    return (
        <ApplicationPageContent className="details-edit-page">
            <Loader state={fetch} useCard={true}>
                {!allowLocalEdits && (
                    <ManagedByCentralAlert
                        stringSelector={strings => strings.organisation.edit.managedByCentralInfo}
                        centralPath={centralLinkHelper.orgEdit(centralOrganisationId!)}
                    />
                )}

                <CardWrapper>
                    <OrganisationDetailsForm
                        organisation={organisation}
                        onCancel={onCancel}
                        onSubmit={onSubmit}
                        error={update.error}
                        showSpinner={update.loading}
                        disabled={!allowLocalEdits}
                    />
                </CardWrapper>
            </Loader>
        </ApplicationPageContent>
    );
};
