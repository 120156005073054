import { Job } from 'src/models/jobs/job.model';
import { DatePropertyDisplay } from 'src/ui/shared/components/details/DatePropertyDisplay';
import { DetailsProperty } from 'src/ui/shared/components/details/DetailsProperty';

interface Props {
    job: Job;
}

export const JobHistoryDetailsProperty = ({ job }: Props): React.ReactElement<any, any> | null => {
    return (
        <DetailsProperty label="History">
            <div className="d-table">
                <DatePropertyDisplay label="Added" date={job.addedDate} />
                <DatePropertyDisplay label="Last Updated" date={job.lastUpdatedDate} />
                {job.publishDate && <DatePropertyDisplay label="Publish" date={job.publishDate} />}
                {job.approvalCheckedDate && job.isApproved && (
                    <DatePropertyDisplay label="Approved" date={job.approvalCheckedDate} />
                )}
                {job.approvalCheckedDate && !job.isApproved && (
                    <DatePropertyDisplay label="Rejected" date={job.approvalCheckedDate} />
                )}
                {job.expireDate && <DatePropertyDisplay label="Expire" date={job.expireDate} />}
            </div>
        </DetailsProperty>
    );
};
