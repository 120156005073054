import { faCalendarCheck, faPen, faRedo, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
    eventRequestDeleteAsync,
    eventRequestRevertAsync,
} from 'src/logic/features/event-unions/event-union.actions';
import { EventRequest } from 'src/models/events/event-request.model';
import { eventPathCreator } from 'src/routes/employer/paths/event.paths';
import {
    ActionDropdown,
    ActionDropdownSize,
} from 'src/ui/shared/components/bootstrap/dropdown/ActionDropdown';
import { DropdownLink } from 'src/ui/shared/components/bootstrap/dropdown/DropdownLink';
import { DeleteModal } from 'src/ui/shared/components/bootstrap/modals/DeleteModal';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

interface Props {
    eventRequest: EventRequest;
    size: ActionDropdownSize;
}

export const EventRequestActionDropdown = ({
    eventRequest,
    size,
}: Props): React.ReactElement<any, any> | null => {
    const [showConfirm, setShowConfirm] = useState(false);
    const deleting = useRootSelector(
        state => !!state.eventUnions.single[eventRequest.id]?.deleteState?.loading
    );
    const reverting = useRootSelector(
        state => !!state.eventUnions.single[eventRequest.id]?.update?.loading
    );
    const dispatch = useDispatch();

    useEffect(() => {
        setShowConfirm(false);
    }, [eventRequest.status]);

    const onDelete = () => {
        dispatch(eventRequestDeleteAsync.request({ id: eventRequest.id }));
    };

    const onConfirmCancel = () => {
        setShowConfirm(false);
    };

    const onRevert = () => {
        dispatch(eventRequestRevertAsync.request({ id: eventRequest.id }));
    };

    return (
        <ActionDropdown size={size}>
            {eventRequest.status === 'Draft' && (
                <>
                    <Dropdown.Menu>
                        <DropdownLink
                            to={eventPathCreator.edit(eventRequest.id)}
                            icon={faPen}
                            iconClassName="fa-fw"
                        >
                            Edit
                        </DropdownLink>
                        <DropdownLink
                            to={eventPathCreator.publish(eventRequest.id)}
                            icon={faCalendarCheck}
                            iconClassName="fa-fw"
                        >
                            Publish
                        </DropdownLink>
                        <Dropdown.Divider />
                        <Dropdown.Item className="text-danger" onClick={() => setShowConfirm(true)}>
                            <Icon icon={faTimes} iconClassName="fa-fw">
                                Delete
                            </Icon>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                    {showConfirm && (
                        <DeleteModal
                            loading={deleting}
                            onCancel={onConfirmCancel}
                            onConfirm={onDelete}
                        />
                    )}
                </>
            )}
            {eventRequest.status === 'Pending' && (
                <>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setShowConfirm(true)}>
                            <Icon icon={faRedo} iconClassName="fa-fw">
                                Revert to draft
                            </Icon>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                    {showConfirm && (
                        <DeleteModal
                            loading={reverting}
                            onCancel={onConfirmCancel}
                            onConfirm={onRevert}
                        />
                    )}
                </>
            )}
        </ActionDropdown>
    );
};
