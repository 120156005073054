import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import uniqBy from 'lodash/uniqBy';
import { entityListInitialState } from 'src/logic/helpers/initial-state.helper';
import { listSingleReducerHandler } from 'src/logic/reducer-handlers/list-single.reducer-handler';
import { listReducerHandler } from 'src/logic/reducer-handlers/list.reducer-handler';
import { EntityNote } from 'src/models/entity-notes/entity-note.model';
import { EventProvider } from 'src/models/events/event-provider.model';
import { EventInclude } from 'src/models/events/event-request.model';
import { EventSession } from 'src/models/events/event-session.model';
import { EventStatistics } from 'src/models/events/event-statistics';
import { EventUnion } from 'src/models/events/event-union.model';
import { EntityListState } from 'src/models/store-models/entity-list-state.model';
import { createReducer } from 'typesafe-actions';
import {
    entityNoteCreateAsync,
    entityNoteDeleteAsync,
    entityNoteUpdateAsync,
} from '../entity-notes/entity-note.actions';
import * as actions from './event-union.actions';
import { EventUnionPageRequest } from './event-union.service';

export type EventUnionState = EntityListState<EventUnion, EventUnionPageRequest> & {
    statistics: { [eventId: number]: EventStatistics | undefined };
    providers: { [eventId: number]: EventProvider[] | undefined };
    sessions: { [eventId: number]: EventSession[] | undefined };
    notes: { [eventId: number]: Pick<EntityNote, 'id' | 'attachedToEntityId'>[] | undefined };
    cancelled: {
        id?: number | undefined;
        title?: string | undefined;
        showMessage: boolean;
    };
};

const initialState: EventUnionState = {
    ...entityListInitialState,
    statistics: {},
    providers: {},
    sessions: {},
    notes: {},
    cancelled: { showMessage: false },
};

// NOTE: this doesn't handle all aspects of entity-notes. They have their own reducer
const handleInclude = (state: EventUnionState, include: EventInclude) => ({
    statistics: {
        ...state.statistics,
        ...keyBy(include.statistics, i => i.eventId),
    },
    providers: {
        ...state.providers,
        ...groupBy(include.providers, i => i.eventId),
    },
    sessions: {
        ...state.sessions,
        ...groupBy(include.sessions, i => i.eventId),
    },
    notes: {
        ...state.notes,
        ...groupBy(
            (include.notes || []).map(i => ({
                id: i.id,
                attachedToEntityId: i.attachedToEntityId,
            })),
            i => i.attachedToEntityId
        ),
    },
});

const handleNoteSuccess = (state: EventUnionState, note: EntityNote) => ({
    ...state,
    notes: {
        ...state.notes,
        [note.attachedToEntityId]: uniqBy(
            [
                ...(state.notes[note.attachedToEntityId] || []),
                { id: note.id, attachedToEntityId: note.attachedToEntityId },
            ],
            x => x.id
        ),
    },
});

const eventUnionReducer = createReducer(initialState)
    .handleAction(actions.eventUnionListSetActive, listReducerHandler.setActive)
    .handleAction(actions.eventUnionListAsync.request, listReducerHandler.request)
    .handleAction(actions.eventUnionListAsync.failure, listReducerHandler.failure)
    .handleAction(actions.eventUnionListAsync.cancel, listReducerHandler.cancel)
    // overloading success, to handle includes
    .handleAction(actions.eventUnionListAsync.success, (state, action) => ({
        ...listReducerHandler.success(state, action),
        ...handleInclude(state, action.payload.include),
    }))
    .handleAction(actions.eventUnionListClear, listReducerHandler.clear)

    .handleAction(actions.eventUnionSetActive, listSingleReducerHandler.setActive)
    .handleAction(actions.eventUnionAsync.request, listSingleReducerHandler.request)
    .handleAction(actions.eventUnionAsync.failure, listSingleReducerHandler.failure)
    .handleAction(actions.eventUnionAsync.cancel, listSingleReducerHandler.cancel)
    .handleAction(actions.eventUnionAsync.success, (state, action) => ({
        ...listSingleReducerHandler.success(state, action),
        ...handleInclude(state, action.payload.include),
    }))

    // creation will return an event request, which I manually massage into an event union
    .handleAction(actions.eventRequestCreateAsync.request, listSingleReducerHandler.create)
    .handleAction(actions.eventRequestCreateAsync.failure, listSingleReducerHandler.createFailure)
    .handleAction(
        [
            actions.eventRequestCreateAsync.success,
            actions.eventRequestPublishAsync.success,
            actions.eventRequestUpdateAsync.success,
            actions.eventRequestRevertAsync.success,
        ],
        (state, action) => {
            const eventUnion: EventUnion = {
                id: action.payload.data.id,
                entityType: 'EventRequest',
                eventRequest: action.payload.data,
            };

            return listSingleReducerHandler.success(state, { payload: { data: eventUnion } });
        }
    )

    // publish
    .handleAction(actions.eventRequestPublishAsync.request, listSingleReducerHandler.updateRequest)
    .handleAction(actions.eventRequestPublishAsync.failure, listSingleReducerHandler.updateFailure)
    // publish revert
    .handleAction(actions.eventRequestRevertAsync.request, listSingleReducerHandler.updateRequest)
    .handleAction(actions.eventRequestRevertAsync.failure, listSingleReducerHandler.updateFailure)
    // update
    .handleAction(actions.eventRequestUpdateAsync.request, (state, action) =>
        listSingleReducerHandler.updateRequest(state, { payload: action.payload.eventRequest })
    )
    .handleAction(actions.eventRequestUpdateAsync.failure, listSingleReducerHandler.updateFailure)
    // delete
    .handleAction(actions.eventRequestDeleteAsync.request, listSingleReducerHandler.deleteRequest)
    .handleAction(actions.eventRequestDeleteAsync.failure, listSingleReducerHandler.deleteFailure)
    .handleAction(actions.eventRequestDeleteAsync.success, (state, action) => {
        const eventUnion: EventUnion = {
            id: action.payload.data.id,
            entityType: 'EventRequest',
            eventRequest: action.payload.data,
        };
        return listSingleReducerHandler.deleteSuccess(state, { payload: { data: eventUnion } });
    })
    // cancel
    .handleAction(actions.eventCancelAsync.request, listSingleReducerHandler.updateRequest)
    .handleAction(actions.eventCancelAsync.failure, listSingleReducerHandler.updateFailure)
    .handleAction(actions.eventCancelAsync.success, (state, action) => {
        const eventUnion: EventUnion = {
            id: action.payload.data.id,
            entityType: 'Event',
            event: action.payload.data,
        };
        return {
            ...listSingleReducerHandler.success(state, { payload: { data: eventUnion } }),
            cancelled: {
                id: action.payload.data.id,
                title: action.payload.data.title,
                showMessage: true,
            },
            list: {},
            activeList: { ...initialState.activeList },
        };
    })
    .handleAction(actions.eventDismissCancelMessage, state => ({
        ...state,
        cancelled: {
            ...state.cancelled,
            showMessage: false,
        },
    }))

    // handling entityNotes
    .handleAction(
        [entityNoteCreateAsync.success, entityNoteUpdateAsync.success],
        (state, action) => ({
            ...handleNoteSuccess(state, action.payload.data),
        })
    )
    .handleAction(entityNoteDeleteAsync.success, (state, action) => ({
        ...state,
        notes: {
            [action.payload.data.attachedToEntityId]: (
                state.notes[action.payload.data.attachedToEntityId] || []
            ).filter(x => x.id !== action.payload.data.id),
        },
    }))
    .handleAction(actions.eventUnionResetState, () => initialState);

export default eventUnionReducer;
