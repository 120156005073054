import { useClient } from './client.hook';
import { useRootSelector } from './root-selector.hook';

//ToDo: add version number and logic
export const useIsCareerHubVersion = (major: number, minor: number): boolean => {
    const { disableVersionChecks } = useClient();
    const careerHubVersion = useRootSelector(state => state.apiConfiguration.version);

    if (disableVersionChecks) {
        return true;
    }

    if (careerHubVersion.major < major) {
        return false;
    }

    if (careerHubVersion.major === major && careerHubVersion.minor < minor) {
        return false;
    }

    return true;
};
