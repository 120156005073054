import { faIndustry } from '@fortawesome/free-solid-svg-icons';
import { Badge } from 'react-bootstrap';
import { Organisation } from '../../../../../models/organisation/organisation.model';
import { Icon } from '../../../../shared/components/icon/Icon';
import { OrganisationBadges } from './OrganisationBadges';
import { OrganisationLogoHeaderDisplay } from './OrganisationLogoHeaderDisplay';
import { OrganisationNameDivision } from './OrganisationNameDivision';

interface Props {
    organisation: Organisation;
}

export const OrganisationDetailsHeader = ({
    organisation,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <OrganisationLogoHeaderDisplay>
            <div className="d-flex mb-2">
                <h2 className="font-weight-normal mb-0">
                    <OrganisationNameDivision organisation={organisation} />
                </h2>

                <div className="ml-auto d-flex">
                    <OrganisationBadges organisation={organisation} />
                </div>
            </div>
            {organisation.industries && organisation.industries.length > 0 && (
                <div>
                    <Icon icon={faIndustry} iconClassName="mr-2" />
                    {organisation.industries &&
                        organisation.industries.map((val, index) => (
                            <span key={val.id}>
                                <Badge variant="outline-dark" className="mr-2">
                                    {val.name}
                                </Badge>
                            </span>
                        ))}
                </div>
            )}
        </OrganisationLogoHeaderDisplay>
    );
};
