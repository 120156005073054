import { useMemo } from 'react';
import { Badge } from 'react-bootstrap';
import { EntityNote } from 'src/models/entity-notes/entity-note.model';

interface Props {
    entityNote: EntityNote;
}

export const EntityNoteStatusBadge = ({
    entityNote,
}: Props): React.ReactElement<any, any> | null => {
    const badgeClass = useMemo(() => {
        switch (entityNote.action) {
            case 'Confirm':
                return 'outline-success';
            case 'Denied':
                return 'outline-danger';
            case 'None':
                return 'outline-secondary';
            default:
                return 'outline-secondary';
        }
    }, [entityNote.action]);

    const text = useMemo(() => {
        switch (entityNote.action) {
            case 'Confirm':
                return 'Completed';
            case 'Denied':
                return 'Denied';
            case 'None':
                return 'Pending';
            default:
                return entityNote.action;
        }
    }, [entityNote.action]);

    return (
        <Badge variant={badgeClass} className="font-weight-normal">
            {text}
        </Badge>
    );
};
