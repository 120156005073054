import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { routerHistory } from '../logic/features/reducer.root-index';
import configureStore from '../logic/store.index';
import { storeRegistry } from '../logic/store.registry';
import { RouteList } from '../routes/RouteList';
import { AnalyticsConfirmation } from './features/analytics/components/AnalyticsConfirmation';
import { InitializationRouteGuard } from './features/authentication/components/InitializationRouteGuard';
import { MiniProfilerComponent } from './features/mini-profiler/MiniProfiler';
import { MasterErrorBoundry } from './features/sentry/components/MasterErrorBoundry';
import { FontAwesomeScript } from './features/endorsements/components/init/FontAwesomeScript';

const store = configureStore();
storeRegistry.store(store);

export const App = (): React.ReactElement<any, any> | null => {
    return (
        <MasterErrorBoundry>
            <Provider store={store}>
                <ConnectedRouter history={routerHistory}>
                    <InitializationRouteGuard>
                        <RouteList />
                        <AnalyticsConfirmation />
                    </InitializationRouteGuard>
                </ConnectedRouter>
                <MiniProfilerComponent />
                <FontAwesomeScript />
            </Provider>
        </MasterErrorBoundry>
    );
};
