import { createContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useEventLocationOptions } from 'src/ui/features/events/hooks/event-location-options.hook';
import { HookControlProps, HookForm } from '../../HookForm';
import { SingleSelectControl } from '../react-selects/SingleSelectControl';
import { EventLocationInnerControl } from './EventLocationInnerControl';
import { EventLocationSelectValue } from './EventLocationOptions';

export interface EventLocationForm {
    select: EventLocationSelectValue | undefined;

    onlineUrl?: string | undefined;
    onlineInstructions?: string | undefined;

    onCampusId?: number | undefined;
    onCampusLocationId?: number | undefined;

    onCampusRequest?: string | undefined;

    offCampusCountry?: string | undefined;
    offCampusRegion?: string | undefined;
    offCampusCity?: string | undefined;
    offCampusAddress?: string | undefined;
}

const selectName: keyof EventLocationForm = 'select';

export const EventLocationControlContext = createContext('location');

export const EventLocationControl = (
    props: HookControlProps & { defaultLocation: EventLocationForm }
): React.ReactElement<any, any> | null => {
    const selectControlName = `${props.name}.${selectName}`;
    const options = useEventLocationOptions();
    const { setValue, unregister, clearErrors, register } = useFormContext();

    // this feels hacky, super hacky, I think the whole control needs to be redone
    // for some reason the unregister wasn't working, and it was keeping errors
    // after switching select options, so I manually unregister, reregister
    // and set the value AGAIN, and clears errors because why not.. sigh.
    const onSelectChange = (value: EventLocationSelectValue | undefined) => {
        clearErrors(props.name);
        unregister(props.name);
        register(props.name);
        setValue(props.name, { [selectName]: value });
    };

    return (
        <EventLocationControlContext.Provider value={props.name}>
            <HookForm.Row>
                <HookForm.Col lg={6} xs={12}>
                    <SingleSelectControl
                        label="Location"
                        name={selectControlName}
                        options={options}
                        rules={props.rules}
                        onChange={onSelectChange}
                    />
                </HookForm.Col>
            </HookForm.Row>
            <EventLocationInnerControl selectControlName={selectName} />
        </EventLocationControlContext.Provider>
    );
};
