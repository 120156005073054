import { isAfter, parseISO, isValid } from 'date-fns';
import { useCallback } from 'react';
import { Col, Form } from 'react-bootstrap';
import { StringsAlertWrapper } from 'src/ui/shared/components/bootstrap/alerts/StringsAlertWrapper';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import { ErrorNormalized } from '../../../../../models/errors/error.model';
import { JobPublishRequest } from '../../../../../models/jobs/job-publish-request.model';
import { Job } from '../../../../../models/jobs/job.model';
import { HookForm } from '../../../../shared/components/forms/HookForm';
import { useFormDefault } from '../../../../shared/hooks/form-default.hook';
import { useJobDateValidation } from '../../hooks/job-date-validation.hook';

interface Props {
    job: Job;
    onSubmit: (jobPublish: JobPublishRequest) => void;
    onCancel: () => void;
    error?: ErrorNormalized;
    showSpinner?: boolean;
    disabled?: boolean;
}

export const JobPublishForm = ({
    job,
    onSubmit,
    onCancel,
    error,
    showSpinner,
    disabled,
}: Props): React.ReactElement<any, any> | null => {
    const formMethods = useFormDefault<JobPublishRequest>({
        defaultValues: {
            id: job.id,
            publishDate: job.publishDate,
            expireDate: job.expireDate,
        },
        error,
        disabled,
    });
    const { watch, setValue } = formMethods;
    const { now, in2Months, minExpireDate, maxExpireDate } = useJobDateValidation(formMethods);

    const dateExpireValue = parseISO(watch('expireDate'));
    const datePublishValue = parseISO(watch('publishDate'));

    const onExpireChange = useCallback(
        (val: string | undefined) => {
            if (!val || !isValid(datePublishValue)) {
                return;
            }

            const newDate = parseISO(val);

            if (isAfter(datePublishValue, newDate)) {
                setValue('publishDate', val);
            }
        },
        [datePublishValue, setValue]
    );

    const onPublishChange = useCallback(
        (val: string | undefined) => {
            if (!val || !isValid(dateExpireValue)) {
                return;
            }

            if (isAfter(parseISO(val), dateExpireValue)) {
                setValue('expireDate', val);
            }
        },
        [dateExpireValue, setValue]
    );

    const {
        job: {
            publish: { datePublish, dateExpire, terms, alerts },
        },
    } = useStrings();

    return (
        <HookForm {...formMethods} onSubmit={onSubmit}>
            <HookForm.ErrorAlert error={error} />
            <StringsAlertWrapper alerts={alerts} />

            <HookForm.HiddenId />
            <Form.Row>
                <Col lg={3} md={4} sm={6}>
                    <HookForm.DatePicker
                        label={datePublish.label}
                        popover={datePublish.popover}
                        name="publishDate"
                        options={{ minDate: now, maxDate: in2Months }}
                        rules={{ required: true }}
                        onChange={onPublishChange}
                    />
                </Col>
                <Col lg={3} md={4} sm={6}>
                    <HookForm.DatePicker
                        label={dateExpire.label}
                        popover={dateExpire.popover}
                        name="expireDate"
                        options={{
                            minDate: minExpireDate,
                            maxDate: maxExpireDate,
                        }}
                        rules={{ required: true }}
                        onChange={onExpireChange}
                    />
                </Col>
            </Form.Row>
            <HookForm.Checkbox
                label={terms.label}
                popover={terms.popover}
                name="terms"
                rules={{ required: true }}
            />
            <HookForm.Buttons onCancel={onCancel} showSpinner={showSpinner} />
        </HookForm>
    );
};
