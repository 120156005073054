import {
    Attachment as CentralJobAttachment,
    ConfigurationModelInclude as CentralConfigurationIncludeOption,
    ConfigurationModelIncludeModel as CentralConfigurationResponse,
    Contact as CentralContact,
    Job as CentralJob,
    JobEql as CentralJobEql,
    Organisation as CentralOrganisation,
    OrganisationEql as CentralOrganisationEql,
    OrganisationRegistration as CentralRegistration,
    SimilarOrganisationModel as CentralSimilarOrganisation,
    SimilarOrganisationQuery as CentralSimilarOrganisationRequest,
    NodeEntityEql,
} from '@symplicity/central-types';
import { EqlBuilder } from '@symplicity/eql-builder';
import { getClientConfig } from 'src/clients-internal/configuration.init';
import { ApiResponse } from 'src/models/api/response.model';
import { FetchHelperOptions, ObsApiExpected } from 'src/models/api/service.model';
import { CentralListRequest } from 'src/models/central/central-list.model';
import { Contact } from 'src/models/contacts/contact.model';
import { centralApiStringify } from '../../helpers/api-query-string.helper';
import { fetchHelper } from '../../helpers/fetch.helper';
import { createCentralContact } from './helpers/central.helper';

const options: FetchHelperOptions = { includeAccessToken: true, credentials: 'include' };

const hasNodeEntity =
    (nodeKey: string, nodeEntityId: number) => (builder: EqlBuilder<NodeEntityEql>) => {
        return builder.eq('NodeKey', nodeKey).eq('NodeEntityId', nodeEntityId.toString());
    };

export const configurationGet = (): ObsApiExpected<CentralConfigurationResponse> => {
    const centralApiBasePath = getClientConfig().centralApiPath;
    const include: CentralConfigurationIncludeOption[] = [
        'countries',
        'employmentTypes',
        'industries',
        'occupations',
        'organisationScopes',
        'organisationSizes',
        'organisationTypes',
        'nodes',
    ];
    return fetchHelper.get(
        `${centralApiBasePath}/v2/configuration${centralApiStringify({ include })}`,
        options
    );
};

export const register = (
    centralRegister: CentralRegistration
): ObsApiExpected<ApiResponse<CentralRegistration>> => {
    const centralApiBasePath = getClientConfig().centralApiPath;
    return fetchHelper.post(
        `${centralApiBasePath}/v2/organisations/registration`,
        centralRegister,
        options
    );
};

// this if only ever used for MSP, so I'm not really handling pagination or anything here
// the expectation is that only one id is sent in at a time
export const organisationQuery = (
    request: CentralListRequest
): ObsApiExpected<ApiResponse<CentralOrganisation[]>> => {
    const centralApiBasePath = getClientConfig().centralApiPath;

    const filterString = new EqlBuilder<CentralOrganisationEql>()
        .where('Nodes', hasNodeEntity(request.clientCode, request.localId))
        .toURIComponent();

    return fetchHelper.get(
        `${centralApiBasePath}/v2/organisations?filter=${filterString}`,
        options
    );
};

export const organisationSimilarQuery = (
    request: CentralSimilarOrganisationRequest
): ObsApiExpected<ApiResponse<(CentralSimilarOrganisation & { id: number })[]>> => {
    const centralApiBasePath = getClientConfig().centralApiPath;
    return fetchHelper.get(
        `${centralApiBasePath}/v2/organisations/similar${centralApiStringify(request)}`,
        options
    );
};

export const contactInvite = (centralOrganisationId: number, contact: Contact) => {
    const centralApiBasePath = getClientConfig().centralApiPath;

    const centralContact = createCentralContact(
        contact,
        {
            subscribeCentral: false,
            subscribeCentralApps: false,
        },
        { email: contact.email, keyContact: false }
    ) as Partial<CentralContact>;

    // bleh, this is just to get around the "readonly" organisationId
    // (which doesn't exist on registration, but does on contact invite)
    centralContact.organisationId = centralOrganisationId;

    return fetchHelper.post(`${centralApiBasePath}/v2/invites`, centralContact, options);
};

export const jobQuery = (
    request: CentralListRequest
): ObsApiExpected<ApiResponse<CentralJob[]>> => {
    const centralApiBasePath = getClientConfig().centralApiPath;

    const filterString = new EqlBuilder<CentralJobEql>()
        .where('Nodes', hasNodeEntity(request.clientCode, request.localId))
        .toURIComponent();

    return fetchHelper.get(`${centralApiBasePath}/v2/jobs?filter=${filterString}`, options);
};

export const jobCreate = (
    centralJob: Partial<CentralJob>
): ObsApiExpected<ApiResponse<CentralJob>> => {
    const centralApiBasePath = getClientConfig().centralApiPath;
    return fetchHelper.post(`${centralApiBasePath}/v2/jobs/draft`, centralJob, options);
};

export const jobAttachmentCreate = (
    jobId: number,
    file: Blob
): ObsApiExpected<ApiResponse<CentralJobAttachment>> => {
    const centralApiBasePath = getClientConfig().centralApiPath;

    const formData = new FormData();
    formData.append('file', file);

    return fetchHelper.post(
        `${centralApiBasePath}/v2/jobs/${jobId}/attachments`,
        formData,
        options
    );
};
