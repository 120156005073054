import { Contact } from '../../../../../models/contacts/contact.model';
import { ContactStatusBadge } from '../badges/ContactStatusBadge';

interface Props {
    contact: Contact;
}

export const ContactDetails = ({ contact }: Props): React.ReactElement<any, any> | null => {
    return (
        <>
            <div>
                <small className="mr-2 font-weight-bold">Status:</small>
                <ContactStatusBadge contact={contact} badgeClassName="mr-2" />
            </div>
            <div>
                <small className="mr-2 font-weight-bold">Email:</small>
                <span>{contact.email}</span>
            </div>
            {contact.phone && (
                <div>
                    <small className="mr-2 font-weight-bold">Phone:</small>
                    <span>{contact.phone}</span>
                </div>
            )}
        </>
    );
};
