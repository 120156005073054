import { getClientConfig } from 'src/clients-internal/configuration.init';
import { apiStringify } from 'src/logic/helpers/api-query-string.helper';
import {
    apiConfigurationIncludeKeys,
    ApiConfigurationResponse,
} from '../../../models/api-configuration/api-configuration.model';
import { ObsApiExpected } from '../../../models/api/service.model';
import { fetchHelper } from '../../helpers/fetch.helper';

export function all(): ObsApiExpected<ApiConfigurationResponse> {
    // when all employer site instances are on version 5.9 we can change the 'options' to 'configuration'
    // and remove 'options' from CareerHub
    const include = { include: apiConfigurationIncludeKeys };
    return fetchHelper.get(
        `${getClientConfig().careerHubBasePath}/services/employers/v1/options${apiStringify(
            include
        )}`
    );
}
