interface Props {
    children?: React.ReactNode;
}

export const ApplicationLayoutSideNav = ({
    children,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <div className="layout-main-side d-print-none">
            <div className="border-right bg w-100" style={{ overflowY: 'auto' }}>
                {children}
            </div>
        </div>
    );
};
