import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import noop from 'lodash/noop';
import { useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useClaimValues } from 'src/ui/features/authentication/hooks/claim-values.hook';
import { Markdown } from 'src/ui/shared/components/details/Markdown';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';
import {
    registerRequestAccessAsync,
    registerRequestAccessShow,
} from '../../../../../logic/features/register/register.actions';
import { RegisterRequestAccess } from '../../../../../models/register/register-request-access.model';
import { AlertWrapper } from '../../../../shared/components/bootstrap/alerts/AlertWrapper';
import { HookForm } from '../../../../shared/components/forms/HookForm';
import {
    createMaxLengthRule,
    emailPatternRule,
} from '../../../../shared/helpers/validation.helper';
import { useFormDefault } from '../../../../shared/hooks/form-default.hook';

interface Props {
    organisationId: number;
}

export const RequestAccessModal = ({
    organisationId,
}: Props): React.ReactElement<any, any> | null => {
    const { show, fetch } = useRootSelector(state => state.register.requestAccess);
    const claims = useClaimValues();
    const {
        register: {
            requestAccess: { form, title },
        },
    } = useStrings();

    const getDefaultValues = useCallback(
        () => ({
            organisationId: organisationId,
            email: claims?.email[0],
            firstName: claims?.firstName,
            lastName: claims?.lastName,
            phone: claims?.phone,
        }),
        [claims?.email, claims?.firstName, claims?.lastName, claims?.phone, organisationId]
    );

    const formMethods = useFormDefault<RegisterRequestAccess>({
        error: fetch.error,
        defaultValues: getDefaultValues(),
    });

    const { setValue } = formMethods;

    useEffect(() => {
        if (organisationId) {
            setValue('organisationId', organisationId);
        }
    }, [setValue, organisationId]);

    const dispatch = useDispatch();
    const onSubmit = useCallback(
        (data: RegisterRequestAccess) => {
            dispatch(registerRequestAccessAsync.request(data));
        },
        [dispatch]
    );

    const onCancel = useCallback(() => {
        dispatch(registerRequestAccessShow({ show: false }));
    }, [dispatch]);

    return (
        <Modal show={show} animation={false} onHide={noop}>
            <HookForm {...formMethods} onSubmit={onSubmit}>
                <HookForm.ErrorAlert error={fetch.error} />
                <Modal.Header className="flex-column">
                    <Modal.Title className="mb-2">{title}</Modal.Title>
                    {!fetch.error && (
                        <AlertWrapper variant="info" icon={faInfoCircle}>
                            <Markdown source={form.info} />
                        </AlertWrapper>
                    )}
                </Modal.Header>
                <Modal.Body>
                    <HookForm.HiddenId name="organisationId" />
                    <HookForm.Input
                        {...form.email}
                        name="email"
                        rules={{
                            required: true,
                            maxLength: createMaxLengthRule(255),
                            pattern: emailPatternRule,
                        }}
                    />
                    <HookForm.Input
                        {...form.firstName}
                        name="firstName"
                        rules={{ required: true, maxLength: createMaxLengthRule(100) }}
                    />
                    <HookForm.Input
                        {...form.lastName}
                        name="lastName"
                        rules={{ required: true, maxLength: createMaxLengthRule(200) }}
                    />
                    <HookForm.Input
                        {...form.phone}
                        name="phone"
                        rules={{ required: true, maxLength: createMaxLengthRule(30) }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <HookForm.Buttons
                        onCancel={onCancel}
                        showSpinner={fetch.loading}
                    ></HookForm.Buttons>
                </Modal.Footer>
            </HookForm>
        </Modal>
    );
};
