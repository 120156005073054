import { sortByPriorityThenAddedDate, ContentKey, SiteContext } from '@careerhub/content-shared';
import { LoaderState } from 'src/models/errors/error.model';
import { createReducer } from 'typesafe-actions';
import { contentAsync, contentSetActive } from './content.actions';

interface CollectionTokens {
    [key: string]: {
        key: string;
        tokens: string[];
    };
}
type CollectionState = Readonly<{
    fetchState: LoaderState;
    loaded: boolean;
    activeCollectionId?: string;
    context: SiteContext;
    collectionTokens: CollectionTokens;
    firstCollectionId?: string;
}>;

export type ContentState = Readonly<{ [key in ContentKey]: CollectionState }>;

const emptyContext: SiteContext = {
    site: null,
    sections: [],
    components: [],
    collections: [],
};

const initialState: ContentState = {
    employerContent: {
        fetchState: { loading: false },
        loaded: false,
        context: emptyContext,
        collectionTokens: {},
    },
    employerProtected: {
        fetchState: { loading: false },
        loaded: false,
        context: emptyContext,
        collectionTokens: {},
    },
};

const contentReducer = createReducer(initialState)
    .handleAction(contentSetActive, (state, action) => ({
        ...state,
        [action.payload.key]: {
            ...state[action.payload.key],
            activeCollectionId: action.payload.activeCollectionId,
        },
    }))
    .handleAction(contentAsync.request, (state, action) => {
        const collectionState: CollectionState = {
            ...state[action.payload.key],
            fetchState: { loading: true },
        };

        return {
            ...state,
            [action.payload.key]: collectionState,
        };
    })
    .handleAction(contentAsync.failure, (state, action) => {
        const collectionState: CollectionState = {
            ...state[action.meta.key],
            fetchState: { loading: false, error: action.payload },
            loaded: true,
        };

        return {
            ...state,
            [action.meta.key]: collectionState,
        };
    })
    .handleAction(contentAsync.success, (state, action) => {
        // set the firstId to be the "home key";
        const ordered = action.payload.collections.sort((a, b) =>
            sortByPriorityThenAddedDate(a, b, action.payload.site?.collectionPriority || [])
        );

        const collectionState: CollectionState = {
            ...state[action.meta.key],
            fetchState: { loading: false },
            context: action.payload,
            firstCollectionId: ordered[0]?.id,
            loaded: true,
        };

        return {
            ...state,
            [action.meta.key]: collectionState,
        };
    });

export default contentReducer;
