import { formatISO } from 'date-fns';
import pick from 'lodash/pick';
import { useMemo } from 'react';
import { LoaderState } from 'src/models/errors/error.model';
import { EventRequest, EventRequestPublish } from 'src/models/events/event-request.model';
import { HookForm } from 'src/ui/shared/components/forms/HookForm';
import { createMaxLengthRule } from 'src/ui/shared/helpers/validation.helper';
import { useFormDefault } from 'src/ui/shared/hooks/form-default.hook';

interface Props {
    onSubmit: (data: EventRequestPublish) => void;
    onCancel?: () => void;
    eventRequest: EventRequest;
    state: LoaderState;
}

export const EventRequestPublishForm = ({
    onSubmit,
    onCancel,
    eventRequest,
    state,
}: Props): React.ReactElement<any, any> | null => {
    const defaultValues = useMemo(() => {
        const picked = pick(eventRequest, [
            'id',
            'contactId',
            'additionalNote',
            'targetingNote',
            'publish',
        ]);

        picked.publish = picked.publish || formatISO(new Date());

        return picked;
    }, [eventRequest]);

    const formMethods = useFormDefault<EventRequestPublish>({
        defaultValues,
        error: state.error,
    });

    return (
        <HookForm {...formMethods} onSubmit={onSubmit}>
            <HookForm.HiddenId />
            <HookForm.HiddenId name="publish" forceRender />
            <HookForm.Row>
                <HookForm.Col xs={12} md={6}>
                    <HookForm.ContactSelect
                        name="contactId"
                        label="Administrative contact"
                        rules={{ required: true }}
                    />
                </HookForm.Col>
            </HookForm.Row>

            <HookForm.Textarea
                minRows={3}
                name="targetingNote"
                label="Who is the audience of your event?"
                rules={{ maxLength: createMaxLengthRule(500) }}
            />
            <HookForm.Textarea
                minRows={3}
                name="additionalNote"
                label="Additional comments or questions"
                rules={{ maxLength: createMaxLengthRule(4000) }}
            />

            <HookForm.Buttons
                showSpinner={state.loading}
                onCancel={onCancel}
                submitText="Submit Request"
            />
        </HookForm>
    );
};
