import { ErrorNormalized } from 'src/models/errors/error.model';

interface Props {
    error?: ErrorNormalized;
}

export const Initialization503ErrorMessage = ({
    error,
}: Props): React.ReactElement<any, any> | null => {
    if (!error || error.status !== 503) {
        return null;
    }

    return (
        <div className="px-5 pb-5 text-center">
            <h2>Service unavailable</h2>
            <p>
                This service is temporarily unavailable and might be undergoing maintenance. Please
                try again in a few minutes.
            </p>
        </div>
    );
};
