import omit from 'lodash/omit';
import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { asyncEpicBase, asyncEpicStandard } from 'src/logic/helpers/epics/async.epic-helper';
import { isActionOf } from 'typesafe-actions';
import { RootEpic } from '../../epic.root-index';
import * as actions from '../central.actions';

export const centralJobCreateAsyncEpic = asyncEpicBase(
    actions.centralJobCreateAsync,
    (services, action) => services.central.api.jobCreate(action.payload.job),
    {
        success: result => actions.centralJobCreateAsync.success(result.json),
        failure: (error, ra) => actions.centralJobCreateAsync.failure(error, ra.payload),
    }
);

// Central can handle incomplete jobs, so there's no reason (and it's horrible for user experience)
// to fail at this point. If Central responds with a 400, we can just ignore the offending properties
// and push the incomplete job to Cental. Central will handle the communication to the user concerning
// the missing properties.
export const centralJobCreateRetryEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(actions.centralJobCreateAsync.failure)),
        filter(action => services.central.helper.shouldRetryJobFailure(action)),
        mergeMap(action => {
            const problematicKeys = Object.keys(action.payload.validationErrors || {}).map(key => {
                const index = key.indexOf('[');
                return index === -1 ? key : key.substring(0, index);
            });
            const partialJob = omit(action.meta.job, problematicKeys);
            return of(
                actions.centralJobCreateAsync.request({ job: partialJob, isInitialAttempt: false })
            );
        })
    );
};

export const centralJobListAsyncEpic = asyncEpicStandard(
    actions.centralJobListAsync,
    (services, action) => services.central.api.jobQuery(action.payload)
);
