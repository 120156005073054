import { useFormError } from 'src/ui/shared/hooks/form-error.hook';
import { useInputHasFocus } from '../../../hooks/input-has-focus.hook';
import { useMaxLengthCount } from '../../../hooks/max-length-count.hook';
import { HookFormRules } from '../HookForm';

interface Props {
    name: string;
    errorName: string | undefined;
    rules?: HookFormRules;
    removeMaxCount?: boolean;
}

export const MaxLengthDisplay = ({
    name,
    errorName,
    rules,
    removeMaxCount,
}: Props): React.ReactElement<any, any> | null => {
    const { length, greater, maxLength } = useMaxLengthCount(errorName || name, rules);

    const { hasFocus } = useInputHasFocus(errorName || name);

    const hasError = useFormError(errorName || name);

    if (!maxLength || removeMaxCount) {
        return null;
    }

    return (
        <>
            <span className={`position-relative ${hasError ? 'is-invalid' : ''}`}>
                <span
                    className={`${greater ? 'text-danger' : 'text-muted'} ${
                        hasFocus ? 'd-inline' : 'd-none'
                    }`}
                >
                    {length}/{maxLength}
                </span>
            </span>
        </>
    );
};
