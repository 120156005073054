import { useDispatch } from 'react-redux';
import { entityNoteCreateAsync } from 'src/logic/features/entity-notes/entity-note.actions';
import { EntityNote } from 'src/models/entity-notes/entity-note.model';
import { CardWrapper } from 'src/ui/shared/components/bootstrap/cards/CardWrapper';
import { useActiveEntity } from 'src/ui/shared/hooks/active-entity.hook';
import { useFormButton } from 'src/ui/shared/hooks/form-button.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { EventChangeRequestForm } from '../components/change-requests/EventChangeRequestForm';
import { EventDetailsPageContent } from '../components/details/EventDetailsPageContent';

export const EventChangeRequestAddPage = (): React.ReactElement<any, any> | null => {
    const { id: eventId } = useActiveEntity(state => state.eventUnions);
    const state = useRootSelector(state => state.entityNotes.create);
    const dispatch = useDispatch();

    const onSubmit = (data: EntityNote) => {
        dispatch(entityNoteCreateAsync.request(data));
    };

    const { onCancel } = useFormButton();

    if (!eventId) {
        // should be impossible
        return null;
    }

    return (
        <EventDetailsPageContent>
            <CardWrapper>
                <EventChangeRequestForm
                    eventId={eventId}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    state={state}
                />
            </CardWrapper>
        </EventDetailsPageContent>
    );
};
