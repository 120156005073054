import { useEffect, useRef, useState } from 'react';

export const useInputHasFocus = (name: string) => {
    const hookIsNotMountedRef = useRef(false);
    const [listenersReady, setListenersReady] = useState(false);
    const [hasFocus, setHasFocus] = useState(false);
    const [activeName, setActiveName] = useState(
        document.activeElement?.attributes?.getNamedItem('name')?.value
    );

    useEffect(() => {
        const onFocus = (event: FocusEvent) => {
            if (!hookIsNotMountedRef.current) {
                setActiveName((event.target as Element)?.attributes?.getNamedItem('name')?.value);
            }
        };
        const onBlur = () => {
            if (!hookIsNotMountedRef.current) {
                setActiveName(undefined);
            }
        };

        window.addEventListener('focus', onFocus, true);
        window.addEventListener('blur', onBlur, true);

        setListenersReady(true);

        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
            hookIsNotMountedRef.current = true;
        };
    }, []);

    useEffect(() => {
        if (!listenersReady) {
            return;
        }

        setHasFocus(activeName === name);
    }, [activeName, listenersReady, name]);

    return {
        hasFocus,
    };
};
