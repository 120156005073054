import { BulletPointDetails } from 'src/ui/shared/components/details/BulletPointDetails';
import { Organisation } from '../../../../../models/organisation/organisation.model';
import { Details } from '../../../../shared/components/details/Details';
import { OrganisationDetailsHeader } from './OrganisationDetailsHeader';
import { OrganisationDetailsProperties } from './OrganisationDetailsProperties';

interface Props {
    organisation: Organisation;
}

export const OrganisationDetails = ({
    organisation,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <Details
            header={<OrganisationDetailsHeader organisation={organisation} />}
            properties={<OrganisationDetailsProperties organisation={organisation} />}
        >
            <h2 className="font-weight-normal">Details</h2>
            <BulletPointDetails source={organisation.description} />
        </Details>
    );
};
