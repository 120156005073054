import {
    SocialProperty,
    SOCIAL_TYPE_META,
} from '../../../../../models/organisation/social-property.model';
import { DetailsProperty } from '../../../../shared/components/details/DetailsProperty';
import { Icon } from '../../../../shared/components/icon/Icon';

interface Props {
    item: SocialProperty;
}

export const SocialPropertyDisplay = ({ item }: Props): React.ReactElement<any, any> | null => {
    const meta = SOCIAL_TYPE_META[item.socialType];

    return (
        <DetailsProperty label={meta.label} collapse={true}>
            <Icon icon={meta.icon} iconClassName="fa-w-16">
                <a href={`${meta.linkPrefix}${item.value}`} target="_blank" rel="noreferrer">
                    {item.value}
                </a>
            </Icon>
        </DetailsProperty>
    );
};
