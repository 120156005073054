import { useContext } from 'react';
import { createMaxLengthRule, urlPatternRule } from 'src/ui/shared/helpers/validation.helper';
import { useApiIncrement } from 'src/ui/shared/hooks/apiIncrement.hook';
import { HookForm } from '../../HookForm';
import { EventLocationControlContext, EventLocationForm } from './EventLocationControl';

const onlineUrlName: keyof EventLocationForm = 'onlineUrl';
const onlineInstructionsName: keyof EventLocationForm = 'onlineInstructions';

export const EventLocationOnlineControl = (): React.ReactElement<any, any> | null => {
    const controlName = useContext(EventLocationControlContext);
    const apiIncrement = useApiIncrement();

    return (
        <>
            <HookForm.Input
                label="Online link"
                name={`${controlName}.${onlineUrlName}`}
                rules={{
                    required: true,
                    pattern: urlPatternRule,
                }}
            />
            <HookForm.Textarea
                minRows={3}
                label="Online instructions"
                name={`${controlName}.${onlineInstructionsName}`}
                rules={
                    apiIncrement >= 2
                        ? { maxLength: createMaxLengthRule(4000) }
                        : { required: true, maxLength: createMaxLengthRule(4000) }
                }
            />
        </>
    );
};
