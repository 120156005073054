import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useClient } from 'src/ui/shared/hooks/client.hook';

export const AustraliaCentralAdvertisementInfo = (): React.ReactElement<any, any> | null => {
    const { centralMarketingPath, centralPricingPath } = useClient();

    return (
        <div className="d-flex flex-column">
            <div>
                <h1 className="mb-3" style={{ fontSize: '20px' }}>
                    Post your job to more universities
                </h1>
                <p>3+ million students and graduates from 37 Australian Institutions.</p>
                <div className="mb-2 d-flex align-items-center flex-wrap">
                    <a
                        href={centralMarketingPath}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-link-light pl-0"
                    >
                        <Icon icon={faExternalLinkAlt}>How it works</Icon>
                    </a>
                    <a
                        href={centralPricingPath}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-link-light position-relative"
                    >
                        <Icon icon={faExternalLinkAlt}>Pricing</Icon>
                    </a>
                    <div>
                        <img
                            style={{ width: '22px' }}
                            src="https://cdn.content.careerhub.com.au/_core/wrapped-gift.png"
                        />
                        <span className="ml-2">First job free</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
