import { JobDetailsBreadcrumb } from '../breadcrumbs/JobDetailsBreadcrumb';
import { JobListBreadcrumb } from '../breadcrumbs/JobListBreadcrumb';
import { PageTitle } from '../../../../shared/components/page-components/PageTitle';
import { Job } from '../../../../../models/jobs/job.model';
import { TitleLoader } from '../../../../shared/components/loader/TitleLoader';

interface Props {
    job?: Job;
}

export const JobChangeRequestTitle = ({ job }: Props): React.ReactElement<any, any> | null => {
    return (
        <div className="d-flex">
            <PageTitle>
                <JobListBreadcrumb />
                {job && (
                    <>
                        <JobDetailsBreadcrumb job={job} />
                        Change Request
                    </>
                )}
            </PageTitle>
            {!job && <TitleLoader />}
        </div>
    );
};
