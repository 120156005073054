import { Link } from 'react-router-dom';

interface Props {
    to?: string;
    children?: React.ReactNode;
    className?: string;
}

export const ListGroupItemTitle = ({
    children,
    to,
    className,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <h4 className={`d-inline mb-0 ${className || ''}`}>
            {to ? (
                <Link className="mr-2" to={to}>
                    {children}
                </Link>
            ) : (
                children
            )}
        </h4>
    );
};
