import { CardWrapper } from 'src/ui/shared/components/bootstrap/cards/CardWrapper';
import { ConfirmationTitle } from 'src/ui/shared/components/confirmation/ConfirmationTitle';
import { Markdown } from 'src/ui/shared/components/details/Markdown';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { ApplicationPageContent } from 'src/ui/shared/components/page-components/ApplicationPageContent';
import { useActiveEntity } from 'src/ui/shared/hooks/active-entity.hook';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';

export const EventPublishConfirmationPage = (): React.ReactElement<any, any> | null => {
    const { entity, fetch } = useActiveEntity(state => state.eventUnions);
    const {
        event: {
            publishConfirmation: { title, message },
        },
    } = useStrings();

    return (
        <ApplicationPageContent>
            <Loader useCard={true} state={fetch}>
                {entity && entity.eventRequest && (
                    <CardWrapper className="mb-2">
                        <ConfirmationTitle title={title} />
                        <Markdown source={message} />
                    </CardWrapper>
                )}
            </Loader>
        </ApplicationPageContent>
    );
};
