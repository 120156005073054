import { useResizeDetector } from 'react-resize-detector';
import { ContentScrollspyNav } from 'src/ui/features/content/components/ContentScrollspyNav';
import { ContentMenu } from '../../../../features/content/components/ContentMenu';
import { useContent } from '../../../../features/content/hooks/content.hook';
import { SessionContainer } from '../../../../features/session/components/SessionContainer';
import { Logo } from '../../logo/Logo';
import { MobileNav } from '../mobile-navigation/MobileNavigation';
import { UserNav } from '../user-navigation/UserNav';

export const ContentTopNav = (): React.ReactElement<any, any> | null => {
    const { count } = useContent('employerContent');
    const { ref, height } = useResizeDetector({ handleWidth: false, refreshMode: 'debounce' });

    return (
        <>
            <div className="content-nav-master" ref={ref}>
                <div className="align-items-center h-100 mx-auto justify-content-center hero-width d-none d-md-flex">
                    <div className="h-100 p-2 mr-2 logo-container">
                        <Logo />
                    </div>
                    <div className="mr-auto content-nav-dropdown-container">
                        <div className="d-flex align-items-end">
                            {count > 1 && (
                                <>
                                    <ContentMenu />
                                    <div className="d-none d-xl-block">
                                        <ContentScrollspyNav contentKey="employerContent" />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="content-nav-login-container">
                        <UserNav />
                    </div>
                </div>

                {/* Mobile nav */}
                <div className="d-md-none">
                    <div className="d-flex align-items-center py-1 h-100">
                        <div className="flex-grow-1 mr-1 ml-4">
                            <div className="mb-2 logo-container">
                                <Logo />
                            </div>
                            {count > 1 && <ContentMenu />}
                        </div>
                        <div>
                            <MobileNav />
                        </div>
                    </div>
                    <div className="hero-width mx-auto">
                        <SessionContainer />
                    </div>
                </div>
            </div>
            <div
                className="content-nav-slave"
                style={{ height: height ? `${height}px` : undefined }}
            ></div>
        </>
    );
};
