import flatten from 'lodash/flatten';
import flow from 'lodash/flow';
import invertBy from 'lodash/invertBy';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';

// import {
//     CentralCategoryMapping,
// } from 'src/models/central/central-configuration.model';
import {
    Geolocation as CentralGeolocation,
    Location as CentralLocation,
    Node as CentralNode,
    NodeCategory as CentralNodeCategory,
} from '@symplicity/central-types';
import { LocalCategoryMapV2 } from 'src/models/central/central-mapping.model';
import { GeolocationModel } from 'src/models/locations/geolocation.model';
import { LocationModel } from 'src/models/locations/location.model';

interface CentralCategoryMapping {
    [key: string]: CentralNodeCategory[];
}

const invertMappings = (centralCategories: CentralCategoryMapping | undefined) => {
    return invertBy(centralCategories, value => value.map(i => i.id));
};

export const mapCentralCategoriesToLocalIds = (centralNode: CentralNode): LocalCategoryMapV2 => {
    const localCategoryMap: LocalCategoryMapV2 = {
        typesOfWork: invertMappings(centralNode.employmentTypeIdMappings),
        industries: invertMappings(centralNode.industryIdMappings),
        occupations: invertMappings(centralNode.occupationIdMappings),
    };

    return localCategoryMap;
};

export const getCentralMappingsV2 = (
    localMap: LocalCategoryMapV2,
    key: keyof LocalCategoryMapV2,
    ids: number[]
) => {
    return flow(flatten, uniq)(ids.map(id => localMap[key][id]?.map(i => Number(i)) || []));
};

export const mapCentralGeolocation = (
    geolocation: GeolocationModel | null | undefined
): CentralGeolocation | undefined =>
    geolocation
        ? {
              east: geolocation.right,
              north: geolocation.top,
              south: geolocation.bottom,
              west: geolocation.left,
          }
        : undefined;

export const mapCentralLocation = (location: LocationModel): CentralLocation => ({
    ...pick(location, 'street', 'city', 'region', 'postcode'),
    countryCode: location.countryCode!,
    geolocation: mapCentralGeolocation(location.geolocation),
});
