import { Route, Switch } from 'react-router-dom';
import { FormRedirectPage } from '../../ui/features/forms/pages/FormRedirectPage';
import { FormSubmissionListPage } from '../../ui/features/forms/pages/FormSubmissionListPage';
import { formPaths } from './paths/form.paths';

export const FormRouter = () => (
    <Switch>
        <Route path={formPaths.redirectSubmission} component={FormRedirectPage} />
        <Route path={formPaths.redirect} component={FormRedirectPage} />
        <Route path={formPaths.submissions} component={FormSubmissionListPage} />
    </Switch>
);
