import { useMemo } from 'react';
import { useWorkGroups } from './work-groups.hook';

export const useWorkGroup = (id: number | undefined) => {
    const { workgroups, isDefault } = useWorkGroups();

    const workgroup = useMemo(() => {
        return workgroups?.find(w => w.id === id);
    }, [id, workgroups]);

    return {
        // the workgroup object did not always have the "isDefault" property
        isDefault: isDefault(id || -1),
        name: workgroup?.name,
    };
};
