import { Route, Switch } from 'react-router-dom';
import { OrganisationDetailsPage } from '../../ui/features/organisation/pages/OrganisationDetailsPage';
import { OrganisationEditPage } from '../../ui/features/organisation/pages/OrganisationEditPage';
import { organisationPaths } from './paths/organisation.paths';

export const OrganisationRouter = () => {
    const { edit, details } = organisationPaths;

    return (
        <Switch>
            <Route path={edit} component={OrganisationEditPage} />
            <Route path={details} component={OrganisationDetailsPage} />
        </Switch>
    );
};
