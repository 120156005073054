import { asapScheduler, forkJoin, Observable, scheduled } from 'rxjs';
import { mergeAll } from 'rxjs/operators';
import { getClientConfig } from 'src/clients-internal/configuration.init';
import { EntityRequest } from '../../../models/api/request.model';
import { ApiResponse } from '../../../models/api/response.model';
import { ObsApiExpected } from '../../../models/api/service.model';
import { AttachmentCreate } from '../../../models/attachments/attachment-create.model';
import { fetchHelper } from '../../helpers/fetch.helper';

// Note: this is also used in the attachment download helper file
export const attachmentBasePath = () =>
    `${getClientConfig().careerHubBasePath}/services/employers/v1/attachments`;

export function add(entityId: number, files: AttachmentCreate[]): Observable<unknown> {
    const requests$ = files.map(file => {
        const formData = new FormData();
        formData.append('title', file.title);
        formData.append('file', file.file);

        return fetchHelper.fetch(`${attachmentBasePath()}/job/${entityId}`, 'POST', formData);
    });

    return forkJoin([scheduled(requests$, asapScheduler).pipe(mergeAll(3))]);
}

export function deleteStoredFile(id: number): ObsApiExpected<ApiResponse<EntityRequest>> {
    return fetchHelper.del(`${attachmentBasePath()}/${id}`);
}

export function downloadAsBlob(id: number): ObsApiExpected<unknown> {
    return fetchHelper.get(`${attachmentBasePath()}/${id}`, {
        expectBlobResponse: true,
        credentials: 'include',
    });
}
