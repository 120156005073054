import { RegisterName } from 'src/models/register/register-name.model';
import { appStringify } from '../router.helper';

export const registerPaths = {
    name: '/register/name',
    similar: '/register/similar',
    requestAccess: '/register/request-access',
    organisation: '/register/organisation',
    contact: '/register/contact',
    individual: '/register/individual',
    start: '/register',
};

export const registerPathCreator = {
    requestAccessList: (params?: RegisterName) => `/register/request-access${appStringify(params)}`,
};
