import { ActionsObservable, StateObservable } from 'redux-observable';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, skipUntil, take } from 'rxjs/operators';
import { RootAction } from 'src/logic/features/action.root-index';
import { authenticationComplete } from 'src/logic/features/authentication/authentication.actions';
import { RootState } from 'src/logic/features/reducer.root-index';
import { ActionCreator, isActionOf, isOfType, PayloadActionCreator } from 'typesafe-actions';
import { appInitComplete } from '../../features/initialization/initialization.actions';

export const skipUntilAction = (action: ActionCreator) => (action$: Observable<RootAction>) =>
    action$.pipe(skipUntil(action$.pipe(filter(isActionOf(action)))));

export const skipUntilAppInitComplete = (action$: Observable<RootAction>) =>
    skipUntilAction(appInitComplete)(action$);

export const delayActionUntil = <TActionCreator extends ActionCreator>(
    action$: ActionsObservable<RootAction>,
    delayUntilAction: ActionCreator,
    filterOnAction: TActionCreator
): Observable<ReturnType<TActionCreator>> =>
    combineLatest([
        action$.pipe(filter(isActionOf(filterOnAction))),
        action$.pipe(filter(isActionOf(delayUntilAction), take(1))),
    ]).pipe(map(result => result[0]));

export function delayActionUntilAppInit<TActionCreator extends ActionCreator>(
    action$: ActionsObservable<RootAction>,
    filterOnAction: TActionCreator
): Observable<ReturnType<TActionCreator>> {
    return delayActionUntil(action$, appInitComplete, filterOnAction);
}

export const delayTypeUntil = <TType extends string>(
    action$: ActionsObservable<RootAction>,
    delayUntilAction: ActionCreator,
    type: TType
): Observable<RootAction & { type: TType }> => {
    return combineLatest([
        action$.pipe(filter(isOfType(type))),
        action$.pipe(filter(isActionOf(delayUntilAction), take(1))),
    ]).pipe(map(result => result[0]));
};

export function delayTypeUntilAppInit<TType extends string>(
    action$: ActionsObservable<RootAction>,
    type: TType
): Observable<RootAction & { type: TType }> {
    return delayTypeUntil(action$, appInitComplete, type);
}

// this is a bit misleading as it doesn't actually "delay", it will just filter
// the action away, but authenticating always causes a page-reload, so it doesn't actually matter.
export function delayActionUntilAuthenticated<
    TActionCreator extends PayloadActionCreator<any, any>
>(
    action$: ActionsObservable<RootAction>,
    state$: StateObservable<RootState>,
    filterOnAction: TActionCreator
): Observable<ReturnType<TActionCreator>> {
    return delayActionUntil(action$, authenticationComplete, filterOnAction).pipe(
        filter(() => state$.value.authentication.isCareerHubAuthenticated)
    );
}

export function delayTypeUntilAuthenticated<TType extends string>(
    action$: ActionsObservable<RootAction>,
    state$: StateObservable<RootState>,
    type: TType
): Observable<RootAction & { type: TType }> {
    return delayTypeUntil(action$, authenticationComplete, type).pipe(
        filter(() => state$.value.authentication.isCareerHubAuthenticated)
    );
}
