import { ListGroup } from 'react-bootstrap';
import { EventRequest } from 'src/models/events/event-request.model';
import { eventPathCreator } from 'src/routes/employer/paths/event.paths';
import { WorkGroupLabel } from 'src/ui/features/work-groups/components/WorkGroupLabel';
import { ListGroupItemHeader } from 'src/ui/shared/components/bootstrap/lists/ListGroupItemHeader';
import { EventRequestActionDropdown } from '../actions/EventRequestActionDropdown';
import { EventRequestStatusBadge } from '../badges/EventRequestStatusBadge';
import { EventStartEndDisplay } from '../details/EventStartEndDisplay';

interface Props {
    eventRequest: EventRequest;
}

export const EventRequestListItem = ({
    eventRequest,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <ListGroup.Item>
            <ListGroupItemHeader>
                <ListGroupItemHeader.Title to={eventPathCreator.details(eventRequest.id)}>
                    {eventRequest.title}
                </ListGroupItemHeader.Title>
                <EventRequestStatusBadge eventRequest={eventRequest} />
                <ListGroupItemHeader.Actions>
                    <EventRequestActionDropdown eventRequest={eventRequest} size="xs" />
                </ListGroupItemHeader.Actions>
            </ListGroupItemHeader>

            <div className="mb-1">
                <EventStartEndDisplay
                    start={eventRequest.start}
                    end={eventRequest.end}
                    timeZoneId={eventRequest.timeZoneId}
                />
            </div>
            <div>{eventRequest.summary}</div>
            {eventRequest.workGroupId && (
                <WorkGroupLabel className="mt-2 d-block" workGroupId={eventRequest.workGroupId} />
            )}
        </ListGroup.Item>
    );
};
