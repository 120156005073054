import { Button, Modal } from 'react-bootstrap';
import { BaseModal } from './BaseModal';

interface Props {
    onConfirm: () => any;
    onCancel: () => any;
    message?: string;
    title?: string;
}

export const ConfirmModal = ({
    onCancel,
    onConfirm,
    title = 'Confirm',
    message = 'Are you sure?',
}: Props): React.ReactElement<any, any> | null => {
    return (
        <BaseModal>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={onCancel}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="danger" className="ml-0" onClick={onConfirm}>
                    OK
                </Button>
            </Modal.Footer>
        </BaseModal>
    );
};
