import { useMemo } from 'react';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

export const useIsPrimaryContact = (id: number) => {
    // you can't trust the data on the actual contact object, as it might be stale
    const primaryId = useRootSelector(state => state.contacts.primaryContactId);
    const isPrimaryContact = useMemo(() => {
        return primaryId === id;
    }, [primaryId, id]);

    return isPrimaryContact;
};
