import { EntitySingleState } from 'src/models/store-models/entity-single-state.model';
import { WorkGroup } from 'src/models/work-groups/work-group.model';
import { createReducer } from 'typesafe-actions';
import * as actions from './work-group.actions';
import { singleEntityState } from '../../helpers/initial-state.helper';
import { singleReducerHandler } from '../../reducer-handlers/single.reducer-handler';

export type WorkGroupState = EntitySingleState<WorkGroup[]> & {
    queryWorkGroupId?: number;
};

const initialState: WorkGroupState = {
    ...singleEntityState,
};

const workGroupReducer = createReducer(initialState)
    .handleAction(actions.workGroupSetDefault, (state, action) => ({
        ...state,
        queryWorkGroupId: action.payload.id,
    }))
    .handleAction(actions.workGroupListAsync.request, singleReducerHandler.request)
    .handleAction(actions.workGroupListAsync.success, singleReducerHandler.success)
    .handleAction(actions.workGroupListAsync.failure, singleReducerHandler.failure)
    .handleAction(actions.workGroupListAsync.cancel, singleReducerHandler.cancel)

    // I suppose we should keep the query id in case the user selects the wrong employer initially
    .handleAction(actions.workGroupResetState, state => ({
        ...initialState,
        queryWorkGroupId: state.queryWorkGroupId,
    }));

export default workGroupReducer;
