import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import Select, { createFilter, ValueType } from 'react-select';
import { formControlIdentifier } from 'src/ui/shared/identifiers/form-control.identifier';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { HookControlProps } from '../../HookForm';
import { ControlFormGroup } from '../../shared/ControlFormGroup';
import { useReactSelect } from './react-select.hooks';

export interface TypeaheadOption {
    label: React.ReactNode;
    value: string;
    tokens: string[];
}

export interface TypeaheadControlProps extends HookControlProps {
    options: TypeaheadOption[];
    filter?: ReturnType<typeof createFilter>;
    placeholder?: string;
    onChange?: (value: ValueType<TypeaheadOption, false>) => void;
}

export const TypeaheadControl = (
    props: TypeaheadControlProps
): React.ReactElement<any, any> | null => {
    const { name, rules, tabIndex, disabled, options, errorName, filter, placeholder } = props;

    const {
        register,
        setValue,
        formState: { errors },
        watch,
        control,
    } = useFormContext();
    const formValue = watch(name) as string | undefined;

    useDeepCompareEffect(() => {
        register(name, rules);
    }, [register, name, rules]);

    const { onBlur, ref } = useReactSelect(name, { isMulti: false });

    const getValueOption = useCallback(
        (list: TypeaheadOption[]) => {
            return list.find(c => c.value === formValue) || null;
        },
        [formValue]
    );

    function onChange(option: ValueType<TypeaheadOption, false> | undefined) {
        setValue(name, option ? option.value : undefined, {
            shouldDirty: true,
            shouldValidate: true,
        });
        props.onChange?.(option);
    }

    const isInvalid = !!errors[errorName ?? name];
    const innerRef = control._fields[name];

    return (
        <ControlFormGroup {...props}>
            <Select
                ref={ref}
                isDisabled={disabled || innerRef?._f.ref.disabled}
                tabIndex={tabIndex ? tabIndex.toString() : null}
                name={name}
                value={getValueOption(options)}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                options={options}
                filterOption={filter}
                className={`react-select-container ${formControlIdentifier.reactSelect(name)} ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                classNamePrefix="react-select"
            />
        </ControlFormGroup>
    );
};
