import { EntityRequest } from 'src/models/api/request.model';
import { ApiResponse } from 'src/models/api/response.model';
import { EntityNote } from 'src/models/entity-notes/entity-note.model';
import { ErrorNormalized } from 'src/models/errors/error.model';
import { createAsyncAction } from 'typesafe-actions';

export const entityNoteCreateAsync = createAsyncAction(
    'ENTITY_NOTE_CREATE:REQUEST',
    'ENTITY_NOTE_CREATE:SUCCESS',
    'ENTITY_NOTE_CREATE:FAILURE'
)<EntityNote, ApiResponse<EntityNote>, [ErrorNormalized, EntityNote]>();

export const entityNoteUpdateAsync = createAsyncAction(
    'ENTITY_NOTE_UPDATE:REQUEST',
    'ENTITY_NOTE_UPDATE:SUCCESS',
    'ENTITY_NOTE_UPDATE:FAILURE'
)<EntityNote, ApiResponse<EntityNote>, [ErrorNormalized, EntityNote]>();

export const entityNoteDeleteAsync = createAsyncAction(
    'ENTITY_NOTE_DELETE:REQUEST',
    'ENTITY_NOTE_DELETE:SUCCESS',
    'ENTITY_NOTE_DELETE:FAILURE'
)<EntityRequest, ApiResponse<EntityNote>, [ErrorNormalized, EntityRequest]>();
