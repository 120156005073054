import { useMemo } from 'react';
import { Badge } from 'react-bootstrap';
import { CareerHubEvent } from 'src/models/events/event.model';

interface Props {
    event: CareerHubEvent;
}

export const EventStatusBadge = ({ event }: Props): React.ReactElement<any, any> | null => {
    const status = event.status;

    const badgeClass = useMemo(() => {
        switch (event.status) {
            case 'Upcoming':
                return 'outline-secondary';
            case 'Active':
                return 'outline-success';
            case 'Expired':
            case 'Cancelled':
                return 'outline-danger';
            default:
                return 'outline-secondary';
        }
    }, [event.status]);

    return (
        <Badge variant={badgeClass} className="font-weight-normal">
            {status}
        </Badge>
    );
};
