import { getClientConfig } from 'src/clients-internal/configuration.init';
import { fetchHelper } from 'src/logic/helpers/fetch.helper';
import { ApiListIncludeResponse } from 'src/models/api/response.model';
import { ObsApiExpected } from 'src/models/api/service.model';
import { EndorementInclude, Endorsement } from 'src/models/endorsements/endorsement.model';

const basePath = () => `${getClientConfig().careerHubBasePath}/services/employers/v1/endorsements`;

export const all = (): ObsApiExpected<ApiListIncludeResponse<Endorsement, EndorementInclude>> => {
    return fetchHelper.get(`${basePath()}?isArchived=false`);
};
