import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { Icon } from '../../../shared/components/icon/Icon';
import { ThinPageContent } from '../../../shared/components/page-components/ThinPageContent';

export const ImpersonatePage = (): React.ReactElement<any, any> | null => {
    const { impersonateError, activeIdentity, isImpersonation } = useRootSelector(
        state => state.authentication
    );

    return (
        <ThinPageContent>
            {impersonateError && (
                <span className="text-danger">
                    <Icon icon={faExclamationTriangle}>{impersonateError.message}</Icon>
                </span>
            )}
            <div className="text-center">
                {activeIdentity && isImpersonation && (
                    <>
                        You are <span className="font-weight-bold">impersonating</span>{' '}
                        {activeIdentity.firstName} {activeIdentity.lastName}.
                    </>
                )}
                {activeIdentity && !isImpersonation && (
                    <>
                        You are {activeIdentity.firstName} {activeIdentity.lastName}.
                    </>
                )}
                {!activeIdentity && !impersonateError && <>Loading...</>}
            </div>
        </ThinPageContent>
    );
};
