import { ZodTypeAny, z } from 'zod';

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
    if (issue.code === z.ZodIssueCode.invalid_string) {
        if (issue.validation === 'email') {
            return { message: 'Please provide a valid email' };
        }
        if (issue.validation === 'url') {
            return { message: 'Please provide a valid http, or https URL' };
        }
    }

    if (issue.code === z.ZodIssueCode.invalid_type) {
        if (issue.expected === 'object' && issue.received === 'undefined') {
            return { message: 'This field is required' };
        }
    }

    return { message: ctx.defaultError };
};

z.setErrorMap(customErrorMap);

const stringMin = () => z.string().min(1, 'This field is required');
const arrayMin = <T extends ZodTypeAny>(schema: T) =>
    z.array(schema).nonempty('This field is required');

export const zEx = {
    stringMin,
    arrayMin,
};
