import { UserSettings } from 'oidc-client';
import { createReducer } from 'typesafe-actions';
import { identityHelper } from '../../../ui/features/authentication/helpers/identity.helper';
import { IdentityType } from '../../../models/authentication/identity.model';
import { ErrorNormalized, LoaderState } from '../../../models/errors/error.model';
import * as actions from './authentication.actions';
import { userInfoAsync } from '../email-claims/email-claims.actions';
import { registerIndividualAsync, registerOrganisationAsync } from '../register/register.actions';
import { OidcEventLog } from 'src/models/authentication/oidc-event.model';

export type AuthenticationState = Readonly<{
    logoutSelected: boolean;

    initStarted: boolean;
    initComplete: boolean;
    initCompleteRedirectTo: string;
    initCompleteHasRedirected: boolean;

    identitySetUserFetching: boolean;
    identityListState: LoaderState;

    centralIdentityIsRedirecting: boolean;

    // note: isAuthenticated indicated that the user is authenticated to CAREERHUB
    // use the idToken if you need to know whether or not the user has authenticated with Identity
    isCareerHubAuthenticated: boolean;
    error?: ErrorNormalized;

    activeIdentity: IdentityType | undefined;
    identities: IdentityType[];
    hasIndividualIdentity: boolean;

    expires: string | undefined;
    expiresSoon: boolean;
    expiresSoonDismissed: boolean;

    // a bit erroneous as this is set completely off the initial route on app load
    // it assumes that the user is already logged in, and this should be fine as if they aren't it should error.
    isImpersonation: boolean;
    impersonateError?: ErrorNormalized;

    oidcUser: UserSettings | null;
    oidcEventLog: OidcEventLog[];
}>;

const initialState: AuthenticationState = {
    logoutSelected: false,

    initStarted: false,
    initComplete: false,
    initCompleteRedirectTo: '',
    initCompleteHasRedirected: false,

    identitySetUserFetching: false,
    identityListState: { loading: false },

    centralIdentityIsRedirecting: false,

    isCareerHubAuthenticated: false,

    activeIdentity: undefined,
    identities: [],
    hasIndividualIdentity: false,

    expires: undefined,
    expiresSoon: false,
    expiresSoonDismissed: false,

    isImpersonation: false,

    oidcUser: null,
    oidcEventLog: [],
};

const authenticationReducer = createReducer(initialState)
    .handleAction(actions.authenticationLogoutSelected, state => ({
        ...state,
        logoutSelected: true,
    }))
    .handleAction(actions.authenticationInit, state => ({
        ...state,
        initStarted: true,
    }))
    .handleAction(actions.authenticationSetRedirect, (state, action) => ({
        ...state,
        initCompleteRedirectTo: action.payload.to,
    }))
    .handleAction(actions.authenticationHasRedirected, state => ({
        ...state,
        initCompleteHasRedirected: true,
    }))
    .handleAction(actions.centralIdentitySetUser, (state, action) => ({
        ...state,
        oidcUser: action.payload.user as UserSettings,
    }))
    .handleAction(actions.centralIdentityComplete, (state, action) => ({
        ...state,
        oidcUser: action.payload.user as UserSettings,
    }))
    .handleAction(actions.centralIdentityFailure, (state, action) => ({
        ...state,
        error: action.payload,
    }))
    .handleAction(actions.centralIdentityRedirect, state => ({
        ...state,
        centralIdentityIsRedirecting: true,
    }))
    .handleAction(actions.careerhubIdentityListAsync.request, (state, action) => ({
        ...state,
        identityListState: { loading: true },
    }))
    .handleAction(actions.careerhubIdentityListAsync.success, (state, action) => ({
        ...state,
        identityListState: { loading: false },
        identities: action.payload.data.identities,
        hasIndividualIdentity: !!action.payload.data.identities.find(identityHelper.isIndividual),
    }))
    // this is a bit dumb and probably points at me overcomplicating something in the authentication
    // flow. Probably trying to be too smart with epics. In any case, the result is the error getting stored in two places
    .handleAction(actions.careerhubIdentityListAsync.failure, (state, action) => ({
        ...state,
        identityListState: { loading: false, error: action.payload },
        error: action.payload,
    }))
    .handleAction(actions.careerhubIdentitySetUserAsync.request, state => ({
        ...state,
        identitySetUserFetching: true,
    }))
    .handleAction(actions.careerhubIdentitySetUserAsync.success, (state, action) => ({
        ...state,
        identitySetUserFetching: false,
    }))
    .handleAction(actions.careerhubIdentitySetUserAsync.failure, (state, action) => ({
        ...state,
        identitySetUserFetching: false,
        error: action.payload,
    }))
    .handleAction(actions.careerHubIdentitySetActive, (state, { payload }) => ({
        ...state,

        isCareerHubAuthenticated: !!payload,
        activeIdentity: payload,
    }))
    .handleAction(actions.authenticationComplete, state => ({
        ...state,
        initComplete: true,
    }))
    // Note: This is actually the only place in the application where I
    // have two different handlers for the same action... (and the handler below this one)
    .handleAction(registerOrganisationAsync.success, (state, action) => ({
        ...state,
        identities: [...state.identities, action.payload.data],
    }))
    .handleAction(registerIndividualAsync.success, (state, action) => ({
        ...state,
        identities: [...state.identities, action.payload.data],
    }))
    // this only ever gets called when the user enters the site through the authentication page
    .handleAction(actions.impersonateGetIdentityAsync.request, (state, action) => ({
        ...state,
        isImpersonation: true,
    }))
    // this isn't really necessary since the identity array shouldn't ever be accessed if the user
    // is impersonating, but it looks weird to have an active identity without the identity being in the array
    .handleAction(actions.impersonateGetIdentityAsync.success, (state, action) => ({
        ...state,
        identities: [action.payload.data],
    }))
    .handleAction(actions.impersonateGetIdentityAsync.failure, (state, action) => ({
        ...state,
        impersonateError: action.payload,
    }))
    .handleAction(actions.authenticationNotAuthorized, state => ({
        ...initialState,
        initStarted: state.initStarted,
        initComplete: state.initComplete,
    }))
    // shouldn't need to worry about clearing this
    // as it will update on any change of value
    // and should be present in the identity-set call
    .handleAction(actions.authenticationExpireSet, (state, action) => ({
        ...state,
        expires: action.payload.expires,
        expiresSoon: false,
        expiresSoonDismissed: false,
    }))
    .handleAction(actions.authenticationExpireSoon, state => ({
        ...state,
        expiresSoon: true,
    }))
    .handleAction(actions.authenticationExpireSoonDismissed, state => ({
        ...state,
        expiresSoonDismissed: true,
    }))
    // cross-reducer action. I consider the email-claims logic standalone
    // so it has it's own reducer/epics/actions/etc however, it's easier to just merge the result here.
    .handleAction(userInfoAsync.success, (state, action) => ({
        ...state,
        // this should always exist at this point. The userinfo endpoint should never
        // get called when the user isn't authenticated
        oidcUser: state.oidcUser
            ? {
                  ...state.oidcUser,
                  profile: {
                      ...state.oidcUser.profile,
                      ...action.payload,
                  },
              }
            : null,
    }))

    .handleAction(actions.oidcEvent, (state, action) => ({
        ...state,
        oidcEventLog: [action.payload, ...state.oidcEventLog.slice(0, 20)],
    }));

export default authenticationReducer;
